import { useFormContext } from 'react-hook-form'
import { Card, CardContent, CardHeader } from '@/components/Card'
import { FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import {
    calculateEndDate,
    calculateOccurrences,
    formatTo24HourClock,
    formatToDateTimeWithUTC,
    getDayWithSuffix,
} from '@/utils/time'
import { Input } from '@/components/Input'
import { RecurringDatePicker } from '@/components/RecurringDatePicker'
import {
    DURATION,
    DURATION_ARGUMENTS,
    DURATION_TYPES,
    ISO_8601,
    ISO_8601_WITH_UTC_OFFSET,
    RECURRING_TYPES,
    SHOULD_VALIDATE_TRUE,
    TWENTY_FOUR_HOUR_CLOCK,
    US_FORMAT,
    WEEKDAY_FULLNAME,
} from '@/constants'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { useEffect, useState } from 'react'
import { ManipulateType, RepeatType } from '@/api/job/schema'
import { SelectSingleEventHandler } from 'react-day-picker'
import { Label } from '@/components/Label'
import { Switch } from '@/components/Switch'
import dayjs from 'dayjs'

dayjs.extend(advancedFormat)

const RecurringJobSchedule = () => {
    const { control, setValue, getValues, watch } = useFormContext()

    const [duration, setDuration] = useState<number>(1)

    const endDateTimeField = 'appointment.endDateTime'
    const startDateTimeField = 'appointment.startDateTime'

    const [availableAnytime, setAvailableAnytime] = useState<boolean>(false)
    const [recurringEnd, setRecurringEnd] = useState<string>('')
    const [occurences, setOccurences] = useState<number>(0)
    const [durationType, setDurationType] = useState<ManipulateType>('week')

    const endDate = watch(endDateTimeField)
    const startDate = watch(startDateTimeField)

    const repeat: RepeatType = watch('appointment.repeat')
    const appointmentType = watch('appointment.appointmentType')

    const startDayName = dayjs(startDate).format(WEEKDAY_FULLNAME)
    const startMonthDayName = dayjs(startDate).format('MMMM DD')
    const startDayDate = dayjs(startDate).date()

    const setDateTime = (field: string, date: Date | undefined) => {
        // Extract the Month, Day, and Year from the date in ISO 8601 format
        const newValueDate = dayjs(date).format(ISO_8601)

        // Access the previousValue from useForm via getValues() to extract the time from it
        const previousValueTime = dayjs(getValues(field)).format(TWENTY_FOUR_HOUR_CLOCK)

        setValue(
            field,
            dayjs(`${newValueDate} ${previousValueTime}`).format(ISO_8601_WITH_UTC_OFFSET),
            {
                shouldValidate: true,
            },
        )
    }

    const setStartDate: SelectSingleEventHandler = (date: Date | undefined) =>
        setDateTime(startDateTimeField, date)

    useEffect(() => {
        if (repeat == RECURRING_TYPES.MONTHLY) {
            setDurationType(DURATION_ARGUMENTS.MONTHLY)
            setDuration(1)
        } else if (repeat == RECURRING_TYPES.YEARLY) {
            setDurationType(DURATION_ARGUMENTS.YEARLY)
            setDuration(1)
        }
    }, [repeat])

    useEffect(() => {
        if (repeat) {
            const end = calculateEndDate(startDate, duration, durationType, repeat)
            const oc = calculateOccurrences(dayjs(startDate).format(US_FORMAT), end, repeat)
            setOccurences(oc)

            const newValueDate = dayjs(end).format(ISO_8601)
            const previousValueTime = dayjs(endDate).format(TWENTY_FOUR_HOUR_CLOCK)

            setRecurringEnd(newValueDate)
            setValue(
                'appointment.endDateTime',
                dayjs(`${newValueDate} ${previousValueTime}`).format(ISO_8601_WITH_UTC_OFFSET),
                {
                    shouldValidate: true,
                },
            )
        }
    }, [startDate, repeat, duration, durationType, appointmentType])

    useEffect(() => {
        // set the default value of repeat
        setValue('appointment.repeat', RECURRING_TYPES.WEEKLY)
    }, [])

    useEffect(() => {
        setValue('appointment.occurrences', occurences, SHOULD_VALIDATE_TRUE)
    }, [occurences])

    useEffect(() => {
        setValue('appointment.availableAnytime', availableAnytime)
    }, [availableAnytime])

    return (
        <Card className='mt-7 rounded-none'>
            <CardHeader className='bg-zentive-bg-100'>
                <h1 className='font-bold text-lg'>Recurring Job</h1>
            </CardHeader>
            <CardContent className='flex flex-col gap-5 my-10'>
                <RecurringDatePicker setStartDate={setStartDate} startDate={startDate} />

                <div className='flex flex-row justify-between'>
                    <p className='text-zentive-black font-semibold'>Available Anytime</p>
                    <Switch
                        className='mt-[.20rem]'
                        onCheckedChange={() => setAvailableAnytime(!availableAnytime)}
                    />
                </div>
                <p className='text-zentive-black-medium font-bold'>Preferred Time</p>
                <div className='flex flex-row gap-5 w-full'>
                    <FormField
                        control={control}
                        name='appointment.startDateTime'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className='relative font-sans pl-3 text-base h-11 border border-gray-400 disabled:bg-[#EBEBEB] rounded-sm'
                                        type='time'
                                        placeholder='Start Time'
                                        {...field}
                                        onChange={(evt) => {
                                            formatToDateTimeWithUTC(
                                                dayjs(startDate).format(ISO_8601),
                                                evt.target.value,
                                                field.onChange,
                                            )
                                        }}
                                        value={formatTo24HourClock(field.value)}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={control}
                        name='appointment.endDateTime'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className='font-sans pl-3 text-base h-11 border border-gray-400 disabled:bg-[#EBEBEB] rounded-sm'
                                        type='time'
                                        placeholder='End Time'
                                        {...field}
                                        onChange={(evt) => {
                                            formatToDateTimeWithUTC(
                                                dayjs(endDate).format(ISO_8601),
                                                evt.target.value,
                                                field.onChange,
                                            )
                                        }}
                                        value={formatTo24HourClock(field.value)}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <FormField
                    control={control}
                    name='appointment.repeat'
                    render={({ field }) => (
                        <FormItem>
                            <Select
                                onValueChange={field.onChange}
                                defaultValue={field.value}
                                name={field.name}
                            >
                                <FormControl>
                                    <div className='relative'>
                                        <SelectTrigger className='font-sans pl-3 text-base h-11 border border-gray-400 disabled:bg-[#EBEBEB] rounded-sm  focus:ring-0 focus:ring-offset-0 ring-0 ring-offset-0'>
                                            <SelectValue
                                                placeholder={` Weekly on ${startDayName}`}
                                            />
                                        </SelectTrigger>
                                        <Label className='floating-label absolute left-3 top-1/2 -translate-y-[2.15rem] px-1 text-sm text-zentive-gray-medium split-color pointer-events-none'>
                                            Repeat
                                        </Label>
                                    </div>
                                </FormControl>
                                <SelectContent>
                                    
                                    <SelectItem value={RECURRING_TYPES.WEEKLY}>
                                        Weekly on {startDayName}
                                    </SelectItem>
                                    <SelectItem value={RECURRING_TYPES.BI_WEEKLY}>
                                        Every 2 weeks on {startDayName}
                                    </SelectItem>
                                    <SelectItem value={RECURRING_TYPES.MONTHLY}>
                                        Monthly on the {getDayWithSuffix(startDayDate)}
                                    </SelectItem>
                                    <SelectItem value={RECURRING_TYPES.YEARLY}>
                                        Annually on {startMonthDayName}
                                    </SelectItem>
                                    <SelectItem value={RECURRING_TYPES.DAILY}>
                                        Daily starting on {startMonthDayName}
                                    </SelectItem>
                                </SelectContent>
                            </Select>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <div className='relative'>
                    <Label className='floating-label absolute left-3 top-1/2 -translate-y-[2.15rem] px-1 text-sm text-zentive-gray-medium split-color pointer-events-none'>
                        Duration
                    </Label>
                    <div className='flex flex-row space-y-0  border border-gray-400 rounded-sm'>
                        <Select
                            onValueChange={(val) => setDuration(Number(val))}
                            value={duration.toLocaleString()}
                        >
                            <FormControl>
                                <SelectTrigger
                                    className='w-10 h-11 border-none focus:ring-0 focus:ring-offset-0 ring-0 ring-offset-0 flex justify-center'
                                    hasIcon={false}
                                >
                                    <SelectValue placeholder={1} />
                                </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                                {DURATION?.map((val) => (
                                    <SelectItem value={val}>{val}</SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                        <div className='h-11 w-[1px] bg-gray-400' />
                        <Select
                            onValueChange={(val: ManipulateType) => setDurationType(val)}
                            value={durationType}
                        >
                            <FormControl>
                                <SelectTrigger className='text-base h-11 border-none focus:ring-0 focus:ring-offset-0 ring-0 ring-offset-0'>
                                    <SelectValue placeholder={DURATION_TYPES[repeat]} />
                                </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                                {repeat === RECURRING_TYPES.DAILY && (
                                    <SelectItem value={DURATION_ARGUMENTS.DAILY}>
                                        {DURATION_TYPES.DAILY}
                                    </SelectItem>
                                )}
                                {repeat !== RECURRING_TYPES.MONTHLY &&
                                    repeat !== RECURRING_TYPES.YEARLY && (
                                        <SelectItem value={DURATION_ARGUMENTS.WEEKLY}>
                                            {DURATION_TYPES.WEEKLY}
                                        </SelectItem>
                                    )}

                                {repeat !== RECURRING_TYPES.YEARLY && repeat !== RECURRING_TYPES.DAILY && (
                                    <SelectItem value={DURATION_ARGUMENTS.MONTHLY}>
                                        {DURATION_TYPES.MONTHLY}
                                    </SelectItem>
                                )}
                                {repeat !== RECURRING_TYPES.DAILY && (
                                    <SelectItem value={DURATION_ARGUMENTS.YEARLY}>
                                        {DURATION_TYPES.YEARLY}
                                    </SelectItem>
                                )}
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                <div>
                    <h1 className='font-bold text-lg'>Visits</h1>
                    <div className='flex flex-row justify-between'>
                        <p className='w-1/3'>First</p>
                        <p className='w-1/3'>Last</p>
                        <p className='w-1/3'>Total</p>
                    </div>
                    <div className='flex flex-row justify-between'>
                        <p className='w-1/3'>{dayjs(startDate).format(US_FORMAT)}</p>
                        <p className='w-1/3'>{dayjs(recurringEnd).format(US_FORMAT)}</p>
                        <p className='w-1/3'>{occurences}</p>
                    </div>
                </div>
                <div>
                    <h1 className='font-bold text-lg'>Invoice</h1>
                    <p>Upon Completion</p>
                </div>
            </CardContent>
        </Card>
    )
}

export default RecurringJobSchedule
