import { useQuery } from '@tanstack/react-query'
import BackButtonTopBar from '../../Common/BackButtonTopBar'
import Spinner from '@/components/Spinner'
import { useParams } from 'react-router-dom'
import { getJobById } from '@/api/job'
import { timezoneAtom } from '@/store/auth'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import { ABBREVIATED_DATE_12_HOUR, SPANISH_FORMAT_WITH_TIME } from '@/constants'
import { TimeZoneResult } from '@/hooks/useGeocode'
import { formatDateTime } from '../../Jobs/JobGallery/JobDateFilter/DateSelector'

const CompletedJobDetails = () => {
    const { t, i18n } = useTranslation(['work-history'])
    const { jobId } = useParams()
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult

    const { data: job } = useQuery({
        queryKey: ['jobById', jobId],
        queryFn: () => getJobById(jobId ?? ''),
    })

    if (!job)
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-white'>
                <Spinner variant='normal' size='10' />
            </div>
        )

    return (
        <div className='flex flex-col space-y-3 pb-10'>
            <BackButtonTopBar title={job?.quote?.productServiceGroup?.[0]?.name ?? ''} />
            <div className='w-10/12 flex flex-col mx-auto bg-white rounded-md shadow-md border border-gray-300 px-6 py-4'>
                <div className='flex flex-col border-b space-y-3 py-4'>
                    <p className='text-zentive-gray-medium font-semibold text-lg'>
                        {job?.quote?.profile?.firstName + ' ' + job?.quote?.profile?.lastName}
                    </p>
                    <p className='text-sm'>{job?.quote?.profile?.email}</p>
                    <p className='text-sm'>
                        {formatDateTime(
                            job?.appointment?.startDateTime,
                            timezone.timeZoneId,
                            i18n.language === 'es'
                                ? SPANISH_FORMAT_WITH_TIME
                                : ABBREVIATED_DATE_12_HOUR,
                        )}
                    </p>
                </div>
                <div className='flex flex-col border-b space-y-3 py-4'>
                    <p className='text-zentive-gray-medium font-semibold text-lg'>{t('Job')}</p>
                    {job?.quote?.productServiceGroup
                        ?.filter((service) => service?.type === 'service')
                        ?.map((service, index) => (
                            <p key={index} className='text-sm'>
                                {service?.name ?? ''}
                            </p>
                        ))}
                </div>
                <div className='flex flex-col border-b space-y-3 py-4'>
                    <p className='text-zentive-gray-medium font-semibold text-lg'>
                        {t('List of Materials')}
                    </p>
                    <div className='flex flex-col gap-y-2'>
                        {job?.quote?.productServiceGroup?.filter(
                            (product) => product?.type === 'product',
                        )?.length >= 1
                            ? job?.quote?.productServiceGroup
                                  ?.filter((product) => product?.type === 'product')
                                  ?.map((product, index) => (
                                      <div key={index} className='flex flex-row justify-between'>
                                          <p className='text-sm'>{product?.name ?? ''}</p>
                                          <div className='flex-grow h-px bg-zentive-gray-light my-3 mx-4'></div>
                                          <p className='text-sm'>
                                              $
                                              {product?.unitPrice.toLocaleString(undefined, {
                                                  maximumFractionDigits: 2,
                                                  minimumFractionDigits: 2,
                                              }) ?? ''}
                                          </p>
                                      </div>
                                  ))
                            : '--'}
                    </div>
                </div>
                {job?.quote?.expense && job?.quote?.expense?.length >= 1 && (
                    <div className='flex flex-col border-b space-y-3 py-4'>
                        <p className='text-zentive-gray-medium font-semibold text-lg'>
                            {t('Expenses')}
                        </p>
                        <div className='flex flex-col gap-y-2'>
                            <div>
                                <p className='text-zentive-gray-medium text-md my-2'>
                                    {t(`External (Included in client's invoice)`)}
                                </p>
                                {job?.quote?.expense
                                    ?.filter((item) => !item.isNotIncludedInInvoice)
                                    ?.map((exp, index) => (
                                        <div key={index} className='flex flex-row justify-between'>
                                            <p className='text-sm'>{exp.accountName}</p>
                                            <div className='flex-grow h-px bg-zentive-gray-light my-3 mx-4'></div>
                                            <p className='text-sm'>
                                                $
                                                {exp?.total.toLocaleString(undefined, {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }) ?? ''}
                                            </p>
                                        </div>
                                    ))}
                            </div>
                            <div>
                                {job?.quote?.expense?.filter((item) => item.isNotIncludedInInvoice)
                                    ?.length >= 1 && (
                                    <p className='text-zentive-gray-medium text-md my-2'>
                                        {t(`Internal (Excluded in client's invoice)`)}
                                    </p>
                                )}
                                {job?.quote?.expense
                                    ?.filter((item) => item.isNotIncludedInInvoice)
                                    ?.map((exp, index) => (
                                        <div key={index} className='flex flex-row justify-between'>
                                            <p className='text-sm'>{exp.accountName}</p>
                                            <div className='flex-grow h-px bg-zentive-gray-light my-3 mx-4'></div>
                                            <p className='text-sm'>
                                                $
                                                {exp?.total.toLocaleString(undefined, {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }) ?? ''}
                                            </p>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CompletedJobDetails
