import { Button } from '@/components/Button'
import { useNavigate } from 'react-router-dom'
import UpdateBilingInfo from '../../../UpdateBillingInfo'
import { useAtomValue } from 'jotai'
import { userAtom } from '@/store/auth'
import { AxiosError } from 'axios'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getBusinessProfile, getSubscriptions } from '@/api/profile'
import stripeCC from '@/assets/public/Edited-CC.png'
import poweredByStripe from '@/assets/public/Powered-by-Stripe.png'
import { ProfileSelfResponseType } from '@/api/business/schema'
import { ownerPayInvoice } from '@/api/owner'
import { numberFormatter } from '@/utils/helper'
import BreadCrumbs from '@/components/BreadCrumbs'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import { SubscriptionsInfoType } from '@/api/profile/schema'

const ManualPayment = () => {
    const navigate = useNavigate()
    const user = useAtomValue(userAtom)
    const params = new URLSearchParams(window.location.search)
    const unpaidInvoice = params.get('unpaidInvoice')

    const { data: businessProfileData } = useQuery<unknown, AxiosError, ProfileSelfResponseType>({
        queryKey: ['businessProfile'],
        queryFn: () => getBusinessProfile(user?.profileId as string),
    })

    const { data: subscriptionsData } = useQuery<
        unknown,
        AxiosError,
        SubscriptionsInfoType[]
    >({
        queryKey: ['subscriptionsInfo'],
        queryFn: () => getSubscriptions(user?.customerId as string),
        enabled: !!user?.customerId,
    })
    const { mutate: payInvoice, isPending } = useMutation({
        mutationFn: (invoiceId: string) => ownerPayInvoice(invoiceId),
        onSuccess: () => {
            navigate('/settings/owner-subscription')
        },
        onError: (err: AxiosError) => console.log(err),
    })

    const latestPrice = subscriptionsData?.[0]?.price ?? 0.0

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName='Pay Now' />
            <div className='bg-white pb-[56px] mt-10'>
                <div className='flex flex-col items-center w-full pt-[36px]'>
                    <img
                        src={stripeCC}
                        alt='Stripe CC Logo'
                        className='h-[29px] w-[241px] mb-[5px] '
                    />
                    <img
                        src={poweredByStripe}
                        alt='Powered by Stripe Logo'
                        className='h-[21.93px] w-[96.76px]'
                    />
                    <div>{`$${numberFormatter.format(
                                    latestPrice / 100 ?? 0,
                                )}`}</div>
                </div>
                <div className='px-[100px] pt-[40px]'>
                    <UpdateBilingInfo businessProfileData={businessProfileData} />
                </div>
            </div>
            <div className='flex justify-end bg-[#EBEBEB] gap-3 py-5 px-5 rounded-b-[15px]'>
                <Button
                    disabled={isPending}
                    onClick={() => navigate(-1)}
                    className='w-97 '
                    variant={'outline'}
                >
                    Back
                </Button>
                <Button
                    disabled={isPending}
                    onClick={() => {
                        payInvoice(unpaidInvoice as string)
                    }}
                    className='w-97'
                >
                    Proceed
                </Button>
            </div>
        </div>
    )
}

export default ManualPayment
