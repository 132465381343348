import { signUpGalleryAtom, userAtom } from '@/store/auth'
import { useAtomValue } from 'jotai'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { getPricingByID } from '@/api/subscription'
import { SubscriptionType } from '@/api/subscription/schema'
import ChangePlanButton from './ChsngePlanButton'
import { ProfileSelfResponseType } from '@/api/business/schema'
import { getBusinessProfile } from '@/api/profile'
import { FullProfileType } from '@/api/profile/schema'

import { checkCustomerPaymentMethod } from '@/api/subscription'

const ActionButton = () => {
    const selected = useAtomValue(signUpGalleryAtom)
    const user = useAtomValue(userAtom) as FullProfileType

    const { data: businessProfileData } = useQuery<unknown, AxiosError, ProfileSelfResponseType>({
        queryKey: ['businessProfileData'],
        queryFn: () => getBusinessProfile(user?.profileId as string),
    })

    const { data: currentPlan } = useQuery<unknown, AxiosError, SubscriptionType>({
        queryKey: [businessProfileData?.business.subscriptionTypeId],
        queryFn: () => getPricingByID(businessProfileData?.business.subscriptionTypeId as string),
    })

    const { data } = useQuery({
        queryFn: () => checkCustomerPaymentMethod(user.customerId as string),
        queryKey: ['hasPaymentMethod', user.customerId],
    })

    const hasPayment = data === true

    return (
        <div className='flex justify-end w-full'>
            <div
                className='
            inline-flex 
            xl:gap-x-[22px] 
            2xl:gap-x-[85px] 
            w2xl:gap-x-[110px]
            3xl:gap-x-[195px]
            4xl:gap-x-[408px]
            '
            >
                {selected &&
                    selected?.length > 0 &&
                    selected
                        .sort((a, b) => {
                            const order = ['Starter', 'Standard', 'Growth Pro']
                            return order.indexOf(a.name as string) - order.indexOf(b.name as string)
                        })
                        ?.map((plan) => (
                            <div>
                                {/* Free Trial */}
                                {currentPlan?.name === 'Free Trial'}
                                {currentPlan?.name === 'Free Trial' &&
                                    (currentPlan?.interval === 'days' ? (
                                        currentPlan?.name === plan.name &&
                                        plan.interval === 'days' ? (
                                            <ChangePlanButton
                                                hasPayment={hasPayment}
                                                plan={plan}
                                                changePlanType='Current Plan'
                                                isChangePlan={
                                                    businessProfileData?.business
                                                        .isChangePlan as string
                                                }
                                            />
                                        ) : (
                                            <ChangePlanButton
                                                hasPayment={hasPayment}
                                                plan={plan}
                                                changePlanType='Upgrade'
                                                isChangePlan={
                                                    businessProfileData?.business
                                                        .isChangePlan as string
                                                }
                                            />
                                        )
                                    ) : currentPlan?.name === plan.name &&
                                      plan.interval === 'year' ? (
                                        <ChangePlanButton
                                            hasPayment={hasPayment}
                                            plan={plan}
                                            changePlanType='Current Plan'
                                            isChangePlan={
                                                businessProfileData?.business.isChangePlan as string
                                            }
                                        />
                                    ) : plan.interval === 'month' ? (
                                        <ChangePlanButton
                                            hasPayment={hasPayment}
                                            plan={plan}
                                            changePlanType='Downgrade'
                                            isChangePlan={
                                                businessProfileData?.business.isChangePlan as string
                                            }
                                        />
                                    ) : (
                                        <ChangePlanButton
                                            hasPayment={hasPayment}
                                            plan={plan}
                                            changePlanType='Upgrade'
                                            isChangePlan={
                                                businessProfileData?.business.isChangePlan as string
                                            }
                                        />
                                    ))}
                                {/* STARTER PLAN */}
                                {currentPlan?.name === 'Starter'}
                                {currentPlan?.name === 'Starter' &&
                                    (currentPlan?.interval === 'month' ? (
                                        currentPlan?.name === plan.name &&
                                        plan.interval === 'month' ? (
                                            <ChangePlanButton
                                                hasPayment={hasPayment}
                                                plan={plan}
                                                changePlanType='Current Plan'
                                                isChangePlan={
                                                    businessProfileData?.business
                                                        .isChangePlan as string
                                                }
                                            />
                                        ) : (
                                            <ChangePlanButton
                                                hasPayment={hasPayment}
                                                plan={plan}
                                                changePlanType='Upgrade'
                                                isChangePlan={
                                                    businessProfileData?.business
                                                        .isChangePlan as string
                                                }
                                            />
                                        )
                                    ) : currentPlan?.name === plan.name &&
                                      plan.interval === 'year' ? (
                                        <ChangePlanButton
                                            hasPayment={hasPayment}
                                            plan={plan}
                                            changePlanType='Current Plan'
                                            isChangePlan={
                                                businessProfileData?.business.isChangePlan as string
                                            }
                                        />
                                    ) : plan.interval === 'month' ? (
                                        <ChangePlanButton
                                            hasPayment={hasPayment}
                                            plan={plan}
                                            changePlanType='Downgrade'
                                            isChangePlan={
                                                businessProfileData?.business.isChangePlan as string
                                            }
                                        />
                                    ) : (
                                        <ChangePlanButton
                                            hasPayment={hasPayment}
                                            plan={plan}
                                            changePlanType='Upgrade'
                                            isChangePlan={
                                                businessProfileData?.business.isChangePlan as string
                                            }
                                        />
                                    ))}

                                {/* STANDARD PLAN */}
                                {currentPlan?.name === 'Standard' &&
                                    (currentPlan?.interval === 'month' ? (
                                        'Standard' === plan.name && plan.interval === 'month' ? (
                                            <ChangePlanButton
                                                hasPayment={hasPayment}
                                                plan={plan}
                                                changePlanType='Current Plan'
                                                isChangePlan={
                                                    businessProfileData?.business
                                                        .isChangePlan as string
                                                }
                                            />
                                        ) : 'Starter' === plan.name && plan.interval === 'month' ? (
                                            <ChangePlanButton
                                                hasPayment={hasPayment}
                                                plan={plan}
                                                changePlanType='Downgrade'
                                                isChangePlan={
                                                    businessProfileData?.business
                                                        .isChangePlan as string
                                                }
                                            />
                                        ) : (
                                            <ChangePlanButton
                                                hasPayment={hasPayment}
                                                plan={plan}
                                                changePlanType='Upgrade'
                                                isChangePlan={
                                                    businessProfileData?.business
                                                        .isChangePlan as string
                                                }
                                            />
                                        )
                                    ) : currentPlan?.name === plan.name &&
                                      plan.interval === 'year' ? (
                                        <ChangePlanButton
                                            hasPayment={hasPayment}
                                            plan={plan}
                                            changePlanType='Current Plan'
                                            isChangePlan={
                                                businessProfileData?.business.isChangePlan as string
                                            }
                                        />
                                    ) : 'Growth Pro' === plan.name && plan.interval === 'year' ? (
                                        <ChangePlanButton
                                            hasPayment={hasPayment}
                                            plan={plan}
                                            changePlanType='Upgrade'
                                            isChangePlan={
                                                businessProfileData?.business.isChangePlan as string
                                            }
                                        />
                                    ) : (
                                        <ChangePlanButton
                                            hasPayment={hasPayment}
                                            plan={plan}
                                            changePlanType='Downgrade'
                                            isChangePlan={
                                                businessProfileData?.business.isChangePlan as string
                                            }
                                        />
                                    ))}

                                {/* GROWTH PRO PLAN */}
                                {currentPlan?.name === 'Growth Pro' &&
                                    (currentPlan?.interval === 'year' ? (
                                        currentPlan?.name === plan.name &&
                                        plan.interval === 'year' ? (
                                            <ChangePlanButton
                                                hasPayment={hasPayment}
                                                plan={plan}
                                                changePlanType='Current Plan'
                                                isChangePlan={
                                                    businessProfileData?.business
                                                        .isChangePlan as string
                                                }
                                            />
                                        ) : (
                                            <ChangePlanButton
                                                hasPayment={hasPayment}
                                                plan={plan}
                                                changePlanType='Downgrade'
                                                isChangePlan={
                                                    businessProfileData?.business
                                                        .isChangePlan as string
                                                }
                                            />
                                        )
                                    ) : currentPlan?.name === plan.name &&
                                      plan.interval === 'month' ? (
                                        <ChangePlanButton
                                            hasPayment={hasPayment}
                                            plan={plan}
                                            changePlanType='Current Plan'
                                            isChangePlan={
                                                businessProfileData?.business.isChangePlan as string
                                            }
                                        />
                                    ) : plan.interval === 'month' ? (
                                        <ChangePlanButton
                                            hasPayment={hasPayment}
                                            plan={plan}
                                            changePlanType='Downgrade'
                                            isChangePlan={
                                                businessProfileData?.business.isChangePlan as string
                                            }
                                        />
                                    ) : (
                                        <ChangePlanButton
                                            hasPayment={hasPayment}
                                            plan={plan}
                                            isChangePlan={
                                                businessProfileData?.business.isChangePlan as string
                                            }
                                            changePlanType='Upgrade'
                                        />
                                    ))}
                            </div>
                        ))}
            </div>
        </div>
    )
}

export default ActionButton
