import BreadCrumbs from '@/components/BreadCrumbs'
import { SubscribersCount } from './SubscribersCount'
import SubscribersChart from './SubscribersChart'

const AdminDashboard = () => {
    return (
        <div className='h-full'>
            <BreadCrumbs titleName='Dashboard' />
            <div className='h-full mt-10'>
                <h1 className='font-bold text-xl text-zentive-gray-medium'>
                    Hi, Welcome to Zentive!
                </h1>
            </div>

            <div className='flex flex-row gap-10'>
                <SubscribersCount />
                <SubscribersChart />
            </div>
        </div>
    )
}

export default AdminDashboard
