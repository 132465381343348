import { Bar } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { useAtomValue } from 'jotai'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useQuery } from '@tanstack/react-query'
import { DashboardSubscriberChartMonthlyRecord } from '@/api/reports/schema'
import { getAdminDashboardSubscriberChart } from '@/api/reports'
import Spinner from '@/components/Spinner'
import { useEffect, useRef } from 'react'

// Register the required components from Chart.js
ChartJS.register(CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend)

const SubscribersChart = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const chartRef = useRef<ChartJS<'bar', (number | undefined)[]> | null>(null)

    const { data: subscriberChart, isLoading: incomeChartLoading } =
        useQuery<DashboardSubscriberChartMonthlyRecord>({
            queryKey: ['subscriberChart', user],
            queryFn: () => getAdminDashboardSubscriberChart(),
        })

    const data = [
        {
            month: 'January',
            active: subscriberChart?.january?.activeSubscribers,
            new: subscriberChart?.january?.newSubscribers,
        },
        {
            month: 'February',
            active: subscriberChart?.february?.activeSubscribers,
            new: subscriberChart?.february?.newSubscribers,
        },
        {
            month: 'March',
            active: subscriberChart?.march?.activeSubscribers,
            new: subscriberChart?.march?.newSubscribers,
        },
        {
            month: 'April',
            active: subscriberChart?.april?.activeSubscribers,
            new: subscriberChart?.april?.newSubscribers,
        },
        {
            month: 'May',
            active: subscriberChart?.may?.activeSubscribers,
            new: subscriberChart?.may?.newSubscribers,
        },
        {
            month: 'June',
            active: subscriberChart?.june?.activeSubscribers,
            new: subscriberChart?.june?.newSubscribers,
        },
        {
            month: 'July',
            active: subscriberChart?.july?.activeSubscribers,
            new: subscriberChart?.july?.newSubscribers,
        },
        {
            month: 'August',
            active: subscriberChart?.august?.activeSubscribers,
            new: subscriberChart?.august?.newSubscribers,
        },
        {
            month: 'September',
            active: subscriberChart?.september?.activeSubscribers,
            new: subscriberChart?.september?.newSubscribers,
        },
        {
            month: 'October',
            active: subscriberChart?.october?.activeSubscribers,
            new: subscriberChart?.october?.newSubscribers,
        },
        {
            month: 'November',
            active: subscriberChart?.november?.activeSubscribers,
            new: subscriberChart?.november?.newSubscribers,
        },
        {
            month: 'December',
            active: subscriberChart?.december?.activeSubscribers,
            new: subscriberChart?.december?.newSubscribers,
        },
    ]

    const chartData = {
        labels: data.map((row) => row.month),
        datasets: [
            {
                label: 'Active',
                data: data.map((row) => row.active),
                backgroundColor: '#4DB8FF',
                borderColor: '#4DB8FF',
                borderWidth: 1,
            },
            {
                label: 'New',
                data: data.map((row) => row.new),
                backgroundColor: '#34C73B',
                borderColor: '#34C73B',
                borderWidth: 1,
            },
        ],
    }

    const options = {
        responsive: true,
        title: {
            display: true,
            text: 'Active and New Subscribers',
        },
        legend: {
            position: 'top',
        },
    }

    useEffect(() => {
        chartRef?.current?.destroy()
    }, [])

    ChartJS.unregister()

    return (
        <div className='w-full h-full bg-white p-5 mt-10'>
            {incomeChartLoading ? (
                <Spinner variant={'normal'} className='h-[70px] w-[70px]' />
            ) : (
                <Bar
                    data={chartData}
                    options={options}
                    className='max-h-[21rem]'
                    ref={chartRef}
                    id='subschart'
                />
            )}
        </div>
    )
}

export default SubscribersChart
