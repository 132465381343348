import { Drawer, DrawerClose, DrawerContent } from '@/components/Drawer'
import { viewedLeadAtom } from '@/store/lead'
import { toCurrency } from '@/utils/helper'
import { Cross1Icon } from '@radix-ui/react-icons'
import { useAtom } from 'jotai'
import { ComponentProps, FC } from 'react'
import ViewLeadItem from './ViewLeadItem'

const ViewLead: FC<ComponentProps<typeof Drawer>> = (props) => {
    const [viewedLead, setViewedLead] = useAtom(viewedLeadAtom)

    return (
        <Drawer {...props} direction='right'>
            <DrawerContent
                className='bg-white h-full w-[506px] rounded-b-[10px] px-[28px] py-[36px]'
                hideSlider
            >
                <div className='h-full w-full relative flex flex-col'>
                    <DrawerClose asChild>
                        <button
                            className='w-fit flex-none place-self-end mt-[36px]'
                            onClick={() => setViewedLead(null)}
                            type='button'
                        >
                            <Cross1Icon />
                        </button>
                    </DrawerClose>

                    <p className='text-[18px] text-zentive-black font-semibold'>
                        Deal Size: {toCurrency(viewedLead?.dealSize ?? 0)}
                    </p>

                    <div className='my-[21px] h-[2px] bg-zentive-gray-light'></div>

                    <p className='text-zentive-gray-medium mb-[12px]'>Contact Information</p>

                    <ViewLeadItem
                        name='Name'
                        value={`${viewedLead?.firstName} ${viewedLead?.lastName}`}
                    />
                    <ViewLeadItem name='Email' value={viewedLead?.email} />
                    <ViewLeadItem name='Contact No' value={viewedLead?.contactNumber} />
                    <ViewLeadItem name='Contact Type' value={viewedLead?.contactType} />
                    <ViewLeadItem name='Source' value={viewedLead?.source} />
                    <ViewLeadItem
                        name='Service Types'
                        value={viewedLead?.productServiceNames ?? []}
                    />

                    <p className='text-zentive-gray-medium mt-[32px] mb-[12px]'>Job Address</p>

                    {!!(
                        viewedLead?.streetAddress &&
                        viewedLead?.city &&
                        viewedLead?.state &&
                        viewedLead?.zipCode
                    ) ? (
                        <ViewLeadItem
                            name=''
                            value={`${viewedLead?.streetAddress} ${viewedLead?.city} ${viewedLead?.state} ${viewedLead?.zipCode}`}
                        />
                    ) : (
                        <ViewLeadItem name='' value='There is nothing to display' />
                    )}
                </div>
            </DrawerContent>
        </Drawer>
    )
}

export default ViewLead
