import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { cn, numberFormatter } from '@/utils/helper'
import { ExpenseType, QuoteType } from '@/api/quoting/schema'
import dayjs from 'dayjs'

const tableHeader = ['ITEMS', 'DESCRIPTION', 'DATE', 'AMOUNT']

interface IJobExpenses {
    quote: QuoteType
}

const JobExpenses = ({ quote }: IJobExpenses) => {
    let total = quote?.expense
        ? quote.expense.reduce((sum, expense) => sum + (expense.total || 0), 0)
        : 0

    return (
        <div className='flex flex-col mt-4 gap-x-2 bg-white px-6'>
            <div className='flex flex-row justify-between w-full px-6'>
                <h1 className='text-xl font-bold mt-2'>Expenses</h1>
            </div>
            <div className='flex flex-row justify-between w-full px-2'>
                <Table className='table-auto whitespace-normal mx-auto'>
                    <TableHeader style={{ marginBottom: '10px' }}>
                        <TableRow>
                            {tableHeader?.map((header) => (
                                <TableHead
                                    key={`${header}-head`}
                                    className={cn(
                                        'flex-row items-center text-center font-semibold text-base',
                                        'items-start text-start',
                                    )}
                                >
                                    <div className='inline-flex gap-[14px] whitespace-nowrap'>
                                        {header}
                                    </div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {quote?.expense && Array.isArray(quote?.expense) &&
                            quote?.expense?.map((expenses: ExpenseType, index: number) => (
                                <TableRow key={index} className='whitespace-nowrap'>
                                    <TableCell className=' text-zentive-black text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                        {expenses?.accountName}
                                    </TableCell>
                                    <TableCell className=' text-zentive-black text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                        {expenses?.description ?? 'N/A'}
                                    </TableCell>
                                    <TableCell className=' text-zentive-black text-base max-w-[200px] text-ellipsis overflow-hidden'>
                                        {dayjs(expenses?.date).format('MM/DD/YYYY')}
                                    </TableCell>
                                    <TableCell className=' text-zentive-black text-base max-w-[200px] text-ellipsis overflow-hidden'>
                                        <p className='text-base'>
                                            {expenses?.total
                                                ? '$' + numberFormatter.format(expenses?.total)
                                                : '$' + '0.00'}
                                        </p>
                                    </TableCell>
                                </TableRow>
                            ))}
                        <TableRow className='whitespace-nowrap hover:bg-transparent'>
                            <TableCell>
                                <p className='text-base'> </p>
                            </TableCell>
                            <TableCell>
                                <p className='text-base font-bold'> </p>
                            </TableCell>
                            <TableCell>
                                <p className='text-base'> </p>
                            </TableCell>
                            <TableCell>
                                <p className='text-base font-bold'>
                                    {'$' + numberFormatter.format(total)}
                                </p>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default JobExpenses
