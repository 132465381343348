import { getExpenseReport } from '@/api/reports'
import { ExpenseRecordType, ExpenseReportFilterType, ExpenseReportType } from '@/api/reports/schema'
import { Button } from '@/components/Button'
import { Card, CardContent } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import { Pagination } from '@/components/Pagination'
import { PaginationType } from '@/components/Pagination/schema'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import { RECORD_PREFIX, US_FORMAT } from '@/constants'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { expenseReportExportAtom, expenseReportFilterAtom } from '@/store/reports'
import { cn, displayCurrency, formatRecordNumber } from '@/utils/helper'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useAtom, useAtomValue } from 'jotai'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ExpenseNotes } from '../ExpenseNotes'

const tableHeader = ['Job Number', 'Date', 'Expense Name', 'Description', 'Amount', 'Notes']

const ExternalExpenseTable = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const [isAllChecked, setIsAllChecked] = useState(false)

    const expenseReportFilter = useAtomValue(expenseReportFilterAtom)
    const [exportAtom, setExportAtom] = useAtom(expenseReportExportAtom)

    const [pagination, setPagination] = useState<PaginationType>({
        page: 1,
        pageSize: 5,
    })

    const { data: report } = useQuery<ExpenseReportType>({
        queryKey: ['externalExpenseReport', user?.businessId, expenseReportFilter, pagination],
        queryFn: () =>
            getExpenseReport(
                user?.businessId as string,
                expenseReportFilter as ExpenseReportFilterType,
                pagination,
            ),
    })

    const handleCheckAllChange = (isChecked: boolean) => {
        setIsAllChecked(isChecked)

        if (isChecked) {
            // Add all available records to exportAtom
            const allRecords = report?.content?.report ?? []

            setExportAtom({
                records: allRecords,
            })
        } else {
            // Clear all records from exportAtom
            setExportAtom({
                records: [],
            })
        }
    }

    const handleCheckChange = (expense: ExpenseRecordType) => {
        setExportAtom((prev) => {
            if (!prev) {
                // If there is no existing filter, create one with the new invoiceId
                return {
                    records: [expense],
                }
            }

            const isInvoiceSelected = prev.records?.some(
                (record) => record.expenseId === expense.expenseId,
            )

            // Update records based on whether the job is being added or removed
            const updatedJobs = isInvoiceSelected
                ? prev.records.filter((record) => record.expenseId !== expense.expenseId) // Remove if exists
                : [...(prev.records || []), expense] // Add if it doesn't exist

            return {
                records: updatedJobs,
            }
        })
    }

    return (
        <>
            <Card>
                <CardContent className='overflow-x-scroll green-scrollbar rounded-t-none pb-0'>
                    <Table className='table-auto whitespace-normal mx-auto text-zentive-black text-sm'>
                        <TableHeader className='whitespace-nowrap'>
                            <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                                {tableHeader.map((header, index) => (
                                    <TableHead
                                        key={index}
                                        className={cn(
                                            'font-semibold text-zentive-gray-medium text-md whitespace-nowrap',
                                        )}
                                    >
                                        <div className='flex flex-row items-center justify-start gap-2'>
                                            {' '}
                                            {index === 0 && (
                                                <Checkbox
                                                    onCheckedChange={handleCheckAllChange}
                                                    checked={
                                                        isAllChecked ||
                                                        exportAtom?.records?.length ===
                                                            report?.content?.report?.length
                                                    }
                                                    className='mt-[1px]'
                                                />
                                            )}
                                            {header}
                                        </div>
                                    </TableHead>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {Array.isArray(report?.content?.report) &&
                                report?.content?.report?.length > 0 &&
                                report?.content?.report?.map((r: ExpenseRecordType, i: number) => (
                                    <TableRow
                                        key={('external' + r.jobId + '-' + i) as string}
                                        className='whitespace-nowrap'
                                    >
                                        <TableCell className='text-zentive-black  flex flex-row gap-2 items-center'>
                                            <Checkbox
                                                onCheckedChange={() => handleCheckChange(r)}
                                                checked={exportAtom?.records?.some(
                                                    (record) => r.expenseId === record.expenseId,
                                                )}
                                            />
                                            <NavLink
                                                to={r.jobId ? `/schedule/jobs/${r.jobId}` : '#'}
                                            >
                                                <Button
                                                    className=' text-zentive-blue-dark font-semibold'
                                                    variant={'link'}
                                                >
                                                    {r.jobId
                                                        ? RECORD_PREFIX.job +
                                                          formatRecordNumber(r.jobNumber)
                                                        : 'N/A'}
                                                </Button>
                                            </NavLink>
                                        </TableCell>
                                        <TableCell>
                                            <p>{dayjs(r.date).format(US_FORMAT)}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{r.name}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{r.description ? r.description : '--'}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{displayCurrency(r.amount)}</p>
                                        </TableCell>
                                        <TableCell>
                                            <ExpenseNotes notes={r.note} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </CardContent>
                <div className='p-5 pb-0 flex flex-row items-center'>
                    <Pagination
                        pagination={pagination}
                        setPagination={setPagination}
                        itemsPerPage={report?.meta?.pageSize ?? 5}
                        totalRecords={report?.meta?.totalRecords ?? 0}
                    />
                </div>
            </Card>
        </>
    )
}
export default ExternalExpenseTable
