import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import SearchBar from '@/components/InputSearchBox'
import { Button } from '@/components/Button'
import { useState } from 'react'
// import CreateService from './CreateService'
import ActionButton from './ServiceTable/ActionButton'
import { getAllServices } from '@/api/service'
import emptyPlaceholder from '@/assets/private/product-services-empty-state.png'
import debounce from 'debounce'
import AppSkeletonLoadingState from '@/components/LoadingState/TableLoadingState'
import { ProductServiceType } from '@/api/service/schema'
import { FullProfileType } from '@/api/profile/schema'
import { useNavigate } from 'react-router-dom'
import { ROLE } from '@/constants'

const ProductServices = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const isCustomer = user?.role.roleName === ROLE.CUSTOMER
    const navigate = useNavigate()

    const [keyword, setKeyword] = useState<string>('')

    const { data: services, isLoading } = useQuery({
        queryKey: ['ProductServiceType', keyword],
        queryFn: () => getAllServices(user?.businessId as string, keyword),
    })

    const debouncedSearch = debounce((query: string) => {
        handleSearch(query)
    }, 300)

    const handleSearch = (query: string) => {
        setKeyword(query)
    }

    const handleAddItems = () => {
        navigate('/settings/product-services/product-service-add-item')
    }

    return (
        <section>
            {!isCustomer && (
                <>
                    <p className='text-3xl font-semibold text-zentive-gray-medium'>
                        Products & Services
                    </p>
                    <p className='text-md mb-5 text-zentive-black'>
                        Add and update your products and services to stay organized when creating
                        quotes, jobs, and invoices.
                    </p>
                    <div className='flex flex-row w-full space-x-16 items-start text-left justify-between'>
                        <SearchBar
                            placeHolder='Search for...'
                            onSearchChange={(e) => debouncedSearch(e.target.value)}
                        />
                        <div className=' items-end'>
                            {services && services?.length > 0 && (
                                <Button
                                    type='button'
                                    onClick={handleAddItems}
                                    className='border bg-zentive-green-dark text-white px-8 py-5'
                                >
                                    + Add Item
                                </Button>
                            )}
                        </div>
                    </div>
                    <div
                        className={`bg-white pt-5 pb-7 px-0 mt-5 rounded-lg ${
                            services && services?.length > 0 ? 'h-[550px] max-h-[550px] overflow-y-scroll green-scrollbar' : 'h-auto'
                        }`}
                    >
                        {services && services?.length >= 1 && !isLoading ? (
                            <Table>
                                <TableHeader>
                                    <TableRow className='whitespace-nowrap'>
                                        <TableHead className='font-semibold text-zentive-gray-medium text-base px-10'>
                                            Name
                                        </TableHead>
                                        <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                            Description
                                        </TableHead>
                                        <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                            Type
                                        </TableHead>
                                        <TableHead className='font-semibold text-zentive-gray-medium text-base text-center'>
                                            Actions
                                        </TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {isLoading && <AppSkeletonLoadingState />}
                                    {!isLoading &&
                                        services?.map((data: ProductServiceType) => (
                                            <TableRow key={data.name} className='whitespace-nowrap'>
                                                <TableCell className='flex-row items-start text-start text-zentive-black text-base px-10'>
                                                    {data.name}
                                                </TableCell>
                                                <TableCell className='flex-row items-start text-start text-zentive-black max-w-[15rem] text-base overflow-hidden text-ellipsis'>
                                                    {data.description}
                                                </TableCell>
                                                <TableCell className='flex-row items-start text-start text-zentive-black text-base capitalize'>
                                                    {data.type}
                                                </TableCell>
                                                <ActionButton service={data} />
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        ) : (
                            <div className='bg-white w-auto rounded-sm py-8'>
                                <div className='mt-4 w-[1260px]'>
                                    {' '}
                                    {/* width basis for empty state */}{' '}
                                </div>
                                <div className='flex justify-center items-center'>
                                    <img
                                        src={emptyPlaceholder}
                                        alt='empty-table-placeholder'
                                        className='my-12 h-60'
                                    />
                                </div>
                                <p className='pb-4 text-center text-zentive-green-dark text-2xl font-semibold'>
                                    {' '}
                                    No Product and Services to Show{' '}
                                </p>
                                <div className='flex justify-center items-center'>
                                    <Button
                                        type='button'
                                        onClick={() =>
                                            navigate(
                                                '/settings/product-services/product-service-add-item',
                                            )
                                        }
                                        className='border  bg-zentive-green-dark text-white px-8 py-5'
                                    >
                                        + Add Item
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </section>
    )
}

export default ProductServices
