import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { Button } from '@/components/Button'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/hooks/useToast'
import { Dispatch, SetStateAction, useState } from 'react'
import { FaRegTrashCan } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import { cn } from '@/utils/helper'
import { useAtom, useAtomValue } from 'jotai'
import { selectedEmployeeTabStatusAtom } from '@/store/employee'
import { BulkUpdateCrewStatusSchema } from '@/api/crew/schema'
import { updateCrewStatus } from '@/api/crew'
import { bulkUpdateCrewStatusAtom } from '@/store/crew'
import DeleteProfileJobRestrictionModal from '@/components/DeleteProfileJobRestrictionModal/indexs'

interface DeleteCrewModalProps {
    onSuccessRestore?: Dispatch<SetStateAction<BulkUpdateCrewStatusSchema>>
    isCrewTable?: boolean
}

const DeleteCrewModal: React.FC<DeleteCrewModalProps> = ({ onSuccessRestore, isCrewTable }) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const navigate = useNavigate()
    const crewsToUpdate = useAtomValue(bulkUpdateCrewStatusAtom)
    const [selected, setSelected] = useAtom(selectedEmployeeTabStatusAtom)
    const [open, setOpen] = useState<boolean>(false)
    const [isDeleteCrewRestrictionModalOpen, setIsDeleteCrewRestrictionModalOpen] =
        useState<boolean>(false)
    const { mutate: deleteCrewsMu, isPending: updateLoading } = useMutation({
        mutationFn: (data: BulkUpdateCrewStatusSchema) => updateCrewStatus(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['crewList'] })
            queryClient.invalidateQueries({ queryKey: ['Notifications'] })
            toast({
                description: `Crew profile has been successfully deleted`,
                variant: 'default',
            })
            navigate('/schedule/crew')
            setSelected('ACT'),
                onSuccessRestore &&
                    onSuccessRestore({
                        crewIds: [],
                    })
        },
        onError: () => {
            setIsDeleteCrewRestrictionModalOpen(true)
        },
    })

    const isDirty = () => {
        if (!crewsToUpdate?.crewIds?.length) return true
        return false
    }

    return (
        <div>
            <AlertDialog onOpenChange={(o) => setOpen(o)} open={open}>
                <AlertDialogTrigger asChild>
                    <Button
                        variant={'ghost'}
                        disabled={isDirty()}
                        className={cn(
                            isCrewTable
                                ? 'border border-zentive-green-dark text-zentive-green-dark font-semibold hover:none px-10'
                                : 'px-14 py-6 my-4 justify-center bg-red-600 border shadow-md text-white hover:none',
                        )}
                    >
                        {selected === 'ACT' && <FaRegTrashCan className='mr-2' />}{' '}
                        {isCrewTable
                            ? selected === 'ACT'
                                ? 'Delete'
                                : 'Restore'
                            : selected === 'ACT'
                              ? 'Delete Crew'
                              : 'Restore Crew'}
                    </Button>
                </AlertDialogTrigger>
                <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-2xl p-0'>
                    <div className='flex gap-5 px-6 pt-10'>
                        <div className='flex justify-center items-center bg-zentive-red-light h-16 w-16 rounded-full'>
                            <HiOutlineExclamationTriangle className='h-6 w-6 mx-5  yx-5 text-red-500' />
                        </div>
                        <div className=' overflow-auto'>
                            <AlertDialogTitle className='text-left font-semibold text-2xl text-gray-900 mt-2.5'>
                                {selected === 'ACT' ? 'Delete Crew' : 'Restore Account'}{' '}
                            </AlertDialogTitle>
                            <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                                {selected === 'ACT'
                                    ? `Deleting a crew will remove all associated data and access.`
                                    : `By restoring these accounts, user will have access to services, content or features associated with the subscription plan.`}
                            </AlertDialogDescription>
                            <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                                {selected === 'ACT'
                                    ? 'Are you sure you want to terminate this account?'
                                    : 'Are you sure you want to restore this account?'}
                            </AlertDialogDescription>
                        </div>
                    </div>
                    <AlertDialogFooter className='mx-0 bg-gray-100 rounded-b-2xl pr-6 pb-4 pt-1.5'>
                        <div className='w-full flex justify-end items-end gap-4'>
                            <AlertDialogCancel
                                onClick={() => {
                                    setOpen(false)
                                }}
                                className='w-97 h-11 text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'
                            >
                                Cancel
                            </AlertDialogCancel>
                            <AlertDialogAction
                                disabled={updateLoading}
                                onClick={() => {
                                    crewsToUpdate && deleteCrewsMu(crewsToUpdate)
                                }}
                                className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-dark/90'
                            >
                                {selected === 'ACT' ? 'Delete' : 'Restore'}
                            </AlertDialogAction>
                        </div>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            <DeleteProfileJobRestrictionModal
                open={isDeleteCrewRestrictionModalOpen}
                setOpen={setIsDeleteCrewRestrictionModalOpen}
                type='crew'
            />
        </div>
    )
}

export default DeleteCrewModal
