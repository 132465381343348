import { TableHeader, TableRow, TableHead, TableBody, TableCell, Table } from '@/components/Table'
import { FaArrowDownLong } from 'react-icons/fa6'
import { RefundType } from '@/api/invoicing/schema'
import { useAtomValue } from 'jotai'
import { businessIdAtom, userAtom } from '@/store/auth'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { getCustomerRefund } from '@/api/invoicing'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'

const tableHeader = ['Invoice Number', 'Date', 'Amount', 'Status']

const RefundTable = () => {
    const user = useAtomValue(userAtom)
    const businessId = useAtomValue(businessIdAtom)
    const navigate = useNavigate()

    if (!businessId) {
        navigate('/customer-portal/select-business')
    }

    const { data: refundList, isLoading } = useQuery<unknown, AxiosError, RefundType[]>({
        queryKey: ['refunds', businessId],
        queryFn: () => getCustomerRefund(businessId, user?.profileId as string),
    })

    return (
        <>
            <Table>
                <TableHeader>
                    <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                        {tableHeader?.map((header) => (
                            <TableHead
                                key={`${header}-head`}
                                className={
                                    'flex-row items-center font-semibold text-zentive-gray-medium text-base'
                                }
                            >
                                <div className='inline-flex gap-[10px]'>
                                    {header}
                                    {header === 'Date' && <FaArrowDownLong />}
                                </div>
                            </TableHead>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {isLoading && <> Loading . . .</>}
                    {!isLoading && (
                        <>
                            {refundList?.map((body) => (
                                <TableRow key={body.refundId} className='whitespace-nowrap'>
                                    <TableCell className='flex-row items-start text-[#191A0A] text-base '>
                                        {body.invoice?.invoiceName}
                                    </TableCell>
                                    <TableCell className='flex-row  items-start   text-[#191A0A] text-base '>
                                        {dayjs(body.createdAt).format('MMMM DD, YYYY')}
                                    </TableCell>
                                    <TableCell className='flex-row items-start text-[#191A0A] text-base '>
                                        ${body.amount.toFixed(2)}
                                    </TableCell>
                                    {body.status === 'succeeded' ? (
                                        <TableCell className='text-zentive-green-dark flex-row items-start text-start text-[16px]'>
                                            Successful
                                        </TableCell>
                                    ) : (
                                        <TableCell className='  flex-row items-start text-start text-[16px]'>
                                            <div className='flex text-zentive-blue'>Pending</div>
                                            <div className='flex text-zentive-gray-medium text-[12px]'>
                                                5-10 days processing
                                            </div>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </>
                    )}
                </TableBody>
            </Table>
            {!isLoading && refundList?.length == 0 && (
                <div className='mx-4 my-10'>
                    <div className='flex justify-center rounded-md bg-white'>
                        <img
                            src={emptyPlaceholder}
                            alt='empty-table-placeholder'
                            className='my-8 h-60'
                        />
                    </div>
                    <p className='pb-4 text-center text-2xl text-black mt-4'>
                        {' '}
                        {`Currently Empty`}{' '}
                    </p>
                </div>
            )}
        </>
    )
}
export default RefundTable
