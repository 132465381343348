import { CSVLink } from 'react-csv'
import dayjs from 'dayjs'
import { ExpenseRecordType, ExpenseReportExportType } from '@/api/reports/schema'
import { formatRecordNumber } from '@/utils/helper'
import { US_FORMAT } from '@/constants'
import { useAtomValue } from 'jotai'
import { expenseReportExportAtom } from '@/store/reports'

const ExpenseReportCSV = () => {
    const exportAtom = useAtomValue(expenseReportExportAtom)

    // const selectedTab = useAtomValue(expenseReportTabsAtom)

    if (exportAtom === null || !exportAtom?.records?.length) return null

    const tableData: ExpenseReportExportType | null =
        ((exportAtom &&
            exportAtom?.records &&
            exportAtom?.records.length > 0 && {
                records: exportAtom?.records.map((r) => ({
                    jobNumber: r.jobNumber,
                    jobId: r.jobId,
                    date: r.date,
                    name: r.name,
                    description: r.description,
                    amount: r.amount,
                    expenseId: r.expenseId,
                    note: Array.isArray(r.note) ? r.note : [], // Ensure note is an array of strings
                })),
            }) as ExpenseReportExportType) || null

    if (!tableData) return

    const csvHeaders = ['Job Number', 'Date', 'Name', 'Description', 'Amount']

    const csvData =
        tableData?.records?.map((r: ExpenseRecordType) => ({
            'Job Number': formatRecordNumber(r.jobNumber), // Assuming formatRecordNumber is a function
            Date: dayjs(r.date).format(US_FORMAT), // Ensure date format is as needed for CSV
            Name: r.name,
            Description: r.description,
            Amount: r.amount,
            Notes: r.note.join(', '),
        })) || []

    return (
        <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={'expense-report.csv'}
            className={
                'w-[135px] h-[34px] text-[#191A0A] hover:bg-[#00000029] block px-5 py-1 text-base rounded-b-md'
            }
        >
            Export as CSV
        </CSVLink>
    )
}

export default ExpenseReportCSV
