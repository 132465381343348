import { generateInvoicePdf } from '@/utils/pdf'
import { InvoiceType } from '@/api/invoicing/schema'
import { Button } from '@/components/Button'
import { useAtomValue } from 'jotai'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { FullProfileType } from '@/api/profile/schema'

interface IPrintInvoiceButton {
    invoice: InvoiceType
}

const PrintInvoiceButton = ({ invoice }: IPrintInvoiceButton) => {
    //handle view as owner/admin
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    const handlePrintInvoicePDF = async () => {
        generateInvoicePdf(invoice, user)
    }

    return (
        <Button
            type='button'
            variant={'outline'}
            className='w-full px-8'
            onClick={handlePrintInvoicePDF}
        >
            Print
        </Button>
    )
}
export default PrintInvoiceButton
