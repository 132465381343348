import { CardHeader } from '@/components/Card'
import HeaderClientDetails from './ClientDetails'

interface IAddInvoiceFromJobHeader {
    invoiceNumber: number
}

const AddInvoiceFromJobHeader = ({ invoiceNumber }: IAddInvoiceFromJobHeader) => (
    <CardHeader className='border-b-8 border-zentive-gray-light'>
        <HeaderClientDetails invoiceNumber={invoiceNumber} />
    </CardHeader>
)

export default AddInvoiceFromJobHeader
