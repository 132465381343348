import { Card, CardContent } from '@/components/Card'
import BreadCrumbs from '@/components/BreadCrumbs'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import RefundTable from './RefundTable'

const CPRefund = () => (
    <div className={BREADCRUMBS_PADDING_STYLE}>
        <BreadCrumbs titleName='Refunds' />
        <Card className='mt-5 w-full' style={{ overflowY: 'auto' }}>
            <CardContent className='p-4 pt-[36px]  overflow-x-scroll md:overflow-x-auto'>
                <RefundTable />
            </CardContent>
        </Card>
    </div>
)

export default CPRefund
