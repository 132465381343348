import { TableHead, TableHeader, TableRow } from '@/components/Table'

const tableHeader = ['ITEMS', 'DESCRIPTION', 'DATE', 'AMOUNT']

const ExpenseTableHead = () => {
    return (
        <TableHeader className='bg-white'>
            <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                {tableHeader?.map((header) => (
                    <TableHead className='font-normal text-[#191A0A] text-base' key={header}>
                        {header}
                    </TableHead>
                ))}
            </TableRow>
        </TableHeader>
    )
}

export default ExpenseTableHead
