import { z } from 'zod'
import { baseSchema } from '../common/schema'
import { jobGroupSchema } from '../job/schema'
import { crewSchema } from '../crew/schema'
import { PaginationSchema } from '@/components/Pagination/schema'
import { profileSchema } from '../profile/schema'
import { businessSchema } from '../business/schema'

export const routeSchema = z
    .object({
        routeId: z.string().optional(),
        routeNumber: z.number().optional(),
        businessId: z.string(),
        jobGroupId: z.string().optional(),
        jobGroup: z.array(jobGroupSchema),
        jobDueDate: z.date().optional(),
        crewId: z.string().optional(),
        crew: crewSchema.optional(),
        isSavedPermanently: z.boolean().default(false),
        status: z.string().optional(),
        encodedPolyline: z.string(),
        foremanLat: z.number().optional(),
        foremanLng: z.number().optional(),
        lastLocationUpdate: z.string().optional(),
    })
    .merge(baseSchema.partial())

export const routeListSchema = z.object({
    content: z.array(routeSchema),
    meta: PaginationSchema,
})

export const jobRoutingSchema = z.object({
    businessId: z.string(),
    crewId: z.string(),
    targetDate: z.string(),
})

export const routePolylineSchema = z.object({
    customers: z.array(profileSchema),
    encodedPolyline: z.string(),
})

export const arrangementAndOptimizeSchema = z.object({
    arrangementCount: z.number(),
    optimizeCount: z.number(),
})

export const routeOptimizeSchema = z.object({
    businessId: z.string(),
    profileId: z.string(),
    profile: profileSchema,
    isIncluded: z.boolean().default(true),
    encodedPolyline: z.string().optional(),
})

export const currentPositionSchema = z.object({
    routeId: z.string().optional(),
    foremanLat: z.number(),
    foremanLng: z.number(),
    lastLocationUpdate: z.string().optional(),
})

export const optimizeGroupsSchema = z.object({
    routeOptimizeGroups: z.array(routeOptimizeSchema),
    startingId: z.string(),
})

export const bulkDeleteRoutesSchema = businessSchema.pick({ businessId: true }).merge(
    z.object({
        routeIds: z.array(z.string()),
    }),
)

export type RouteType = z.infer<typeof routeSchema>
export type RouteListType = z.infer<typeof routeListSchema>
export type JobRoutingType = z.infer<typeof jobRoutingSchema>
export type RoutePolylineType = z.infer<typeof routePolylineSchema>
export type RouteParamType = z.infer<typeof routeSchema>
export type ArrangementAndOptimizeType = z.infer<typeof arrangementAndOptimizeSchema>
export type OwnerForemanProfileType = z.infer<typeof profileSchema>
export type RouteOptimizeType = z.infer<typeof routeOptimizeSchema>
export type CurrentPositionType = z.infer<typeof currentPositionSchema>
export type OptimizeGroupsSchemaType = z.infer<typeof optimizeGroupsSchema>
export type BulkDeleteRouteType = z.infer<typeof bulkDeleteRoutesSchema>

export type CustomersRouteByRouteIdType = RoutePolylineType &
    Pick<RouteType, 'routeId' | 'routeNumber' | 'foremanLat' | 'foremanLng'>
