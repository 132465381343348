import { cn } from '@/utils/helper'
import { Menu } from '@headlessui/react'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import ImportEmployeeCSV from './ImportEmployeeCSV'
import EmployeeCSVTemplate from './EmployeeCSVTemplate'

const AddEmployeeDropdown = () => {
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const menuRef = React.useRef<HTMLDivElement>(null)

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const onOpenMenu = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div ref={menuRef} className='relative inline-block text-left'>
            <Menu>
                <Menu.Button
                    className={cn(
                        'bg-zentive-green-dark text-white',
                        'flex justify-center text-center items-center px-4 w-auto h-[43px] gap-x-1.5 rounded-[4px] text=[#3B671A] font-semibold border border-zentive-green-dark text-[16px]',
                    )}
                    onClick={onOpenMenu}
                >
                    Add Employee
                    <FiChevronDown className='-mr-1 h-5 w-5 text-gray-400' aria-hidden='true' />
                </Menu.Button>

                {isOpen ? (
                    <div className='mt-2 w-[150x] h-auto absolute right-0 z-10 origin-top-right rounded-md bg-white shadow-lg focus:outline-none'>
                        <div className='bg-white rounded-md'>
                            <button
                                className={
                                    'w-full h-8 hover:bg-[#00000029] rounded-t-md text-left px-3'
                                }
                                onClick={() => {
                                    setIsOpen(!isOpen)
                                    navigate('/schedule/employee/add')
                                }}
                            >
                                <span className='text-base'> Create </span>
                            </button>
                            <ImportEmployeeCSV />
                            <EmployeeCSVTemplate />
                        </div>
                    </div>
                ) : null}
            </Menu>
        </div>
    )
}

export default AddEmployeeDropdown
