import React, { useEffect, useRef } from 'react'
import { useGoogleMap } from '@react-google-maps/api'

interface AnimatedMarkerProps {
    position: google.maps.LatLngLiteral
}

const AnimatedMarker: React.FC<AnimatedMarkerProps> = ({ position }) => {
    const map = useGoogleMap()
    const markerRef = useRef<HTMLDivElement | null>(null)
    const overlayRef = useRef<google.maps.OverlayView | null>(null)

    useEffect(() => {
        if (map && position && markerRef.current) {
            overlayRef.current = new google.maps.OverlayView()

            const overlay = overlayRef.current

            overlay.onAdd = () => {
                const layer = overlay.getPanes()?.overlayMouseTarget
                if (layer) {
                    layer.appendChild(markerRef.current!)
                }
            }

            overlay.draw = () => {
                const projection = overlay.getProjection()
                if (projection) {
                    const point = projection.fromLatLngToDivPixel(
                        new google.maps.LatLng(position.lat, position.lng),
                    )
                    if (point && markerRef.current) {
                        markerRef.current.style.left = `${point.x}px`
                        markerRef.current.style.top = `${point.y}px`
                    }
                }
            }

            overlay.setMap(map)

            return () => {
                if (overlayRef.current) {
                    //   overlayRef.current.setMap(null) // Remove overlay from the map
                }
            }
        }
    }, [map, position])

    return (
        <div ref={markerRef} className='absolute' style={{ transform: 'translate(-50%, -50%)' }}>
            <div className='relative w-6 h-6'>
                <div className='absolute w-full h-full bg-blue-500 rounded-full opacity-50 animate-ping duration-1200 '></div>
                <div className='absolute top-1/2 left-1/2 w-4 h-4 bg-blue-700 rounded-full transform -translate-x-1/2 -translate-y-1/2'></div>
            </div>
        </div>
    )
}

export default AnimatedMarker
