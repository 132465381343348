import { axiosInstance } from '../axiosInstance'
import { BusinessStatusType, CustomerConnectedBusinessListType } from './schema'

// For setting a profile status only
export const updateProfileStatus = async (
    data: BusinessStatusType,
): Promise<BusinessStatusType> => {
    const response = await axiosInstance.post('/profiles/status', data)

    return response.data.content
}

export const updateContactStatus = async (contactStatus: BusinessStatusType) => {
    const response = await axiosInstance.post('/subscription/contact', contactStatus)

    return response
}

export const getCustomerConnectedBusinesses = async (
    profileId: string | undefined,
): Promise<CustomerConnectedBusinessListType> => {
    if (!profileId) {
        throw new Error('Profile ID not found')
    }

    const response = await axiosInstance.get(
        `/customer/get-connected-businesses?profileId=${profileId}`,
    )

    return response.data.content
}
