import { Button } from '@/components/Button'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useFormContext } from 'react-hook-form'
import { signOut } from '@/api/auth'
import { ResetPasswordType } from '@/api/auth/schema'
import { CustomerCreateNewPassword } from '@/api/profile'
import { createStripeCustomer } from '@/api/auth'
import { useToast } from '@/hooks/useToast'
import { useTranslation } from 'react-i18next'

const ActionButton = () => {
    const { t } = useTranslation(['reset-password'])
    const queryClient = useQueryClient()
    const { handleSubmit } = useFormContext<ResetPasswordType>()
    const { toast } = useToast()

    const createStripeCustomerMu = useMutation({
        mutationFn: () => createStripeCustomer(),
    })

    const { mutate: resetPasswordMu, isPending: isPasswordResetLoading } = useMutation({
        mutationFn: (data: ResetPasswordType) => CustomerCreateNewPassword(data),
        onSuccess: async () => {
            await createStripeCustomerMu.mutateAsync()
            await signOut()
            queryClient.resetQueries({ queryKey: ['isPasswordGenerated'] })
            toast({
                description: 'Successfully Created',
                variant: 'default',
            })
        },
        onError: (err: AxiosError) => {
            return console.log(err)
        },
    })

    const onSubmit = (data: ResetPasswordType) => {
        resetPasswordMu(data)
    }
    return (
        <div className='w-full'>
            <Button
                className='w-full '
                type='submit'
                disabled={isPasswordResetLoading}
                onClick={handleSubmit(onSubmit)}
            >
                {t('Create New Password')}
            </Button>{' '}
            <div
                className='cursor-pointer flex items-center justify-center mt-[24px] w-full text-center text-zentive-blue-dark'
                onClick={() => {
                    signOut()
                }}
            >
                {t('Back to Login')}
            </div>
        </div>
    )
}

export default ActionButton
