// import { Tabs, TabsList, TabsTrigger } from '@/components/Tabs'
// import { cn } from '@/utils/helper'
// import { useState } from 'react'
// import PaymentHistory from './PaymentHistory'
import { userAtom } from '@/store/auth'
import { useAtom } from 'jotai'
import { ROLE } from '@/constants'
import OwnerBillingInfo from './BillingInfo'

const OwnerSubscription = () => {
    const [user] = useAtom(userAtom)
    const isCustomer = user?.role.roleName === ROLE.CUSTOMER

    return <>{!isCustomer && <OwnerBillingInfo />}</>
}

export default OwnerSubscription
