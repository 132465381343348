import BreadCrumbs from '@/components/BreadCrumbs'
import { QuoteReportFilter } from './QuoteReportFilter'
import QuoteReportTable from './QuoteReportTable'

export const QuotesReport = () => {
    return (
        <div className='flex flex-col w-full h-full px-[70px] pt-[50px] gap-5'>
            <BreadCrumbs titleName='Quotes' />
            <QuoteReportFilter />
            <QuoteReportTable />
        </div>
    )
}
