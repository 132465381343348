import CompleteAddress from '@/components/CompleteAddress'

const JobAddress = () => {
    return (
        <CompleteAddress
            className='pt-[36px] h-fit'
            title='Job Address (Optional)'
            titleClassName='text-zentive-gray-medium font-semibold'
        />
    )
}

export default JobAddress
