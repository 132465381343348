import { useQuery } from '@tanstack/react-query'
import NonMailChimpUser from './NonMailChimpUser'
import { FullProfileType, MailChimpIntegrationType } from '@/api/profile/schema'
import { useAtomValue } from 'jotai'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { getMailChimpCredential } from '@/api/profile'
import AppSkeletonLoadingState from '@/components/LoadingState/TableLoadingState'
import LinkedMailChimpCredential from './LinkedMailChimpCredential'

const MailChimpIntegration = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    const { data: mcCreds, isPending } = useQuery<MailChimpIntegrationType>({
        queryKey: ['mcCreds', user?.businessId],
        queryFn: () => getMailChimpCredential(user?.businessId),
    })

    return (
        <div className='bg-white h-full flex flex-col  gap-10'>
            {isPending ? (
                <AppSkeletonLoadingState />
            ) : mcCreds ? (
                <LinkedMailChimpCredential mcCreds={mcCreds} />
            ) : (
                <NonMailChimpUser />
            )}
        </div>
    )
}

export default MailChimpIntegration
