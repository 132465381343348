import { Card, CardContent } from '@/components/Card'
import { EventClickArg } from '@fullcalendar/core/index.js'
import JobDetailsModal from './JobDetailsModal'
import { useEffect, useState } from 'react'
import './index.css'
import CalendarComponent from '../../../../common/Schedule/Job/FullCalendar'

interface CalendarProps {
    isUnassigedToggled?: boolean
    setIsUnassigedToggled?: React.Dispatch<React.SetStateAction<boolean>>
}

const Calendar = ({ isUnassigedToggled, setIsUnassigedToggled }: CalendarProps) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [selectedEvent, setSelectedEvent] = useState<EventClickArg | null>(null)
    const [jobId, setJobId] = useState<string>('')

    // handles onClick event or event div
    const handleEventClick = (clickInfo: EventClickArg) => {
        if (selectedEvent && selectedEvent.event.id === clickInfo.event._def.publicId) {
            setSelectedEvent(null)
            setModalOpen(true)
        } else {
            setSelectedEvent(clickInfo)
            setModalOpen(true)
            setJobId(clickInfo.event.id)
        }
    }
    const handleEventMouseEnter = (arg: EventClickArg) => {
        arg.el.style.cursor = 'pointer'
    }

    const handleEventMouseLeave = (arg: EventClickArg) => {
        arg.el.style.cursor = ''
    }

    // handles the closing of job details modal when clicked outside of the div
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectedEvent && !selectedEvent.el.contains(event.target as Node)) {
                setSelectedEvent(null)
            }
        }

        document.addEventListener('click', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [selectedEvent])

    return (
        <Card className='w-full mt-7'>
            <CardContent className='w-full mt-7'>
                <CalendarComponent
                    handleEventClick={handleEventClick}
                    handleEventMouseEnter={handleEventMouseEnter}
                    handleEventMouseLeave={handleEventMouseLeave}
                    isUnassigedToggled={isUnassigedToggled}
                    setIsUnassigedToggled={setIsUnassigedToggled}
                />
            </CardContent>
            {modalOpen && selectedEvent && (
                <JobDetailsModal clickInfo={selectedEvent} jobId={jobId} />
            )}
        </Card>
    )
}

export default Calendar
