import { lazy } from 'react'
import { Card, CardContent } from '@/components/Card'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { paymentStepAtom } from '@/store/payment'
import { useAtomValue } from 'jotai'
import { Suspense } from 'react'
import { cn } from '@/utils/helper'
import PaymentSteps from './PaymentSteps'
import { Skeleton } from '@/components/Skeleton'

const PaymentInformation = lazy(() => import('./PaymentInformation'))
const SetUpStripe = lazy(() => import('./SetUpStripe'))

const PaymentProcess = () => {
    const currentStep = useAtomValue(paymentStepAtom)

    const stripeKey = import.meta.env.PROD
        ? import.meta.env.VITE_PROD_STRIPE_PUBLISHABLE_KEY
        : import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY

    const stripePromise = loadStripe(stripeKey)

    return (
        <Elements stripe={stripePromise}>
            <Card
                className={cn('w-[556px] relative', currentStep === 1 ? 'h-[669px]' : 'h-[421px]')}
            >
                <CardContent className='p-7 w-full h-full space-y-[36px]'>
                    <PaymentSteps />

                    {currentStep === 1 ? (
                        <Suspense fallback={<Skeleton>Hi</Skeleton>}>
                            <PaymentInformation />
                        </Suspense>
                    ) : (
                        <Suspense fallback={<Skeleton>Hello</Skeleton>}>
                            <SetUpStripe />
                        </Suspense>
                    )}
                </CardContent>
            </Card>
        </Elements>
    )
}

export default PaymentProcess
