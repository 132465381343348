import { Button } from '@/components/Button'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { InputPassword, PasswordCriteria } from '@/components/InputPassword'
import { useUpdateProfile } from '@/hooks/useUpdateProfile'
import { useAtomValue } from 'jotai'
import { isViewedAsAtom } from '@/store/auth'
import { useNavigate } from 'react-router-dom'
import { UpdatePasswordType } from '@/api/auth/schema'

const ChangePasswordForm = () => {
    const { isPasswordChangePending, updatePasswordForm, validatePasswordChange } =
        useUpdateProfile()

    const {
        handleSubmit,
        formState: { isValid },
    } = updatePasswordForm

    const navigate = useNavigate()

    const handleCancelButton = () => {
        navigate('/settings/profile')
    }

    const onSubmit = (data: UpdatePasswordType) => validatePasswordChange(data)

    const isViewedAsSubscriber = useAtomValue(isViewedAsAtom)

    const newPassword = updatePasswordForm.watch('newPassword')

    return (
        <Form {...updatePasswordForm}>
            <form className='h-full w-full items-center bg-white' onSubmit={handleSubmit(onSubmit)}>
                {/* <section className='px-[26px] pt-[35px]'> */}
                <div className='px-[26px] pt-[35px] h-full'>
                    <div className='grid grid-cols-3 gap-[16px]'>
                        <FormField
                            control={updatePasswordForm.control}
                            name='currentPassword'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <InputPassword
                                            placeholder='Current Password'
                                            disabled={isViewedAsSubscriber}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={updatePasswordForm.control}
                            name='newPassword'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <InputPassword
                                            placeholder='New Password'
                                            disabled={isViewedAsSubscriber}
                                            {...field}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={updatePasswordForm.control}
                            name='confirmPassword'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <InputPassword
                                            placeholder='Confirm New Password'
                                            disabled={isViewedAsSubscriber}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>

                    <div className='w-full text-zentive-gray-medium mt-[36px] mb-[16px]'>
                        To enhance the security of your account your password must contain the
                        following:
                    </div>
                    <PasswordCriteria className='w-full' values={newPassword} />
                    {/* </section> */}
                </div>

                <div className='py-5 md:py-0 bg-[#EBEBEB] left-0 w-full md:h-[75px] rounded-b-[8px] flex items-center justify-end px-[26px] gap-[12px]'>
                    <Button
                        className='w-[95px]'
                        onClick={handleCancelButton}
                        variant={'outline'}
                        type='button'
                    >
                        Cancel
                    </Button>

                    <Button
                        className='w-[95px]'
                        disabled={!isValid || isPasswordChangePending}
                        type='submit'
                    >
                        Update
                    </Button>
                </div>
            </form>
        </Form>
    )
}

export default ChangePasswordForm
