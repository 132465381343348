import { createOwnerStripeAccount } from '@/api/auth'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

const useStripeConnect = (id: string | undefined) => {
    const {
        mutate: createOwnerStripeAccountMu,
        isPending,
        isError,
    } = useMutation({
        mutationFn: () => createOwnerStripeAccount(id),
        onSuccess: (data) => {
            window.location.replace(data)
        },
        onError: (err: AxiosError) => console.error(err),
    })

    const handleConnect = () => createOwnerStripeAccountMu()

    return { isError, isPending, handleConnect }
}

export { useStripeConnect }
