import { Table } from '@/components/Table'
import SubsribersTableHead from './TableHead'
import SubsribersTableBody from './TableBody'
import { SubscriberProps } from '..'
import { FC } from 'react'

const SubscribersTableContents: FC<SubscriberProps> = ({ subscriberList }) => {
    return (
        <div className='bg-white overflow-x-auto green-scrollbar'>
            <Table className='table-auto whitespace-normal mx-auto'>
                <SubsribersTableHead subscriberList={subscriberList} />
                <SubsribersTableBody subscriberList={subscriberList} />
            </Table>
        </div>
    )
}

export default SubscribersTableContents
