import { useAtomValue } from 'jotai'
import RequestServiceForm from './RequestForm'
import ServiceList from './ServiceList'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import { businessIdAtom, userAtom } from '@/store/auth'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { RequestServiceType } from '@/api/service/schema'
import { getRequestServices } from '@/api/service'

const CPRequestService = () => {
    const user = useAtomValue(userAtom)
    const businessId = useAtomValue(businessIdAtom)
    const navigate = useNavigate()

    if (!businessId) {
        navigate('/customer-portal/select-business')
    }

    const { data: services, isPending } = useQuery<unknown, AxiosError, RequestServiceType>({
        queryKey: ['RequestServiceType', businessId, user],
        queryFn: () => getRequestServices(businessId),
    })

    if (isPending) {
        return <></>
    }

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <h1 className='text-zentive-gray-medium font-semibold mb-2 text-2xl'>
                Request Service
            </h1>
            <p className='text-zentive-gray-medium'>
                Please Choose from the list of available services. If the service you want is not
                listed, please specify your desired service in your request
            </p>
            <div className='w-full'>
                <div className='flex flex-column mt-10 gap-x-8'>
                    <RequestServiceForm services={services as RequestServiceType} />
                    <ServiceList services={services as RequestServiceType} />
                </div>
            </div>
        </div>
    )
}
export default CPRequestService
