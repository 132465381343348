import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/hooks/useToast'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useEffect } from 'react'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import _ from 'lodash'
import { CreateExpenseAccountType, expenseAccountSchema } from '@/api/expense_account/schema'
import { createExpenseAccount, getNextExpenseAccountNumber } from '@/api/expense_account'
import { Button } from '@/components/Button'
import { Input } from '@/components/Input'

type CreateExpenseAccountProps = {
    businessId: string
    setIsCreateOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const CreateExpenseAccount = ({ businessId, setIsCreateOpen }: CreateExpenseAccountProps) => {
    const { toast } = useToast()
    const queryClient = useQueryClient()
    const updateExpenseAccountMethods = useForm<CreateExpenseAccountType>({
        mode: 'onSubmit',
        resolver: zodResolver(expenseAccountSchema),
    })

    const { data: nextExpenseAccountCode, isLoading } = useQuery({
        queryKey: ['nextExpenseAccountCode', businessId],
        queryFn: () => getNextExpenseAccountNumber(businessId as string),
    })

    const { control, setValue, handleSubmit, watch } = updateExpenseAccountMethods

    const isFormDirty = useCallback(() => {
        const accountName = watch().expenseAccountName || ''
        return accountName.trim() === ''
    }, [watch])

    const { mutate: createExpenseAccountMu, isPending: createLoading } = useMutation({
        mutationFn: (data: CreateExpenseAccountType) => createExpenseAccount(data),
        onSuccess: () => {
            toast({
                description: 'Successfully Saved',
                variant: 'default',
            })
            queryClient.invalidateQueries({ queryKey: ['expenseAccounts'] })
            queryClient.invalidateQueries({ queryKey: ['nextExpenseAccountCode'] })
            setIsCreateOpen(false)
        },
        onError: (err: AxiosError) => {
            const defaultErrMessage = 'Error occurred, please try again later'
            setIsCreateOpen(false)
            if (err.request.status === 409) {
                const responseData = err.response?.data as { message: string } | undefined
                const errorToastMessage = responseData?.message || defaultErrMessage
                toast({
                    description: `${errorToastMessage
                        .charAt(0)
                        .toUpperCase()}${errorToastMessage.slice(1)}`,
                    variant: 'default',
                })
            } else {
                toast({
                    description: defaultErrMessage,
                    variant: 'destructive',
                })
            }
        },
    })

    const handleCancelClick = () => {
        setIsCreateOpen(false)
    }

    useEffect(() => {
        setValue('expenseAccountCode', nextExpenseAccountCode as number)
        setValue('businessId', businessId as string)
    }, [businessId, isLoading])

    const onSubmit = (data: CreateExpenseAccountType) => {
        createExpenseAccountMu(data)
    }

    return (
        <div className='relative w-full px-6 py-2 text-zentive-black'>
            <Form {...updateExpenseAccountMethods}>
                <form className='flex flex-row w-full items-center justify-center text-zentive-black text-base space-x-16 mr-12 transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted whitespace-nowrap'>
                    <div>
                        <FormField
                            control={control}
                            name='expenseAccountCode'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input
                                            className='font-sans -mr-4 pl-3 text-base h-11 border w-[150px] max-w-[400px] text-gray-900 disabled:bg-gray-100 rounded-sm'
                                            type='number'
                                            placeholder='Account Code'
                                            disabled
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div>
                        <FormField
                            control={control}
                            name='expenseAccountName'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input
                                            className='font-sans pl-3 text-base bg-white h-11 w-[300px] max-w-[800px] m-full border text-gray-900 rounded-sm'
                                            type='text'
                                            placeholder='Account Name*'
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className=' flex flex-row gap-x-16'>
                        <Button
                            variant='secondary'
                            onClick={() => handleCancelClick()}
                            className='w-28 h-11 -mr-12 text-base font-semibold text-zentive-green-dark ring-zentive-green-dark border border-zentive-green-dark'
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={createLoading || isFormDirty()}
                            onClick={handleSubmit(onSubmit)}
                            className='w-28 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            Save
                        </Button>
                    </div>
                </form>
            </Form>
        </div>
    )
}

export default CreateExpenseAccount
