import NameFields from './Names'
import ContactFields from './Contact'
import OtherFields from './Others'

const IdentityFields = () => {
    return (
        <>
            <NameFields />
            <ContactFields />
            <OtherFields />
        </>
    )
}
export default IdentityFields
