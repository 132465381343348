import { Bar } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { useAtomValue } from 'jotai'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useQuery } from '@tanstack/react-query'
import { DashboardRevenueGrossNetMonthlyChartType } from '@/api/reports/schema'
import { getDashboardMonthlyIncomeChart } from '@/api/reports'
import Spinner from '@/components/Spinner'
import { useEffect, useRef } from 'react'

// Register the required components from Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const GrossNetRevenueChart = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const chartRef = useRef<ChartJS<'bar', (number | undefined)[]> | null>(null)

    const { data: incomeChartReport, isLoading: incomeChartLoading } =
        useQuery<DashboardRevenueGrossNetMonthlyChartType>({
            queryKey: ['incomeChartReport', user],
            queryFn: () => getDashboardMonthlyIncomeChart(user?.businessId ?? ''),
        })

    const data = [
        {
            month: 'January',
            revenue: incomeChartReport?.january?.revenue,
            gross: incomeChartReport?.january?.gross,
            net: incomeChartReport?.january?.net,
        },
        {
            month: 'February',
            revenue: incomeChartReport?.february?.revenue,
            gross: incomeChartReport?.february?.gross,
            net: incomeChartReport?.february?.net,
        },
        {
            month: 'March',
            revenue: incomeChartReport?.march?.revenue,
            gross: incomeChartReport?.march?.gross,
            net: incomeChartReport?.march?.net,
        },
        {
            month: 'April',
            revenue: incomeChartReport?.april?.revenue,
            gross: incomeChartReport?.april?.gross,
            net: incomeChartReport?.april?.net,
        },
        {
            month: 'May',
            revenue: incomeChartReport?.may?.revenue,
            gross: incomeChartReport?.may?.gross,
            net: incomeChartReport?.may?.net,
        },
        {
            month: 'June',
            revenue: incomeChartReport?.june?.revenue,
            gross: incomeChartReport?.june?.gross,
            net: incomeChartReport?.june?.net,
        },
        {
            month: 'July',
            revenue: incomeChartReport?.july?.revenue,
            gross: incomeChartReport?.july?.gross,
            net: incomeChartReport?.july?.net,
        },
        {
            month: 'August',
            revenue: incomeChartReport?.august?.revenue,
            gross: incomeChartReport?.august?.gross,
            net: incomeChartReport?.august?.net,
        },
        {
            month: 'September',
            revenue: incomeChartReport?.september?.revenue,
            gross: incomeChartReport?.september?.gross,
            net: incomeChartReport?.september?.net,
        },
        {
            month: 'October',
            revenue: incomeChartReport?.october?.revenue,
            gross: incomeChartReport?.october?.gross,
            net: incomeChartReport?.october?.net,
        },
        {
            month: 'November',
            revenue: incomeChartReport?.november?.revenue,
            gross: incomeChartReport?.november?.gross,
            net: incomeChartReport?.november?.net,
        },
        {
            month: 'December',
            revenue: incomeChartReport?.december?.revenue,
            gross: incomeChartReport?.december?.gross,
            net: incomeChartReport?.december?.net,
        },
    ]

    const chartData = {
        labels: data?.map((row) => row.month),
        datasets: [
            {
                label: 'Revenue',
                data: data?.map((row) => row.revenue),
                backgroundColor: '#4DB8FF',
                borderColor: '#4DB8FF',
                borderWidth: 1,
            },
            {
                label: 'Net Income',
                data: data?.map((row) => row.net),
                backgroundColor: '#34C73B',
                borderColor: '#34C73B',
                borderWidth: 1,
            },
            {
                label: 'Gross Income',
                data: data?.map((row) => row.gross),
                backgroundColor: '#769DF5',
                borderColor: '#769DF5',
                borderWidth: 1,
            },
        ],
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Revenue, Gross and Net Income',
            },
        },
    }

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.destroy()
        }
    }, [])

    return (
        <div className='w-full h-full bg-white p-5'>
            {incomeChartLoading ? (
                <Spinner variant={'normal'} className='h-[70px] w-[70px]' />
            ) : (
                <Bar
                    data={chartData}
                    options={options}
                    className='max-h-[21rem]'
                    id='grossnet'
                    ref={chartRef}
                />
            )}
        </div>
    )
}

export default GrossNetRevenueChart
