import { BulkProfileUpdateStatusType, FullProfileType } from '@/api/profile/schema'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { Button } from '@/components/Button'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { bulkProfileStatusUpdate } from '@/api/profile'
import { useToast } from '@/hooks/useToast'
import { Dispatch, SetStateAction, useState } from 'react'
import { FaRegTrashCan } from 'react-icons/fa6'
import { USER_STATUS } from '@/constants'
import { useAtomValue } from 'jotai'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'

interface RestoreModalProps {
    profilesToUpdate: BulkProfileUpdateStatusType
    onSuccessRestore: Dispatch<SetStateAction<BulkProfileUpdateStatusType>>
}

const DeleteModal: React.FC<RestoreModalProps> = ({ profilesToUpdate, onSuccessRestore }) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const [open, setOpen] = useState<boolean>(false)

    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    profilesToUpdate.status = USER_STATUS.DELETED
    profilesToUpdate.businessId = user?.businessId
    const { mutate: deleteProfilesMu, isPending: updateLoading } = useMutation({
        mutationFn: (data: BulkProfileUpdateStatusType) => bulkProfileStatusUpdate(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['coOwnersList'] })
            toast({
                description: 'Successfully Deleted',
                variant: 'default',
                duration: 2000,
            }),
                onSuccessRestore({
                    profileIds: [],
                })
        },
        onError: () => {
            toast({
                description: 'Error deleting co owner',
                variant: 'destructive',
                duration: 2000,
            })
        },
    })

    const isDirty = () => {
        if (!profilesToUpdate.profileIds.length) return true
        return false
    }

    return (
        <AlertDialog onOpenChange={(o) => setOpen(o)} open={open}>
            <AlertDialogTrigger asChild>
                <Button
                    variant={'ghost'}
                    disabled={isDirty()}
                    className={`border ${
                        isDirty()
                            ? 'border-zentive-gray-medium'
                            : 'border-zentive-red-dark text-white bg-zentive-red-dark hover:text-zentive-red-dark hover:bg-white'
                    }`}
                >
                    <FaRegTrashCan className='mr-2' /> Delete
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-6 pt-10'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-16 w-16 rounded-full'>
                        <HiOutlineExclamationTriangle className='h-6 w-6 mx-5  yx-5 text-red-500' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-2xl text-gray-900 mt-2.5'>
                            Delete Co Owner Account{' '}
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            This action is irreversible and will remove all user's account
                            information, settings, and data associated with user's account.
                        </AlertDialogDescription>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            Are you sure you want to delete this account?
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-0 bg-gray-100 rounded-b-[15px] pr-6 pb-4 pt-1.5'>
                    <div className='w-full flex justify-end items-end gap-4'>
                        <AlertDialogCancel className='w-97 h-11 text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            disabled={updateLoading}
                            onClick={() => {
                                deleteProfilesMu(profilesToUpdate)
                            }}
                            className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-dark/90'
                        >
                            Delete
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default DeleteModal
