import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'

const EmptySubscriberList = () => (
    <div className='bg-white w-auto rounded-sm'>
        <div className='mt-4 w-[1260px]' />
        <div className='flex justify-center'>
            <img src={emptyPlaceholder} alt='empty-table-placeholder' className='my-12 h-60' />
        </div>
        <p className='pb-4 text-center text-2xl text-gray-900'> Currently Empty </p>
    </div>
)

export default EmptySubscriberList
