import { NavLink, useNavigate } from 'react-router-dom'
import emailLogo from '@/assets/public/email.svg'
import { useMutation } from '@tanstack/react-query'
import { crewForgotPassword } from '@/api/auth'
import { useToast } from '@/hooks/useToast'
import { useEffect, useState } from 'react'
import { decryptString } from '@/utils/crypto'
import { useResendTimer } from '@/hooks/useResendTimer'
import '../index.css'
import { useTranslation } from 'react-i18next'

const CrewResetPasswordVerification = () => {
    const { t, i18n } = useTranslation(['forgot-password'])
    const { displayText, isTimerActive, triggerCountdown } = useResendTimer()
    const { toast } = useToast()

    const searchParam = new URLSearchParams(window.location.search)
    const emailParams = searchParam.get('email')
    const [decryptedEmail, setDecryptedEmail] = useState<string>(emailParams ?? '')

    const navigate = useNavigate()

    const { mutate: resetPasswordMu, isPending: isPasswordResetLoading } = useMutation({
        mutationFn: () => crewForgotPassword({ email: decryptedEmail, lang: i18n.language }),
        onSuccess: () => {
            toast({
                description: 'Reset password link sent',
                variant: 'default',
            })

            triggerCountdown()
        },
        onError: () => {
            toast({
                description: 'There was an error resending.',
                variant: 'default',
            })
        },
    })

    useEffect(() => {
        emailParams && setDecryptedEmail(decryptString(emailParams))
        if (!emailParams) {
            navigate('/')
        }
    }, [searchParam])

    const onResendClick = () => {
        resetPasswordMu()
    }

    return (
        <div className='crew-bg flex flex-col justify-center items-center z-0'>
            <div className='bg-white flex flex-col justify-center items-center space-y-2 rounded-md py-10'>
                <img
                    src={emailLogo}
                    alt='Email Logo'
                    className='h-[60px] w-[180px] cursor-pointer my-4'
                />
                <h1 className='w-64 md:w-100 text-center text-lg text-zentive-green-dark font-semibold'>
                    {t('Check Your Mailbox')}
                </h1>
                <div className='text-gray-400 w-107 text-left'>
                    <p className='text-center my-4'>
                        {t(`We've sent an reset password email to`)}{' '}
                        <b className='text-zentive-gray-medium'>{decryptedEmail}</b>.
                    </p>
                </div>
                <div className='my-4'>
                    <NavLink to='/sign-in' className='font-bold text-zentive-green-dark '>
                        {' '}
                        {t('Back to Login')}
                    </NavLink>
                </div>
                <div className='flex flex-col'>
                    <p className='text-gray-400 text-center'>
                        {isTimerActive ? (
                            displayText
                        ) : (
                            <>
                                {t(`Didn't get the email?`)}
                                <button
                                    className=' text-zentive-green-dark mx-1'
                                    disabled={isPasswordResetLoading}
                                    onClick={onResendClick}
                                >
                                    {t('Click here to resend it')}
                                </button>
                            </>
                        )}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default CrewResetPasswordVerification
