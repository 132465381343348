import { useFormContext } from 'react-hook-form'

import { AppRichTextBox } from '@/components/Common/AppRichTextbox'
import { EmailServiceParamType, RequestServiceType } from '@/api/service/schema'
import { Dispatch, SetStateAction } from 'react'

interface AppRichTextBoxProps {
    service: RequestServiceType
    setEmptyBox : Dispatch<SetStateAction<boolean>>
}

export const RichTextEditorWrapper = (props: AppRichTextBoxProps) => {
    const { setValue } = useFormContext<EmailServiceParamType>()
    const preBody = `<br><br><p>Customer Details:</p>
                    <p>Name: ${props.service?.firstName} ${props.service?.lastName}</p>
                    <p>Address: ${props.service?.streetAddress} ${props.service?.city}, ${props.service?.state} ${props.service?.zipCode}</p>
                    <p>Email: ${props.service?.email}</p>
                    <p>Number: ${props.service?.phoneNumber}</p>
                    `

    const onBodyChange = (val: string) => {
        console.log(val)
        if (val=="" || val == `"<p></p>"`){
            props.setEmptyBox(true)
        } else {
            props.setEmptyBox(false)
        }

        const modifiedValue = val.replace(/<p><\/p>/g, '<br>').replace(/\\/g, '').replace(/<p\s*style\s*=\s*"text-align:\s*center"\s*><\/p>/g, '<br>').replace(/<p\s*style\s*=\s*"text-align:\s*right"\s*><\/p>/g, '<br>')

        setValue('body', modifiedValue + preBody)
    }

    return (
        <div className='space-y-4 mt-[36px]'>
            <p className='text-zentive-green-dark font-semibold text-[16px] mb-[10px]'>
                Service Request
            </p>
            <AppRichTextBox name='email' placeholder='Please enter your service request here...' onChange={(val) => onBodyChange(val)} />
        </div>
    )
}
