import { CSVLink } from 'react-csv'
import dayjs from 'dayjs'
import {
    JobsCompletedByEmployeeExportType,
    JobsCompletedByEmployeeRecordType,
} from '@/api/reports/schema'
import { displayCurrency, formatRecordNumber } from '@/utils/helper'
import { RECORD_PREFIX, US_FORMAT } from '@/constants'
import { useAtomValue } from 'jotai'
import { jobCompletedByEmployeeExportAtom } from '@/store/reports'
import { formatWorkingHours } from '@/utils/time'

const ExportJobsCompletedByEmployeeCSV = () => {
    const exportAtom = useAtomValue(jobCompletedByEmployeeExportAtom)

    if (exportAtom === null || !exportAtom?.records?.length) return null

    const tableData: JobsCompletedByEmployeeExportType | null =
        ((exportAtom &&
            exportAtom.records &&
            exportAtom.records.length > 0 && {
                records: exportAtom.records.map((r) => ({
                    jobCrewMemberId: r.jobCrewMemberId as string,
                    jobNumber: r.jobNumber as number,
                    jobId: r.jobId as string,
                    employeeName: r.employeeName as string,
                    services: r.services as string[],
                    invoiceAmount: r.invoiceAmount as number,
                    laborCost: r.laborCost as number,
                    date: r.date as string, // Ensure the date is a string if needed
                    address: r.address as string,
                    invoiceStatus: r.invoiceStatus as 'pending' | 'approved' | 'declined',
                    timeSpent: r.timeSpent as number,
                })),
                totalInvoiceAmount: exportAtom.totalInvoiceAmount as number,
                totalLaborCost: exportAtom.totalLaborCost as number,
                totalTimeSpent: exportAtom.totalTimeSpent as number,
            }) as JobsCompletedByEmployeeExportType) || null

    if (!tableData) return

    const csvHeaders = [
        'Job Number',
        'Employee Name',
        'Job Type',
        'Invoice Amount',
        'Labor Cost',
        'Date Completed',
        'Time Spent',
        'Address',
    ]

    const csvData =
        tableData?.records?.map((r: JobsCompletedByEmployeeRecordType) => ({
            'Job Number': RECORD_PREFIX.job + formatRecordNumber(r.jobNumber),
            'Employee Name': r.employeeName,
            'Job Type': r.services.join(','),
            'Invoice Amount': displayCurrency(r.invoiceAmount),
            'Labor Cost': displayCurrency(r.laborCost),
            'Date Completed': dayjs(r.date).format(US_FORMAT),
            'Time Spent': formatWorkingHours(r.timeSpent),
            Address: r.address,
        })) || []

    csvData.push({
        'Job Number': 'TOTAL',
        'Employee Name': '',
        'Job Type': '',
        'Invoice Amount': displayCurrency(exportAtom?.totalInvoiceAmount),
        'Labor Cost': displayCurrency(exportAtom?.totalLaborCost),
        'Date Completed': '',
        'Time Spent': formatWorkingHours(exportAtom?.totalTimeSpent),
        Address: '',
    })

    return (
        <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={'jobs-completed-by-employee-report.csv'}
            className={
                'w-[135px] h-[34px] text-[#191A0A] hover:bg-[#00000029] block px-5 py-1 text-base rounded-b-md'
            }
        >
            Export as CSV
        </CSVLink>
    )
}

export default ExportJobsCompletedByEmployeeCSV
