import { NetGrossExportType } from '@/api/reports/schema'
import { Menu } from '@headlessui/react'
import { useEffect, useRef, useState } from 'react'
import { cn } from '@/utils/helper'
import { FiChevronDown } from 'react-icons/fi'
import ExportGrossAndNetIncomePDF from './ExportGrossAndNetIncomePDF'
import { netGrosstabsATom } from '@/store/reports'
import { useAtomValue } from 'jotai'
import { NET_GROSS_TABS } from '@/constants'
import ExportGrossIncomeCSV from './ExportGrossIncomeCSV'
import ExportNetIncomeCSV from './ExportNetIncomeCSV'

interface IExportDropdown {
    records: NetGrossExportType
    isDisabled: boolean
}

export const ExportButton: React.FC<IExportDropdown> = ({ isDisabled }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const menuRef = useRef<HTMLDivElement>(null)

    const selected = useAtomValue(netGrosstabsATom)

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const onOpenMenu = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div ref={menuRef} className='relative inline-block text-left'>
            <Menu>
                <Menu.Button
                    className={cn(
                        'w-[105px] h-[43px] text-base text-center font-semibold flex justify-center items-center gap-x-1.5 rounded-[4px] ring-2 ring-inset',
                        !isDisabled
                            ? isOpen
                                ? 'bg-white text-black ring-[#00000029]'
                                : 'bg-zentive-green-dark text-white ring-zentive-green-dark'
                            : 'bg-[#00000029] text-black ring-[#00000029] cursor-not-allowed',
                    )}
                    disabled={isDisabled}
                    onClick={onOpenMenu}
                >
                    Export
                    <FiChevronDown className='-mr-1 h-5 w-5 text-gray-400' aria-hidden='true' />
                </Menu.Button>

                {isOpen && (
                    <div className='mt-2 w-[150x] h-auto absolute right-0 z-10 origin-top-right rounded-md bg-white shadow-lg focus:outline-none'>
                        <div className='w-full rounded-md'>
                            <ExportGrossAndNetIncomePDF />
                            {selected === NET_GROSS_TABS.gross ? (
                                <ExportGrossIncomeCSV />
                            ) : (
                                <ExportNetIncomeCSV />
                            )}
                        </div>
                    </div>
                )}
            </Menu>
        </div>
    )
}