import { CardContent, CardTitle } from '@/components/Card'
import ProductAndServicesTable from '@/pages/common/FinancialManagement/ProductsAndServicesTable'
import Expenses from './Expenses'
import BillingAmount from './BillingAmount'
import { QuoteType } from '@/api/quoting/schema'

const ViewQuotingContent = ({ quote }: { quote: QuoteType }) => {
    return (
        <CardContent className='pt-9 pb-20 mx-5'>
            <ProductAndServicesTable quote={quote} />
            <Expenses quote={quote} />
            <BillingAmount quote={quote} isForInvoice={false} />
            <div className='border border-[#EBEBEB] p-6 rounded-md'>
                <CardTitle className='text-[18px] mb-6'>Internal notes</CardTitle>
                <div className='flex w-full font-sans text-base h-24 border border-black disabled:bg-gray-100 text-gray-900 rounded-md p-2'>
                    {quote?.internalNotes}
                </div>
            </div>
        </CardContent>
    )
}

export default ViewQuotingContent
