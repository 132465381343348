import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { getJobById, updateJobStatusCrewInterface } from '@/api/job'
import BackButtonTopBar from '../../Common/BackButtonTopBar'
import { FaCheck } from 'react-icons/fa'
import { ReadJobType, UpdateJobStatusCrewInterfaceType } from '@/api/job/schema'
import ConfirmationModal from '../../Common/ConfirmationModal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useToast } from '@/hooks/useToast'
import { ISO_8601_WITH_UTC_OFFSET, JOB_STATUS } from '@/constants'
import dayjs from 'dayjs'
import CompleteJobModal from '../../Common/CompleteJobModal'
import { userAtom } from '@/store/auth'
import { useAtomValue } from 'jotai'
import Spinner from '@/components/Spinner'
import { isIOS, isMacOs } from 'react-device-detect'
import { formatToUTCWithOffset } from '@/utils/time'

const UpdateJobStatus = () => {
    const { t } = useTranslation(['common'])
    const { toast } = useToast()
    const { jobId } = useParams()
    const queryClient = useQueryClient()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isCompleteJobModalOpen, setIsCompleteJobModalOpen] = useState<boolean>(false)
    const [status, setStatus] = useState<string>('')
    const [title, setTitle] = useState<string>('')
    const currentDate =
        isIOS || isMacOs ? Date.now() : dayjs().set('second', 0).format(ISO_8601_WITH_UTC_OFFSET)
    const user = useAtomValue(userAtom)
    const jobData = queryClient.getQueryData<ReadJobType>(['jobById', jobId])
    const isUserForeman = user?.crewMemberId === jobData?.foreman?.crewMemberId

    const statuses = [
        {
            name: t('Open'),
            value: JOB_STATUS.ACTIVE,
            dotColor: '#707070',
        },
        {
            name: t('In progress'),
            value: JOB_STATUS.IN_PROGRESS,
            dotColor: '#254284',
        },
        {
            name: t('Complete'),
            value: JOB_STATUS.COMPLETED,
            dotColor: '#3B671A',
        },
    ]

    const handleSuccessMessageStatus = (status: string) => {
        switch (status) {
            case JOB_STATUS.ACTIVE:
                return 'open'
            case JOB_STATUS.IN_PROGRESS:
                return 'in progress'
            case JOB_STATUS.COMPLETED:
                return 'completed'
            case JOB_STATUS.CANCELED:
                return 'canceled'
            default:
                return 'open'
        }
    }

    const { data: job } = useQuery({
        queryKey: ['jobById', jobId],
        queryFn: () => getJobById(jobId ?? ''),
    })

    const customerTimezone = job?.address?.timezone || 'Asia/Singapore'

    const { mutate: updateJobStatusCrewInterfaceMu, isPending } = useMutation({
        mutationFn: (data: UpdateJobStatusCrewInterfaceType) => updateJobStatusCrewInterface(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['jobById', job?.jobId] })
            queryClient.invalidateQueries({ queryKey: ['messagesList'] })
            setIsOpen(false)
            toast({
                description: t(
                    `Crew status has been marked as ${handleSuccessMessageStatus(
                        status,
                    )} successfully`,
                ),
                variant: 'crew_interface',
            })
        },
        onError: () =>
            toast({
                description: t(
                    `In order to end this job, the other crew members' time tracking must not be on paused.`,
                ),
                variant: 'crew_interface_destructive',
            }),
    })

    const handleUpdateStatus = (status: string) => {
        try {
            const storedJobID = localStorage.getItem('storedJobID')

            if (!storedJobID) {
                if (status !== job?.status && !isPending) {
                    updateJobStatusCrewInterfaceMu({
                        jobId: job?.jobId as string,
                        status: status,
                        dtrTime: formatToUTCWithOffset(
                            currentDate,
                            customerTimezone,
                            ISO_8601_WITH_UTC_OFFSET,
                        ),
                    })

                    localStorage.setItem('utcStoredTime', new Date().toISOString())
                    localStorage.setItem('storedJobID', job?.jobId?.toString() || '')
                }
            } else {
                if (storedJobID !== job?.jobId?.toString()) {
                    if (status !== job?.status && !isPending) {
                        updateJobStatusCrewInterfaceMu({
                            jobId: job?.jobId as string,
                            status: status,
                            dtrTime: formatToUTCWithOffset(
                                currentDate,
                                customerTimezone,
                                ISO_8601_WITH_UTC_OFFSET,
                            ),
                        })

                        localStorage.setItem('utcStoredTime', new Date().toISOString())
                        localStorage.setItem('storedJobID', job?.jobId?.toString() || '')
                    }
                }
            }

            const storedUTC = localStorage.getItem('utcStoredTime')

            if (storedUTC) {
                const currentUTC = new Date().toISOString()

                const storedDate = new Date(storedUTC)
                const currentUserDate = new Date(currentUTC)
                const storedMinutes = storedDate.getUTCMinutes()
                const currentMinutes = currentUserDate.getUTCMinutes()
                if (storedMinutes !== currentMinutes) {
                    if (status !== job?.status && !isPending) {
                        updateJobStatusCrewInterfaceMu({
                            jobId: job?.jobId as string,
                            status: status,
                            dtrTime: formatToUTCWithOffset(
                                currentDate,
                                customerTimezone,
                                ISO_8601_WITH_UTC_OFFSET,
                            ),
                        })

                        localStorage.setItem('utcStoredTime', new Date().toISOString())
                    }
                } else {
                    throw new Error('Start, pause and end cannot be the same time')
                }
            } else {
                if (status !== job?.status && !isPending) {
                    updateJobStatusCrewInterfaceMu({
                        jobId: job?.jobId as string,
                        status: status,
                        dtrTime: formatToUTCWithOffset(
                            currentDate,
                            customerTimezone,
                            ISO_8601_WITH_UTC_OFFSET,
                        ),
                    })

                    localStorage.setItem('utcStoredTime', new Date().toISOString())
                }
            }

            setIsOpen(false)
            setIsCompleteJobModalOpen(false)
        } catch (error) {
            toast({
                description: t('Start, pause and end time must not be in the same time'),
                variant: 'crew_interface_destructive',
                duration: 2000,
            }),
                setIsOpen(false)
            setIsCompleteJobModalOpen(false)
        }
    }

    const handleStatusClick = (value: string, name: string) => {
        if (
            value !== job?.status &&
            value !== JOB_STATUS.ACTIVE &&
            job?.status !== JOB_STATUS.COMPLETED &&
            job?.status !== JOB_STATUS.CANCELED
        ) {
            if (job?.status === JOB_STATUS.IN_PROGRESS && isUserForeman) {
                setIsCompleteJobModalOpen(true)
            } else {
                setIsOpen(true)
            }

            setStatus(value)
            setTitle(name)
        }
    }

    if (!job)
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-white'>
                <Spinner variant='normal' size='10' />
            </div>
        )

    return (
        <div className='flex flex-col'>
            <BackButtonTopBar title={t('Crew Progress')} />
            <div className='w-10/12 mx-auto bg-white rounded-md shadow-md border border-gray-300'>
                <div className='flex flex-col space-y-1'>
                    {statuses?.map((status, index) => {
                        const isDisabled =
                            (job?.status === 'IN_PROGRESS' && status.value === 'ACT') ||
                            (job?.status === 'COMPLETED' &&
                                (status.value === 'ACT' || status.value === 'IN_PROGRESS'))
                        return (
                            <div
                                key={index}
                                className={`w-full flex flex-row justify-between border-b py-3 ${
                                    isDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                                }`}
                                role={isDisabled ? undefined : 'button'}
                                onClick={
                                    !isDisabled
                                        ? () => handleStatusClick(status.value, status.name)
                                        : undefined
                                }
                            >
                                <div className='flex flex-row'>
                                    <p
                                        className={`flex items-center px-4 rounded-full text-sm pl-3 ${
                                            isDisabled ? 'text-gray-500' : ''
                                        }`}
                                    >
                                        <span
                                            className='w-2 h-2 mr-1 rounded-full inline-block'
                                            style={{
                                                backgroundColor: status.dotColor,
                                            }}
                                        ></span>
                                        {status.name}
                                    </p>
                                </div>
                                {job?.status === status.value && (
                                    <div className='pr-3'>
                                        <FaCheck className='text-zentive-green-dark ml-auto' />
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
            <ConfirmationModal
                isOpen={isOpen}
                title={t(`Are you sure you want to mark this job as`) + ' ' + t(title) + '?'}
                text={t(`Mark as`) + ' ' + t(title)}
                onClose={() => setIsOpen(false)}
                onProceed={() => handleUpdateStatus(status)}
            />
            <CompleteJobModal
                isOpen={isCompleteJobModalOpen}
                title={t(`Are you sure you want to mark this job as`) + ' ' + t(title) + '?'}
                text={t(`Mark as`) + ' ' + t(title)}
                onClose={() => setIsCompleteJobModalOpen(false)}
                onProceed={() => handleUpdateStatus(status)}
                job={job}
            />
        </div>
    )
}

export default UpdateJobStatus
