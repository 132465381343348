import { AGREEMENT_SECTIONS } from "@/assets/static/privacyDocument"

const ServiceAgreement = () => {
    return (
        <div className='flex justify-center items-center z-0 crew-sign-in-bg relative'>
            <div className='flex flex-col justify-center sign-in-overlay w-full mx-28 my-20 h-full overflow-y-scroll'>
                <h1 className='text-zentive-green-dark text-center uppercase font-semibold text-lg'>
                    Service Agreement
                </h1>
                <ol className='list-decimal list-inside mt-4 text-left w-full px-8'>
                    {AGREEMENT_SECTIONS?.map((section, index) => (
                        <li key={index} className='flex flex-col mb-4'>
                            <div className='flex items-center text-zentive-gray-medium mb-2'>
                                <h2 className='font-semibold'>{section.title}</h2>
                            </div>
                            <p className='text-zentive-black'>
                                {section.content}
                            </p>
                        </li>
                    ))}
                </ol>
                {/* Move the link slightly to the right */}
                <a href="https://zentive.io/terms-of-service/" 
                   target="_blank" 
                   className="text-blue-800 mt-6 mb-2 hover:underline"
                   style={{ marginLeft: '35px' }}>
                   Click to view the latest version of Zentive's Service Agreement
                </a>
                {/* Move the revision text slightly to the right as well */}
                <span className="text-left block text-zentive-gray-medium mt-2 opacity-75"
                      style={{ marginLeft: '35px' }}>
                    Revision [3.12.24]
                </span>
            </div>
        </div>
    )
}

export default ServiceAgreement
