import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { Dispatch, SetStateAction } from 'react'
import { Button } from '@/components/Button'
import { Modal } from '@/components/Modal'

interface DeleteQuoteModalProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    quotesToDelete: number
}

const DeleteQuoteModal: React.FC<DeleteQuoteModalProps> = ({ open, setOpen, quotesToDelete }) => {
    return (
        <Modal
            isOpen={open}
            isHideCloseButton
            onClose={() => {
                setOpen(false)
            }}
            title=''
            titleClassName=''
            containerClassName='max-w-[600px]'
        >
            <div className=''>
                <div className='flex gap-5 px-10'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-16 w-16 rounded-full'>
                        <HiOutlineExclamationTriangle className='h-6 w-6 mx-5  yx-5 text-red-500' />
                    </div>
                    <div className=' overflow-auto'>
                        <h1 className='text-left font-semibold text-2xl text-gray-900 mt-2.5 capitalize'>
                            Cannot Remove Quoting
                        </h1>
                        <p className='text-left text-sm text-gray-500 mt-5'>
                            {quotesToDelete > 1
                                ? ' One of the quoting youre trying to delete has job exisiting!'
                                : 'The quote youre trying to delete has an existing Job!'}
                        </p>
                    </div>
                </div>
                <div className='mt-6 flex justify-end gap-x-4 bg-gray-300 py-6 px-6'>
                    <Button
                        onClick={() => setOpen(false)}
                        className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium cursor-pointer'
                    >
                        Got it, Thanks
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteQuoteModal
