import { Form } from '@/components/Forms'
import { useToast } from '@/hooks/useToast'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { crewListAtom, crewMemberProfileIdsAtom, selectedCrewForeManAtom } from '@/store/employee'
import BaseCrew from '../BaseCrew'
import { CrewtoCrewMemberType, UpdateCrewType, updateCrewSchema } from '@/api/crew/schema'
import { getCrewById, updateCrew } from '@/api/crew'
import { extractCrewMemberProfile, getCrewFormValues } from '@/utils/user'
import FullSpinner from '@/components/FullSpinner'

const getProcessedMembers = (members: CrewtoCrewMemberType[]) =>
    members ? members?.map((member) => extractCrewMemberProfile(member)) : []

const UpdateCrew: FC = () => {
    const { crewId } = useParams()
    const navigate = useNavigate()
    const { toast } = useToast()
    
    const setCrewListData = useSetAtom(crewListAtom)
    const setCrewMemberProfileIds = useSetAtom(crewMemberProfileIdsAtom)
    const setSelectedItem = useSetAtom(selectedCrewForeManAtom)
    const queryClient = useQueryClient()

    const updateCrewMethods = useForm<UpdateCrewType>({
        mode: 'onChange',
        resolver: zodResolver(updateCrewSchema),
    })

    const {
        handleSubmit,
        setValue,
        watch,
        formState: { isValid },
    } = updateCrewMethods

    const { data, isSuccess, error, isLoading } = useQuery({
        enabled: !!crewId,
        queryFn: () => getCrewById(crewId),
        queryKey: ['getCrewById', crewId],
    })

    const isUpdated = JSON.stringify(watch()) !== JSON.stringify(getCrewFormValues(data?.content));

    const { mutate: updateCrewMu, isPending } = useMutation({
        mutationFn: updateCrew,
        onSuccess: () => {
            toast({
                description: 'Crew has been successfully updated',
                variant: 'default',
            })
            navigate('/schedule/crew')
            setCrewMemberProfileIds([])
            setCrewListData(null)
            setSelectedItem(null)
            queryClient.invalidateQueries({ queryKey: ['getCrewById'] })
        },
        onError: (error) => {
            console.log('Update crew error:', error)
            toast({
                description: 'There was an error updating this crew profile',
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: UpdateCrewType) => {
        const formData = {
            ...data,
            crewNumber: data.crewNumber,
        }
        updateCrewMu(formData)
    }

    useEffect(() => {
        if (isSuccess && data) {
            setValue('crewId', data?.content?.crewId)
            setValue('businessId', data?.content?.businessId ?? '')
            setValue('foreManId', data?.content?.foreMan?.profileId ?? '')
            setValue('crewNumber', data?.content?.crewNumber)
            setValue('description', data?.content?.description ?? '')
            setValue('crewColor', data?.content?.crewColor ?? '')

            const processedMembers = getProcessedMembers(data?.content?.members)
            const processedMemberProfileIds = processedMembers?.map((member) => member?.profileId)
            const crewListData = {
                content: [
                    ...processedMembers,
                    {
                        ...extractCrewMemberProfile(data?.content?.foreMan),
                    },
                ],
            }

            setCrewListData(crewListData)
            setCrewMemberProfileIds(processedMemberProfileIds)
        }
    }, [data, isSuccess])

    if (isLoading) {
        return <FullSpinner />
    }

    if (error) {
        return <Navigate to='/404' />
    }
    
    return (
        <Form {...updateCrewMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <BaseCrew isUpdated={isUpdated} mode={'update'} isValid={isValid} isSubmitting={isPending} />
            </form>
        </Form>
    )
}

export default UpdateCrew
