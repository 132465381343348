import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import PhoneNumberInput from '@/components/InputNumber'
import { useFormContext } from 'react-hook-form'
import { ActionMode } from '..'
import { USER_STATUS } from '@/constants'

type EmployeeDetailsFieldsProps = {
    mode: ActionMode
}

const UPDATE = 'update'

const EmployeeDetailsFields = ({ mode }: EmployeeDetailsFieldsProps) => {
    const { control, watch } = useFormContext()

    const status = watch('status')

    const isAccountInactive = status === USER_STATUS.INACTIVE

    return (
        <div className='grid grid-cols-3 sm:grid-cols-1 gap-4'>
            <div className='col-span-1'>
                <FormField
                    control={control}
                    name='employeeNumber'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    disabled
                                    placeholder='Employee Number*'
                                    type='text'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    control={control}
                    name='firstName'
                    disabled={isAccountInactive}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input type='text' placeholder='First Name*' {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    control={control}
                    name='lastName'
                    disabled={isAccountInactive}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input type='text' placeholder='Last Name*' {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    control={control}
                    name='email'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    type='text'
                                    placeholder='Email Address*'
                                    disabled={mode === UPDATE}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    control={control}
                    name='phoneNumber'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <PhoneNumberInput
                                    defaultCountry='US'
                                    disabled={isAccountInactive}
                                    inputProps={{
                                        name: 'Contact Number',
                                        placeholder: 'Contact Number*',
                                    }}
                                    smartCaret={true}
                                    limitMaxLength={true}
                                    maxLength={16}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    control={control}
                    name='dateOfBirth'
                    disabled={isAccountInactive}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input type='date' max="9999-12-31" placeholder='Date of Birth (Optional)' {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    control={control}
                    name='caLicense'
                    disabled={isAccountInactive}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    type='text'
                                    placeholder='Chemical Applicator License'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    control={control}
                    name='dateHired'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    type='date'
                                    placeholder='Date Hired*'
                                    disabled={true}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </div>
    )
}
export default EmployeeDetailsFields
