import { deleteBusinessNotes, getBusinessNotes, updateBusinessNotes } from '@/api/notes'
import { NotePayloadType, NoteResponseType } from '@/api/notes/schema'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import { Button } from '@/components/Button'
import { Label } from '@/components/Label'
import UpdateOrDelete from '@/components/NotesModal/UpdateOrDelete'
import { Textarea } from '@/components/TextArea'
import { useToast } from '@/hooks/useToast'
import { manageSubsTabAtom } from '@/store/manageSubs'
import { cn } from '@/utils/helper'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Notes = () => {
    const [currentNote, setNote] = useState<string>('')
    const { toast } = useToast()
    const status = useAtomValue(manageSubsTabAtom)
    const [updateIndex, setUpdateIndex] = useState<number>()
    const [updatedNoteContent, setUpdatedNoteContent] = useState<string>('')

    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const params = new URLSearchParams(window.location.search)
    const profileID = params.get('profileid')

    const { data: notesData, isLoading } = useQuery<unknown, AxiosError, NoteResponseType>({
        queryKey: ['getNotes'],
        queryFn: () => getBusinessNotes(profileID as string),
    })

    // const profileDetailsOnNote = notesData?.data[0] as ProfileNoteResponseType

    const { mutate: updateNotesMu, isPending: updateLoading } = useMutation({
        mutationFn: (newNote: NotePayloadType) => updateBusinessNotes(newNote),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getNotes'] }),
                toast({
                    description: 'Note updated successfully',
                    variant: 'default',
                }),
                setUpdateIndex(undefined)
        },
    })

    const { mutate: deleteNoteMu } = useMutation<unknown, AxiosError, string>({
        mutationFn: (noteId) => deleteBusinessNotes(noteId),
        onSuccess: () => {
            toast({
                description: 'Note deleted successfully',
                variant: 'default',
            })
        },
    })

    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: ['getNotes'] })
    }, [params])

    useEffect(() => {
        setNote('')
        updateIndex !== undefined &&
            notesData?.data[0]?.notes &&
            notesData.data[0].notes[updateIndex]?.note &&
            setNote(notesData.data[0].notes[updateIndex].note)
    }, [updateIndex, notesData])

    return (
        <section>
            {!isLoading && (
                <div
                    className={cn(
                        !notesData?.data[0]?.notes?.length ? '' : 'flex',
                        'bg-white w-full ',
                    )}
                >
                    {status === 'TER' ? (
                        <div className='w-full m-8'>
                            {notesData?.data[0]?.notes?.map((singleNote) => (
                                <div className='w-full p-4' key={singleNote.noteId}>
                                    <div className='rounded-[4px] bg-[#EBEBEB]'>
                                        <Label className='mr-5 pt-3 flex justify-end text-sm text-zentive-gray-medium'>
                                            {dayjs(singleNote.createdAt).format(
                                                'MMMM DD, YYYY hh:mm A',
                                            )}
                                        </Label>
                                        <Label className='p-3 h-28 w-full flex text-[16px] text-[#191A0A]'>
                                            {singleNote.note}
                                        </Label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <>
                            {!notesData?.data[0]?.notes?.length && (
                                <div className='py-12 flex justify-center text-center'>
                                    <div>
                                        <img
                                            src={emptyPlaceholder}
                                            alt='email-logo'
                                            className=' w-full mb-6 h-48'
                                        />
                                        <Label className='text-2xl text-gray-900'>
                                            Currently Empty
                                        </Label>
                                    </div>
                                </div>
                            )}
                            <div className='w-full mx-[25px] mt-5 mb-[16px]'>
                                {!!notesData?.data[0]?.notes?.length &&
                                    notesData?.data[0]?.notes?.map((singleNote, index) => (
                                        <div
                                            className='w-full'
                                            key={singleNote.noteId}
                                            onClick={() => {
                                                setUpdateIndex(index)
                                            }}
                                        >
                                            <div
                                                className={cn(
                                                    index === updateIndex
                                                        ? 'ring-2 ring-[#3a671a]'
                                                        : 'ring-1 ring-zentive-gray-medium',
                                                    'mt-3 p-1 rounded-[4px]',
                                                )}
                                            >
                                                <div className='pt-2 mr-4 flex justify-end'>
                                                    <UpdateOrDelete
                                                        deleteNote={() => {
                                                            deleteNoteMu(singleNote?.noteId ?? '')
                                                        }}
                                                        profileId={profileID ?? ''}
                                                    />
                                                </div>
                                                <Label className='mr-10 pt-3 flex justify-end text-sm text-zentive-gray-medium'>
                                                    {dayjs(singleNote.createdAt).format(
                                                        'MMMM DD, YYYY hh:mm A',
                                                    )}
                                                </Label>
                                                <Textarea
                                                    className='border-white min-h-[85px] text-zentive-black text-[16px] focus-visible:ring-0 focus-visible:ring-offset-0'
                                                    name='notes'
                                                    value={
                                                        index === updateIndex
                                                            ? currentNote
                                                            : singleNote.note
                                                    }
                                                    onChange={(event) => {
                                                        const inputVal = event.target.value
                                                        if (inputVal?.length <= 1000) {
                                                            setUpdatedNoteContent(inputVal)
                                                            setNote(inputVal)
                                                        } else {
                                                            // Limit the input value to 200 characters only
                                                            const truncatedVal = inputVal.slice(
                                                                0,
                                                                1000,
                                                            )
                                                            setUpdatedNoteContent(truncatedVal)
                                                            setNote(truncatedVal)
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <p className='flex justify-end text-[12px] pt-1 text-zentive-gray-medium'>
                                                {index === updateIndex
                                                    ? `${currentNote?.length}/1000`
                                                    : singleNote?.note?.length === undefined
                                                      ? `0/1000`
                                                      : `${singleNote.note?.length}/1000`}
                                            </p>
                                        </div>
                                    ))}
                            </div>
                        </>
                    )}
                </div>
            )}
            {status !== 'TER' && (
                <div className='flex justify-end bg-[#EBEBEB] rounded-b-[15px]'>
                    <Button
                        onClick={() => navigate(-1)}
                        className='w-[95px] h-[43px] mr-[16px] mt-[16px] mb-[16px] text-zentive-green-dark bg-[#F9F9F9] rounded-[5px] ring-2 ring-inset ring-zentive-green-dark font-sans font-semibold text-[16px] hover:text-white hover:bg-[#3a671a] '
                    >
                        Cancel
                    </Button>
                    <Button
                        className='w-[95px] h-[43px] mr-6 mt-[16px] mb-[16px] text-white bg-[#3a671a] rounded-[5px] font-sans font-semibold text-[16px] hover:bg-zentive-green-medium'
                        disabled={
                            updateIndex === undefined ||
                            updateIndex === null ||
                            isLoading ||
                            updateLoading
                        }
                        onClick={() => {
                            if (updateIndex !== undefined) {
                                const newNoteData = {
                                    noteId: notesData?.data[0]?.notes[updateIndex].noteId,
                                    note: updatedNoteContent,
                                } as NotePayloadType

                                updateNotesMu(newNoteData)
                            }
                        }}
                    >
                        Update
                    </Button>
                </div>
            )}
        </section>
    )
}

export default Notes
