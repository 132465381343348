import { axiosInstance } from '../axiosInstance'

export const getConnectStatus = async (businessId: string | undefined): Promise<boolean> => {
    // export const getConnectStatus = async (accountId: string | undefined): Promise<boolean> => {
    if (!businessId) {
        throw new Error('Missing Business ID')
    }

    const res = await axiosInstance.get(`/stripe/connect-status/${businessId}`)

    return res.data.content
}
