import { SignUpType } from '@/api/auth/schema'
import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { useFormContext } from 'react-hook-form'

const ContactFields = () => {
    const { control } = useFormContext<SignUpType>()
    return (
        <div className='grid grid-cols-2 gap-4'>
            <div className='col-span-1'>
                <FormField
                    name='data.lastName'
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input placeholder='Last Name*' type='text' {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    name='email'
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input placeholder='Email Address*' type='text' {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </div>
    )
}
export default ContactFields
