import { useFormContext } from 'react-hook-form'
import { FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { manageSubsTabAtom } from '@/store/manageSubs'
import { useAtomValue } from 'jotai'
import { BillingInfoType } from '@/api/profile/schema'
import PhoneNumberInput from '@/components/InputNumber'

const BusinessEditField = () => {
    const { control, watch } = useFormContext<BillingInfoType>()
    const status = useAtomValue(manageSubsTabAtom)

    const formValues = watch()

    return (
        <div className='w-full h-full flex flex-row flex-wrap gap-[16px]'>
            <FormField
                control={control}
                defaultValue={formValues?.firstName}
                name='firstName'
                render={({ field }) => (
                    <FormItem>
                        <FormControl>
                            <Input
                                className='font-sans pl-3 text-base h-11 w-[300px] border border-black text-zentive-black disabled:bg-text-zentive-gray-light rounded-sm'
                                disabled={status === 'TER'}
                                placeholder='First Name*'
                                type='text'
                                {...field}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <FormField
                control={control}
                defaultValue={formValues?.lastName}
                name='lastName'
                render={({ field }) => (
                    <FormItem>
                        <FormControl>
                            <Input
                                className='font-sans pl-3 text-base h-11 w-[300px] border border-black text-zentive-black disabled:bg-zentive-gray-light rounded-sm'
                                disabled={status === 'TER'}
                                placeholder='Last Name*'
                                type='text'
                                {...field}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <FormField
                control={control}
                defaultValue={formValues?.email}
                name='email'
                render={({ field }) => (
                    <FormItem>
                        <FormControl>
                            <Input
                                className='font-sans pl-3 text-base h-11 w-[300px] border border-black text-zentive-black disabled:bg-zentive-gray-light rounded-sm'
                                disabled={true}
                                placeholder='Email Address*'
                                type='text'
                                {...field}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />

            <FormField
                control={control}
                defaultValue={formValues?.contactNumber}
                name='contactNumber'
                render={({ field }) => (
                    <FormItem>
                        <FormControl>
                            <PhoneNumberInput
                                className='font-sans pl-3 text-base h-11 w-[300px] border border-black text-zentive-black disabled:bg-zentive-gray-light rounded-sm'
                                defaultCountry='US'
                                inputProps={{
                                    name: 'Contact Number',
                                    placeholder: 'Contact Number*',
                                }}
                                smartCaret={true}
                                limitMaxLength={true}
                                maxLength={16}
                                {...field}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
        </div>
    )
}

export default BusinessEditField
