import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { Button } from '@/components/Button'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { useMutation } from '@tanstack/react-query'
import { Dispatch, SetStateAction, useState } from 'react'
import { FaRegTrashCan } from 'react-icons/fa6'
import { BulkDeleteQuoteType } from '@/api/quoting/schema'
import { deleteQuote } from '@/api/quoting'
import DeleteQuoteModal from './DeleteQuoteModal/indexs'
import { useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/hooks/useToast'

interface DeleteModalProps {
    quotesToUpdate: BulkDeleteQuoteType
    setOpen: Dispatch<SetStateAction<boolean>>
}

const DeleteModal: React.FC<DeleteModalProps> = ({ quotesToUpdate, setOpen }) => {
    const [restrictionModal, setRestrictionModal] = useState<boolean>(false)
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const { mutate: deleteQoutesMu, isPending: updateLoading } = useMutation({
        mutationFn: (data: BulkDeleteQuoteType) => deleteQuote(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['quoteList'] })
            toast({
                description: 'Quote Delete Success',
                variant: 'default',
            })
        },
        onError: () => {
            setRestrictionModal(true)
        },
    })

    const isDirty = () => {
        if (!quotesToUpdate.quoteIds?.length) return true
        return false
    }

    return (
        <AlertDialog onOpenChange={(o) => setOpen(o)}>
            <DeleteQuoteModal
                open={restrictionModal}
                setOpen={setRestrictionModal}
                quotesToDelete={quotesToUpdate.quoteIds?.length}
            />
            <AlertDialogTrigger asChild>
                <Button
                    variant='ghost'
                    disabled={isDirty()}
                    className='text-gray-500 border border-gray-500 font-semibold mr-5'
                >
                    <FaRegTrashCan className='mr-2' /> Delete
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white h-[320px] w-[577px] rounded-2xl p-0'>
                <div className='flex gap-5 px-6 pt-10 mb-5'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-16 w-16 rounded-full'>
                        <HiOutlineExclamationTriangle className='h-6 w-6 mx-5  yx-5 text-red-500' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-2xl text-gray-900 mt-2.5'>
                            Delete Quote
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            This action cannot be undone and will permanently remove the quotation 
                            from your records.
                        </AlertDialogDescription>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            This deletion will also be reflected in the client's Customer Portal, 
                            and the client will no longer be able to view this quotation.
                        </AlertDialogDescription>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            Are you sure you want to delete this quotation?
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-0 bg-gray-100 rounded-b-4 pr-6 pb-4 pt-1.5'>
                    <div className='w-full flex justify-end items-end gap-4'>
                        <AlertDialogCancel className='w-97 h-11 text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            disabled={updateLoading}
                            onClick={() => {
                                deleteQoutesMu(quotesToUpdate)
                            }}
                            className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-dark/90'
                        >
                            Delete
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default DeleteModal
