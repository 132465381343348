import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import GoogleMapsApiWrapper from '@/components/GoogleMapsApiWrapper'
import { Input } from '@/components/Input'
import { InputLocation } from '@/components/InputLocation'
import { Label } from '@/components/Label'
import { USER_STATUS } from '@/constants'
import { FullAddress } from '@/hooks/useGeocode'
import { isAddressSelectedAtom } from '@/store/customer'
import { useAtom } from 'jotai'
import { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

type Fields = 'city' | 'state' | 'zipCode'
type Value = string | undefined

type CustomerLocationFieldsProps = {
    profileStatus?: string
}

const isEditingDefaultVal = {
    city: false,
    state: false,
    zipCode: false,
}

const CustomerLocationFields = ({ profileStatus }: CustomerLocationFieldsProps) => {
    const { control, clearErrors, setValue, watch } = useFormContext()
    const [isAddressSelected, setIsAddressSelected] = useAtom(isAddressSelectedAtom)
    const city = watch('address.city')
    const state = watch('address.state')
    const streetAddress = watch('address.streetAddress')
    const zipCode = watch('address.zipCode')
    const [isEditing, setIsEditing] = useState(isEditingDefaultVal)

    const handlePlaceSelected = async (fullAddress: FullAddress) => {
        setIsAddressSelected(true)
        setIsEditing(isEditingDefaultVal)
        setValue('address.streetAddress', fullAddress.streetAddress, { shouldValidate: true })
        setValue('address.city', fullAddress.city, { shouldValidate: true })
        setValue('address.state', fullAddress.state, { shouldValidate: true })
        setValue('address.zipCode', fullAddress.zipCode, { shouldValidate: true })
    }

    const handleLocationChange = useCallback(
        (field: Fields, value: Value, onChange: (val: Value) => void) => {
            setIsEditing((prev) => ({ ...prev, [field]: true }))
            onChange(value)
        },
        [setIsEditing],
    )
    const isValid = (fieldName: Fields, watchedField: Value) =>
        !isAddressSelected || (isAddressSelected && !isEditing[fieldName] && !!watchedField)

    const isAccountInactive = () => {
        if (profileStatus === USER_STATUS.INACTIVE) return true
        else return false
    }

    useEffect(() => {
        if (streetAddress?.length === 0) {
            setIsAddressSelected(false)
            setValue('address.city', '')
            setValue('address.state', '')
            setValue('address.zipCode', '')
            clearErrors('address.city')
            clearErrors('address.state')
            clearErrors('address.zipCode')
        }
    }, [streetAddress])

    return (
        <div>
            <div className='mt-[14px] text-left'>
                <div className='w-[932px] h-[45px]'>
                    <Label className='font-semibold text-[16px]  text-zentive-green-dark'>
                        Complete Address
                    </Label>
                </div>
            </div>
            <div className='grid grid-cols-3 sm:grid-cols-1 gap-4'>
                <div className='col-span-3 sm:col-span-1'>
                    <FormField
                        control={control}
                        name='address.streetAddress'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <GoogleMapsApiWrapper
                                        render={(isLoaded: boolean, loadError?: Error) => {
                                            if (!isLoaded || loadError) return null

                                            return (
                                                <InputLocation
                                                    {...field}
                                                    autocompleteType='address'
                                                    onPlaceSelected={handlePlaceSelected}
                                                    placeholder='Street 1*'
                                                    disabled={isAccountInactive()}
                                                    isLoaded={isLoaded}
                                                />
                                            )
                                        }}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className='col-span-3 sm:col-span-1'>
                    <FormField
                        control={control}
                        name='address.streetTwoAddress'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        type='text'
                                        placeholder='Street 2 (Optional)'
                                        disabled={isAccountInactive()}
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className='col-span-1'>
                    <FormField
                        control={control}
                        name='address.city'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        {...field}
                                        type='text'
                                        placeholder='City*'
                                        disabled={isValid('city', city) || isAccountInactive()}
                                        onChange={(e) =>
                                            handleLocationChange(
                                                'city',
                                                e.target.value,
                                                field.onChange,
                                            )
                                        }
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className='col-span-1'>
                    <FormField
                        control={control}
                        name='address.state'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        type='text'
                                        placeholder='State*'
                                        {...field}
                                        disabled={isValid('state', state) || isAccountInactive()}
                                        onChange={(e) =>
                                            handleLocationChange(
                                                'state',
                                                e.target.value,
                                                field.onChange,
                                            )
                                        }
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className='col-span-1'>
                    <FormField
                        control={control}
                        name='address.zipCode'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        type='text'
                                        placeholder='Zip Code*'
                                        {...field}
                                        disabled={isValid('zipCode', zipCode)}
                                        onChange={(e) =>
                                            handleLocationChange(
                                                'zipCode',
                                                e.target.value,
                                                field.onChange,
                                            )
                                        }
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </div>
        </div>
    )
}
export default CustomerLocationFields
