import { CardFooter, CardTitle } from '@/components/Card'
import { useState } from 'react'
import AlertReimbursementModal from './Modal'
import { Label } from '@/components/Label'
import { cn } from '@/utils/helper'

const Reimbursement = () => {
    const [amount, setAmount] = useState(0.0)

    const handleOnDiscountChange = (amount: number) => {
        setAmount(amount)
    }

    return (
        <>
            <div className='border border-[#EBEBEB] p-6 mt-16 rounded-md'>
                <CardTitle className='text-[18px] mb-4'>Reimbursement</CardTitle>
                <div className='flex flex-row justify-between items-center w-full font-sans text-bas align-middle text-gray-900 rounded-md p-2'>
                    <div className='flex'>How much would you like to reimburse?</div>
                    <div
                    className = 'flex'
                    >
                        <input
                            className='peer flex h-[45px] rounded-sm border border-zentive-gray-medium placeholder:text-black bg-transparent px-3 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-[#ebebeb] disabled:text-zentive-gray-medium'
                            type='number'
                            onChange={(val) => handleOnDiscountChange(parseFloat(val.target.value))}
                        />
                        <Label
                            className={cn(
                                'floating-label bg-white absolute translate-y-3 translate-x-3 text-base duration-100 peer-focus:-translate-y-2 peer-focus:text-sm peer-focus:px-1',
                                amount !== 0.0
                                ? 'text-zentive-green-dark peer-disabled:opacity-100'
                                : '',

                                amount !== 0
                                ? 'peer-focus:text-zentive-green-dark pointer-events-auto -translate-y-4 text-sm px-1'
                                : 'peer-focus:text-zentive-green-dark text-zentive-gray-medium pointer-events-none',
                                )}
                        >
                            Amount*
                        </Label>
                    </div>
                    
                </div>
            </div>
            <CardFooter className='lg:flex lg:justify-end rounded-b-md bg-gray-200 w-full  p-0 '>
                <div className='flex space-x-4 my-4 mr-8'>
                    <AlertReimbursementModal amount={amount} />
                </div>
            </CardFooter>
        </>
    )
}

export default Reimbursement
