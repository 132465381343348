import { updateQuoteStatus } from '@/api/quoting'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { QUOTE_STATUS } from '@/constants'
import { useToast } from '@/hooks/useToast'
import { useMutation } from '@tanstack/react-query'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { useNavigate } from 'react-router-dom'

interface RespondAlertProps {
    status: string
    quoteId: string | undefined
}

const RespondAlert = ({ status, quoteId }: RespondAlertProps) => {
    const navigate = useNavigate()
    const { toast } = useToast()

    const respondMutation = useMutation({
        mutationKey: ['updateQuoteStatus'],
        mutationFn: (status: string) => updateQuoteStatus(quoteId, status),
        onError: (err) => {
            console.error(err)
        },
        onSuccess: () => {
            toast({
                description: 'Response Sent!',
                variant: 'default',
            })
            navigate('/customer-portal/quotes')
        },
    })

    return (
        <AlertDialog>
            <AlertDialogTrigger className='w-[50%] '>
                {status === QUOTE_STATUS.APPROVED ? (
                    <div className='h-11 px-4 py-2 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 w-full font-semibold text-[16px] hover:bg-zentive-green-medium bg-zentive-green-dark text-primary-foreground shadow rounded-md font-semibold text-base hover:bg-zentive-green-dark/90'>
                        Accept Proposal
                    </div>
                ) : (
                    <div
                        // variant={'outline'}
                        className='h-11 px-4 py-2 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 w-full text-[16px] font-semibold hover:bg-zentive-green-dark hover:text-white border border-zentive-green-dark text-zentive-green-dark bg-background shadow-sm hover:bg-accent hover:text-accent-foreground'
                    >
                        Reject Proposal
                    </div>
                )}
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white h-[240px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-[26.5px] pt-[40px]'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                        <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                    </div>
                    <div className='overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-[24px] text-[#191A0A] mt-[10px]'>
                            {status === QUOTE_STATUS.APPROVED ? 'Approve' : 'Decline'} Quote{' '}
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-[14px] text-zentive-gray-medium mt-[18px]'>
                            Are you sure you want to{' '}
                            {status === QUOTE_STATUS.APPROVED ? 'Approve' : 'Decline'} the quote?
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-[0px] bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[20px] pt-[6px]'>
                    <div className='w-full flex justify-end items-end gap-[16px]'>
                        <AlertDialogCancel className='w-[155px] h-[43px] text-[16px] font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            onClick={() => respondMutation.mutate(status)}
                            className='w-[155px] h-[43px] text-[16px] font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            {status === QUOTE_STATUS.APPROVED ? 'Approve' : 'Decline'}
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
export default RespondAlert
