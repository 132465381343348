import { useQuery } from '@tanstack/react-query'
import goTrueClient from '@/api/goTrueClient'
import { Skeleton } from '@/components/Skeleton'
import { PAYMENT_BTN_STYLE, SKLTN_PAYMENT_BTN_STYLE } from '@/constants'
import { useStripeConnect } from '@/hooks/useStripeConnect'
import { Button } from '@/components/Button'
import { cn } from '@/utils/helper'
import SetUpLaterAlert from './RespondAlert'

const SetUpStripe = () => {
    const { data: authSession, isLoading: isSessionLoading } = useQuery({
        queryKey: ['session'],
        queryFn: () => goTrueClient.getSession(),
        enabled: true,
    })

    const id = authSession?.data.session?.user.id

    const { isError, isPending, handleConnect } = useStripeConnect(id)

    console.log(isSessionLoading, id === undefined, isError)

    return (
        <div className='w-full text-center text-base space-y-[32.34px]'>
            <div className='h-[88px] flex flex-col justify-between'>
                <p>
                    To complete the sign up process, please set up your Stripe Account, click the
                    Set up Stripe Account button below to continue.
                </p>
                <p>Thank you for your cooperation!</p>
            </div>
            <p>
                Already have an account?{' '}
                <span className='cursor-pointer text-zentive-green-dark' onClick={handleConnect}>
                    Sign in here
                </span>
            </p>
            <div className='absolute bottom-0 left-0 h-[75px] w-full px-7 bg-zentive-gray-light flex items-center justify-center'>
                {isSessionLoading || id === undefined || isError ? (
                    <Skeleton className={SKLTN_PAYMENT_BTN_STYLE} />
                ) : (
                    <>
                        <SetUpLaterAlert isPending={isPending} />
                        <Button
                            className={cn(
                                PAYMENT_BTN_STYLE,
                                'w-[243.5px] ml-4 bg-zentive-green-dark hover:bg-zentive-green-medium',
                            )}
                            disabled={isPending}
                            onClick={handleConnect}
                            type='button'
                        >
                            Set Up Stripe Account
                        </Button>
                    </>
                )}
            </div>
        </div>
    )
}

export default SetUpStripe
