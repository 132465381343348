import { convertImageToBase64 } from '@/utils/helper'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import { useAtomValue } from 'jotai'
import { revenueReportExportAtom } from '@/store/reports'
import { RevenueReportExportType } from '@/api/reports/schema'
import { GenerateRevenueReportPDF } from './GenerateRevenueReportPDF'

const RevenueReportPDF = () => {
    const exportAtom = useAtomValue(revenueReportExportAtom)

    if (exportAtom === null || !exportAtom?.records?.length) return null

    const handleExportToPDF = async () => {
        convertImageToBase64(zentiveLogo)
            .then((zentiveLogoUrl) => {
                const tableData: RevenueReportExportType = {
                    records:
                        exportAtom?.records?.map((r) => ({
                            accountNumber: r.accountNumber,
                            businessName: r.businessName,
                            planType: r.planType,
                            billingCycle: r.billingCycle,
                            subStart: r.subStart,
                            subStatus: r.subStatus,
                            totalRevenue: r.totalRevenue,
                        })) ?? [],
                } as RevenueReportExportType

                GenerateRevenueReportPDF(zentiveLogoUrl, tableData)
            })
            .catch((err) => console.error('Error converting image to Base64:', err.message))
    }

    return (
        <button
            className='w-[135px] h-[34px] text-gray-800 hover:bg-[#00000029] block px-4 py-2 text-base rounded-t-md'
            onClick={handleExportToPDF}
        >
            Export as PDF
        </button>
    )
}
export default RevenueReportPDF
