import { CreateSubscriptionRes } from '@/pages/private/OwnerSubscription/BillingInfo/UpdatePlan/ChangePlanConfirmation'
import { axiosInstance } from '../axiosInstance'
import { SubscriptionMatrix, SubscriptionType, UpdateSubscriptionType } from './schema'
import { formatTo24HourClock } from '@/utils/time'
import { COMPLETE_FORMAT_WITHOUT_TIME } from '@/constants'

export const getStripeConnectStatus = async (
    profileId: string | undefined,
): Promise<{
    message: string
    status?: string
}> => {
    if (!profileId) throw new Error('Missing Profile ID')

    const response = await axiosInstance.get(`/stripe/check-status/${profileId}`)

    return response.data
}

export const getPricing = async (interval: string): Promise<SubscriptionType[]> => {
    const response = await axiosInstance.get(`/public/pricing/${interval}`)
    return response.data.content
}

export const getPricingByID = async (pricingID: string): Promise<SubscriptionType> => {
    const response = await axiosInstance.get(`/public/plan/${pricingID}`)

    return response.data.content
}

export const checkCustomerPaymentMethod = async (customerId: string): Promise<boolean> => {
    const response = await axiosInstance.get(`/stripe/customer/check-payment-method/${customerId}`)

    return response.data.content
}

export const getSubscriptionTypes = async (
    interval: string,
    isFreeTrial: boolean = false,
): Promise<SubscriptionType[]> => {
    const response = await axiosInstance.get(
        `/public/subscription-types?interval=${interval} ${isFreeTrial ? '&isFreeTrial=true' : ''}`,
    )

    return response.data.content
}

export const createSubscription = async (
    data: UpdateSubscriptionType,
): Promise<CreateSubscriptionRes> => {
    const response = await axiosInstance.post('/profiles/business/update-subscription', {
        planId: data.planId,
        profileId: data.userId,
    })

    return response.data.content
}

export const updateSubscription = async (data: UpdateSubscriptionType) => {
    const response = await axiosInstance.post('/stripe/change/plan', data)

    return response
}

export const getNextAutoBillDate = async (businessId: string): Promise<string> => {
    const response = await axiosInstance.get(
        `stripe/subscription/next-auto-bill-date/${businessId}`,
    )

    return formatTo24HourClock(response.data.content, COMPLETE_FORMAT_WITHOUT_TIME)
}

export const getSubscriptionMatrix = async (interval: string): Promise<SubscriptionMatrix> => {
    const response = await axiosInstance.get(`/public/subscription-matrix/${interval}`)

    return response.data.content
}

export const checkSubscriptionFeatureRestriction = async (
    profileId: string,
    name: string,
): Promise<boolean> => {
    const response = await axiosInstance.get(
        `/subscription/check-restriction?profileId=${profileId}&name=${name}`,
    )

    return response.data.content as boolean
}
