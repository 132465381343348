import React from 'react'
import { CSVLink } from 'react-csv'
import dayjs from 'dayjs'
import { JobListType, JobLogResponseType } from '@/api/job/schema'
import { jobStatus, JobStatusType } from '../../ManageJobLogs/JobLogInfo'
import { getJobLogEndTime, getJobLogStartTime } from '@/utils/time'
export interface IExportDataToCSV {
    date: string
    job: string
    customer: string
    crew: string
    start: string
    finish: string
    status: string
    invoiceStatus: string
}

const ExportJobLogCSV = ({
    isOpen,
    setIsOpen,
    jobs,
}: {
    isOpen?: boolean
    setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
    jobs?: JobListType
}) => {
    const tableData: IExportDataToCSV[] | null =
        (jobs &&
            (jobs.content?.map((job: JobLogResponseType) => {
                return {
                    date: dayjs(job?.appointment?.startDateTime).format('MMMM DD, YYYY'),
                    job: job.quote.productServiceGroup?.[0]?.name,
                    customer:
                        job.quote &&
                        job.quote?.profile?.firstName + ' ' + job.quote?.profile?.lastName,
                    crew: job.crew?.crewNumber ? 'Crew 00' + job.crew?.crewNumber : 'Unassigned',
                    start: getJobLogStartTime(job),
                    finish: getJobLogEndTime(job),
                    status: jobStatus?.[job?.status as JobStatusType]?.text,
                    invoiceStatus: 'Upon Job Completion',
                }
            }) as IExportDataToCSV[])) ||
        null

    if (!tableData) return

    const csvHeaders = [
        'Date',
        'Job',
        'Customer',
        'Crew',
        'Start',
        'Finish',
        'Job Status',
        'Invoice Status',
    ]

    const csvData = tableData?.map((item) => ({
        Date: item.date,
        Job: item.job,
        Customer: item.customer,
        Crew: item.crew,
        Start: item.start,
        Finish: item.finish,
        'Job Status': item.status,
        'Invoice Status': item.invoiceStatus,
    }))

    return (
        <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={'job-log.csv'}
            className={
                'w-[135px] h-[34px] text-[#191A0A] hover:bg-[#00000029] block px-5 py-1 text-base rounded-b-md'
            }
            onClick={() => {
                setIsOpen && setIsOpen(!isOpen)
            }}
        >
            Export as CSV
        </CSVLink>
    )
}

export default ExportJobLogCSV
