import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import PhoneNumberInput from '@/components/InputNumber'
import { useFormContext } from 'react-hook-form'
import { ActionMode } from '..'
import { USER_STATUS } from '@/constants'

type CustomerDetailsFieldsProps = {
    mode: ActionMode
    profileStatus?: string
}

const UPDATE = 'update'
const CREATE = 'create'

const CustomerDetailsFields = ({ mode, profileStatus }: CustomerDetailsFieldsProps) => {
    const { control } = useFormContext()

    const isAccountInactive = () => {
        if (profileStatus === USER_STATUS.INACTIVE) return true
        else return false
    }

    return (
        <div className='grid grid-cols-3 sm:grid-cols-1 gap-4'>
            {mode === UPDATE && (
                <div className='col-span-1'>
                    <FormField
                        control={control}
                        name='accountNumber'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        type='text'
                                        placeholder='Account Number*'
                                        disabled
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            )}
            {mode === CREATE && (
                <div className='col-span-1'>
                    <FormField
                        control={control}
                        name='accountNumber'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        type='text'
                                        placeholder='Account Number*'
                                        disabled
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            )}
            <div className='col-span-1'>
                <FormField
                    control={control}
                    name='firstName'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    disabled={isAccountInactive()}
                                    type='text'
                                    placeholder='First Name*'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    control={control}
                    name='lastName'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    disabled={isAccountInactive()}
                                    type='text'
                                    placeholder='Last Name*'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    control={control}
                    name='email'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    type='text'
                                    placeholder='Email Address*'
                                    disabled
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    control={control}
                    name='phoneNumber'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <PhoneNumberInput
                                    disabled={isAccountInactive()}
                                    inputProps={{
                                        name: 'Contact Number',
                                        placeholder: 'Contact Number*',
                                    }}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </div>
    )
}
export default CustomerDetailsFields
