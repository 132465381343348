import { CardTitle } from '@/components/Card'
import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { Textarea } from '@/components/TextArea'
import { useFieldArray, useFormContext } from 'react-hook-form'
import repeatGrid from '@/assets/private/repeat_grid.svg'
import { useAtomValue, useSetAtom } from 'jotai'
import { useQuery } from '@tanstack/react-query'
import { getRequestServices } from '@/api/service'
import { userAtom } from '@/store/auth'
import { RequestServiceType } from '@/api/service/schema'
import { AxiosError } from 'axios'
import { customerInvoiceAtom, invoiceContentAtom } from '@/store/owner'
import { CiTrash } from 'react-icons/ci'
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri'
import { startTransition, useEffect, useState } from 'react'
import { InvoiceType } from '@/api/invoicing/schema'
import ProductsAndServicesTable from '@/pages/common/FinancialManagement/ProductsAndServicesTable'
import { useNavigate } from 'react-router-dom'
import { jobGenerateInvoiceAtom } from '@/store/job'
import { isNoSelectedServicesInvoicingAtom } from '@/store/financial'
import { DROPDOWN_LABELS } from '@/constants'
import { displayCurrency } from '@/utils/helper'

const ContentService = () => {
    const navigate = useNavigate()
    const setInvoiceContent = useSetAtom(invoiceContentAtom)
    const generateInvoiceData = useAtomValue(jobGenerateInvoiceAtom)
    const user = useAtomValue(userAtom)
    const [IsSelectService, setIsSelectService] = useState<string[]>([])
    const [selectedServices, setSelectedServices] = useState<string[]>([])
    const { control, setValue, register, getValues } = useFormContext<InvoiceType>()
    const [isFocused, setIsFocused] = useState(false)
    const customer = useAtomValue(customerInvoiceAtom)
    const setIsNoSelectedServices = useSetAtom(isNoSelectedServicesInvoicingAtom)

    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: 'productServiceGroup',
    })

    const { data: services } = useQuery<unknown, AxiosError, RequestServiceType>({
        queryKey: ['RequestServiceType'],
        queryFn: () => getRequestServices(user?.businessId as string),
    })

    const handleOnChange = (service: string, index: number) => {
        const selectedServiceItem = [...IsSelectService]
        selectedServiceItem[index] = service
        setIsSelectService(selectedServiceItem)

        if (service === '+ Add Product Or Services') {
            navigate('/settings/product-services')
        }

        if (service === DROPDOWN_LABELS.servicesOrProducts) {
            setSelectedServices((prevSelectedServices) => {
                const updatedSelectedServices = [...prevSelectedServices]
                const selectedService = updatedSelectedServices[index]
                if (selectedService !== DROPDOWN_LABELS.servicesOrProducts) {
                    updatedSelectedServices[index] = DROPDOWN_LABELS.servicesOrProducts
                }
                return updatedSelectedServices
            })

            setValue(`productServiceGroup.${index}.productServiceId`, '')
            setValue(`productServiceGroup.${index}.unitPrice`, 0)
            setValue(`productServiceGroup.${index}.markup`, 0)
            setValue(`productServiceGroup.${index}.cost`, 0)
            setValue(`productServiceGroup.${index}.description`, '')
            setValue(`productServiceGroup.${index}.name`, '')
            setValue(`productServiceGroup.${index}.type`, '')
            if (customer?.quote) {
                setIsNoSelectedServices(false)
            } else {
                setIsNoSelectedServices(true)
            }
        } else {
            setSelectedServices((prevSelectedServices) => {
                const updatedSelectedServices = [...prevSelectedServices]
                updatedSelectedServices[index] = service
                return updatedSelectedServices
            })

            const serv = JSON.parse(service)
            const markup = serv.markup || 0
            setValue(`productServiceGroup.${index}.quantity`, 1)
            setValue(`productServiceGroup.${index}.productServiceId`, serv.productServiceId)
            setValue(`productServiceGroup.${index}.unitPrice`, serv.unitPrice)
            setValue(`productServiceGroup.${index}.markup`, serv.markup)
            setValue(
                `productServiceGroup.${index}.cost`,
                Number((serv.unitPrice + (serv.unitPrice * markup) / 100).toFixed(2)),
            )
            setValue(`productServiceGroup.${index}.description`, serv.description)
            setValue(`productServiceGroup.${index}.name`, serv.name)
            setValue(`productServiceGroup.${index}.type`, serv.type)
            setIsNoSelectedServices(false)
        }

        const group = getValues(`productServiceGroup`)

        setInvoiceContent((prevVal) => ({
            ...prevVal,
            subTotal: group
                ? group?.map((datum) => datum.cost!)?.reduce((a, b) => a + b) +
                  prevVal.laborCost +
                  prevVal.totalExpense
                : 0.0,
        }))
        setInvoiceContent((prevVal) => ({
            ...prevVal,
            totalUnitPrice: group
                ? group?.map((datum) => datum.unitPrice!).reduce((a, b) => a + b)
                : 0.0,
        }))
    }

    const handleOnPriceChange = (index: number, price: number) => {
        if (isNaN(price)) {
            price = 0
        }
        const markup = getValues(`productServiceGroup.${index}.markup`) || 0
        const quantity = getValues(`productServiceGroup.${index}.quantity`)
        const totalPrice = (quantity ? quantity : 1) * price
        const group = getValues(`productServiceGroup`)

        setValue(`productServiceGroup.${index}.unitPrice`, price)

        setValue(
            `productServiceGroup.${index}.cost`,
            Number(((totalPrice * markup) / 100 + totalPrice).toFixed(2)),
            {
                shouldValidate: true,
            },
        )
        setInvoiceContent((prevVal) => ({
            ...prevVal,
            subTotal: group
                ? group?.map((datum) => datum.cost!).reduce((a, b) => a + b) +
                  prevVal.laborCost +
                  prevVal.totalExpense
                : 0.0,
        }))
        setInvoiceContent((prevVal) => ({
            ...prevVal,
            totalUnitPrice: group
                ? group?.map((datum) => datum.unitPrice!).reduce((a, b) => a + b)
                : 0.0,
        }))
    }

    const handleOnMarkupChange = (index: number, markup: number) => {
        const price = getValues(`productServiceGroup.${index}.unitPrice`)
        const quantity = getValues(`productServiceGroup.${index}.quantity`)
        const totalPrice =  (quantity ? quantity : 1) * price
        const validMarkup = markup !== undefined && !isNaN(markup) ? markup : 0
        const group = getValues(`productServiceGroup`)
        setValue(`productServiceGroup.${index}.markup`, markup)
        setValue(
            `productServiceGroup.${index}.cost`,
            Number(((totalPrice * validMarkup / 100) + totalPrice).toFixed(2)),
            {
                shouldValidate: true,
            },
        )
        setInvoiceContent((prevVal) => ({
            ...prevVal,
            subTotal: group
                ? group?.map((datum) => datum.cost!).reduce((a, b) => a + b) +
                  prevVal.laborCost +
                  prevVal.totalExpense
                : 0.0,
        }))
        setInvoiceContent((prevVal) => ({
            ...prevVal,
            totalUnitPrice: group
                ? group?.map((datum) => datum.unitPrice!).reduce((a, b) => a + b)
                : 0.0,
        }))
        setInvoiceContent((prevVal) => ({
            ...prevVal,
            totalProduct: group
                ? group
                      ?.filter((item) => item.type === 'product')
                      .map((datum) => datum.unitPrice!)
                      .reduce((a, b) => a + b)
                : 0.0,
        }))
        setInvoiceContent((prevVal) => ({
            ...prevVal,
            totalService: group
                ? group
                      ?.filter((item) => item.type === 'service')
                      .map((datum) => datum.unitPrice!)
                      .reduce((a, b) => a + b)
                : 0.0,
        }))
    }

    const handleOnQuantityChange = (index: number, quantity: number, price: number) => {
        const markup = (getValues(`productServiceGroup.${index}.markup`) as number)
        const totalPrice = quantity! * price
        const group = getValues(`productServiceGroup`)
        setValue(`productServiceGroup.${index}.quantity`, quantity, {
            shouldValidate: true,
        })

        setValue(
            `productServiceGroup.${index}.cost`,
            Number(((totalPrice * markup! / 100) + totalPrice).toFixed(2)),
            {
                shouldValidate: true,
            },
        )
        setInvoiceContent((prevVal) => ({
            ...prevVal,
            subTotal: group
                ? group?.map((datum) => datum.cost!).reduce((a, b) => a + b) +
                  prevVal.laborCost +
                  prevVal.totalExpense
                : 0.0,
        }))
        setInvoiceContent((prevVal) => ({
            ...prevVal,
            totalUnitPrice: group
                ? group?.map((datum) => datum.unitPrice!).reduce((a, b) => a + b)
                : 0.0,
        }))
        setInvoiceContent((prevVal) => ({
            ...prevVal,
            totalProduct: group
                ? group
                      ?.filter((item) => item.type === 'product')
                      .map((datum) => datum.unitPrice!)
                      .reduce((a, b) => a + b)
                : 0.0,
        }))
        setInvoiceContent((prevVal) => ({
            ...prevVal,
            totalService: group
                ? group
                      ?.filter((item) => item.type === 'service')
                      .map((datum) => datum.unitPrice!)
                      .reduce((a, b) => a + b)
                : 0.0,
        }))
    }

    const handleOnRemove = (index: number) => {
        const newSelectedServices = [...selectedServices]
        newSelectedServices.splice(index, 1)
        setSelectedServices(newSelectedServices)
        const group = getValues(`productServiceGroup`)
        const cost = getValues(`productServiceGroup.${index}.cost`)
        const price = getValues(`productServiceGroup.${index}.unitPrice`)
        setInvoiceContent((prevVal) => ({
            ...prevVal,
            subTotal: Number((prevVal.subTotal - cost!).toFixed(2)),
        }))
        setInvoiceContent((prevVal) => ({
            ...prevVal,
            totalUnitPrice: Number((prevVal.totalUnitPrice - price!).toFixed(2)),
        }))
        setInvoiceContent((prevVal) => ({
            ...prevVal,
            totalProduct: group
                ? group
                      ?.filter((item) => item.type === 'product')
                      .map((datum) => datum.unitPrice!)
                      .reduce((a, b) => a + b)
                : 0.0,
        }))
        setInvoiceContent((prevVal) => ({
            ...prevVal,
            totalService: group
                ? group
                      ?.filter((item) => item.type === 'service')
                      .map((datum) => datum.unitPrice!)
                      .reduce((a, b) => a + b)
                : 0.0,
        }))
        remove(index)
    }

    const totalServicesCost = fields.reduce((total, _, index) => {
        const cost = getValues(`productServiceGroup.${index}.cost`) as number
        return total + (cost ? cost : 0)
    }, 0)

    const totalProduct = fields.reduce((total, _, index) => {
        const cost = getValues(`productServiceGroup.${index}.cost`) as number
        const type = getValues(`productServiceGroup.${index}.type`) as string
        return type === 'product' ? total + (cost ? cost : 0) : total
    }, 0)

    const totalService = fields.reduce((total, _, index) => {
        const cost = getValues(`productServiceGroup.${index}.cost`) as number
        const type = getValues(`productServiceGroup.${index}.type`) as string
        return type === 'service' ? total + (cost ? cost : 0) : total
    }, 0)

    const handleFocus = () => setIsFocused(true)

    const handleBlur = () => setIsFocused(false)

    useEffect(() => {
        startTransition(() => {
            if (customer?.quote) {
                setIsNoSelectedServices(false)
            } else {
                setIsNoSelectedServices(true)
            }
        })
    }, [customer])

    useEffect(() => {
        if (customer?.quote?.productServiceGroup) {
            startTransition(() => {
                setValue('productServiceGroup', customer?.quote?.productServiceGroup)
            })
        }

        if (generateInvoiceData?.quote?.productServiceGroup) {
            startTransition(() => {
                setValue('productServiceGroup', generateInvoiceData?.quote?.productServiceGroup)
            })
        }
    }, [customer, generateInvoiceData])
    useEffect(() => {
        startTransition(() => {
            setInvoiceContent((prevVal) => ({
                ...prevVal,
                totalServicePrice: Number(totalServicesCost.toFixed(2)),
                totalService: Number(totalService.toFixed(2)),
                totalProduct: Number(totalProduct.toFixed(2)),
            }))
        })
    }, [totalServicesCost])

    useEffect(() => {
        startTransition(() => {
            if (
                generateInvoiceData &&
                generateInvoiceData.quote &&
                generateInvoiceData.quote.productServiceGroup?.length >= 1
            ) {
                replace(
                    generateInvoiceData.quote.productServiceGroup?.map((service) => ({
                        productServiceId: service.productServiceId,
                        productServiceGroupID: service.productServiceGroupID,
                        name: service.name,
                        unitPrice: service.unitPrice,
                        markup: service.markup,
                        cost: Number(
                            (
                                (service?.unitPrice ?? 0) +
                                ((service?.unitPrice ?? 0) * (service?.markup ?? 0)) / 100
                            ).toFixed(2),
                        ),
                        description: service.description,
                        quantity: 1,
                        type: service.type,
                    })),
                )
            }
        })
    }, [generateInvoiceData])

    useEffect(() => {
        startTransition(() => {
            if (generateInvoiceData && generateInvoiceData.quote) {
                generateInvoiceData.quote.productServiceGroup.forEach((service, index) => {
                    setSelectedServices((prevSelectedServices) => {
                        const updatedSelectedServices = [...prevSelectedServices]
                        const selectedService = updatedSelectedServices[index]
                        if (selectedService !== 'Select') {
                            updatedSelectedServices[index] = 'Select'
                        }
                        return updatedSelectedServices
                    })
                    setValue(
                        `productServiceGroup.${index}.productServiceId`,
                        service.productServiceId,
                    )
                    setValue(`productServiceGroup.${index}.quantity`, 1)
                    setValue(
                        `productServiceGroup.${index}.productServiceGroupID`,
                        service.productServiceGroupID,
                    )
                    setValue(`productServiceGroup.${index}.unitPrice`, service.unitPrice)
                    setValue(`productServiceGroup.${index}.markup`, service.markup)
                    setValue(
                        `productServiceGroup.${index}.cost`,
                        Number(
                            (
                                (service?.unitPrice ?? 0) +
                                ((service?.unitPrice ?? 0) * (service?.markup ?? 0)) / 100
                            ).toFixed(2),
                        ),
                    )
                    setValue(`productServiceGroup.${index}.description`, service.description)
                    setValue(`productServiceGroup.${index}.name`, service.name)
                    setValue(`productServiceGroup.${index}.type`, service.type)
                })
            }
        })
    }, [generateInvoiceData])

    return (
        <>
            {customer?.quote == null && generateInvoiceData == null ? (
                <div>
                    <div className='w-full flex justify-between'>
                        <CardTitle className='text-[18px]'>Services and Products</CardTitle>
                        <button
                            type='button'
                            disabled={!customer?.profile}
                            className='text-zentive-green-dark font-semibold disabled:text-zentive-gray-medium'
                            onClick={() =>
                                append({
                                    productServiceId: '',
                                    name: '',
                                    cost: 0,
                                    markup: 0,
                                    unitPrice: 0,
                                    description: '',
                                    quantity: 1,
                                    type: '',
                                })
                            }
                        >
                            + Add Service
                        </button>
                    </div>
                    <div className='overflow-x-scroll green-scrollbar'>
                        {fields?.map((_, index) => (
                            <div className='flex flex-row relative' key={index}>
                                <div className='flex justify-left my-6 mr-4'>
                                    <img
                                        src={repeatGrid}
                                        alt={'Repeat Grid'}
                                        className='h-10 w-10'
                                    />
                                </div>
                                <div className='flex flex-col w-full my-4 mr-14'>
                                    <div className='flex flex-row w-full mb-6 justify-between'>
                                        <div className='relative z-10'>
                                            <select
                                                disabled={!customer?.profile}
                                                name='row'
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                onChange={(val) =>
                                                    handleOnChange(val.target.value, index)
                                                }
                                                className='flex 2xl:w-96 xl:w-60 lg:w-48 font-sans text-base pl-3 pr-8 h-11 border border-black disabled:bg-gray-100 text-gray-900 rounded-sm mr-4 bg-white appearance-none'
                                            >
                                                <option className='text-lg'>
                                                    {DROPDOWN_LABELS.servicesOrProducts}
                                                </option>

                                                {services?.services?.map((data) => (
                                                    <option
                                                        value={JSON.stringify(data)}
                                                        key={data.productServiceId}
                                                        hidden={selectedServices.includes(
                                                            JSON.stringify(data),
                                                        )}
                                                        className='text-lg'
                                                    >
                                                        {data.name}
                                                    </option>
                                                ))}
                                                <option className='text-zentive-green-dark font-semibold'>
                                                    + Add Product Or Services
                                                </option>
                                            </select>
                                            <div className='absolute inset-y-0 right-4 flex items-center pr-3 pointer-events-none'>
                                                {isFocused ? (
                                                    <RiArrowDropUpLine className='h-5 w-5 text-gray-500' />
                                                ) : (
                                                    <RiArrowDropDownLine className='h-5 w-5 text-gray-500' />
                                                )}
                                            </div>
                                        </div>

                                        <FormField
                                            control={control}
                                            disabled={
                                                !getValues(
                                                    `productServiceGroup.${index}.productServiceId`,
                                                ) ||
                                                getValues(`productServiceGroup.${index}.type`) ===
                                                    'service'
                                            }
                                            defaultValue={1}
                                            {...register(`productServiceGroup.${index}.quantity`)}
                                            render={({ field }) => (
                                                <FormItem className='z-10'>
                                                    <FormControl>
                                                        <Input
                                                            className='2xl:w-40 xl:w-24 lg:w-20 mr-4 flex justify-center items-center font-sans text-base h-11 border border-black disabled:bg-zentive-gray-light text-gray-900 rounded-sm'
                                                            placeholder='Quantity*'
                                                            type='number'
                                                            {...field}
                                                            onChange={(val) =>
                                                                handleOnQuantityChange(
                                                                    index,
                                                                    parseInt(val.target.value),
                                                                    getValues(
                                                                        `productServiceGroup.${index}.unitPrice`,
                                                                    ),
                                                                )
                                                            }
                                                            onKeyDown={(e) => {
                                                                if (
                                                                    e.key === '-' ||
                                                                    e.key === '+' ||
                                                                    e.key === 'e' ||
                                                                    e.key === 'E'
                                                                ) {
                                                                    e.preventDefault()
                                                                }
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />

                                        <FormField
                                            control={control}
                                            disabled={
                                                !getValues(
                                                    `productServiceGroup.${index}.productServiceId`,
                                                )
                                            }
                                            {...register(`productServiceGroup.${index}.unitPrice`)}
                                            render={({ field }) => (
                                                <FormItem className='z-10'>
                                                    <FormControl>
                                                        <Input
                                                            className='2xl:w-64 xl:w-40 lg:w-32 mr-4 flex justify-center items-center font-sans text-base h-11 border border-black disabled:bg-zentive-gray-light text-gray-900 rounded-sm text-right focus:text-left'
                                                            placeholder='Price* ($)'
                                                            type='number'
                                                            {...field}
                                                            onKeyDown={(e) => {
                                                                if (
                                                                    e.key === '-' ||
                                                                    e.key === '+' ||
                                                                    e.key === 'e' ||
                                                                    e.key === 'E'
                                                                ) {
                                                                    e.preventDefault()
                                                                }
                                                            }}
                                                            onChange={(val) =>
                                                                handleOnPriceChange(
                                                                    index,
                                                                    parseFloat(val.target.value),
                                                                )
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />

                                        <FormField
                                            control={control}
                                            defaultValue={6}
                                            {...register(`productServiceGroup.${index}.markup`)}
                                            render={({ field }) => (
                                                <FormItem className='z-10'>
                                                    <FormControl>
                                                        <Input
                                                            className='2xl:w-52 xl:w-32 lg:w-24 mr-4 flex justify-center items-center font-sans text-base  h-11 border border-black disabled:bg-zentive-gray-light text-gray-900 rounded-sm text-right focus:text-left'
                                                            placeholder='Markup %*'
                                                            disabled={
                                                                !services?.services
                                                                    ?.map((data) =>
                                                                        JSON.stringify(data),
                                                                    )
                                                                    ?.includes(
                                                                        IsSelectService[index],
                                                                    )
                                                            }
                                                            type='text'
                                                            {...field}
                                                            onChange={(val) =>
                                                                handleOnMarkupChange(
                                                                    index,
                                                                    parseFloat(
                                                                        val.target.value.replace(
                                                                            '%',
                                                                            '',
                                                                        ),
                                                                    ),
                                                                )
                                                            }
                                                            value={
                                                                field.value != 0
                                                                    ? typeof field.value ===
                                                                          'number' &&
                                                                      !isNaN(field.value)
                                                                        ? `${field.value}%`
                                                                        : ''
                                                                    : ''
                                                            }
                                                            onKeyDown={(e) => {
                                                                if (
                                                                    e.key === '-' ||
                                                                    e.key === '+' ||
                                                                    e.key === 'e' ||
                                                                    e.key === 'E'
                                                                ) {
                                                                    e.preventDefault()
                                                                }
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />

                                        <FormField
                                            control={control}
                                            {...register(`productServiceGroup.${index}.cost`)}
                                            render={({ field }) => (
                                                <FormItem className='z-10'>
                                                    <FormControl>
                                                        <Input
                                                            className='2xl:w-52 xl:w-32 lg:w-24 flex justify-center items-center font-sans text-base h-11 border border-black disabled:bg-zentive-gray-light text-gray-900 rounded-sm text-right'
                                                            placeholder='Total'
                                                            type='text'
                                                            disabled={true}
                                                            {...field}
                                                            value={displayCurrency(field.value)}
                                                            onKeyDown={(e) => {
                                                                if (
                                                                    e.key === '-' ||
                                                                    e.key === '+' ||
                                                                    e.key === 'e' ||
                                                                    e.key === 'E'
                                                                ) {
                                                                    e.preventDefault()
                                                                }
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <div className='w-full flex justify-end absolute right-4 top-0 mt-6 mr-0'>
                                            {index !== 0 && (
                                                <button
                                                    type='button'
                                                    onClick={() => handleOnRemove(index)}
                                                >
                                                    <CiTrash className='size-7 text-zentive-red-dark' />
                                                </button>
                                            )}
                                        </div>
                                    </div>

                                    <div>
                                        {/* // TODO: gawen formfield */}
                                        <FormField
                                            control={control}
                                            {...register(
                                                `productServiceGroup.${index}.description`,
                                            )}
                                            render={({ field }) => (
                                                <FormItem className='z-10'>
                                                    <FormControl>
                                                        <Textarea
                                                            className='flex w-full pt-3 font-sans text-base h-24 border border-black disabled:bg-gray-100 text-gray-900 rounded-sm'
                                                            placeholder='Description'
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <ProductsAndServicesTable
                    quote={
                        generateInvoiceData && generateInvoiceData.quote && !customer
                            ? generateInvoiceData.quote
                            : customer?.quote
                    }
                />
            )}

            <hr className='border-b border-dashed border-zentive-gray-medium w-full mt-4 '></hr>
            <div className='flex mt-4'>
                <label className='font-bold text-zentive-gray-medium'>Total of Services</label>
                <p className='ml-auto text-zentive-green-dark'>
                    $
                    {totalServicesCost.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}
                </p>
            </div>
        </>
    )
}

export default ContentService
