import { Tabs, TabsList, TabsTrigger } from '@/components/Tabs'
import PersonalInfo from './Presonalnfo'
import SubscriberSettings from './Settings'
import Notes from './Notes'
import Subscription from './Subscription'
import {
    currentSubsInfoAtom,
    isOpenModalForChangesAtom,
    isPersonalInformationFormDirtyAtom,
    manageInfoTabAtom,
    nextTabAtom,
} from '@/store/manageSubs'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import { useQueryClient } from '@tanstack/react-query'
import BreadCrumbs from '@/components/BreadCrumbs'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import { cn } from '@/utils/helper'

const SubscribersInfo = () => {
    const [selected, setSelected] = useAtom(manageInfoTabAtom)
    const [, setCurrentSubsInfo] = useAtom(currentSubsInfoAtom)
    const [, setIsOpenModalForChanges] = useAtom(isOpenModalForChangesAtom)
    const [IsPersonalInfoFormDirty, SetIsPersonalInfoFormDirty] = useAtom(
        isPersonalInformationFormDirtyAtom,
    )
    const currentSubsInfo = useAtom(currentSubsInfoAtom)[0]
    const getBusinessName = currentSubsInfo?.business?.businessName

    const queryClient = useQueryClient()

    const [, setNextTab] = useAtom(nextTabAtom)
    useEffect(() => {
        return () => {
            setCurrentSubsInfo(null)
            SetIsPersonalInfoFormDirty(false)
        }
    }, [])
    const handleTabValueChange = (val: string) => {
        setNextTab(val)
        if (IsPersonalInfoFormDirty && selected === 'personalInformation') {
            setIsOpenModalForChanges(true)
        } else {
            setSelected(val)
            queryClient.invalidateQueries({ queryKey: ['getNotes'] })
        }
    }
    return (
        <section className={cn('flex flex-col w-full', BREADCRUMBS_PADDING_STYLE)}>
            <BreadCrumbs titleName={getBusinessName} />
            <Tabs value={selected} defaultValue={selected} className=' mt-4 w-full'>
                <TabsList className='w-full mt-[26px] mb-[7px]'>
                    <TabsTrigger
                        name='usMngnt'
                        value='personalInformation'
                        onClick={() => handleTabValueChange('personalInformation')}
                        className={twMerge(
                            'flex justify-center items-center w-full h-[54px] text-[18px] text-zentive-gray-medium',
                            selected === 'personalInformation' &&
                                'text-zentive-blue-dark border-zentive-blue-dark font-bold border-b-4 bg-zentive-gray-bg',
                        )}
                    >
                        Personal Information
                    </TabsTrigger>
                    <TabsTrigger
                        name='usMngnt'
                        value='subscription'
                        onClick={() => handleTabValueChange('subscription')}
                        className={twMerge(
                            'flex justify-center items-center w-full h-[54px] text-[18px] text-zentive-gray-medium',
                            selected === 'subscription' &&
                                'text-zentive-blue-dark border-zentive-blue-dark font-bold border-b-4 bg-zentive-gray-bg',
                        )}
                    >
                        Subscription
                    </TabsTrigger>
                    {/* {status == 'ACT' && ( */}
                    <TabsTrigger
                        name='usMngnt'
                        value='notes'
                        onClick={() => handleTabValueChange('notes')}
                        className={twMerge(
                            'flex justify-center items-center w-full h-[54px] text-[18px] text-zentive-gray-medium',
                            selected === 'notes' &&
                                'text-zentive-blue-dark border-zentive-blue-dark font-bold border-b-4 bg-zentive-gray-bg',
                        )}
                    >
                        Notes
                    </TabsTrigger>
                    {/* )} */}
                    <TabsTrigger
                        name='usMngnt'
                        onClick={() => handleTabValueChange('settings')}
                        value='settings'
                        className={twMerge(
                            'flex justify-center items-center w-full h-[54px] text-[18px] text-zentive-gray-medium',
                            selected === 'settings' &&
                                'text-zentive-blue-dark border-zentive-blue-dark font-bold border-b-4 bg-zentive-gray-bg',
                        )}
                    >
                        Settings
                    </TabsTrigger>
                </TabsList>
            </Tabs>

            <div className='w-full'>
                {
                    {
                        personalInformation: <PersonalInfo />,
                        subscription: <Subscription />,
                        notes: <Notes />,
                        settings: <SubscriberSettings />,
                    }[selected]
                }
            </div>
        </section>
    )
}

export default SubscribersInfo
