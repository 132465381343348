import {
    CrewMemberDetailsType,
    CrewMemberType,
    CrewType,
    CrewtoCrewMemberType,
} from '@/api/crew/schema'
import CrewMember from './CrewMember'
import { useFormContext } from 'react-hook-form'
import { useState, useCallback, FC } from 'react'
import { EmployeeCrewDetailsType } from '@/api/employee/schema'
import { getEmployeeCrewDetails } from '@/api/employee'
import AssignForeman from '../../AssignForeman'
import ReassignForemanModal from '@/components/ReassignForemanModal'

type SelectedCrewProps = {
    crew: CrewType
}

const getCrewFullName = (crewProfile: CrewMemberType) =>
    `${crewProfile.firstName} ${crewProfile.lastName}`

const SelectedCrew: FC<SelectedCrewProps> = ({ crew }) => {
    const { watch, setValue } = useFormContext()
    const [foremanModal, setForemanModal] = useState<boolean>(false)
    const [crewMemberDetails, setCrewMemberDetails] = useState<EmployeeCrewDetailsType | null>(null)

    const foremanId: string = watch('defaultForemanId') || ''
    const foreman: CrewMemberDetailsType | null = watch('foreman') || null
    const memberIds: string[] = watch('crewMemberIds') || []
    const laborCost = watch('quote.laborCost')

    const handleRemoveCrewMemberClick = useCallback(
        (member: CrewtoCrewMemberType) => {
            const updatedMemberIds = memberIds.filter((id) => id !== member.crewMemberId)
            setValue('crewMemberIds', updatedMemberIds)
            const updatedLaborCost = laborCost - (member.crewProfile.compensation ?? 0)
            setValue('quote.laborCost', updatedLaborCost)
        },
        [memberIds, setValue],
    )

    const handleAddCrewMemberClick = useCallback(
        (member: CrewtoCrewMemberType) => {
            const updatedMemberIds = [...memberIds, member.crewMemberId]
            setValue('crewMemberIds', updatedMemberIds)
            const updatedLaborCost = laborCost + (member.crewProfile.compensation ?? 0)
            setValue('quote.laborCost', updatedLaborCost)
        },
        [memberIds, setValue],
    )

    const handleCrewMemberClick = useCallback(
        (member: CrewtoCrewMemberType) => {
            const isActive = memberIds.includes(member.crewMemberId)
            if (isActive) {
                handleRemoveCrewMemberClick(member)
            } else {
                handleAddCrewMemberClick(member)
            }
        },
        [memberIds, handleRemoveCrewMemberClick, handleAddCrewMemberClick],
    )

    const handleForemanClick = useCallback(
        async (profileId: string) => {
            const empCrewDetails = await getEmployeeCrewDetails(
                profileId,
                foremanId === foreman?.crewMemberId,
            )
            setCrewMemberDetails(empCrewDetails)
            setForemanModal(true)
        },
        [foremanId, foreman, setCrewMemberDetails, setForemanModal],
    )

    return (
        <>
            <div className='h-full w-full flex flex-row gap-[10px] p-5'>
                <ReassignForemanModal
                    open={foremanModal}
                    setOpen={setForemanModal}
                    crewMemberDetails={crewMemberDetails}
                />
                {crew.members?.length > 0 &&
                    crew.members?.map(
                        (member) =>
                            foreman?.crewMemberId !== member.crewMemberId && (
                                <button
                                    key={member.crewProfile.profileId}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        handleCrewMemberClick(member)
                                    }}
                                >
                                    <CrewMember isActive={memberIds.includes(member.crewMemberId)}>
                                        {getCrewFullName(member.crewProfile)}
                                    </CrewMember>
                                </button>
                            ),
                    )}
            </div>
            <AssignForeman onClick={handleForemanClick} />
        </>
    )
}

export default SelectedCrew
