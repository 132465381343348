import 'react-datepicker/dist/react-datepicker.css'
import { Card, CardFooter } from '@/components/Card'
import { Button } from '@/components/Button'
import { useNavigate } from 'react-router-dom'
import { saveOptimizeGroup } from '@/api/routing'
import { RouteOptimizeType } from '@/api/routing/schema'
import { toast } from '@/hooks/useToast'
import {
    routeJobListAtom,
    routePolylineAtom,
    isPolylineLoadingAtom,
    hasJobResultAtom,
    routeCustomerListAtom,
    routeOptimizeAtom,
} from '@/store/route'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtom, useSetAtom } from 'jotai'
import { useState, useEffect, useRef } from 'react'
import PropertyList from './PropertyList'
import WrappedOptimizeMapView from './RouteMap'

const OptimizationBody = () => {
    const setRouteJobList = useSetAtom(routeJobListAtom)
    const setPolylineList = useSetAtom(routePolylineAtom)
    const setIsPolylineLoading = useSetAtom(isPolylineLoadingAtom)
    const setHasJobResult = useSetAtom(hasJobResultAtom)
    const setRouteCustomerList = useSetAtom(routeCustomerListAtom)
    const navigate = useNavigate()
    const [routeOptimize, setrouteOptimize] = useAtom(routeOptimizeAtom)
    
    const initialRouteOptimizeRef = useRef(routeOptimize)
    const [isChanged, setIsChanged] = useState(false)

    useEffect(() => {
        setIsChanged(JSON.stringify(routeOptimize) !== JSON.stringify(initialRouteOptimizeRef.current))
    }, [routeOptimize])

    const clearAtoms = () => {
        setRouteJobList([])
        setPolylineList(null)
        setIsPolylineLoading(false)
        setHasJobResult(true)
        setRouteCustomerList([])
        setrouteOptimize([])
    }

    const { mutate: saveRouteMu } = useMutation<
        RouteOptimizeType[],
        AxiosError,
        RouteOptimizeType[]
    >({
        mutationKey: ['SaveGroup'],
        mutationFn: (data) => saveOptimizeGroup(data),
        onSuccess: () => {
            clearAtoms()
            toast({
                description: 'Route saved!',
                variant: 'default',
            })
            navigate('/schedule/routing')
        },
    })

    return (
        <div className='w-full flex flex-col mt-6'>
            <div className='w-full flex flex-row'>
                <PropertyList />
                <WrappedOptimizeMapView />
            </div>
            <Card className='w-full text-center mt-4 mb-4'>
                <CardFooter className='h-full p-4 lg:flex rounded-t-sm lg:justify-end rounded-b-md bg-zentive-gray-light my-auto'>
                    <div className='flex space-x-4 '>
                        <Button
                            onClick={() => {
                                clearAtoms()
                                navigate('/schedule/routing')
                            }}
                            type='button'
                            variant={'outline'}
                            className='w-full px-8 py-2 h-full rounded-sm hover:text-zentive-green-light hover:border-zentive-green-medium hover:bg-zentive-green-medium'
                        >
                            Cancel
                        </Button>
                        <Button
                            className='w-full px-8 py-2 h-full rounded-sm hover:border-zentive-green-medium  hover:bg-zentive-green-medium'
                            type='button'
                            onClick={() => {
                                if (routeOptimize) {
                                    saveRouteMu(routeOptimize)
                                }
                            }}
                            disabled={!isChanged}
                        >
                            Save
                        </Button>
                    </div>
                </CardFooter>
            </Card>
        </div>
    )
}

export default OptimizationBody
