import { Button } from '@/components/Button'
import { Modal } from '@/components/Modal'
import * as React from 'react'

interface IProps {
    isOpen: boolean
    onClose: () => void
    handleDiscard: () => void
}

const DiscardModal: React.FC<IProps> = (props) => {
    return (
        <Modal
            isOpen={props.isOpen}
            isHideCloseButton
            onClose={() => {
                props.onClose()
            }}
            title='Discard Changes'
            isAlert
        >
            <div className='flex flex-col gap-5 text-left text-sm text-gray-500 mx-auto justify-center'>
                <div className='max-w-[500px] mx-auto flex flex-col gap-5 pb-10 ml-[6.6rem]'>
                    <p className='-mt-5'>
                        This action cannot be undone, and any unsaved changes will be lost.
                    </p>
                    <p> Are you sure you want to discard changes? </p>
                </div>

                <div className='inline-flex gap-2 bg-gray-100 w-full justify-end items-center px-6 h-[75px]'>
                    <Button className='w-97' variant={'outline'} onClick={() => props.onClose()}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            props.handleDiscard()
                        }}
                        className='w-97'
                    >
                        Discard
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default DiscardModal
