import { EmailServiceParamType, RequestServiceType } from '@/api/service/schema'
import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { useFormContext } from 'react-hook-form'

interface FieldsProps {
    services: RequestServiceType
}

const Fields: React.FC<FieldsProps> = ({ services }) => {
    const { control, setValue } = useFormContext<EmailServiceParamType>()

    return (
        <div className='space-y-4'>
            <FormField
                control={control}
                name='email'
                render={() => (
                    <FormItem>
                        <FormControl>
                            <Input
                                className='font-sans pl-3 w-full text-base h-11 border border-black text-zentive-black disabled:bg-[#EBEBEB] placeholder-transparent disabled:opacity-100 rounded-sm'
                                type='text'
                                placeholder='Recipient*'
                                value={services?.ownerEmail}
                                disabled
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <FormField
                control={control}
                name='address'
                render={() => (
                    <FormItem>
                        <FormControl>
                            <Input
                                className='font-sans pl-3 w-full text-base h-11 border border-black text-zentive-black  rounded-sm'
                                type='text'
                                placeholder='Address*'
                                value={`${services?.streetAddress}, ${services?.city}, ${services?.state} ${services?.zipCode}`}
                                onChange={(val) => setValue('address', val.target.value)}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
        </div>
    )
}
export default Fields
