import { Checkbox } from '@/components/Checkbox'
import { JOB_STATUS } from '@/constants'
import {
    completedFilterCheckAtom,
    openFilterCheckAtom,
    selectedStatusFilterAtom,
} from '@/store/jobLog'
import { useAtom, useSetAtom } from 'jotai'
import { useEffect } from 'react'

const StatusFilter = () => {
    const setSelectedStatusFilter = useSetAtom(selectedStatusFilterAtom)
    const [openChecked, setOpenChecked] = useAtom(openFilterCheckAtom)
    const [completedChecked, setCompletedChecked] = useAtom(completedFilterCheckAtom)

    const handleOpenChange = () => {
        setOpenChecked((prev) => !prev)
    }

    const handleCompletedChange = () => {
        setCompletedChecked((prev) => !prev)
    }

    useEffect(() => {
        if (openChecked && completedChecked) {
            setSelectedStatusFilter('')
        } else if (openChecked) {
            setSelectedStatusFilter(JOB_STATUS.ACTIVE)
        } else if (completedChecked) {
            setSelectedStatusFilter(JOB_STATUS.COMPLETED)
        } else {
            setSelectedStatusFilter('')
        }
    }, [openChecked, completedChecked])

    return (
        <div>
            <div className='mt-3'>
                <Checkbox
                    className='mt-0.5 accent-zentive-green-dark'
                    onCheckedChange={handleOpenChange}
                    checked={openChecked}
                />
                <span className='pl-2 text-black'>Open</span>
            </div>
            <div className='mt-3'>
                <Checkbox
                    className='mt-0.5 accent-zentive-green-dark'
                    onCheckedChange={handleCompletedChange}
                    checked={completedChecked}
                />
                <span className='pl-2 text-black'>Completed</span>
            </div>
        </div>
    )
}

export default StatusFilter
