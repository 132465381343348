import { convertImageToBase64 } from '@/utils/helper'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import { FaRegFilePdf } from 'react-icons/fa'
import { ReadJobType } from '@/api/job/schema'
import { generateJobPdf } from '@/utils/pdf'
import { useAtomValue } from 'jotai'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { FullProfileType } from '@/api/profile/schema'

interface IDownloadPDF {
    job: ReadJobType
}

const DownloadPDFButton = ({ job }: IDownloadPDF) => {
    // handle view as owner/admin
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    // owner/business details
    const ownerName = user?.business?.businessName as string
    const ownerEmail = user?.email as string
    const ownerAddress = `${user?.address?.streetAddress + ','} ${
        user?.address?.streetTwoAddress && user?.address?.streetTwoAddress != 'undefined'
            ? user?.address?.streetTwoAddress + ', '
            : ''
    }${user?.address?.city + ','} ${user?.address?.state + ','} ${user?.address?.zipCode}`

    const handleDownloadPDF = async () => {
        try {
            const zentiveLogoUrl = await convertImageToBase64(zentiveLogo)

            generateJobPdf(zentiveLogoUrl, job, ownerName, ownerEmail, ownerAddress)
        } catch (error) {
            console.error('Error converting image to Base64')
        }
    }

    return (
        <button
            className='w-full h-8 hover:bg-[#00000029] text-left px-3 whitespace-nowrap'
            onClick={handleDownloadPDF}
        >
            <FaRegFilePdf className='inline-block w-5 h-5 text-[#83265A]' />
            <span className='text-base'> Download PDF </span>
        </button>
    )
}
export default DownloadPDFButton
