import { createOrUpdateDealStage, getDealStagesByBusinessId } from '@/api/deal_stage'
import { Button } from '@/components/Button'
import { Drawer, DrawerClose, DrawerContent } from '@/components/Drawer'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { cn } from '@/utils/helper'
import { Cross1Icon } from '@radix-ui/react-icons'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ChangeEvent, ComponentProps, FC, useEffect, useState } from 'react'
import { FaPencilAlt } from 'react-icons/fa'

const CreateOrUpdateDealStage: FC<ComponentProps<typeof Drawer>> = (props) => {
    const currentUser = useViewedAsUser()
    const queryClient = useQueryClient()

    const [newDealStage, setNewDealStage] = useState<string>('')
    const [existingDealStage, setExistingDealStage] = useState<string>('')
    const [selectedDealStageId, setSelectedDealStageId] = useState<string>('')

    const { businessId = '' } = currentUser

    const {
        data: dealStages,
        isLoading,
        isSuccess,
    } = useQuery({
        enabled: !!(businessId && props?.open),
        queryKey: ['dealStages', businessId],
        queryFn: () => getDealStagesByBusinessId(businessId),
    })

    const resetStates = () => {
        setExistingDealStage('')
        setNewDealStage('')
        setSelectedDealStageId('')
    }

    const { mutate: newDealsStageMu, isPending: isNewDealsPending } = useMutation({
        mutationFn: () =>
            createOrUpdateDealStage({
                dealStageId: selectedDealStageId,
                businessId,
                label: !!selectedDealStageId ? existingDealStage : newDealStage,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['dealStages'] })
            resetStates()
        },
    })

    const handleNewDealStage = (evt: ChangeEvent<HTMLInputElement>) => {
        setNewDealStage(evt.target.value)
    }

    const handleExistingDealStage = (evt: ChangeEvent<HTMLInputElement>) => {
        setExistingDealStage(evt.target.value)
    }

    console.log('newDeal', newDealStage)

    useEffect(() => {
        if (!props?.open) resetStates()
    }, [props?.open])

    return (
        <Drawer {...props} direction='right'>
            <DrawerContent className='bg-white h-full w-[506px] rounded-b-[10px]' hideSlider>
                <div className='px-[36px]'>
                    <div className='h-fit w-full relative flex flex-col'>
                        <DrawerClose asChild>
                            <button
                                className='w-fit flex-none place-self-end mt-[36px]'
                                // onClick={() => setViewedLead(null)}
                                type='button'
                            >
                                <Cross1Icon />
                            </button>
                        </DrawerClose>
                    </div>

                    <p className='mt-[36px] text-[18px] text-zentive-black font-semibold'>
                        Edit Labels
                    </p>

                    <div className='my-[21px] h-[2px] bg-zentive-gray-light'></div>

                    {isLoading && <p>Retrieving Deal Stages...</p>}

                    {isSuccess && dealStages.length === 0 && <p>No Deal Stages found</p>}

                    {isSuccess && dealStages.length > 0 && (
                        <div className='flex flex-col gap-[22px]'>
                            {dealStages.map((dealStage) => {
                                if (dealStage.dealStageId !== selectedDealStageId) {
                                    return (
                                        <div
                                            className='flex flex-row gap-[23px]'
                                            key={dealStage.dealStageId}
                                        >
                                            <p>{dealStage.label}</p>
                                            <button
                                                onClick={() => {
                                                    setExistingDealStage(dealStage.label)
                                                    setSelectedDealStageId(dealStage.dealStageId)
                                                }}
                                                type='button'
                                            >
                                                <FaPencilAlt className='h-4 w-4 cursor-pointer text-indigo-900' />
                                            </button>
                                        </div>
                                    )
                                }

                                return (
                                    <div
                                        className='flex flex-row gap-[16px]'
                                        key={dealStage.dealStageId}
                                    >
                                        <input
                                            className={cn(
                                                'flex h-[45px] w-full rounded-sm border border-zentive-gray-medium bg-transparent px-3 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-[#ebebeb] disabled:text-zentive-gray-medium',
                                            )}
                                            onChange={handleExistingDealStage}
                                            placeholder='Label'
                                            required
                                            type='text'
                                            value={existingDealStage}
                                        />
                                        <Button
                                            className='w-3/5'
                                            disabled={
                                                existingDealStage.length === 0 || isNewDealsPending
                                            }
                                            onClick={() => newDealsStageMu()}
                                            type='button'
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            className='w-3/5'
                                            onClick={() => {
                                                setExistingDealStage('')
                                                setSelectedDealStageId('')
                                            }}
                                            type='button'
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                )
                            })}
                        </div>
                    )}

                    <p className='mt-[36px] text-[18px] text-zentive-black font-semibold'>
                        Add Labels
                    </p>

                    <div className='my-[21px] h-[2px] bg-zentive-gray-light'></div>

                    <div className='flex flex-row gap-[16px]'>
                        <input
                            className={cn(
                                'flex h-[45px] w-full rounded-sm border border-zentive-gray-medium bg-transparent px-3 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-[#ebebeb] disabled:text-zentive-gray-medium',
                            )}
                            onChange={handleNewDealStage}
                            placeholder='Label'
                            required
                            type='text'
                            value={newDealStage}
                        />
                        <Button
                            className='w-3/5'
                            disabled={newDealStage.length === 0 || isNewDealsPending}
                            onClick={() => newDealsStageMu()}
                            type='button'
                        >
                            Add
                        </Button>
                    </div>
                </div>
            </DrawerContent>
        </Drawer>
    )
}

export default CreateOrUpdateDealStage
