import { getBillingInfo, updateBillingInfo } from '@/api/profile'
import { BillingInfoType, FullProfileType, billingInfoSchema } from '@/api/profile/schema'
import { Button } from '@/components/Button'
import { toast } from '@/hooks/useToast'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import BusinessEditField from './BusinessInfo'
import LocationEditField from '@/components/CompleteAddress'
import BreadCrumbs from '@/components/BreadCrumbs'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'

const EditOwnerInfo = () => {
    const navigate = useNavigate()

    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const queryClient = useQueryClient()

    const { data: defaultValues } = useQuery<BillingInfoType, AxiosError, BillingInfoType>({
        queryKey: ['billingInfo', user?.profileId],
        queryFn: () => getBillingInfo(user?.profileId as string),
    })

    const updateMethods = useForm<BillingInfoType>({
        mode: 'onChange',
        resolver: zodResolver(billingInfoSchema),
    })

    const {
        handleSubmit,
        reset,
        watch,
        formState: { isValid },
    } = updateMethods

    const { mutate: updateBillingInfoMu, isPending: updateLoading } = useMutation({
        mutationFn: (data: BillingInfoType) => updateBillingInfo(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['billingInfo'] })
            queryClient.invalidateQueries({ queryKey: ['businessProfile'] })

            toast({
                description: 'Successfully Updated',
                variant: 'default',
            })

            navigate(-1)
        },
        onError: (err) => console.log(err),
    })

    const onSubmit = (data: BillingInfoType) => updateBillingInfoMu(data)

    const isSame = JSON.stringify(defaultValues) === JSON.stringify(watch())

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues)
        }
    }, [defaultValues])

    return (
        <FormProvider {...updateMethods}>
            <div className={`${BREADCRUMBS_PADDING_STYLE} flex flex-col justify-start'`}>
                <BreadCrumbs titleName='Update Billing Information' />
                <div className='max-w-[984px] bg-white rounded-b-[15px] mt-[36px]'>
                    <div className='px-[26px] pt-[47px] pb-[59px]'>
                        <BusinessEditField />
                        <LocationEditField
                            className='mt-[36px]'
                            streetAddressOne='address.streetOne'
                            streetAddressTwo='address.streetTwo'
                            title='Billing Address'
                        />
                    </div>

                    <div className='flex items-center justify-end pr-[25px] h-[75px] bg-zentive-gray-light gap-[12px] rounded-b-[15px]'>
                        <Button
                            onClick={() => navigate(-1)}
                            className='w-[95px] text-zentive-green-dark font-semibold text-lg'
                            variant='outline'
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSubmit(onSubmit)}
                            disabled={updateLoading || !isValid || isSame}
                            className='w-[95px]'
                        >
                            Update
                        </Button>
                    </div>
                </div>
            </div>
        </FormProvider>
    )
}

export default EditOwnerInfo
