import { ReadJobType } from '@/api/job/schema'
import dayjs from 'dayjs'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'

interface IJobLogNotes {
    job: ReadJobType | undefined
}

const JobLogNotes = ({ job }: IJobLogNotes) => {
    if (!job?.note)
        return (
            <div className='bg-white rounded-sm'>
                <div className='w-[1260px]'> </div>
                <div className='flex justify-center'>
                    <img
                        src={emptyPlaceholder}
                        alt='empty-table-placeholder'
                        className='my-12 h-60'
                    />
                </div>
                <p className='pb-4 text-center text-2xl text-gray-900'> Currently Empty </p>
            </div>
        )
    return (
        <div className='bg-white p-10 space-y-10 *:bg-[#EBEBEB] rounded-lg'>
            {job?.note?.map((note, index) => (
                <div key={index} className='flex flex-col border border-black p-6 rounded-lg'>
                    <div className='bg-red ml-auto'>
                        <p className='text-xs text-right text-gray-500'>
                            {dayjs(note?.createdAt).format('MMM DD, YYYY')}
                        </p>
                    </div>
                    <div className='bg-red mr-auto'>
                        <p className='text-lg mt-auto'>{note?.note ?? 'No notes.'}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}
export default JobLogNotes
