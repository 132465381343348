import { PaginationType } from '@/components/Pagination/schema'
import { axiosInstance } from '../axiosInstance'
import {
    DashboardExpensesRecords,
    DashboardInvoiceRecordsType,
    DashboardJobRecordsType,
    DashboardQuoteSentRecordsType,
    DashboardRevenueGrossNetMonthlyChartType,
    DashboardSubscriberChartMonthlyRecord,
    DashboardSubscriberCountType,
    ExpenseReportFilterType,
    ExpenseReportType,
    InvoiceReportFilterType,
    InvoiceReportType,
    JobCimpletionFilterType,
    JobCompletionReportType,
    JobsCompletedByEmployeeFilterType,
    JobsCompletedByEmployeeReportType,
    NetGrossFilterType,
    NetGrossReportType,
    QuoteReportFilterType,
    QuoteReportType,
    RevenueReportFilterType,
    RevenueReportType,
} from './schema'

export const getNetGrossReport = async (
    businessId: string,
    filter: NetGrossFilterType | null,
    p: PaginationType,
): Promise<NetGrossReportType> => {
    const res = await axiosInstance.get('/reports/gross-net-income', {
        params: {
            businessId: businessId,
            page: p.page,
            pageSize: p.pageSize,
            profileIds: filter?.profileIds?.join(','),
            productServices: filter?.productServices?.join(','),
            keyword: filter?.keyword,
            start: filter?.start,
            end: filter?.end,
        },
    })

    return res.data
}

export const getJobCompletionReport = async (
    businessid: string,
    filter: JobCimpletionFilterType | null,
    p: PaginationType,
): Promise<JobCompletionReportType> => {
    const res = await axiosInstance.get('/reports/job-completion', {
        params: {
            businessId: businessid,
            page: p.page,
            pageSize: p.pageSize,
            svcIds: filter?.svcIds?.join(','),
            keyword: filter?.keyword,
            start: filter?.start,
            end: filter?.end,
        },
    })

    return res.data
}

export const getJobCompletedByEmployeeReport = async (
    businessId: string,
    filter: JobsCompletedByEmployeeFilterType | null,
    p: PaginationType,
): Promise<JobsCompletedByEmployeeReportType> => {
    const res = await axiosInstance.get('/reports/jobs-completed-by-employee', {
        params: {
            businessId: businessId,
            page: p.page,
            pageSize: p.pageSize,
            svcIds: filter?.svcIds?.join(','),
            profileIds: filter?.employeeIds?.join(','),
            keyword: filter?.keyword,
            start: filter?.start,
            end: filter?.end,
        },
    })

    return res.data
}

export const getInvoiceReport = async (
    businessId: string,
    filter: InvoiceReportFilterType,
    p: PaginationType,
): Promise<InvoiceReportType> => {
    const res = await axiosInstance.get(`/reports/invoice-report`, {
        params: {
            businessId: businessId,
            page: p.page,
            pageSize: p.pageSize,
            profileIds: filter?.profileIds?.join(','),
            keyword: filter?.keyword,
            status: filter?.status?.join(','),
            start: filter?.start,
            end: filter?.end,
        },
    })

    return res.data
}

export const getExpenseReport = async (
    businessId: string,
    filter: ExpenseReportFilterType,
    p: PaginationType,
): Promise<ExpenseReportType> => {
    const res = await axiosInstance.get(`/reports/expense-report`, {
        params: {
            businessId: businessId,
            page: p.page,
            pageSize: p.pageSize,
            keyword: filter?.keyword,
            type: filter?.type,
            start: filter?.start,
            end: filter?.end,
        },
    })

    return res.data
}

export const getQuoteReport = async (
    businessId: string,
    filter: QuoteReportFilterType,
    p: PaginationType,
): Promise<QuoteReportType> => {
    const res = await axiosInstance.get(`reports/quote-report`, {
        params: {
            businessId: businessId,
            page: p.page,
            pageSize: p.pageSize,
            customerIds: filter?.customerIds.join(','),
            status: filter?.status.join(','),
            keyword: filter?.keyword,
            start: filter?.start,
            end: filter?.end,
        },
    })

    return res.data
}

export const getRevenueReport = async (
    filter: RevenueReportFilterType,
    p: PaginationType,
): Promise<RevenueReportType> => {
    const res = await axiosInstance.get(`reports/revenue`, {
        params: {
            page: p.page,
            pageSize: p.pageSize,
            keyword: filter?.keyword,
            start: filter?.start,
            end: filter?.end,
        },
    })

    return res.data
}

export const getOwnerJobsDashboardRecords = async (
    businessId: string,
    start: string,
): Promise<DashboardJobRecordsType> => {
    const res = await axiosInstance.get(`/reports/dashboard/owner/job-chart`, {
        params: {
            businessId: businessId,
            start: start,
        },
    })

    return res.data.content
}

export const getDashboardQuoteSentChartRecords = async (
    businessId: string,
    start: string,
    end: string,
): Promise<DashboardQuoteSentRecordsType> => {
    const res = await axiosInstance.get(`/reports/dashboard/owner/quote-chart`, {
        params: {
            businessId: businessId,
            start: start,
            end: end,
        },
    })

    return res.data.content
}

export const getDashboardMonthlyIncomeChart = async (
    businessId: string,
): Promise<DashboardRevenueGrossNetMonthlyChartType> => {
    const res = await axiosInstance.get(`/reports/dashboard/owner/income-chart`, {
        params: {
            businessId: businessId,
        },
    })

    return res.data.content
}

export const getDashboardInvoiceChart = async (
    businessId: string,
    start: string,
    end: string,
): Promise<DashboardInvoiceRecordsType> => {
    const res = await axiosInstance.get(`/reports/dashboard/owner/invoice-chart`, {
        params: {
            businessId: businessId,
            start: start,
            end: end,
        },
    })

    return res.data.content
}

export const getDashboardExpenseChart = async (
    businessId: string,
    start: string,
    end: string,
): Promise<DashboardExpensesRecords> => {
    const res = await axiosInstance.get(`/reports/dashboard/owner/expense-chart`, {
        params: {
            businessId: businessId,
            start: start,
            end: end,
        },
    })

    return res.data.content
}

export const getAdminDashboardSubscriberCount = async (): Promise<DashboardSubscriberCountType> => {
    const res = await axiosInstance.get(`/reports/dashboard/admin/subscriber-count`)

    return res.data.content
}

export const getAdminDashboardSubscriberChart =
    async (): Promise<DashboardSubscriberChartMonthlyRecord> => {
        const res = await axiosInstance.get(`/reports/dashboard/admin/subscriber-chart`)

        return res.data.content
    }
