import { ReadJobType } from '@/api/job/schema'
import { ABBREVIATED_DATE_12_HOUR, APPOINTMENT_TYPES } from '@/constants'
import { TimeZoneResult } from '@/hooks/useGeocode'
import { timezoneAtom } from '@/store/auth'
import { formatToUTCWithOffset } from '@/utils/time'
import { useAtomValue } from 'jotai'

interface ViewJobProps {
    job: ReadJobType
}

const JobDetails = ({ job }: ViewJobProps) => {
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult

    const formattedStartDate = formatToUTCWithOffset(
        job?.appointment?.startDateTime as string,
        timezone?.timeZoneId,
        ABBREVIATED_DATE_12_HOUR,
    )

    const formattedEndDate = formatToUTCWithOffset(
        job?.appointment?.endDateTime as string,
        timezone?.timeZoneId,
        ABBREVIATED_DATE_12_HOUR,
    )

    return (
        <div className='flex flex-row mt-4 gap-x-2'>
            <div className='flex-1'>
                <p className='text-2xl font-bold mt-5 ml-4'>
                    {job?.quote?.profile?.firstName + ' ' + job?.quote?.profile?.lastName}
                </p>
                <div className='flex px-4 justify-between py-4'>
                    <div className='flex flex-row  w-full gap-x-10'>
                        <div>
                            <p className='text-md font-bold'>Property Address</p>
                            <p className='text-md'>{`${job?.address?.streetAddress + ','} ${
                                job?.address?.streetTwoAddress &&
                                job?.address?.streetTwoAddress != 'undefined'
                                    ? job?.address?.streetTwoAddress + ', '
                                    : ''
                            }${job?.address?.city + ','} ${job?.address?.state + ','} ${job?.address
                                ?.zipCode}`}</p>
                        </div>
                        <div>
                            <p className='text-md font-bold'>Contact Details</p>
                            <p className='text-md'>{job?.quote?.profile?.email ?? ''}</p>
                            <p className='text-md'>{job?.quote?.profile?.phoneNumber ?? ''}</p>
                        </div>
                    </div>
                </div>
                <div className='bg-white ml-4 p-5 w-[27rem] 2xl:w-[50rem] h-32 max-h-36 overflow-y-scroll green-scrollbar'>
                    <p className='text-md'>Notes:</p>
                    {job?.note?.map((singleNote, index) => (
                        <p key={index} className='text-md py-1 w-[27rem] 2xl:w-[50rem] p-5 pr-10 break-all'>
                            {singleNote?.note ? `- ${singleNote?.note}` : 'N/A'}
                        </p>
                    ))}
                </div>
            </div>
            <div className='flex-1 bg-white'>
                <div className='justify-between'>
                    <div className=''>
                        <p className='p-2 text-md font-bold'>Job Details</p>
                        <div className='flex flex-row justify-between border-b py-2 px-4'>
                            <p className='text-md'>Job Type</p>
                            <p className='text-md'>
                                {job?.appointment?.appointmentType === APPOINTMENT_TYPES.ONEOFF_JOB
                                    ? 'One Time Job'
                                    : 'Recurring Job'}
                            </p>
                        </div>
                        <div className='flex flex-row justify-between border-b py-2 px-4'>
                            <p className='text-md'>Available Anytime</p>
                            <p className='text-md'>{job.appointment.availableAnytime ? "Yes" : "No"}</p>
                        </div>
                        <div className='flex flex-row justify-between border-b py-2 px-4'>
                            <p className='text-md'>{job.appointment.availableAnytime ? "Prefer To Start On": "Starts On"}</p>
                            <p className='text-md'>{formattedStartDate}</p>
                        </div>
                        <div className='flex flex-row justify-between border-b py-2 px-4'>
                            <p className='text-md'>Ends on</p>
                            <p className='text-md'>{formattedEndDate}</p>
                        </div>
                        <div className='flex flex-row justify-between border-b py-2 px-4'>
                            <p className='text-md'>Time Zone</p>
                            <p className='text-md'>{timezone ? timezone.timeZoneName : ''}</p>
                        </div>
                        <div className='flex flex-row justify-between border-b py-2 px-4'>
                            <p className='text-md'>Invoice Status</p>
                            <p className='text-md'>Upon Job Completion</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JobDetails
