import { InvoiceReportFilterType } from '@/api/reports/schema'
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { Button } from '@/components/Button'
import { RadioGroup, RadioGroupItem } from '@/components/RadioGroup'
import { invoiceReportFilterAtom } from '@/store/reports'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'

import { INVOICE_STATUS_LABEL } from '@/constants'

export const InvoiceStatusFilter = () => {
    const [open, setIsOpen] = useState<boolean>(false)

    const [invoiceReportFilter, setInvoiceReportFilter] = useAtom(invoiceReportFilterAtom)
    const [statusFilter, setStatusFilter] = useState<InvoiceReportFilterType | null>(null)

    const handleAddStatusFilter = (status: string) => {
        setStatusFilter((prevFilter) => {
            if (!prevFilter) {
                return {
                    profileIds: [],
                    status: [status],
                    start: '',
                    end: '',
                    keyword: '',
                }
            }

            const updatedStatuses = prevFilter.status?.includes(status)
                ? prevFilter.status.filter((s) => s !== status)
                : [...(prevFilter.status || []), status]

            return {
                ...prevFilter,
                status: updatedStatuses,
            }
        })
    }
    const handleSaveStatusFilter = () => {
        if (!statusFilter) return

        setInvoiceReportFilter((prevInvoiceReportFilter) => {
            const newStatuses = statusFilter.status || []
            const existingStatuses = prevInvoiceReportFilter?.status || []

            const profileIds = prevInvoiceReportFilter?.profileIds || []
            const keyword = prevInvoiceReportFilter?.keyword || ''
            const start = prevInvoiceReportFilter?.start || ''
            const end = prevInvoiceReportFilter?.end || ''

            // Keep only statuses that are in the new status filter and remove others
            const filteredStatuses = existingStatuses.filter((s) => newStatuses.includes(s))

            // Add new statuses and ensure no duplicates
            const mergedStatuses = Array.from(new Set([...filteredStatuses, ...newStatuses]))

            return {
                ...prevInvoiceReportFilter,
                status: mergedStatuses,
                profileIds,
                keyword,
                start,
                end,
            }
        })

        setIsOpen(false)
    }

    const handleClearFilters = () => {
        setInvoiceReportFilter({
            profileIds: [],
            keyword: invoiceReportFilter?.keyword ?? '',
            status: [],
            start: '',
            end: '',
        })

        setIsOpen(false)
    }

    useEffect(() => {
        if (open) {
            setStatusFilter({
                profileIds: invoiceReportFilter?.profileIds || [],
                status: invoiceReportFilter?.status || [],
                start: invoiceReportFilter?.start || '',
                end: invoiceReportFilter?.end || '',
                keyword: invoiceReportFilter?.keyword ?? '',
            })
        }
    }, [open])

    return (
        <AlertDialog open={open} onOpenChange={setIsOpen} key={'customer-filter'}>
            <AlertDialogTrigger asChild onClick={() => {}}>
                <button type='button' className='button-outline'>
                    Invoice Status
                </button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white rounded-[15px] p-0 max-w-[38rem]'>
                <div className='flex flex-col gap-5 px-16'>
                    <AlertDialogTitle className='text-left font-semibold text-lg text-[#191A0A] py-10 pb-0'>
                        <p className='text-2xl font-bold text-left'>Filter by Invoice Status</p>
                    </AlertDialogTitle>
                    <div className='p-5 flex flex-row border border-1 border-zentive-gray-light'>
                        <RadioGroup>
                            {Object.keys(INVOICE_STATUS_LABEL).map((status) => (
                                <div className='flex flex-row gap-2 items-center' key={status}>
                                    <RadioGroupItem
                                        checked={statusFilter?.status?.includes(status)}
                                        value={status}
                                        onClick={() => handleAddStatusFilter(status)}
                                    />
                                    <p>
                                        {
                                            INVOICE_STATUS_LABEL[
                                                status as keyof typeof INVOICE_STATUS_LABEL
                                            ]
                                        }
                                    </p>
                                </div>
                            ))}
                        </RadioGroup>
                    </div>
                </div>

                <AlertDialogFooter className='py-5 w-full rounded-b-md items-center justify-center bg-zentive-gray-light max-w-[38rem]'>
                    <div className='flex flex-row gap-2'>
                        <Button
                            type='button'
                            variant='link'
                            className='w-[8rem] font-bold text-zentive-blue-dark text-md'
                            onClick={handleClearFilters}
                        >
                            Clear Filters
                        </Button>
                        <div className='flex flex-row gap-5 mr-5'>
                            <Button
                                type='button'
                                variant='outline'
                                className='w-[11rem]'
                                onClick={() => {
                                    setIsOpen(!open)
                                    setStatusFilter(null)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='button'
                                disabled={!statusFilter}
                                className={`w-[11rem]`}
                                onClick={handleSaveStatusFilter}
                            >
                                Apply
                            </Button>
                        </div>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
