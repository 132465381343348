import { CardContent, CardFooter, CardTitle } from '@/components/Card'
import { Tabs, TabsList, TabsTrigger } from '@/components/Tabs'
import GalleryTable from '@/pages/public/SignUp/SignUpGallery/Table'
import { ANNUALLY, MONTHLY } from '@/assets/static/signupGallery'
import ActionButton from '../../ActionButton'
import { useMemo, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { SubscriptionType } from '@/api/subscription/schema'
import { AxiosError } from 'axios'
import { getPricing } from '@/api/subscription'
import { useAtomValue, useSetAtom } from 'jotai'
import { signUpGalleryAtom } from '@/store/auth'
import SetupPaymentModal from '@/components/SetupPaymentModal'
import { isFreeTrialEndedAtom } from '@/store/owner'

const SubscriptionPlans = () => {
    const [interval, setInterval] = useState<string>('month')
    const setSelected = useSetAtom(signUpGalleryAtom)
    const isFreeTrialEnded = useAtomValue(isFreeTrialEndedAtom)

    const { mutate: getPricingMu } = useMutation<SubscriptionType[], AxiosError, string>({
        mutationKey: ['Pricing'],
        mutationFn: (param) => getPricing(param),
        onSuccess: (data) => setSelected(data),
        onError: (err) => console.log(err),
    })

    useMemo(() => {
        getPricingMu(interval)
    }, [interval])

    return (
        <>
            <SetupPaymentModal />
            <section>
                {!isFreeTrialEnded && (
                    <p className='mt-9 text-zentive-gray-medium font-bold '>Subscription Plans</p>
                )}
                <div className='bg-white pt-2 w-full rounded-md mt-3 shadow-md'>
                    {isFreeTrialEnded && (
                        <CardTitle className='text-[#3B671A] text-center text-[24px] mt-5 pb-2'>
                            Pricing
                        </CardTitle>
                    )}
                    <div className={`${isFreeTrialEnded ? '' : 'my-10'}`}>
                        <CardContent className='p-0 shadow-md'>
                            <div className='flex justify-center mt-4 mb-14 '>
                                <Tabs
                                    defaultValue='month'
                                    onValueChange={(val) => setInterval(val)}
                                    className='w-[400px] '
                                >
                                    <TabsList className='grid w-full grid-cols-2 rounded-full bg-[#0C3D3C]'>
                                        <TabsTrigger
                                            value='month'
                                            className=' data-[state=active]:bg-transparent data-[state=active]:rounded-full data-[state=active]:text-white data-[state=inactive]:bg-white data-[state=inactive]:rounded-full data-[state=inactive]:text-gray-900'
                                        >
                                            Monthly
                                        </TabsTrigger>
                                        <TabsTrigger
                                            value='year'
                                            className=' data-[state=active]:bg-transparent data-[state=active]:rounded-full data-[state=active]:text-white data-[state=inactive]:bg-white data-[state=inactive]:rounded-full data-[state=inactive]:text-gray-900'
                                        >
                                            Annually
                                        </TabsTrigger>
                                    </TabsList>
                                </Tabs>
                            </div>
                            {
                                {
                                    month: <GalleryTable tableData={MONTHLY} interval={interval} />,
                                    year: <GalleryTable tableData={ANNUALLY} interval={interval} />,
                                }[interval]
                            }
                        </CardContent>
                        <CardFooter
                            className='
                        bg-zentive-gray-light p-0 pb-4 
                        xl:pr-4 
                        2xl:pr-12
                        w2xl:pr-[60px]
                        3xl:pr-24
                        4xl:pr-52
                        shadow-md rounded-sm'
                        >
                            <ActionButton />
                        </CardFooter>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SubscriptionPlans
