import { getAllJobsByCustomerProfileID } from '@/api/job'
import { ReadJobType } from '@/api/job/schema'
import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { DROPDOWN_LABELS } from '@/constants'
import { businessIdAtom, userAtom } from '@/store/auth'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri'

const Fields = () => {
    const {
        control,
        setValue,
        formState: { isValid },
        watch
    } = useFormContext()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [selectedJob, setSelectedJob] = useState<string>('')
    console.log('watch', watch())
    const user = useAtomValue(userAtom)
    const businessId = useAtomValue(businessIdAtom)
    console.log('isValid', isValid)
    const { data: jobs } = useQuery({
        enabled: !!user?.profileId && !!businessId,
        queryKey: ['jobsByQuoteProfileId', user?.profileId, businessId],
        queryFn: () => getAllJobsByCustomerProfileID(String(user?.profileId), businessId),
    })

    const handleOnChange = (job: string) => {
        setIsOpen(false)

        if (job === DROPDOWN_LABELS.jobsByQuoteProfile) {
            setSelectedJob('')
            setValue('jobId', undefined)
        } else {
            const parsedJob: ReadJobType = JSON.parse(job)
            setSelectedJob(job)
            setValue('jobId', parsedJob.jobId)
        }
    }

    const handleFocus = () => {
        setIsOpen(true)
    }

    const handleBlur = () => {
        setIsOpen(false)
    }

    return (
        <div className='space-y-4'>
            <FormField
                control={control}
                name='email'
                render={({ field }) => (
                    <FormItem>
                        <FormControl>
                            <Input
                                className='font-sans pl-3 w-full text-base h-11 border border-black text-[#191A0A] rounded-sm'
                                type='text'
                                placeholder='Recipient*'
                                disabled
                                {...field}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <div className='relative z-10'>
                <select
                    name='row'
                    onFocus={() => handleFocus()}
                    onBlur={handleBlur}
                    onChange={(val) => handleOnChange(val.target.value)}
                    onMouseDown={() => setIsOpen((prev) => !prev)}
                    className='flex 2xl:w-96 xl:w-44 lg:w-36 font-sans text-base pl-3 pr-8 h-11 border border-black disabled:bg-gray-100 text-gray-900 rounded-sm mr-4 bg-white appearance-none'
                >
                    <option className='text-lg text-zentive-gray-medium'>
                        {DROPDOWN_LABELS.jobsByQuoteProfile}
                    </option>
                    {jobs?.content?.map((job) => (
                        <option
                            value={JSON.stringify(job)}
                            key={job.jobNumber}
                            hidden={selectedJob.includes(JSON.stringify(job))}
                            className='text-lg text-zentive-gray-medium'
                        >
                            {job.jobNumber}
                        </option>
                    ))}
                </select>
                <div className='absolute inset-y-0 2xl:left-80 md:left-32 left-20 flex items-center pr-1 ml-6 pointer-events-none'>
                    {isOpen ? (
                        <RiArrowDropUpLine className='h-5 w-5 text-gray-500' />
                    ) : (
                        <RiArrowDropDownLine className='h-5 w-5 text-gray-500' />
                    )}
                </div>
            </div>
            <FormField
                control={control}
                name='address'
                render={({ field }) => (
                    <FormItem>
                        <FormControl>
                            <Input
                                className='font-sans pl-3 w-full text-base h-11 border border-black text-[#191A0A] rounded-sm'
                                type='text'
                                placeholder='Address*'
                                disabled
                                {...field}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
        </div>
    )
}
export default Fields
