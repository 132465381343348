import { checkEmailValidity, crewForgotPassword } from '@/api/auth'
import {
    CrewForgotPasswordType,
    EmailValidityType,
} from '@/api/auth/schema'
import { Button } from '@/components/Button'
import { encryptString } from '@/utils/crypto'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { cn } from '@/utils/helper'

interface ActionButtonProps {
    buttonContainerClassName?: string
}

const ActionButton: React.FC<ActionButtonProps> = ({ buttonContainerClassName }) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState<string>('')

    const { handleSubmit, setError, watch } = useFormContext<CrewForgotPasswordType>()
    const emailValue = watch('email') // check if there is email input value

    const { mutate: checkEmailValidityMu } = useMutation({
        mutationFn: checkEmailValidity,
        onSuccess: (data: EmailValidityType) => {
            const emailOnState = email

            if (data.isEmailExist === true) {
                resetPasswordMu({ email: emailOnState, lang: 'en' })
            } else {
                setError('email', { message: 'Email does not exist.' })
            }
        },
        onError: (err: AxiosError) => console.log(err),
    })

    const { mutate: resetPasswordMu, isPending: isPasswordResetLoading } = useMutation({
        mutationFn: (data: CrewForgotPasswordType) => crewForgotPassword(data),
        onSuccess: () => {
            const encryptedEmail = encryptString(email)
            navigate(`/check-email?email=${encryptedEmail}`)
            setEmail('')
        },
        onError: (err: AxiosError) => {
            const defaultErrMessage = 'Error occurred, please try again later'
            if (err.request.status === 500) {
                setError('email', {
                    type: 'manual',
                    message: 'Unverified email',
                })
            } else {
                setError('email', {
                    type: 'manual',
                    message: defaultErrMessage,
                })
            }
        },
    })

    const onSubmit = (data: EmailValidityType) => {
        setEmail(data.email)
        checkEmailValidityMu({ email: data.email })
    }

    return (
        <div
            className={cn(
                'flex flex-col md:flex-row items-center justify-center space-x-0 md:space-x-4 mt-2',
                buttonContainerClassName,
            )}
        >
            <Button className='w-97' onClick={() => navigate('/')} variant={'outline'}>
                Back
            </Button>
            <Button
                className='w-97'
                disabled={isPasswordResetLoading || !emailValue} // disable if email is empty / no input yet
                type='submit'
                onClick={handleSubmit(onSubmit)}
            >   
                Send Reset Link
            </Button>   
        </div>
    )
}

export default ActionButton
