import { axiosInstance } from '../axiosInstance'
import { NotePayloadType, NoteResponseType } from './schema'

export const getBusinessNotes = async (profileId: string) => {
    const response = await axiosInstance.get('/subscription/notes?profileId=' + profileId)

    return response.data as NoteResponseType
}

export const getEmployeeNotes = async (profileId: string) => {
    const response = await axiosInstance.get('/subscription/notes?profileId=' + profileId)

    return response.data as NoteResponseType
}

export const addBusinessNotes = async (newNote: NotePayloadType) => {
    const response = await axiosInstance.post('/subscription/notes/add', newNote)

    return response.data as NoteResponseType
}

export const updateBusinessNotes = async (newNote: NotePayloadType) => {
    const response = await axiosInstance.post('/subscription/notes/update', newNote)

    return response.data as NoteResponseType
}

export const deleteBusinessNotes = async (noteId: string) => {
    await axiosInstance.delete('/subscription/notes/delete/' + noteId)
}
