import { GetJobLogsByBusinessIdParams, getJobLogsByBusinessId } from '@/api/job'
import { JobListType } from '@/api/job/schema'
import { FullProfileType } from '@/api/profile/schema'
import BreadCrumbs from '@/components/BreadCrumbs'
import SearchBar from '@/components/InputSearchBox'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import {
    itemsPerPageAtom,
    jobLogPaginationAtom,
    selectedCrewIdsAtom,
    selectedStatusAtom,
} from '@/store/jobLog'
import { cn } from '@/utils/helper'
import { useMutation } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import { Pagination } from '@/components/Pagination'
import JobLogTable from './JobLogTable'
import Tooltip from '@/components/Tooltip'
import { useMediaQuery } from 'react-responsive'
import JobExportDropdown from './ExportDropdown'
import JobLogFilterButton from './JobLogFilterButton'
import { AxiosError } from 'axios'
import { jobListAtom } from '@/store/job'
import debounce from 'debounce'

const JobLogs = () => {
    const [keyWord, setKeyWord] = useState<string>('')
    const [pagination, setPagination] = useAtom(jobLogPaginationAtom)
    const selectedCrewIds = useAtomValue(selectedCrewIdsAtom)
    const selectedStatus = useAtomValue(selectedStatusAtom)
    const [jobs, setJobs] = useAtom(jobListAtom)
    const [selectedItemsPerPage] = useAtom(itemsPerPageAtom)

    //handle view as owner/admin
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    const handleSearch = (query: string) => {
        setKeyWord(query)
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 1,
        }))
    }

    const debouncedSearch = debounce((query: string) => {
        handleSearch(query)
    }, 500)

    const { mutate: getJobLogsMu } = useMutation<
        JobListType,
        AxiosError,
        GetJobLogsByBusinessIdParams
    >({
        mutationFn: (data) => getJobLogsByBusinessId(data),
        onSuccess: (data) => {
            setJobs(data)
        },
        onError: (error) => {
            console.error('Error fetching job logs:', error)
        },
    })

    const isTableEmpty = () => {
        if (!jobs || !jobs.content) {
            return true
        }
        if (!keyWord) {
            return jobs.content?.length === 0
        }
        return false
    }

    const xl_vw_already = useMediaQuery({ maxWidth: 1370 })
    const xxl_vw_already = useMediaQuery({ maxWidth: 1620 })

    useEffect(() => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 1,
            pageSize: selectedItemsPerPage,
        }))
    }, [selectedItemsPerPage, setPagination])

    useEffect(() => {
        if (user?.businessId) {
            getJobLogsMu({
                businessId: user.businessId,
                keyword: keyWord,
                crewIds: selectedCrewIds,
                status: selectedStatus,
                p: pagination,
            })
        }
    }, [
        keyWord,
        selectedCrewIds,
        selectedStatus,
        pagination,
        selectedItemsPerPage,
        getJobLogsMu,
        user.businessId,
    ])

    return (
        <div className='w-full h-full'>
            <div className='mx-[-31px] my-[-40px]'>
                <div className='bg-zentive-green-dark w-full px-[101px] py-9 mb-6'>
                    <BreadCrumbs titleName='Job Log'/>
                </div>
            </div>
            <div className={cn(BREADCRUMBS_PADDING_STYLE)}>
                <div className='flex justify-between py-4'>
                    <div>
                        <SearchBar placeHolder='Search for...' onSearchChange={(e) => debouncedSearch(e.target.value)} />
                    </div>
                    <div className='flex gap-x-4'>
                        {jobs && (
                            <div className={cn('flex gap-x-4 text-zentive-black text-base')}>
                                <Tooltip position='top' content='Filter'>
                                    <p
                                        className={cn(
                                            xxl_vw_already ? 'w-24 truncate' : 'w-full',
                                            xl_vw_already && 'w-full',
                                        )}
                                    >
                                        <JobLogFilterButton />
                                    </p>
                                </Tooltip>
                                <JobExportDropdown jobs={jobs} isDisabled={isTableEmpty()} />
                            </div>
                        )}
                    </div>
                </div>
                {jobs && jobs?.content?.length >= 1 && <JobLogTable jobs={jobs} />}
                {!!jobs?.content?.length && (
                    <div className='mb-5 mt-3'>
                        {pagination.pageSize !== undefined && (
                            <Pagination
                                itemsPerPage={pagination.pageSize}
                                setPagination={setPagination}
                                totalRecords={jobs?.meta.totalRecords ?? 1}
                                pagination={pagination}
                            />
                        )}
                    </div>
                )}
                {!jobs?.content?.length && (
                    <div className='bg-white rounded-sm'>
                        <div className='w-[1260px]'> </div>
                        <div className='flex justify-center'>
                            <img
                                src={emptyPlaceholder}
                                alt='empty-table-placeholder'
                                className='my-12 h-60'
                            />
                        </div>
                        <p className='pb-4 text-center text-2xl text-gray-900'> Currently Empty </p>
                    </div>
                )}
            </div>    
        </div>
    )
}

export default JobLogs
