import { getQuoteReport } from '@/api/reports'
import { QuoteReportFilterType, QuoteReportRecordType, QuoteReportType } from '@/api/reports/schema'
import { Button } from '@/components/Button'
import { Card, CardContent } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import { Pagination } from '@/components/Pagination'
import { PaginationType } from '@/components/Pagination/schema'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import {
    QUOTE_LABEL_COLOR,
    QUOTE_STATUS,
    QUOTE_STATUS_LABEL,
    RECORD_PREFIX,
    US_FORMAT,
} from '@/constants'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { quoteReportExportAtom, quoteReportFilterAtom } from '@/store/reports'
import { cn, displayCurrency, formatRecordNumber } from '@/utils/helper'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useAtom, useAtomValue } from 'jotai'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { QuoteReportOverview } from './QuoteReportOverview'

const tableHeader = [
    'Quote Number',
    'Job Type',
    'Quote Price',
    'Customer Name',
    'Email Address',
    'Contact Number',
    'Date',
    'Status',
    'Processed By',
]

const QuoteReportTable = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const quoteReportFilter = useAtomValue(quoteReportFilterAtom)
    const [exportAtom, setExportAtom] = useAtom(quoteReportExportAtom)

    const [isAllChecked, setIsAllChecked] = useState(false)

    const [pagination, setPagination] = useState<PaginationType>({
        page: 1,
        pageSize: 5,
    })

    const { data: report } = useQuery<QuoteReportType>({
        queryKey: ['quoteReport', user?.businessId, quoteReportFilter, pagination],
        queryFn: () =>
            getQuoteReport(
                user?.businessId as string,
                quoteReportFilter as QuoteReportFilterType,
                pagination,
            ),
    })

    const handleCheckAllChange = (isChecked: boolean) => {
        setIsAllChecked(isChecked)

        if (isChecked) {
            // Add all available records to exportAtom
            const allRecords = report?.content?.report ?? []

            setExportAtom({
                records: allRecords,
                acceptedCount: report?.content?.acceptedCount ?? 0,
                acceptedAmount: report?.content?.acceptedAmount ?? 0,
                deniedCount: report?.content?.deniedCount ?? 0,
                deniedAmount: report?.content?.deniedAmount ?? 0,
                pendingCount: report?.content?.pendingCount ?? 0,
                pendingAmount: report?.content?.pendingAmount ?? 0,
                totalRecords: report?.meta?.totalRecords ?? 0,
                totalAmount: report?.content?.totalAmount ?? 0,
            })
        } else {
            // Clear all records from exportAtom
            setExportAtom({
                records: [],
                acceptedCount: 0,
                acceptedAmount: 0,
                deniedCount: 0,
                deniedAmount: 0,
                pendingCount: 0,
                pendingAmount: 0,
                totalRecords: 0,
                totalAmount: 0,
            })
        }
    }

    const handleCheckChange = (quote: QuoteReportRecordType) => {
        setExportAtom((prev) => {
            if (!prev) {
                // If there is no existing filter, create one with the new quoteId
                return {
                    records: [quote],
                    acceptedCount: quote.status === QUOTE_STATUS.ACCEPTED ? 1 : 0,
                    acceptedAmount: quote.status === QUOTE_STATUS.ACCEPTED ? quote?.totalAmount : 0,
                    deniedCount: quote.status === QUOTE_STATUS.DENIED ? 1 : 0,
                    deniedAmount: quote.status === QUOTE_STATUS.DENIED ? quote?.totalAmount : 0,
                    pendingCount: quote.status === QUOTE_STATUS.PENDING ? 1 : 0,
                    pendingAmount: quote.status === QUOTE_STATUS.PENDING ? quote?.totalAmount : 0,
                    totalRecords: 1,
                    totalAmount: quote?.totalAmount,
                }
            }

            const isQuoteSelected = prev.records?.some((record) => record.quoteId === quote.quoteId)

            // Update records based on whether the quote is being added or removed
            const updatedQuotes = isQuoteSelected
                ? prev.records.filter((record) => record.quoteId !== quote.quoteId) // Remove if exists
                : [...(prev.records || []), quote] // Add if it doesn't exist

            return {
                records: updatedQuotes,
                acceptedCount:
                    quote.status === QUOTE_STATUS.ACCEPTED
                        ? isQuoteSelected
                            ? prev.acceptedCount - 1
                            : prev.acceptedCount + 1
                        : prev.acceptedCount,
                acceptedAmount:
                    quote.status === QUOTE_STATUS.ACCEPTED
                        ? isQuoteSelected
                            ? prev.acceptedAmount - quote.totalAmount
                            : prev.acceptedAmount + quote.totalAmount
                        : prev.acceptedAmount,
                deniedCount:
                    quote.status === QUOTE_STATUS.DENIED
                        ? isQuoteSelected
                            ? prev.deniedCount - 1
                            : prev.deniedCount + 1
                        : prev.deniedCount,
                deniedAmount:
                    quote.status === QUOTE_STATUS.DENIED
                        ? isQuoteSelected
                            ? prev.deniedAmount - quote.totalAmount
                            : prev.deniedAmount + quote.totalAmount
                        : prev.deniedAmount,
                pendingCount:
                    quote.status === QUOTE_STATUS.PENDING
                        ? isQuoteSelected
                            ? prev.pendingCount - 1
                            : prev.pendingCount + 1
                        : prev.pendingCount,
                pendingAmount:
                    quote.status === QUOTE_STATUS.PENDING
                        ? isQuoteSelected
                            ? prev.pendingAmount - quote.totalAmount
                            : prev.pendingAmount + quote.totalAmount
                        : prev.pendingAmount,
                totalRecords: isQuoteSelected ? prev.totalRecords - 1 : prev.totalRecords + 1,
                totalAmount: isQuoteSelected
                    ? prev.totalAmount - quote.totalAmount
                    : prev.totalAmount + quote.totalAmount,
            }
        })
    }

    return (
        <>
            <QuoteReportOverview report={report as QuoteReportType} />
            <Card>
                <CardContent className='overflow-x-scroll green-scrollbar rounded-t-none pb-0'>
                    <Table className='table-auto whitespace-normal mx-auto text-zentive-black text-sm'>
                        <TableHeader className='whitespace-nowrap'>
                            <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                                {tableHeader.map((header, index) => (
                                    <TableHead
                                        key={index}
                                        className={cn(
                                            'font-semibold text-zentive-gray-medium text-md whitespace-nowrap',
                                        )}
                                    >
                                        <div className='flex flex-row items-center justify-start gap-2'>
                                            {' '}
                                            {index === 0 && (
                                                <Checkbox
                                                    onCheckedChange={handleCheckAllChange}
                                                    checked={
                                                        isAllChecked ||
                                                        exportAtom?.records?.length ===
                                                            report?.content?.report?.length
                                                    }
                                                    className='mt-[1px]'
                                                />
                                            )}
                                            {header}
                                        </div>
                                    </TableHead>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {Array.isArray(report?.content?.report) &&
                                report?.content?.report?.length > 0 &&
                                report?.content?.report?.map((r: QuoteReportRecordType) => (
                                    <TableRow
                                        key={r.quoteId as string}
                                        className='whitespace-nowrap'
                                    >
                                        <TableCell className='text-zentive-black  flex flex-row gap-2 items-center'>
                                            <Checkbox
                                                onCheckedChange={() => handleCheckChange(r)}
                                                checked={exportAtom?.records?.some(
                                                    (record) => r.quoteId === record.quoteId,
                                                )}
                                            />
                                            <NavLink
                                                to={
                                                    r.quoteNumber
                                                        ? `/financial-management/quotes/view?quoteId=${r.quoteId}`
                                                        : '#'
                                                }
                                            >
                                                <Button
                                                    className=' text-zentive-blue-dark font-semibold'
                                                    variant={'link'}
                                                >
                                                    {r.quoteNumber
                                                        ? RECORD_PREFIX.quote +
                                                          formatRecordNumber(r.quoteNumber)
                                                        : 'N/A'}
                                                </Button>
                                            </NavLink>
                                        </TableCell>
                                        <TableCell>
                                            <p>{r.services.join(',')}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{displayCurrency(r.totalAmount)}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{r.customerName}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{r.email}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{r.phoneNumber}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{dayjs(r.date).format(US_FORMAT)}</p>
                                        </TableCell>
                                        <TableCell className={QUOTE_LABEL_COLOR[r.status]}>
                                            <p>{QUOTE_STATUS_LABEL[r.status]}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{r.createdBy}</p>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </CardContent>
                <div className='p-5 pb-0 flex flex-row items-center'>
                    <Pagination
                        pagination={pagination}
                        setPagination={setPagination}
                        itemsPerPage={report?.meta?.pageSize ?? 5}
                        totalRecords={report?.meta?.totalRecords ?? 0}
                    />
                </div>
            </Card>
        </>
    )
}
export default QuoteReportTable
