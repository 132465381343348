import { QuoteType } from '@/api/quoting/schema'
import { CardTitle } from '@/components/Card'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { cn } from '@/utils/helper'

const tableHeader = [
    { textAlign: 'text-start', title: 'Service Type', width: 'w-[40%]' },
    { textAlign: 'text-end', title: 'Quantity', width: 'w-[15%]' },
    { textAlign: 'text-end', title: 'Unit Price', width: 'w-[15%]' },
    { textAlign: 'text-end', title: 'Markup %', width: 'w-[15%]' },
    { textAlign: 'text-end', title: 'Cost', width: 'w-[15%]' },
]

const ProductsAndServicesTable = ({ quote }: { quote: QuoteType | undefined }) => {
    return (
        <>
            <CardTitle className='text-[18px] font-bold mb-8'>Service</CardTitle>
            <div className='p-[20px] bg-clip-content shadow-[inset_0_0_0_20px_#F5F5F5]'>
                <Table className='rounded-lg'>
                    <TableHeader>
                        <TableRow className='whitespace-nowrap !border-0'>
                            {tableHeader?.map((header) => (
                                <TableHead
                                    key={header.title}
                                    className={cn(
                                        header.textAlign,
                                        header.width,
                                        'flex-row font-semibold text-zentive-gray-medium text-base items-start text-[18px]',
                                    )}
                                >
                                    <div className='inline-flex p-0 m-0'>{header.title}</div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {quote?.productServiceGroup?.map((data) => (
                            <TableRow
                                key={data.productServiceId}
                                className='whitespace-break-spaces'
                            >
                                <TableCell className='flex-row items-start text-end text-[#191A0A] text-[16px]'>
                                    <div className='text-left flex flex-col'>
                                        <span className='font-bold'>{data?.name}</span>
                                        <span className='text-[14px] text-zentive-gray-medium'>
                                            {data?.description}
                                        </span>
                                    </div>
                                </TableCell>
                                <TableCell className='flex-row items-start text-end text-[#191A0A] text-[16px]'>
                                    {data?.quantity && data?.quantity > 0 ? data?.quantity : 1}
                                </TableCell>
                                <TableCell className='flex-row items-start text-end text-[#191A0A] text-[16px]'>
                                    ${' '}
                                    {data?.unitPrice?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </TableCell>
                                <TableCell className='flex-row items-start text-end text-[#191A0A] text-[16px]'>
                                    {data.cost
                                        ? '$' +
                                          (
                                              ((data.quantity ? data.quantity : 1) *
                                                  data.unitPrice *
                                                  (data.markup ? data.markup : 0)) /
                                              100
                                          ).toFixed(2)
                                        : '$' + '0.00'}
                                </TableCell>
                                <TableCell className='flex-row items-start text-end text-[#191A0A] text-[16px]'>
                                    ${' '}
                                    {data?.cost?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </>
    )
}

export default ProductsAndServicesTable
