import { Card, CardContent, CardHeader } from '@/components/Card'
import { ServicesEmptyState } from './EmptyState'
import { RequestServiceType } from '@/api/service/schema'

interface ServiceListProps {
    services: RequestServiceType
}

const ServiceList: React.FC<ServiceListProps> = ({ services }) => {
    return (
        <Card className='w-[50%] bg-service-list bg-cover h-[50%] pb-10 brightness-110'>
            <CardHeader className='px-10 pt-[36px] space-y-8'>
                <p className='text-zentive-gray-bg font-semibold text-[20px] mb-[10px]'>
                    Our Services
                </p>
            </CardHeader>
            <CardContent className='px-10 space-y-8 overflow-auto mr-3 white-scrollbar max-h-[400px]'>
                <ul className='space-y-3 list-disc list-inside' aria-live='assertive'>
                    {services?.services ? (
                        services?.services?.map((data) => (
                            <div key={``} className='flex items-center ml-1'>
                                <li
                                    className='text-zentive-gray-bg text-base text-[20px]'
                                    aria-hidden={true}
                                >
                                    {data.name}
                                </li>
                            </div>
                        ))
                    ) : (
                        <ServicesEmptyState />
                    )}
                </ul>
            </CardContent>
        </Card>
    )
}
export default ServiceList
