import { EmployeeDataType } from '@/api/auth/schema'
import { getUnassignedCrews } from '@/api/crew'
import { EmployeeListType } from '@/api/employee/schema'
import { FullProfileType } from '@/api/profile/schema'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import { Button } from '@/components/Button'
import { Checkbox } from '@/components/Checkbox'
import { FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import SearchBar from '@/components/InputSearchBox'
import { Modal } from '@/components/Modal'
import { Pagination } from '@/components/Pagination'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { crewListAtom, crewMemberProfileIdsAtom, crewPaginationAtom } from '@/store/employee'
import { useQuery } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

interface IAddCrewModalProps {
    isOpen: boolean
    onClose: React.Dispatch<React.SetStateAction<boolean>>
}

const AddCrewMemberModal: React.FunctionComponent<IAddCrewModalProps> = (props) => {
    const { control, setValue } = useFormContext()
    const [selectedCrewId, setSelectedCrewId] = useState<string[]>([])
    const [selectedCrew, setSelectedCrew] = useState<EmployeeListType | null>(null)
    const [keyWord, setKeyWord] = useState<string>('')
    const [crewMemberProfileIds, setCrewMemberProfileIds] = useAtom(crewMemberProfileIdsAtom)
    const [pagination, setPagination] = useAtom(crewPaginationAtom)
    const [crewListData, setCrewListData] = useAtom(crewListAtom)
    const [allCrewMembers, setAllCrewMembers] = useState<EmployeeDataType[]>([])

    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    const handleSearch = (query: string) => {
        setKeyWord(query)
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 1,
        }))
    }

    const { data } = useQuery({
        queryKey: ['unassignedCrews', pagination, keyWord, user],
        queryFn: () =>
            getUnassignedCrews({
                keyWord: keyWord.trim() ?? '',
                businessId: user.businessId,
                p: pagination,
            }),
    })

    const handleCheckClick = (id: string) => {
        setSelectedCrewId((prevSelectedCrew) => {
            const updatedSelectedCrew = prevSelectedCrew.includes(id)
                ? prevSelectedCrew.filter((crewId) => crewId !== id)
                : [...prevSelectedCrew, id]

            const selectedCrewMembers = allCrewMembers.filter((crewMember) =>
                updatedSelectedCrew.includes(crewMember.profileId),
            )

            setSelectedCrew({
                content: selectedCrewMembers,
                meta: data?.meta || {},
            })

            return updatedSelectedCrew
        })
    }

    useEffect(() => {
        setKeyWord('')
        setSelectedCrewId([])
        setSelectedCrew(null)
    }, [props.isOpen])

    const handleSave = () => {
        setCrewListData((prevCrewListData) => {
            const existingContent = prevCrewListData?.content || []
            const existingProfileIds = new Set(existingContent?.map((member) => member.profileId))
            const filteredContent = selectedCrew?.content?.filter(
                (member) => !existingProfileIds.has(member.profileId),
            )
            const updatedContent = filteredContent
                ? [...existingContent, ...filteredContent]
                : existingContent
            const updatedCrewListData = {
                content: updatedContent,
                meta: selectedCrew?.meta || {},
            }
            return updatedCrewListData
        })

        setCrewMemberProfileIds((prevProfileIds) => {
            const updatedProfileIds = [...prevProfileIds, ...selectedCrewId]
            return updatedProfileIds
        })
        props.onClose(false)
    }

    useEffect(() => {
        setValue('memberProfileIds', crewMemberProfileIds)
    }, [crewMemberProfileIds])

    useEffect(() => {
        if (selectedCrew) {
            const filteredSelectedCrewId = selectedCrewId.filter((id) => {
                return crewListData?.content.some((member) => member.profileId === id)
            })

            setSelectedCrewId(filteredSelectedCrewId)
        }
    }, [crewListData])

    // added a separate call to get 100 crewmembers
    // due to pagination of the add crew member modal, the minimum ids that we can add in crewListData is only 5 from the previous page
    useEffect(() => {
        const fetchAllCrewMembers = async () => {
            try {
                const allCrewData = await getUnassignedCrews({
                    keyWord: keyWord.trim() ?? '',
                    businessId: user.businessId,
                    p: { page: 1, pageSize: 100 },
                })
                setAllCrewMembers(allCrewData?.content || [])
            } catch (error) {
                console.log('error', error)
            }
        }

        fetchAllCrewMembers()
    }, [keyWord, user])

    return (
        <Modal
            isOpen={props?.isOpen}
            isHideCloseButton
            onClose={() => {
                props.onClose(false)
            }}
            title='Add Crew Member'
            titleClassName=''
            containerClassName='max-w-[700px]'
        >
            <div className='px-5 pb-5'>
                <SearchBar
                    placeHolder='Search for...'
                    onSearchChange={(e) => handleSearch(e.target.value)}
                    value={keyWord}
                    className='w-[300px] md:w-[660px] h-[40px] py-2 pl-10 pr-4 border border-gray-300 rounded-l-[2px] focus:outline-none'
                />
                <div className='mt-8 h-[190px] max-h-[190px] overflow-y-scroll green-scrollbar'>
                    {data?.content && data?.content?.length > 0 &&
                        data?.content?.map((crewMember: EmployeeDataType) => (
                            <FormField
                                control={control}
                                name='memberProfileIds'
                                render={() => (
                                    <FormItem>
                                        <FormControl>
                                            <div key={crewMember.profileId} className='mt-3'>
                                                <Checkbox
                                                    checked={selectedCrewId.includes(
                                                        crewMember.profileId,
                                                    )}
                                                    className='mr-1 mt-0.5 accent-zentive-green-dark'
                                                    onCheckedChange={() =>
                                                        handleCheckClick(crewMember.profileId)
                                                    }
                                                />
                                                <span className='pl-4'>
                                                    {crewMember.firstName + ' ' + crewMember.lastName}
                                                </span>
                                            </div>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        ))}
                    {!data?.content?.length && (
                        <div className='bg-white w-auto rounded-sm'>
                            <div className='mt-4 w-[1260px]'> </div>
                            <div className='flex justify-center'>
                                <img
                                    src={emptyPlaceholder}
                                    alt='empty-table-placeholder'
                                    className='my-12 h-60'
                                />
                            </div>
                            <p className='pb-4 text-center text-2xl text-gray-900'>
                                Currently Empty
                            </p>
                        </div>
                    )}
                </div>
                <div className='mb-5 mt-3'>
                    <Pagination
                        itemsPerPage={pagination.pageSize ?? 5}
                        setPagination={setPagination}
                        totalRecords={data?.meta?.totalRecords ?? 1}
                        pagination={pagination}
                    />
                </div>
                <div className='mt-3 flex justify-end gap-x-4'>
                    <Button
                        variant='ghost'
                        className='w-97 h-11 text-base font-semibold bg-white text-zentive-green-dark ring-zentive-green-dark border border-zentive-green-dark'
                        onClick={() => props.onClose(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        disabled={!selectedCrew || selectedCrew.content?.length === 0}
                    >
                        Add
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default AddCrewMemberModal
