import { useEffect } from 'react'
import { SignUpType } from '@/api/auth/schema'
import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { InputLocation } from '@/components/InputLocation'
import { FullAddress } from '@/hooks/useGeocode'
import { useFormContext } from 'react-hook-form'
import { useSetAtom } from 'jotai'
import { isAddressSelectedAtom } from '@/store/auth'
import GoogleMapsApiWrapper from '@/components/GoogleMapsApiWrapper'

const StreetFields = () => {
    const { control, setValue, watch, reset, clearErrors } = useFormContext<SignUpType>()

    const streetAddress = watch('data.streetAddress')

    const handlePlaceSelected = async (fullAddress: FullAddress) => {
        setIsAddressSelected(true)
        setValue('data.streetAddress', fullAddress.streetAddress, { shouldValidate: true })
        setValue('data.city', fullAddress.city, { shouldValidate: true })
        setValue('data.state', fullAddress.state, { shouldValidate: true })
        setValue('data.zipCode', fullAddress.zipCode, { shouldValidate: true })
    }

    const setIsAddressSelected = useSetAtom(isAddressSelectedAtom)

    useEffect(() => {
        if (streetAddress?.length === 0) {
            setIsAddressSelected(false)
            setValue('data.city', '')
            setValue('data.state', '')
            setValue('data.zipCode', '')
            clearErrors('data.city')
            clearErrors('data.state')
            clearErrors('data.zipCode')
        }
    }, [streetAddress, setIsAddressSelected, setValue, reset])

    // Prevent "ctrl + z" key in Location form
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key === 'z') {
                event.preventDefault()
            }
        }

        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    return (
        <div className='grid grid-cols-2 gap-4 w-full'>
            <div className='col-span-full'>
                <FormField
                    name='data.streetAddress'
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <GoogleMapsApiWrapper
                                    render={(isLoaded: boolean, loadError?: Error) => {
                                        if (!isLoaded || loadError) return null

                                        return (
                                            <InputLocation
                                                autocompleteType='address'
                                                onPlaceSelected={handlePlaceSelected}
                                                placeholder='Street 1*'
                                                {...field}
                                                isLoaded={isLoaded}
                                            />
                                        )
                                    }}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-full'>
                <FormField
                    name='data.streetTwoAddress'
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input placeholder='Street 2 (Optional)' type='text' {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </div>
    )
}
export default StreetFields
