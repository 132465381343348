import { SignUpType } from '@/api/auth/schema'
import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { InputPassword } from '@/components/InputPassword'
import { useFormContext } from 'react-hook-form'

const PasswordFields = () => {
    const { control } = useFormContext<SignUpType>()
    return (
        <div className='grid grid-cols-2 gap-4'>
            <div className='col-span-1'>
                <FormField
                    name='password.newPassword'
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <InputPassword
                                    placeholder='New Password*'
                                    hasConfirmPassword
                                    {...field}
                                />
                            </FormControl>
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    name='password.confirmPassword'
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <InputPassword placeholder='Confirm Password*' {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </div>
    )
}
export default PasswordFields
