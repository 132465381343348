import { Button } from '@/components/Button'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import { ActionMode } from '..'

interface CustomerButtonProp {
    isValid?: boolean
    isAvatarChanged?: boolean
    isSubmitting: boolean
    mode: ActionMode
    isFormDirty: boolean
}

const CustomerButtons: React.FC<CustomerButtonProp> = ({
    isValid,
    isAvatarChanged,
    isSubmitting,
    mode,
    isFormDirty,
}) => {
    const { navigateTo } = useAppNavigate()

    const isDisabled = () => {
        if (mode === 'create') {
            if (isValid || isSubmitting) return false
            return true
        }

        if (mode === 'update') {
            if ((isAvatarChanged || isFormDirty || isSubmitting) && isValid) return false

            return true
        }
    }

    return (
        <div className='flex space-x-4 mt-[24px]'>
            <Button
                onClick={() => navigateTo('/customers/customer-list')}
                type='button'
                variant={'outline'}
                className='w-full'
            >
                Cancel
            </Button>
            <Button type='submit' className='w-full' disabled={isDisabled()}>
                Update
            </Button>
        </div>
    )
}
export default CustomerButtons
