import { signOut } from '@/api/auth'
import { updateStripeSetupLaterStatus } from '@/api/profile'
import { StripeSetupLaterType } from '@/api/profile/schema'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { Button } from '@/components/Button'
import { PAYMENT_BTN_STYLE } from '@/constants'
import { sessionAtom } from '@/store/auth'
import { paymentStepAtom } from '@/store/payment'
import { cn } from '@/utils/helper'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue, useSetAtom } from 'jotai'
import { RESET } from 'jotai/utils'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { useNavigate } from 'react-router-dom'

interface SetUpLaterAlertProps {
    isPending: boolean
}

const SetUpLaterAlert = ({ isPending }: SetUpLaterAlertProps) => {
    const navigate = useNavigate()
    const setCurrentStep = useSetAtom(paymentStepAtom)
    const session = useAtomValue(sessionAtom)

    const setupLaterMutation = useMutation<unknown, AxiosError, StripeSetupLaterType>({
        mutationKey: ['stripeSetupLater'],
        mutationFn: (setupLater) => updateStripeSetupLaterStatus(setupLater),
        onSuccess: () => {
            navigate('/')
            setCurrentStep(RESET)
            signOut()
        },
        onError: () => {},
    })

    return (
        <AlertDialog>
            <AlertDialogTrigger className='w-[50%] '>
                <Button
                    className={cn(
                        PAYMENT_BTN_STYLE,
                        'w-[243.5px] font-semibold hover:bg-zentive-green-dark hover:text-[#FFFFFF]',
                    )}
                    disabled={isPending}
                    type='button'
                    variant='outline'
                >
                    Set Up Later
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white  w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-[26.5px] pt-[40px]'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                        <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                    </div>
                    <div className=' overflow-auto tracking-wide'>
                        <AlertDialogTitle className='text-left font-bold text-[24px] text-zentive-black mt-[10px]'>
                            Set Up Stripe Later
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-[14px] text-zentive-gray-medium mt-[18px]'>
                            <p className='mb-4'>
                                By choosing to set up Stripe Later, some pages or features will not
                                be available.
                            </p>
                            <div className='flex flex-col mb-4'>
                                <span className='flex font-semibold mb-3'>Your Side</span>
                                <ul className='space-y-1 ml-4 list-disc list-inside'>
                                    <li>Invoice</li>
                                    <li>Financial Report</li>
                                    <li>Reimbursements</li>
                                </ul>
                            </div>
                            <div className='flex flex-col mb-4'>
                                <span className='flex font-semibold mb-3'>Your Customer</span>
                                <ul className='space-y-1 ml-4 list-disc list-inside'>
                                    <li>Payments (including auto-pay)</li>
                                    <li>Invoices</li>
                                    <li>Refunds</li>
                                </ul>
                            </div>
                            <p className='mb-4'>
                                In case you change your mind and wants to linked a Stripe account,
                                you can always set it up on your
                                <span className='font-semibold'> Settings</span>.
                            </p>
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-[0px] bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[20px] pt-[6px]'>
                    <div className='w-full flex justify-end items-end gap-[16px]'>
                        <AlertDialogCancel className='w-[155px] h-[43px] text-[16px] font-semibold text-zentive-green-dark ring-zentive-green-dark hover:bg-zentive-green-dark hover:text-[#FFFFFF]'>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            onClick={() => {
                                setupLaterMutation.mutate({
                                    profileId: session?.user.id!,
                                    isSetupLater: true,
                                })
                            }}
                            className='w-[155px] h-[43px] text-[16px] font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            Continue
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
export default SetUpLaterAlert
