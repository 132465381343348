import { cancelDowngrade, previousDowngradePlan } from '@/api/owner'
import { SubscriptionType } from '@/api/subscription/schema'
import { Button } from '@/components/Button'
import { userAtom } from '@/store/auth'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai'
import { FaCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { numberFormatter } from '@/utils/helper'
import { useToast } from '@/hooks/useToast'

const CancelDowngrade = () => {
    const user = useAtomValue(userAtom)
    const navigate = useNavigate()
    const { toast } = useToast()

    const { data: previousPlan } = useQuery<unknown, AxiosError, SubscriptionType>({
        queryKey: ['previousPlan'],
        queryFn: () => previousDowngradePlan(user?.profileId as string),
    })

    const { mutate: cancelDowngradeMu, isIdle } = useMutation<unknown, AxiosError, unknown>({
        mutationFn: () => cancelDowngrade(user?.profileId ?? ''),
        onSuccess: () => {
            navigate('/settings/owner-subscription')
            toast({
                description: 'Successfully canceled',
                variant: 'default',
            })
        },
        onError: (err) => console.log(err),
    })

    return (
        <div className='mx-[75px] mt-[90px]'>
            <div className='bg-white'>
                <div className='flex justify-center'>
                    <p className='pt-[36px] text-zentive-green-dark font-semibold text-2xl items-center'>
                        Confirm Cancellation of Downgrade
                    </p>
                </div>
                <div className='mx-[105px] mt-5 text-gray-900 pb-[36.25px]'>
                    <p>
                        You have chosen to cancel the downgrade of your subscription plan. By
                        clicking 'Confirm Cancel Downgrade' you agree to the following:
                    </p>
                    <div>
                        <p className='mt-[5px] flex justify-start items-center gap-1'>
                            <FaCircle className='h-2' />
                            {`Your current plan will be immediately reverted back to your previous
                            plan ${previousPlan?.name}.`}
                        </p>
                        <p className='mt-[5px] flex justify-start items-center gap-1'>
                            <FaCircle className='h-2' />{' '}
                            {`Future billing will occur at the full
                            ${
                                previousPlan?.interval == 'month' ? 'monthly' : 'annual'
                            } rate of $${numberFormatter.format(
                                previousPlan?.price ?? 0,
                            )} starting from the next billing cycle.`}
                        </p>
                        <p className='mt-[20px] flex justify-start text-zentive-gray-medium items-center gap-1'>
                            **You can review or modify your subscription details at any time in your
                            account settings.**
                        </p>
                    </div>
                </div>
            </div>
            <div className='flex justify-end bg-gray-200 rounded-b-[15px] gap-3 py-5 px-5'>
                <Button
                    onClick={() => navigate('/owner-subscription/change-plan')}
                    className='w-[95px] text-zentive-green-dark font-semibold text-lg'
                    variant={'outline'}
                    disabled={!isIdle}
                >
                    Cancel
                </Button>
                <Button disabled={!isIdle} onClick={cancelDowngradeMu} className='w-[250px]'>
                    Confirm Cancel Downgrade
                </Button>
            </div>
        </div>
    )
}

export default CancelDowngrade
