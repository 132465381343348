import { Navigate, RouteObject } from 'react-router-dom'
import CompleteAccountCreation from '@/pages/public/Payment/CompleteCreation'
import CreatePassword from '@/pages/public/CreatePassword'
import ForgotPassword from '@/pages/public/ForgotPassword'
import PaymentProcess from '@/pages/public/Payment/PaymentProcess'
import PublicLayout from '../../layouts/PublicLayout'
import PublicGuard from '@/components/PublicGuard'
import ResetPasswordVerification from '@/pages/public/ResetPasswordVerification'
import SessionExpired from '@/pages/public/Payment/SessionExpired'
import SignIn from '@/pages/public/SignIn'
import SignUp from '@/pages/public/SignUp'
import SignUpForm from '@/pages/public/SignUp/SignUpForm'
import SignUpGallery from '@/pages/public/SignUp/SignUpGallery'
import SignUpVerification from '@/pages/public/SignUp/SignUpVerification'
import VerificationSuccess from '@/pages/public/VerificationSuccess'
import CrewForgotPassword from '@/pages/public/CrewPortal/ForgotPassword'
import CrewResetPasswordVerification from '@/pages/public/CrewPortal/ResetPasswordVerification'
import FreeTrial from '@/pages/public/FreeTrial'
import PrivacyPolicy from '@/pages/public/PrivacyDocuments/PrivacyPolicy'
import ServiceAgreement from '@/pages/public/PrivacyDocuments/ServiceAgreement'
import Faqs from '@/pages/public/PrivacyDocuments/Faqs'
import CustomerInvitationResult from '@/pages/public/CustomerInvitationResult'
import CreatePasswordSuccess from '@/pages/public/CrewPortal/CreatePasswordSuccess'

const PublicRoutes: RouteObject = {
    element: (
        <PublicGuard>
            <PublicLayout />
        </PublicGuard>
    ),
    children: [
        {
            path: '/',
            element: <Navigate to='/sign-in' replace />,
        },
        {
            path: '/forgot-password',
            element: <ForgotPassword />,
        },
        {
            path: '/sign-in',
            element: <SignIn />,
        },
        {
            path: '/crew/sign-in',
            element: <SignIn />,
        },
        {
            path: '/success',
            element: <CreatePasswordSuccess />,
        },
        {
            path: '/privacy-policy',
            element: <PrivacyPolicy />,
        },
        {
            path: '/service-agreement',
            element: <ServiceAgreement />,
        },
        {
            path: '/faqs',
            element: <Faqs />,
        },
        {
            path: '/crew/forgot-password',
            element: <CrewForgotPassword />,
        },
        {
            path: '/crew/check-email',
            element: <CrewResetPasswordVerification />,
        },
        {
            path: '/sign-up',
            element: <SignUp />,
            children: [
                {
                    element: <SignUpGallery />,
                    index: true,
                },
                {
                    path: 'form',
                    element: <SignUpForm />,
                },
                {
                    path: 'verify',
                    element: <SignUpVerification />,
                },
            ],
        },
        {
            path: '/reset-password',
            element: <CreatePassword />,
        },
        {
            path: '/verified',
            element: <VerificationSuccess />,
        },
        {
            path: '/invite-result',
            element: <CustomerInvitationResult />,
        },
        {
            path: '/free-trial',
            element: <FreeTrial />,
        },
        {
            path: '/payment',
            children: [
                {
                    element: <PaymentProcess />,
                    index: true,
                },
                {
                    path: 'session-expired',
                    element: <SessionExpired />,
                },
                {
                    path: 'status',
                    element: <CompleteAccountCreation />,
                },
            ],
        },
        {
            path: '/check-email',
            element: <ResetPasswordVerification />,
        },
    ],
}

export default PublicRoutes
