import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { useToast } from '@/hooks/useToast'
import { AxiosError } from 'axios'
import { deleteService } from '@/api/service'
import { FiTrash } from 'react-icons/fi'
import { useAtom } from 'jotai'
import { businessServiceTypeAtom } from '@/store/customer'
import { ProductServiceType } from '@/api/service/schema'
import { useState } from 'react'

type DeleteServiceProps = {
    service: ProductServiceType
}

const DeleteServiceModal = (props: DeleteServiceProps) => {
    const { toast } = useToast()
    const [businessServiceType, setBussinessServiceType] = useAtom(businessServiceTypeAtom)
    const queryClient = useQueryClient()
    const [open, setOpen] = useState<boolean>(false)

    const { mutate: deleteServiceMu, isPending: updateLoading } = useMutation({
        mutationFn: () => deleteService(props.service.productServiceId!),
        onSuccess: () => {
            toast({
                description: 'Successfully Deleted',
                variant: 'default',
            })
            const updatedServiceTypes = businessServiceType?.filter(
                (service) => service.productServiceId !== props.service.productServiceId,
            )
            setBussinessServiceType(updatedServiceTypes! != null ? updatedServiceTypes : [])
            queryClient.invalidateQueries({ queryKey: ['ProductServiceType'] })
            setOpen(false)
        },
        onError: (err: AxiosError) => {
            const defaultErrMessage = 'Error occurred, please try again later'

            if (err.request.status === 409) {
                const responseData = err.response?.data as { message: string } | undefined
                const errorToastMessage = responseData?.message || defaultErrMessage

                toast({
                    description: `${errorToastMessage
                        .charAt(0)
                        .toUpperCase()}${errorToastMessage.slice(1)}`,
                    variant: 'default',
                })
            } else {
                toast({
                    description: defaultErrMessage,
                    variant: 'destructive',
                })
            }
        },
    })

    return (
        <AlertDialog open={open} onOpenChange={() => setOpen(!open)}>
            <AlertDialogTrigger>
                <button key='trash-icon'>
                    <FiTrash className='h-4 w-4 cursor-pointer text-indigo-900 mt-[.4rem]' />
                </button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-[26.5px] pt-[40px]'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                        <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-2xl text-[#191A0A] mt-[10px] capitalize'>
                            Delete {props.service.type}
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-sm text-zentive-gray-medium mt-[18px]'>
                            This action cannot be undone. Please confirm deletion of Products and
                            Services.
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-[0px] bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[15px] pt-[6px]'>
                    <div className='w-full flex justify-end items-end gap-[16px]'>
                        <AlertDialogCancel
                            className='w-97 h-[43px] text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            disabled={updateLoading}
                            onClick={() => {
                                deleteServiceMu()
                            }}
                            className='w-97 h-[43px] text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            Delete
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default DeleteServiceModal
