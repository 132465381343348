import { getExpenseAccountsByBusinessId } from '@/api/expense_account'
import { FullProfileType } from '@/api/profile/schema'
import { ExpenseType } from '@/api/quoting/schema'
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { Button } from '@/components/Button'
import { Input } from '@/components/Input'
import { Textarea } from '@/components/TextArea'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { cn, hasMoreThanTwoDecimalPlaces } from '@/utils/helper'
import { Label } from '@radix-ui/react-label'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { ChangeEvent, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { DEFAULT_EXPENSE_VALUE } from '@/constants'
import emptyExpensesPlaceholder from '@/assets/private/empty-expenses.png'
import { useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { format } from 'date-fns'


const ExpenseDialog = () => {
    const [open, setIsOpen] = useState<boolean>(false)
    const [expense, setExpense] = useState(() => DEFAULT_EXPENSE_VALUE)
    const { getValues, setValue } = useFormContext()
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const navigateTo = useNavigate()

    const { data } = useQuery({
        queryKey: ['expenseAccounts', user?.businessId],
        queryFn: () => getExpenseAccountsByBusinessId('', user?.businessId as string),
        select: (res) => res?.content,
    })

    const expenseAccounts = data ?? []

    const handleOnSave = () => {
        const expenses: ExpenseType[] = getValues('quote.expense')

        // const totalExpense = expenses.reduce(a,b => a.total + b.total)
        // setTotalExpense((prevTotal: number) => prevTotal + expense.total)

        setValue('quote.expense', [...expenses, expense])

        // setSubtotal('quote.subTotal', total + totalExpense)
        // setSubTotal((prevTotal: number) => prevTotal + expense.total)
        setExpense(DEFAULT_EXPENSE_VALUE)
        setIsOpen(false)
    }

    const onCancel = () => {
        setExpense(DEFAULT_EXPENSE_VALUE)
        setIsOpen(false)
    }

    const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const expenseAccountName = event.target.value

        setExpense((prevState) => ({
            ...prevState,
            accountName: expenseAccountName,
        }))
    }

    //match
    // const handleTotalChange = (evt: ChangeEvent<HTMLInputElement>) => {
    //     let val: string = evt.target.value

    //     if (Number.isNaN(val)) return

    //     if (Number(val[0]) === 0) {
    //         val = val.slice(1)

    //         setExpense((prevState) => ({
    //             ...prevState,
    //             total: Number(val),
    //         }))

    //         return
    //     }

    //     setExpense((prevState) => ({
    //         ...prevState,
    //         total: Number((val ? parseFloat(val) : 0).toFixed(2)),
    //     }))
    // }

    const isDisabled = expense.total === 0 || expense.accountName === '' || expense.date === ''

    const [selectDate, setSelectDate] = useState<Date | null>(null)
    const [isFocused, setIsFocused] = useState<boolean>(false)


    const handleDateChange = (date: Date | null) => {
        setSelectDate(date);
        setExpense((prevState) => ({
            ...prevState,
            date: date ? format(date, 'MM/dd/yyyy') : '',
        }))
    }
    

    return (
        <AlertDialog open={open} onOpenChange={setIsOpen}>
            <AlertDialogTrigger asChild onClick={() => {}}>
                <button
                    type='button'
                    className='text-[17.07px] text-zentive-green-dark font-semibold text-right w-[13%]'
                >
                    + New Expenses
                </button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white rounded-[15px] p-0'>
                <AlertDialogTitle className='text-left font-semibold text-lg text-[#191A0A] p-10 pb-0'>
                    <h1 className='text-[20px] font-semibold text-left mb-6'>Add Expense Account</h1>
                </AlertDialogTitle>
                {!expenseAccounts?.length && expenseAccounts?.length === 0 ? (
                    <div className='flex flex-col items-center px-4 mx-4 mb-8'>
                        <div className='flex justify-center bg-white rounded-md'>
                            <img
                                src={emptyExpensesPlaceholder}
                                alt='empty-table-placeholder'
                                className='h-36'
                            />
                        </div>
                        <p className='pb-4 text-center text-2xl text-zentive-green-dark font-semibold'>
                            No Expense Yet
                        </p>
                        <p className='pb-4 text-center text-zentive-black text-base'>
                            You have no expenses to display at the moment.
                        </p>
                        <Button
                            className='mt-2 py-6 px-10 font-medium text-xl'
                            onClick={() => navigateTo('/settings/expense-accounts/add')}
                        >
                            Add Expense
                        </Button>
                    </div>
                    ) : ( 
                        <>
                            <div className='bg-white flex flex-col gap-10 px-10 pb-10'>
                                <div className='relative z-10'>
                                    <select
                                        name='row'
                                        onChange={handleOnChange}
                                        className='w-full mb-4 flex justify-center items-center font-sans text-base h-11 border border-zentive-gray-medium disabled:bg-gray-100 text-gray-900 focus:border-zentive-green-dark'
                                    >
                                        <option
                                            key={DEFAULT_EXPENSE_VALUE.accountName}
                                            value={DEFAULT_EXPENSE_VALUE.accountName}
                                        >
                                            Select Expense Account
                                        </option>
                                        {expenseAccounts?.map((expenseAccount, index) => (
                                            <option
                                                key={index}
                                                value={expenseAccount.expenseAccountName}
                                                className='text-lg'
                                            >
                                                {expenseAccount.expenseAccountName}
                                            </option>
                                        ))}
                                        {!expenseAccounts?.length && (
                                            <option className='text-lg'>No expense account yet</option>
                                        )}
                                    </select>
                                    <Label
                                        className={cn(
                                            'floating-label bg-white absolute left-1 top-2 -translate-y-1/90 text-base duration-100 ease-linear peer-focus:-translate-y-4 peer-focus:text-sm peer-focus:px-1',
                                            expense.accountName !== '' || expenseAccounts?.length >= 0
                                                ? 'text-zentive-green-dark peer-disabled:opacity-100'
                                                : '',
                                            expense.accountName !== '' || expenseAccounts?.length >= 0
                                                ? 'peer-focus:text-zentive-green-dark pointer-events-auto -translate-y-4 text-sm px-1'
                                                : 'peer-focus:text-zentive-green-dark text-zentive-gray-medium pointer-events-none',
                                        )}
                                    >
                                        Name of Expense
                                    </Label>
                                </div>
                                <Textarea
                                    className='flex w-full h-24 max-h-[200px] font-sans text-base py-2 -mb-6 border disabled:bg-gray-100 text-gray-900 rounded-sm focus:border-zentive-green-dark'
                                    placeholder='Description'
                                    value={expense.description}
                                    onChange={(val) =>
                                        setExpense((prevState) => ({
                                            ...prevState,
                                            description: val.target.value,
                                        }))
                                    }
                                />
                                <div className='relative z-10'>
                                <DatePicker
                                        className='peer flex w-full pr-96 -mb-2 rounded-sm cursor-pointer 
                                            font-sans text-base h-11 disabled:bg-gray-100 text-gray-900 
                                            bg-transparent px-3 shadow-sm transition-colors focus:outline-none
                                            border focus:border-zentive-green-dark border-zentive-gray-medium'
                                        selected={selectDate}
                                        onChange={(date) => handleDateChange(date)}
                                        placeholderText='Select'
                                        minDate={new Date()}
                                        onKeyDown={(e) => e.preventDefault()}
                                        onFocus={() => setIsFocused(true)}
                                        onBlur={() => setIsFocused(false)}
                                    />

                                    {/* <Input
                                        className='w-full -mb-2  flex justify-center items-center font-sans text-base h-11 border disabled:bg-gray-100 text-gray-900'
                                        name='date'
                                        type='date'
                                        value={expense.date}
                                        onChange={(val) =>
                                            setExpense((prevState) => ({
                                                ...prevState,
                                                date: val.target.value,
                                            }))
                                        }
                                        onKeyDown={(e) => {
                                            if (
                                                e.key === '-' ||
                                                e.key === '+' ||
                                                e.key === 'e' ||
                                                e.key === 'E'
                                            ) {
                                                e.preventDefault()
                                            }
                                        }}
                                    /> */}
                                    
                                    <Label
                                        className={cn(
                                            'floating-label bg-white absolute left-3 bottom-6 -translate-y-1/90 text-base duration-100 ease-linear',
                                            isFocused ? 'text-zentive-green-dark pointer-events-auto text-sm px-1 -translate-y-4 bottom-3' : 'text-zentive-gray-medium',
                                        )}
                                    >
                                        {isFocused ? 'Date' : 'Date Expense Incurred'}
                                    </Label>
                                </div>
                                <Input
                                    className='w-full flex justify-center mb-0 items-center font-sans text-base h-11  border disabled:bg-gray-100 text-gray-900 focus:text-left focus:border-zentive-green-dark'
                                    placeholder='Total Expense Amount $'
                                    name='total'
                                    type='number'
                                    value={expense.total}
                                    onChange={(e) => {
                                        const inputValue: string = e.target.value
            
                                        if (hasMoreThanTwoDecimalPlaces(inputValue)) return
            
                                        setExpense((prevState) => ({
                                            ...prevState,
                                            total: parseFloat(inputValue),
                                        }))
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === '-' || e.key === '+' || e.key === 'e' || e.key === 'E') {
                                            e.preventDefault()
                                        }
                                    }}
                                />
                            </div>
                        </> 
                )}

                <AlertDialogFooter className='py-5 px-10 w-full rounded-b-md justify-center items-end bg-zentive-gray-light'>
                    <div className='flex flex-row w-[45%]'>
                        <Button
                            type='button'
                            variant='outline'
                            className='w-full mr-4'
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={isDisabled}
                            type='submit'
                            className={`${!expenseAccounts?.length && expenseAccounts?.length === 0 ? 'hidden' : ''} w-full`}
                            onClick={() => handleOnSave()}
                        >
                            Save
                        </Button>
                    </div>
                </AlertDialogFooter>
               
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default ExpenseDialog
