import { CustomerProfileType } from '@/api/profile/schema'
import { Pencil1Icon } from '@radix-ui/react-icons'
import { formatPhoneNumber } from '@/utils/helper'
import { E164Number } from 'libphonenumber-js/core'

const SelectedCustomer = ({ customer }: { customer: CustomerProfileType }) => {
    return (
        <div className=''>
            <div className='flex flex-row items-center gap-2' role='button'>
                <p className='text-zentive-blue-dark text-[20.74px] font-bold'>
                    {customer.firstName} {customer.lastName}
                </p>

                <Pencil1Icon className='w-[13.8px] h-[13.8px]' />
            </div>
            <div className='mt-[42px] flex flex-row text-left'>
                <div className='text-zentive-black w-1/3'>
                    <p className='font-bold mb-[5px]'>Property Address</p>
                    <p>{customer.address.streetAddress}</p>
                    <p>
                        {customer.address.city +
                            ', ' +
                            customer.address.state +
                            ', ' +
                            customer.address.zipCode}
                    </p>
                </div>
                <div className='text-zentive-black w-1/3'>
                    <p className='font-bold mb-[5px]'>Contact Details</p>
                    <p>{formatPhoneNumber(customer.phoneNumber as E164Number)}</p>
                    <p>{customer.email}</p>
                </div>
            </div>
        </div>
    )
}

export default SelectedCustomer
