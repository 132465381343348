import { FullProfileType } from '@/api/profile/schema'
import { Button } from '@/components/Button'

interface RenderViewAsCancellationModalProps {
    isViewedAsOwner: boolean
    isViewedAsOwnerDismissed: boolean
    owner: FullProfileType | null
    handleCloseViewAsSubscriberModal: () => void
    handleDismissViewAsSubscriberModal: () => void
}

export const RenderViewAsCancellationModal = ({
    isViewedAsOwner,
    owner,
    isViewedAsOwnerDismissed,
    handleCloseViewAsSubscriberModal,
    handleDismissViewAsSubscriberModal,
}: RenderViewAsCancellationModalProps) => {
    if (isViewedAsOwner && owner && isViewedAsOwnerDismissed) {
        return (
            <div className='py-0 top-14 left-0 z-30 w-full h-full flex items-center justify-center'>
                <div className='bg-[#87877F] z-30 p-4 py-3 rounded-md flex flex-1 justify-between mx-20'>
                    <div className='flex-1'>
                        <p className='text-white mt-3'>
                            You are viewing now as {owner?.firstName} {owner?.lastName}
                        </p>
                    </div>
                    <div className='flex gap-2 pr-4'>
                        <Button
                            className='w-[100px]'
                            variant={'secondary'}
                            onClick={handleCloseViewAsSubscriberModal}
                        >
                            Back To Admin
                        </Button>
                        <button
                            className='py-1 px-2 bg-none rounded-none text-center text-white justify-center'
                            onClick={handleDismissViewAsSubscriberModal}
                        >
                            Dismiss
                        </button>
                    </div>
                </div>
            </div>
        )
    }
    return null
}
