import { getNextAutoBillDate } from '@/api/subscription'
import { useQuery } from '@tanstack/react-query'

const useNextAutoBillDate = (businessId: string) => {
    const queryData = useQuery({
        enabled: !!businessId,
        queryKey: ['nextAutoBillDate', businessId],
        queryFn: () => getNextAutoBillDate(businessId),
    })

    return queryData
}

export default useNextAutoBillDate
