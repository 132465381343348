import { ReadJobType } from '@/api/job/schema'
import { ABBREVIATED_DATE_12_HOUR, US_FORMAT } from '@/constants'
import { TimeZoneResult } from '@/hooks/useGeocode'
import { timezoneAtom } from '@/store/auth'
import { formatRecordNumber } from '@/utils/helper'
import { formatToUTCWithOffset } from '@/utils/time'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'

interface ViewJobProps {
    job: ReadJobType
}

const JobDetails = ({ job }: ViewJobProps) => {
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult

    const formattedStartDate = formatToUTCWithOffset(
        job?.appointment?.startDateTime as string,
        timezone?.timeZoneId,
        ABBREVIATED_DATE_12_HOUR,
    )

    const formattedEndDate = formatToUTCWithOffset(
        job?.appointment?.endDateTime as string,
        timezone?.timeZoneId,
        ABBREVIATED_DATE_12_HOUR,
    )

    const formattedCreatedAt = formatToUTCWithOffset(
        job?.invoice?.createdAt as string,
        timezone?.timeZoneId,
        ABBREVIATED_DATE_12_HOUR,
    )

    return (
        <div className='flex flex-col gap-x-2 bg-white p-10'>
            <div className='border border-1 border-t-0 border-x-0 border-zentive-gray-light w-full'>
                <h1 className='font-bold text-xl pb-5'>
                    Job Order {formatRecordNumber(job.jobNumber ?? 0)}
                </h1>
            </div>
            <div className='flex flex-col gap-2 py-5 text-md'>
                <h1 className='font-bold text-xl text-zentive-green-darker'>
                    {job?.crew?.description}
                </h1>
                <div>
                    <p className='font-semibold text-lg'>Property Address</p>
                    <p>
                        {job?.address?.streetAddress}{' '}
                        {job?.address?.streetTwoAddress !== '' &&
                        job?.address?.streetTwoAddress === 'undefined'
                            ? ', ' + job?.address?.streetAddress
                            : ''}{' '}
                    </p>
                    <p>
                        {job?.address?.city}, {job?.address?.state} {job?.address?.zipCode}
                    </p>
                </div>
            </div>
            <div className='flex flex-col border border-zentive-gray-light border-y-8 py-5 border-x-0'>
                <h1 className='font-bold text-xl pb-5'>Job Details</h1>
                <div className='flex justify-between border border-zentive-gray-light border-x-0 border-t-0 border-b-1 py-2'>
                    <div>
                        <p>Created On</p>
                    </div>
                    <div>
                        <p>{dayjs(job?.createdAt).format(US_FORMAT)}</p>
                    </div>
                </div>
                <div className='flex justify-between border border-zentive-gray-light border-x-0 border-t-0 border-b-1 py-2'>
                    <div>
                        <p>Job Type</p>
                    </div>
                    <div>
                        <p>
                            {job?.appointment?.appointmentType === 'ONE-OFF_JOB'
                                ? 'One-off Job'
                                : 'Recurring Job'}
                        </p>
                    </div>
                </div>
                <div className='flex justify-between border border-zentive-gray-light border-x-0 border-t-0 border-b-1 py-2'>
                    <div>
                        <p>Frst Schedule</p>
                    </div>
                    <div>
                        <p>{formattedStartDate}</p>
                    </div>
                </div>
                <div className='flex justify-between border border-zentive-gray-light border-x-0 border-t-0 border-b-1 py-2'>
                    <div>
                        <p>Last Schedule</p>
                    </div>
                    <div>
                        <p>{formattedEndDate}</p>
                    </div>
                </div>
                <div className='flex justify-between border border-zentive-gray-light border-x-0 border-t-0 border-b-1 py-2'>
                    <div>
                        <p>Quote Number</p>
                    </div>
                    <div>
                        <p>QN-{formatRecordNumber(job?.quote?.quoteNumber ?? 0)}</p>
                    </div>
                </div>
                <div className='flex justify-between border border-zentive-gray-light border-x-0 border-t-0 border-b-1 py-2'>
                    <div>
                        <p>Invoiced On</p>
                    </div>
                    <div>
                        <p>{formattedCreatedAt}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JobDetails
