import { Card, CardContent, CardHeader } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import { MdOutlinePersonOff } from 'react-icons/md'

interface IStatusFilter {
    setIsUnassigedToggled: React.Dispatch<React.SetStateAction<boolean>>
}

export const StatusFilter = (props: IStatusFilter) => {
    const handleCheckClick = (isCheck: boolean) => {
        isCheck ? props.setIsUnassigedToggled(true) : props.setIsUnassigedToggled(false)
    }

    return (
        <Card className='w-1/3 mt-2 bg-transparent border-none'>
            <CardHeader className='bg-zentive-bg-100 bg-transparent border-b-2 border-gray-300'>
                <h1 className='font-semibold text-2xl'>Status</h1>
            </CardHeader>
            <CardContent className='flex flex-row gap-3 my-6'>
                <Checkbox
                    className='border border-zentive-green-dark mt-1'
                    onCheckedChange={(val) => handleCheckClick(val as boolean)}
                />
                <MdOutlinePersonOff className='w-6 h-6 text-gray-600' />
                <h1 className='text-zentive-blue-dark'>Unassigned</h1>
            </CardContent>
        </Card>
    )
}
