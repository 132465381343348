import { FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { Textarea } from '@/components/TextArea'
import { ChangeEventHandler, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { ProductServiceType } from '@/api/service/schema'
import { handleNumberInputChange } from '@/utils/eventHandler'
import { PRODUCT_SERVICE_TYPES } from '@/constants'

export const InputFormData = () => {
    const { control, setValue, watch } = useFormContext<ProductServiceType>()

    const [markup, unitPrice] = watch(['markup', 'unitPrice'])

    const onMarkupChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
        const markup = handleNumberInputChange(evt)
        setValue('markup', markup, {
            shouldValidate: true,
        })
    }

    const onTypeChange: ChangeEventHandler<HTMLSelectElement> = (evt) => {
        setValue('type', evt.target.value, {
            shouldValidate: true,
        })
    }

    const onUnitPriceChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
        const unitPrice = handleNumberInputChange(evt)
        setValue('unitPrice', unitPrice, {
            shouldValidate: true,
        })
    }

    useEffect(() => {
        if (!unitPrice && !markup) return

        const cost = Number((unitPrice + (unitPrice * (markup ?? 0)) / 100).toFixed(2))
        setValue('cost', cost, {
            shouldValidate: true,
        })
    }, [markup, unitPrice])

    return (
        <div className='flex flex-col bg-white justify-start items-start px-10 py-10'>
            <div className='flex flex-col'>
                <FormField
                    control={control}
                    name='type'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <select
                                    className='w-[670px] max-w-[670px] hover:bg-none items-center font-sans text-base pl-3 h-11 border border-zentive-gray-light disabled:bg-gray-100 text-gray-900 rounded'
                                    {...field}
                                    onChange={onTypeChange}
                                >
                                    {PRODUCT_SERVICE_TYPES?.map((type) => (
                                        <option
                                            id={type}
                                            value={type.toLowerCase()}
                                            selected={field.value === type.toLowerCase()}
                                        >
                                            {type}
                                        </option>
                                    ))}
                                </select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='mt-10'>
                <FormField
                    control={control}
                    name='name'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    className='w-[670px] max-w-[670px] items-center font-sans text-base h-11 border border-zentive-gray-light disabled:bg-gray-100 text-gray-900'
                                    placeholder='Name'
                                    type='text'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>

            <div className='mt-10'>
                <FormField
                    control={control}
                    name='description'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Textarea
                                    className='py-4 w-[670px] max-w-[670px] min-h-[7.8rem] items-center font-sans text-base border border-zentive-gray-light disabled:bg-gray-100 text-gray-900'
                                    placeholder='Description'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>

            <div className='flex flex-row mt-10 w-full gap-4 pb-6'>
                <FormField
                    control={control}
                    name='unitPrice'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    className='font-sans pl-1 text-base h-11 border border-zentive-gray-light text-gray-900 disabled:bg-gray-100 rounded-sm'
                                    placeholder='Unit Price $'
                                    type='number'
                                    {...field}
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === '-' ||
                                            e.key === '+' ||
                                            e.key === 'e' ||
                                            e.key === 'E'
                                        ) {
                                            e.preventDefault()
                                        }
                                    }}
                                    onChange={onUnitPriceChange}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={control}
                    name='markup'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    className='font-sans pl-1 text-base h-11 border border-zentive-gray-light text-gray-900 disabled:bg-gray-100 rounded-sm'
                                    placeholder='Markup %'
                                    type='number'
                                    {...field}
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === '-' ||
                                            e.key === '+' ||
                                            e.key === 'e' ||
                                            e.key === 'E'
                                        ) {
                                            e.preventDefault()
                                        }
                                    }}
                                    onChange={onMarkupChange}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={control}
                    name='cost'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    className='font-sans pl-1 text-base h-11 border border-zentive-gray-light text-gray-900 disabled:bg-gray-100 rounded-sm'
                                    placeholder='Cost $'
                                    type='text'
                                    disabled
                                    {...field}
                                    value={
                                        field.value != 0
                                            ? typeof field.value === 'number' &&
                                              !isNaN(field.value)
                                                ? `$${field.value.toLocaleString(undefined, {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                })}`
                                                : ''
                                            : ''
                                    }
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </div>
    )
}
