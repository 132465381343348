import { ReactNode } from 'react'
import { BsPersonFill, BsPersonFillExclamation } from 'react-icons/bs'
interface CrewMemberProps {
    children: ReactNode
    isActive?: boolean // Typing isActive as a boolean
}

const CrewMember = ({ children, isActive = false }: CrewMemberProps) => {
    return (
        <div className='h-[33px] flex flex-row items-center text-zentive-black text-base w-fit rounded-[3px] border border-1 border-zentive-gray-light'>
            <p className='h-[33px] px-3 py-1'>{children}</p>
            <div className='h-full w-[38px] flex items-center justify-center bg-zentive-gray-light'>
                {isActive ? (
                    <BsPersonFill className='text-zentive-green-dark' />
                ) : (
                    <BsPersonFillExclamation className='text-zentive-red-dark' />
                )}
            </div>
        </div>
    )
}

export default CrewMember
