import { SortCriterion } from '@/api/lead/schema'
import { ArrowDown, ArrowUp } from 'lucide-react'
import { FC } from 'react'

type LeadsTableHeaderSorterProps = {
    order: SortCriterion['order']
}

const ARROW_PROPS = {
    height: 16,
    strokeWidth: 1,
}

const LeadsTableHeaderSorter: FC<LeadsTableHeaderSorterProps> = ({ order }) => {
    if (order === 'ASC') {
        return <ArrowUp {...ARROW_PROPS} />
    }

    return <ArrowDown {...ARROW_PROPS} />
}

export default LeadsTableHeaderSorter
