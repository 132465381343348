import { axiosInstance } from '../axiosInstance'
import { ProductServiceType, EmailServiceParamType, ProductServiceListType, ProductServiceOnSettingsType } from './schema'
import { productServiceSchemaOnSettingsSchema, SingleProductService } from '../quoting/schema'
import { PaginationType } from '@/components/Pagination/schema'

export const getAllServices = async (businessId: string, keyword: string) => {
    const response = await axiosInstance.get(
        `/services/?businessId=${businessId}&keyword=${keyword}`,
    )

    return response.data.content
}

export const getServiceList = async (
    businessId: string,
    keyword: string,
    p: PaginationType,
): Promise<ProductServiceListType> => {
    const response = await axiosInstance.get(
        `/services/service-list?businessId=${businessId}&keyword=${keyword}&page=${p.page}&pageSize=${p.pageSize}`,
    )

    return response.data
}

export const saveService = async (service: ProductServiceOnSettingsType) => {
    const payload = productServiceSchemaOnSettingsSchema.parse(service)

    const formData = new FormData()
    formData.append('productServiceId', payload.productServiceId as string)
    formData.append('businessId', payload.businessId as string)
    formData.append('type', payload.type as string)
    formData.append('name', (payload.name as string).trim())
    formData.append('description', (payload.description as string).trim())
    formData.append('cost', String(payload.cost))
    formData.append('markup', String(payload.markup))
    formData.append('unitPrice', String(payload.unitPrice))
    formData.append('isTaxExempt', String(payload.isTaxExempt))

    if (payload.serviceImage) {
        formData.append('serviceImage', payload.serviceImage)
    }
    try {
        const response = await axiosInstance.post(`/services`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        return response.data
    } catch (err) {
        console.error('Failed to save service:', err)
        throw err
    }
}

export const deleteService = async (serviceId: string) => {
    const res = await axiosInstance.delete(`/services/${serviceId}`)
    return res.data
}

export const updateService = async (service: ProductServiceType) => {
    const res = await axiosInstance.patch(`/services`, service)
    return res.data
}

export const getRequestServices = async (businessId: string) => {
    const response = await axiosInstance.get(`/services/list?businessId=${businessId}`)
    return response.data.content
}

export const getProductsAndServices = async (
    businessId: string,
): Promise<SingleProductService[]> => {
    const response = await axiosInstance.get(`/services/list?businessId=${businessId}`)
    return response.data.content.services
}

export const sendEmailRequest = async (param: EmailServiceParamType) => {
    const response = await axiosInstance.post(`/services/request`, param)
    return response.data.content
}

export const sendEmailNotes = async (param: EmailServiceParamType) => {
    const response = await axiosInstance.post(`/services/notes`, param)
    return response.data.content
}

export const getOwnerProfile = async (businessId: string) => {
    const response = await axiosInstance.get(`/profiles/owner/${businessId}`)

    return response.data.content
}
