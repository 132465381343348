import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { updateAutoPay, updateOwnerSubscriptionStatus } from '@/api/owner'
import {} from '@/api/owner'
import { Switch } from '@/components/Switch'
import { UpdateAutoPayType } from '@/api/owner/schema'
import { IoMdInformationCircle } from 'react-icons/io'
import { useAtomValue } from 'jotai'
import { userAtom } from '@/store/auth'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { getBusinessProfile } from '@/api/profile'
import { BusinessStatusType, ProfileSelfResponseType } from '@/api/business/schema'
import { USER_STATUS } from '@/constants'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import Tooltip from '@/components/Tooltip'
import { toast } from '@/hooks/useToast'
import Spinner from '@/components/Spinner'

const SubscriptionSettings = () => {
    const navigate = useNavigate()
    const user = useAtomValue(userAtom)
    const [isAutoPay, setIsAutoPay] = useState<boolean>(false)
    const queryClient = useQueryClient()

    const { data: businessProfileData } = useQuery<unknown, AxiosError, ProfileSelfResponseType>({
        queryKey: ['businessProfile'],
        queryFn: () => getBusinessProfile(user?.profileId as string),
    })

    useEffect(() => {
        setIsAutoPay(businessProfileData?.isAutoPay ?? false)
    }, [businessProfileData])

    const { mutate: cancelRenewalMu, isPending } = useMutation<unknown, AxiosError, unknown>({
        mutationFn: () => updateOwnerSubscriptionStatus(businessProfileData as BusinessStatusType), // to check by Air if working fine
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['businessProfile'] })
            if (businessProfileData?.business.status === USER_STATUS.ACTIVATED) {
                UpdateAutoPayMu({
                    profileId: user?.profileId as string,
                    isAutoPay: false,
                })
            }
        },
        onError: (err) => console.log(err),
    })

    const { mutate: UpdateAutoPayMu } = useMutation<unknown, AxiosError, UpdateAutoPayType>({
        mutationFn: updateAutoPay,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['businessProfile'] })
            const toastMessage = isAutoPay
                ? 'Successfully Turned On'
                : 'Successfully Turned Off'
            toast({
                description: toastMessage,
                variant: 'default',
            })
        },
    })

    return (
        <section>
            {businessProfileData?.business.isDowngrade && (
                <div className=' bg-zentive-bg-200 rounded-sm flex justify-between items-center px-[15px] mt-[24px] py-4'>
                    <p>
                        Downgrade Status: <span className='font-semibold'>Pending</span> <br />
                        Current Expiry Date:{' '}
                        {dayjs(businessProfileData?.business.subscriptionExpiryDate).format(
                            'MMMM DD, YYYY',
                        )}
                        <br />
                        In case you change your mind please cancel downgrade before <br />
                        {dayjs(businessProfileData?.business.subscriptionExpiryDate).format(
                            'MMMM DD, YYYY',
                        )}{' '}
                        to continue using our service.
                    </p>
                    <button>
                        <p
                            className='text-zentive-green-dark font-semibold mt-[16px] mb-[17px]  hover:cursor-pointer '
                            onClick={() => {
                                navigate('/owner-subscription/cancel-downgrade')
                            }}
                        >
                            Cancel Downgrade
                        </p>
                    </button>
                </div>
            )}
            <p className='mt-5 text-zentive-gray-medium font-bold'>Subscription Settings</p>
            <div className='bg-white rounded-md ring ring-zentive-gray-light ring-opacity-30'>
                <div className='flex justify-between items-center px-[15px] pb-4 w-full mt-3 pt-3'>
                    <div>
                        <p className='flex items-center text-base font-semibold gap-2'>
                            Enable Autopay
                            <Tooltip
                                position='bottom'
                                alignment='start'
                                width='64'
                                fontstyle='font-sans'
                                content='By turning autopay on, payments are automatically deducted from your bank account or charged to your credit card without requiring manual intervention each time.'
                            >
                                <IoMdInformationCircle />
                            </Tooltip>
                        </p>
                        <p className='text-sm'>Status: {isAutoPay ? 'On' : 'Off'}</p>
                    </div>
                    <div>
                        <AlertDialog>
                            <AlertDialogTrigger>
                                <Switch
                                    className='data-[state=checked]:bg-zentive-green-dark'
                                    checked={isAutoPay}
                                    disabled={
                                        businessProfileData?.business?.status ===
                                        USER_STATUS.BILLING_PAUSED
                                    }
                                />
                            </AlertDialogTrigger>
                            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                                <div className='flex gap-5 px-[26.5px] pt-[40px]'>
                                    <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                                        <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                                    </div>
                                    <div className=' overflow-auto'>
                                        <AlertDialogTitle className='text-left font-semibold text-2xl text-[#191A0A] mt-[10px]'>
                                            Turn {isAutoPay ? 'Off' : 'On'} Autopay
                                        </AlertDialogTitle>
                                        <AlertDialogDescription className='text-left text-sm text-zentive-gray-medium mt-[18px]'>
                                            By turning {isAutoPay ? 'off' : ''} autopay on, payments are {isAutoPay ? 'manually' : 'automatically '}deducted from your bank account or charged to your credit card without manual intervention each time.
                                        </AlertDialogDescription>
                                        <AlertDialogDescription className='text-left text-sm text-zentive-gray-medium mt-[18px]'>
                                            Are you sure you want to turn {isAutoPay ? 'off' : 'on'}{' '}
                                            autopay?
                                        </AlertDialogDescription>
                                    </div>
                                </div>
                                <AlertDialogFooter className='mx-[0px] bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[15px] pt-[6px]'>
                                    <div className='w-full flex justify-end items-end gap-[16px]'>
                                        <AlertDialogCancel
                                            onClick={() => {
                                                setIsAutoPay(isAutoPay)
                                            }}
                                            className='w-97 h-[43px] text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'
                                        >
                                            Cancel
                                        </AlertDialogCancel>
                                        <AlertDialogAction
                                            className='w-97 h-[43px] text-base font-semibold bg-zentive-green-dark'
                                            onClick={() => {
                                                setIsAutoPay(!isAutoPay)
                                                UpdateAutoPayMu({
                                                    profileId: user?.profileId,
                                                    isAutoPay: !isAutoPay,
                                                } as UpdateAutoPayType)
                                            }}
                                        >
                                            Turn {isAutoPay ? 'Off' : 'On'}
                                        </AlertDialogAction>
                                    </div>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                    </div>
                </div>
                <hr className='mx-5'></hr>
                <div className='flex justify-between items-center px-[15px] w-full  '>
                    <p>Renewal</p>
                    <AlertDialog>
                        <AlertDialogTrigger>
                            <button
                                disabled={
                                    businessProfileData?.business?.status === USER_STATUS.TERMINATED
                                }
                            >
                                <p className='text-zentive-red-dark mt-[16px] mb-[17px]  hover:cursor-pointer'>
                                    {isPending && <Spinner size='5' variant='normal' />}
                                    {!isPending &&
                                        businessProfileData?.business?.status ===
                                            USER_STATUS.ACTIVATED &&
                                        'Cancel Renewal'}
                                    {!isPending &&
                                        businessProfileData?.business?.status ===
                                            USER_STATUS.BILLING_PAUSED &&
                                        'Renew Subscription'}
                                </p>
                            </button>
                        </AlertDialogTrigger>
                        <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                            <div className='flex gap-5 px-[26.5px] pt-[40px]'>
                                <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                                    <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                                </div>
                                <div className=' overflow-auto'>
                                    <AlertDialogTitle className='text-left font-semibold text-2xl text-[#191A0A] mt-[10px]'>
                                        {businessProfileData?.business?.status ===
                                        USER_STATUS.ACTIVATED
                                            ? 'Cancel Renewal'
                                            : 'Renew Subscription'}
                                    </AlertDialogTitle>
                                    <AlertDialogDescription className='text-left text-sm text-zentive-gray-medium mt-[18px]'>
                                        By cancelling your renewal you will still have access to the
                                        subscription service until the current subscription period
                                        expires, but will lose all access thereafter.
                                    </AlertDialogDescription>
                                    <AlertDialogDescription className='text-left text-sm text-zentive-gray-medium mt-[18px]'>
                                        Are you sure you want to cancel your renewal?
                                    </AlertDialogDescription>
                                </div>
                            </div>
                            <AlertDialogFooter className='mx-[0px] bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[15px] pt-[6px]'>
                                <div className='w-full flex justify-end items-end gap-[16px]'>
                                    <AlertDialogCancel className='w-97 h-[43px] text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                                        Cancel
                                    </AlertDialogCancel>
                                    <AlertDialogAction
                                        className='h-[43px] text-base font-semibold bg-zentive-green-dark'
                                        onClick={cancelRenewalMu}
                                    >
                                        {businessProfileData?.business?.status ===
                                        USER_STATUS.ACTIVATED
                                            ? 'Cancel Renewal'
                                            : 'Renew Subscription'}
                                    </AlertDialogAction>
                                </div>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                </div>
            </div>
        </section>
    )
}

export default SubscriptionSettings
