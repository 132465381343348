import { BulkRemoveCrewMemberType, BulkUpdateCrewStatusSchema } from "@/api/crew/schema";
import { GetJobsByCrewMemberDateFilter } from "@/api/job";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const splashScreenAtom = atomWithStorage<boolean>('splash', false, undefined, {
    getOnInit: true,
})

export const languageAtom = atomWithStorage<string>('i18nextLng', 'en', undefined, {
    getOnInit: true,
})

export const bulkUpdateCrewStatusAtom = atom<BulkUpdateCrewStatusSchema>({
    crewIds: [],
    status: '',
})

export const crewMembersToRemoveAtom = atom<BulkRemoveCrewMemberType>({
    profileIds: []
})

export const crewInterfaceFilterAtom = atom<boolean>(false)
export const crewjobSearchOpenAtom = atom<boolean>(false)
export const isCrewJobsInitialFetch = atom<boolean>(false)
export const crewjobSearchKeywordAtom = atom<string>('')

export const selectedDateAtom = atomWithStorage<GetJobsByCrewMemberDateFilter>('sdca', {startDate: '', endDate: ''}, undefined, {
    getOnInit: true,
})

export const selectedDateFilterAtom = atomWithStorage<GetJobsByCrewMemberDateFilter>('sdcfa', {startDate: '', endDate: ''}, undefined, {
    getOnInit: true,
})

export const completedJobsTodayAtom = atomWithStorage<number>('cjta', 0, undefined, {
    getOnInit: true,
})

export const jobsToCompleteTodayAtom = atomWithStorage<number>('jtct', 0, undefined, {
    getOnInit: true,
})

export const totalToRenderedHoursInMinutesAtom = atomWithStorage<number>('ttrhinm', 0, undefined, {
    getOnInit: true,
})

export const totalRenderedHoursInMinutesAtom = atomWithStorage<number>('trhim', 0, undefined, {
    getOnInit: true,
})

export const crewSortByAtom = atom<string>('')
export const crewSortOrderAtom = atom<string>('')