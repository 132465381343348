import PaymentStep from './PaymentStep'
import { useAtomValue } from 'jotai'
import { paymentStepAtom } from '@/store/payment'

const PaymentSteps = () => {
    const currentStep = useAtomValue(paymentStepAtom)

    return (
        <section className='h-[110px] w-full border border-zentive-gray-light flex flex-row justify-center gap-[13px] px-[15.96px] py-[16.24px] rounded'>
            <PaymentStep currentStep={currentStep ?? 1} stepNumber={1} />
            <PaymentStep currentStep={currentStep ?? 1} stepNumber={2} />
        </section>
    )
}

export default PaymentSteps
