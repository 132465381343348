import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
} from '@/components/AlertDialog'
import { Dispatch, SetStateAction } from 'react'
import { FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'

import { Button } from '@/components/Button'
import { UseFormReturn } from 'react-hook-form'

interface AddCustomerModalProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    businessId: string
    onSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    inviteMethod: UseFormReturn<{
        email: string
        businessId: string
    }>
}

const AddCustomerModal: React.FC<AddCustomerModalProps> = ({
    setOpen,
    open,
    inviteMethod,
    onSubmit,
}) => {
    return (
        <AlertDialog onOpenChange={(o) => setOpen(o)} open={open}>
            <AlertDialogContent className='bg-white rounded-[15px] w-[23rem] p-0'>
                <div className='p-5 pb-0'>
                    <AlertDialogHeader className='text-left font-bold text-zentive-gray-dark text-2xl'>
                        Add Customer
                        <p className='text-sm mt-5 text-zentive-gray-medium font-normal'>
                            Please enter the customer's email address.
                        </p>
                    </AlertDialogHeader>

                    <div className='bg-white rounded-md flex mt-8 w-full'>
                        <FormField
                            name='email'
                            control={inviteMethod.control}
                            render={({ field }) => (
                                <FormItem className='w-full'>
                                    <FormControl>
                                        <Input
                                            placeholder='Email Address*'
                                            type='email'
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
                <AlertDialogFooter className='mx-0 bg-gray-100 rounded-b-[15px] pr-6 pb-4 pt-1.5 mt-5'>
                    <div className='w-full flex justify-end items-end gap-4 bg-gray-100 '>
                        <AlertDialogCancel
                            className={`bg-zentive-white px-5 w-32`}
                            type='button'
                            onClick={() => inviteMethod.resetField('email')}
                        >
                            Cancel
                        </AlertDialogCancel>
                        <Button
                            className='w-32'
                            onClick={onSubmit}
                            disabled={
                                !inviteMethod.formState.isValid ||
                                inviteMethod.formState.isSubmitting
                            }
                        >
                            Add
                        </Button>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default AddCustomerModal
