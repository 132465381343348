import BreadCrumbs from '@/components/BreadCrumbs'
import { ExpenseReportTabs } from './ExpenseReportTabs'
import { expenseReportFilterAtom, expenseReportTabsAtom } from '@/store/reports'
import { useAtomValue, useSetAtom } from 'jotai'
import { EXPENSE_REPORT_TABS } from '@/constants'
import InternalExpenseTable from './InternalExpenseTable'
import ExternalExpenseTable from './ExternalExpenseTable'
import { useEffect } from 'react'
import { ExpenseReportFilter } from './ExpenseReportFilter'

export const ExpenseReport = () => {
    const tab = useAtomValue(expenseReportTabsAtom)
    const setFilter = useSetAtom(expenseReportFilterAtom)

    useEffect(() => {
        setFilter((prev) => {
            if (!prev) {
                return {
                    start: '',
                    end: '',
                    keyword: '',
                    type: tab,
                }
            }

            return {
                ...prev,
                type: tab,
            }
        })
    }, [tab])

    return (
        <div className='flex flex-col w-full h-full px-[70px] pt-[50px] gap-5'>
            <BreadCrumbs titleName='Expense' />
            <ExpenseReportFilter />
            <ExpenseReportTabs />
            {tab === EXPENSE_REPORT_TABS.internal ? (
                <InternalExpenseTable />
            ) : (
                <ExternalExpenseTable />
            )}
        </div>
    )
}
