import BreadCrumbs from '@/components/BreadCrumbs'
import JobCompletionTable from './JobCompletionTable'
import { JobCompletionFilter } from './JobCompletionFIlter'

export const JobCompletion = () => {
    return (
        <div className='flex flex-col w-full h-full px-[70px] pt-[50px] gap-5'>
            <BreadCrumbs titleName='Job Completion' />
            <JobCompletionFilter />
            <JobCompletionTable />
        </div>
    )
}
