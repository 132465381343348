import { BillingInfoType } from '@/api/profile/schema'
import { FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import PhoneNumberInput from '@/components/InputNumber'
import { userAtom } from '@/store/auth'
import { useAtomValue } from 'jotai'
import { useFormContext } from 'react-hook-form'

function PersonalInfo() {
    const { control, watch } = useFormContext<BillingInfoType>()

    const formValues = watch()
    const user = useAtomValue(userAtom)

    return (
        <div className='grid grid-cols-3 sm:grid-cols-1 gap-4'>
            <div className='col-span-1'>
                <FormField
                    control={control}
                    defaultValue={formValues?.lastFourNo}
                    name='lastFourNo'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    className='font-sans pl-3 w-full text-base h-11 border border-black text-[#191A0A] disabled:bg-[#EBEBEB] rounded-sm'
                                    type='text'
                                    placeholder='Account Number*'
                                    disabled
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    control={control}
                    defaultValue={user?.firstName}
                    name='firstName'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    className='font-sans pl-3 w-full text-base h-11 border border-black text-[#191A0A] disabled:bg-[#EBEBEB] rounded-sm'
                                    type='text'
                                    placeholder='First Name*'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    control={control}
                    defaultValue={user?.lastName}
                    name='lastName'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    className='font-sans pl-3 w-full text-base h-11 border border-black text-[#191A0A] disabled:bg-[#EBEBEB] rounded-sm'
                                    type='text'
                                    placeholder='Last Name*'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    control={control}
                    defaultValue={user?.email}
                    name='email'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    className='font-sans pl-3 w-full text-base h-11 border border-black text-[#191A0A] disabled:bg-[#EBEBEB] rounded-sm'
                                    type='text'
                                    placeholder='Email Address*'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    control={control}
                    defaultValue={user?.phoneNumber}
                    name='contactNumber'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <PhoneNumberInput
                                    className='flex font-sans-pro  h-11 border border-zentive-gray-medium'
                                    defaultCountry='US'
                                    inputProps={{
                                        name: 'Contact Number',
                                        placeholder: 'Contact Number*',
                                    }}
                                    smartCaret={true}
                                    limitMaxLength={true}
                                    maxLength={16}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </div>
    )
}
export default PersonalInfo
