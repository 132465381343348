import { CustomerProfileType } from '@/api/profile/schema'

type CustomerDetailsProps = {
    customer: CustomerProfileType | null
}

const CustomerDetailsWithQuote: React.FC<CustomerDetailsProps> = ({ customer }) => {
    return (
        <section className='w-full'>
            <h1 className='font-bold text-zentive-green-dark text-xl flex flex-row gap-1'>
                {customer?.firstName + ' ' + customer?.lastName}
            </h1>
            <div className='mt-5 flex flex-row gap-7'>
                <div>
                    <h1 className='font-bold text-lg flex flex-row gap-1'>Property Address </h1>
                    <p className=''>
                        {customer?.address?.streetAddress +
                            ', ' +
                            customer?.address.city +
                            ', ' +
                            customer?.address.state +
                            ', ' +
                            customer?.address.zipCode}
                    </p>
                </div>

                <div>
                    <h1 className='font-bold text-lg flex flex-row gap-1'>Contact Details </h1>
                    <p className=''>{customer?.email}</p>
                    <p className=''>{customer?.phoneNumber}</p>
                </div>
            </div>
        </section>
    )
}

export default CustomerDetailsWithQuote
