import { Button } from '@/components/Button'
import SearchBar from '@/components/InputSearchBox'
import QuotingTableContents from './QuotingTable'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import { getAllQuotes } from '@/api/quoting'
import { useState } from 'react'
import { BulkDeleteQuoteType } from '@/api/quoting/schema'
import DeleteModal from './QuotingModal/BulkActionModal'
import { useAtom, useAtomValue } from 'jotai'
import QuoteFilterDialog from './QuotingFilter'
import { serviceProductFilter, serviceProductApprovedFilter } from '@/store/customer'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import { Pagination } from '@/components/Pagination'
import { paginationAtom } from '@/store/manageSubs'
import BreadCrumbs from '@/components/BreadCrumbs'
import { useEffect } from 'react'
import { quoteSortByAtom, quoteSortOrderAtom } from '@/store/quote'

const Quoting = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const [pagination, setPagination] = useAtom(paginationAtom)
    const [searchKeyword, setSearchKeyword] = useState('')
    const serviceProduct = useAtomValue(serviceProductFilter)
    const serviceProductApproved = useAtomValue(serviceProductApprovedFilter)
    const quoteSortBy = useAtomValue(quoteSortByAtom)
    const quoteSortOrder = useAtomValue(quoteSortOrderAtom)

    const [quotesToUpdate, setQuotesToUpdate] = useState<BulkDeleteQuoteType>({
        quoteIds: [],
    })
    const [_, setIsModalOpen] = useState<boolean>(false)
    const navigate = useNavigate()

    const [isCheckAll, setIsCheckAll] = useState<boolean>(false)

    const {
        data: quoteListType,
        isLoading,
        isSuccess,
    } = useQuery({
        queryKey: ['quoteList', pagination, searchKeyword, serviceProduct, serviceProductApproved, user?.businessId, quoteSortBy, quoteSortOrder],
        queryFn: () => getAllQuotes(user?.businessId as string, searchKeyword, serviceProduct, serviceProductApproved, pagination, quoteSortBy, quoteSortOrder),
    })

    useEffect(() => {
        const areAllQuotesChecked = () => {
            let allChecked = true
            quoteIdsPerPage()?.forEach((quoteId) => {
                if (!quotesToUpdate.quoteIds.includes(quoteId)) {
                    allChecked = false
                    return
                }
            })
            setIsCheckAll(allChecked)
        }

        areAllQuotesChecked()
    }, [quotesToUpdate, quoteListType])

    const quoteIdsPerPage = (): string[] => {
        const quoteIds: string[] = []
        quoteListType?.content?.forEach((item) => {
            if (item.quoteId) {
                quoteIds.push(item.quoteId)
            }
        })
        return quoteIds
    }

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName='Quoting' />
            <div className='flex flex-row items-start text-left justify-between mt-10'>
                <SearchBar
                    placeHolder='Search for...'
                    onSearchChange={(e) => setSearchKeyword(e.target.value)}
                    value={searchKeyword}
                />
                <div className='flex flex-row items-end'>
                    <QuoteFilterDialog />
                    <DeleteModal quotesToUpdate={quotesToUpdate} setOpen={setIsModalOpen} />
                    <Button
                        type='button'
                        onClick={() => navigate('/financial-management/quoting/add-quoting')}
                        className='border bg-zentive-green-dark text-white'
                    >
                        + Add Quote
                    </Button>
                </div>
            </div>

            <div className='mt-4 w-full'>
                {isSuccess && !isLoading ? (
                    <QuotingTableContents
                        quote={quoteListType}
                        setQuotesToUpdate={setQuotesToUpdate}
                        quotesToUpdate={quotesToUpdate}
                        isCheckAll={isCheckAll}
                        setIsCheckAll={setIsCheckAll}
                    />
                ) : null}
                {!quoteListType?.content?.length && isSuccess ? (
                    <div className='bg-white w-auto rounded-sm'>
                        <div className='w-[1260px]'> </div>
                        <div className='flex justify-center'>
                            <img
                                src={emptyPlaceholder}
                                alt='empty-table-placeholder'
                                className='my-12 h-60'
                            />
                        </div>
                        <p className='pb-4 text-center text-2xl text-gray-900'> Currently Empty </p>
                    </div>
                ) : (
                    <div className='mb-5 mt-3'>
                        {pagination.pageSize !== undefined && (
                            <Pagination
                                itemsPerPage={pagination.pageSize}
                                setPagination={setPagination}
                                totalRecords={quoteListType?.meta?.totalRecords ?? 1}
                                pagination={pagination}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Quoting
