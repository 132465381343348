import { getJobCompletionReport } from '@/api/reports'
import { JobCompletionRecordType, JobCompletionReportType } from '@/api/reports/schema'
import { Button } from '@/components/Button'
import { Card, CardContent } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import { Pagination } from '@/components/Pagination'
import { PaginationType } from '@/components/Pagination/schema'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import { RECORD_PREFIX, US_FORMAT } from '@/constants'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { jobCompletionExportAtom, jobCompletionFilterAtom } from '@/store/reports'
import { cn, displayCurrency, formatRecordNumber } from '@/utils/helper'
import { formatWorkingHours } from '@/utils/time'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useAtom, useAtomValue } from 'jotai'
import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

const tableHeader = [
    'Job Number',
    'Crew Name',
    'Job Type',
    'Date Completed',
    'Invoice Amount',
    'Labor Cost',
    'Time Spent',
    'Address',
    'Invoice Number',
    'Quote Number',
]

const JobCompletionTable = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const navigate = useNavigate()

    const [isAllChecked, setIsAllChecked] = useState(false)

    // const jobCompletionFilter = useAtomValue(jobCompletionFilterAtom)
    const jobCompletionFilter = useAtomValue(jobCompletionFilterAtom)
    const [exportAtom, setExportAtom] = useAtom(jobCompletionExportAtom)

    const [pagination, setPagination] = useState<PaginationType>({
        page: 1,
        pageSize: 5,
    })

    const { data } = useQuery<JobCompletionReportType>({
        queryKey: ['jobCompletionReport', user?.businessId, jobCompletionFilter],
        queryFn: () =>
            getJobCompletionReport(user?.businessId as string, jobCompletionFilter, pagination),
    })

    const handleCheckAllChange = (isChecked: boolean) => {
        setIsAllChecked(isChecked)

        if (isChecked) {
            // Add all available records to exportAtom
            const allRecords = data?.content?.report ?? []

            setExportAtom({
                records: allRecords,
                totalInvoiceAmount: allRecords.reduce(
                    (total, record) => total + record.invoiceAmount,
                    0,
                ),
                totalLaborCost: allRecords.reduce((total, record) => total + record.laborCost, 0),
                totalTimeSpent: allRecords.reduce((total, record) => total + record.timeSpent, 0),
            })
        } else {
            // Clear all records from exportAtom
            setExportAtom({
                records: [],
                totalInvoiceAmount: 0,
                totalLaborCost: 0,
                totalTimeSpent: 0,
            })
        }
    }

    const handleCheckChange = (job: JobCompletionRecordType) => {
        setExportAtom((prev) => {
            if (!prev) {
                // If there is no existing filter, create one with the new invoiceId
                return {
                    records: [job],
                    totalInvoiceAmount: job.invoiceAmount,
                    totalLaborCost: job.laborCost,
                    totalTimeSpent: job.timeSpent,
                }
            }

            const isJobSelected = prev.records?.some((record) => record.jobId === record.jobId)

            // Update records based on whether the job is being added or removed
            const updatedJobs = isJobSelected
                ? prev.records.filter((record) => record.jobId !== job.jobId) // Remove if exists
                : [...(prev.records || []), job] // Add if it doesn't exist

            return {
                records: updatedJobs,
                totalInvoiceAmount: isJobSelected
                    ? prev.totalInvoiceAmount - job.invoiceAmount
                    : prev.totalInvoiceAmount + job.invoiceAmount,
                totalLaborCost: isJobSelected
                    ? prev.totalLaborCost - job.laborCost
                    : prev.totalLaborCost + job.laborCost,
                totalTimeSpent: isJobSelected
                    ? prev.totalTimeSpent - job.timeSpent
                    : prev.totalTimeSpent + job.timeSpent,
            }
        })
    }

    return (
        <Card>
            <CardContent className='overflow-x-scroll green-scrollbar rounded-t-none pb-0'>
                <Table className='table-auto whitespace-normal mx-auto'>
                    <TableHeader className='whitespace-nowrap'>
                        <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                            {tableHeader.map((header, index) => (
                                <TableHead
                                    key={index}
                                    className={cn(
                                        'font-semibold text-zentive-gray-medium text-md whitespace-nowrap',
                                    )}
                                >
                                    <div className='flex flex-row items-center justify-start gap-2'>
                                        {' '}
                                        {index === 0 && (
                                            <Checkbox
                                                onCheckedChange={handleCheckAllChange}
                                                checked={
                                                    isAllChecked ||
                                                    exportAtom?.records?.length ===
                                                        data?.content?.report?.length
                                                }
                                                className='mt-[1px]'
                                            />
                                        )}
                                        {header}
                                    </div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {Array.isArray(data?.content?.report) &&
                            data?.content?.report?.length > 0 &&
                            data?.content?.report?.map((r: JobCompletionRecordType) => (
                                <TableRow
                                    key={r.quoteNumber as number}
                                    className='whitespace-nowrap'
                                >
                                    <TableCell className='text-zentive-black cursor-pointer text-md flex flex-row gap-2 items-center'>
                                        <Checkbox
                                            onCheckedChange={() => handleCheckChange(r)}
                                            checked={exportAtom?.records?.some(
                                                (record) => r.jobId === record.jobId,
                                            )}
                                        />
                                        <NavLink
                                            to={r.jobNumber ? `/schedule/jobs/${r.jobId}` : '#'}
                                        >
                                            <Button
                                                className='text-md text-zentive-blue-dark font-semibold'
                                                variant={'link'}
                                            >
                                                {r.jobNumber
                                                    ? RECORD_PREFIX.job +
                                                      formatRecordNumber(r.jobNumber)
                                                    : 'N/A'}
                                            </Button>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell
                                        className='text-zentive-black cursor-pointer text-md'
                                        onClick={() =>
                                            navigate(
                                                `/reports/jobs-completion-report-details/${r.jobId}`,
                                            )
                                        }
                                    >
                                        {r.crewName}
                                    </TableCell>
                                    <TableCell
                                        className='text-zentive-black cursor-pointer text-md'
                                        onClick={() =>
                                            navigate(
                                                `/reports/jobs-completion-report-details/${r.jobId}`,
                                            )
                                        }
                                    >
                                        <p>{r.services.join(', ')}</p>
                                    </TableCell>
                                    <TableCell
                                        className='text-zentive-black cursor-pointer text-md'
                                        onClick={() =>
                                            navigate(
                                                `/reports/jobs-completion-report-details/${r.jobId}`,
                                            )
                                        }
                                    >
                                        <p>{dayjs(r.date).format(US_FORMAT)}</p>
                                    </TableCell>
                                    <TableCell
                                        onClick={() =>
                                            navigate(
                                                `/reports/jobs-completion-report-details/${r.jobId}`,
                                            )
                                        }
                                    >
                                        <p>{displayCurrency(r.invoiceAmount)}</p>
                                    </TableCell>
                                    <TableCell
                                        onClick={() =>
                                            navigate(
                                                `/reports/jobs-completion-report-details/${r.jobId}`,
                                            )
                                        }
                                    >
                                        <p>{displayCurrency(r.laborCost)}</p>
                                    </TableCell>
                                    <TableCell
                                        onClick={() =>
                                            navigate(
                                                `/reports/jobs-completion-report-details/${r.jobId}`,
                                            )
                                        }
                                    >
                                        <p>{formatWorkingHours(r.timeSpent)}</p>
                                    </TableCell>
                                    <TableCell
                                        onClick={() =>
                                            navigate(
                                                `/reports/jobs-completion-report-details/${r.jobId}`,
                                            )
                                        }
                                    >
                                        <p>{r.address}</p>
                                    </TableCell>
                                    <TableCell>
                                        <NavLink
                                            to={
                                                r.invoiceNumber
                                                    ? `/financial-management/invoicing/view-invoice/${r.invoiceId}`
                                                    : '#'
                                            }
                                        >
                                            <Button
                                                className='text-md text-zentive-blue-dark font-semibold'
                                                variant={'link'}
                                            >
                                                {r.invoiceNumber
                                                    ? RECORD_PREFIX.invoice +
                                                      formatRecordNumber(r.invoiceNumber)
                                                    : 'N/A'}
                                            </Button>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell>
                                        <NavLink
                                            to={
                                                r.quoteId
                                                    ? `/financial-management/quoting/view?quoteId=${r.quoteId}`
                                                    : '#'
                                            }
                                        >
                                            <Button
                                                className='text-md text-zentive-blue-dark font-semibold'
                                                variant={'link'}
                                            >
                                                {r.quoteNumber
                                                    ? RECORD_PREFIX.quote +
                                                      formatRecordNumber(r.quoteNumber)
                                                    : 'N/A'}
                                            </Button>
                                        </NavLink>
                                    </TableCell>
                                </TableRow>
                            ))}
                        {data?.content?.report && (
                            <TableRow key='total-row' className='whitespace-nowrap'>
                                <TableCell>
                                    <p className='font-bold text-md'>Total</p>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    <p className='font-bold text-md'>
                                        {displayCurrency(data?.content?.totalInvoiceAmount ?? 0)}
                                    </p>
                                </TableCell>
                                <TableCell>
                                    <p className='font-bold text-md'>
                                        {displayCurrency(data?.content?.totalLaborCost ?? 0)}
                                    </p>
                                </TableCell>
                                <TableCell>
                                    <p className='font-bold text-md'>
                                        {formatWorkingHours(data?.content?.totalTimeSpent ?? 0)}
                                    </p>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </CardContent>
            <div className='p-5 pb-0 flex flex-row items-center'>
                <Pagination
                    pagination={pagination}
                    setPagination={setPagination}
                    itemsPerPage={data?.meta?.pageSize ?? 5}
                    totalRecords={data?.meta?.totalRecords ?? 0}
                />
            </div>
        </Card>
    )
}
export default JobCompletionTable
