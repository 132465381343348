import { Button } from '@/components/Button'
import { Form } from '@/components/Forms'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { InputFormData } from './InputForm'
import { ProductServiceType } from '@/api/service/schema'
import { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { saveService } from '@/api/service'
import { useToast } from '@/hooks/useToast'
import { useAtomValue } from 'jotai'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import './style.css'
import { FullProfileType } from '@/api/profile/schema'
import BreadCrumbs from '@/components/BreadCrumbs'
import { useNavigate } from 'react-router-dom'
import { PRODUCT_SERVICE_TYPES, ROLE } from '@/constants'
import { productServiceSchemaOnSettingsSchema } from '@/api/quoting/schema'

const CreateService = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const isCustomer = user?.role.roleName === ROLE.CUSTOMER

    const { toast } = useToast()
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const methods = useForm<ProductServiceType>({
        defaultValues: {
            businessId: user?.businessId,
            cost: 0.0,
            description: '',
            isTaxExempt: false,
            markup: 0.0,
            name: '',
            type: PRODUCT_SERVICE_TYPES[1].toLowerCase(),
            unitPrice: 0.0,
        },
        mode: 'onChange',
        resolver: zodResolver(productServiceSchemaOnSettingsSchema),
    })

    const {
        handleSubmit,
        formState: { isValid },
    } = methods

    const { mutate: saveServiceMu } = useMutation<
        ProductServiceType,
        AxiosError,
        ProductServiceType
    >({
        mutationKey: ['SaveService'],
        mutationFn: saveService,
        onSuccess: () => {
            toast({
                description: `${
                    methods.getValues('type').charAt(0).toUpperCase() +
                    methods.getValues('type').slice(1)
                } Saved`,
                variant: 'default',
            })
            queryClient.invalidateQueries({ queryKey: ['ProductServiceType'] })
            setIsSubmitting(true)
            navigate('/settings/product-services')
        },
        onError: (err) => {
            console.error(err)
            toast({
                description: 'Product/Service already exists',
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: ProductServiceType) => saveServiceMu(data)

    const onCancelButton = () => navigate('/settings/product-services')

    return !isCustomer ? (
        <>
            <BreadCrumbs titleName='Add Items' />
            <div className='flex py-6 justify-center'>
                <Form {...methods}>
                    <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
                        <InputFormData />
                        <div className='py-5 md:py-0 bg-zentive-green-light left-0 w-full md:h-[75px] rounded-b-[8px] flex items-center justify-end px-[26px] gap-[12px]'>
                            <Button
                                className='w-[95px] h-[43px] border border-zentive-green-dark text-zentive-green-dark hover:text-white font-semibold bg-white rounded-[5px] text-[16px] hover:bg-zentive-green-dark'
                                type='button'
                                onClick={onCancelButton}
                            >
                                Cancel
                            </Button>
                            <Button
                                className='w-[95px] h-[43px] text-white font-semibold bg-zentive-green-dark  rounded-[5px] text-[16px] hover:bg-zentive-green-medium'
                                type='submit'
                                disabled={!isValid || isSubmitting}
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                </Form>
            </div>
        </>
    ) : null
}

export default CreateService
