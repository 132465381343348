import autoTable from 'jspdf-autotable'
import jsPDF from 'jspdf'

interface ILeadData {
    dealSize: string
    customerName: string
    serviceType: string
    dealStage: string
    createdAt: string
}

export const PDFExport = (zentiveLogoUrl: string, tableData: ILeadData[]) => {
    const doc = new jsPDF({ orientation: 'landscape' })

    let startY = 10
    const imageHeight = 15
    const imageWidth = 50

    const pageWidth = doc.internal.pageSize.getWidth()
    const centerX = (pageWidth - imageWidth) / 2

    doc.addImage(zentiveLogoUrl, 'JPEG', centerX, startY, imageWidth, imageHeight)

    startY += imageHeight + 10

    doc.setFontSize(16)
    doc.text('Leads', pageWidth / 2, startY, { align: 'center' })

    startY += 10

    const head = [['Deal Size', 'Customer', 'Service Type', 'Deals Stage', 'Date']]

    const body = tableData?.map((lead) => {
        const formattedDealSize = `$${parseFloat(lead.dealSize).toFixed(2)}`
        return [
            formattedDealSize,
            lead.customerName,
            lead.serviceType,
            lead.dealStage,
            lead.createdAt,
        ]
    })

    autoTable(doc, {
        startY: startY,
        head: head,
        body: body,
        theme: 'striped',
        styles: { fontSize: 8, cellPadding: 2, overflow: 'linebreak' },
        headStyles: { fillColor: [59, 103, 26], textColor: [255, 255, 255], fontSize: 8 },
        didDrawPage: (lead) => {
            const pageCount = doc.getNumberOfPages()
            const footerStr = `Page ${lead.pageNumber} of ${pageCount}`
            doc.setFontSize(10)
            doc.text(footerStr, lead.settings.margin.left, doc.internal.pageSize.getHeight() - 10)
        },
    })

    doc.save('Lead-Data.pdf')
}
