import { addBusinessNotes, getBusinessNotes, updateBusinessNotes } from '@/api/notes'
import { NotePayloadType, NoteResponseType, noteSchema } from '@/api/notes/schema'
import { Button } from '@/components/Button'
import { Label } from '@/components/Label'
import { Textarea } from '@/components/TextArea'
import { useToast } from '@/hooks/useToast'
import { manageSubsTabAtom } from '@/store/manageSubs'
import { cn } from '@/utils/helper'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import { FaPlus } from 'react-icons/fa'
import { USER_STATUS } from '@/constants'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { getEmployeeById } from '@/api/profile'

const EmployeeNotes = () => {
    const [currentNote, setNote] = useState<string>('')
    const { toast } = useToast()
    const queryClient = useQueryClient()
    const status = useAtomValue(manageSubsTabAtom)
    const [updateIndex, setUpdateIndex] = useState<number>()
    const [updatedNoteContent, setUpdatedNoteContent] = useState<string>('')
    const [showTextBox, setShowTextBox] = useState<boolean>(false)
    const now = dayjs()

    const navigate = useNavigate()
    const { profileId } = useParams()

    const createEmployeeNotesMethods = useForm<NotePayloadType>({
        mode: 'onChange',
        resolver: zodResolver(noteSchema),
    })

    const updateEmployeeNotesMethods = useForm<NotePayloadType>({
        mode: 'onChange',
        resolver: zodResolver(noteSchema),
    })

    const { data: notesData, isLoading } = useQuery<unknown, AxiosError, NoteResponseType>({
        queryKey: ['getNotes', profileId],
        queryFn: () => getBusinessNotes(profileId as string),
    })

    const { data: employeeData } = useQuery({
        queryKey: ['employee', profileId],
        queryFn: () => getEmployeeById(profileId),
    })

    const { mutate: addNotesMu } = useMutation<unknown, AxiosError, NotePayloadType>({
        mutationFn: (newNote) => addBusinessNotes(newNote),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getNotes'] })
            setShowTextBox(false)
            toast({
                description: 'Successfully added a note!',
                variant: 'default',
            }),
            createEmployeeNotesMethods.setValue('note', '')
        },
    })

    const { setValue: setAddNotesValue } = createEmployeeNotesMethods
    const { setValue: setUpdateNotesValue } = updateEmployeeNotesMethods

    const { mutate: updateNotesMu, isPending: updateLoading } = useMutation({
        mutationFn: (newNote: NotePayloadType) => updateBusinessNotes(newNote),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getNotes'] }),
                toast({
                    description: 'Note updated successfully',
                    variant: 'default',
                }),
                setUpdateIndex(undefined)
        },
    })

    const onSubmitCreateNotes = (data: NotePayloadType) => {
        addNotesMu(data)
    }

    const onSubmitUdpateNotes = (data: NotePayloadType) => {
        updateNotesMu(data)
    }
    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: ['getNotes'] })
        setAddNotesValue('profileId', profileId)
        setUpdateNotesValue('profileId', profileId)
    }, [profileId])

    useEffect(() => {
        setNote('')
        updateIndex !== undefined &&
            notesData?.data[0]?.notes &&
            notesData.data[0].notes[updateIndex]?.note &&
            setNote(notesData.data[0].notes[updateIndex].note)
    }, [updateIndex, notesData])

    const isNotesEmpty = !createEmployeeNotesMethods.watch('note')

    const handleCancel = () => {
        setShowTextBox(false);
        createEmployeeNotesMethods.setValue('note', '')
    }
    
    const renderTextBox = () => {
        return (
            <Form {...createEmployeeNotesMethods}>
                <form onSubmit={createEmployeeNotesMethods.handleSubmit(onSubmitCreateNotes)}>
                    <div className='w-full mx-auto py-3'>
                        <div className={cn('ring-1 ring-zentive-gray-medium', 'rounded-[4px]')}>
                            <div className='p-1'>
                                <Label className='flex justify-end text-[12px] mr-2 text-zentive-gray-medium'>
                                    {dayjs(now).format('MMMM DD, YYYY hh:mm A')}
                                </Label>
                                <FormField
                                    control={createEmployeeNotesMethods.control}
                                    name='note'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Textarea
                                                    className='border-white text-[#191A0A] min-h-[85px] text-[16px] focus-visible:ring-0 focus-visible:ring-offset-0'
                                                    // onChange={(event) => {
                                                    //     setNewNoteContent(event.target.value.trim())
                                                    // }}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className='bg-[#EBEBEB] rounded-b-[4px] flex flex-row w-full justify-end gap-x-4 items-centerp p-4'>
                                <Button
                                    variant='ghost'
                                    className='w-97 h-[43px] mr-[7px] b-[16px] text-zentive-green-dark rounded-[5px] font-sans font-semibold text-base border border-zentive-green-dark'
                                    onClick={() => handleCancel()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className='w-97 h-[43px] mr-[7px] b-[16px] text-white bg-[#3a671a] rounded-[5px] font-sans font-semibold text-base hover:bg-zentive-green-medium'
                                    //disabled={addLoading || newNoteContent?.length == 0}
                                    /* onClick={() => {
                                        const newNoteData = {
                                            profileId: profileId,
                                            note: newNoteContent,
                                        } as NotePayloadType

                                        addNotesMu(newNoteData)
                                    }} */
                                    disabled={isNotesEmpty}
                                    type='submit'
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </Form>
        )
    }

    return (
        <section>
            <div className='flex bg-white justify-end px-6'>
                <Button
                    onClick={() => setShowTextBox(true)}
                    type='button'
                    variant={'outline'}
                    className='shadow-md px-10 mt-2 mb-4'
                    disabled={
                        employeeData?.status === USER_STATUS.INACTIVE ||
                        employeeData?.status === USER_STATUS.DELETED
                    }
                >
                    <FaPlus className='w-4 h-4 mr-2' />
                    Add a Note
                </Button>
            </div>
            <div className='w-full bg-white px-6'> {showTextBox && renderTextBox()} </div>
            {!isLoading && (
                <div
                    className={cn(
                        !notesData?.data[0]?.notes?.length ? '' : 'flex',
                        'bg-white w-full ',
                    )}
                >
                    {status === 'TER' ? (
                        <div className='w-full mx-[25px]'>
                            {notesData?.data[0]?.notes?.map((singleNote) => (
                                <div className='w-full' key={singleNote.noteId}>
                                    <div className='bg-zentive-gray-bg w-full h-[85px] mt-[36px]'>
                                        <Label className='flex justify-end pt-[16px] mr-[12px] mb-[14px]'>
                                            {dayjs(singleNote.createdAt).format(
                                                'MMMM DD, YYYY hh:mm A',
                                            )}
                                        </Label>
                                        <Label className='text-[#191A0A] text-[16px] ml-[16px] pt-[50px]'>
                                            {singleNote.note}
                                        </Label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <>
                            {!notesData?.data[0]?.notes?.length && (
                                <div className='py-12 flex justify-center text-center'>
                                    <div>
                                        <img
                                            src={emptyPlaceholder}
                                            alt='email-logo'
                                            className=' w-full mb-6 h-48'
                                        />
                                        <Label className='text-2xl text-gray-900'>
                                            Currently Empty
                                        </Label>
                                    </div>
                                </div>
                            )}
                            <div className='w-full mx-[25px] mb-[16px]'>
                                {!!notesData?.data[0]?.notes?.length &&
                                    notesData?.data[0]?.notes?.map((singleNote, index) => (
                                        <Form {...updateEmployeeNotesMethods}>
                                            <form
                                                onSubmit={updateEmployeeNotesMethods.handleSubmit(
                                                    onSubmitUdpateNotes,
                                                )}
                                            >
                                                <div
                                                    className='w-full'
                                                    key={singleNote.noteId}
                                                    onClick={() => {
                                                        setUpdateIndex(index)
                                                    }}
                                                >
                                                    <div
                                                        className={cn(
                                                            index === updateIndex
                                                                ? 'ring-2 ring-zentive-green-dark'
                                                                : 'ring-1 ring-zentive-gray-medium',
                                                            'mt-3 p-1 rounded-[4px] ',
                                                        )}
                                                    >
                                                        <Label className='flex justify-end mt-2 text-[12px] mr-2 text-zentive-gray-medium'>
                                                            {dayjs(singleNote.createdAt).format(
                                                                'MMMM DD, YYYY hh:mm A',
                                                            )}
                                                        </Label>
                                                        <Textarea
                                                            className='border-white text-[#191A0A] min-h-[85px] text-[16px] focus-visible:ring-0 focus-visible:ring-offset-0'
                                                            name='notes'
                                                            disabled={
                                                                employeeData?.status ===
                                                                    USER_STATUS.INACTIVE ||
                                                                employeeData?.status ===
                                                                    USER_STATUS.DELETED
                                                            }
                                                            value={
                                                                index === updateIndex
                                                                    ? currentNote
                                                                    : singleNote.note
                                                            }
                                                            onChange={(event) => {
                                                                setUpdatedNoteContent(
                                                                    event.target.value,
                                                                )
                                                                setNote(event.target.value)
                                                                // singleNote.note = event.target.value <-- to delete if there's no issue
                                                            }}
                                                        />
                                                    </div>
                                                    <p className='flex justify-end text-[12px] text-zentive-gray-medium'>
                                                        {index === updateIndex
                                                            ? `${currentNote?.length}/1000`
                                                            : singleNote?.note?.length === undefined
                                                              ? `0/1000`
                                                              : `${singleNote.note?.length}/1000`}
                                                    </p>
                                                </div>
                                            </form>
                                        </Form>
                                    ))}
                            </div>
                        </>
                    )}
                </div>
            )}
            {(status !== 'TER' && employeeData?.status === USER_STATUS.INACTIVE) ||
                employeeData?.status === USER_STATUS.DELETED ||
                (notesData?.data[0]?.notes?.length && notesData?.data[0]?.notes?.length > 0 && (
                    <div className='flex justify-end bg-[#EBEBEB] rounded-b-[15px]'>
                        <Button
                            onClick={() => navigate(-1)}
                            className='w-[95px] h-[43px] mr-[16px] mt-[16px] mb-[16px] text-zentive-green-dark bg-[#F9F9F9] rounded-[5px] ring-2 ring-inset ring-zentive-green-dark font-sans font-semibold text-[16px] hover:text-white hover:bg-[#3a671a] '
                        >
                            Cancel
                        </Button>
                        <Button
                            className='w-[95px] h-[43px] mr-6 mt-[16px] mb-[16px] text-white bg-[#3a671a] rounded-[5px] font-sans font-semibold text-[16px] hover:bg-zentive-green-medium'
                            disabled={
                                updateIndex === undefined ||
                                updateIndex === null ||
                                isLoading ||
                                updateLoading || !updatedNoteContent
                            }
                            onClick={() => {
                                if (updateIndex !== undefined) {
                                    const newNoteData = {
                                        noteId: notesData?.data[0]?.notes[updateIndex].noteId,
                                        note: updatedNoteContent,
                                    } as NotePayloadType

                                    updateNotesMu(newNoteData)
                                }
                            }}
                        >
                            Update
                        </Button>
                    </div>
                ))}
        </section>
    )
}

export default EmployeeNotes
