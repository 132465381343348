import { CardHeader, CardTitle } from '@/components/Card'
import { QuoteType } from '@/api/quoting/schema'
import HeaderClientDetails from './ClientDetails'
import { QUOTE_STATUS } from '@/constants'

const ViewQuotingHeader = ({ quote }: { quote: QuoteType }) => {
    return (
        <CardHeader className=' border-b-[12px] border-zentive-gray-light'>
            <div className=' mx-5'>
                <div className='flex flex-row w-full justify-between py-6 border-b-2'>
                    <CardTitle className='text-[24px]'>
                        Quote Number: {quote?.quoteNumber}
                    </CardTitle>
                    {quote?.status === QUOTE_STATUS.APPROVED ? (
                        <div className='w-[100px] text-center my-auto  bg-zentive-blue-dark px-2  text-white font-normal rounded-full'>
                            Approved
                        </div>
                    ) : quote?.status === QUOTE_STATUS.DECLINED ? (
                        <div className='w-[120px] text-center my-auto  bg-[#EBEBEB] px-2  text-black font-normal rounded-full'>
                            Declined
                        </div>
                    ) : (
                        <div className='w-[120px] text-center my-auto  bg-[#EBEBEB] px-2  text-black font-normal rounded-full'>
                            Pending
                        </div>
                    )}
                </div>
            </div>
            <HeaderClientDetails quote={quote} />
        </CardHeader>
    )
}

export default ViewQuotingHeader
