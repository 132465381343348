import { CardHeader, CardTitle } from '@/components/Card'
import ClientDialog from './ClientModal'
import { useAtomValue } from 'jotai'
import { customerQuoteAtom } from '@/store/owner'
import HeaderClientDetails from './ClientDetails'

const AddQuotingHeader = () => {
    const customer = useAtomValue(customerQuoteAtom)

    return (
        <CardHeader className='border-b-8 border-zentive-gray-light'>
            <div className='flex flex-row justify-between mx-5'>
                <CardTitle className='text-[24px]'>Quote for</CardTitle>
            </div>
            {customer == null ? <ClientDialog isEditing={false} /> : <HeaderClientDetails />}
        </CardHeader>
    )
}

export default AddQuotingHeader
