import ContentService from './Service'
import Expenses from './Expenses'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { ExpenseType, ProductServiceGroupType } from '@/api/quoting/schema'

const ServicesAndExpensesWithoutQuote = () => {
    const { getValues, setValue, watch } = useFormContext()

    const expenses: ExpenseType[] = watch('quote.expense')
    const productServiceGroup: ProductServiceGroupType[] = watch('quote.productServiceGroup')

    useEffect(() => {
        let totalAmount: number = 0

        if (expenses?.length) {
            totalAmount = totalAmount + expenses.reduce((acc, expense) => acc + expense.total, 0)
        }

        if (productServiceGroup?.length) {
            totalAmount =
                totalAmount +
                productServiceGroup.reduce((acc, productService) => acc + productService.cost, 0)
        }

        setValue('quote.subTotal', totalAmount)
        setValue('quote.totalAmount', totalAmount)
    }, [expenses, JSON.stringify(productServiceGroup), getValues])

    return (
        <>
            <ContentService />
            <Expenses />
        </>
    )
}

export default ServicesAndExpensesWithoutQuote
