import { getAdminDashboardSubscriberCount } from '@/api/reports'
import { DashboardSubscriberCountType } from '@/api/reports/schema'
import Spinner from '@/components/Spinner'
import { useQuery } from '@tanstack/react-query'
import { ArrowUp } from 'lucide-react'

export const SubscribersCount = () => {
    const { data: subscriberCount, isLoading: loading } = useQuery<DashboardSubscriberCountType>({
        queryKey: ['subsCount'],
        queryFn: () => getAdminDashboardSubscriberCount(),
    })

    return (
        <div className='flex flex-col gap-10 mt-10'>
            <div className='flex flex-row gap-10'>
                <div className='flex flex-col gap-2 bg-white h-[11rem] w-[12rem] p-5'>
                    {loading ? (
                        <Spinner variant={'normal'} className='h-5 w-5 mx-auto my-auto' />
                    ) : (
                        <>
                            <h1 className='text-sm text-zentive-gray-medium font-bold'>
                                Active Subscriber
                            </h1>
                            <p className='text-4xl font-bold text-zentive-green-dark'>
                                {subscriberCount?.activeSubscriber}
                            </p>
                            <p className='text-sm flex flex-row text-zentive-green-300 font-bold'>
                                <ArrowUp className='h-3 w-3 mt-1' />
                                <span>{subscriberCount?.activeLastMonthIncrement}% </span>
                            </p>
                            <p className='text-sm text-zentive-gray-medium'>Since Last Month</p>
                        </>
                    )}
                </div>

                <div className='flex flex-col gap-2 bg-white h-[11rem] w-[12rem] p-5'>
                    {loading ? (
                        <Spinner variant={'normal'} className='h-5 w-5 mx-auto my-auto' />
                    ) : (
                        <>
                            <h1 className='text-sm text-zentive-gray-medium font-bold'>
                                New Paid User
                            </h1>
                            <p className='text-4xl font-bold text-zentive-green-dark'>
                                {subscriberCount?.newPaidSubscriber}
                            </p>
                            <p className='text-sm flex flex-row text-zentive-green-300 font-bold'>
                                <ArrowUp className='h-3 w-3 mt-1' />
                                <span>{subscriberCount?.newPaidLastMonthIncrement}% </span>
                            </p>
                            <p className='text-sm text-zentive-gray-medium'>Since Last Month</p>
                        </>
                    )}
                </div>
            </div>

            <div className='flex flex-row gap-10'>
                <div className='flex flex-col gap-2 bg-white h-[11rem] w-[12rem] p-5'>
                    {loading ? (
                        <Spinner variant={'normal'} className='h-5 w-5 mx-auto my-auto' />
                    ) : (
                        <>
                            <h1 className='text-sm text-zentive-gray-medium font-bold'>
                                Cancelled Subscription
                            </h1>
                            <p className='text-4xl font-bold text-zentive-green-dark'>
                                {subscriberCount?.canceledSubscription}
                            </p>
                            <p className='text-sm flex flex-row text-zentive-red-500 font-bold'>
                                <ArrowUp className='h-3 w-3 mt-1' />
                                <span>{subscriberCount?.canceledLastMonthIncrement}% </span>
                            </p>
                            <p className='text-sm text-zentive-gray-medium'>Since Last Month</p>
                        </>
                    )}
                </div>

                <div className='flex flex-col gap-2 bg-white h-[11rem] w-[12rem] p-5'>
                    {loading ? (
                        <Spinner variant={'normal'} className='h-5 w-5 mx-auto my-auto' />
                    ) : (
                        <>
                            <h1 className='text-sm text-zentive-gray-medium font-bold'>
                                Deleted Subscriber
                            </h1>
                            <p className='text-4xl font-bold text-zentive-green-dark'>
                                {subscriberCount?.deletedSubscriber}
                            </p>
                            <p className='text-sm flex flex-row text-zentive-red-500 font-bold'>
                                <ArrowUp className='h-3 w-3 mt-1' />
                                <span>{subscriberCount?.deletedLastMonthIncrement}% </span>
                            </p>
                            <p className='text-sm text-zentive-gray-medium'>Since Last Month</p>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
