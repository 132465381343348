import { CardTitle } from '@/components/Card'
import { TableRow, TableCell, Table, TableBody } from '@/components/Table'
import ExpenseDialog from './ExpenseDialog'
import ExpenseTableHead from './ExpenseTable/ExpenseHeader'
import { expenseAtom, quoteContentAtom } from '@/store/owner'
import { useAtom } from 'jotai'
import { CiTrash } from 'react-icons/ci'
import { useFormContext } from 'react-hook-form'
import { QuoteType } from '@/api/quoting/schema'

const Expense = () => {
    const [expenses, setExpenses] = useAtom(expenseAtom)
    const [quoteContent, setQuoteContent] = useAtom(quoteContentAtom)
    const { setValue } = useFormContext<QuoteType>()

    const handleOnRemove = (index: number) => {
        const updatedExpenses = expenses ? [...expenses] : []
        const removedExpense = updatedExpenses.splice(index, 1)[0]

        setExpenses(updatedExpenses)
        setValue('expense', updatedExpenses)

        if (quoteContent && quoteContent.subTotal && removedExpense?.total) {
            const subtotalAfterRemove = quoteContent.subTotal - removedExpense.total
            setQuoteContent({ ...quoteContent, subTotal: subtotalAfterRemove })
        }
    }
    const formatDate = (dateString: string) => {
        const date = new Date(dateString)
        if (isNaN(date.getTime())) {
            return ''
        } else {
            return date.toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
            })
        }
    }

    const totalExpenses =
        expenses && expenses?.length > 0
            ? expenses.reduce((total, expense) => total + (expense.total || 0), 0)
            : 0
    return (
        <>
            <div className='border border-[#EBEBEB] p-6 my-8'>
                <div className='flex flex-row justify-between w-full mb-6'>
                    <CardTitle className='text-[18px]'>Expenses</CardTitle>
                    <ExpenseDialog />
                </div>
                {!expenses || expenses?.length == 0 ? (
                    <div>All additional fees will show up here</div>
                ) : (
                    <Table className='table-auto whitespace-normal mx-auto'>
                        <ExpenseTableHead />
                        <TableBody className='bg-white'>
                            {expenses?.map((data, index) => (
                                <TableRow className='whitespace-nowrap'>
                                    <TableCell className=' text-[#191A0A] text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                        {data.accountName}
                                    </TableCell>
                                    <TableCell className=' text-[#191A0A] text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                        {data.description}
                                    </TableCell>
                                    <TableCell className=' text-[#191A0A] text-base max-w-[200px] text-ellipsis overflow-hidden'>
                                        {formatDate(data.date)}
                                    </TableCell>
                                    <TableCell className=' text-[#191A0A] text-base max-w-[200px] text-ellipsis overflow-hidden'>
                                        ${data.total}
                                    </TableCell>
                                    <TableCell className=' text-[#191A0A] text-base w-10'>
                                        <button type='button' onClick={() => handleOnRemove(index)}>
                                            <CiTrash className='size-7 text-zentive-red-dark' />
                                        </button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </div>
            <hr className='border-b border-dashed border-zentive-gray-medium w-full '></hr>
            <div className='flex mt-4'>
                <label className='font-bold text-zentive-gray-medium'>Total of Expenses</label>
                <p className='ml-auto text-zentive-green-dark'>${totalExpenses.toFixed(2)}</p>
            </div>
        </>
    )
}

export default Expense
