import BreadCrumbs from '@/components/BreadCrumbs'
import { InvoiceReportFilter } from './InvoiceReportFilter'
import InvoiceReportTable from './InvoiceReportTable'

export const InvoiceReport = () => {
    return (
        <div className='flex flex-col w-full h-full px-[70px] pt-[50px] gap-5'>
            <BreadCrumbs titleName='Invoice' />
            <InvoiceReportFilter />
            <InvoiceReportTable />
        </div>
    )
}
