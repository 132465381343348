import { Card } from '@/components/Card'
import { QuoteType } from '@/api/quoting/schema'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import ViewQuotingContent from './CardContent'
import ViewQuotingHeader from './CardHeader'
import { getQuote } from '@/api/quoting'
import BreadCrumbs from '@/components/BreadCrumbs'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'

const ViewQuote = () => {
    const planParam = new URLSearchParams(window.location.search)
    const quoteId = planParam.get('quoteId')

    const { data: quote } = useQuery<unknown, AxiosError, QuoteType>({
        queryKey: ['quote'],
        queryFn: () => getQuote(quoteId as string),
    })

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName={quote?.name} />
            {/* TODO pagination */}
            <div className='mt-10'>
                <Card className='border-0 border-t-8 border-zentive-green-dark '>
                    <ViewQuotingHeader quote={quote!} />
                    <ViewQuotingContent quote={quote!} />
                </Card>
            </div>
        </div>
    )
}

export default ViewQuote
