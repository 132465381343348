import { Card, CardContent, CardHeader } from '@/components/Card'
import { TableRow, TableCell, Table, TableBody } from '@/components/Table'
import ExpenseDialog from './ExpenseDialog'
import ExpenseTableHead from './ExpenseTable/ExpenseHeader'
import { useAtomValue } from 'jotai'
import { Trash2Icon } from 'lucide-react'
import { ExpenseType } from '@/api/quoting/schema'
import { expenseAtomToNewJobAtom } from '@/store/job'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

const Expense = () => {
    const { setValue, watch } = useFormContext()
    const expensesToNewJob = useAtomValue(expenseAtomToNewJobAtom)

    const expenses: ExpenseType[] = watch('quote.expense')

    const handleExpenseRemoval = (index: number) =>
        setValue(
            'quote.expense',
            expenses.filter((_, x) => x !== index),
        )

    useEffect(() => {
        if (expensesToNewJob && expensesToNewJob?.length >= 1) {
            setValue('quote.expense', expensesToNewJob)
        }
    }, [expensesToNewJob])

    return (
        <Card className='rounded-none'>
            <CardHeader className='bg-zentive-bg-100 h-[55px] flex flex-row items-center justify-between w-full'>
                <p className='font-semibold text-zentive-green-dark'>
                    Expenses
                </p>
                <ExpenseDialog />
            </CardHeader>

            <CardContent>
                {expenses?.length ? (
                    <Table className='table-auto whitespace-normal mx-auto'>
                        <ExpenseTableHead />
                        <TableBody className='bg-white'>
                            {expenses?.map((data, index) => (
                                <TableRow className='whitespace-nowrap'>
                                    <TableCell className=' text-[#191A0A] text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                        {data.accountName}
                                    </TableCell>
                                    <TableCell className=' text-[#191A0A] text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                        {data.description}
                                    </TableCell>
                                    <TableCell className=' text-[#191A0A] text-base max-w-[200px] text-ellipsis overflow-hidden'>
                                        {data.date}
                                    </TableCell>
                                    <TableCell className=' text-[#191A0A] text-base max-w-[200px] text-ellipsis overflow-hidden'>
                                        ${data.total.toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        })}
                                    </TableCell>
                                    <TableCell className=' text-[#191A0A] text-base max-w-[200px] text-ellipsis overflow-hidden text-right  px-0'>
                                        <button
                                            className=''
                                            type='button'
                                            onClick={() => handleExpenseRemoval(index)}
                                        >
                                            <Trash2Icon className='text-zentive-red-dark' />
                                        </button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <div className='pt-[24px]'>All additional fees will show up here</div>
                )}
            </CardContent>
        </Card>
    )
}

export default Expense
