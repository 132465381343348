import { toExportSubsAtom } from '@/store/manageSubs'
import { useAtomValue } from 'jotai'
import { pdfGenerator } from '@/utils/pdf'
import { convertImageToBase64 } from '@/utils/helper'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import dayjs from 'dayjs'

export type ExportDataToPDFType = {
    businessName: string
    subscriberName: string
    subscription: string
    email: string
    address: string
    phoneNumber: string
    createdAt: string
}

const ExportPDF = () => {
    const subsData = useAtomValue(toExportSubsAtom)

    // Early return if content has 0 OR null data from /subscription api
    if (subsData === null || !subsData?.length) return null

    const handleExportToPDF = async () => {
        convertImageToBase64(zentiveLogo)
            .then((zentiveLogoUrl) => {
                const tableData: ExportDataToPDFType[] = subsData?.map((data) => ({
                    businessName: data?.business.businessName,
                    subscriberName: (data?.firstName ?? '') + ' ' + (data?.lastName ?? ''),
                    subscription: `$${(data.business.subscriptionType?.price ?? 0).toFixed(2)}`,
                    email: data?.email,
                    phoneNumber: data?.phoneNumber,
                    address: data?.address.streetAddress,
                    createdAt: dayjs(data?.createdAt).format('MMMM DD, YYYY hh:mm A'),
                })) as ExportDataToPDFType[]

                pdfGenerator(zentiveLogoUrl, tableData)
            })
            .catch((err) => console.error('Error converting image to Base64:', err.message))
    }

    return (
        <button
            className='w-[135px] h-[34px] text-gray-800 hover:bg-[#00000029] block px-4 py-2 text-base rounded-t-md'
            onClick={handleExportToPDF}
        >
            Export as PDF
        </button>
    )
}
export default ExportPDF
