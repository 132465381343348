import { InvoiceProfileType, InvoiceType, InvoiceListType } from '@/api/invoicing/schema'
import { CustomerProfileType, FreeTrialType } from '@/api/profile/schema'
import { ExpenseType, QuoteContentType, QuoteListType } from '@/api/quoting/schema'
import { atom } from 'jotai'
import { DEFAULT_QUOTE_TAX_VALUE, DEFAULT_QUOTE_VALUE } from '@/constants'
import { TaxesParams } from '@/pages/private/FinancialManagement/Quoting/AddQuoting/CardContent/BillingAmount/AddTaxDialog'

export const quoteAtom = atom<QuoteListType | null>(null)
export const quoteListAtom = atom<QuoteListType | null>(null)
export const quoteTaxAtom = atom<TaxesParams>(DEFAULT_QUOTE_TAX_VALUE)
export const invoiceTaxAtom = atom<TaxesParams>(DEFAULT_QUOTE_TAX_VALUE)
export const invoiceAtom = atom<InvoiceType[] | null>([])
export const invoiceListAtom = atom<InvoiceListType | null>(null)
export const expenseAtom = atom<ExpenseType[] | []>([])
export const jobExpensesAtom = atom<ExpenseType[] | null>([])
export const invoiceUserAtom = atom<InvoiceType | null>(null)
export const customerQuoteAtom = atom<CustomerProfileType | null>(null)
export const customerInvoiceAtom = atom<InvoiceProfileType | null>(null)
export const isRestrictedAtom = atom<boolean>(false)
export const isFreeTrialEndedAtom = atom<boolean>(false)
export const isSetupLaterAtom = atom<boolean>(false)
export const freeTrialAtom = atom<FreeTrialType | null>(null)
export const quoteContentAtom = atom<QuoteContentType>(DEFAULT_QUOTE_VALUE)
// TO DO: replace invoiceContentAtom with quoteContentAtom with onMount callback
export const invoiceContentAtom = atom<QuoteContentType>(DEFAULT_QUOTE_VALUE)


customerInvoiceAtom.onMount = (set) => () => set(null)
customerQuoteAtom.onMount = (set) => () => set(null)
expenseAtom.onMount = (set) => () => set([])
isSetupLaterAtom.onMount = (set) => () => set(false)
isRestrictedAtom.onMount = (set) => () => set(false)
isFreeTrialEndedAtom.onMount = (set) => () => set(false)