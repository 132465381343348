import { RemoveJobImagesParams, removeJobImages } from '@/api/job'
import { ReadJobType } from '@/api/job/schema'
import { Button } from '@/components/Button'
import { Card, CardContent, CardFooter } from '@/components/Card'
import { useToast } from '@/hooks/useToast'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { MdClose } from 'react-icons/md'

interface IJobImages {
    job: ReadJobType
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const EditJobPhotos = ({ job, setIsOpen }: IJobImages) => {
    const { toast } = useToast()
    const [toDeletePhotoIds, setToDeletePhotoIds] = useState<string[]>([])
    const queryClient = useQueryClient()
    const handleSetToDeletePhoto = (jobImageId: string) => {
        setToDeletePhotoIds((prev) => [...prev, jobImageId])
    }

    const { mutate: removeJobImagesMu } = useMutation({
        mutationFn: (data: RemoveJobImagesParams) => removeJobImages(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['jobById'] })
            toast({
                description: 'Successfully updated!',
                variant: 'default',
            })
            setIsOpen(false)
        },
        onError: () =>
            toast({
                description: 'Unable to update job images.',
                variant: 'destructive'
            }),
    })

    const filteredImages = job?.jobImages?.filter(
        (img) => !toDeletePhotoIds.includes(img.jobImageId),
    )

    return (
        <Card className='rounded-none bg-transparent border border-zentive-green-dark bg-white'>
            <CardContent className='pb-6 flex flex-col pt-4'>
                <div className={`flex flex-row ${job?.jobImages?.length ? 'gap-x-[16px]' : ''}`}>
                    {filteredImages?.length
                        ? filteredImages?.map((img) => (
                              <div key={img.jobImageId} className='relative flex flex-row'>
                                  <img
                                      className='h-[80px] w-[82px]'
                                      src={img.jobImageUrl}
                                      alt='Job'
                                  />
                                  <Button
                                      type='button'
                                      variant={'ghost'}
                                      className='relative flex flex-row p-0'
                                      onClick={() => handleSetToDeletePhoto(img.jobImageId)}
                                  >
                                      <MdClose className='h-5 w-5 absolute top-0 right-0 p-0 text-white bg-zentive-gray-medium' />
                                  </Button>
                              </div>
                          ))
                        : 'No images uploaded.'}
                </div>
            </CardContent>
            <CardFooter className='lg:flex lg:justify-end rounded-b-md bg-[#EBEBEB] '>
                <div className='flex space-x-4 mt-[16px]'>
                    <Button
                        type='button'
                        variant={'outline'}
                        className='w-97 mr-3 text-zentive-green-dark font-semibold'
                        onClick={() => {
                            setToDeletePhotoIds([])
                            setIsOpen(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className='w-97 mr-5'
                        disabled={toDeletePhotoIds?.length <= 0}
                        onClick={() => removeJobImagesMu({ jobImageIds: toDeletePhotoIds })}
                    >
                        Update
                    </Button>
                </div>
            </CardFooter>
        </Card>
    )
}

export default EditJobPhotos
