import { updateProfileStatus } from '@/api/business'
import {
    BusinessStatusType as ProfileStatusType,
    updateBusinessStatusSchema,
} from '@/api/business/schema'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/Card'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/Forms'
import { RadioGroup, RadioGroupItem } from '@/components/RadioGroup'
import { USER_STATUS } from '@/constants'
import { useToast } from '@/hooks/useToast'
import { profileStatusAtom } from '@/store/customer'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaInfoCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import ActivateModal from './Modals/Activate'
import InactivateModal from './Modals/Inactivate'
import Tooltip from '@/components/Tooltip'

const Settings = () => {
    const navigate = useNavigate()
    const { toast } = useToast()

    const [isInactiveModalOpen, setIsInactiveModalOpen] = useState<boolean>(false)
    const [isActivateModalOpen, setIsActivateModalOpen] = useState<boolean>(false)
    const [profileStatus] = useAtom(profileStatusAtom)

    const statusMethods = useForm<ProfileStatusType>({
        mode: 'onSubmit',
        defaultValues: {
            businessId: profileStatus?.businessId,
            profileId: profileStatus?.profileId,
            status: profileStatus?.status,
        },
        resolver: zodResolver(updateBusinessStatusSchema),
    })

    const { handleSubmit, reset } = statusMethods

    const { mutate: updateProfileStatusMu } = useMutation({
        mutationFn: (data: ProfileStatusType) => updateProfileStatus(data),
        onSuccess: () => {
            toast({
                description: 'Customer status has been successfully updated',
                variant: 'default',
            })
            navigate('/customers/customer-list')
        },
        onError: (err) => console.log('Setting of status error:', err),
    })

    const onInactivate = () => {
        if (profileStatus?.status !== USER_STATUS.INACTIVE) {
            setIsInactiveModalOpen(true)
        } else return
    }

    const onActivate = () => {
        if (profileStatus?.status !== USER_STATUS.ACTIVATED) {
            setIsActivateModalOpen(true)
        } else return
    }

    const onSubmit = (data: ProfileStatusType) => {
        updateProfileStatusMu(data)
    }

    return (
        <Form {...statusMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Card>
                    <CardContent className='pt-9 pb-9'>
                        <Card className='w-full bg-gray-100'>
                            <CardHeader>
                                <CardTitle> Account Status </CardTitle>
                                <CardDescription>
                                    Indicates whether the user's subscription is currently active or
                                    terminated.
                                </CardDescription>
                            </CardHeader>
                            <CardContent>
                                <FormField
                                    control={statusMethods.control}
                                    name='status'
                                    render={({ field }) => (
                                        <FormItem className='space-y-3'>
                                            <FormControl>
                                                <RadioGroup
                                                    onValueChange={field.onChange}
                                                    defaultValue={field.value}
                                                    className='flex flex-col space-y-1'
                                                >
                                                    <FormItem className='flex items-center space-x-3 space-y-0'>
                                                        <FormControl>
                                                            <RadioGroupItem
                                                                onClick={onActivate}
                                                                value='ACT'
                                                                className='text-zentive-green-dark'
                                                                checked={
                                                                    profileStatus?.status === 'ACT'
                                                                }
                                                            />
                                                        </FormControl>
                                                        <FormLabel className='text-sm flex'>
                                                            <label className='mr-2'>Activate</label>
                                                            <Tooltip
                                                                position='bottom'
                                                                alignment='start'
                                                                width='64'
                                                                fontstyle='font-sans'
                                                                content='The customer is able to receive current services and purchase services.'
                                                            >
                                                                <FaInfoCircle className='mt-1 text-zentive-gray-medium' />
                                                            </Tooltip>
                                                        </FormLabel>
                                                    </FormItem>
                                                    <FormItem className='flex items-center space-x-3 space-y-0'>
                                                        <FormControl>
                                                            <RadioGroupItem
                                                                onClick={onInactivate}
                                                                value='INA'
                                                                className='text-zentive-green-dark'
                                                                checked={
                                                                    profileStatus?.status === 'INA'
                                                                }
                                                            />
                                                        </FormControl>
                                                        <FormLabel className='text-sm flex'>
                                                            <label className='mr-2'>
                                                                Inactivate
                                                            </label>
                                                            <Tooltip
                                                                position='bottom'
                                                                alignment='start'
                                                                width='64'
                                                                fontstyle='font-sans'
                                                                content='The customer will not be able to receive current services and purchase services.'
                                                            >
                                                                <FaInfoCircle className='mt-1 text-zentive-gray-medium' />
                                                            </Tooltip>
                                                        </FormLabel>
                                                    </FormItem>
                                                </RadioGroup>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </CardContent>
                        </Card>
                    </CardContent>
                </Card>
                <InactivateModal
                    open={isInactiveModalOpen}
                    setOpen={setIsInactiveModalOpen}
                    formValues={statusMethods.getValues()}
                    valueResetter={reset}
                />
                <ActivateModal
                    open={isActivateModalOpen}
                    setOpen={setIsActivateModalOpen}
                    formValues={statusMethods.getValues()}
                    valueResetter={reset}
                />
            </form>
        </Form>
    )
}
export default Settings
