import { REQUIRED } from '@/constants'
import z from 'zod'

export type DealStage = {
    dealStageId: string
    label: string
}

export const dealStageSchema = z.object({
    businessId: z.string().min(1, { message: `Business ID ${REQUIRED}` }),
    dealStageId: z.string().optional(),
    label: z.string().min(1, { message: `Deal Stage Label ${REQUIRED}` }),
})

export const editDealStageSchema = dealStageSchema
    .merge(
        z.object({
            leadId: z.string().min(1, { message: `Lead ID ${REQUIRED}` }),
        }),
    )
    .omit({
        businessId: true,
        label: true,
    })

export type CreateOrUpdateDealStage = z.infer<typeof dealStageSchema>
export type EditLeadDealStage = z.infer<typeof editDealStageSchema>
