// import { Link } from 'react-router-dom'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import { Link, useLocation } from 'react-router-dom'

const PublicHeader = () => {
    const { navigateTo } = useAppNavigate()
    const location = useLocation()
    const currentPage = location.pathname
    return (
        <header className='h-[75px] px-[36px] py-[16px] flex items-center justify-between bg-white shadow-md sticky top-0 z-50 '>
            <img
                onClick={() => {
                    navigateTo('/sign-in')
                }}
                src={zentiveLogo}
                alt='Zentive Logo'
                className='h-[44px] w-[125px] cursor-pointer'
            />
            {currentPage === '/sign-up' && (
                <Link
                    className='text-lg text-zentive-green-dark bg-transparent hover:text-zentive-blue-dark hover:underline font-semibold py-2 px-4 rounded'
                    to='/sign-in'
                >
                    Sign In
                </Link>
            )}
        </header>
    )
}

export default PublicHeader
