import { checkSignUpValidity, signUp, subscribeOwnerToMailChimp } from '@/api/auth'
import { SignupValidityType, SignUpType, SignUpValidityRequest } from '@/api/auth/schema'
import { Button } from '@/components/Button'
import { isPlanSelected } from '@/store/auth'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { encryptString } from '@/utils/crypto'
import { parsePhoneNumber } from 'react-phone-number-input'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const ActionButton = () => {
    const [isChecked, setIsChecked] = useState(false)
    const navigate = useNavigate()
    const isPlanError = useAtomValue(isPlanSelected)
    const {
        handleSubmit,
        setError,
        watch,
        formState: { dirtyFields, isValid },
    } = useFormContext<SignUpType>()

    const formValues = watch()

    const requiredFieldsDirty =
        dirtyFields.email &&
        dirtyFields.data?.businessName &&
        dirtyFields.data?.firstName &&
        dirtyFields.data?.lastName &&
        dirtyFields.data?.phoneNumber &&
        dirtyFields.data?.businessWebsite &&
        dirtyFields.data?.streetAddress &&
        dirtyFields.data?.city &&
        dirtyFields.data?.state &&
        dirtyFields.data?.zipCode &&
        dirtyFields.password

    const { mutate: signUpMu, isPending: isUpdateTierLoading } = useMutation({
        mutationFn: (data: SignUpType) => signUp(data),
        onSuccess: (data) => {
            const userEmail = data?.data?.user?.email

            try {
                subscribeOwnerToMailChimp({
                    email_address: data.data.user?.email as string,
                    status: 'subscribed',
                    merge_fields: {
                        LNAME: data.data.user?.user_metadata?.lastName,
                        FNAME: data.data.user?.user_metadata?.firstName,
                        ADDRESS:
                            data?.data?.user?.user_metadata?.streetAddress +
                            ', ' +
                            data?.data?.user?.user_metadata?.streetTwoAddress +
                            ', ' +
                            data?.data?.user?.user_metadata?.city +
                            ', ' +
                            data?.data?.user?.user_metadata?.state +
                            ', ' +
                            data?.data?.user?.user_metadata?.zipCode,
                        PHONE: data?.data?.user?.user_metadata?.phoneNumber,
                    },
                })
            } catch (err) {
                console.log(err)
            }

            if (userEmail) {
                const encryptedEmail = encryptString(userEmail)
                navigate(`/sign-up/verify?email=${encryptedEmail}`)
            }
        },
        onError: (err: AxiosError) => {
            return console.log(err)
        },
    })

    const { mutate: CheckValidityMu, isPending: isCheckValidityLoading } = useMutation({
        mutationFn: (data: SignUpValidityRequest) => checkSignUpValidity(data),
        onSuccess: (data: SignupValidityType) => {
            const { isEmailExist, isPhoneNumberExist } = data
            const phoneNumber = formValues.data?.phoneNumber
            const phoneNumberInstance = parsePhoneNumber(phoneNumber)

            if (isEmailExist) {
                setError('email', { message: 'Email address already exists.' })
            }

            if (isPhoneNumberExist) {
                setError('data.phoneNumber', { message: 'Phone number already exists.' })
            }

            if (!phoneNumberInstance || !phoneNumberInstance.isValid()) {
                setError('data.phoneNumber', { message: 'Invalid phone number.' })
            }

            if (!isEmailExist && !isPhoneNumberExist) {
                signUpMu(formValues)
            }
        },
        onError: (err: AxiosError) => {
            return console.log(err)
        },
    })

    const onSubmit = (data: SignUpType) => {
        CheckValidityMu({ email: data.email, phoneNumber: data.data.phoneNumber })
    }

    return (
        <div>
            <div className='bg-zentive-green-light p-6 mt-6 rounded-md'>
                <label>
                    <input
                        type='checkbox'
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                        className='mr-2 bg-green-300'
                    />
                    By checking the box, you agree to our
                    <Link
                        className='text-blue-500 underline'
                        to='/service-agreement'
                        target='_blank'
                    >
                        {' '}
                        Service Agreement{' '}
                    </Link>
                    and have read and acknowledge the
                    <Link className='text-blue-500 underline' to='/privacy-policy' target='_blank'>
                        {' '}
                        Privacy Policy
                    </Link>
                </label>
            </div>
            <div className='flex gap-x-4 w-full mt-4'>
                <Button
                    className='w-full'
                    type='button'
                    variant={'outline'}
                    onClick={() => navigate(-1)}
                >
                    Back
                </Button>
                <Button
                    className='w-full'
                    disabled={
                        !!requiredFieldsDirty ||
                        !formValues.data?.employeeCount ||
                        isCheckValidityLoading ||
                        isUpdateTierLoading ||
                        isPlanError ||
                        !isValid ||
                        !isChecked
                    }
                    type='submit'
                    onClick={handleSubmit(onSubmit)}
                >
                    Next
                </Button>
            </div>
        </div>
    )
}

export default ActionButton
