import BreadCrumbs from '@/components/BreadCrumbs'
import { NetGrossTabs } from './NetGrossTabs'
import { useAtomValue } from 'jotai'
import { netGrosstabsATom } from '@/store/reports'
import { NET_GROSS_TABS } from '@/constants'
import GrossTable from './GrossTable'
import NetTable from './NetTable'
import { GrossNetFilter } from './GrossNetFilter'

export const GrossAndNetIncome = () => {
    const tab = useAtomValue(netGrosstabsATom)

    return (
        <div className='flex flex-col w-full h-full px-[70px] pt-[50px] gap-5'>
            <BreadCrumbs titleName='Gross Net Income' />
            <GrossNetFilter />
            <NetGrossTabs />
            {tab === NET_GROSS_TABS.gross ? <GrossTable /> : <NetTable />}
        </div>
    )
}
