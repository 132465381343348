import { Button } from '@/components/Button'
import { useNavigate } from 'react-router-dom'
import { useAtomValue } from 'jotai'
import { businessIdAtom, userAtom } from '@/store/auth'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getBillingInfo } from '@/api/profile'
import stripeCC from '@/assets/public/Edited-CC.png'
import poweredByStripe from '@/assets/public/Powered-by-Stripe.png'
import { getInvoiceTotalOwed, payOwedInvoice } from '@/api/customer'
import { InvoiceOwedType } from '@/api/customer/schema'
import UpdateCardDetails from './UpdateCardDetails'
import { BillingInfoType } from '@/api/profile/schema'
import { Card, CardContent } from '@/components/Card'
import { toast } from '@/hooks/useToast'

const InvoicePayment = () => {
    const navigate = useNavigate()
    const user = useAtomValue(userAtom)

    const queryClient = useQueryClient()

    const businessId = useAtomValue(businessIdAtom)

    const { data: billingInfoData } = useQuery<unknown, AxiosError, BillingInfoType>({
        queryKey: ['billingInfo'],
        queryFn: () => getBillingInfo(user?.profileId as string),
        enabled: !!user?.profileId,
    })

    const { data: invoiceOwed } = useQuery<unknown, AxiosError, InvoiceOwedType>({
        queryKey: ['invoiceOwed', businessId],
        queryFn: () => getInvoiceTotalOwed(user?.profileId as string, businessId),
    })

    const payMutation = useMutation<unknown, AxiosError, InvoiceOwedType>({
        mutationKey: ['payInvoice'],
        mutationFn: (payInvoice) => payOwedInvoice(payInvoice.customerID as string, businessId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['invoiceOwed'] })

            toast({
                description: 'Successfully Paid',
                variant: 'default',
            })
            queryClient.invalidateQueries({ queryKey: ['invoiceOwed'] })
        },
        onSettled: () => {
            navigate('/customer-portal/payments')
        },
        onError: () => {
            toast({
                description: 'Insufficient Funds',
                variant: 'default',
            })
        },
    })

    return (
        <>
            <div className='mt-[45px] ml-[75px] text-2xl text-zentive-gray-medium font-semibold'>
                Pay Now
            </div>
            {/* TODO: Breadcrumbs */}
            <div className='ml-[75px] mb-[-40px] text-sm text-zentive-gray-medium'>
                Dashboard &gt; Payments &gt; Pay Now
            </div>
            <Card className='m-[75px]'>
                <CardContent className='p-4'>
                    <div className='flex flex-col items-center w-full pt-[36px]'>
                        <img
                            src={stripeCC}
                            alt='Stripe CC Logo'
                            className='h-[29px] w-[241px] mb-[5px] '
                        />
                        <img
                            src={poweredByStripe}
                            alt='Powered by Stripe Logo'
                            className='h-[21.93px] w-[96.76px]'
                        />
                    </div>
                    <div className='flex flex-col items-center w-full mt-6 text-2xl'>
                        $
                        {(invoiceOwed?.amount == null
                            ? 0
                            : invoiceOwed?.amount.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                              })
                        ).toLocaleString()}
                    </div>
                    <div className='w-3/5 mx-auto pt-[40px]'>
                        <UpdateCardDetails billingInfoData={billingInfoData ?? undefined} />
                    </div>
                </CardContent>
                <CardContent className='flex justify-end bg-[#EBEBEB] p-4'>
                    <div className='my-auto'>
                        <Button
                            onClick={() => navigate(-1)}
                            className='w-97 mr-3'
                            variant={'outline'}
                            disabled={payMutation.isPending}
                        >
                            Back
                        </Button>
                        <Button
                            onClick={() => {
                                payMutation.mutate({
                                    profileID: invoiceOwed?.profileID!,
                                    customerID: invoiceOwed?.customerID!,
                                    amount: invoiceOwed?.amount!,
                                    description: 'multiple',
                                })
                            }}
                            className='w-97 mr-5'
                            disabled={payMutation.isPending}
                        >
                            Proceed
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </>
    )
}

export default InvoicePayment
