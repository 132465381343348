import {
    ExpenseReportExportType,
    ExpenseReportFilterType,
    ExpenseReportTabsType,
    InvoiceReportExportType,
    InvoiceReportFilterType,
    JobCimpletionFilterType,
    JobCompletionExportType,
    JobsCompletedByEmployeeExportType,
    JobsCompletedByEmployeeFilterType,
    NetGrossExportType,
    NetGrossFilterType,
    NetGrossTabsType,
    QuoteReportExportType,
    QuoteReportFilterType,
    RevenueReportExportType,
    RevenueReportFilterType,
} from '@/api/reports/schema'
import { EXPENSE_REPORT_TABS, NET_GROSS_TABS } from '@/constants'
import { atom } from 'jotai'

export const netGrosstabsATom = atom<NetGrossTabsType>(NET_GROSS_TABS.gross)
export const netGrossFilterAtom = atom<NetGrossFilterType | null>(null)
export const netGrossExportAtom = atom<NetGrossExportType | null>(null)

export const jobCompletionExportAtom = atom<JobCompletionExportType | null>(null)
export const jobCompletionFilterAtom = atom<JobCimpletionFilterType | null>(null)

export const jobCompletedByEmployeeFilterAtom = atom<JobsCompletedByEmployeeFilterType | null>(null)
export const jobCompletedByEmployeeExportAtom = atom<JobsCompletedByEmployeeExportType | null>(null)

export const invoiceReportFilterAtom = atom<InvoiceReportFilterType | null>(null)
export const invoiceReportExportAtom = atom<InvoiceReportExportType | null>(null)

export const expenseReportFilterAtom = atom<ExpenseReportFilterType | null>(null)
export const expenseReportTabsAtom = atom<ExpenseReportTabsType>(EXPENSE_REPORT_TABS.internal)
export const expenseReportExportAtom = atom<ExpenseReportExportType | null>(null)

export const quoteReportFilterAtom = atom<QuoteReportFilterType | null>(null)
export const quoteReportExportAtom = atom<QuoteReportExportType | null>(null)

export const revenueReportExportAtom = atom<RevenueReportExportType | null>(null)
export const revenueReportFilterAtom = atom<RevenueReportFilterType | null>(null)
