import { Button } from '@/components/Button'
import { LiaToolsSolid } from 'react-icons/lia'
import { HiOutlineMap } from 'react-icons/hi2'
import { GoGear } from 'react-icons/go'
import { useLocation, useNavigate } from 'react-router-dom'
import { cn } from '@/utils/helper'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import { crewInterfaceFilterAtom } from '@/store/crew'

const CrewBottomNav = () => {
    const { t } = useTranslation(['navbar'])
    const navigate = useNavigate()
    const isDateFilterOpen = useAtomValue(crewInterfaceFilterAtom)
    const { pathname } = useLocation()
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const currentUser = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const handleActiveColor = (currentPath: string) => {
        if (pathname === currentPath) {
            return 'text-zentive-green-dark'
        } else {
            return 'text-gray-700'
        }
    }
  
    return (
        <div
            className={cn(
                'w-full max-w-[1024px] fixed bottom-0 bg-gray-500 z-20',
                isDateFilterOpen ? 'hidden' : 'block',
            )}
        >
            <nav
                className='flex flex-1 justify-evenly items-center border-t border-gray-300 w-full h-16 md:h-16 bg-white px-1 sm:px-4'
                aria-label='Tab Navigation - List of 4 tabs'
            >
                <Button
                    className='flex flex-col items-center justify-center flex-1 outline-none hover:bg-transparent'
                    onClick={() => navigate('/crew/jobs')}
                    variant='ghost'
                >
                    <div
                        className={cn(
                            'flex flex-col items-center',
                            handleActiveColor('/crew/jobs'),
                        )}
                    >
                        <LiaToolsSolid className='w-7 h-7 items-center' />
                        <p className='font-semibold'>{t('Jobs')}</p>
                    </div>
                </Button>
                <Button
                    className='flex flex-col items-center justify-center flex-1 outline-none hover:bg-transparent'
                    onClick={() => navigate('/crew/route')}
                    variant='ghost'
                >
                    <div
                        className={cn(
                            'flex flex-col items-center',
                            handleActiveColor('/crew/route'),
                        )}
                    >
                        <HiOutlineMap className='w-7 h-7' />
                        <p className='font-semibold'>{t('Route')}</p>
                    </div>
                </Button>
                <Button
                    className='flex flex-col items-center justify-center flex-1 outline-none hover:bg-transparent'
                    onClick={() => navigate('/crew/settings')}
                    variant='ghost'
                >
                    <div
                        className={cn(
                            'flex flex-col items-center',
                            handleActiveColor('/crew/settings'),
                        )}
                    >
                        <GoGear className='w-7 h-7' />
                        <p className='font-semibold'>{t('Settings')}</p>
                    </div>
                </Button>
                <Button
                    className='flex flex-col items-center justify-center flex-1 outline-none relative hover:bg-transparent'
                    onClick={() => navigate('/crew/profile')}
                    variant='ghost'
                >
                    <div
                        className={cn(
                            'flex flex-col items-center',
                            handleActiveColor('/crew/profile'),
                        )}
                    >
                        {currentUser?.profileImage ? (
                            <img
                                src={
                                    typeof currentUser?.profileImage === 'string'
                                        ? currentUser?.profileImage
                                        : ''
                                }
                                alt={`Profile image`}
                                className='relative flex h-8 w-8 shrink-0 overflow-hidden rounded-full'
                            />
                        ) : (
                            <div className='h-[26px] w-[26px] rounded-full bg-gray-300 flex justify-center items-center mr-1'>
                                <span className='text-[#191A0A] text-base'>
                                    {`${currentUser?.firstName?.charAt(0)?.toUpperCase() ?? ''}${
                                        currentUser?.lastName?.charAt(0)?.toUpperCase() ?? ''
                                    }`}
                                </span>
                            </div>
                        )}
                        <p className='font-semibold'>{t('Profile')}</p>
                    </div>
                </Button>
            </nav>
        </div>
    )
}

export default CrewBottomNav
