import mailChompLogo from '@/assets/private/mail-chimp-logo.svg'
import { MailChimpIntegrationType } from '@/api/profile/schema'

interface LinkedMailChimpCredential {
    mcCreds: MailChimpIntegrationType
}

const LinkedMailChimpCredential: React.FC<LinkedMailChimpCredential> = ({ mcCreds }) => {
    return (
        <div className='bg-white h-full flex flex-col items-center justify-center gap-10 p-10'>
            <img src={mailChompLogo} />
            <h1 className='font-bold text-zentive-green-dark text-2xl'>
                MailChimp Account is Linked
            </h1>

            <div className='w-full font-medium text-zentive-gray-dark'>
                <p className='mb-3'>Account Information</p>
                <div className='flex flex-col gap-5'>
                    <div className='flex flex-col border border-zentive-gray-light h-[5rem] w-full p-4'>
                        <h1 className='text-zentive-gray-dark'>API key</h1>
                        <p className='text-zentive-gray-medium text-base'>{mcCreds.apiKey}</p>
                    </div>
                    <div className='flex flex-col border border-zentive-gray-light h-[5rem] w-full p-4'>
                        <h1 className='text-zentive-gray-dark'>List ID</h1>
                        <p className='text-zentive-gray-medium text-base'>{mcCreds.listId}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LinkedMailChimpCredential
