//@ts-nocheck
import i18n, { InitOptions } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export const i18nConf: InitOptions = {
    supportedLngs: ['en', 'es'],
    returnNull: false,
    backend: {
        loadPath: '/locales/{{ns}}/{{lng}}.json',
    },
    // we init with resources
    fallbackLng: 'en',
    // disabled in production
    debug: false,
    // can have multiple namespaces, in case you want to divide a huge
    // translation into smaller pieces and load them on demand
    ns: [
        'navbar',
        'jobs',
        'sign-in',
        'forgot-password',
        'reset-password',
        'common',
        'profile',
        'view-job',
        'work-history',
    ],

    interpolation: {
        escapeValue: false,
        formatSeparator: ',',
    },
    react: {
        nsMode: 'default',
    },
}

i18n.use(Backend).use(LanguageDetector).use(initReactI18next)

export default i18n
