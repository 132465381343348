import { customerQuoteAtom } from '@/store/owner'
import { useAtomValue } from 'jotai'
import ClientDialog from '../ClientModal'

const HeaderClientDetails = () => {
    const customer = useAtomValue(customerQuoteAtom)

    return (
        <div className='mx-5 mt-6'>
            <div className='text-[#0C3D3C] font-bold text-[18px] flex flex-row mb-4'>
                {customer?.firstName} {customer?.lastName}
                {customer ? <ClientDialog isEditing={true} /> : <ClientDialog isEditing={false} />}
            </div>
            <div className='flex flex-row'>
                <div className='flex flex-col mr-16'>
                    <div className='flex flex-row pt-6 font-bold mb-4'>
                        <span className='font-bold text-[16px]'>Property Address</span>
                    </div>
                    <p className='text-zentive-gray-medium'>{customer?.address.streetAddress}</p>
                    <p className='text-zentive-gray-medium'>
                        {customer?.address.city}, {customer?.address.state}{' '}
                        {customer?.address.zipCode}
                    </p>
                </div>
                <div className='flex flex-col mb-8'>
                    <span className=' pt-6 font-bold mb-4 text-[16px]'>Contact Details</span>
                    <p className='text-zentive-gray-medium'>{customer?.phoneNumber}</p>
                    <p className='text-zentive-gray-medium'>{customer?.email}</p>
                </div>
            </div>
        </div>
    )
}

export default HeaderClientDetails
