import { useMutation } from '@tanstack/react-query'
import { GetJobsByCrewMemberIdParams, getJobsByCrewMemberId } from '@/api/job'
import BackButtonTopBar from '../../Common/BackButtonTopBar'
import { CreateJobType, JobListType } from '@/api/job/schema'
import { useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai'
import { timezoneAtom, userAtom } from '@/store/auth'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezonePlugin from 'dayjs/plugin/timezone'
import Spinner from '@/components/Spinner'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import emptyHistoryPlaceholder from '@/assets/private/empty-work history.png'
import { JOB_STATUS, SPANISH_FORMAT, US_FORMAT } from '@/constants'
import { formatToUTCWithOffsetIos } from '@/utils/time'
import { TimeZoneResult } from '@/hooks/useGeocode'

dayjs.extend(utc)
dayjs.extend(timezonePlugin)

const WorkHistory = () => {
    const { t, i18n } = useTranslation(['common'])
    const navigate = useNavigate()
    const user = useAtomValue(userAtom)
    const [jobs, setJobs] = useState<CreateJobType[] | null>(null)
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult

    const { mutate: getJobsByEmployeeMu } = useMutation<
        JobListType,
        AxiosError,
        GetJobsByCrewMemberIdParams
    >({
        mutationFn: (data) => getJobsByCrewMemberId(data),
        onSuccess: (data) => {
            setJobs(data?.content)
        },
    })

    useEffect(() => {
        getJobsByEmployeeMu({
            businessId: user?.businessId ?? '',
            profileId: user?.profileId ?? '',
            crewMemberId: user?.crewMemberId ?? '',
            keyword: '',
            status: JOB_STATUS.COMPLETED,
            startDate: '',
            endDate: '',
        })
    }, [])

    const formatDateTime = (dateTime: string, timezone: string, format: string) => {
        try {
            return formatToUTCWithOffsetIos(dateTime, timezone, format)
        } catch (error) {
            console.error('Error formatting date:', error)
            return 'Invalid Date'
        }
    }

    if (!jobs)
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-white'>
                <Spinner variant='normal' size='10' />
            </div>
        )

    return (
        <div className='flex flex-col space-y-3'>
            <BackButtonTopBar title={t('Work History')} />
            {jobs?.map((job, index) => (
                <div
                    key={index}
                    className='w-10/12 flex flex-col mx-auto bg-white rounded-md shadow-md border border-zentive-blue-dark px-3 py-4'
                    role='button'
                    onClick={() => navigate(`/crew/profile/history/${job?.jobId}`)}
                >
                    <div className='flex flex-row justify-between items-center'>
                        <p className='text-sm truncate pr-10'>
                            {job?.quote?.productServiceGroup
                                ?.map((service) => service?.name ?? '')
                                .filter(Boolean)
                                .join(', ')}
                        </p>
                        <p className='text-sm text--zentive-blue-dark'>
                            $
                            {job?.quote?.laborCost?.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            })}
                        </p>
                    </div>
                    <div className='flex flex-row justify-between items-center'>
                        <p className='text-sm text-zentive-gray-medium'>
                            {job?.quote?.profile?.firstName + ' ' + job?.quote?.profile?.lastName}
                        </p>
                        <p className='text-sm text-zentive-gray-medium'>
                            {formatDateTime(
                                job?.appointment?.startDateTime,
                                timezone.timeZoneId,
                                i18n.language === 'en' ? US_FORMAT : SPANISH_FORMAT,
                            )}
                        </p>
                    </div>
                </div>
            ))}

            {jobs?.length === 0 && (
                <div className=' px-4 m-4'>
                    <div className='flex justify-center bg-white rounded-sm'>
                        <img
                            src={emptyHistoryPlaceholder}
                            alt='empty-table-placeholder'
                            className='my-16 h-44'
                        />
                    </div>
                    <p className='pb-4 text-center text-2xl text-white font-semibold mt-4'>
                        {' '}
                        {t('No Work History to Display')}{' '}
                    </p>
                    <p className='pb-4 text-center text-white'>
                        {' '}
                        {t(`You don't have any working history to review.`)}{' '}
                    </p>
                </div>
            )}
        </div>
    )
}

export default WorkHistory
