import jigsawIcon from '@/assets/public/jigsawIcon.png'
import { PAYMENT_BTN_STYLE } from '@/constants'
import { cn } from '@/utils/helper'
import { Button } from '@/components/Button'
import { useStripeConnect } from '@/hooks/useStripeConnect'
import useViewedAsUser from '@/hooks/useViewedAsUser'

const StripeAccount = () => {
    const user = useViewedAsUser()

    const { isPending, handleConnect } = useStripeConnect(user?.profileId)

    return (
        <section>
            <div className='bg-white pt-5 pb-7 px-5'>
                <div className='flex flex-col mx-auto mt-10'>
                    <img
                        src={jigsawIcon}
                        alt='Jigsaw Icon'
                        className='w-[25%] m-auto h-auto max-w-md mb-10'
                    />
                    <span className='m-auto text-2xl text-zentive-green-dark font-bold tracking-wide mb-4'>
                        No Linked Stripe Account
                    </span>
                    <span className='m-auto text-base tracking-wide mb-10'>
                        To link your Stripe account, please click 'Set up Stripe Account' button
                        below.
                    </span>
                    <Button
                        className={cn(
                            PAYMENT_BTN_STYLE,
                            'w-[243.5px] m-auto mb-10 bg-zentive-green-dark hover:bg-zentive-green-medium',
                        )}
                        disabled={isPending}
                        onClick={handleConnect}
                        type='button'
                    >
                        Set Up Stripe Account
                    </Button>
                </div>
            </div>
        </section>
    )
}

export default StripeAccount
