import { signOut } from '@/api/auth'
import completedStripe from '@/assets/public/owner_stripe_complete.png'
import restrictedStripe from '@/assets/public/restricted-stripe-account.svg'
import { Button } from '@/components/Button'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import { paymentStepAtom } from '@/store/payment'
import { useSetAtom } from 'jotai'
// import { useEffect } from 'react'
import { RESET } from 'jotai/utils'
import { useQuery } from '@tanstack/react-query'
import goTrueClient from '@/api/goTrueClient'
import { useStripeConnect } from '@/hooks/useStripeConnect'
import { PAYMENT_BTN_STYLE, SKLTN_PAYMENT_BTN_STYLE } from '@/constants'
import { cn } from '@/utils/helper'
import { getStripeConnectStatus } from '@/api/subscription'
import { Skeleton } from '@/components/Skeleton'

const CompleteAccountCreation = () => {
    const { navigateTo } = useAppNavigate()

    const setPaymentStep = useSetAtom(paymentStepAtom)

    const { data: authSession } = useQuery({
        queryKey: ['session'],
        queryFn: () => goTrueClient.getSession(),
        enabled: true,
    })

    const id = authSession?.data.session?.user.id

    const checkStripeConnectStatus = useQuery({
        queryKey: ['stripe-connect-status', id],
        queryFn: () => getStripeConnectStatus(id),
        enabled: !!id,
    })

    const { handleConnect, isPending } = useStripeConnect(id)

    const isStatusCheckLoading = checkStripeConnectStatus.isLoading
    const message = checkStripeConnectStatus?.data?.message
    const status = checkStripeConnectStatus?.data?.status

    return (
        <div>
            <div className='flex justify-center'>
                {isStatusCheckLoading && !status && !message && (
                    <Skeleton className='h-[133px] w-[133px] bg-zentive-gray-light' />
                )}

                {status && (
                    <img
                        src={status === 'restricted' ? restrictedStripe : completedStripe}
                        alt={`${status} account Png`}
                        className={
                            status === 'restricted' ? `h-[233px] w-[233px]` : `h-[133px] w-[133px]`
                        }
                    />
                )}
            </div>

            <div className='text-zentive-green-dark font-semibold text-center text-[24px] mt-[56.65px] capitalize'>
                {/* Success! */}
                {status || <Skeleton className='h-[24px] bg-zentive-gray-light w-full' />}
            </div>

            <div className='text-[16px] text-center mt-[16px]'>
                {/* You have successfully created a Stripe account. */}
                {message || <Skeleton className='h-[16px] bg-zentive-gray-light w-full' />}
            </div>

            <div className='flex justify-center'>
                {isStatusCheckLoading && !status && !message && (
                    <Skeleton
                        className={cn(
                            SKLTN_PAYMENT_BTN_STYLE,
                            'w-[250px] mt-[56.65px] bg-zentive-gray-light',
                        )}
                    />
                )}

                {status === 'completed' && (
                    <Button
                        onClick={() => {
                            navigateTo('/sign-in')
                            setPaymentStep(RESET)
                            signOut()
                        }}
                        className='w-[250px] mt-[56.65px]'
                    >
                        Login to Zentive
                    </Button>
                )}

                {status === 'restricted' && (
                    <Button
                        className={cn(PAYMENT_BTN_STYLE, 'w-[250px]  mt-[56.65px]')}
                        disabled={isPending}
                        onClick={handleConnect}
                        type='button'
                    >
                        Set Up Stripe Account
                    </Button>
                )}
            </div>
        </div>
    )
}

export default CompleteAccountCreation
