import 'react-datepicker/dist/react-datepicker.css'
import Map from './Map'
import { useAtom } from 'jotai'
import { FC } from 'react'
import GoogleMapsApiWrapper from '@/components/GoogleMapsApiWrapper'
import { isLandmarkShownAtom } from '@/store/route'
import { Switch } from '@/components/Switch'

type MapViewProps = {
    isLoaded: boolean
    loadError?: Error
}

const RouteMap: FC<MapViewProps> = ({ isLoaded, loadError }) => {
    if (!isLoaded || loadError) {
        return null
    }
    return (
        <div className='w-full h-full flex flex-col items-center'>
            <div className='w-full h-full min-h-[540px] rounded-[8px] border border-zentive-gray-medium'>
                <div className='h-full w-full flex flex-col items-center'>
                    <div className='w-full h-full min-h-[540px] rounded-[8px] border border-zentive-gray-medium'>
                        <Map />
                    </div>
                </div>
            </div>
        </div>
    )
}

const WrappedRoutingMapView = () => {
    const [isLandmarkShown, setIsLandmarkShown] = useAtom(isLandmarkShownAtom)

    return (
        <div className=' flex flex-col w-full  '>
            <div className='flex flex-col h-full w-full'>
                <div className='w-full flex flex-row justify-end py-3 mb-3 rounded-md bg-white pr-4'>
                    <span className='mr-4 h-full'>Show landmarks in the map</span>
                    <Switch
                        className='data-[state=checked]:bg-zentive-green-dark '
                        onCheckedChange={(event) => setIsLandmarkShown(event)}
                        checked={isLandmarkShown}
                    />
                </div>
                <GoogleMapsApiWrapper
                    render={(isLoaded: boolean, loadError?: Error) => (
                        <RouteMap isLoaded={isLoaded} loadError={loadError} />
                    )}
                />
            </div>
        </div>
    )
}

export default WrappedRoutingMapView
