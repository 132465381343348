import { Tabs, TabsList, TabsTrigger } from '@/components/Tabs'
import { EXPENSE_REPORT_TABS } from '@/constants'
import { expenseReportTabsAtom, expenseReportExportAtom } from '@/store/reports'
import { useSetAtom } from 'jotai'

export const ExpenseReportTabs = () => {
    const setTabValue = useSetAtom(expenseReportTabsAtom)

    const setExportAtom = useSetAtom(expenseReportExportAtom)

    const handleTabChange = (val: string) => {
        setTabValue(val)
        setExportAtom(null)
    }

    return (
        <Tabs
            defaultValue={EXPENSE_REPORT_TABS.internal}
            onValueChange={(val) => handleTabChange(val)}
            className='rounded-b-none'
        >
            <TabsList className='w-full flex flex-row space-x-0 rounded-b-none p-0'>
                <TabsTrigger
                    onClick={() => handleTabChange(EXPENSE_REPORT_TABS.internal)}
                    value={EXPENSE_REPORT_TABS.internal}
                    className={
                        'w-1/2 h-[54px] text-[18px] text-zentive-gray-medium data-[state=active]:text-zentive-blue-dark data-[state=active]:border data-[state=active]:border-zentive-blue-dark data-[state=active]:border-b-2 data-[state=active]:border-x-0 data-[state=active]:border-t-0 data-[state=active]:font-bold data-[state=active]:border-b-4 bg-white sm:truncate ...'
                    }
                >
                    Internal
                </TabsTrigger>
                <TabsTrigger
                    onClick={() => handleTabChange(EXPENSE_REPORT_TABS.external)}
                    value={EXPENSE_REPORT_TABS.external}
                    className={
                        'w-1/2 h-[54px] text-[18px] text-zentive-gray-medium data-[state=active]:text-zentive-blue-dark data-[state=active]:border data-[state=active]:border-zentive-blue-dark data-[state=active]:border-b-2 data-[state=active]:border-x-0 data-[state=active]:border-t-0 data-[state=active]:font-bold data-[state=active]:border-b-4 bg-white sm:truncate ...'
                    }
                >
                    External
                </TabsTrigger>
            </TabsList>
        </Tabs>
    )
}
