import z from 'zod'
import { profileDetails } from '../profile/schema'
import { LEAD_CONTACT_TYPES, LEAD_SOURCES } from '@/constants'
import { phoneNumberValidator } from '@/utils/regex'
import { addressSchema } from '../address/schema'
import { emailSchema } from '../auth/schema'
import { PaginationType } from '@/components/Pagination/schema'

const contactTypeSchema = z.enum(Object.values(LEAD_CONTACT_TYPES) as [string, ...string[]])
const sourceSchema = z.enum(Object.values(LEAD_SOURCES) as [string, ...string[]])

export const leadDetailsSchema = profileDetails
    .omit({ phoneNumber: true })
    .merge(
        z.object({
            address: addressSchema.partial(),
            businessId: z.string().min(1),
            businessName: z.string().min(1),
            contactNumber: phoneNumberValidator,
            contactType: contactTypeSchema,
            dealSize: z.number().min(1, { message: 'Deal Size must be a value greater than 0' }),
            dealStageId: z.string().min(1, { message: 'Deal Stage must be applied' }),
            productServiceTypeIds: z
                .array(z.string())
                .nonempty({ message: 'At least one product/service type must be selected' }),
            source: sourceSchema,
            status: z.string(),
        }),
    )
    .merge(emailSchema)

export type LeadDetailsRequest = z.infer<typeof leadDetailsSchema>

export type SortCriterion = {
    column: 'deal_size' | 'customer_name' | 'deal_stage' | 'created_at'
    order: 'ASC' | 'DESC'
}

export type Lead = {
    leadId: string
    firstName: string
    lastName: string
    email: string
    businessName: string
    contactNumber: string
    contactType: string
    dealSize: number
    label: string
    productServiceNames: Array<string>
    source: string
    status?: string
    streetAddress?: string
    streetTwoAddress?: string
    city?: string
    state?: string
    zipCode?: string
    createdAt: string
    updatedAt: string
}

export type GetLeadsResponse = {
    content: Lead[]
    meta: PaginationType
}
