import { getExpenseAccountsByBusinessId } from '@/api/expense_account'
import { InvoiceType } from '@/api/invoicing/schema'
import { Button } from '@/components/Button'
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    DialogPortal,
    DialogTitle,
    DialogTrigger,
} from '@/components/Dialog'
import { Input } from '@/components/Input'
import { Label } from '@/components/Label'
import { Textarea } from '@/components/TextArea'
import { DEFAULT_EXPENSE_VALUE } from '@/constants'
import { jobGenerateInvoiceAtom } from '@/store/job'
import { expenseAtom, invoiceContentAtom } from '@/store/owner'
import { cn, hasMoreThanTwoDecimalPlaces } from '@/utils/helper'
import { useQuery } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'
import { ChangeEvent, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import emptyExpensesPlaceholder from '@/assets/private/empty-expenses.png'
import { useNavigate } from 'react-router-dom'
import { isNoSelectedServicesInvoicingAtom } from '@/store/financial'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { format } from 'date-fns'
import useViewedAsUser from '@/hooks/useViewedAsUser'

const ExpenseDialog = () => {
    const [invoiceContent, setInvoiceContent] = useAtom(invoiceContentAtom)
    const generateInvoiceData = useAtomValue(jobGenerateInvoiceAtom)
    const [expenses, setExpenses] = useAtom(expenseAtom)
    const isNoSelectedServices = useAtomValue(isNoSelectedServicesInvoicingAtom) as boolean
    const [expense, setExpense] = useState(() => DEFAULT_EXPENSE_VALUE)
    const { setValue } = useFormContext<InvoiceType>()
    const navigateTo = useNavigate()

    const user = useViewedAsUser()
    const { businessId = '' } = user

    // add isSuccess / isLoading indicator
    const { data } = useQuery({
        enabled: !!businessId,
        queryKey: ['expenseAccounts', businessId],
        queryFn: () => getExpenseAccountsByBusinessId('', businessId),
        select: (res) => res?.content,
    })

    const expenseAccounts = data ?? []

    const handleOnSave = () => {
        setInvoiceContent((prevVal) => ({
            ...prevVal,
            totalExpense: prevVal.totalExpense + expense.total,
        }))
        setExpenses([...expenses!, expense!])
        setValue('expense', [...expenses!, expense!])
        setInvoiceContent((prevVal) => ({ ...prevVal, subTotal: prevVal.subTotal + expense.total }))
    }

    const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const expenseAccountName = event.target.value

        setExpense((prevState) => ({
            ...prevState,
            accountName: expenseAccountName,
        }))
    }

    const onCancel = () => setExpense(DEFAULT_EXPENSE_VALUE)

    useEffect(() => {
        console.log('HELLO')
        setExpense(DEFAULT_EXPENSE_VALUE)
    }, [expenses])

    useEffect(() => {
        if (
            generateInvoiceData &&
            generateInvoiceData.expense &&
            generateInvoiceData.laborCost &&
            generateInvoiceData.subTotal
        ) {
            setExpenses(generateInvoiceData.expense)
            setInvoiceContent((prevVal) => ({
                ...prevVal,
                totalExpense: prevVal.totalExpense + expense.total,
            }))
            setInvoiceContent((prevVal) => ({
                ...prevVal,
                subTotal:
                    invoiceContent.laborCost +
                    invoiceContent.totalExpense +
                    invoiceContent.totalUnitPrice,
            }))
            setValue('expense', [...expenses!, expense!])
        }
    }, [
        generateInvoiceData,
        setValue,
        invoiceContent.totalUnitPrice,
        invoiceContent.totalExpense,
        invoiceContent.laborCost,
        invoiceContent.subTotal,
    ])

    const [selectDate, setSelectDate] = useState<Date | null>(null)
    const [isFocused, setIsFocused] = useState<boolean>(false)
    const [isTotalFocused, setIsTotalFocused] = useState<boolean>(false)
    const [isDescriptionFocused, setIsDescriptionFocused] = useState<boolean>(false)
    const [isNameExpenseFocused, setIsNameExpenseFocused] = useState<boolean>(false)

    const handleDateChange = (date: Date | null) => {
        setSelectDate(date)
        setExpense((prevState) => ({
            ...prevState,
            date: date ? format(date, 'MM/dd/yyyy') : '',
        }))
    }

    return (
        <Dialog>
            <DialogTrigger asChild>
                <button
                    disabled={isNoSelectedServices}
                    type='button'
                    className={`${
                        !isNoSelectedServices
                            ? 'text-zentive-green-dark'
                            : 'text-zentive-gray-medium cursor-not-allowed'
                    } font-semibold`}
                >
                    + Add Expense
                </button>
            </DialogTrigger>
            <DialogPortal>
                <DialogOverlay>
                    <DialogContent className='max-w-[650px] w-[650px] max-h-[650px] h-[600px] rounded-md '>
                        <DialogHeader className='m-6'>
                            <DialogTitle className='mb-2'>
                                <div className='text-[20px] font-semibold text-left mb-6'>
                                    Add Expense Account
                                </div>
                            </DialogTitle>
                            <DialogDescription className='max-h-[350px] no-scrollbar'>
                                <div className='relative z-10'>
                                    {!expenseAccounts?.length && expenseAccounts?.length === 0 ? (
                                        <div className='px-4 mx-4'>
                                            <div className='flex justify-center bg-white rounded-md'>
                                                <img
                                                    src={emptyExpensesPlaceholder}
                                                    alt='empty-table-placeholder'
                                                    className='mt-8 h-36'
                                                />
                                            </div>
                                            <p className='pb-4 text-center text-2xl text-zentive-green-dark font-semibold'>
                                                No Expense Yet
                                            </p>
                                            <p className='pb-4 text-center text-zentive-black text-base'>
                                                You have no expenses to display at the moment.
                                            </p>
                                            <Button
                                                className='mt-2 py-6 px-10 font-medium text-xl'
                                                onClick={() =>
                                                    navigateTo('/settings/expense-accounts/add')
                                                }
                                            >
                                                Add Expense
                                            </Button>
                                        </div>
                                    ) : (
                                        <>
                                            <select
                                                name='row'
                                                onFocus={() => setIsNameExpenseFocused(true)}
                                                onBlur={() => setIsNameExpenseFocused(false)}
                                                onChange={handleOnChange}
                                                className='w-full mb-4 flex justify-center items-center font-sans text-base h-11 border border-zentive-gray-medium disabled:bg-gray-100 text-gray-900 focus:border-zentive-green-dark'
                                            >
                                                <option
                                                    key={DEFAULT_EXPENSE_VALUE.accountName}
                                                    value={DEFAULT_EXPENSE_VALUE.accountName}
                                                >
                                                    Select Expense Account
                                                </option>
                                                {expenseAccounts?.map((expenseAccount, index) => (
                                                    <option
                                                        key={index}
                                                        value={expenseAccount.expenseAccountName}
                                                        className='text-lg'
                                                    >
                                                        {expenseAccount.expenseAccountName}
                                                    </option>
                                                ))}
                                            </select>
                                            <Label
                                                className={cn(
                                                    'floating-label bg-white absolute left-1 top-2 -translate-y-1/90 text-base duration-100 ease-linear peer-focus:-translate-y-4 peer-focus:text-sm peer-focus:px-1',
                                                    isNameExpenseFocused
                                                        ? 'text-zentive-green-dark peer-disabled:opacity-100'
                                                        : 'text-zentive-gray-medium',

                                                    expense.accountName !== '' ||
                                                        expenseAccounts?.length >= 0
                                                        ? 'peer-focus:text-zentive-green-dark pointer-events-auto -translate-y-4 text-sm px-1'
                                                        : 'peer-focus:text-zentive-green-dark text-zentive-gray-medium pointer-events-none',
                                                )}
                                            >
                                                {isNameExpenseFocused
                                                    ? 'Account Name'
                                                    : 'Name of Expense'}
                                            </Label>
                                            <Textarea
                                                className='flex w-full mb-4 pt-3 max-h-[200px] font-sans text-base h-32 border border-black disabled:bg-gray-100 text-gray-900'
                                                placeholder={
                                                    isDescriptionFocused
                                                        ? 'Description'
                                                        : 'Add Description'
                                                }
                                                onFocus={() => setIsDescriptionFocused(true)}
                                                onBlur={() => setIsDescriptionFocused(false)}
                                                value={expense.description}
                                                onChange={(val) =>
                                                    setExpense((prevState) => ({
                                                        ...prevState,
                                                        description: val.target.value,
                                                    }))
                                                }
                                            />
                                            <div className='relative z-10'>
                                                <DatePicker
                                                    className='peer flex w-full pr-96 -mb-2 rounded-sm cursor-pointer 
                                            font-sans text-base h-11 disabled:bg-gray-100 text-gray-900 
                                            bg-transparent px-3 shadow-sm transition-colors focus:outline-none
                                            border focus:border-zentive-green-dark border-zentive-gray-medium'
                                                    selected={selectDate}
                                                    onChange={(date) => handleDateChange(date)}
                                                    placeholderText='Select'
                                                    minDate={new Date()}
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    onFocus={() => setIsFocused(true)}
                                                    onBlur={() => setIsFocused(false)}
                                                />
                                                <Label
                                                    className={cn(
                                                        'floating-label bg-white absolute left-3 bottom-6 -translate-y-1/90 text-base duration-100 ease-linear',
                                                        isFocused
                                                            ? 'text-zentive-green-dark pointer-events-auto text-sm px-1 -translate-y-4 bottom-3'
                                                            : 'text-zentive-gray-medium',
                                                    )}
                                                >
                                                    {isFocused ? 'Date' : 'Date Expense Incurred'}
                                                </Label>
                                            </div>
                                            <div className='relative mt-8'>
                                                <Input
                                                    className='w-full mb-4 justify-center items-center font-sans text-base h-11 border border-black disabled:bg-gray-100 text-gray-900 focus:text-left'
                                                    placeholder=''
                                                    name='total'
                                                    type='number'
                                                    value={expense.total > 0 ? expense.total : ''}
                                                    onFocus={() => setIsTotalFocused(true)}
                                                    onBlur={() => setIsTotalFocused(false)}
                                                    onChange={(e) => {
                                                        const inputValue: string = e.target.value

                                                        if (hasMoreThanTwoDecimalPlaces(inputValue))
                                                            return

                                                        if (
                                                            inputValue === '' ||
                                                            !isNaN(Number(inputValue))
                                                        ) {
                                                            setExpense((prevState) => ({
                                                                ...prevState,
                                                                total:
                                                                    inputValue === ''
                                                                        ? 0
                                                                        : parseFloat(inputValue),
                                                            }))
                                                        }
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (
                                                            e.key === '-' ||
                                                            e.key === '+' ||
                                                            e.key === 'e' ||
                                                            e.key === 'E'
                                                        ) {
                                                            e.preventDefault()
                                                        }
                                                    }}
                                                />
                                                <span
                                                    className={cn(
                                                        `absolute text-base left-2 top-1/2 transform -translate-y-1/2 translate-x-[30rem] ${
                                                            isTotalFocused
                                                                ? 'translate-x-[0.3rem]'
                                                                : ''
                                                        }`,
                                                    )}
                                                >
                                                    {expense.total === 0 ? '$0.00' : ''}
                                                </span>
                                                <Label
                                                    className={cn(
                                                        'floating-label bg-white absolute left-3 bottom-8 -translate-y-1/90 text-base duration-100 ease-linear',
                                                        isTotalFocused
                                                            ? 'text-zentive-green-dark pointer-events-auto text-sm px-1 -translate-y-4 bottom-5'
                                                            : 'text-zentive-gray-medium',
                                                    )}
                                                >
                                                    {isTotalFocused
                                                        ? 'Total'
                                                        : 'Total Expense Amount'}
                                                </Label>
                                            </div>
                                            <Input
                                                className='w-full mb-4 flex justify-center items-center font-sans text-base h-11 border border-black disabled:bg-gray-100 text-gray-900 text-right focus:text-left'
                                                placeholder='Total Expense Amount $'
                                                name='total'
                                                type='number'
                                                value={expense.total}
                                                onChange={(e) => {
                                                    let inputValue: string = e.target.value

                                                    if (hasMoreThanTwoDecimalPlaces(inputValue))
                                                        return

                                                    setExpense((prevState) => ({
                                                        ...prevState,
                                                        total: parseFloat(inputValue),
                                                    }))
                                                }}
                                                onKeyDown={(e) => {
                                                    if (
                                                        e.key === '-' ||
                                                        e.key === '+' ||
                                                        e.key === 'e' ||
                                                        e.key === 'E'
                                                    ) {
                                                        e.preventDefault()
                                                    }
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                            </DialogDescription>
                        </DialogHeader>
                        <DialogFooter className='absolute self-end py-4 px-6 w-full rounded-b-md items-end bg-zentive-gray-light'>
                            <div className='flex flex-row w-[50%] justify-end'>
                                <DialogClose asChild>
                                    <Button
                                        type='button'
                                        variant={'outline'}
                                        className='w-[50%] mr-4'
                                        onClick={onCancel}
                                    >
                                        Cancel
                                    </Button>
                                </DialogClose>

                                <DialogClose asChild>
                                    <Button
                                        type='submit'
                                        className={`${
                                            !expenseAccounts?.length &&
                                            expenseAccounts?.length === 0
                                                ? 'hidden'
                                                : ''
                                        } w-[50%]`}
                                        onClick={() => handleOnSave()}
                                        disabled={
                                            expense.accountName == '' ||
                                            expense.description == '' ||
                                            expense.date == '' ||
                                            expense.total == 0
                                                ? true
                                                : false
                                        }
                                    >
                                        Save
                                    </Button>
                                </DialogClose>
                            </div>
                        </DialogFooter>
                    </DialogContent>
                </DialogOverlay>
            </DialogPortal>
        </Dialog>
    )
}

export default ExpenseDialog
