import GrossNetRevenueChart from './GrossNetRevenueChart'
import { JobsChart } from './JobsChart'
import BreadCrumbs from '@/components/BreadCrumbs'
import QuoteChart from './QuoteChart'
import InvoiceChart from './InvoiceChart'
import ExpenseChart from './ExpenseChart'

const OwnerDashboard = () => {
    return (
        <div className='h-full'>
            <BreadCrumbs titleName='Dashboard' />
            <div className='flex flex-col gap-10'>
                <div className='mt-10'>
                    <JobsChart />
                </div>
                <div className='h-full flex flex-col justify-center items-center'>
                    <GrossNetRevenueChart />
                </div>
                <div className='grid grid-cols-3 gap-10 justify-between items-start w-full'>
                    <div className='flex-1'>
                        <QuoteChart />
                    </div>
                    <div className='flex-1'>
                        <InvoiceChart />
                    </div>
                    <div className='flex-1'>
                        <ExpenseChart />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OwnerDashboard
