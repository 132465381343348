import { FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { InputPassword } from '@/components/InputPassword'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const ConfirmNewPassword = () => {
    const { t } = useTranslation(['reset-password'])
    const { control } = useFormContext()
    return (
        <div className='flex grid-cols-2 gap-4 justify-center items-center'>
            <div className='col-span-1'>
                <FormField
                    name='newPassword'
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <InputPassword
                                    className='mt-6 pl-3 w-107'
                                    placeholder={t('New Password*')}
                                    hasConfirmPassword
                                    isReverse={true}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    name='confirmPassword'
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <InputPassword
                                    className='pl-3 w-107 mt-[20px]'
                                    placeholder={t('Confirm New Password*')}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </div>
    )
}

export default ConfirmNewPassword
