import { Form } from '@/components/Forms'
import { zodResolver } from '@hookform/resolvers/zod'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtom, useAtomValue } from 'jotai'
import { FullProfileType } from '@/api/profile/schema'
import { crewListAtom, crewMemberProfileIdsAtom, selectedCrewForeManAtom } from '@/store/employee'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { CreateCrewType, createCrewSchema } from '@/api/crew/schema'
import { createCrew, getNextCrewNumber } from '@/api/crew'
import BaseCrew from '../../BaseCrew'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@/hooks/useToast'
import BreadCrumbs from '@/components/BreadCrumbs'
import MinimumCrewMemberModal from '../../CrewModals/AddMinimumCrewMemberModal'

const CreateCrew: FC = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const [crewMemberProfileIds, setCrewMemberProfileIds] = useAtom(crewMemberProfileIdsAtom)
    const [crewListData, setCrewListData] = useAtom(crewListAtom)
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const [selectedItem, setSelectedItem] = useAtom(selectedCrewForeManAtom)
    const { toast } = useToast()
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const [isMinimumCrewMemberModalOpen, setIsMinimumCrewMemberModalOpen] = useState<boolean>(false)

    const createCrewMethods = useForm<CreateCrewType>({
        mode: 'onSubmit',
        resolver: zodResolver(createCrewSchema),
    })

    const {
        handleSubmit,
        setValue,
        formState: { isValid },
    } = createCrewMethods

    const { data: nextCrewNumber } = useQuery({
        queryKey: ['nextCrewNumber'],
        queryFn: () => getNextCrewNumber(user.businessId),
    })

    const { mutate: createCrewMu, isPending } = useMutation({
        mutationFn: createCrew,
        onError: (err: AxiosError) => console.log('Create Crew Error: ', err.message),
        onSuccess: () => {
            toast({
                description: 'Crew has been successfully added',
                variant: 'default',
            }),
                navigate('/schedule/crew')
            setCrewMemberProfileIds([])
            setCrewListData(null)
            setSelectedItem(null)
            queryClient.invalidateQueries({ queryKey: ['Notifications'] })
        },
    })

    const onSubmit = (data: CreateCrewType) => {
        if (crewListData && crewListData.content && crewListData.content?.length >= 1) {
            createCrewMu(data)
        } else {
            setIsMinimumCrewMemberModalOpen(true)
        }
    }

    useEffect(() => {
        setValue('crewNumber', nextCrewNumber)
        setValue('crewColor', '#ffffff')
        setValue('businessId', user.businessId)
        setValue('memberProfileIds', crewMemberProfileIds)
        setValue('foreManId', selectedItem?.profileId ?? '')
        setCrewListData(null)
    }, [nextCrewNumber, user])

    return (
        <Form {...createCrewMethods}>
            <BreadCrumbs titleName='Add Crew' containerClassName='mb-4' />
            <form onSubmit={handleSubmit(onSubmit)}>
                <BaseCrew mode='create' isValid={isValid} isSubmitting={isPending} />
                <MinimumCrewMemberModal
                    open={isMinimumCrewMemberModalOpen}
                    setOpen={setIsMinimumCrewMemberModalOpen}
                />
            </form>
        </Form>
    )
}

export default CreateCrew
