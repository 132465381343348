import BreadCrumbs from '@/components/BreadCrumbs'
import JobDetails from './JobDetails'
import JobLineItems from './LineItems'
import JobTimeEntry from './TimeEntry'
import JobExpenses from './Expenses'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getJobById, updateJob } from '@/api/job'
import { useNavigate, useParams } from 'react-router-dom'
import { cn, formatRecordNumber } from '@/utils/helper'
import {
    BREADCRUMBS_PADDING_STYLE,
    JOB_STATUS,
    JOB_STATUS_LABEL,
    getJobStatusClasses,
} from '@/constants'
import InternalNotesAndAttachments from './InternalNotesAndAttachments'
import MoreActionsDropDown from './MoreActionsDropdown'
import { JobStatusType, UpdateJobType, updateJobSchema } from '@/api/job/schema'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form } from '@/components/Forms'
import { Button } from '@/components/Button'

const ViewJob = () => {
    const { jobId } = useParams()
    const navigate = useNavigate()

    const updateJobMethods = useForm<UpdateJobType>({
        defaultValues: {
            description: '',
            jobImages: [],
        },
        mode: 'onChange',
        resolver: zodResolver(updateJobSchema),
    })

    const { data: job, isLoading: isJobLoading } = useQuery({
        queryKey: ['jobById', jobId],
        queryFn: () => getJobById(jobId ?? ''),
    })

    const jobsStatus = job?.status as JobStatusType

    const { handleSubmit } = updateJobMethods

    const { mutate: updateJobMu } = useMutation({
        mutationFn: (data: UpdateJobType) => updateJob(data),
    })

    const onSubmit = (data: UpdateJobType) => updateJobMu(data)

    if (!job) {
        return null
    }

    if (isJobLoading) {
        return 'Loading...'
    }

    const formattedJobNumber = `Job Order #${formatRecordNumber(job?.jobNumber ?? 0)}`
    const formattedQuoteNumber = `Quote Number #${formatRecordNumber(job?.quote.quoteNumber ?? 0)}`

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName={formattedJobNumber} pathId={job.jobId} label='View Job' />
            {job.status !== JOB_STATUS.CANCELED && (
                <div className='flex flex-col pt-6 pb-2'>
                    <div className='flex flex-row w-full'>
                        {job?.status == JOB_STATUS.RESCHEDULE ? (
                            <>
                                <div className='relative inline-block text-left ml-auto'>
                                    <Button
                                        className='h-[48px] bg-zentive-blue-dark'
                                        type='button'
                                        onClick={() =>
                                            navigate(`/schedule/jobs/reschedule/${job.jobId}`)
                                        }
                                    >
                                        Reschedule Job
                                    </Button>
                                </div>
                                <MoreActionsDropDown job={job} margin={true} />
                            </>
                        ) : (
                            <>
                                <MoreActionsDropDown job={job} />
                            </>
                        )}
                    </div>
                </div>
            )}
            <div className='bg-[#f1f1f1] p-4 border-t-8 border-t-zentive-green-dark rounded-none'>
                <div className='flex border-b border-b-gray-200 p-4 justify-between items-center'>
                    <div>
                        <h1 className='text-2xl font-bold mt-2'>{formattedJobNumber}</h1>
                        <h2 className='text-xl font-bold mt-2'>{formattedQuoteNumber}</h2>
                    </div>
                    <p
                        className={cn(
                            'text-base py-2 px-4 rounded-3xl',
                            getJobStatusClasses(job?.status as string),
                        )}
                    >
                        {job?.status && JOB_STATUS_LABEL[jobsStatus]}
                    </p>
                </div>
                <Form {...updateJobMethods}>
                    <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
                        <JobDetails job={job} />

                        <div className='bg-[#e7e7e7] px-4 py-4 mt-4 border-t-8 border-[#d6d6d6]'>
                            <JobLineItems quote={job.quote} />
                            <JobTimeEntry job={job} />
                            <JobExpenses quote={job.quote} />
                        </div>
                        <InternalNotesAndAttachments job={job} />
                    </form>
                </Form>
            </div>
        </div>
    )
}

export default ViewJob
