import { getAllCustomers } from '@/api/profile'
import { CustomerProfileListType, CustomerProfileType } from '@/api/profile/schema'
import { Button } from '@/components/Button'
import {
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/Dialog'
import SearchBar from '@/components/InputSearchBox'
import { Pagination } from '@/components/Pagination'
import { RadioGroup, RadioGroupItem } from '@/components/RadioGroup'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { paginationAtom } from '@/store/manageSubs'
import { customerQuoteAtom } from '@/store/owner'
import { useQuery } from '@tanstack/react-query'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { ChangeEvent, useState } from 'react'
import { useFormContext } from 'react-hook-form'

const SelectCustomer = () => {
    const [selectedProfileId, setSelectedProfileId] = useState<string>('')
    const [searchQuery, setSearchQuery] = useState<string>('')

    const { getValues, setValue } = useFormContext()
    const [pagination, setPagination] = useAtom(paginationAtom)

    const setCustomer = useSetAtom(customerQuoteAtom)
    const profileId = getValues('profileId')
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const [user] = useAtom(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const { data: customers } = useQuery({
        queryKey: ['customers', user?.businessId, searchQuery, pagination],
        queryFn: () =>
            getAllCustomers(user?.businessId as string, 'ACT', searchQuery, '', pagination),
        enabled: !!user?.businessId,
        select: (res: CustomerProfileListType) => res,
    })

    const handleSetProfileId = (customerId: string) => setSelectedProfileId(customerId)

    const handleSearchChange = (evt: ChangeEvent<HTMLInputElement>) =>
        setSearchQuery(evt.target.value)

    return (
        <DialogContent className='h-full max-h-[40rem] max-w-[45rem] outline-none rounded-[13px] py-[44px] px-[37px] shadow-none border-none'>
            <DialogHeader className='h-[260px] w-full'>
                <DialogTitle className='text=zentive-black text-[20.74px] text-left'>
                    Select Client
                </DialogTitle>
                <DialogDescription className='text-zentive-black text-left text-base pb-[15px]'>
                    Which client would you like to create this for?
                </DialogDescription>
                <SearchBar onSearchChange={handleSearchChange} />
                <div className='h-[20.8rem]'>
                    <div className='pt-[15px] text-left text-[16px] text-zentive-black  h-full overflow-y-auto no-scrollbar'>
                        <RadioGroup
                            onValueChange={(val) => handleSetProfileId(val)}
                            className='gap-0'
                        >
                            {customers?.content?.length
                                ? customers?.content?.map((customer) => {
                                      return (
                                          <div
                                              className='flex flex-row items-start gap-[12px] p-4 border border-zentive-gray-light'
                                              key={customer.profileId}
                                          >
                                              <RadioGroupItem 
                                                  className='mt-1'
                                                  checked={
                                                    (selectedProfileId === '' && customer.profileId === profileId) ||
                                                    selectedProfileId === customer.profileId
                                                    }
                                                  value={customer.profileId}
                                                  disabled={!customer.customerId}
                                              />
                                              <div className='w-full'>
                                                  <span className='font-bold'>
                                                      {customer.firstName} {customer.lastName}{' '}
                                                      {!customer.customerId && (
                                                          <span className='rounded-lg text-[14px] bg-zentive-red-dark m-2 p-1 text-white'>
                                                              {' '}
                                                              Unverified{' '}
                                                          </span>
                                                      )}
                                                  </span>
                                                  <div className='flex flex-row justify-between text-zentive-gray-medium'>
                                                      <div className='w-1/2'>
                                                          <p>{customer.address.streetAddress}</p>
                                                          <p>
                                                              {customer.address.city}
                                                              {customer.address.state}
                                                              {customer.address.zipCode}
                                                          </p>
                                                      </div>
                                                      <div className='w-1/2'>
                                                          <p>{customer.phoneNumber}</p>
                                                          <p>{customer.email}</p>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      )
                                  })
                                : 'No customers available'}
                        </RadioGroup>
                    </div>
                </div>
            </DialogHeader>
            <div className='mt-40'>
                {pagination.pageSize !== undefined && (
                    <Pagination
                        itemsPerPage={pagination.pageSize}
                        setPagination={setPagination}
                        totalRecords={customers?.meta.totalRecords ?? 1}
                        pagination={pagination}
                    />
                )}
            </div>
            <DialogFooter className='absolute left-0 bottom-0 h-[84px] w-full pr-[26px] bg-zentive-gray-light rounded-b-[13px] flex flex-row gap-[16px] items-center justify-end'>
                <DialogClose asChild>
                    <Button
                        className='h-[53px] w-[96px]'
                        onClick={() => setSelectedProfileId('')}
                        variant='outline'
                    >
                        Cancel
                    </Button>
                </DialogClose>
                <DialogClose asChild>
                    <Button
                        className='h-[53px] w-[100px] font-normal'
                        disabled={
                            !selectedProfileId ||
                            !customers?.content?.length ||
                            profileId === selectedProfileId
                        }
                        onClick={() => {
                            setValue('profileId', selectedProfileId as string, {
                                shouldValidate: true,
                            })

                            const selectedCustomer = customers?.content?.find(
                                (customer: CustomerProfileType) =>
                                    customer.profileId === selectedProfileId,
                            ) as CustomerProfileType
                            setValue('profile', selectedCustomer)
                            setCustomer(selectedCustomer)
                        }}
                    >
                        Save
                    </Button>
                </DialogClose>
            </DialogFooter>
        </DialogContent>
    )
}

export default SelectCustomer
