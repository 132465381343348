import CityStateZipFields from './CityStateZip'
import StreetFields from './Streets'

const LocationFields = () => {
    return (
        <>
            <StreetFields />
            <CityStateZipFields />
        </>
    )
}
export default LocationFields
