import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getJobById, updateJobStatus } from '@/api/job'
import { UpdateJobStatusType } from '@/api/job/schema'
import Spinner from '@/components/Spinner'
import { useNavigate, useParams } from 'react-router-dom'
import BackButtonTopBar from '../../../Common/BackButtonTopBar'
import { useState } from 'react'
import ConfirmationModal from '../../../Common/ConfirmationModal'
import { useTranslation } from 'react-i18next'
import { useToast } from '@/hooks/useToast'
import { JOB_STATUS } from '@/constants'

const MoreOptions = () => {
    const { t } = useTranslation(['view-job'])
    const { toast } = useToast()
    const queryClient = useQueryClient()
    const { jobId } = useParams()
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const { data: job } = useQuery({
        queryKey: ['jobById', jobId],
        queryFn: () => getJobById(jobId ?? ''),
    })

    const isJobCanceled = job?.status === JOB_STATUS.CANCELED

    const { mutate: updateJobStatusMu } = useMutation({
        mutationFn: (data: UpdateJobStatusType) => updateJobStatus(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['jobById'] })
            navigate(`/crew/job/${jobId}`)
            toast({
                description: `Job has been marked as canceled successfully`,
                variant: 'crew_interface',
                duration: 2000
            })
        },
        onError: (err) => console.log(err),
    })

    if (!job)
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-white'>
                <Spinner variant='normal' size='10' />
            </div>
        )

    return (
        <div className='flex flex-col space-y-3'>
            <BackButtonTopBar title={t('More Options')} />

            <button
                className='w-10/12 flex flex-col mx-auto bg-white rounded-md shadow-md border px-3 py-4'
                role='button'
                onClick={() => setIsOpen(true)}
                disabled={isJobCanceled}
            >
                <div className='flex flex-row justify-between items-center text-sm pr-4'>
                    {isJobCanceled ? t('Canceled') : t('Cancel Job')}
                </div>
            </button>
            <ConfirmationModal
                isOpen={isOpen}
                title={t('Are you sure you want to cancel this job?')}
                text={t('Mark as Canceled')}
                onClose={() => setIsOpen(false)}
                onProceed={() =>
                    updateJobStatusMu({ jobId: jobId ?? '', status: JOB_STATUS.CANCELED })
                }
            />
        </div>
    )
}

export default MoreOptions
