import { InvoiceListType } from '@/api/invoicing/schema'
import { TableHead, TableHeader, TableRow } from '@/components/Table'
import { Checkbox } from '@/components/Checkbox'
import { useAtom } from 'jotai'
import { invoiceSortByAtom, invoiceSortOrderAtom } from '@/store/invoice'
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6'

const tableHeader = ['Customer Name', 'Billing Date', 'Service Type', 'Job Charge', 'Status']

type InvoicingTableContentProps = {
    invoice: InvoiceListType
    setInvoiceToUpdate: React.Dispatch<
        React.SetStateAction<{
            invoiceIds: string[]
        }>
    >
    setIsCheckAll: React.Dispatch<React.SetStateAction<boolean>>
    isCheckAll: boolean
}

const InvoicingTableHead: React.FC<InvoicingTableContentProps> = ({
    invoice,
    setInvoiceToUpdate,
    isCheckAll,
}) => {
    const [sortBy, setSortBy] = useAtom(invoiceSortByAtom)
    const [sortOrder, setSortOrder] = useAtom(invoiceSortOrderAtom)

    const handleCheckAll = (checked: boolean) => {
        if (checked) {
            const updatedProfileIds = invoice?.content
                ?.map((data) => data.invoiceId)
                .filter(Boolean) as string[]

            setInvoiceToUpdate((prev) => ({
                ...prev,
                invoiceIds: [...new Set([...prev.invoiceIds, ...updatedProfileIds])],
            }))
        } else {
            const currentInvoiceIds = invoice?.content
                ?.map((data) => data.invoiceId)
                .filter(Boolean) as string[]

            setInvoiceToUpdate((prev) => ({
                ...prev,
                invoiceIds: prev.invoiceIds.filter((id) => !currentInvoiceIds.includes(id)),
            }))
        }
    }

    const onHandleSorting = (column: string) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC')
        } else {
            setSortBy(column)
            setSortOrder('ASC')
        }
    }

    const getSortIcon = (column: string) => {
        if (sortBy === column) {
            return sortOrder === 'ASC' ? (
                <FaArrowUpLong className='mt-1 w-3 h-3' />
            ) : (
                <FaArrowDownLong className='mt-1 w-3 h-3' />
            )
        }
        return <FaArrowDownLong className='mt-1 w-3 h-3' />
    }

    return (
        <TableHeader className='bg-white'>
            <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                    <Checkbox
                        className='mr-[16px] mt-[2px]'
                        onCheckedChange={handleCheckAll}
                        checked={isCheckAll}
                    />
                </TableHead>
                {tableHeader?.map((header, index) => (
                    <TableHead
                        className={`font-semibold text-zentive-gray-medium text-base ${
                            index == 3 ? 'text-right' : ''
                        }${index >= 4 ? 'text-center' : ''}`}
                        key={header}
                    >
                        {header}
                        <button onClick={() => onHandleSorting(header)} className='ml-2'>
                            {getSortIcon(header)}
                        </button>
                    </TableHead>
                ))}
            </TableRow>
        </TableHeader>
    )
}

export default InvoicingTableHead
