import SubscriptionPlans from '../OwnerChangePlan/SubscriptionPlans'
import { useAtom, useAtomValue } from 'jotai'
import { userAtom } from '@/store/auth'
import { BREADCRUMBS_PADDING_STYLE, ROLE } from '@/constants'
import { isFreeTrialEndedAtom } from '@/store/owner'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const EndTrialPlanGalery = () => {

    const [user] = useAtom(userAtom)
    const navigate = useNavigate()
    const isCustomer = user?.role.roleName === ROLE.CUSTOMER
    const isFreeTrialEnded = useAtomValue(isFreeTrialEndedAtom)

    useEffect(() => {
        if (!isFreeTrialEnded) {
            navigate('/403')
        }
    }, [isFreeTrialEnded])

    return (
        <section className={`${BREADCRUMBS_PADDING_STYLE}`}>
            {!isCustomer && (
                <div className='mx-20'>
                    <div className='flex flex-col items-center justify-center gap-6 mb-10 mx-20 text-center'>
                        <h1 className='text-zentive-green-dark font-semibold text-2xl'> Your Free Trial has Expired </h1>
                        <p> Your 14-day free trial has ended. But don't worry! You can unlock the full 
                            potential of our platform and enjoy uninterrupted access to all our amazing 
                            features by subscribing today. </p> 
                        <p> Ready to take the next step? Simply choose a plan below to continue your journey with us! </p>
                    </div>  
                    <SubscriptionPlans />
                </div>
            )}
        </section>
    )
}

export default EndTrialPlanGalery