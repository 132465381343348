import { TableHead, TableHeader, TableRow } from '@/components/Table'

const tableHeader = ['ITEMS', 'DESCRIPTION', 'DATE', 'AMOUNT']

const ExpenseTableHead = () => {
    return (
        <TableHeader className='bg-white'>
            <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                {tableHeader?.map((header, index) => (
                    <TableHead
                        key={index}
                        className='font-semibold text-zentive-gray-medium text-base'
                    >
                        {header}
                    </TableHead>
                ))}
            </TableRow>
        </TableHeader>
    )
}

export default ExpenseTableHead
