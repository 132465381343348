import { axiosInstance } from '../axiosInstance'
import {
    BulkDeleteInvoiceType,
    InvoiceCustomerListType,
    InvoiceListType,
    InvoiceType,
} from './schema'
import { PaginationType } from '@/components/Pagination/schema'

export const getNextBusinessInvoiceNumber = async (businessId: string): Promise<number> => {
    const response = await axiosInstance.get(`invoice/next-invoice-number/${businessId}`)
    return response.data.content
}

export const getAllInvoices = async (
    businessId: string,
    keyword: string,
    p: PaginationType,
    sortBy?: string,
    sortOrder?: string,
): Promise<InvoiceListType> => {
    const { page, pageSize } = p
    const response = await axiosInstance.get(
        `/invoice/list?businessId=${businessId}&keyword=${keyword}&page=${page}&pageSize=${pageSize}&sortby=${sortBy}&sortOrder=${sortOrder}`,
    )
    return response.data
}

export const getAllInvoiceCustomer = async (
    businessId: string,
    keyword: string,
    p: PaginationType,
): Promise<InvoiceCustomerListType> => {
    const response = await axiosInstance.get(
        `/invoice/customers?businessId=${businessId}&page=${p.page}&pageSize=${p.pageSize}&keyword=${keyword}`,
    )
    return response.data
}

export const saveInvoice = async (invoice: InvoiceType) => {
    const res = await axiosInstance.post(`/stripe/invoice/new`, invoice)
    return res.data
}

export const getInvoice = async (invoiceId: string) => {
    const response = await axiosInstance.get(`/invoice/${invoiceId}`)
    return response.data.content as InvoiceType
}

export const sendReimbursement = async (invoiceId: string, amount: string) => {
    const response = await axiosInstance.post(
        `/invoice/refund?invoiceId=${invoiceId}&amount=${amount}`,
    )
    return response.data.content
}

export const payInvoiceManually = async (invoiceNumber: string) => {
    const response = await axiosInstance.post(
        `/stripe/invoice/manual-payment?invoiceNumber=${invoiceNumber}`,
    )
    return response.data.content
}

export const getOwnerRefund = async (businessId: string) => {
    const response = await axiosInstance.post(`/invoice/refund/list?businessId=${businessId}`)
    return response.data.content
}

export const getCustomerRefund = async (businessId: string, profileId: string) => {
    const response = await axiosInstance.post(
        `/invoice/refund/list?businessId=${businessId}&profileId=${profileId}`,
    )
    return response.data.content
}

export const bulkDeleteInvoices = async (invoices: BulkDeleteInvoiceType) => {
    const response = await axiosInstance.post(`/invoice/bulk-delete`, invoices)
    return response.data.content
}
