import { useNavigate, useParams } from 'react-router-dom'
import BackButtonTopBar from '../../../Common/BackButtonTopBar'
import { Button } from '@/components/Button'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { NotePayloadType, noteSchema } from '@/api/notes/schema'
import { useEffect } from 'react'
import { Label } from '@radix-ui/react-label'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Textarea } from '@/components/TextArea'
import { useMutation, useQuery } from '@tanstack/react-query'
import { updateBusinessNotes } from '@/api/notes'
import dayjs from 'dayjs'
import { getJobNoteByNoteId } from '@/api/job'
import { useTranslation } from 'react-i18next'
import { useToast } from '@/hooks/useToast'
import { US_FORMAT, US_FORMAT_WITH_TIME_MERIDIEM_DAY } from '@/constants'
import Spinner from '@/components/Spinner'

const CrewJobEditNote = () => {
    const { t, i18n } = useTranslation(['view-job'])
    const { toast } = useToast()
    const navigate = useNavigate()
    const { noteId } = useParams()

    const { data: note, isLoading } = useQuery({
        queryKey: ['noteByNoteId', noteId],
        queryFn: () => getJobNoteByNoteId(noteId ?? ''),
    })

    const updateJobNotesMethods = useForm<NotePayloadType>({
        mode: 'onChange',
        resolver: zodResolver(noteSchema),
    })

    const { mutate: updateJobNotesMu, isPending: isMutationPending } = useMutation({
        mutationFn: (newNote: NotePayloadType) => updateBusinessNotes(newNote),
        onSuccess: () => {
            navigate(-1)
            toast({
                description: t('Note has been updated successfully'),
                variant: 'crew_interface',
                duration: 2000,
            })
        },
    })

    const {
        setValue,
        handleSubmit,
        control,
        formState: { isSubmitting },
        watch,
    } = updateJobNotesMethods

    const onSubmitUpdateJobNote = (data: NotePayloadType) => updateJobNotesMu(data)

    useEffect(() => {
        if (note) {
            setValue('note', note?.note)
            setValue('noteId', note?.noteId)
        }
    }, [noteId, note])

    if (isLoading) {
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-white'>
                <Spinner variant='normal' size='10' />
            </div>
        )
    }

    return (
        <div className='flex flex-col justify-between items-center'>
            <BackButtonTopBar title={t('Notes')} />
            <div className='flex flex-col w-10/12 max-w-[1024px] border border-zentive-green-dark p-3 rounded-lg bg-white mx-4'>
                <Form {...updateJobNotesMethods}>
                    <form onSubmit={handleSubmit(onSubmitUpdateJobNote)}>
                        <div className='w-full mx-auto py-3'>
                            <div>
                                <div className='p-1'>
                                    <Label className='flex justify-end text-[12px] mr-2 text-zentive-gray-medium'>
                                        {dayjs(note?.updatedAt).format(
                                            i18n.language === 'en'
                                                ? US_FORMAT + ' h:mmA'
                                                : US_FORMAT_WITH_TIME_MERIDIEM_DAY,
                                        )}
                                    </Label>
                                    <FormField
                                        control={control}
                                        name='note'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Textarea
                                                        className='border-white shadow-none text-[#191A0A] h-[350px] min-h-[350px] max-h-[400px] text-[16px]'
                                                        /* value={watvc}
                                                        onChange={(event) => {
                                                            setUpdatedNoteContent(
                                                                event.target.value,
                                                            )
                                                            setNote(event.target.value)
                                                            // singleNote.note = event.target.value <-- to delete if there's no issue
                                                        }} */
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <Button
                            variant='ghost'
                            type='submit'
                            className='absolute top-1 right-5 z-50 text-zentive-green-dark font-semibold'
                            disabled={
                                isSubmitting ||
                                watch().note === '' ||
                                note?.note === watch().note ||
                                isMutationPending
                            }
                        >
                            {t('Update')}
                        </Button>
                    </form>
                </Form>
            </div>
        </div>
    )
}

export default CrewJobEditNote
