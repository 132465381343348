import { formatToUTCWithOffset } from '@/utils/time'
import { axiosInstance } from '../axiosInstance'
import { APPOINTMENT_TYPES, ISO_8601_WITH_UTC_OFFSET } from '@/constants'
import { ValidateScheduleType } from './schema'

export const validateSchedule = async (data: ValidateScheduleType): Promise<boolean> => {
    // include FormData

    if (data.availableAnytime) {return true}

    const formData = new FormData()

    formData.append('crewId', data.crewId ?? '')

    formData.append('repeat', data.repeat as string)

    const apiUrl =
        data.appointmentType === APPOINTMENT_TYPES.ONEOFF_JOB
            ? '/appointment/check-schedule'
            : '/appointment/check-recurring-schedule'

    const start = formatToUTCWithOffset(
        data?.startDateTime,
        data?.profile?.address?.timezone as string,
        ISO_8601_WITH_UTC_OFFSET,
    )

    const end = formatToUTCWithOffset(
        data?.endDateTime,
        data?.profile?.address?.timezone as string,
        ISO_8601_WITH_UTC_OFFSET,
    )

    // Appointment fields
    formData.append('startDateTime', start)
    formData.append('endDateTime', end)
    formData.append('appointmentType', data.appointmentType)

    const res = await axiosInstance.post(apiUrl, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })

    return res.data.content
}
