import goTrueClient from '@/api/goTrueClient'
import { createStripeCustomer } from '@/api/profile'
import verifiedLogo from '@/assets/public/verified.svg'
import { Button } from '@/components/Button'
import { Card, CardHeader, CardTitle, CardContent } from '@/components/Card'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import { sessionAtom } from '@/store/auth'
import { paymentStepAtom } from '@/store/payment'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtom, useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { getPricingByID } from '@/api/subscription'

const VerificationSuccess = () => {
    const setCurrentStep = useSetAtom(paymentStepAtom)
    const [session, setSession] = useAtom(sessionAtom)
    const { navigateTo } = useAppNavigate()

    const subscriptionTypeId = session?.user?.user_metadata?.subscriptionTypeId

    const { isLoading: isLoading1 } = useQuery<string, AxiosError, unknown>({
        queryKey: ['business'],
        queryFn: () => createStripeCustomer(),
    })

    const { data: currentPlan, isLoading: isLoading2 } = useQuery({
        enabled: !!subscriptionTypeId,
        queryKey: ['currentPlan'],
        queryFn: () => getPricingByID(subscriptionTypeId),
    })

    let btnVerbiage: string = 'Loading...'
    let redirectTo: '/' | '/free-trial' | '/payment' = '/'

    if (!isLoading2 && currentPlan) {
        btnVerbiage = currentPlan.name === 'Free Trial' ? 'Proceed to Set up' : 'Proceed to Payment'
        redirectTo = currentPlan.name === 'Free Trial' ? '/free-trial' : '/payment'
    }

    useEffect(() => {
        const { data: authListener } = goTrueClient.onAuthStateChange((event, session) => {
            switch (event) {
                case 'INITIAL_SESSION':
                    if (session) {
                        return setSession(session)
                    }

                    return navigateTo('/')
            }
        })

        return () => authListener.subscription.unsubscribe()
    }, [])

    return (
        <Card className='bg-zentive-gray-bg border-none shadow-none'>
            <CardHeader>
                <div className='flex justify-center content-center w-full my-10'>
                    <img
                        src={verifiedLogo}
                        alt='Verified Logo'
                        className='w-[115.38px] h-[110.94px]'
                    />
                </div>
                <CardTitle className='text-zentive-green-dark text-center'>Verified!</CardTitle>
            </CardHeader>
            <CardContent className='text-center mb-10'>
                <div className='text-zentive-gray-medium text-center'>
                    <p>You have successfully verified your account.</p>
                </div>
                <div className='my-[44px]'>
                    <Button
                        className='w-[250px] text-white '
                        type='button'
                        onClick={() => {
                            setTimeout(() => setCurrentStep(1), 0)
                            navigateTo(redirectTo)
                        }}
                        disabled={isLoading1 || isLoading2}
                    >
                        {btnVerbiage}
                    </Button>
                </div>
            </CardContent>
        </Card>
    )
}

export default VerificationSuccess
