import { validateSchedule } from '@/api/appointment'
import { ValidateScheduleType } from '@/api/appointment/schema'
import { getJobById, rescheduleJob } from '@/api/job'
import { JobStatusType, RescheduleJobType, rescheduleJobSchema } from '@/api/job/schema'
import BreadCrumbs from '@/components/BreadCrumbs'
import { Button } from '@/components/Button'
import { Form } from '@/components/Forms'
import {
    APPOINTMENT_TYPES,
    BREADCRUMBS_PADDING_STYLE,
    JOB_STATUS_LABEL,
    getJobStatusClasses
} from '@/constants'
import { useToast } from '@/hooks/useToast'
import { AssignCrew } from '@/pages/common/Schedule/Job/AssignCrew'
import CalendarComponent from '@/pages/common/Schedule/Job/FullCalendar'
import { OneOffJobSchedule } from '@/pages/common/Schedule/Job/OneOffJobSchedule'
import RecurringJobSchedule from '@/pages/common/Schedule/Job/RecurringJobSchedule'
import { editJobAtom } from '@/store/job'
import { cn, formatRecordNumber } from '@/utils/helper'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import JobExpenses from '../ViewJob/Expenses'
import InternalNotesAndAttachments from '../ViewJob/InternalNotesAndAttachments'
import JobDetails from '../ViewJob/JobDetails'
import JobLineItems from '../ViewJob/LineItems'

const RescheduleJob = () => {
    const { jobId } = useParams()
    const queryClient = useQueryClient()
    const setIsEditing = useSetAtom(editJobAtom)
    const navigate = useNavigate()
    const { toast } = useToast()

    const {
        data: job,
        isLoading: isJobLoading,
    } = useQuery({
        queryKey: ['jobById', jobId],
        queryFn: () => getJobById(jobId ?? ''),
    })
        const updateJobMethods = useForm<RescheduleJobType>({
            defaultValues: {
                jobId: job?.jobId,
                appointment: job?.appointment?.appointmentType.includes(APPOINTMENT_TYPES.RECURRING)
                    ? {
                        ...job.appointment,
                        appointmentType: APPOINTMENT_TYPES.RECURRING,
                    }
                    : job?.appointment,
                description: job?.description,
                jobImages: [],
                crewId: job?.crew?.crewId,
            },
            mode: 'onChange',
            resolver: zodResolver(rescheduleJobSchema),

        });
        
    

    const jobsStatus = job?.status as JobStatusType

    const { setValue, watch, handleSubmit } = updateJobMethods

    const appointmentType = watch('appointment.appointmentType')
    const crewId = watch('crewId')
    const description = watch('description')
    const jobImages = watch('jobImages')
    const startDT = watch('appointment.startDateTime')
    const endDT = watch('appointment.endDateTime')
    const availableAnytime = watch('appointment.availableAnytime')
    const repeat = watch('appointment.repeat')
    

    const { mutateAsync: validateScheduleMu, data: isScheduleAvailable } = useMutation<
        boolean,
        AxiosError,
        ValidateScheduleType
    >({
        mutationFn: validateSchedule,
    })

    const { mutate: updateJobMu, isPending } = useMutation({
        mutationFn: (data: RescheduleJobType) => rescheduleJob(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['jobById'] })
            toast({
                description: 'Successfully rescheduled!',
                variant: 'default',
            })
            navigate(`/schedule/jobs/${job?.jobId}`)
        },
        onError: () =>
            toast({
                description: 'Unable to reschedule job.',
                variant: 'destructive',
            }),
    })

    useEffect(() => {
        if (crewId) {
            validateScheduleMu({
                crewId: crewId as string,
                startDateTime: startDT,
                endDateTime: endDT,
                repeat: repeat,
                appointmentType: appointmentType,
                availableAnytime: availableAnytime,
            })
        }
    }, [crewId, startDT, endDT, appointmentType])

    const onSubmit = (data: RescheduleJobType) => updateJobMu(data)

    if (!job) {
        return null
    }

    if (isJobLoading) {
        return 'Loading...'
    }

    const formattedJobNumber = `Job Order #${formatRecordNumber(job?.jobNumber ?? 0)}`

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName={formattedJobNumber} pathId={job.jobId} label='Edit Job' />
            <div className='bg-[#f1f1f1] p-4 border-t-8 border-t-zentive-green-dark rounded-none'>
                <div className='flex border-b border-b-gray-200 p-4 justify-between'>
                    <h1 className='text-2xl font-bold mt-2'>{formattedJobNumber}</h1>
                    <p
                        className={cn(
                            'text-base py-2 px-4 rounded-3xl',
                            getJobStatusClasses(job?.status as string),
                        )}
                    >
                        {job?.status && JOB_STATUS_LABEL[jobsStatus]}
                    </p>
                </div>
                <Form {...updateJobMethods}>
                <form
    onSubmit={handleSubmit(onSubmit)}
    encType='multipart/form-data'
>
                        <JobDetails job={job} />

                        <div className='bg-[#e7e7e7] px-4 py-4 mt-4 border-t-8 border-[#d6d6d6]'>
                            <section className='flex flex-row gap-5 mb-5'>
                                {appointmentType === APPOINTMENT_TYPES.ONEOFF_JOB ? (
                                    <OneOffJobSchedule />
                                ) : (
                                    <RecurringJobSchedule />
                                )}

                                <CalendarComponent />
                            </section>
                            <AssignCrew
                                schedAvailable={!!isScheduleAvailable}
                                rsdCrewId={job?.crew?.crewId}
                            />
                            <JobLineItems quote={job.quote} />
                            {/* {job?.crew ? (
                                <JobTimeEntry job={job} />
                            ) : (
                                
                            )} */}

                            <JobExpenses quote={job.quote} />
                        </div>

                        <InternalNotesAndAttachments job={job} />

                        <div className='lg:flex lg:justify-end rounded-b-md bg-[#EBEBEB] py-4 px-6'>
                            <div className='flex space-x-4'>
                                <Button
                                    type='button'
                                    variant={'outline'}
                                    className='w-97 mr-3 text-zentive-green-dark font-semibold'
                                    onClick={() => {
                                        navigate(-1)
                                        setIsEditing(false)
                                        setValue('description', '')
                                        setValue('crewId', '')
                                        setValue('jobImages', [])
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type='submit'
                                    className='w-97 mr-5'
                                    disabled={
                                        isPending ||
                                        (!crewId && !description && jobImages?.length == 0) ||
                                        !isScheduleAvailable
                                    }
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                    </form>
                </Form>
            </div>
        </div>
    )
}

export default RescheduleJob
