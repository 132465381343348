import { getConnectStatus } from '@/api/stripeConnect'
import { businessIdAtom } from '@/store/auth'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai/react'

const useStripeConnectStatus = () => {
    const businessId = useAtomValue(businessIdAtom)

    const queryData = useQuery({
        enabled: !!businessId,
        queryKey: ['isConnectedToStripe', businessId],
        queryFn: () => getConnectStatus(businessId),
    })

    return queryData
}

export default useStripeConnectStatus
