import { checkExistingLead, createLead } from '@/api/lead'
import { LeadDetailsRequest, leadDetailsSchema } from '@/api/lead/schema'
import { Dialog, DialogClose, DialogContent, DialogFooter } from '@/components/Dialog'
import { Form, FormTitle } from '@/components/Forms'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { FC, useEffect } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import ContactInformation from './ContactInformation'
import DealStage from './DealStage'
import JobAddress from './JobAddress'
import { Button } from '@/components/Button'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { useToast } from '@/hooks/useToast'

type AddLeadModalProps = {
    open: boolean
    onOpenChange: (val: boolean) => void
}

export type AddLeadFormProps = {
    useFormMethods: UseFormReturn<LeadDetailsRequest>
}

const AddLeadModal: FC<AddLeadModalProps> = ({ open, onOpenChange }) => {
    const currentUser = useViewedAsUser()
    const queryClient = useQueryClient()
    const { toast } = useToast()

    const formMethods = useForm<LeadDetailsRequest>({
        mode: 'onChange',
        resolver: zodResolver(leadDetailsSchema),
        defaultValues: {
            businessId: currentUser?.businessId,
            businessName: currentUser?.business?.businessName,
            status: 'subscribed',
            productServiceTypeIds: [],
        },
    })

    const {
        handleSubmit,
        reset,
        setError,
        formState: { isValid },
    } = formMethods

    const { mutate, isPending } = useMutation<LeadDetailsRequest, AxiosError, LeadDetailsRequest>({
        mutationFn: createLead,
        onSuccess: () => {
            toast({
                description: 'A New Lead has been added successfully.',
            })
            onOpenChange(false)
            queryClient.invalidateQueries({ queryKey: ['leadList'] })
        },
    })

    const onSubmit = async (data: LeadDetailsRequest) => {
        console.log('data', data)
        try {
            const isLeadExisting = await checkExistingLead(data.email)

            if (isLeadExisting) {
                setError('email', {
                    message: 'Email already exists!',
                })
                return
            }

            mutate(data)
        } catch (err) {
            if (err instanceof AxiosError) {
                toast({
                    variant: 'destructive',
                    description: err.message,
                })
            }
        }
    }

    useEffect(() => {
        if (!open) {
            reset()
        }
    }, [open])

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                id='add-lead'
                className='bg-white max-h-[850px] h-fit w-[688px] rounded-[15px] p-0 overflow-y-auto no-scrollbar'
            >
                <Form {...formMethods}>
                    <form className='' onSubmit={handleSubmit(onSubmit)}>
                        <div className='pt-[88px] pb-[56px] px-[36px] '>
                            <FormTitle
                                className='pb-[32px] text-zentive-black text-[18px] font-semibold'
                                headingLevel={1}
                                title='New Lead'
                            />
                            <ContactInformation isOpen={open} useFormMethods={formMethods} />
                            <DealStage isOpen={open} useFormMethods={formMethods} />
                            <JobAddress />
                            {/* <InputAddress /> */}
                        </div>
                        <DialogFooter className='bg-zentive-gray-light h-[75px] flex flex-row items-center gap-[13px] px-[25px] justify-end'>
                            <DialogClose asChild>
                                <Button variant='secondary' type='button'>
                                    Cancel
                                </Button>
                            </DialogClose>
                            <Button type='submit' disabled={isPending || isValid === false}>
                                Add New Lead
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default AddLeadModal
