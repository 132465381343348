import BreadCrumbs from '@/components/BreadCrumbs'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getJobById, updateJob } from '@/api/job'
import { useNavigate, useParams } from 'react-router-dom'
import { cn, formatRecordNumber } from '@/utils/helper'
import {
    APPOINTMENT_TYPES,
    BREADCRUMBS_PADDING_STYLE,
    JOB_STATUS_LABEL,
    getJobStatusClasses,
} from '@/constants'
import { Button } from '@/components/Button'
import { AssignCrew } from '@/pages/common/Schedule/Job/AssignCrew'
import { JobStatusType, UpdateJobType, updateJobSchema } from '@/api/job/schema'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { Form } from '@/components/Forms'
import { useToast } from '@/hooks/useToast'
import { AxiosError } from 'axios'
import { ValidateScheduleType } from '@/api/appointment/schema'
import { validateSchedule } from '@/api/appointment'
import { removeRecurringPrefix } from '@/utils/time'
import JobDetails from '../ViewJob/JobDetails'
import JobLineItems from '../ViewJob/LineItems'
import JobTimeEntry from '../ViewJob/TimeEntry'
import JobExpenses from '../ViewJob/Expenses'
import InternalNotesAndAttachments from '../ViewJob/InternalNotesAndAttachments'
import { editJobAtom } from '@/store/job'
import { useSetAtom } from 'jotai'

const EditJob = () => {
    const { jobId } = useParams()
    const queryClient = useQueryClient()
    const setIsEditing = useSetAtom(editJobAtom)
    const navigate = useNavigate()
    const { toast } = useToast()

    const updateJobMethods = useForm<UpdateJobType>({
        defaultValues: {
            description: '',
            jobImages: [],
        },
        mode: 'onChange',
        resolver: zodResolver(updateJobSchema),
    })

    const {
        data: job,
        isLoading: isJobLoading,
        isSuccess: isJobSuccess,
    } = useQuery({
        queryKey: ['jobById', jobId],
        queryFn: () => getJobById(jobId ?? ''),
    })

    const jobsStatus = job?.status as JobStatusType

    const { handleSubmit, setValue, watch } = updateJobMethods

    const crewId = watch('crewId')
    const description = watch('description')
    const jobImages = watch('jobImages')

    const { mutateAsync: validateScheduleMu, data: isScheduleAvailable } = useMutation<
        boolean,
        AxiosError,
        ValidateScheduleType
    >({
        mutationFn: validateSchedule,
    })

    const { mutate: updateJobMu, isPending } = useMutation({
        mutationFn: (data: UpdateJobType) => updateJob(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['jobById'] })
            toast({
                description: 'Successfully updated!',
                variant: 'default',
            })
            navigate(`/schedule/jobs/${job?.jobId}`)
        },
        onError: () =>
            toast({
                description: 'Unable to update job.',
                variant: 'destructive',
            }),
    })

    useEffect(() => {
        setIsEditing(true)
        if (job?.addressId && job?.businessId && job?.jobId) {
            setValue('addressId', job.addressId)
            setValue('businessId', job.businessId)
            setValue('jobId', job.jobId)
        }
    }, [job])

    useEffect(() => {
        if (isJobSuccess) {
            validateScheduleMu({
                crewId: crewId,
                profile: job?.quote?.profile,
                startDateTime: job?.appointment?.startDateTime,
                endDateTime: job?.appointment?.endDateTime,
                repeat: removeRecurringPrefix(job?.appointment?.appointmentType as string),
                appointmentType: job?.appointment?.appointmentType ?? APPOINTMENT_TYPES.ONEOFF_JOB,
                availableAnytime: job?.appointment?.availableAnytime ?? false,
            })
        }
    }, [crewId, job, isJobSuccess])

    const onSubmit = (data: UpdateJobType) => updateJobMu(data)

    if (!job) {
        return null
    }

    if (isJobLoading) {
        return 'Loading...'
    }

    const formattedJobNumber = `Job Order #${formatRecordNumber(job?.jobNumber ?? 0)}`

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName={formattedJobNumber} pathId={job.jobId} label='Edit Job' />
            <div className='bg-[#f1f1f1] p-4 border-t-8 border-t-zentive-green-dark rounded-none'>
                <div className='flex border-b border-b-gray-200 p-4 justify-between'>
                    <h1 className='text-2xl font-bold mt-2'>{formattedJobNumber}</h1>
                    <p
                        className={cn(
                            'text-base py-2 px-4 rounded-3xl',
                            getJobStatusClasses(job?.status as string),
                        )}
                    >
                        {job?.status && JOB_STATUS_LABEL[jobsStatus]}
                    </p>
                </div>
                <Form {...updateJobMethods}>
                    <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
                        <JobDetails job={job} />

                        <div className='bg-[#e7e7e7] px-4 py-4 mt-4 border-t-8 border-[#d6d6d6]'>
                            <JobLineItems quote={job.quote} />
                            {job?.crew ? (
                                <JobTimeEntry job={job} />
                            ) : (
                                <AssignCrew schedAvailable={!!isScheduleAvailable} />
                            )}
                            <JobExpenses quote={job.quote} />
                        </div>

                        <InternalNotesAndAttachments job={job} />

                        <div className='lg:flex lg:justify-end rounded-b-md bg-[#EBEBEB] py-4 px-6'>
                            <div className='flex space-x-4'>
                                <Button
                                    type='button'
                                    variant={'outline'}
                                    className='w-97 mr-3 text-zentive-green-dark font-semibold'
                                    onClick={() => {
                                        navigate(-1)
                                        setIsEditing(false)
                                        setValue('description', '')
                                        setValue('crewId', '')
                                        setValue('jobImages', [])
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type='submit'
                                    className='w-97 mr-5'
                                    disabled={
                                        isPending ||
                                        (!crewId && !description && jobImages?.length == 0) ||
                                        !isScheduleAvailable
                                    }
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                    </form>
                </Form>
            </div>
        </div>
    )
}

export default EditJob
