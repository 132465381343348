
import { TableCell } from '@/components/Table' 
import DeleteServiceModal from '../DeleteService'
import { ProductServiceType } from '@/api/service/schema'
import UpdateServiceModal from '../UpdateService'

type ActionButtonProps = {
    service: ProductServiceType
}

const ActionButton = (props: ActionButtonProps) => {

    return (
        <TableCell className='flex justify-center'>
            <div className='flex flex-row justify-center space-x-10'>
                <DeleteServiceModal service={props.service} />
                <UpdateServiceModal service={props.service} />
            </div>
        </TableCell>
    )
}
export default ActionButton
