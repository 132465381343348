import { SignUpType } from '@/api/auth/schema'
import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import PhoneNumberInput from '@/components/InputNumber'
import { useFormContext } from 'react-hook-form'

const OtherFields = () => {
    const { control } = useFormContext<SignUpType>()
    return (
        <div className='grid grid-cols-2 gap-4'>
            <div className='col-span-1'>
                <FormField
                    name='data.phoneNumber'
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <PhoneNumberInput
                                    inputProps={{
                                        name: 'Contact Number',
                                        placeholder: 'Contact Number*',
                                    }}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    name='data.businessWebsite'
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input placeholder='Business Website*' type='text' {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    name='data.employeeCount'
                    control={control}
                    render={({ field: { onChange, ...res } }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    placeholder='Employee Count*'
                                    type='number'
                                    isNumberRestricted={true}
                                    onChange={(e) => {
                                        const value = e.target.value

                                        onChange(!value ? 0 : parseFloat(value))
                                    }}
                                    {...res}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </div>
    )
}
export default OtherFields
