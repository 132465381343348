import { QuoteType } from '@/api/quoting/schema'
import { Button } from '@/components/Button'
import {
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    DialogPortal,
    DialogTitle,
} from '@/components/Dialog'
import { Input } from '@/components/Input'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Checkbox } from '@/components/Checkbox'
import { useSetAtom } from 'jotai'
import { quoteTaxAtom } from '@/store/owner'
import { DEFAULT_QUOTE_TAX_VALUE } from '@/constants'

export interface TaxesParams {
    name: string
    productTax: number
    isProductsTaxable: boolean
    serviceTax: number
    isServicesTaxable: boolean
    expenseTax: number
    isExpensesTaxable: boolean
}

const AddTaxDialog = () => {
    const [taxesToSave, setTaxesToSave] = useState<TaxesParams>(DEFAULT_QUOTE_TAX_VALUE)
    const setTaxes = useSetAtom(quoteTaxAtom)

    const { setValue } = useFormContext<QuoteType>()

    const handleOnServicesTaxChange = (servicesTaxRate: number) => {
        if (isNaN(servicesTaxRate)) {
            servicesTaxRate = 0
        }

        if (taxesToSave.isServicesTaxable) {
            setTaxesToSave((prevState) => ({
                ...prevState,
                serviceTax: servicesTaxRate,
            }))
        }
    }
    const handleOnProductsTaxChange = (productsTaxRate: number) => {
        if (isNaN(productsTaxRate)) {
            productsTaxRate = 0
        }

        if (taxesToSave.isProductsTaxable) {
            setTaxesToSave((prevState) => ({
                ...prevState,
                productTax: productsTaxRate,
            }))
        }
    }
    const handleOnExpensesTaxChange = (expensesTaxRate: number) => {
        if (isNaN(expensesTaxRate)) {
            expensesTaxRate = 0
        }

        if (taxesToSave.isExpensesTaxable) {
            setTaxesToSave((prevState) => ({
                ...prevState,
                expenseTax: expensesTaxRate,
            }))
        }
    }

    const handleSaveTaxes = () => {
        setTaxes({
            name: taxesToSave.name,
            serviceTax: taxesToSave.serviceTax,
            isServicesTaxable: taxesToSave.isServicesTaxable,
            productTax: taxesToSave.productTax,
            isProductsTaxable: taxesToSave.isProductsTaxable,
            expenseTax: taxesToSave.expenseTax,
            isExpensesTaxable: taxesToSave.isExpensesTaxable,
        })
    }

    const handleTaxCancel = () => {
        setTaxesToSave({
            name: '',
            productTax: 0,
            isProductsTaxable: false,
            serviceTax: 0,
            isServicesTaxable: false,
            expenseTax: 0,
            isExpensesTaxable: false,
        })
    }

    const handleCheckboxChange = (
        isCheck: boolean,
        fieldName: string,
        taxFieldName: 'expenseTax' | 'serviceTax' | 'productTax',
    ) => {
        if (isCheck) {
            setTaxesToSave((prevState) => ({
                ...prevState,
                [fieldName]: true,
            }))
        } else {
            setTaxesToSave((prevState) => ({
                ...prevState,
                [fieldName]: false,
                [taxFieldName]: 0,
            }))
            setValue(taxFieldName, 0)
        }
    }

    return (
        <DialogPortal>
            <DialogOverlay>
                <DialogContent className='max-w-[400px] w-[400px] max-h-[470px] h-[470px] rounded-md '>
                    <DialogHeader className='m-6'>
                        <DialogTitle className='mb-2'>
                            <div className='text-[24px] font-semibold text-left mb-6'>
                                Add Tax Rate
                            </div>
                        </DialogTitle>
                        <DialogDescription className='no-scrollbar'>
                            <Input
                                className='w-full flex mb-8 justify-center items-center font-sans text-base h-11 border border-black disabled:bg-gray-100 text-gray-900'
                                placeholder='Tax Name'
                                value={taxesToSave.name}
                                onChange={(val) =>
                                    setTaxesToSave((prevState) => ({
                                        ...prevState,
                                        name: val.target.value,
                                    }))
                                }
                                name='name'
                                type='text'
                            />
                            <Checkbox
                                className='justify-left flex -ml-8 -mb-4 accent-zentive-green-dark'
                                checked={taxesToSave.isProductsTaxable}
                                onCheckedChange={(val) =>
                                    handleCheckboxChange(
                                        val as boolean,
                                        'isProductsTaxable',
                                        'productTax',
                                    )
                                }
                            />
                            <Input
                                className='w-full mb-8 -mt-7 flex justify-center items-center font-sans text-base h-11 border border-black disabled:bg-gray-100 text-gray-900'
                                placeholder='Products Tax Rate %'
                                value={taxesToSave.productTax}
                                name='rate'
                                type='number'
                                onChange={(val) =>
                                    handleOnProductsTaxChange(parseFloat(val.target.value))
                                }
                                disabled={!taxesToSave.isProductsTaxable}
                                onKeyDown={(e) => {
                                    if (
                                        e.key === '-' ||
                                        e.key === '+' ||
                                        e.key === 'e' ||
                                        e.key === 'E'
                                    ) {
                                        e.preventDefault()
                                    }
                                }}
                            />
                            <Checkbox
                                className='justify-left flex -ml-8 -mb-4 accent-zentive-green-dark'
                                checked={taxesToSave.isServicesTaxable}
                                onCheckedChange={(val) =>
                                    handleCheckboxChange(
                                        val as boolean,
                                        'isServicesTaxable',
                                        'serviceTax',
                                    )
                                }
                            />
                            <Input
                                className='w-full mb-8 -mt-7 flex justify-center items-center font-sans text-base h-11 border border-black disabled:bg-gray-100 text-gray-900'
                                placeholder='Services Tax Rate %'
                                value={taxesToSave.serviceTax}
                                name='rate'
                                type='number'
                                onChange={(val) =>
                                    handleOnServicesTaxChange(parseFloat(val.target.value))
                                }
                                disabled={!taxesToSave.isServicesTaxable}
                                onKeyDown={(e) => {
                                    if (
                                        e.key === '-' ||
                                        e.key === '+' ||
                                        e.key === 'e' ||
                                        e.key === 'E'
                                    ) {
                                        e.preventDefault()
                                    }
                                }}
                            />
                            <Checkbox
                                className='justify-left flex -ml-8 -mb-4 accent-zentive-green-dark'
                                checked={taxesToSave.isExpensesTaxable}
                                onCheckedChange={(val) =>
                                    handleCheckboxChange(
                                        val as boolean,
                                        'isExpensesTaxable',
                                        'expenseTax',
                                    )
                                }
                            />
                            <Input
                                className='w-full mb-8 -mt-7 flex justify-center items-center font-sans text-base h-11 border border-black disabled:bg-gray-100 text-gray-900'
                                placeholder='Expenses Tax Rate %'
                                value={taxesToSave.expenseTax}
                                name='rate'
                                type='number'
                                onChange={(val) =>
                                    handleOnExpensesTaxChange(parseFloat(val.target.value))
                                }
                                disabled={!taxesToSave.isExpensesTaxable}
                                onKeyDown={(e) => {
                                    if (
                                        e.key === '-' ||
                                        e.key === '+' ||
                                        e.key === 'e' ||
                                        e.key === 'E'
                                    ) {
                                        e.preventDefault()
                                    }
                                }}
                            />
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className='absolute self-end py-4 px-6 w-full items-center justify-center bg-zentive-gray-light'>
                        <div className='flex flex-row gap-x-4 justify-center w-full'>
                            <DialogClose asChild>
                                <Button
                                    type='button'
                                    variant={'outline'}
                                    className='w-[50%]'
                                    onClick={handleTaxCancel}
                                >
                                    Cancel
                                </Button>
                            </DialogClose>

                            <DialogClose asChild>
                                <Button
                                    type='submit'
                                    className='w-[50%]'
                                    disabled={
                                        (taxesToSave.name !== '' &&
                                            taxesToSave.serviceTax > 0 &&
                                            taxesToSave.isServicesTaxable) ||
                                        (taxesToSave.productTax > 0 &&
                                            taxesToSave.isProductsTaxable) ||
                                        (taxesToSave.expenseTax > 0 &&
                                            taxesToSave.isExpensesTaxable)
                                            ? false
                                            : true
                                    }
                                    onClick={handleSaveTaxes}
                                >
                                    Save
                                </Button>
                            </DialogClose>
                        </div>
                    </DialogFooter>
                </DialogContent>
            </DialogOverlay>
        </DialogPortal>
    )
}

export default AddTaxDialog
