import { Button } from '@/components/Button'
import { useMutation, useQuery } from '@tanstack/react-query'
import { isViewedAsAtom, timezoneAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { ISO_8601_WITH_UTC_OFFSET, USER_STATUS } from '@/constants'
import { getAllCrews } from '@/api/crew'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useForm, useFormContext } from 'react-hook-form'
import { JobRoutingType, RouteParamType, jobRoutingSchema } from '@/api/routing/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { CiSearch } from 'react-icons/ci'
import { CreateJobType, JobGroupType } from '@/api/job/schema'
import { AxiosError } from 'axios'
import { getJobsInRoute } from '@/api/routing'
import { hasJobResultAtom, routeJobListAtom, routePolylineAtom } from '@/store/route'
import { formatToUTCWithOffset } from '@/utils/time'
import dayjs from 'dayjs'

const isToday = (date: Date | null): boolean => {
    if (!date) return false
    const today = new Date()
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    )
}

const RoutingMapHeader = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const userTimezone = useAtomValue(timezoneAtom)
    const [_, setPolylineList] = useAtom(routePolylineAtom)
    const setRouteJobList = useSetAtom(routeJobListAtom)
    const setHasJobResult = useSetAtom(hasJobResultAtom)
    const [date, setDate] = useState('Specify a Date')
    const [crewNumber, setCrewNumber] = useState('Specify a Crew')
    const [startDate, setStartDate] = useState<Date | null>(new Date())
    const [datePickerIsOpen, setDatePickerIsOpen] = useState<boolean>(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [jobGroups, setJobGroups] = useState<JobGroupType[]>([])

    const methods = useForm<JobRoutingType>({
        mode: 'onSubmit',
        resolver: zodResolver(jobRoutingSchema),
    })

    const { setValue, watch, handleSubmit } = methods
    const watchedFields = watch()

    const { setValue: setRouteValue } = useFormContext<RouteParamType>()

    useEffect(() => {
        if (user?.businessId) {
            setValue('businessId', user?.businessId)
        }
    }, [user, setValue])

    const { data } = useQuery({
        queryKey: ['crews', user?.businessId],
        queryFn: () => getAllCrews('', USER_STATUS.ACTIVATED, user?.businessId as string, {}),
        enabled: !!user?.businessId,
    })

    const crews = data?.content || []
    const today = new Date()

    const handleChange = (date: Date | null) => {
        if (!date) return

        const dateStr = date.toDateString()

        const timezoneDate = formatToUTCWithOffset(
            dateStr,
            userTimezone?.timeZoneId as string,
            ISO_8601_WITH_UTC_OFFSET,
        )
        const tzDate = dayjs(timezoneDate).format(ISO_8601_WITH_UTC_OFFSET)

        setDatePickerIsOpen(!datePickerIsOpen)
        setStartDate(date)
        setDate(formatDate(tzDate))
        setValue('targetDate', tzDate)
        setRouteValue('jobDueDate', date)
        const status = isToday(date) ? 'in progress' : 'open'
        setRouteValue('status', status)
    }

    const toggleDatePicker = () => {
        setDatePickerIsOpen(!datePickerIsOpen)
    }

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen)
    }

    const formatDate = (date: string | null): string => {
        if (!date) return ''
        return dayjs(date).format('MMM D, YYYY')
    }

    useEffect(() => {
        const isDisable =
            watchedFields.businessId == '' ||
            watchedFields.businessId == null ||
            watchedFields.crewId == '' ||
            watchedFields.crewId == null ||
            watchedFields.targetDate == '' ||
            watchedFields.targetDate == null
        setIsDisabled(isDisable)
    }, [watchedFields])

    const { mutate: getJobsMu } = useMutation<CreateJobType[], AxiosError, JobRoutingType>({
        mutationFn: (param) => getJobsInRoute(param),
        onSuccess: (data) => {
            setRouteValue('jobGroup', [])
            setRouteJobList(data)
            if (!data || data?.length === 0) {
                setHasJobResult(false)
            } else {
                setHasJobResult(true)
            }
            const updatedJobGroups = data?.map((job) => ({ jobId: job.jobId! }))
            setJobGroups((prevGroups) => [...(prevGroups ?? []), ...updatedJobGroups])
            setRouteValue('jobGroup', [...(jobGroups ?? []), ...updatedJobGroups])
            setPolylineList(null)
        },
        onError: (err) => console.log(err),
    })

    const onSubmit = (data: JobRoutingType) => {
        getJobsMu(data)
    }

    return (
        <div className='flex items-start text-left justify-between mt-[1.1rem] bg-white py-5 px-5'>
            <div className='flex w-[50%]'>
                <div className='flex flex-col w-[30%] border-r border-gray-300'>
                    <div
                        className='flex flex-col w-full hover:bg-zentive-gray-light cursor-pointer rounded-sm p-2'
                        onClick={toggleDatePicker}
                    >
                        <span className='text-zentive-gray-medium font-semibold'>Date</span>
                        <span className=' text-lg text-zentive-gray-medium'>{date}</span>
                    </div>
                    {datePickerIsOpen && (
                        <DatePicker
                            selected={startDate}
                            onChange={handleChange}
                            onClickOutside={toggleDatePicker}
                            open={datePickerIsOpen}
                            minDate={today}
                            className='w-0 h-0 p-0'
                        />
                    )}
                </div>
                <div className='relative w-[30%]'>
                    <div className='flex flex-col '>
                        <div
                            className='flex flex-col w-full hover:bg-zentive-gray-light cursor-pointer rounded-sm p-2 pl-5'
                            onClick={toggleDropdown}
                        >
                            <span className='text-zentive-gray-medium font-semibold'>Crew</span>
                            <span className='text-lg text-zentive-gray-medium'>{crewNumber}</span>
                        </div>
                    </div>
                    {isDropdownOpen && (
                        <div className='mt-2 w-full h-auto absolute rounded-md bg-white shadow-lg focus:outline-none'>
                            <div className='bg-white rounded-md'>
                                {crews?.map((item) => (
                                    <button
                                        className='w-full h-8 hover:bg-[#00000029] rounded-t-md text-left px-3'
                                        onClick={() => {
                                            setIsDropdownOpen(false)
                                            setCrewNumber(item.crewNumber.toString())
                                            setValue('crewId', item.crewId)
                                            setRouteValue('crewId', item.crewId)
                                        }}
                                    >
                                        <span className='text-base ml-2'> {item.crewNumber} </span>
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='flex w-[50%] justify-end my-auto'>
                <Button
                    type='submit'
                    onClick={handleSubmit(onSubmit)}
                    disabled={isDisabled}
                    className='border font-extabold bg-zentive-green-dark text-zentive-green-light hover:text-zentive-green-light hover:border-zentive-green-medium hover:bg-zentive-green-medium disabled:bg-zentive-gray-light disabled:text-zentive-gray-dark'
                >
                    <CiSearch size={24} className='' />
                </Button>
            </div>
        </div>
    )
}

export default RoutingMapHeader
