import { PaginationType } from '@/components/Pagination/schema'
import { atom } from 'jotai'

export const invoicePaginationAtom = atom<PaginationType>({
    page: 1,
    pageSize: 5,
})

export const crewPaginationAtom = atom<PaginationType>({
    page: 1,
    pageSize: 5,
})

export const paginationAtom = atom<number>(0)
export const employeePaginationAtom = atom<number>(0)
export const crewsPaginationAtom = atom<number>(0)
