import { atom } from 'jotai'
import { IFile } from '@/components/Common/ImageUploader'
import { EmployeeDataType, UserDataType } from '@/api/auth/schema'
import { EmployeeListType, EmployeeToCrewType } from '@/api/employee/schema'
import { CrewListResponseType } from '@/api/crew/schema'
import { PaginationType } from '@/components/Pagination/schema'

export const employeeAtom = atom<UserDataType | null>(null)
export const employeeProfileAtom = atom<EmployeeDataType | null>(null)
export const employeeImgAtom = atom<IFile[]>([])
export const selectedCrewTabStatusAtom = atom<string>('ACT')
export const selectedEmployeeTabStatusAtom = atom<string>('ACT')
export const selectedEmployeeTabAtom = atom<string>('PI')
export const selectedCrewTabAtom = atom<string>('CI')
export const manageEmployeeAtom = atom<EmployeeListType | null>(null)
export const toExportEmployeeAtom = atom<EmployeeListType | null>(null)
export const manageCrewListAtom = atom<CrewListResponseType | null>(null)
export const crewMemberProfileIdsAtom = atom<string[]>([])
export const crewListAtom = atom<EmployeeListType | null>(null)
export const selectedCrewForeManAtom = atom<EmployeeToCrewType | null>(null)

export const crewPaginationAtom = atom<PaginationType>({
    page: 1,
    pageSize: 5,
})

export const setCrewPaginationAtom = atom(null, (_, set, newValue: PaginationType) => {
    set(crewPaginationAtom, newValue)
})

export const itemsPerPageAtom = atom(5)

export const employeeSortByAtom = atom<string>('')
export const employeeSortOrderAtom = atom<string>('')