import { Button } from '@/components/Button'
import { cn } from '@/utils/helper'
import { useTranslation } from 'react-i18next'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'

interface IConfirmationModal {
    title: string
    text: string
    isOpen: boolean
    onClose: () => void
    onProceed: () => void
}

const ConfirmationModal = ({ title, text, isOpen, onClose, onProceed }: IConfirmationModal) => {
    const { t } = useTranslation(['common'])
    return (
        <div className='fixed bottom-0 w-full z-50 max-w-[1024px]'>
            <div
                className={`sticky transform ${
                    isOpen
                        ? 'translate-y-4 h-[65dvh] md:h-[65dvh] lg:h-[62dvh] 2xl:h-[45dvh]'
                        : 'translate-y-1/4 h-[0dvh] '
                } transition-all duration-500r bg-[#EBEBEB]`}
            >
                <div className='flex flex-col'>
                    <div className='flex flex-col items-center justify-center bg-white space-y-4 text-sm py-8'>
                        <div className='bg-zentive-red-light rounded-full py-4'>
                            <HiOutlineExclamationTriangle className='h-10 w-10 mx-5 text-red-500' />
                        </div>
                        <div className='px-10 text-center'>{title}</div>
                    </div>
                    <div
                        className={cn(
                            'flex flex-col w-full items-center justify-center bg-[#EBEBEB] py-10 px-4',
                            'mb-0 md:mt-10 lg:mt-0 2xl:mt-2',
                        )}
                    >
                        <Button
                            variant='default'
                            className='text-white w-full mb-4'
                            onClick={onProceed}
                        >
                            {text}
                        </Button>
                        <Button
                            variant='ghost'
                            className='w-full hover:none bg-white border border-zentive-green-dark'
                            onClick={onClose}
                        >
                            {t('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal
