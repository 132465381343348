import { getQuote } from '@/api/quoting'
import { Card, CardContent, CardFooter, CardHeader } from '@/components/Card'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import CustomerDetailsWithQuote from '../../../../common/Schedule/Job/CustomerDetailsWithQuote'
import { JobNote } from '@/pages/common/Schedule/Job/JobNote'
import { JobType } from '@/pages/common/Schedule/Job/JobType'
import { OneOffJobSchedule } from '../../../../common/Schedule/Job/OneOffJobSchedule'
import {
    CreateJobWithQuoteType,
    CreateJobWithoutQuoteType,
    convertToJobSchema,
} from '@/api/job/schema'
import { Form } from '@/components/Forms'
import CalendarComponent from '../../../../common/Schedule/Job/FullCalendar'
import { AssignCrew } from '@/pages/common/Schedule/Job/AssignCrew'
import { Button } from '@/components/Button'
import { NotesAndAttachments } from '@/pages/common/Schedule/Job/NotesAndAttachments'
import { useEffect, useState } from 'react'
import { convertQuoteToJob, getJobNumber } from '@/api/job'
import { formatRecordNumber } from '@/utils/helper'
import dayjs from 'dayjs'
import { ISO_8601_WITH_UTC_OFFSET, APPOINTMENT_TYPES, FEATURE_RESTRICTIONS } from '@/constants'
import ProductServiceWithQuote from '@/pages/common/Schedule/Job/ProductServiceWithQuote'
import ExpenseWithQuote from '@/pages/common/Schedule/Job/ExpenseWithQuote'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import { useToast } from '@/hooks/useToast'
import RecurringJobSchedule from '@/pages/common/Schedule/Job/RecurringJobSchedule'
import { validateSchedule } from '@/api/appointment'
import { AxiosError } from 'axios'
import useValidateFormTime from '@/hooks/useValidateFormTime'
import JobCrewMemberModal from '@/components/JobCrewMemberModal'
import { ValidateScheduleType } from '@/api/appointment/schema'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useAtomValue } from 'jotai/react'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'

const CreateJobWithQuote = () => {
    const { quoteId } = useParams()
    const { navigateTo } = useAppNavigate()
    const { toast } = useToast()
    const [crewMemberModal, setCrewMemberModal] = useState<boolean>(false)
    const [isScheduleAvailable, setIsCheduleAvailable] = useState<boolean>(true)

    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const { data: quote, isFetched } = useQuery({
        queryKey: ['quote', quoteId],
        queryFn: () => getQuote(quoteId ?? ''),
    })

    const { data: jobNumber } = useQuery({
        queryKey: ['jobNumber', user?.businessId],
        queryFn: () => getJobNumber(user?.businessId as string),
        enabled: !!user?.businessId,
    })

    const methods = useForm<CreateJobWithQuoteType | CreateJobWithoutQuoteType>({
        defaultValues: {
            appointment: {
                appointmentType: APPOINTMENT_TYPES.ONEOFF_JOB,
                startDateTime: dayjs()
                    .hour(9)
                    .minute(0)
                    .second(0)
                    .millisecond(0)
                    .format(ISO_8601_WITH_UTC_OFFSET),
                endDateTime: dayjs()
                    .hour(17)
                    .minute(0)
                    .second(0)
                    .millisecond(0)
                    .format(ISO_8601_WITH_UTC_OFFSET),
            },
            description: '',
            jobImages: [],
            note: '',
        },
        mode: 'onChange',
        resolver: zodResolver(convertToJobSchema),
    })

    const {
        formState: { errors, isValid },
        clearErrors,
        handleSubmit,
        setError,
        setValue,
        watch,
    } = methods

    const appointmentType = watch('appointment.appointmentType')
    const crewId = watch('crewId')
    const startDT = watch('appointment.startDateTime')
    const endDT = watch('appointment.endDateTime')
    const customerProfile = watch('profile')
    const repeat = watch('appointment.repeat')
    const availableAnytime = watch('appointment.availableAnytime')

    const hasErrors = !!Object.keys(errors)?.length

    const { mutate: quoteToJobMu, isPending } = useMutation<
        undefined,
        undefined,
        CreateJobWithQuoteType | CreateJobWithoutQuoteType
    >({
        mutationFn: convertQuoteToJob,
        onSuccess: () => {
            navigateTo('/schedule/jobs')
            toast({
                description: 'Job successfully created!',
                variant: 'default',
            })
        },
    })

    const { data: isFeatureAvailable, isLoading: featureLoading } = useQuery<boolean>({
        queryKey: ['createJobFeatureRestriction', user?.profileId],
        queryFn: () =>
            checkSubscriptionFeatureRestriction(
                user?.profileId as string,
                FEATURE_RESTRICTIONS.customFields,
            ),
    })

    const validateScheduleMu = useMutation<boolean, AxiosError, ValidateScheduleType>({
        mutationFn: validateSchedule,
        onSuccess: (data) => setIsCheduleAvailable(data),
    })

    const createJob = (data: CreateJobWithQuoteType | CreateJobWithoutQuoteType) => {
        quoteToJobMu(data)
    }

    const onSubmit = (data: CreateJobWithQuoteType | CreateJobWithoutQuoteType) => {
        if (crewId) {
            createJob(data)
        } else {
            setCrewMemberModal(true)
        }
    }

    useEffect(() => {
        if (quote && isFetched) {
            setValue('addressId', quote.addressId)
            setValue('businessId', quote.businessId)
            setValue('quoteId', quote.quoteId as string)
            setValue('quote', quote)
        }
    }, [quote, isFetched])

    useEffect(() => {
        setValue(
            'appointment.startDateTime',
            dayjs().hour(9).minute(0).second(0).millisecond(0).format(ISO_8601_WITH_UTC_OFFSET),
        )
    }, [appointmentType])

    useEffect(() => {
        if (crewId && !availableAnytime) {
            validateScheduleMu.mutateAsync({
                crewId: crewId as string,
                profile: customerProfile,
                startDateTime: startDT,
                endDateTime: endDT,
                repeat: repeat,
                appointmentType: appointmentType,
                availableAnytime: availableAnytime,
            })
        } else {
            setIsCheduleAvailable(true)
        }
    }, [crewId, customerProfile, startDT, endDT, appointmentType])

    useValidateFormTime<CreateJobWithQuoteType>({
        endDateTime: endDT,
        endField: 'appointment.endDateTime',
        startDateTime: startDT,
        startField: 'appointment.startDateTime',
        clearErrors,
        setError,
        additionalDeps: [appointmentType],
    })

    return (
        <Form {...methods}>
            <JobCrewMemberModal
                open={crewMemberModal}
                setOpen={setCrewMemberModal}
                submit={createJob}
                methods={methods}
            />
            <form className='p-24' onSubmit={handleSubmit(onSubmit)}>
                <Card className='bg-zentive-bg-100 border-0 border-t-[10px] border-zentive-green-dark rounded-none'>
                    <CardHeader>
                        <h1 className='font-bold text-2xl'>Job For</h1>
                    </CardHeader>
                    <CardContent className='flex flex-col gap-5'>
                        <section className='flex flex-row gap-5'>
                            <CustomerDetailsWithQuote customer={quote?.profile ?? null} />
                            <JobNote
                                jobNumber={jobNumber ? formatRecordNumber(jobNumber) : ''}
                                quoteNumber={
                                    quote?.quoteNumber ? formatRecordNumber(quote?.quoteNumber) : 1
                                }
                                featureAvailable={isFeatureAvailable as boolean}
                                isLoading={featureLoading}
                            />
                        </section>

                        <JobType />

                        <section className='flex flex-row gap-5'>
                            {appointmentType === APPOINTMENT_TYPES.ONEOFF_JOB ? (
                                <OneOffJobSchedule />
                            ) : (
                                <RecurringJobSchedule />
                            )}

                            <CalendarComponent />
                        </section>

                        <AssignCrew schedAvailable={isScheduleAvailable} />

                        <ProductServiceWithQuote productServiceGroup={quote?.productServiceGroup} />

                        <ExpenseWithQuote expense={quote?.expense} />

                        <NotesAndAttachments />
                    </CardContent>
                    <CardFooter className='gap-5 bg-zentive-bg-100 justify-end'>
                        <Button
                            className='w-[8rem] h-[4rem]'
                            variant={'outline'}
                            onClick={() => navigateTo('/schedule/jobs')}
                        >
                            Cancel
                        </Button>
                        <Button
                            className='w-[8rem] h-[4rem]'
                            type='submit'
                            disabled={
                                hasErrors ||
                                !isValid ||
                                !isScheduleAvailable ||
                                methods.formState.isSubmitting ||
                                validateScheduleMu.isPending
                            }
                        >
                            {isPending ? 'Saving' : 'Save'}
                        </Button>
                    </CardFooter>
                </Card>
            </form>
        </Form>
    )
}

export default CreateJobWithQuote
