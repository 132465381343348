import { MessageListType } from '@/api/customer/schema'
const Subject = (type: string, subject: string) => {
    if (type != 'sms') {
        return (
            <>
                <div className='pt-10'>
                    <p className='font-bold'>Subject</p>
                    <p>{subject}</p>
                </div>
            </>
        )
    }
    return null
}

const MessageContent = ({ type, content }: { type: string; content: string }) => (
    <div className='pt-10 px-2'>
        <p className='font-bold'>{type.toUpperCase()}</p>
        <div className='mt-3 w-full bg-gray-100 p-5 rounded-md'>
            {type === 'email' ? (
                <div dangerouslySetInnerHTML={{ __html: content }} />
            ) : (
                <p>{content}</p>
            )}
        </div>
    </div>
)

const EmailandSMS = ({ smsBody, emailBody }: { smsBody: string; emailBody: string }) => (
    <div className='grid grid-row-2'>
        <div>
            <div>
                <MessageContent type='email' content={emailBody} />
            </div>
        </div>
        <div>
            <div>
                <MessageContent type='sms' content={smsBody} />
            </div>
        </div>
    </div>
)

const Body = (message: MessageListType) => {
    let content = ''
    let type = ''

    if (message?.type === 'sms' && message?.smsBody) {
        type = 'sms'
        content = message.smsBody
    } else if (message?.type === 'email' && message?.emailBody) {
        type = 'email'

        content = message.emailBody.replace(/^"(.+(?="$))"$/, '$1').replace(/\\"/g, '"')
    }

    if (message?.type === 'both') {
        const emailBody = message.emailBody!.replace(/^"(.+(?="$))"$/, '$1').replace(/\\"/g, '"')
        return <EmailandSMS smsBody={message?.smsBody || ''} emailBody={emailBody} />
    }

    return content ? <MessageContent type={type} content={content} /> : null
}

interface Props {
    message: MessageListType
}
const EmailDetails = ({ message }: Props) => {
    const subject = Subject(message?.type!, message?.subject!)
    const body = Body(message!)

    return (
        <>
            <div className='grid grid-cols-2'>
                <div>
                    <p className='font-bold'>Title</p>
                    <p>{message?.title}</p>
                </div>
                <div>
                    <p className='font-bold'>Date Created</p>
                    <p>{message?.dateCreated}</p>
                </div>

                {subject}

                <div className='pt-10'>
                    <p className='font-bold'>Sent by</p>
                    <p>{message?.sentBy}</p>
                </div>
            </div>
            {body}
        </>
    )
}

export default EmailDetails
