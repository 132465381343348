import { Button } from '@/components/Button'
import { cn } from '@/utils/helper'

interface EmployeeButtonProp {
    isUpdated?: boolean
    isValid?: boolean
    isSubmitting?: boolean
    isInactive?: boolean
    mode?: string
    isDescriptionEmpty?: boolean
    isForeManEmpty?: boolean
    handleCancel: () => void
}

const CrewButtons: React.FC<EmployeeButtonProp> = ({
    isUpdated,
    isValid,
    isSubmitting,
    isInactive,
    mode,
    isDescriptionEmpty,
    isForeManEmpty,
    handleCancel
}) => {
    const isDirty = () => {
        if (isValid === true) return false
        if (!isSubmitting) return false
        else return true
    }

    return (
        <div className={cn('flex space-x-4 mt-[24px] px-4', isInactive && 'hidden')}>
            <Button
                onClick={handleCancel}
                type='button'
                variant={'outline'}
                className='w-full'
            >
                Cancel
            </Button>
            <Button type='submit' className='w-full' disabled={isDirty() || isDescriptionEmpty || isForeManEmpty || !isUpdated }>
                {mode === 'create' ? 'Save' : 'Update'}
            </Button>
        </div>
    )
}
export default CrewButtons
