import { FullProfileType } from '@/api/profile/schema'
import { getOwnerProfile } from '@/api/service'
import { businessIdAtom } from '@/store/auth'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai'

const InvoiceFooter = () => {
    const businessId = useAtomValue(businessIdAtom)

    const { data: owner } = useQuery<unknown, AxiosError, FullProfileType>({
        queryKey: ['owner', businessId],
        queryFn: () => getOwnerProfile(businessId as string),
    })

    return (
        <div className='py-6'>
            <p className='text-3xl font-semibold text-center'>Thank You for Trusting Us</p>
            <p className='text-center font-semibold text-zentive-gray-medium'>
                {owner?.business?.businessName}
            </p>
            <p className='text-center text-zentive-gray-medium'>{`${
                owner?.address?.streetAddress + ','
            } ${
                owner?.address?.streetTwoAddress !== 'undefined' &&
                owner?.address?.streetTwoAddress !== undefined
                    ? owner?.address?.streetTwoAddress + ', '
                    : ''
            }${owner?.address?.city + ','} ${owner?.address?.state + ','} ${owner?.address
                ?.zipCode}`}</p>
            <p className='text-center text-zentive-gray-medium'>{owner?.email}</p>
            <p className='text-center text-zentive-gray-medium text-lg font-semibold py-4'>
                Powered by Zentive
            </p>
        </div>
    )
}
export default InvoiceFooter
