import { Card, CardContent, CardFooter, CardTitle } from '@/components/Card'
import { Form } from '@/components/Forms'
import ConfirmNewPassword from './ConfirmNewPassword'
import { Button } from '@/components/Button'
import { useUpdateProfile } from '@/hooks/useUpdateProfile'
import { ResetPasswordType } from '@/api/auth/schema'
import { useEffect, useState } from 'react'
import { setItem } from '@/utils/storage'
import goTrueClient from '@/api/goTrueClient'
import { authEventAtom, sessionAtom } from '@/store/auth'
import { useAtom, useAtomValue } from 'jotai'
import { Navigate } from 'react-router-dom'
import { Skeleton } from '@/components/Skeleton'
import { useTranslation } from 'react-i18next'
import LanguagePicker from '@/components/LanguagePicker'

const CreatePassword = () => {
    const { t } = useTranslation(['reset-password'])
    const { isPasswordChangePending, resetPasswordForm, validatePasswordChange } =
        useUpdateProfile()

    const [authSession, setSession] = useAtom(sessionAtom)

    const event = useAtomValue(authEventAtom)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const {
        handleSubmit,
        formState: { isValid },
    } = resetPasswordForm

    const onSubmit = (data: ResetPasswordType) => validatePasswordChange(data)

    useEffect(() => {
        const { data: authListener } = goTrueClient.onAuthStateChange((event, session) => {
            if (event === 'PASSWORD_RECOVERY' && session) {
                setSession(session)
                setIsLoading(false)
            }

            if (event === 'INITIAL_SESSION' || event === 'TOKEN_REFRESHED') {
                setItem('refresh_token', session?.refresh_token)
                setIsLoading(false)
            }
        })

        return () => authListener.subscription.unsubscribe()
    }, [])

    if (event === 'PASSWORD_RECOVERY' && isLoading) {
        return (
            <Skeleton className='w-125 h-337 bg-white bg-no-repeat bg-clip-padding box-shadow border rounded-lg opacity-100' />
        )
    }

    if (event === 'PASSWORD_RECOVERY' && authSession) {
        return (
            <div className='forgot-password-bg flex items-center justify-center h-[45rem] w-[56.5rem] bg-zentive-green-light'>
                <div className='w-125 h-337 bg-white bg-no-repeat bg-clip-padding box-shadow border rounded-lg opacity-100'>
                    <Form {...resetPasswordForm}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Card>
                                <CardTitle className='text-zentive-green-dark mt-5 text-center py-6'>
                                    {t('Create New Password')}
                                </CardTitle>
                                <CardContent>
                                    <p className='font-sans-pro text-gray-500'>
                                        {t('To enhance the security of your account your password must contain the following:')}
                                    </p>
                                    <ConfirmNewPassword />
                                </CardContent>
                                <CardFooter className='w-full flex flex-col mt-4 bg-zentive-gray-bg pr-[26px] pb-[15px] pt-[6px]'>
                                    <Button
                                        className='w-full mt-4'
                                        type='submit'
                                        disabled={!isValid || isPasswordChangePending}
                                    >
                                        {t('Create New Password')}
                                    </Button>
                                    <LanguagePicker />
                                </CardFooter>
                            </Card>
                        </form>
                    </Form>
                </div>
            </div>
        )
    }

    return <Navigate to='/' />
}

export default CreatePassword
