import { JobListType, JobLogResponseType } from '@/api/job/schema'
import { Card, CardContent, CardFooter } from '@/components/Card'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import { selectedJobLogTabAtom } from '@/store/jobLog'
import { cn, getQuoteServiceNames, formatRecordNumber } from '@/utils/helper'
import dayjs from 'dayjs'
import { useSetAtom } from 'jotai'
import { FaArrowDownLong } from 'react-icons/fa6'
import { HiOutlineDocument } from 'react-icons/hi'
import { NavLink, useNavigate } from 'react-router-dom'
import { getJobLogEndTime, getJobLogStartTime } from '@/utils/time'
import { getJobFontColor, JOB_STATUS_LABEL } from '@/constants'

const tableHeader = [
    'Date',
    'Job',
    'Customer',
    'Address',
    'Crew',
    'Start',
    'Finish',
    'Job Status',
    'Invoice Status',
    'Notes',
]

interface IJobLogTableProps {
    jobs: JobListType
}

const JobLogTable: React.FC<IJobLogTableProps> = ({ jobs }) => {
    const setSelected = useSetAtom(selectedJobLogTabAtom)
    const navigate = useNavigate()
    const handleNotesClick = (jobId: string) => {
        setSelected('NOTES')
        navigate(`/schedule/job-log/${jobId}`)
    }

    return (
        <Card className='overflow-x-scroll green-scrollbar'>
            <CardContent>
                <Table className='whitespace-normal w-full'>
                    <TableHeader className='whitespace-nowrap'>
                        <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                            {tableHeader?.map((header, index) => (
                                <TableHead
                                    key={index}
                                    className={cn(
                                        'font-semibold text-zentive-gray-medium text-base whitespace-nowrap',
                                    )}
                                >
                                    <div className='inline-flex gap-1'>
                                        {header}
                                        <FaArrowDownLong className='w-3 h-3 mt-[6px]' />
                                    </div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {jobs &&
                            jobs?.content?.map((job: JobLogResponseType, index) => (
                                <TableRow key={index} className='whitespace-nowrap'>
                                    <TableCell className=''>
                                        <NavLink
                                            to={`/schedule/job-log/${job.jobId}`}
                                            onClick={() => setSelected('INFO')}
                                        >
                                            <div className='flex items-center'>
                                                <div>
                                                    {dayjs(job?.appointment?.startDateTime).format(
                                                        'MM/DD/YYYY',
                                                    )}
                                                </div>
                                            </div>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='text-zentive-black text-base'>
                                        <NavLink
                                            to={`/schedule/job-log/${job.jobId}`}
                                            onClick={() => setSelected('INFO')}
                                        >
                                            <p className='truncate'>
                                                {getQuoteServiceNames(
                                                    job.quote.productServiceGroup,
                                                )}
                                            </p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className=''>
                                        <NavLink
                                            to={`/schedule/job-log/${job.jobId}`}
                                            onClick={() => setSelected('INFO')}
                                        >
                                            <p>
                                                {job.quote.profile?.firstName +
                                                    ' ' +
                                                    job.quote.profile?.lastName}
                                            </p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className=''>
                                        <NavLink
                                            to={`/schedule/job-log/${job.jobId}`}
                                            onClick={() => setSelected('INFO')}
                                        >
                                            <p>
                                                {job?.address?.streetAddress +
                                                    ', ' +
                                                    job?.address?.city +
                                                    ', ' +
                                                    job?.address?.state}
                                            </p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='text-zentive-black text-base'>
                                        <NavLink
                                            to={`/schedule/job-log/${job.jobId}`}
                                            onClick={() => setSelected('INFO')}
                                        >
                                            <p>
                                                {job.crew?.crewNumber
                                                    ? 'Crew ' +
                                                      formatRecordNumber(job.crew?.crewNumber)
                                                    : 'Unassigned'}
                                            </p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='text-zentive-black text-base'>
                                        <NavLink
                                            to={`/schedule/job-log/${job.jobId}`}
                                            onClick={() => setSelected('INFO')}
                                        >
                                            <p>{getJobLogStartTime(job)}</p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='text-zentive-black text-base'>
                                        <NavLink
                                            to={`/schedule/job-log/${job.jobId}`}
                                            onClick={() => setSelected('INFO')}
                                        >
                                            <p>{getJobLogEndTime(job)}</p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className={`text-zentive-black text-base`}>
                                        <span
                                            className={cn(
                                                'ml-2 inline-block',
                                                getJobFontColor(job?.status as string),
                                            )}
                                        >
                                            {job?.status && JOB_STATUS_LABEL[job?.status]}
                                        </span>
                                    </TableCell>
                                    <TableCell className='text-zentive-black text-base'>
                                        <NavLink
                                            to={`/schedule/job-log/${job.jobId}`}
                                            onClick={() => setSelected('INFO')}
                                        >
                                            <p>Upon Job Completion</p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell>
                                        <HiOutlineDocument
                                            className='mr-2 h-5 w-5 cursor-pointer'
                                            onClick={() => handleNotesClick(job.jobId ?? '')}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </CardContent>
            <CardFooter className='items-end justify-end'></CardFooter>
        </Card>
    )
}
export default JobLogTable
