import React from 'react'
import PublicHeader from '@/layouts/PublicLayout/PublicHeader'
import logo403 from '@/assets/public/logo403.png'
import manWithLock from '@/assets/public/temp403.png'
import { Button } from '@/components/Button'
import { useNavigate } from 'react-router-dom'

export const Error403 = () => {
    const navigate = useNavigate()
    return (
        <React.Fragment>
            <div className='relative'>
                <div className='bg-white absolute inset-x-0 top-0'>
                    <PublicHeader />
                </div>
                <div className='bg-zentive-gray-bg min-h-screen flex justify-center'>
                    <div className='flex flex-row items-center w-full justify-center'>
                        <div className='flex flex-col  md:mb-64 md:mt-20 md:mr-20 sm:mt-40'>
                            <img
                                src={logo403}
                                alt='403 Logo'
                                className='w-full h-auto max-w-md mb-10'
                            />
                            <Button
                                onClick={() => {navigate('/')}}
                                className='w-[50%] h-[70px] text-[#FFFFFF] text-xl bg-zentive-green-dark m-auto hover:bg-[#3B8102] hover:text-[#FFFFFF]'
                                variant={'outline'}
                            >
                                Go Back
                            </Button>
                        </div>
                        <div className='flex md:mb-40 md:mt-20 md:ml-0 w-[50%]'>
                            <img
                                src={manWithLock}
                                alt='main with lock'
                                className='w-[80%] h-auto m-auto'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
