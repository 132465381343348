import { Link } from 'react-router-dom'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import { SignInType } from '@/api/auth/schema'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { Button } from '@/components/Button'
import { InputPassword } from '@/components/InputPassword'
import { useSignInForm } from '@/hooks/useSignIn'
import RememberMe from '../RememberMe'
import { useCallback, useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import './index.css'
import { rememberMeAtom, rememberedEmailAtom } from '@/store/auth'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getProfileImageByEmail } from '@/api/auth'
import { CgProfile } from 'react-icons/cg'
import LanguagePicker from '@/components/LanguagePicker'
import { useTranslation } from 'react-i18next'

const CrewSignInForm = () => {
    const { t } = useTranslation(['sign-in'])
    const queryClient = useQueryClient()
    const { signInForm, signInMutator } = useSignInForm()
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [rememberedEmail, setRememberedEmail] = useAtom(rememberedEmailAtom)
    const [isRememberToggled, setIsRememberToggled] = useAtom(rememberMeAtom)

    const { data } = useQuery({
        queryKey: ['profileImage', rememberedEmail],
        queryFn: () => getProfileImageByEmail(rememberedEmail),
        enabled: !!rememberedEmail,
    })

    const handleSubmit = (data: SignInType) => {
        signInMutator.mutate(data)
    }

    const handleInputChange = () => {
        setIsSubmitted(false)
        if (signInForm.formState.errors.email) {
            signInForm.clearErrors('email')
        }
    }

    const handleButtonClick = () => {
        setIsSubmitted(true)
        if (isRememberToggled) {
            setRememberedEmail(signInForm.watch().email as string)
        }
    }

    const handleForgetRemember = () => {
        setIsRememberToggled(false)
        setRememberedEmail('')
        signInForm.setValue('email', '')
        queryClient.invalidateQueries({ queryKey: ['profileImage'] })
    }

    useEffect(() => {
        if (isRememberToggled) {
            signInForm.setValue('email', rememberedEmail)
        }

        if (signInForm.watch().email == '') {
            signInForm.reset()
            signInForm.setError('email', { message: 'Email is required' })
        }
    }, [isSubmitted])

    const RenderProfileImage = useCallback(() => {
        if (data && data?.content != '') {
            return (
                <div className='flex flex-col justify-center items-center gap-y-2'>
                    <img
                        src={data.content ?? ''}
                        className='relative flex h-16 w-16 shrink-0 overflow-hidden rounded-full'
                    />
                    <p className='text-sm font-semibold text-gray-500'>{rememberedEmail}</p>
                </div>
            )
        } else {
            return (
                <div className='flex flex-col  justify-center items-center gap-y-2'>
                    <CgProfile className='text-zentive-green-dark h-12 w-12' />
                    <p className='text-sm font-semibold text-gray-500'>{rememberedEmail}</p>
                </div>
            )
        }
    }, [data])

    return (
        <Form {...signInForm}>
            <form
                autoComplete='on'
                noValidate
                className='w-full h-full flex flex-col items-center'
                onSubmit={signInForm.handleSubmit(handleSubmit)}
            >
                <img
                    src={zentiveLogo}
                    alt='Zentive Logo'
                    className='h-[60px] w-[180px] cursor-pointer'
                />
                {rememberedEmail === '' ? (
                    <FormField
                        control={signInForm.control}
                        name='email'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        autoComplete='email'
                                        className='mt-[36px] w-64 md:w-100 bg-white'
                                        placeholder={t('Email Address')}
                                        type='email'
                                        {...field}
                                        onInput={handleInputChange}
                                    />
                                </FormControl>
                                {isSubmitted && signInForm.formState.errors.email && (
                                    <FormMessage>
                                        {signInForm.formState.errors.email.message}
                                    </FormMessage>
                                )}
                            </FormItem>
                        )}
                    />
                ) : (
                    RenderProfileImage()
                )}
                <FormField
                    control={signInForm.control}
                    name='password'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <InputPassword
                                    // autoComplete='password'
                                    criteria={false}
                                    className='mt-[16px] w-64 md:w-100 bg-white'
                                    placeholder={t('Password')}
                                    {...field}
                                    onInput={handleInputChange}
                                />
                            </FormControl>
                            {isSubmitted && signInForm.formState.errors.password && (
                                <FormMessage>
                                    {signInForm.formState.errors.password.message}
                                </FormMessage>
                            )}
                        </FormItem>
                    )}
                />
                {rememberedEmail === '' && (
                    <div className='flex items-center justify-between w-64 md:w-100 mt-[12px]'>
                        <RememberMe />
                    </div>
                )}
                {rememberedEmail === '' && (
                    <div className='flex items-end justify-end w-64 md:w-100 my-4'>
                        <Link to='/crew/forgot-password' className='text-zentive-blue-dark'>
                            {t('Forgot Password?')}
                        </Link>
                    </div>
                )}
                <Button className='w-64 md:w-100 mt-3' type='submit' onClick={handleButtonClick}>
                    {t('Sign In')}
                </Button>
                {rememberedEmail !== '' && (
                    <div className='flex items-center justify-centerw-100 mt-[24px]'>
                        <Link to='/crew/forgot-password' className='text-zentive-blue-dark'>
                            {t('Forgot Password?')}
                        </Link>
                    </div>
                )}
                {rememberedEmail !== '' && (
                    <div className='flex items-center justify-centerw-100 mt-[24px]'>
                        <p>
                            {t('Not you?')}{' '}
                            <button
                                className='text-zentive-green-dark'
                                onClick={handleForgetRemember}
                            >
                                {t('Use Another Account')}
                            </button>
                        </p>
                    </div>
                )}
                <LanguagePicker />
            </form>
        </Form>
    )
}

export default CrewSignInForm
