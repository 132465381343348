import { Tabs, TabsList, TabsTrigger } from '@radix-ui/react-tabs'
import BreadCrumbs from '@/components/BreadCrumbs'
import { useAtom, useAtomValue } from 'jotai'
import { BREADCRUMBS_PADDING_STYLE, FEATURE_RESTRICTIONS } from '@/constants'
import { cn } from '@/utils/helper'
import { selectedJobLogTabAtom } from '@/store/jobLog'
import JobLogInfo from './JobLogInfo'
import JobLogNotes from './JobLogNotes'
import JobLogGallery from './JobLogGallery'
import JobLogSettings from './JobLogSettings'
import { useQuery } from '@tanstack/react-query'
import { getJobById } from '@/api/job'
import { useParams } from 'react-router-dom'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import { useState } from 'react'
import FeatureRestrictionModal from '@/components/FeatureRestrictionModal'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { FullProfileType } from '@/api/profile/schema'
import { UpgradeBadge } from '@/components/UpgradeBadge'

const ManageJobLogs = () => {
    const [selected, setSelected] = useAtom(selectedJobLogTabAtom)
    const [prev, setPrev] = useState<string>('INFO')
    const { jobId } = useParams()
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const [restrictionModal, setRestrictionModal] = useState<boolean>(false)

    const { data: job } = useQuery({
        queryKey: ['jobById', jobId],
        queryFn: () => getJobById(jobId ?? ''),
    })

    const { data: isFeatureAvailable, isLoading: featureLoading } = useQuery<boolean>({
        queryKey: ['jobLogFeatureRestriction', user?.profileId],
        queryFn: () =>
            checkSubscriptionFeatureRestriction(
                user?.profileId as string,
                FEATURE_RESTRICTIONS.jobLogImages,
            ),
    })

    const handleChange = (val: string) => {
        if (val !== 'GALLERY') {
            setSelected(val)
            setPrev(val)
        }
    }

    const serviceName = job?.quote?.productServiceGroup?.[0]?.name

    return (
        <div className={cn('w-full', BREADCRUMBS_PADDING_STYLE)}>
            <div className='flex flex-row'>
                <BreadCrumbs titleName={serviceName} pathId={jobId} label={serviceName} />
                {!featureLoading && !isFeatureAvailable && (
                    <span>
                        <UpgradeBadge />
                    </span>
                )}
            </div>

            <Tabs defaultValue={'INFO'} onValueChange={(val) => handleChange(val)}>
                <TabsList className='w-full flex flex-row mt-[26px] space-x-4 bg-white rounded-md'>
                    <TabsTrigger
                        value='INFO'
                        className={cn(
                            'w-1/3 h-[54px] text-[18px] text-zentive-gray-medium sm:truncate ...',
                            {
                                'text-indigo-900 border-zentive-blue-dark font-bold border-b-4':
                                    selected === 'INFO',
                            },
                        )}
                    >
                        Job Log Information
                    </TabsTrigger>
                    <TabsTrigger
                        value='NOTES'
                        className={cn(
                            'w-1/3 h-[54px] text-[18px] text-zentive-gray-medium sm:truncate ...',
                            {
                                'text-indigo-900 border-zentive-blue-dark font-bold border-b-4':
                                    selected === 'NOTES',
                            },
                        )}
                    >
                        Notes
                    </TabsTrigger>
                    <TabsTrigger
                        value='GALLERY'
                        className={cn(
                            'w-1/3 h-[54px] text-[18px] text-zentive-gray-medium sm:truncate ...',
                            {
                                'text-indigo-900 border-zentive-blue-dark font-bold border-b-4':
                                    selected === 'GALLERY',
                            },
                        )}
                        onClick={() => {
                            if (!featureLoading) {
                                setRestrictionModal(!isFeatureAvailable)
                                if (isFeatureAvailable) {
                                    setSelected('GALLERY')
                                } else {
                                    setSelected(prev)
                                }
                            }
                        }}
                    >
                        Gallery
                    </TabsTrigger>
                    <TabsTrigger
                        value='SETTINGS'
                        className={cn(
                            'w-1/3 h-[54px] text-[18px] text-zentive-gray-medium sm:truncate ...',
                            {
                                'text-indigo-900 border-zentive-blue-dark font-bold border-b-4':
                                    selected === 'SETTINGS',
                            },
                        )}
                    >
                        Settings
                    </TabsTrigger>
                </TabsList>
            </Tabs>
            <div className=''>
                {
                    {
                        INFO: <JobLogInfo jobId={job?.jobId as string} />,
                        NOTES: <JobLogNotes job={job} />,
                        GALLERY: <JobLogGallery job={job} />,
                        SETTINGS: <JobLogSettings />,
                    }[selected]
                }
            </div>
            <FeatureRestrictionModal open={restrictionModal} setOpen={setRestrictionModal} />
        </div>
    )
}
export default ManageJobLogs
