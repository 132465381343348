import { Card, CardContent, CardFooter } from '@/components/Card'
import { crewListAtom, crewMemberProfileIdsAtom, selectedCrewForeManAtom } from '@/store/employee'
import { useAtom, useSetAtom } from 'jotai'
import { FC, useState } from 'react'
import { Button } from '@/components/Button'
import { FaPlus } from 'react-icons/fa'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import CrewDetailFields from './CrewFields'
import AddCrewTable from '../CrewMemberTable'
import AddForemanDropDown from './AddForeManDropdown'
import CrewButtons from './CrewButtons'
import AddCrewMemberModal from './CrewModals/AddCrewMemberModal'
import { CiTrash } from 'react-icons/ci'
import { useFormContext } from 'react-hook-form'
import RemoveCrewMemberRestrictionModal from '../CrewModals/RemoveCrewRestrictionModal'
import { useNavigate } from 'react-router-dom'
import { crewMembersToRemoveAtom } from '@/store/crew'
import { getAllAssignedCrewMembers } from '@/api/crew'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useToast } from '@/hooks/useToast'

export type ActionMode = 'create' | 'update'

type BaseCrewProps = {
    isUpdated?: boolean
    mode: ActionMode
    isValid: boolean
    isSubmitting?: boolean
}

const BaseCrew: FC<BaseCrewProps> = ({ isUpdated = true, mode, isValid, isSubmitting }) => {
    const { toast } = useToast()
    const navigate = useNavigate()
    const { watch, setValue } = useFormContext()
    const setSelectedItem = useSetAtom(selectedCrewForeManAtom)
    const [crewListData, setCrewListData] = useAtom(crewListAtom)
    const [crewMemberProfileIds, setCrewMemberProfileIds] = useAtom(crewMemberProfileIdsAtom)
    const [crewMembersToRemove, setCrewMembersToRemove] = useAtom(crewMembersToRemoveAtom)

    const [isAddCrewModalOpen, setIsAddCrewModalOpen] = useState<boolean>(false)
    const [isDeleteRestrictionModalOpen, setIsDeleteRestrictionModalOpen] = useState<boolean>(false)

    const { mutate: checkCrewMemberDeletionRestriction } = useMutation<
        unknown,
        AxiosError,
        string[]
    >({
        mutationFn: (data) => getAllAssignedCrewMembers(data),
    })

    const addCrewModalOnClose = () => {
        setIsAddCrewModalOpen(false)
    }

    const handleRemoveCrew = () => {
        if (crewListData && crewListData?.content && mode === 'update') {
            checkCrewMemberDeletionRestriction(crewMemberProfileIds, {
                onSuccess: () => {
                    if (
                        crewListData?.content?.length > 1 &&
                        crewMembersToRemove?.profileIds?.length !== crewListData?.content?.length &&
                        crewMembersToRemove?.profileIds?.length < crewListData?.content?.length
                    ) {
                        const updatedCrewListData = crewListData?.content?.filter((crewMember) => {
                            if (crewMembersToRemove?.profileIds.includes(crewMember?.profileId)) {
                                if (watch().foreManId === crewMember?.profileId) {
                                    setValue('foreManId', '')
                                }
                                return false
                            }
                            return true
                        })
                        setCrewListData({ ...crewListData, content: updatedCrewListData })
                        const newCrewMemberProfileIds =
                            crewListData &&
                            crewListData?.content
                                ?.map((crewMember) => crewMember?.profileId)
                                .filter(
                                    (profileId) =>
                                        !crewMembersToRemove?.profileIds?.includes(profileId),
                                )
                        setCrewMemberProfileIds(newCrewMemberProfileIds)
                        setValue('memberProfileIds', newCrewMemberProfileIds)
                        setIsDeleteRestrictionModalOpen(false)
                    }
                },
                onError: () => {
                    toast({
                        description:
                            'Unable to remove crew members, some of the selected members are still assigned to jobs',
                        variant: 'destructive',
                    })
                },
            })
        } else if (crewListData && crewListData.content && mode === 'create') {
            const updatedCrewListData = crewListData?.content?.filter((crewMember) => {
                if (crewMembersToRemove.profileIds.includes(crewMember?.profileId)) {
                    if (watch()?.foreManId === crewMember?.profileId) {
                        setValue('foreManId', '')
                    }
                    return false
                }
                return true
            })
            setCrewListData({ ...crewListData, content: updatedCrewListData })
            const newCrewMemberProfileIds =
                crewListData &&
                crewListData?.content
                    ?.map((crewMember) => crewMember?.profileId)
                    .filter((profileId) => !crewMembersToRemove.profileIds.includes(profileId))
            setCrewMemberProfileIds(newCrewMemberProfileIds)
            setValue('memberProfileIds', newCrewMemberProfileIds)
            setIsDeleteRestrictionModalOpen(false)
        }
        setCrewMembersToRemove({ profileIds: [] })
    }

    const isDirty = () => {
        if (!crewMembersToRemove.profileIds?.length) return true
        return false
    }

    const handleCancel = () => {
        setValue('foreManId', '')
        navigate('/schedule/crew')
        setCrewListData(null)
        setSelectedItem(null)
        setCrewMemberProfileIds([])
    }

    return (
        <div className='w-full 2xl:max-w-[1500px] xl:max-w-[1024px]'>
            <CardContent className='bg-white w-full shadow-lg'>
                <CrewDetailFields mode={mode} profileStatus={'ACT'} />
                <h1 className='text-zentive-green-dark mt-10 font-semibold text-lg'>
                    Crew Members
                </h1>
                {crewListData?.content && crewListData?.content?.length >= 1 && (
                    <div>
                        <div className='flex justify-between mx-6'>
                            <AddForemanDropDown />
                            <div className='mt-12'>
                                <Button
                                    type={'button'}
                                    variant='ghost'
                                    disabled={isDirty()}
                                    className='text-zentive-green-dark px-8 py-6  shadow-md border border-zentive-green-dark font-semibold mr-5'
                                    onClick={handleRemoveCrew}
                                >
                                    <CiTrash className='w-4 h-4 mr-2' />
                                    Delete
                                </Button>
                                <Button
                                    type={'button'}
                                    variant='ghost'
                                    className='px-8 py-6 justify-center text-zentive-green-dark border border-zentive-green-dark shadow-md'
                                    onClick={() => setIsAddCrewModalOpen(true)}
                                >
                                    <FaPlus className='w-4 h-4 mr-2 text-zentive-green-dark' />
                                    Add Crew Member
                                </Button>
                            </div>
                        </div>
                        <AddCrewTable />
                    </div>
                )}

                {!crewListData?.content?.length && (
                    <div className='bg-white w-auto rounded-sm'>
                        <div className='mt-4 w-[1260px]'> </div>
                        <div className='flex justify-center'>
                            <img
                                src={emptyPlaceholder}
                                alt='empty-table-placeholder'
                                className='my-12 h-60'
                            />
                        </div>
                        <p className='pb-4 text-center text-2xl text-gray-900'>Currently Empty</p>
                        <div className='flex justify-center'>
                            <Button
                                type={'button'}
                                variant='ghost'
                                className='px-8 py-6 my-4 justify-center text-zentive-green-dark border border-zentive-green-dark shadow-md'
                                onClick={() => {
                                    setCrewListData(null)
                                    setIsAddCrewModalOpen(true)
                                }}
                            >
                                <FaPlus className='w-4 h-4 mr-2 text-zentive-green-dark' />
                                Add Crew Member
                            </Button>
                        </div>
                    </div>
                )}
            </CardContent>
            <Card className='w-full text-center'>
                <CardFooter className='lg:flex lg:justify-end rounded-b-md bg-[#EBEBEB] '>
                    <CrewButtons
                        isUpdated={isUpdated}
                        isValid={isValid}
                        //isAvatarChanged={isAvatarChanged}
                        isSubmitting={isSubmitting}
                        isInactive={false}
                        mode={mode}
                        isDescriptionEmpty={
                            watch().description === '' ||
                            watch().description === null ||
                            watch().description === undefined
                        }
                        isForeManEmpty={
                            watch().foreManId === '' ||
                            watch().foreManId === null ||
                            watch().foreManId === undefined
                        }
                        handleCancel={handleCancel}
                    />
                </CardFooter>
            </Card>
            <AddCrewMemberModal isOpen={isAddCrewModalOpen} onClose={addCrewModalOnClose} />
            <RemoveCrewMemberRestrictionModal
                open={isDeleteRestrictionModalOpen}
                setOpen={setIsDeleteRestrictionModalOpen}
            />
        </div>
    )
}

export default BaseCrew
