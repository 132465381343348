import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import CreateCrew from './AddCrew'
import { cn } from '@/utils/helper'

const CreateCrewView = () => {
    return (
        <div className={cn('max-w-[1700px] h-full', BREADCRUMBS_PADDING_STYLE)}>
            <CreateCrew />
        </div>
    )
}
export default CreateCrewView
