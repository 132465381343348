export interface ISubscription {
    features: IOffers[]
}

export interface IOffers {
    feature: string
    starter?: string
    standard?: string
    pro?: string
    tooltip?: string
}

export type SubscriptionInterval = 'month' | 'year'

const HEADER_CLASSNAME_1 = 'w-[50%] text-white py-[20px] pl-[26px]'
const HEADER_CLASSNAME_2 = 'w-[160px] text-center text-white'
const GALLERY_TAB_CLASSNAME =
    'data-[state=active]:bg-transparent data-[state=active]:rounded-full data-[state=active]:text-white data-[state=inactive]:bg-white data-[state=inactive]:rounded-full data-[state=inactive]:text-gray-900 text-[16px]'

type GalleryTitle = {
    className: string
    title: string
}

export const SIGN_UP_GALLERY_HEADER_TITLES: GalleryTitle[] = [
    {
        className: HEADER_CLASSNAME_1,
        title: 'Zentive Feature',
    },
    {
        className: HEADER_CLASSNAME_2,
        title: 'Starter',
    },
    {
        className: HEADER_CLASSNAME_2,
        title: 'Standard',
    },
    {
        className: HEADER_CLASSNAME_2,
        title: 'Growth Pro',
    },
]

type GalleryTab = GalleryTitle & {
    value: SubscriptionInterval
}

export const SIGN_UP_GALLERY_TABS: GalleryTab[] = [
    {
        className: GALLERY_TAB_CLASSNAME,
        title: 'Monthly',
        value: 'month',
    },
    {
        className: GALLERY_TAB_CLASSNAME,
        title: 'Annually',
        value: 'year',
    },
]

export const MONTHLY: ISubscription = {
    features: [
        { feature: 'Price', starter: '$64/mo', standard: '$119/mo', pro: '$219/mo' },
        { feature: 'Set-Up Fee', starter: '$500', standard: '$500', pro: '$500' },
    ],
}

export const ANNUALLY: ISubscription = {
    features: [
        { feature: 'Price', starter: '$51/mo', standard: '$95/mo', pro: '$175/mo' },
        { feature: 'Set-Up Fee', starter: '$500', standard: '$500', pro: '$500' },
    ],
}

export const SIGN_UP_GALLERY: Record<SubscriptionInterval, ISubscription> = {
    month: MONTHLY,
    year: ANNUALLY,
}
