import { Checkbox } from '@/components/Checkbox'
import { Label } from '@/components/Label'
import { useAtom } from 'jotai'
import { rememberMeAtom } from '@/store/auth'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

const RememberMe = () => {
    const { t } = useTranslation(['sign-in'])
    const { pathname } = useLocation()
    const [rememberMe, setRememberMe] = useAtom(rememberMeAtom)

    const handleCheckedChange = () => setRememberMe(!rememberMe)

    return (
        <div className='flex items-center'>
            <Checkbox
                className='h-5 w-5 focus:ring-indigo-500 border-zentive-green-dark border-2 rounded-sm'   
                checked={rememberMe}
                onCheckedChange={handleCheckedChange}
            />

            <Label className='ml-2 text-base text-gray-900' htmlFor='remember-me'>
                {pathname === '/crew/sign-in' ? t('Remember Me') : 'Remember Me'}
            </Label>
        </div>
    )
}

export default RememberMe
