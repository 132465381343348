import { useState, useEffect, useCallback } from 'react'
import { GoogleMap, PolylineF } from '@react-google-maps/api'
import Marker from './Marker'
import { CustomerProfileType } from '@/api/profile/schema'
import { mapBoundsAtom } from '@/store/location'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { isLandmarkShownAtom, currentLocationAtom } from '@/store/route'
import polyline from '@mapbox/polyline'
import { useMutation } from '@tanstack/react-query'
import { getCurrentLocation } from '@/api/routing'
import AnimatedMarker from './GoogleMarker'
import { useParams } from 'react-router-dom'
import useRoutePolyline from '@/hooks/useRoutePolyline'

const CONTAINER_STYLE = {
    borderRadius: '8px',
    width: '100%',
    height: '100%',
}

const MAP_OPTIONS = {
    keyboardShortcuts: false,
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: true,
    zoomControl: true,
}

const MINUTE_MS = 2000

const Map = () => {
    const { routeId } = useParams()
    const [map, setMap] = useState<google.maps.Map | null>(null)
    const [activeMarker, setMarker] = useState<string | null>(null)
    const [isMounted, setIsMounted] = useState<boolean>(false)
    const [previousBounds, setPreviousBounds] = useAtom(mapBoundsAtom)
    const [currentLocation, setCurrentLocation] = useState<google.maps.LatLngLiteral | null>(null)
    const isLandmarkShown = useAtomValue(isLandmarkShownAtom)

    const setCurrentLoc = useSetAtom(currentLocationAtom)

    const { data, isLoading, isSuccess } = useRoutePolyline(routeId as string)

    const coordinates = data?.customers
    const foremanLat = data?.foremanLat
    const foremanLng = data?.foremanLat
    const polylines = data?.encodedPolyline
        ? polyline.decode(data.encodedPolyline)?.map(([lat, lng]) => ({ lat, lng }))
        : []

    useEffect(() => {
        if (foremanLat && foremanLng && routeId) {
            const interval = setInterval(() => {
                getForemanLocation(routeId)
            }, MINUTE_MS)

            return () => clearInterval(interval)
        }
    }, [foremanLat, foremanLng, routeId])

    const { mutate: getForemanLocation } = useMutation({
        mutationFn: (data: string) => getCurrentLocation(data),
        onSuccess: (res) => {
            const newLocation = { lat: res.foremanLat, lng: res.foremanLng }
            setCurrentLoc(res)
            setCurrentLocation(newLocation)
        },
    })

    const handleLoad = useCallback((mapInstance: google.maps.Map) => {
        setMap(mapInstance)
    }, [])

    useEffect(() => {
        setIsMounted(true)
        return () => setMap(null)
    }, [])

    useEffect(() => {
        if (coordinates!?.length > 0 && map) {
            const bounds = new google.maps.LatLngBounds()
            coordinates!.forEach((coord: CustomerProfileType) => {
                bounds.extend(
                    new google.maps.LatLng(
                        coord.address.gmapLat as number,
                        coord.address.gmapLng as number,
                    ),
                )
            })

            setPreviousBounds(bounds)
        }
    }, [coordinates, map])

    useEffect(() => {
        if (!isMounted || !map || coordinates === undefined || !isSuccess) return

        const bounds = new google.maps.LatLngBounds()

        coordinates.forEach((coord: CustomerProfileType) => {
            bounds.extend(
                new google.maps.LatLng(
                    coord.address.gmapLat as number,
                    coord.address.gmapLng as number,
                ),
            )
        })

        if (!coordinates?.length && previousBounds) {
            map.fitBounds(previousBounds)
        } else {
            map.fitBounds(bounds)
            setPreviousBounds(bounds)
        }

        if (coordinates?.length === 1) {
            map.setZoom(10)
        }
    }, [coordinates, isMounted, isSuccess, map])

    if (!isLoading && isMounted) {
        const modifiedMapOptions = {
            ...MAP_OPTIONS,
            styles: [
                {
                    featureType: 'poi',
                    stylers: [
                        {
                            visibility: isLandmarkShown ? 'on' : 'off',
                        },
                    ],
                },
            ],
        }

        return (
            <div className='relative w-full h-[650px]'>
                <GoogleMap
                    mapContainerStyle={CONTAINER_STYLE}
                    onLoad={handleLoad}
                    options={modifiedMapOptions}
                >
                    {currentLocation && <AnimatedMarker position={currentLocation} />}

                    {coordinates?.map((coord, index) => {
                        return (
                            <Marker
                                activeMarker={activeMarker}
                                coordinate={coord}
                                key={coord.profileId}
                                position={{
                                    lat: coord.address.gmapLat as number,
                                    lng: coord.address.gmapLng as number,
                                }}
                                setMarker={setMarker}
                                text={`${index + 1}`}
                            />
                        )
                    })}

                    {polylines?.length > 0 && (
                        <PolylineF
                            path={polylines}
                            options={{
                                strokeColor: '#FF0000',
                                strokeOpacity: 1.0,
                                strokeWeight: 2,
                                icons: [
                                    {
                                        icon: {
                                            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                            scale: 2,
                                            strokeColor: '#FF0000',
                                            fillOpacity: 1,
                                            fillColor: '#FF0000',
                                        },
                                        offset: '0%',
                                        repeat: '100px',
                                    },
                                ],
                            }}
                        />
                    )}
                </GoogleMap>
            </div>
        )
    }

    return null
}

export default Map
