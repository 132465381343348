import { ProfileBusinessType } from '@/api/business/schema'
import { getAllSubscribers } from '@/api/profile'
import { BulkProfileUpdateStatusType } from '@/api/profile/schema'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import { Checkbox } from '@/components/Checkbox'
import SearchBar from '@/components/InputSearchBox'
import { Pagination } from '@/components/Pagination'
import { PaginationType } from '@/components/Pagination/schema'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { BREADCRUMBS_PADDING_STYLE, USER_STATUS } from '@/constants'
import { manageSubsAtom, manageSubsTabAtom } from '@/store/manageSubs'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import RestoreModal from './RestoreModal'

/* The component below is from the "Subscribers" folder,
this can be move in "ManageSubscription" parent folder
since both "Subscribers" & "Trash" folders are using it */
// import NoteListModal from '../Subscribers/SubscriberTableContents/TableBody/NoteModal'
import Tooltip from '@/components/Tooltip'
import BreadCrumbs from '@/components/BreadCrumbs'
import AppSkeletonLoadingState from '@/components/LoadingState/TableLoadingState'
import { cn } from '@/utils/helper'
import NoteModal from '@/components/NotesModal'

const Trash = () => {
    const [profilesToUpdate, setProfilesToUpdate] = useState<BulkProfileUpdateStatusType>({
        profileIds: [],
    })

    const [keyWord, setKeyWord] = useState<string>('')
    const [subscribersListData, setSubscribersListData] = useAtom(manageSubsAtom)
    const [, setSubStatus] = useAtom(manageSubsTabAtom)
    const [open, setOpen] = useState<boolean>(false)
    const [isCheckAll, setIsCheckAll] = useState<boolean>(false)
    const queryClient = useQueryClient()

    const [pagination, setPagination] = useState<PaginationType>({
        page: 1,
        pageSize: 5,
    })

    const { isLoading: loading } = useQuery({
        queryKey: ['subsribersList', pagination, keyWord, open],
        queryFn: () => getAllSubscribers(keyWord, 'DEL', '', pagination),
        select: (data) => {
            setSubscribersListData(data)
        },
    })

    const handleSearch = (query: string) => {
        setKeyWord(query)
        if (query === '') {
            getAllSubscribers('', 'DEL', '', pagination)
                .then((data) => {
                    setSubscribersListData(data)
                })
                .catch((error) => {
                    console.error('Error fetching subscribers:', error)
                })
        }
    }

    const handleCheckClick = (subscriber: ProfileBusinessType, isCheck: boolean) => {
        if (isCheck) {
            const updatedProfileIds = [...profilesToUpdate.profileIds, subscriber.profileId]
            setProfilesToUpdate({
                ...profilesToUpdate,
                profileIds: updatedProfileIds,
            })
            setIsCheckAll(updatedProfileIds?.length === subscribersListData?.content?.length)
        } else {
            const updatedProfileIds = profilesToUpdate.profileIds.filter(
                (id) => id !== subscriber.profileId,
            )

            setProfilesToUpdate({
                ...profilesToUpdate,
                profileIds: updatedProfileIds,
            })
            setIsCheckAll(false)
        }
    }

    // Set the status to 'DEL' on render
    useEffect(() => {
        setSubStatus(USER_STATUS.DELETED)
    }, [])

    const handleCheckAll = (checked: boolean) => {
        setIsCheckAll(!isCheckAll)
        if (subscribersListData && checked) {
            const updatedProfileIds = [...profilesToUpdate.profileIds]
            subscribersListData.content.forEach((subscriber) => {
                updatedProfileIds.push(subscriber.profileId)
            })
            setProfilesToUpdate({
                ...profilesToUpdate,
                profileIds: updatedProfileIds,
            })
        } else {
            setProfilesToUpdate({
                profileIds: [],
            })
        }
    }

    useEffect(() => {
        setSubscribersListData(null)
    }, [])

    useEffect(() => {
        if (open && subscribersListData && subscribersListData?.content?.length > 0) {
            const intervalId = setInterval(() => {
                queryClient.invalidateQueries({ queryKey: ['subsribersList'] })
            }, 1000)
            return () => clearInterval(intervalId)
        } else {
            setOpen(false)
        }
    }, [open, subscribersListData])

    return (
        <div className={cn('flex=col justify-center', BREADCRUMBS_PADDING_STYLE)}>
            <BreadCrumbs titleName='Deleted Subscribers' />
            <div>
                <div className='mt-10 flex justify-between'>
                    <div>
                        <SearchBar
                            placeHolder='Search for...'
                            onSearchChange={(e) => handleSearch(e.target.value)}
                        />
                    </div>
                    <div>
                        {subscribersListData && subscribersListData?.content?.length > 0 && (
                            <RestoreModal
                                profilesToUpdate={profilesToUpdate}
                                setOpen={setOpen}
                                onSuccessRestore={() =>
                                    setProfilesToUpdate({
                                        profileIds: [],
                                    })
                                }
                            />
                        )}
                    </div>
                </div>

                {!loading && !!subscribersListData?.content?.length && (
                    <div className='bg-white mt-[26px]'>
                        <div className='overflow-x-auto green-scrollbar'>
                            <Table className='table-auto whitespace-normal mx-auto'>
                                <TableHeader>
                                    <TableRow className='whitespace-nowrap'>
                                        <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                            <label>
                                                <Checkbox
                                                    id={'business-name'}
                                                    className='mr-[5px]'
                                                    onCheckedChange={handleCheckAll}
                                                    checked={isCheckAll}
                                                ></Checkbox>
                                                Business Name
                                            </label>
                                        </TableHead>
                                        <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                            Subscriber
                                        </TableHead>
                                        <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                            Email Address
                                        </TableHead>
                                        <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                            Address
                                        </TableHead>
                                        <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                            Contact Number
                                        </TableHead>
                                        <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                            Status
                                        </TableHead>
                                        <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                            Registered On
                                        </TableHead>
                                        <TableHead
                                            key='Note'
                                            className='font-semibold text-zentive-gray-medium text-base'
                                        >
                                            Note
                                        </TableHead>
                                    </TableRow>
                                </TableHeader>
                                {!loading && (
                                    <TableBody>
                                        {subscribersListData?.content?.map((data) => (
                                            <TableRow
                                                key={data.business.businessName}
                                                className='whitespace-nowrap'
                                            >
                                                <TableCell className=' text-[#191A0A] text-base'>
                                                    <Checkbox
                                                        id={
                                                            'business-name' +
                                                            ' ' +
                                                            data.business.businessName
                                                        }
                                                        checked={profilesToUpdate?.profileIds?.some(
                                                            (p) => p === data.profileId,
                                                        )}
                                                        className='mr-[5px] mt-[2px]'
                                                        onCheckedChange={(val) =>
                                                            handleCheckClick(data, val as boolean)
                                                        }
                                                    ></Checkbox>
                                                    {data.business.businessName}
                                                </TableCell>
                                                <TableCell className=' text-[#191A0A] text-base'>
                                                    {data.firstName + ' ' + data.lastName}
                                                </TableCell>

                                                <TableCell className=' text-[#191A0A] text-base'>
                                                    {data.email}
                                                </TableCell>
                                                <TableCell className=' text-[#191A0A] text-base'>
                                                    {data.address.streetAddress}
                                                </TableCell>
                                                <TableCell className=' text-[#191A0A] text-base'>
                                                    {data.phoneNumber}
                                                </TableCell>
                                                <TableCell className=' text-[#191A0A] text-base'>
                                                    {data.business.status}
                                                </TableCell>
                                                <TableCell className=' text-[#191A0A] text-base'>
                                                    {dayjs(data.createdAt).format('MMMM DD, YYYY')}
                                                </TableCell>
                                                <TableCell>
                                                    <div className='ml-2'>
                                                        <Tooltip position='bottom' content='Notes'>
                                                            <NoteModal profileId={data.profileId} />
                                                        </Tooltip>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                )}
                            </Table>
                        </div>
                        <div className='mb-5 mt-3'>
                            <Pagination
                                itemsPerPage={pagination?.pageSize ?? 0}
                                setPagination={setPagination}
                                totalRecords={subscribersListData?.meta.totalRecords ?? 0}
                                pagination={pagination}
                            />
                        </div>
                    </div>
                )}

                {!!loading && <AppSkeletonLoadingState />}

                {!subscribersListData?.content?.length && !loading && (
                    <div className='bg-white mt-[26px] w-full'>
                        <div className='flex justify-center'>
                            <img
                                src={emptyPlaceholder}
                                alt='empty-table-placeholder'
                                className='my-12 h-60'
                            />
                        </div>
                        <p className='pb-4 text-center text-2xl text-gray-900'> Currently Empty </p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Trash
