import { useQuery } from '@tanstack/react-query'
import { getJobById } from '@/api/job'
import Spinner from '@/components/Spinner'
import { useTranslation } from 'react-i18next'
import BackButtonTopBar from '../../Common/BackButtonTopBar'
import ListOfMaterialsEmptyPlaceHolder from '@/assets/private/empty-list-of-materials.png'
import { useParams } from 'react-router-dom'

const Materials = () => {
    const { jobId } = useParams()
    const { t } = useTranslation(['view-job'])
    const { data: job } = useQuery({
        queryKey: ['jobById', jobId],
        queryFn: () => getJobById(jobId ?? ''),
    })

    if (!job)
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-white'>
                <Spinner variant='normal' size='10' />
            </div>
        )

    return (
        <div className='flex flex-col justify-between items-center'>
            <BackButtonTopBar title={t('List of Materials')} redirectTo={`/crew/job/${jobId}`} />
            {/* Still in progress on owner side - products */}
            {job?.quote?.productServiceGroup
                ?.filter((product) => product?.type === 'product')
                ?.map((details, index) => (
                    <div
                        key={index}
                        className='w-10/12 flex flex-col mx-auto bg-white rounded-sm shadow-md border px-3 py-4'
                    >
                        <div className='flex flex-row justify-between items-center'>
                            <p className='text-sm pr-4'>{details?.name}</p>
                        </div>
                    </div>
                ))}

            {job?.quote?.productServiceGroup?.filter((product) => product?.type === 'product')
                ?.length == 0 && (
                <div className=' px-4 mx-4 my-10'>
                    <div className='flex justify-center bg-white rounded-md'>
                        <img
                            src={ListOfMaterialsEmptyPlaceHolder}
                            alt='empty-table-placeholder'
                            className='h-44'
                        />
                    </div>
                    <p className='pb-4 text-center text-2xl text-white font-semibold mt-4'>
                        {' '}
                        {t('No Materials Listed')}{' '}
                    </p>
                    <p className='pb-4 text-center text-white'>
                        {' '}
                        {t(`You don't have any materials to display`)}{' '}
                    </p>
                </div>
            )}
        </div>
    )
}

export default Materials
