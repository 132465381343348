import SubscriptionSettings from './SubscriptionSettings'
import SubscriptionPlans from './SubscriptionPlans'
import BreadCrumbs from '@/components/BreadCrumbs'
import { useAtom } from 'jotai'
import { userAtom } from '@/store/auth'
import { BREADCRUMBS_PADDING_STYLE, ROLE } from '@/constants'

const OwnerChangePlanGalery = () => {
    const [user] = useAtom(userAtom)
    const isCustomer = user?.role.roleName === ROLE.CUSTOMER

    return (
        <section className={`${BREADCRUMBS_PADDING_STYLE}`}>
            {!isCustomer && (
                <>
                    <BreadCrumbs titleName='Change Plan' />
                    <SubscriptionSettings />
                    <SubscriptionPlans />
                </>
            )}
        </section>
    )
}

export default OwnerChangePlanGalery
