import { useCallback, useState } from 'react'
import { SignUpType } from '@/api/auth/schema'
import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { useFormContext } from 'react-hook-form'
import { useAtomValue } from 'jotai'
import { isAddressSelectedAtom } from '@/store/auth'

type Fields = 'city' | 'state' | 'zipCode'
type Value = string | undefined

const CityStateZipFields = () => {
    const [isEditing, setIsEditing] = useState({
        city: false,
        state: false,
        zipCode: false,
    })

    const { control, watch } = useFormContext<SignUpType>()

    const isAddressSelected = useAtomValue(isAddressSelectedAtom)
    const city = watch('data.city')
    const state = watch('data.state')
    const zipCode = watch('data.zipCode')

    const handleLocationChange = useCallback(
        (field: Fields, value: Value, onChange: (val: Value) => void) => {
            setIsEditing((prev) => ({ ...prev, [field]: true }))
            onChange(value)
        },
        [setIsEditing],
    )

    const isValid = (fieldName: Fields, watchedField: Value) =>
        !isAddressSelected || (isAddressSelected && !isEditing[fieldName] && !!watchedField)

    return (
        <div className='grid grid-cols-2 gap-4 w-full'>
            <div className='col-span-1'>
                <FormField
                    name='data.city'
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    {...field}
                                    disabled={isValid('city', city)}
                                    onChange={(e) =>
                                        handleLocationChange('city', e.target.value, field.onChange)
                                    }
                                    placeholder='City*'
                                    type='text'
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    name='data.state'
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    {...field}
                                    disabled={isValid('state', state)}
                                    onChange={(e) =>
                                        handleLocationChange(
                                            'state',
                                            e.target.value,
                                            field.onChange,
                                        )
                                    }
                                    placeholder='State*'
                                    type='text'
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    name='data.zipCode'
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    {...field}
                                    disabled={isValid('zipCode', zipCode)}
                                    onChange={(e) => {
                                        const value = e.target.value
                                        if (value?.length <= 5) {
                                            return handleLocationChange(
                                                'zipCode',
                                                value,
                                                field.onChange,
                                            )
                                        }
                                    }}
                                    placeholder='ZIP*'
                                    type='text'
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </div>
    )
}
export default CityStateZipFields
