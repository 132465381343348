import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
} from '@/components/AlertDialog'
import DeleteProfileJobRestrictionModal from '@/components/DeleteProfileJobRestrictionModal/indexs'
import { useState } from 'react'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { EmployeeStatusModalProps } from '../../EmployeeSettings'
import { getEmployeeCrewDetails } from '@/api/employee'
import { EmployeeCrewDetailsType } from '@/api/employee/schema'
import { useAtomValue } from 'jotai'
import { profileStatusAtom } from '@/store/customer'
import DeleteForemanRestrictionModal from '@/components/DeleteForemanRestrictionModal'
import { USER_STATUS } from '@/constants'

const DeactivateModal = ({
    handleProfileStatusUpdate,
    onOpenChange,
    open,
}: EmployeeStatusModalProps) => {
    const [openRestrictionModal, setOpenRestrictionModal] = useState<boolean>(false)
    const [openForemanRestrictionModal, setOpenForemanRestrictionModal] = useState<boolean>(false)

    const [crewMemberDetails, setCrewMemberDetails] = useState<EmployeeCrewDetailsType | null>(null)
    const profileStatus = useAtomValue(profileStatusAtom)

    const handleStatusUpdate = async () => {
        try {
            await handleProfileStatusUpdate()
        } catch (err) {
            setOpenRestrictionModal(true)
        }
    }

    const onSubmit = async () => {
        try {
            const empCrewDetails = await getEmployeeCrewDetails(
                profileStatus?.profileId as string,
                true,
            )
            setCrewMemberDetails(empCrewDetails)
            setOpenForemanRestrictionModal(true)
        } catch (err) {
            handleStatusUpdate()
        }
    }

    return (
        <AlertDialog open={open} onOpenChange={onOpenChange}>
            <DeleteProfileJobRestrictionModal
                open={openRestrictionModal}
                setOpen={setOpenRestrictionModal}
                type='employee'
            />
            <DeleteForemanRestrictionModal
                open={openForemanRestrictionModal}
                setOpen={setOpenForemanRestrictionModal}
                crewMemberDetails={crewMemberDetails}
                status={USER_STATUS.INACTIVE}
            />
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-[26.5px] pt-[40px]'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                        <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-[24px] text-[#191A0A] mt-[10px]'>
                            Deactivate Account
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-[14px] text-zentive-gray-medium mt-[18px]'>
                            By deactivating the account, user will temporarily lose access to the
                            application and cannot be assigned to any jobs, unless activated.
                        </AlertDialogDescription>
                        <AlertDialogDescription className='text-left text-[14px] text-zentive-gray-medium mt-[18px]'>
                            Are you sure you want to deactivate this account?
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-[0px] bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[15px] pt-[6px]'>
                    <div className='w-full flex justify-end items-end gap-[16px]'>
                        <AlertDialogCancel className='w-[155px] h-[43px] text-[16px] font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            onClick={onSubmit}
                            className='w-[155px] h-[43px] text-[16px] font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            Deactivate
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
export default DeactivateModal
