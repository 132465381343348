// For imrpovement skeleton quoting table or loading component for quoting table body
import { Table } from '@/components/Table'
import QuotingTableHead from './TableHead'
import QuotingTableBody from './TableBody'
import { QuoteListType } from '@/api/quoting/schema'
import { BulkDeleteQuoteType } from '@/api/quoting/schema'

type QuotingTableContentsProps = {
    quote: QuoteListType | null
    setQuotesToUpdate: React.Dispatch<
        React.SetStateAction<{
            quoteIds: string[]
        }>
    >
    setIsCheckAll: React.Dispatch<React.SetStateAction<boolean>>
    isCheckAll: boolean
    quotesToUpdate: BulkDeleteQuoteType
}

const QuotingTableContents: React.FC<QuotingTableContentsProps> = ({
    quote,
    setIsCheckAll,
    isCheckAll,
    setQuotesToUpdate,
    quotesToUpdate,
}) => {
    return (
        <div className='bg-white overflow-x-auto green-scrollbar'>
            <Table className='table-auto whitespace-normal mx-auto'>
                <QuotingTableHead
                    quote={quote}
                    setQuotesToUpdate={setQuotesToUpdate}
                    setIsCheckAll={setIsCheckAll}
                    isCheckAll={isCheckAll}
                />
                <QuotingTableBody
                    quote={quote}
                    setQuotesToUpdate={setQuotesToUpdate}
                    quotesToUpdate={quotesToUpdate}
                    setIsCheckAll={setIsCheckAll}
                />
            </Table>
        </div>
    )
}

export default QuotingTableContents
