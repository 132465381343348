import { getInvoice } from '@/api/invoicing'
import { useQuery } from '@tanstack/react-query'
import InvoiceHeader from './InvoiceDialog/InvoiceHeader'
import InvoiceContent from './InvoiceDialog/InvoiceContent'
import InvoiceFooter from './InvoiceDialog/InvoiceFooter'

const RespondInvoice = () => {
    const planParam = new URLSearchParams(window.location.search)
    const invoiceId = planParam.get('invoiceId')

    const { data: invoice } = useQuery({
        enabled: !!invoiceId,
        queryKey: ['quote', invoiceId],
        queryFn: () => getInvoice(invoiceId as string),
    })

    if (!invoice) return 'No data'

    return (
        <div className='bg-white shadow-md rounded-lg mx-44 px-10'>
            <InvoiceHeader invoice={invoice} />
            <InvoiceContent invoice={invoice} />
            <InvoiceFooter />
        </div>
    )
}
export default RespondInvoice
