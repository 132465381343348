import React from 'react'

interface DayHeaderProps {
    date: Date
}

const DayHeader: React.FC<DayHeaderProps> = ({ date }) => {
    const today = new Date()
    return (
        <span
            className={`flex ${
                date.getDay() === today.getDay()
                    ? 'bg-zentive-green-dark text-white font-semibold'
                    : 'text-black'
            } justify-center w-full rounded-sm`}
        >
            {date.toLocaleDateString('en-US', { weekday: 'short' })}
        </span>
    )
}

export default DayHeader
