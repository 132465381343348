import { getExpenseAccountsByBusinessId } from '@/api/expense_account'
import { FullProfileType } from '@/api/profile/schema'
import { QuoteType } from '@/api/quoting/schema'
import { Button } from '@/components/Button'
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    DialogPortal,
    DialogTitle,
    DialogTrigger,
} from '@/components/Dialog'
import { Input } from '@/components/Input'
import { Textarea } from '@/components/TextArea'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { expenseAtom, quoteContentAtom } from '@/store/owner'
import { cn, hasMoreThanTwoDecimalPlaces } from '@/utils/helper'
import { Label } from '@radix-ui/react-label'
import { useQuery } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'
import { ChangeEvent, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { DEFAULT_EXPENSE_VALUE } from '@/constants'
import emptyExpensesPlaceholder from '@/assets/private/empty-expenses.png'
import { useNavigate } from 'react-router-dom'
import { isNoSelectedServicesQuotingAtom } from '@/store/financial'

const ExpenseDialog = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const [_, setQuoteContent] = useAtom(quoteContentAtom)
    const [expenses, setExpenses] = useAtom(expenseAtom)
    const isNoSelectedServices = useAtomValue(isNoSelectedServicesQuotingAtom) as boolean
    const [expense, setExpense] = useState(() => DEFAULT_EXPENSE_VALUE)
    const { setValue } = useFormContext<QuoteType>()
    const navigateTo = useNavigate()

    const { data } = useQuery({
        queryKey: ['expenseAccounts', user?.businessId],
        queryFn: () => getExpenseAccountsByBusinessId('', user?.businessId as string),
        select: (res) => res?.content,
    })

    const expenseAccounts = data ?? []

    const handleOnSave = () => {
        setQuoteContent((prevVal) => ({
            ...prevVal,
            totalExpense: prevVal.totalExpense + expense.total,
            subTotal: prevVal.subTotal + expense.total,
        }))

        setValue('expense', [...(expenses ?? []), expense])
        setExpenses((prevExpenses) => [...(prevExpenses ?? []), expense])

        setExpense(DEFAULT_EXPENSE_VALUE)
    }

    const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const expenseAccountName = event.target.value

        setExpense((prevState) => ({
            ...prevState,
            accountName: expenseAccountName,
        }))
    }

    const onCancel = () => setExpense(DEFAULT_EXPENSE_VALUE)

    return (
        <Dialog>
            <DialogTrigger asChild>
                <button 
                    disabled={isNoSelectedServices} 
                    type='button' 
                    className={`${!isNoSelectedServices ? 'text-zentive-green-dark' : 'text-zentive-gray-medium cursor-not-allowed'} font-semibold`}>
                    + Add Expense
                </button>
            </DialogTrigger>
            <DialogPortal>
                <DialogOverlay>
                    <DialogContent className='max-w-[650px] w-[650px] max-h-[650px] h-[600px] rounded-md '>
                        <DialogHeader className='m-6'>
                            <DialogTitle className='mb-2'>
                                <div className='text-[20px] font-semibold text-left mb-6'>
                                    Add Expense Account
                                </div>
                            </DialogTitle>
                            <DialogDescription className='max-h-[350px] no-scrollbar'>
                                <div className='relative z-10 mb-4'>
                                    {!expenseAccounts?.length && expenseAccounts?.length === 0 ? (
                                        <div className='px-4 mx-4'>
                                            <div className='flex justify-center bg-white rounded-md'>
                                                <img
                                                    src={emptyExpensesPlaceholder}
                                                    alt='empty-table-placeholder'
                                                    className='mt-8 h-36'
                                                />
                                            </div>
                                            <p className='pb-4 text-center text-2xl text-zentive-green-dark font-semibold'>
                                                No Expense Yet
                                            </p>
                                            <p className='pb-4 text-center text-zentive-black text-base'>
                                                You have no expenses to display at the moment.
                                            </p>
                                            <Button
                                                className='mt-2 py-6 px-10 font-medium text-xl'
                                                onClick={() => navigateTo('/settings/expense-accounts/add')}
                                            >
                                                Add Expense
                                            </Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <select
                                                name='row'
                                                onChange={handleOnChange}
                                                className='w-full mb-4 flex justify-center items-center font-sans text-base h-11 border border-zentive-gray-medium disabled:bg-gray-100 text-gray-900 focus:border-zentive-green-dark'
                                            >
                                                <option
                                                    key={DEFAULT_EXPENSE_VALUE.accountName}
                                                    value={DEFAULT_EXPENSE_VALUE.accountName}
                                                >
                                                    Select Expense Account
                                                </option>
                                                {expenseAccounts?.map((expenseAccount, index) => (
                                                    <option
                                                        key={index}
                                                        value={expenseAccount.expenseAccountName}
                                                        className='text-lg'
                                                    >
                                                        {expenseAccount.expenseAccountName}
                                                    </option>
                                                ))}
                                            </select>
                                            <Label
                                                className={cn(
                                                    'floating-label bg-white absolute left-1 top-2 -translate-y-1/90 text-base duration-100 ease-linear peer-focus:-translate-y-4 peer-focus:text-sm peer-focus:px-1',
                                                    expense.accountName !== '' ||
                                                        expenseAccounts?.length >= 0
                                                        ? 'text-zentive-green-dark peer-disabled:opacity-100'
                                                        : '',
                                                    expense.accountName !== '' ||
                                                        expenseAccounts?.length >= 0
                                                        ? 'peer-focus:text-zentive-green-dark pointer-events-auto -translate-y-4 text-sm px-1'
                                                        : 'peer-focus:text-zentive-green-dark text-zentive-gray-medium pointer-events-none',
                                                )}
                                            >
                                                Name of Expense
                                            </Label>
                                            <Textarea
                                                className='flex w-full pt-3 max-h-[200px] font-sans text-base h-32 border disabled:bg-gray-100 text-gray-900'
                                                placeholder='Description'
                                                value={expense.description}
                                                onChange={(val) =>
                                                    setExpense((prevState) => ({
                                                        ...prevState,
                                                        description: val.target.value,
                                                    }))
                                                }
                                            />
                                            <div className='relative z-10'>
                                                <Input
                                                    className='w-full mb-4 flex justify-center items-center font-sans text-base h-11 border disabled:bg-gray-100 text-gray-900'
                                                    name='date'
                                                    type='date'
                                                    value={expense.date}
                                                    onChange={(val) =>
                                                        setExpense((prevState) => ({
                                                            ...prevState,
                                                            date: val.target.value,
                                                        }))
                                                    }
                                                />
                                                <Label
                                                    className={cn(
                                                        'floating-label bg-white absolute left-1 top-2 -translate-y-1/90 text-base duration-100 ease-linear peer-focus:-translate-y-4 peer-focus:text-sm peer-focus:px-1',
                                                        'peer-focus:text-zentive-green-dark pointer-events-auto -translate-y-4 text-sm px-1',
                                                        'peer-focus:text-zentive-green-dark text-zentive-gray-medium pointer-events-none',
                                                    )}
                                                >
                                                    Date Expense Incurred
                                                </Label>
                                            </div>
                                            <Input
                                                className='w-full mb-4 flex justify-center items-center font-sans text-base h-11 border disabled:bg-gray-100 text-gray-900 text-left'
                                                placeholder='Total Expense Amount $'
                                                name='total'
                                                type='number'
                                                value={expense.total}
                                                onChange={(e) => {
                                                    let inputValue: string = e.target.value

                                                    if (hasMoreThanTwoDecimalPlaces(inputValue)) return

                                                    setExpense((prevState) => ({
                                                        ...prevState,
                                                        total: parseFloat(inputValue),
                                                    }))
                                                }}
                                                onKeyDown={(e) => {
                                                    if (
                                                        e.key === '-' ||
                                                        e.key === '+' ||
                                                        e.key === 'e' ||
                                                        e.key === 'E'
                                                    ) {
                                                        e.preventDefault()
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                
                            </DialogDescription>
                        </DialogHeader>
                        <DialogFooter className='absolute self-end py-4 px-6 w-full rounded-b-md items-end bg-zentive-gray-light'>
                            <div className='flex flex-row w-[50%] justify-end'>
                                <DialogClose asChild>
                                    <Button
                                        type='button'
                                        variant='outline'
                                        className='w-[50%] mr-4'
                                        onClick={onCancel}
                                    >
                                        Cancel
                                    </Button>
                                </DialogClose>

                                <DialogClose asChild>
                                    <Button
                                        type='submit'
                                        className={`${!expenseAccounts?.length && expenseAccounts?.length === 0 ? 'hidden' : ''} w-[50%]`}
                                        onClick={() => handleOnSave()}
                                        disabled={
                                            expense.accountName == '' ||
                                            expense.description == '' ||
                                            expense.date == '' ||
                                            expense.total == 0
                                                ? true
                                                : false
                                        }
                                    >
                                        Save
                                    </Button>
                                </DialogClose>
                            </div>
                        </DialogFooter>
                    </DialogContent>
                </DialogOverlay>
            </DialogPortal>
        </Dialog>
    )
}

export default ExpenseDialog
