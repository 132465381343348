import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import BreadCrumbs from '@/components/BreadCrumbs'
import 'react-datepicker/dist/react-datepicker.css'
import { useAtomValue } from 'jotai'
import { RouteParamType, routeSchema } from '@/api/routing/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { isViewedAsAtom, viewedAsUserAtom, userAtom } from '@/store/auth'
import RoutingMapBody from './RoutingMapBody'
import { useParams } from 'react-router-dom'

const EditRoute = () => {
    const { routeId } = useParams()
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const methods = useForm<RouteParamType>({
        mode: 'onSubmit',
        resolver: zodResolver(routeSchema),
        defaultValues: { isSavedPermanently: false, businessId: user?.businessId },
    })

    return (
        <FormProvider {...methods}>
            <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
                <BreadCrumbs titleName='Edit Route' />
                <RoutingMapBody routeId={routeId} />
            </div>
        </FormProvider>
    )
}

export default EditRoute
