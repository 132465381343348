import { FC } from 'react'
import { AddLeadFormProps } from '..'
import { FormField, FormItem, FormMessage } from '@/components/Forms'
import InputSelect from '@/components/InputSelect'
import { Label } from '@/components/Label'
import { useQuery } from '@tanstack/react-query'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { getDealStagesByBusinessId } from '@/api/deal_stage'

const DealStage: FC<
    AddLeadFormProps & {
        isOpen: boolean
    }
> = ({ isOpen, useFormMethods }) => {
    const currentUser = useViewedAsUser()
    const { businessId = '' } = currentUser

    const {
        data: dealStages,
        isLoading,
        isSuccess,
    } = useQuery({
        enabled: !!(businessId && isOpen),
        queryKey: ['dealStages', businessId],
        queryFn: () => getDealStagesByBusinessId(businessId),
        select: (res) =>
            res.map((dealStage) => ({
                label: dealStage.label,
                value: dealStage.dealStageId,
            })),
    })

    return (
        <div className='space-y-[16px]'>
            <Label className='text-zentive-gray-medium font-semibold'>Deal Stage</Label>
            <FormField
                name='dealStageId'
                control={useFormMethods.control}
                render={({ field }) => (
                    <FormItem>
                        <InputSelect
                            {...field}
                            options={dealStages ?? []}
                            placeholder={
                                isLoading
                                    ? 'Retrieving Deal Stages'
                                    : isSuccess && dealStages.length === 0
                                      ? 'No Deal Stages found, please create a Deal Stage'
                                      : 'Select Deal Stage*'
                            }
                            disabled={isLoading || (isSuccess && dealStages.length === 0)}
                        />
                        <FormMessage />
                    </FormItem>
                )}
            />
        </div>
    )
}

export default DealStage
