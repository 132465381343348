import { CardTitle } from '@/components/Card'
import { TableRow, TableCell, Table, TableBody } from '@/components/Table'
import ExpenseDialog from './ExpenseDialog'
import ExpenseTableHead from './ExpenseTable/ExpenseHeader'
import { expenseAtom, invoiceContentAtom } from '@/store/owner'
import { useAtom } from 'jotai'
import { CiTrash } from 'react-icons/ci'
import { startTransition, useEffect } from 'react'
import { displayCurrency } from '@/utils/helper'

const Expense = () => {
    const [expenses, setExpenses] = useAtom(expenseAtom)
    const [invoiceContent, setInvoiceContent] = useAtom(invoiceContentAtom)

    const handleOnRemove = (index: number) => {
        if (expenses === null) return
        const updatedExpenses = [...expenses]
        const removedExpense = updatedExpenses.splice(index, 1)[0]
        setExpenses(updatedExpenses)

        if (invoiceContent && invoiceContent.subTotal && removedExpense?.total) {
            const subtotalAfterRemove = invoiceContent.subTotal - removedExpense.total
            setInvoiceContent({ ...invoiceContent, subTotal: subtotalAfterRemove })
        }
    }

    const formatDate = (dateString: string) => {
        const date = new Date(dateString)
        return isNaN(date.getTime())
            ? ''
            : date.toLocaleDateString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
              })
    }

    useEffect(() => {
        if (expenses) {
            startTransition(() => {
                setInvoiceContent((prevVal) => ({
                    ...prevVal,
                    totalExpense: Number(
                        expenses
                            ?.filter((exp) => !exp.isNotIncludedInInvoice)
                            ?.reduce((total, expense) => total + (expense.total || 0), 0),
                    ),
                }))
            })
        }
    }, [expenses])

    const totalExpenses = expenses
        ? expenses
              ?.filter((exp) => !exp.isNotIncludedInInvoice)
              ?.reduce((total, expense) => total + (expense.total || 0), 0)
        : 0

    return (
        <>
            <div className='border border-[#EBEBEB] p-6 my-8'>
                <div className='flex flex-row justify-between w-full mb-6'>
                    <CardTitle className='text-[18px]'>Expenses</CardTitle>
                    <ExpenseDialog />
                </div>
                {!expenses || expenses.length === 0 ? (
                    <div>All additional fees will show up here</div>
                ) : (
                    <Table className='table-auto whitespace-normal mx-auto'>
                        <ExpenseTableHead />
                        <TableBody className='bg-white'>
                            {expenses
                                .filter((exp) => !exp.isNotIncludedInInvoice)
                                ?.map((data, index) => (
                                    <TableRow key={index} className='whitespace-nowrap'>
                                        <TableCell className='text-[#191A0A] text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                            {data.accountName}
                                        </TableCell>
                                        <TableCell className='text-[#191A0A] text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                            {data.description}
                                        </TableCell>
                                        <TableCell className='text-[#191A0A] text-base max-w-[200px] text-ellipsis overflow-hidden'>
                                            {formatDate(data.date)}
                                        </TableCell>
                                        <TableCell className='text-[#191A0A] text-base max-w-[200px] text-ellipsis overflow-hidden'>
                                            {displayCurrency(data.total)}
                                        </TableCell>
                                        <TableCell className='text-[#191A0A] text-base w-10'>
                                            <button
                                                type='button'
                                                onClick={() => handleOnRemove(index)}
                                            >
                                                <CiTrash className='size-7 text-zentive-red-dark' />
                                            </button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                )}
            </div>
            <hr className='border-b border-dashed border-zentive-gray-medium w-full' />
            <div className='flex mt-4'>
                <label className='font-bold text-zentive-gray-medium'>Total of Expenses</label>
                <p className='ml-auto text-zentive-green-dark'>{displayCurrency(totalExpenses)}</p>
            </div>
        </>
    )
}

export default Expense
