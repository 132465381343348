import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

export type FullAddress = {
    streetAddress: string
    city: string
    state: string
    zipCode: string
}

export type GeocodeResult = {
    lat: number
    lng: number
}

export type TimeZoneResult = {
    dstOffset: number
    rawOffset: number
    status: string
    timeZoneId: string
    timeZoneName: string
}

const geocodeApi = 'https://maps.googleapis.com/maps/api/geocode/json'
const timeZoneApi = 'https://maps.googleapis.com/maps/api/timezone/json'

const googleMapsApiKey = import.meta.env.PROD
    ? import.meta.env.VITE_GOOGLE_MAPS_PROD_API_KEY
    : import.meta.env.VITE_GOOGLE_MAPS_DEV_API_KEY

const calculateCenter = (coordinates: GeocodeResult[]): GeocodeResult | undefined => {
    if (coordinates?.length === 0) return undefined

    const total = coordinates.reduce(
        (acc, coord) => {
            acc.lat += coord.lat
            acc.lng += coord.lng
            return acc
        },
        { lat: 0, lng: 0 },
    )

    return {
        lat: total.lat / coordinates?.length,
        lng: total.lng / coordinates?.length,
    }
}

export const fetchCoordinates = async (address: FullAddress): Promise<GeocodeResult | null> => {
    try {
        const formattedAddress = `${address.streetAddress}, ${address.city}, ${address.state}, ${address.zipCode}`

        const response = await axios.get(geocodeApi, {
            params: {
                address: formattedAddress,
                key: googleMapsApiKey,
            },
        })

        if (response.data.status === 'OK') {
            return response.data.results[0].geometry.location
        } else {
            throw new Error(response.data.error_message || 'Geocoding failed')
        }
    } catch (error) {
        console.error('Error during geocoding:', error)
        return null
    }
}

export const fetchTimezone = async (coordinates: GeocodeResult): Promise<TimeZoneResult | null> => {
    try {
        const response = await axios.get(timeZoneApi, {
            params: {
                location: `${coordinates.lat.toString()},${coordinates.lng.toString()}`,
                timestamp: 1331161200,
                key: googleMapsApiKey,
            },
        })

        if (response.data.status === 'OK') {
            return response.data
        } else {
            throw new Error(response.data.error_message || 'Geocoding failed')
        }
    } catch (error) {
        console.error('Error during geocoding:', error)
        return null
    }
}

export const validateAddress = async (address: string) => {
    try {
        const response = await axios.get(geocodeApi, {
            params: {
                address: address,
                key: googleMapsApiKey,
            },
        })
        const data = response.data

        if (data.status === 'OK' && data.results?.length > 0) {
            console.log('Address is valid.', data.results[0].geometry.location)
        } else {
            throw new Error('Invalid geolocation for the provided address.')
        }
    } catch (error) {
        console.error('Error validating address:', error)
        throw error
    }
}

export const useGeocode = (addresses: FullAddress[]) => {
    const queryResult = useQuery({
        enabled: addresses?.length > 0,
        queryKey: ['geocode', JSON.stringify(addresses)],
        queryFn: async () => {
            const results = await Promise.all(addresses?.map(fetchCoordinates))
            return results.filter((result) => result !== null) as GeocodeResult[]
        },
    })

    const coordinates = queryResult.data || []
    const center = calculateCenter(coordinates)

    // Exclude 'data' from the spread to avoid overriding 'coordinates'
    const { data: _data, ...restQueryResult } = queryResult

    return { coordinates, center, ...restQueryResult }
}
