import autoTable from 'jspdf-autotable'
import jsPDF from 'jspdf'
import dayjs from 'dayjs'
import { JobsCompletedByEmployeeExportType } from '@/api/reports/schema'
import { RECORD_PREFIX, US_FORMAT } from '@/constants'
import { displayCurrency, formatRecordNumber } from '@/utils/helper'
import { formatWorkingHours } from '@/utils/time'

export const GenerateJobsCompletedByEmployeePDF = (
    zentiveLogoUrl: string,
    tableData: JobsCompletedByEmployeeExportType,
    businessName: string,
) => {
    const doc = new jsPDF({ orientation: 'landscape' })

    let startY = 10

    const imageHeight = 17
    const imageWidth = 50

    const pageWidth = doc.internal.pageSize.getWidth()
    const centerX = (pageWidth - imageWidth) / 2

    doc.addImage(zentiveLogoUrl, 'JPEG', centerX, startY, imageWidth, imageHeight)

    startY += imageHeight + 10

    doc.setFontSize(16)
    doc.text(businessName + ' Jobs Completed by Employee Report', pageWidth / 2, startY, {
        align: 'center',
    })

    startY += 10

    const head = [
        [
            'Job Number',
            'Employee Name',
            'Job Type',
            'Invoice Amount',
            'Labor Cost',
            'Date Completed',
            'Time Spent',
            'Address',
        ],
    ]

    const body = tableData?.records?.map((data) => [
        RECORD_PREFIX.job + formatRecordNumber(data.jobNumber as number) ?? 'N/A', // Ensure that the value is either string or number
        data.employeeName ?? '',
        data.services.length ? data?.services?.join(',') : '',
        displayCurrency(data?.invoiceAmount ?? 0),
        displayCurrency(data?.laborCost ?? 0),
        dayjs(data?.date).format(US_FORMAT) ?? '',
        formatWorkingHours(data?.timeSpent ?? 0),
        data?.address ?? '',
    ])

    body.push([
        'TOTAL',
        '', // Empty for Job Type
        '', // Empty for Date Completed
        displayCurrency(tableData.totalInvoiceAmount) ?? 0,
        displayCurrency(tableData.totalLaborCost) ?? 0,
        '', // Empty for Paid Via
        formatWorkingHours(tableData.totalTimeSpent) ?? 0,
        '', // Empty for Quote Number
    ])

    autoTable(doc, {
        startY: startY,
        head: head,
        body: body,
        theme: 'striped',
        styles: { fontSize: 8, cellPadding: 2, overflow: 'linebreak' },
        headStyles: { fillColor: [59, 103, 26], textColor: [255, 255, 255], fontSize: 8 },
        didDrawPage: (data) => {
            const pageCount = doc.getNumberOfPages()
            const footerStr = `Page ${data.pageNumber} of ${pageCount}`
            doc.setFontSize(10)
            doc.text(footerStr, data.settings.margin.left, doc.internal.pageSize.getHeight() - 10)
        },
    })

    doc.save('jobs-completed-by-employee.pdf')
}
