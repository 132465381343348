import { FC } from 'react'

type ViewLeadItemProps = {
    name: string
    value: string | undefined | Array<string>
}

const ViewLeadItem: FC<ViewLeadItemProps> = ({ name, value = '' }) => (
    <div className='flex flex-row justify-between'>
        {!!name && <p className='w-1/3'>{name}:</p>}

        {Array.isArray(value) ? (
            <div className='w-full'>
                {value.length ? (
                    value.map((valueName) => <p className='w-full'>{valueName}</p>)
                ) : (
                    <p className='w-full'>No products or services available</p>
                )}
            </div>
        ) : (
            <p className='w-full'>{value}</p>
        )}
    </div>
)

export default ViewLeadItem
