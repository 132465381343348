import { SignUpType } from '@/api/auth/schema'
import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { useFormContext } from 'react-hook-form'

const CouponFields = () => {
    const { control } = useFormContext<SignUpType>()
    return (
        <div className='grid grid-cols-2 gap-4 w-full'>
            <div className='col-span-1'>
                <FormField
                    name='data.stripeCoupon'
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    className='font-sans-pro pl-3  h-11 border border-gray-400'
                                    placeholder='Coupon Code (Optional)'
                                    type='text'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </div>
    )
}
export default CouponFields
