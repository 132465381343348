import { TableCell } from '@/components/Table'
import DeleteExpenseAcountModal from '../DeleteExpenseAccount'
import { ExpenseAccountResponseType } from '@/api/expense_account/schema'
import { FaPencilAlt } from 'react-icons/fa'
import React from 'react'

type ActionButtonProps = {
    expenseAccount: ExpenseAccountResponseType
    setSelectedExpenseAccountId: React.Dispatch<React.SetStateAction<string>>
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ActionButton = ({
    expenseAccount,
    setSelectedExpenseAccountId,
    setIsOpen,
}: ActionButtonProps) => {
    const handleUpdateClick = (expenseAccountId: string) => {
        setIsOpen(true)
        setSelectedExpenseAccountId(expenseAccountId)
    }

    return (
        <TableCell className='flex justify-end mr-5'>
            <div className='flex justify-center space-x-2 mr-10'>
                <DeleteExpenseAcountModal
                    expenseAccountId={expenseAccount.expenseAccountId as string}
                />
               </div>
            <div>
                <FaPencilAlt
                    className='h-4 w-4 cursor-pointer text-indigo-900 mt-2'
                    onClick={() => handleUpdateClick(expenseAccount.expenseAccountId as string)}
                />
            </div>
        </TableCell>
    )
}
export default ActionButton
