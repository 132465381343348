import { Card, CardContent } from '@/components/Card'
import { AxiosError } from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { businessIdAtom, userAtom } from '@/store/auth'
import { QuoteType } from '@/api/quoting/schema'
import { getCustomerQuotes } from '@/api/quoting'
import QuoteTable from '../QuoteTable'
import BreadCrumbs from '@/components/BreadCrumbs'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'

const QuoteContent = () => {
    const user = useAtomValue(userAtom)
    const businessId = useAtomValue(businessIdAtom)

    const { data: quoteList } = useQuery<unknown, AxiosError, QuoteType[]>({
        queryKey: ['customerQuoteList', businessId, user?.profileId],
        queryFn: () => getCustomerQuotes(user?.profileId as string, businessId as string),
    })

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName='Quotes' />
            <Card className='mt-5 w-full' style={{ overflowY: 'auto' }}>
                <CardContent className='p-4 pt-[36px]  overflow-x-scroll md:overflow-x-auto'>
                    <QuoteTable quoteList={quoteList} />
                </CardContent>
            </Card>
        </div>
    )
}
export default QuoteContent
