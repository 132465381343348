import { deleteLeads } from '@/api/lead'
import { Button } from '@/components/Button'
import { Dialog, DialogClose, DialogContent, DialogFooter } from '@/components/Dialog'
import { useToast } from '@/hooks/useToast'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { selectedLeadIdsAtom } from '@/store/lead'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtom } from 'jotai'
import { FC } from 'react'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'

type DeleteLeadModalProps = {
    open: boolean
    onOpenChange: (val: boolean) => void
}

const DeleteLeadModal: FC<DeleteLeadModalProps> = ({ open, onOpenChange }) => {
    const currentUser = useViewedAsUser()
    const queryClient = useQueryClient()
    const [selectedLeadIds, setSelectedLeadIds] = useAtom(selectedLeadIdsAtom)
    const { toast } = useToast()

    const { businessId = '' } = currentUser

    const { mutate, isPending } = useMutation<string, AxiosError>({
        mutationFn: () => deleteLeads(businessId, selectedLeadIds),
        onError: (err) => {
            if (err instanceof AxiosError) {
                toast({ description: err.message, variant: 'destructive' })
                return
            }
            toast({
                description: 'An error occured, please try again later',
                variant: 'destructive',
            })
        },
        onSuccess: (res) => {
            queryClient.invalidateQueries({
                queryKey: ['leadList'],
            })

            onOpenChange(false)
            setSelectedLeadIds([])
            toast({ description: res })
        },
    })

    const handleDelete = () => mutate()

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                id='add-lead'
                className='bg-white max-h-[850px] h-fit w-[688px] rounded-[15px] p-0 overflow-y-auto'
            >
                <div className='pt-[26px] px-[26px] pb-[56px] flex flex-row gap-[16px]'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-16 w-16 rounded-full'>
                        <HiOutlineExclamationTriangle className='h-6 w-6 mx-5  yx-5 text-red-500' />
                    </div>
                    <div>
                        <p className='h-[62px] text-zentive-black text-[24px] font-semibold flex items-center'>
                            Delete Lead
                        </p>
                        <p className='text-[14px] text-zentive-gray-medium'>
                            This action cannot be undone and will permanently remove the lead from
                            your records.
                        </p>
                        <p className='text-[14px] text-zentive-gray-medium mt-[20px]'>
                            Are you sure you want to delete this lead?
                        </p>
                    </div>
                </div>
                <DialogFooter className='bg-zentive-gray-light h-[75px] flex flex-row items-center gap-[13px] px-[25px] justify-end'>
                    <DialogClose asChild>
                        <Button disabled={isPending} type='button' variant='secondary'>
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button onClick={handleDelete} type='button' disabled={isPending}>
                        Delete
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default DeleteLeadModal
