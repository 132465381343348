import BreadCrumbs from '@/components/BreadCrumbs'
import SearchBar from '@/components/InputSearchBox'

import { BREADCRUMBS_PADDING_STYLE, FEATURE_RESTRICTIONS } from '@/constants'
import { cn } from '@/utils/helper'
import {
    ChangeEventHandler,
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react'
import AddLeadModal from './AddLeadModal'
import AddLeadSelection from './AddLeadSelection'
import LeadsTable from './LeadsTable'
import ExportSelection from './ExportSelection'
import { Button } from '@/components/Button'
import { Trash } from 'lucide-react'
import { debounce } from 'lodash'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { getLeads } from '@/api/lead'
import { useQuery } from '@tanstack/react-query'

import FeatureRestrictionModal from '@/components/FeatureRestrictionModal'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import { UpgradeBadge } from '@/components/UpgradeBadge'
import LeadNotFound from '../LeadNotFound'
import { useAtom, useAtomValue } from 'jotai'
import {
    leadSortCriterionAtom,
    leadToUpdateAtom,
    selectedLeadIdsAtom,
    viewedLeadAtom,
} from '@/store/lead'
import ViewLeadDrawer from './ViewLead'
import DeleteLeadModal from './DeleteLeadModal'
import CreateOrUpdateDealStage from './CreateOrUpdateDealStage'
import EditLeadDealStageModal from './EditLeadDealStage'
import { MODAL_VARIANTS } from '@/constants'

export type ModalVariants = (typeof MODAL_VARIANTS)[keyof typeof MODAL_VARIANTS]

const LeadManagement = () => {
    const currentUser = useViewedAsUser()
    const sortCriteria = useAtomValue(leadSortCriterionAtom)
    // const selectedLeads = useAtomValue(selectedLeadsAtom)
    const selectedLeadIds = useAtomValue(selectedLeadIdsAtom)

    const { businessId = '', profileId = '' } = currentUser

    const [viewedLead, setViewedLead] = useAtom(viewedLeadAtom)
    const [modalType, setModalType] = useState<ModalVariants>(MODAL_VARIANTS.NONE)
    const [searchKeyword, setSearchKeyword] = useState<string>('')

    const [leadToUpdate, setLeadToUpdate] = useAtom(leadToUpdateAtom)

    const { data: isFeatureAvailable, isSuccess: isCrmRestrictionSuccess } = useQuery<boolean>({
        enabled: !!profileId,
        queryKey: ['crmRestriction', profileId],
        queryFn: () => checkSubscriptionFeatureRestriction(profileId, FEATURE_RESTRICTIONS.crm),
    })

    const {
        data,
        isLoading,
        isSuccess: isLeadListSuccess,
    } = useQuery({
        enabled: !!businessId,
        queryKey: ['leadList', businessId, searchKeyword, sortCriteria],
        queryFn: () => getLeads(businessId, searchKeyword, sortCriteria),
    })

    const debouncedSetSearchKeyword = useCallback(
        debounce((value: string) => setSearchKeyword(value), 300),
        [],
    )

    const handleSearchChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
        debouncedSetSearchKeyword(evt.target.value)
    }

    const handleOpenchange = (val: boolean) =>
        setModalType((prev) => (val ? prev : MODAL_VARIANTS.NONE))

    useEffect(() => {
        if (viewedLead) {
            setModalType(MODAL_VARIANTS.VIEW)
        }
    }, [viewedLead])

    useEffect(() => {
        if (modalType === MODAL_VARIANTS.VIEW) {
            return () => setViewedLead(null)
        }

        if (modalType === MODAL_VARIANTS.UPDATE) {
            return () => setLeadToUpdate(null)
        }
    }, [modalType, setLeadToUpdate, setViewedLead])

    console.log('leadtoupdate', leadToUpdate)

    return (
        <>
            <AddLeadModal
                open={modalType === MODAL_VARIANTS.CREATE}
                onOpenChange={handleOpenchange}
            />

            <CreateOrUpdateDealStage
                open={modalType === MODAL_VARIANTS.DEAL_STAGE}
                onOpenChange={handleOpenchange}
            />

            <DeleteLeadModal
                open={modalType === MODAL_VARIANTS.DELETE}
                onOpenChange={handleOpenchange}
            />

            <EditLeadDealStageModal
                open={modalType === MODAL_VARIANTS.UPDATE}
                onOpenChange={handleOpenchange}
            />

            <FeatureRestrictionModal
                open={modalType === MODAL_VARIANTS.RESTRICT}
                setOpen={handleOpenchange as Dispatch<SetStateAction<boolean>>}
            />

            <ViewLeadDrawer
                open={modalType === MODAL_VARIANTS.VIEW}
                onOpenChange={handleOpenchange}
            />

            <div className={cn(BREADCRUMBS_PADDING_STYLE, 'flex flex-col gap-[36px]')}>
                <div
                    className={cn(
                        'h-[35px]',
                        isCrmRestrictionSuccess && !isFeatureAvailable
                            ? 'flex flex-row gap-[16px]'
                            : '',
                    )}
                >
                    <BreadCrumbs titleName='Lead Management (CRM)' />
                    {isCrmRestrictionSuccess && !isFeatureAvailable && (
                        <span>
                            <UpgradeBadge />
                        </span>
                    )}
                </div>

                {isCrmRestrictionSuccess && !isFeatureAvailable && (
                    <div className='flex flex-col justify-center items-center bg-white py-[40px] text-center'>
                        <LeadNotFound />
                        <p className='text-zentive-gray-medium text-[18px]'>
                            Looks like you don’t have any leads, yet. <br />
                            Start adding leads by clicking the button below.
                        </p>
                        <AddLeadSelection
                            className='pt-[16px]'
                            onValueChange={setModalType}
                            isFeatureAvailable={!!isFeatureAvailable}
                            value={modalType}
                        />
                    </div>
                )}

                {isCrmRestrictionSuccess && isFeatureAvailable && (
                    <>
                        <div className='flex flex-row justify-between items-center'>
                            <SearchBar
                                onSearchChange={handleSearchChange}
                                placeHolder='Searching for...'
                            />
                            <div className='flex flex-row gap-[12px]'>
                                <Button
                                    className='w-[108px] flex flex-row justify-between px-[20px] disabled:pointer-events-auto disabled:cursor-not-allowed'
                                    disabled={selectedLeadIds.length === 0}
                                    onClick={
                                        selectedLeadIds.length === 0
                                            ? undefined
                                            : () => setModalType(MODAL_VARIANTS.DELETE)
                                    }
                                    type='button'
                                    variant={
                                        selectedLeadIds.length === 0 ? 'outline' : 'destructive'
                                    }
                                >
                                    <Trash className='h-5' />
                                    <p>Delete</p>
                                </Button>
                                <ExportSelection
                                    onValueChange={setModalType}
                                    value={modalType}
                                    disabled={selectedLeadIds.length === 0}
                                />
                                <Button
                                    className='h-[44px] w-[151px] bg-zentive-green-dark font-semibold text-zentive-gray-bg px-[20px] text-sm'
                                    // disabled={selectedLeadIds.length === 0}
                                    onClick={() => setModalType(MODAL_VARIANTS.DEAL_STAGE)}
                                    type='button'
                                    variant='default'
                                >
                                    Add/Edit Label
                                </Button>
                                <AddLeadSelection
                                    isFeatureAvailable={isFeatureAvailable}
                                    onValueChange={setModalType}
                                    value={modalType}
                                />
                            </div>
                        </div>
                        <LeadsTable
                            isLoading={isLoading}
                            isSuccess={isLeadListSuccess}
                            leads={data?.content ?? []}
                            setModalType={setModalType}
                            totalRecords={data?.meta?.totalRecords ?? 0}
                        />
                    </>
                )}
            </div>
        </>
    )
}

export default LeadManagement
