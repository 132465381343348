import { useFieldArray, useFormContext } from 'react-hook-form'
import { InvoiceType } from '@/api/invoicing/schema'
import ProductsAndServicesTableWithForm from '@/pages/common/FinancialManagement/ProductsAndServicesTableWithForm'

const ContentService = () => {
    const { control, getValues } = useFormContext<InvoiceType>()

    const { fields } = useFieldArray({
        control,
        name: 'productServiceGroup',
    })

    const totalServicesCost = fields.reduce((total, _, index) => {
        const cost = getValues(`productServiceGroup.${index}.cost`) as number
        return total + (cost ? cost : 0)
    }, 0)

    return (
        <>
            <ProductsAndServicesTableWithForm />

            <hr className='border-b border-dashed border-zentive-gray-medium w-full mt-4 '></hr>
            <div className='flex mt-4'>
                <label className='font-bold text-zentive-gray-medium'>Total of Services</label>
                <p className='ml-auto text-zentive-green-dark'>${totalServicesCost.toFixed(2)}</p>
            </div>
        </>
    )
}

export default ContentService
