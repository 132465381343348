import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { EmployeeStatusModalProps } from '../../EmployeeSettings'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { FaRegTrashCan } from 'react-icons/fa6'
import { Button } from '@/components/Button'

const RestoreEmployeeModal = ({
    handleProfileStatusUpdate,
    onOpenChange,
    open,
}: EmployeeStatusModalProps) => {
    const handleStatusUpdate = async () => {
        await handleProfileStatusUpdate()
    }

    const onSubmit = async () => {
        handleStatusUpdate()
    }
    return (
        <AlertDialog open={open} onOpenChange={onOpenChange}>
            <AlertDialogTrigger asChild>
                <Button
                    variant='default'
                    onClick={() => onOpenChange(true)}
                    className='px-14 py-6 my-4 justify-center bg-zentive-green-dark border shadow-md text-white hover:none'
                >
                    <FaRegTrashCan className='mr-2' />
                    Restore Account
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-[26.5px] pt-[40px]'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                        <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-[24px] text-[#191A0A] mt-[10px]'>
                            Restore Account
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-[14px] text-zentive-gray-medium mt-[18px]'>
                            By restoring the account, user will regain the account status the user
                            previously have.
                        </AlertDialogDescription>
                        <AlertDialogDescription className='text-left text-[14px] text-zentive-gray-medium mt-[18px]'>
                            Are you sure you want to restore this account?
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-[0px] bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[15px] pt-[6px]'>
                    <div className='w-full flex justify-end items-end gap-[16px]'>
                        <AlertDialogCancel className='w-[155px] h-[43px] text-[16px] font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            onClick={onSubmit}
                            className='w-[155px] h-[43px] text-[16px] font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            Restore
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default RestoreEmployeeModal
