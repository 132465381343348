import { JOB_STATUS, TWENTY_FOUR_HOUR_CLOCK } from '@/constants'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { userAtom } from '@/store/auth'
import { FC } from 'react'
import { ReadJobType } from '@/api/job/schema'

dayjs.extend(duration)

type JobStatusesProps = {
    jobList: ReadJobType[]
}

const JobStatuses: FC<JobStatusesProps> = ({ jobList }) => {
    const { t } = useTranslation(['jobs'])
    const user = useAtomValue(userAtom)

    const today = dayjs()

    const matchedJobs = jobList?.filter((job) =>
        dayjs(job.appointment.startDateTime).isSame(today, 'day'),
    )

    const completedJobsCount =
        matchedJobs.filter((job) => job.status === JOB_STATUS.COMPLETED)?.length ?? 0

    const jobsToCompleteToday = matchedJobs?.length

    const totalToRenderedMinutes =
        matchedJobs
            .filter((job) => dayjs(job.appointment.startDateTime).isSame(today, 'day'))
            .reduce((total, job) => {
                const startDateTime = job?.appointment?.startDateTime
                    ? dayjs(job.appointment.startDateTime)
                    : null
                const endDateTime = job?.appointment?.endDateTime
                    ? dayjs(job.appointment.endDateTime)
                    : null

                if (
                    startDateTime &&
                    endDateTime &&
                    startDateTime.isValid() &&
                    endDateTime.isValid()
                ) {
                    const hours = endDateTime.diff(startDateTime, 'hour', true)
                    return total + hours
                }
                return total
            }, 0) * 60

    const totalRenderedMinutes =
        matchedJobs
            .filter((job) => job.status === JOB_STATUS.COMPLETED)
            .reduce((acc, job) => {
                const matchingCrewMember = job.jobCrewMembers.find(
                    (member) => member?.crewMemberId === user?.crewMemberId,
                )
                return acc + (matchingCrewMember?.totalWorkingHours || 0)
            }, 0) * 60

    return (
        <div className='flex flex-row w-full bg-zentive-green-light py-3'>
            <div className='flex items-center justify-center w-1/2 border-r'>
                <div className='text-center'>
                    <p className='text-xs'>{t('Jobs Completed Today')}</p>
                    <p className='font-semibold'>{`${completedJobsCount ?? 0} / ${
                        jobsToCompleteToday ?? 0
                    }`}</p>
                </div>
            </div>
            <div className='flex items-center justify-center w-1/2'>
                <div className='text-center'>
                    <p className='text-xs'>{t('Hours Completed Today')}</p>
                    <p className='font-semibold'>{`${dayjs
                        .duration(totalRenderedMinutes as number, 'minutes')
                        .format(TWENTY_FOUR_HOUR_CLOCK)} of ${dayjs
                        .duration(totalToRenderedMinutes, 'minutes')
                        .format(TWENTY_FOUR_HOUR_CLOCK)}`}</p>
                </div>
            </div>
        </div>
    )
}

export default JobStatuses
