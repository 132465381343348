import { BulkProfileUpdateStatusType } from '@/api/profile/schema'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { Button } from '@/components/Button'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { bulkProfileStatusRestore } from '@/api/profile'
import { useToast } from '@/hooks/useToast'
import { Dispatch, SetStateAction } from 'react'
import { FaArrowsRotate } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

interface RestoreModalProps {
    profilesToUpdate: BulkProfileUpdateStatusType
    setOpen: Dispatch<SetStateAction<boolean>>
    onSuccessRestore: Dispatch<SetStateAction<BulkProfileUpdateStatusType>>
}

const RestoreModal: React.FC<RestoreModalProps> = ({
    profilesToUpdate,
    setOpen,
    onSuccessRestore,
}) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const navigate = useNavigate()

    const { mutate: restoreProfilesMu, isPending: updateLoading } = useMutation({
        mutationFn: (data: BulkProfileUpdateStatusType) => bulkProfileStatusRestore(data),
        onSuccess: () => {
            navigate('/manage-subs/subscribers')
            queryClient.invalidateQueries({ queryKey: ['subsribersList'] })
            toast({
                description: 'Profiles successfully restored',
                variant: 'default',
            }),
                onSuccessRestore({
                    profileIds: [],
                })
        },
        onError: (err) => console.log(err),
    })

    const isDirty = () => {
        if (!profilesToUpdate.profileIds?.length) return true
        return false
    }

    return (
        <AlertDialog onOpenChange={(o) => setOpen(o)}>
            <AlertDialogTrigger asChild>
                <Button variant={'default'} disabled={isDirty()}>
                    <FaArrowsRotate className='mr-2' /> Restore
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-2xl p-0'>
                <div className='flex gap-5 px-6 pt-10'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-16 w-16 rounded-full'>
                        <HiOutlineExclamationTriangle className='h-6 w-6 mx-5  yx-5 text-red-500' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-2xl text-gray-900 mt-2.5'>
                            Restore Account{' '}
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            By restoring the account, user will regaing the account status the user
                            previously have.
                        </AlertDialogDescription>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            Are you sure you want to restore this account?
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-0 bg-gray-100 rounded-b-4 pr-6 pb-4 pt-1.5'>
                    <div className='w-full flex justify-end items-end gap-4'>
                        <AlertDialogCancel className='w-97 h-11 text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            disabled={updateLoading}
                            onClick={() => {
                                restoreProfilesMu(profilesToUpdate)
                            }}
                            className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-dark/90'
                        >
                            Restore
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default RestoreModal
