import { DialogFooter } from '@/components/Dialog'

const InvoiceDialogFooter = () => {
    return (
        <DialogFooter className='pb-10'>
            <div className='flex justify-center'>
                <div className='row-auto w-full'>
                    <div className='flex font-sans text-2xl justify-center font-semibold pb-2'>
                        <p>Thank You for Trusting Us</p>
                    </div>
                    <p className='font-sans text-xs text-center text-zentive-gray-medium'>
                        Zentive are registered trademarks of Zentive Company.
                    </p>
                    <p className='font-sans text-xs text-center text-zentive-gray-medium'>
                        *EU VAT Directive 2008/8/EC amending directive 2006/112/EC as regards the
                        place of supply of services.
                    </p>
                    <p className='font-sans text-xs text-center text-zentive-gray-medium'>
                        VAT Number ESB93183366. Commercial Registry of Málaga, volume 4994, sheet
                        217, page number MA-113059
                    </p>
                    <p className='font-sans text-xs text-center text-zentive-gray-medium'>
                        Freepik Indian HSN Code: 998439 - Taiwan Tax Serial Number: A10033043
                    </p>
                </div>
            </div>
        </DialogFooter>
    )
}
export default InvoiceDialogFooter
