import { convertImageToBase64 } from '@/utils/helper'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import { useAtomValue } from 'jotai'
import { expenseReportExportAtom, expenseReportTabsAtom } from '@/store/reports'
import { ExpenseReportExportType } from '@/api/reports/schema'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'

import { GenerateExpenseReportPDF } from './GenerateExpenseReportPDF'

const ExpenseReportPDF = () => {
    const exportAtom = useAtomValue(expenseReportExportAtom)

    const selectedTab = useAtomValue(expenseReportTabsAtom)

    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    if (exportAtom === null || !exportAtom?.records?.length) return null

    const handleExportToPDF = async () => {
        convertImageToBase64(zentiveLogo)
            .then((zentiveLogoUrl) => {
                const tableData: ExpenseReportExportType = {
                    records:
                        exportAtom?.records?.map((r) => ({
                            date: r.date ?? '',
                            jobNumber: r.jobNumber ?? 0,
                            jobId: r.jobId ?? '',
                            name: r.name ?? '',
                            description: r.description ?? '',
                            amount: r.amount ?? 0,
                            expenseId: r.expenseId ?? '',
                            note: [],
                        })) ?? [],
                } as ExpenseReportExportType

                GenerateExpenseReportPDF(
                    zentiveLogoUrl,
                    tableData,
                    user?.business?.businessName ?? 'Zentive',
                    selectedTab,
                )
            })
            .catch((err) => console.error('Error converting image to Base64:', err.message))
    }

    return (
        <button
            className='w-[135px] h-[34px] text-gray-800 hover:bg-[#00000029] block px-4 py-2 text-base rounded-t-md'
            onClick={handleExportToPDF}
        >
            Export as PDF
        </button>
    )
}
export default ExpenseReportPDF
