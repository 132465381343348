import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/Select'
import { FC, SelectHTMLAttributes } from 'react'
import { ModalVariants } from '..'
import { MODAL_VARIANTS } from '@/constants'

type AddLeadSelectionProps = SelectHTMLAttributes<HTMLSelectElement> & {
    isFeatureAvailable?: boolean
    value: string
    onValueChange: (val: ModalVariants) => void
}

const DEFAULT_STYLE =
    'h-[44px] w-[151px] bg-zentive-green-dark font-semibold text-zentive-gray-bg px-[20px]'

const AddLeadSelection: FC<AddLeadSelectionProps> = ({
    isFeatureAvailable,
    className,
    onValueChange,
}) => (
    <div className={className}>
        <Select
            onValueChange={onValueChange}
            // Assign empty string to the value to avoid changing the verbiage
            value=''
        >
            <SelectTrigger className={DEFAULT_STYLE}>
                <SelectValue placeholder='Add New Lead' />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    <SelectItem
                        value={isFeatureAvailable ? MODAL_VARIANTS.CREATE : MODAL_VARIANTS.RESTRICT}
                    >
                        Create
                    </SelectItem>
                    <SelectItem
                        value={isFeatureAvailable ? MODAL_VARIANTS.IMPORT : MODAL_VARIANTS.RESTRICT}
                        disabled
                    >
                        Import CSV/Excel
                    </SelectItem>
                </SelectGroup>
            </SelectContent>
        </Select>
    </div>
)

export default AddLeadSelection
