import { Card, CardContent, CardFooter } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import { cn } from '@/utils/helper'
import { FaArrowDownLong } from 'react-icons/fa6'
import { NavLink } from 'react-router-dom'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import {
    customemrMessageListAtom as customerMessageListAtom,
    customerMessageAtom,
    messagesListAtom,
} from '@/store/customer'
import DeleteMessageModal from './Modal'
import { MessageListType } from '@/api/customer/schema'

const tableHeader = ['Name', 'Recipients', 'Message', 'Date', 'Action']

const MessageTable = () => {
    const messageList = useAtomValue(customerMessageListAtom)
    const setMessageData = useSetAtom(customerMessageAtom)
    const [selected, setSelected] = useAtom(messagesListAtom)
    const handleCheckClick = (content: MessageListType[], isCheck: boolean) => {
        setSelected((prev: MessageListType[]) => {
            if (isCheck) {
                return [...prev, ...content]
            } else {
                return prev.filter((sub: MessageListType) => sub.messageId !== content[0].messageId)
            }
        })
    }

    const handleCheckAllClick = (isCheck: boolean) => {
        if (messageList) {
            isCheck ? setSelected(messageList.content ?? []) : setSelected([])
        }
    }

    //The purpose of this function is to extract text content only
    const stripHtmlTags = (html: string) => {
        const tagRegex = /<[^>]*>/g

        return html.replace(tagRegex, ' ').replace(/"/g, '')
    }

    const routeToMessage = (status: string, messageID: string) => {
        console.log(status, messageID)
        if(status === 'draft') {
            console.log(`/customers/customer-messaging/create/draft/${messageID}`)
            return(`/customers/customer-messaging/create/draft/${messageID}`)
        }
        return(`/customers/customer-messaging/${messageID}`)
    }
    
    return (
        <Card className='w-full'>
            <CardContent className='overflow-x-scroll md:overflow-x-auto'>
                <Table>
                    <TableHeader>
                        <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                            {tableHeader?.map((header) => (
                                <TableHead
                                    key={`${header}-head`}
                                    className={cn(
                                        'flex-row items-center font-semibold text-zentive-gray-medium text-base',
                                        header === 'Date' || header === 'Action'
                                            ? 'items-start text-center'
                                            : 'items-center text-left',
                                    )}
                                >
                                    <div className='inline-flex gap-2 relative'>
                                        {header === 'Name' && (
                                            <Checkbox
                                                id={'message-checked-status'}
                                                className='absolute mt-[5px] left-0'
                                                onCheckedChange={(val) =>
                                                    handleCheckAllClick(val as boolean)
                                                }
                                                checked={
                                                    selected?.length ===
                                                    messageList?.content?.length
                                                }
                                            />
                                        )}
                                        <span
                                            className={cn(header === 'Name' ? 'pl-[21px]' : '')}
                                        ></span>
                                        {header}
                                        <FaArrowDownLong className='w-2 self-center' />
                                    </div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {messageList?.content?.map((messages) => (
                            <TableRow
                                key={messages.messageId}
                                className='whitespace-nowrap hover:text-[#405993] hover:underline'
                            >
                                <TableCell className='flex-row items-start text-start  text-[#191A0A] text-base hover:text-[#405993]'>
                                    <div className='flex flex-col'>
                                        <div className='inline-flex '>
                                            <Checkbox
                                                className='mr-[5px] mt-[5px]'
                                                id={'message-id' + ' ' + messages.messageId}
                                                checked={selected?.some(
                                                    (prev) => prev.messageId === messages.messageId,
                                                )}
                                                onCheckedChange={(val) =>
                                                    handleCheckClick([messages], val as boolean)
                                                }
                                            />
                                            <NavLink
                                                to={routeToMessage(messages.status, messages.messageId)}
                                                onClick={() => setMessageData(messages)}
                                                className='ml-2 '
                                            >
                                                {messages.title}
                                            </NavLink>
                                        </div>
                                        <div className='flex flex-row'>
                                            <div
                                                className={cn(
                                                    'w-[8px] h-[4px] rounded-full ml-7 py-1 mt-[7px]',
                                                    {
                                                        'bg-green-800':
                                                            messages.status === 'sent' ||
                                                            messages.status === 'opened',
                                                        'bg-gray-400':
                                                            messages.status === 'draft' ||
                                                            messages.status === 'processing',
                                                    },
                                                )}
                                            ></div>
                                            <p
                                                className={`capitalize pl-1 hover-highlight
                                                ${
                                                    messages.status === 'sent'
                                                        ? 'text-green-800'
                                                        : 'text-gray-400'
                                                }`}
                                            >
                                                {messages.status + ' | '}
                                                {messages.type === 'both'
                                                    ? 'Regular Email/SMS'
                                                    : messages.type === 'email'
                                                      ? 'Regular Email'
                                                      : 'SMS'}
                                            </p>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell className='flex-row items-center text-start text-[#191A0A] text-base hover:text-[#405993] hover:cursor-pointer'>
                                    <NavLink
                                        to={routeToMessage(messages.status, messages.messageId)}
                                        onClick={() => setMessageData(messages)}
                                        className='ml-2'
                                    >
                                        {messages.recipientCount}
                                    </NavLink>
                                </TableCell>
                                <TableCell className='flex-row items-center text-start text-[#191A0A] text-base overflow-hidden hover:text-[#405993] hover:cursor-pointer'>
                                    <div className='truncate w-[70%] max-w-[450px]'>
                                        <NavLink
                                            to={routeToMessage(messages.status, messages.messageId)}
                                            onClick={() => setMessageData(messages)}
                                            className='ml-2'
                                        >
                                            {messages.type === 'email' && messages.emailBody
                                                ? stripHtmlTags(messages.emailBody)
                                                : messages.smsBody}
                                        </NavLink>
                                    </div>
                                </TableCell>
                                <TableCell className='flex-row justify-center items-center text-center text-[#191A0A] text-base hover:text-[#405993] hover:cursor-pointer'>
                                    <div className='flex flex-col justify-center items-center'>
                                        <div>
                                            <NavLink
                                                to={routeToMessage(messages.status, messages.messageId)}
                                                onClick={() => setMessageData(messages)}
                                            >
                                                <p>{messages.dateSent}</p>
                                            </NavLink>
                                        </div>
                                        <div>
                                            <p className='text-[#405993]'>{messages.timeSent}</p>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div className='flex justify-center mr-1 cursor-pointer'>
                                        <DeleteMessageModal messageId={messages.messageId} />
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
            <CardFooter className='items-end justify-end'></CardFooter>
        </Card>
    )
}
export default MessageTable
