import { StepKey } from '@/pages/public/Payment/PaymentProcess/PaymentSteps/PaymentStep'
import { atomWithStorage, createJSONStorage } from 'jotai/utils'

const PAYMENT_STEP = 'paymentStep'

export const paymentStepAtom = atomWithStorage<StepKey | null>(
    PAYMENT_STEP,
    null,
    createJSONStorage(() => sessionStorage),
    {
        getOnInit: true,
    },
)
