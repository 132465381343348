import envelopeLogo from '@/assets/public/envelope.svg'
import { Button } from '@/components/Button'
import { useNavigate } from 'react-router-dom'

export const InviteSuccess = () => {
    const navigate = useNavigate()

    return (
        <div className='flex flex-col justify-center items-center w-full my-[10rem] gap-10'>
            <img
                src={envelopeLogo}
                alt={'Envelope Logo'}
                className='bg-blue-600 h-[10rem] w-[10rem] bg-transparent'
            />
            <h1 className='font-bold text-3xl text-zentive-green-dark'>Successfully Invited</h1>
            <div className='text-center flex flex-col gap-2'>
                <p>Your invitation has been successfully sent.</p>
                <p>
                    Since an account already exists on Zentive under this email address, the
                    customer can sign in using their existing credentials.
                </p>
                <p>
                    If you have any questions or need assistance, please contact our support team{' '}
                    <a
                        href='mailto:notifications@zentive.io'
                        className='text-zentive-blue-dark underline'
                    >
                        notifications@zentive.io
                    </a>
                    .
                </p>
            </div>
            <Button onClick={() => navigate('/customers/customer-list')} className='px-12'>
                Go to Customer List
            </Button>
        </div>
    )
}
