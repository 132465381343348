import { SignUpType } from '@/api/auth/schema'
import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { useFormContext } from 'react-hook-form'

const NameFields = () => {
    const { control } = useFormContext<SignUpType>()
    return (
        <div className='grid grid-cols-2 gap-4 w-full'>
            <div className='col-span-1'>
                <FormField
                    name='data.businessName'
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input placeholder='Business Name*' type='text' {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='col-span-1'>
                <FormField
                    name='data.firstName'
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input placeholder='First Name*' type='text' {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </div>
    )
}
export default NameFields
