import { Outlet, RouteObject } from 'react-router-dom'
import PrivateLayout from '@/layouts/PrivateLayout'
import Dashboard from '@/pages/private/Dashboard'
import ManageSubscriptionSubscribers from '@/pages/private/ManageSubscription/Subscribers'
import Trash from '@/pages/private/ManageSubscription/Trash'
import SubscribersInfo from '@/pages/private/ManageSubscription/Subscribers/SubscribersInfo'
import Profile from '@/pages/private/Profile'
import Customers from '@/pages/private/Costumers'
import ChangePlanGalery from '@/pages/private/ManageSubscription/Subscribers/SubscribersInfo/Subscription/ChangePlanGalery'
import CustomerMessaging from '@/pages/private/Costumers/CustomerMessaging'
import CPDashboard from '@/pages/private/CustomerPortal/Dashboard'
import CPHistory from '@/pages/private/CustomerPortal/History'
import CPInvoice from '@/pages/private/CustomerPortal/Invoice'
import CPPayments from '@/pages/private/CustomerPortal/Payments'
import CPRequestService from '@/pages/private/CustomerPortal/RequestService'
import CPServiceNotes from '@/pages/private/CustomerPortal/ServiceNotes'
import PaymentContent from '@/pages/private/CustomerPortal/Payments/PaymentContent'
import BillingInfo from '@/pages/private/CustomerPortal/Payments/BillingInfo'
import CreateEmail from '@/pages/private/Costumers/CustomerMessaging/CreateEmail'
import OwnerSubscription from '@/pages/private/OwnerSubscription'
import OwnerChangePlanGalery from '@/pages/private/OwnerSubscription/BillingInfo/UpdatePlan/OwnerChangePlan'
import EditOwnerInfo from '@/pages/private/OwnerSubscription/BillingInfo/UpdateBillingInfo/UpdateOwnerData'
import ChangePlanConfirmation from '@/pages/private/OwnerSubscription/BillingInfo/UpdatePlan/ChangePlanConfirmation'
import ChangeCreditCard from '@/pages/private/OwnerSubscription/BillingInfo/UpdateCardDetails/ChangeCard'
import MessageContents from '@/pages/private/Costumers/CustomerMessaging/MessageContents'
import CreateCustomerPassword from '@/pages/private/CustomerPortal/CreateNewPassword'
import PlanComparisonDowngrade from '@/pages/private/OwnerSubscription/BillingInfo/UpdatePlan/PlanComparisonDowngrade'
import PlanComparisonUpgrade from '@/pages/private/OwnerSubscription/BillingInfo/UpdatePlan/PlanComparisonUpgrade'
import ManualPayment from '@/pages/private/OwnerSubscription/BillingInfo/UpdatePlan/OwnerChangePlan/ManualPayment'
import InvoicePayment from '@/pages/private/CustomerPortal/Payments/InvoicePayment'
import ManageCustomers from '@/pages/private/Costumers/ManageCustomer'
import CreateCustomerView from '@/pages/private/Costumers/ManageCustomer/CreateCustomer'
import MapView from '@/pages/private/Costumers/ViewCustomers/MapView'
import ListView from '@/pages/private/Costumers/ViewCustomers/ListView'
import CancelDowngrade from '@/pages/private/OwnerSubscription/BillingInfo/UpdatePlan/OwnerChangePlan/SubscriptionSettings/CancelDowngrade'
import Schedule from '@/pages/private/Schedule'
import Crew from '@/pages/private/Schedule/Crew'
import Settings from '@/pages/private/Settings'
import Employee from '@/pages/private/Schedule/Employee'
import EmployeeSettings from '@/pages/private/Schedule/Employee/ManageEmployees/EmployeeSettings'
import EmployeeNotes from '@/pages/private/Schedule/Employee/ManageEmployees/EmployeeNotes'
import ManageEmployee from '@/pages/private/Schedule/Employee/ManageEmployees'
import Quoting from '@/pages/private/FinancialManagement/Quoting'
import AddQuoting from '@/pages/private/FinancialManagement/Quoting/AddQuoting'
import CPQuote from '@/pages/private/CustomerPortal/Quote'
import RespondQuote from '@/pages/private/CustomerPortal/Quote/RespondQuote'
import QuoteContent from '@/pages/private/CustomerPortal/Quote/QuoteContent'
import ViewQuote from '@/pages/private/CustomerPortal/Quote/ViewQuote'
import ViewQuoting from '@/pages/private/FinancialManagement/Quoting/ViewQuote'
import ManageCrew from '@/pages/private/Schedule/Crew/ManageCrews'
import CreateJobWithQuote from '@/pages/private/Schedule/Job/CreateJobWithQuote'
import CreateEmployeeView from '@/pages/private/Schedule/Employee/ManageEmployees/CreateEmployee'
import CreateCrewView from '@/pages/private/Schedule/Crew/ManageCrews/CreateCrew'
import Calendar from '@/pages/private/Schedule/Calendar'
import ManageSubscription from '@/pages/private/ManageSubscription'
import Job from '@/pages/private/Schedule/Job'
import CreateJobWithoutQuote from '@/pages/private/Schedule/Job/CreateJobWithoutQuote'
import ViewJob from '@/pages/private/Schedule/Job/ViewJob'
import ProductServices from '@/pages/private/Settings/ProductsServices'
import PaymentHistory from '@/pages/private/OwnerSubscription/PaymentHistory'
import ChangePasswordForm from '@/pages/private/Profile/ChangePasswordForm'
import CreateService from '@/pages/private/Settings/ProductsServices/CreateService'
import JobLogs from '@/pages/private/Schedule/JobLogs'
import ManageJobLogs from '@/pages/private/Schedule/JobLogs/ManageJobLogs'

import Invoicing from '@/pages/private/FinancialManagement/Invoicing'
import AddInvoice from '@/pages/private/FinancialManagement/Invoicing/AddInvoice'
import AddInvoiceFromJob from '@/pages/private/FinancialManagement/Invoicing/AddInvoiceFromJob'
import ViewInvoice from '@/pages/private/FinancialManagement/Invoicing/ViewInvoice'
import CrewInterface from '@/pages/private/CrewPortal'
import CreateCrewPassword from '@/pages/private/CrewPortal/CreateNewPassword'
import CrewJobs from '@/pages/private/CrewPortal/Jobs/JobGallery'
import CrewRoute from '@/pages/private/CrewPortal/Route'
import CrewSettings from '@/pages/private/CrewPortal/Settings'
import CrewProfile from '@/pages/private/CrewPortal/Profile'
import Reimbursements from '@/pages/private/Settings/Reimbursements'
import CPRefund from '@/pages/private/CustomerPortal/Refund'
import JobSearch from '@/pages/private/CrewPortal/Jobs/JobSearch'
import UpdateJobStatus from '@/pages/private/CrewPortal/Jobs/JobStatus'
import WorkHistory from '@/pages/private/CrewPortal/Profile/WorkHistory'
import CompletedJobDetails from '@/pages/private/CrewPortal/Profile/CompletedJobDetails'
import StripeAccount from '@/pages/private/Settings/StripeAccount'
import QuickBooks from '@/pages/private/Settings/QuickBooks'
import Expenses from '@/pages/private/CrewPortal/JobDetails/Expenses'
import AddExpense from '@/pages/private/CrewPortal/JobDetails/Expenses/AddExpense'
import Materials from '@/pages/private/CrewPortal/JobDetails/Materials'
import CrewJobPhotos from '@/pages/private/CrewPortal/JobDetails/Photos'
import CrewJobNotes from '@/pages/private/CrewPortal/JobDetails/Notes'
import CrewJobAddNote from '@/pages/private/CrewPortal/JobDetails/Notes/AddNote'
import CrewJobEditNote from '@/pages/private/CrewPortal/JobDetails/Notes/EditNote'
import MoreOptions from '@/pages/private/CrewPortal/Jobs/ViewCustomerJob/MoreOptions'
import ViewCustomerJob from '@/pages/private/CrewPortal/Jobs/ViewCustomerJob'
import { PrivateGuard } from '@/components/PrivateGuard'
import Routing from '@/pages/private/Schedule/Routing'
import RoutingMap from '@/pages/private/Schedule/Routing/RoutingMap'
import RouteOptimization from '@/pages/private/Schedule/Routing/RouteOptimization'
import ViewRoute from '@/pages/private/Schedule/Routing/ViewRoute'
import ExpenseAccounts from '@/pages/private/Settings/ExpenseAccounts'
import CreateNewExpenseAccount from '@/pages/private/Settings/ExpenseAccounts/CreateNewExpenseAccount'
import EndTrialPlanGalery from '@/pages/private/OwnerSubscription/BillingInfo/UpdatePlan/EndOfFreeTrial'
import EditRoute from '@/pages/private/Schedule/Routing/EditRoute'
import SelectBusiness from '@/pages/private/CrewPortal/SelectBusiness'
import { InviteSuccess } from '@/pages/private/Costumers/InviteSuccess'
import EditJob from '@/pages/private/Schedule/Job/EditJob'
import RescheduleJob from '@/pages/private/Schedule/Job/RescheduleJob'
import CustomerPortal from '@/pages/private/CustomerPortal'
import MailChimpIntegration from '@/pages/private/Settings/MailChimpIntegration'
import { CoOwner } from '@/pages/private/Settings/CoOwner'
import { CreateCoOwner } from '@/pages/private/Settings/CoOwner/CreateCoOwner'
import { GrossAndNetIncome } from '@/pages/private/Reports/GrossAndNetIncome'
import { JobCompletion } from '@/pages/private/Reports/JobCompletion'
import ViewJobCompletionRecord from '@/pages/private/Reports/ViewJobCompletionRecord'
import RespondInvoice from '@/pages/private/CustomerPortal/Invoice/PayInvoice'
import { JobsCompletedByEmployee } from '@/pages/private/Reports/JobsCompletedByEmployee'
import LeadList from '@/pages/private/Costumers/LeadManagement/LeadList'
import LeadMessaging from '@/pages/private/Costumers/LeadManagement/LeadMessaging'
import { InvoiceReport } from '@/pages/private/Reports/InvoiceReport'
import { ExpenseReport } from '@/pages/private/Reports/ExpenseReport'
import { QuotesReport } from '@/pages/private/Reports/QuotesReport'
import { Revenue } from '@/pages/private/Reports/Revenue'
// TO DO: append child paths to /dashboard for the sidebar navigation.
// e.g. /dashboard/customers, /schedule, /finance-managmement, etc..

const PrivateRoutes: RouteObject = {
    path: '/',
    element: (
        <PrivateGuard>
            <PrivateLayout />
        </PrivateGuard>
    ),
    children: [
        {
            path: '/dashboard',
            children: [
                {
                    element: <Dashboard />,
                    index: true,
                },
            ],
        },
        {
            path: '/manage-subs/',
            children: [
                {
                    element: <ManageSubscription />,
                    index: true,
                },
                {
                    path: 'subscribers',
                    element: <ManageSubscriptionSubscribers />,
                },
                {
                    path: 'subscribers/details',
                    element: <SubscribersInfo />,
                },
                {
                    path: 'trash',
                    element: <Trash />,
                },
                {
                    path: 'update-plan',
                    element: <ChangePlanGalery />,
                },
            ],
        },
        {
            path: '/customers',
            element: <Customers />,
            children: [
                {
                    path: 'create-customer',
                    element: <CreateCustomerView />,
                },
                {
                    path: 'customer-list/:profileId',
                    element: <ManageCustomers />,
                },
                {
                    path: 'invitation-success',
                    element: <InviteSuccess />,
                },
                {
                    path: 'customer-list',
                    element: <Outlet />,
                    children: [
                        { index: true, element: <ListView /> },
                        { path: 'map-view', element: <MapView /> },
                    ],
                },
                {
                    path: 'customer-messaging',
                    element: <CustomerMessaging />,
                },
                {
                    path: 'customer-messaging/create',
                    element: <CreateEmail />,
                },
                {
                    path: 'customer-messaging/create/draft/:id',
                    element: <CreateEmail />,
                },
                {
                    path: 'customer-messaging/:id',
                    element: <MessageContents />,
                },
                {
                    path: 'lead-management',
                    children: [
                        {
                            index: true,
                            element: <LeadList />,
                        },
                        {
                            path: 'create-message',
                            element: <LeadMessaging />,
                        },
                    ],
                },
            ],
        },
        {
            path: '/schedule',
            element: <Schedule />,
            children: [
                {
                    path: 'calendar',
                    element: <Calendar />,
                },
                {
                    path: 'crew',
                    element: <Crew />,
                },
                {
                    path: 'crew/add-crew',
                    element: <CreateCrewView />,
                },
                {
                    path: 'crew/update/:crewId',
                    element: <ManageCrew />,
                },
                {
                    path: 'employee',
                    element: <Employee />,
                },
                {
                    path: 'employee/add',
                    element: <CreateEmployeeView />,
                },
                {
                    path: 'employee/update/:profileId',
                    element: <ManageEmployee />,
                },
                {
                    path: 'employee/settings',
                    element: <EmployeeSettings />,
                },
                {
                    path: 'employee/notes',
                    element: <EmployeeNotes />,
                },
                {
                    path: 'jobs/new-job',
                    element: <CreateJobWithoutQuote />,
                },
                {
                    path: 'jobs/new-job/:quoteId',
                    element: <CreateJobWithQuote />,
                },
                {
                    path: 'jobs',
                    element: <Job />,
                },
                {
                    path: 'jobs/:jobId',
                    element: <ViewJob />,
                },
                {
                    path: 'jobs/edit/:jobId',
                    element: <EditJob />,
                },
                {
                    path: 'jobs/reschedule/:jobId',
                    element: <RescheduleJob />,
                },
                {
                    path: 'job-log',
                    element: <JobLogs />,
                },
                {
                    path: 'job-log/:jobId',
                    element: <ManageJobLogs />,
                },
                {
                    path: 'routing',
                    element: <Routing />,
                },
                {
                    path: 'routing/map',
                    element: <RoutingMap />,
                },
                {
                    path: 'routing/route-optimization',
                    element: <RouteOptimization />,
                },
                {
                    path: 'routing/view-route/:routeId',
                    element: <ViewRoute />,
                },
                {
                    path: 'routing/edit-route/:routeId',
                    element: <EditRoute />,
                },
            ],
        },
        {
            path: '/customer-portal',
            element: <CustomerPortal />,
            children: [
                {
                    index: true,
                    path: 'dashboard',
                    element: <CPDashboard />,
                },
                {
                    path: 'payments',
                    element: <CPPayments />,
                    children: [
                        { index: true, element: <PaymentContent /> },
                        { path: 'checkout', element: <></> },
                        { path: 'billing-info', element: <BillingInfo /> },
                        { path: 'payment-info', element: <></> },
                    ],
                },
                {
                    path: 'select-business',
                    element: <SelectBusiness />,
                },
                {
                    path: 'quotes',
                    element: <CPQuote />,
                    children: [
                        { index: true, element: <QuoteContent /> },
                        { path: 'respond', element: <RespondQuote /> },
                        { path: 'view', element: <ViewQuote /> },
                    ],
                },
                {
                    path: 'invoices',
                    element: <CPInvoice />,
                },
                {
                    path: 'invoices/respond',
                    element: <RespondInvoice />,
                },
                {
                    path: 'history',
                    element: <CPHistory />,
                },
                {
                    path: 'service-notes',
                    element: <CPServiceNotes />,
                },
                {
                    path: 'request-service',
                    element: <CPRequestService />,
                },
                {
                    path: 'create-password',
                    element: <CreateCustomerPassword />,
                },
                {
                    path: 'invoice-payment',
                    element: <InvoicePayment />,
                },
                {
                    path: 'payments/customer-change-card',
                    element: <ChangeCreditCard />,
                },
                {
                    path: 'refunds',
                    element: <CPRefund />,
                },
            ],
        },
        {
            path: '/crew',
            element: <CrewInterface />,
            children: [
                { path: 'create-password', element: <CreateCrewPassword /> },
                { path: 'jobs', element: <CrewJobs /> },
                { path: 'job/:jobId', element: <ViewCustomerJob /> },
                { path: 'job/expenses/:jobId', element: <Expenses /> },
                { path: 'job/materials/:jobId', element: <Materials /> },
                { path: 'job/photos/:jobId', element: <CrewJobPhotos /> },
                { path: 'job/notes/:jobId', element: <CrewJobNotes /> },
                { path: 'job/note/update/:noteId', element: <CrewJobEditNote /> },
                { path: 'job/note/add/:jobId', element: <CrewJobAddNote /> },
                { path: 'job/options/:jobId', element: <MoreOptions /> },
                { path: 'job/expenses/add/:jobId', element: <AddExpense /> },
                { path: 'jobs/status/:jobId', element: <UpdateJobStatus /> },
                { path: 'jobs/search', element: <JobSearch /> },
                { path: 'route', element: <CrewRoute /> },
                { path: 'settings', element: <CrewSettings /> },
                { path: 'profile', element: <CrewProfile /> },
                { path: 'profile/history', element: <WorkHistory /> },
                { path: 'profile/history/:jobId', element: <CompletedJobDetails /> },
            ],
        },
        {
            path: '/owner-subscription',
            children: [
                {
                    element: <OwnerSubscription />,
                    index: true,
                },
                {
                    path: 'change-plan',
                    element: <OwnerChangePlanGalery />,
                },
                {
                    path: 'change-plan/confirm',
                    element: <ChangePlanConfirmation />,
                },
                {
                    path: 'change-plan/downgrade',
                    element: <PlanComparisonDowngrade />,
                },
                {
                    path: 'change-plan/upgrade',
                    element: <PlanComparisonUpgrade />,
                },
                {
                    path: 'manual-payment',
                    element: <ManualPayment />,
                },
                {
                    path: 'end-trial',
                    element: <EndTrialPlanGalery />,
                },
                {
                    path: 'update-profile',
                    element: <EditOwnerInfo />,
                },
                {
                    path: 'change-card',
                    element: <ChangeCreditCard />,
                },
                {
                    path: 'cancel-downgrade',
                    element: <CancelDowngrade />,
                },
            ],
        },
        {
            path: '/settings',
            element: <Settings />,
            children: [
                {
                    path: 'profile',
                    element: <Profile />,
                },
                {
                    path: 'change-password',
                    element: <ChangePasswordForm />,
                },
                {
                    path: 'product-services',
                    element: <ProductServices />,
                    children: [
                        {
                            path: 'product-service-add-item',
                            element: <CreateService />,
                        },
                    ],
                },
                {
                    path: 'owner-subscription',
                    element: <OwnerSubscription />,
                },
                {
                    path: 'payment-history',
                    element: <PaymentHistory />,
                },
                {
                    path: 'expense-accounts',
                    element: <ExpenseAccounts />,
                    children: [
                        {
                            path: 'add',
                            element: <CreateNewExpenseAccount />,
                        },
                    ],
                },
                {
                    path: 'mail-chimp',
                    element: <MailChimpIntegration />,
                },
                {
                    path: 'co-owner',
                    element: <CoOwner />,
                },
                {
                    path: 'add-co-owner',
                    element: <CreateCoOwner />,
                },
                {
                    path: 'reimbursement',
                    element: <Reimbursements />,
                },
                {
                    path: 'stripe-account',
                    element: <StripeAccount />,
                },
                {
                    path: 'quickbooks',
                    element: <QuickBooks />,
                },
            ],
        },
        {
            path: '/financial-management',
            children: [
                {
                    path: 'quoting',
                    element: <Quoting />,
                },
                {
                    path: 'quoting/add-quoting',
                    element: <AddQuoting />,
                },
                {
                    path: 'quoting/view',
                    element: <ViewQuoting />,
                },
                {
                    path: 'invoicing',
                    element: <Invoicing />,
                },
                {
                    path: 'invoicing/add-invoice',
                    element: <AddInvoice />,
                },
                {
                    path: 'invoicing/add-invoice/:jobId',
                    element: <AddInvoiceFromJob />,
                },
                {
                    path: 'invoicing/view-invoice/:id',
                    element: <ViewInvoice />,
                },
            ],
        },
        {
            path: '/reports',
            children: [
                {
                    path: 'gross-net-income',
                    element: <GrossAndNetIncome />,
                },
                {
                    path: 'job-completion-reports',
                    element: <JobCompletion />,
                },
                {
                    path: 'jobs-completion-report-details/:jobId',
                    element: <ViewJobCompletionRecord />,
                },
                {
                    path: 'jobs-completed-employee-reports',
                    element: <JobsCompletedByEmployee />,
                },
                {
                    path: 'invoice-reports',
                    element: <InvoiceReport />,
                },
                {
                    path: 'expense-reports',
                    element: <ExpenseReport />,
                },
                {
                    path: 'quote-reports',
                    element: <QuotesReport />,
                },
                {
                    path: 'revenue',
                    element: <Revenue />,
                },
            ],
        },
    ],
}

export default PrivateRoutes
