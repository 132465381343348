import { ExpenseType } from '@/api/quoting/schema'

interface ViewExpenseProps {
    expense: ExpenseType
}

const ViewExpense = ({ expense }: ViewExpenseProps) => {
    return (
        <div className='flex flex-col justify-between items-center px-0'>
            <div className='w-10/12 flex flex-col mx-auto bg-white rounded-md shadow-md px-6 py-2'>
                <div className='flex flex-col border-b space-y-3 py-4'>
                    <p className='text-zentive-gray-medium font-semibold text-lg'>Item</p>
                    <div className='flex flex-row'>
                        <p className='text-sm'>{expense.accountName}</p>
                        <div className='flex-grow h-px bg-zentive-gray-light my-3 mx-4'></div>
                        <p className='text-sm'>
                            $
                            {expense.total?.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            }) ?? ''}
                        </p>
                    </div>
                </div>
                {expense.description && (
                    <div className='flex flex-col border-b space-y-3 py-4'>
                        <p className='text-zentive-gray-medium font-semibold text-lg'>
                            Description
                        </p>
                        {expense.description}
                    </div>
                )}
                <div className='flex flex-col space-y-3 py-4'>
                    <p className='text-zentive-gray-medium font-semibold text-lg'>
                        Include in customer invoice?
                    </p>
                    {expense.isNotIncludedInInvoice ? 'No' : 'Yes'}
                </div>
            </div>
        </div>
    )
}

export default ViewExpense
