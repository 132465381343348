import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useAtomValue } from 'jotai'
import OwnerDashboard from './OwnerDashboard'
import AdminDashboard from './AdminDashboard'
import { ROLE } from '@/constants'

const Dashboard = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    return user?.role?.roleName === ROLE.OWNER ? <OwnerDashboard /> : <AdminDashboard />
}

export default Dashboard
