import { TableHead, TableHeader, TableRow } from '@/components/Table'
import { Checkbox } from '@/components/Checkbox'
import { QuoteListType } from '@/api/quoting/schema'
import { quoteSortByAtom, quoteSortOrderAtom } from '@/store/quote'
import { useAtom } from 'jotai'
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6'

const tableHeader = ['Customer Name', 'Property', 'Total', 'Status']

type QuotingTableHeadProps = {
    quote: QuoteListType | null
    setQuotesToUpdate: React.Dispatch<
        React.SetStateAction<{
            quoteIds: string[]
        }>
    >
    setIsCheckAll: React.Dispatch<React.SetStateAction<boolean>>
    isCheckAll: boolean
}

const QuotingTableHead: React.FC<QuotingTableHeadProps> = ({
    quote,
    setQuotesToUpdate,
    isCheckAll,
}) => {
    const [sortBy, setSortBy] = useAtom(quoteSortByAtom)
    const [sortOrder, setSortOrder] = useAtom(quoteSortOrderAtom)
    const handleCheckAll = (checked: boolean) => {
        if (checked) {
            const updatedProfileIds = quote?.content
                ?.map((data) => data.quoteId)
                .filter(Boolean) as string[]

            setQuotesToUpdate((prev) => ({
                ...prev,
                quoteIds: [...new Set([...prev.quoteIds, ...updatedProfileIds])],
            }))
        } else {
            const currentQuoteIds = quote?.content
                ?.map((data) => data.quoteId)
                .filter(Boolean) as string[]

            setQuotesToUpdate((prev) => ({
                ...prev,
                quoteIds: prev.quoteIds.filter((id) => !currentQuoteIds.includes(id)),
            }))
        }
    }

    const onHandleSorting = (column: string) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC')
        } else {
            setSortBy(column)
            setSortOrder('ASC')
        }
    }

    const getSortIcon = (column: string) => {
        if (sortBy === column) {
            return sortOrder === 'ASC' ? (
                <FaArrowUpLong className='mt-1 w-3 h-3' />
            ) : (
                <FaArrowDownLong className='mt-1 w-3 h-3' />
            )
        }
        return <FaArrowDownLong className='mt-1 w-3 h-3' />
    }

    return (
        <TableHeader className='bg-white'>
            <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                    <Checkbox
                        className='mr-[16px] mt-[2px]'
                        onCheckedChange={handleCheckAll}
                        checked={isCheckAll}
                    />
                    Quote Number
                    <button onClick={() => onHandleSorting('Quote Number')} className='ml-2'>
                        {getSortIcon('Quote Number')}
                    </button>
                </TableHead>
                {tableHeader?.map((header, index) => (
                    <TableHead
                        className={`font-semibold text-zentive-gray-medium text-base ${
                            index == 2 ? 'text-right w-[200px]' : ''
                        }${index >= 3 ? 'text-center w-[300px]' : ''}`}
                    >
                        {header}
                        <button
                            onClick={() => onHandleSorting(header)}
                            className='ml-2'
                            disabled={header === 'Customer Name' || header === 'Property'}
                        >
                            {getSortIcon(header)}
                        </button>
                    </TableHead>
                ))}
            </TableRow>
        </TableHeader>
    )
}

export default QuotingTableHead
