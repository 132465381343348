import { getJobCompletedByEmployeeReport } from '@/api/reports'
import {
    JobsCompletedByEmployeeRecordType,
    JobsCompletedByEmployeeReportType,
} from '@/api/reports/schema'
import { Button } from '@/components/Button'
import { Card, CardContent } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import { Pagination } from '@/components/Pagination'
import { PaginationType } from '@/components/Pagination/schema'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import { RECORD_PREFIX, US_FORMAT } from '@/constants'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { jobCompletedByEmployeeExportAtom, jobCompletedByEmployeeFilterAtom } from '@/store/reports'
import { cn, displayCurrency, formatRecordNumber } from '@/utils/helper'
import { formatWorkingHours } from '@/utils/time'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useAtom, useAtomValue } from 'jotai'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

const tableHeader = [
    'Job Number',
    'Employee Name',
    'Job Type',
    'Invoice Amount',
    'Labor Cost',
    'Date Completed',
    'Time Spent',
    'Address',
]

const JobsCompletedByEmployeeTable = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const [isAllChecked, setIsAllChecked] = useState(false)

    const jobCompletionFilter = useAtomValue(jobCompletedByEmployeeFilterAtom)
    const [exportAtom, setExportAtom] = useAtom(jobCompletedByEmployeeExportAtom)

    const [pagination, setPagination] = useState<PaginationType>({
        page: 1,
        pageSize: 5,
    })

    const { data } = useQuery<JobsCompletedByEmployeeReportType>({
        queryKey: [
            'jobsCompletedByEmployeeReport',
            user?.businessId,
            jobCompletionFilter,
            pagination,
        ],
        queryFn: () =>
            getJobCompletedByEmployeeReport(
                user?.businessId as string,
                jobCompletionFilter,
                pagination,
            ),
    })

    const handleCheckAllChange = (isChecked: boolean) => {
        setIsAllChecked(isChecked)

        if (isChecked) {
            // Add all available records to exportAtom
            const allRecords = data?.content?.report ?? []

            setExportAtom({
                records: allRecords,
                totalInvoiceAmount: allRecords.reduce(
                    (total, record) => total + record.invoiceAmount,
                    0,
                ),
                totalLaborCost: allRecords.reduce((total, record) => total + record.laborCost, 0),
                totalTimeSpent: allRecords.reduce((total, record) => total + record.timeSpent, 0),
            })
        } else {
            // Clear all records from exportAtom
            setExportAtom({
                records: [],
                totalInvoiceAmount: 0,
                totalLaborCost: 0,
                totalTimeSpent: 0,
            })
        }
    }

    const handleCheckChange = (jobCrew: JobsCompletedByEmployeeRecordType) => {
        setExportAtom((prev) => {
            if (!prev) {
                // If there is no existing filter, create one with the new jobCrewMember
                return {
                    records: [jobCrew],
                    totalInvoiceAmount: jobCrew?.invoiceAmount,
                    totalLaborCost: jobCrew?.laborCost,
                    totalTimeSpent: jobCrew?.timeSpent,
                }
            }

            const isJobCrewSelected = prev.records?.some(
                (record) => record.jobCrewMemberId === jobCrew.jobCrewMemberId,
            )

            // Update records based on whether the jobCrewMember is being added or removed
            const updatedJobCrews = isJobCrewSelected
                ? prev.records.filter(
                      (record) => record.jobCrewMemberId !== jobCrew.jobCrewMemberId,
                  ) // Remove if exists
                : [...(prev.records || []), jobCrew] // Add if it doesn't exist

            return {
                records: updatedJobCrews,
                totalInvoiceAmount: isJobCrewSelected
                    ? prev.totalInvoiceAmount - jobCrew.invoiceAmount // Subtract if unselected
                    : prev.totalInvoiceAmount + jobCrew.invoiceAmount, // Add if selected
                totalLaborCost: isJobCrewSelected
                    ? prev.totalLaborCost - jobCrew.laborCost // Subtract if unselected
                    : prev.totalLaborCost + jobCrew.laborCost, // Add if selected
                totalTimeSpent: isJobCrewSelected
                    ? prev.totalTimeSpent - jobCrew.timeSpent // Subtract if unselected
                    : prev.totalTimeSpent + jobCrew.timeSpent, // Add if selected
            }
        })
    }

    return (
        <Card>
            <CardContent className='overflow-x-scroll green-scrollbar rounded-t-none pb-0'>
                <Table className='table-auto whitespace-normal mx-auto'>
                    <TableHeader className='whitespace-nowrap'>
                        <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                            {tableHeader.map((header, index) => (
                                <TableHead
                                    key={index}
                                    className={cn(
                                        'font-semibold text-zentive-gray-medium text-md whitespace-nowrap',
                                    )}
                                >
                                    <div className='flex flex-row items-center justify-start gap-2'>
                                        {' '}
                                        {index === 0 && (
                                            <Checkbox
                                                onCheckedChange={handleCheckAllChange}
                                                checked={
                                                    isAllChecked ||
                                                    exportAtom?.records?.length ===
                                                        data?.content?.report?.length
                                                }
                                                className='mt-[1px]'
                                            />
                                        )}
                                        {header}
                                    </div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {Array.isArray(data?.content?.report) &&
                            data?.content?.report?.length > 0 &&
                            data?.content?.report?.map(
                                (r: JobsCompletedByEmployeeRecordType, i: number) => (
                                    <TableRow key={i} className='whitespace-nowrap'>
                                        <TableCell className='text-[#191A0A] text-md flex flex-row gap-2 items-center'>
                                            <Checkbox
                                                onCheckedChange={() => handleCheckChange(r)}
                                                checked={exportAtom?.records?.some(
                                                    (record) =>
                                                        r.jobCrewMemberId ===
                                                        record.jobCrewMemberId,
                                                )}
                                            />
                                            <NavLink
                                                to={r.jobNumber ? `/schedule/jobs/${r.jobId}` : '#'}
                                            >
                                                <Button
                                                    className='text-md text-zentive-blue-dark font-semibold'
                                                    variant={'link'}
                                                >
                                                    {r.jobNumber
                                                        ? RECORD_PREFIX.job +
                                                          formatRecordNumber(r.jobNumber)
                                                        : 'N/A'}
                                                </Button>
                                            </NavLink>
                                        </TableCell>
                                        <TableCell className='text-zentive-black text-md'>
                                            {r.employeeName}
                                        </TableCell>
                                        <TableCell className='text-zentive-black text-md'>
                                            <p>{r.services.join(', ')}</p>
                                        </TableCell>
                                        <TableCell className='text-zentive-black text-md'>
                                            <p>{displayCurrency(r.invoiceAmount)}</p>
                                        </TableCell>
                                        <TableCell className='text-zentive-black text-md'>
                                            <p>{displayCurrency(r.laborCost)}</p>
                                        </TableCell>
                                        <TableCell className='text-zentive-black text-md'>
                                            <p>{dayjs(r.date).format(US_FORMAT)}</p>
                                        </TableCell>
                                        <TableCell className='text-zentive-black text-md'>
                                            <p>{formatWorkingHours(r.timeSpent)}</p>
                                        </TableCell>
                                        <TableCell className='text-zentive-black text-md'>
                                            <p>{r.address}</p>
                                        </TableCell>
                                    </TableRow>
                                ),
                            )}
                        {data?.content?.report && (
                            <TableRow key='total-row' className='whitespace-nowrap'>
                                <TableCell>
                                    <p className='font-bold text-md'>Total</p>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    <p className='font-bold text-md'>
                                        {displayCurrency(data?.content?.totalInvoiceAmount ?? 0)}
                                    </p>
                                </TableCell>
                                <TableCell>
                                    <p className='font-bold text-md'>
                                        {displayCurrency(data?.content?.totalLaborCost ?? 0)}
                                    </p>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    <p className='font-bold text-md'>
                                        {formatWorkingHours(data?.content?.totalTimeSpent ?? 0)}
                                    </p>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </CardContent>
            <div className='p-5 pb-0 flex flex-row items-center'>
                <Pagination
                    pagination={pagination}
                    setPagination={setPagination}
                    itemsPerPage={data?.meta?.pageSize ?? 5}
                    totalRecords={data?.meta?.totalRecords ?? 0}
                />
            </div>
        </Card>
    )
}
export default JobsCompletedByEmployeeTable
