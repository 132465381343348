import { Card, CardContent } from '@/components/Card'
import InvoiceTable from './InvoiceTable'
import { AxiosError } from 'axios'
import { useQuery } from '@tanstack/react-query'
import { InvoiceListType } from '@/api/customer/schema'
import { getInvoiceList } from '@/api/customer'
import { useAtomValue } from 'jotai'
import { businessIdAtom, userAtom } from '@/store/auth'
import BreadCrumbs from '@/components/BreadCrumbs'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'

const CPInvoice = () => {
    const user = useAtomValue(userAtom)
    const businessId = useAtomValue(businessIdAtom)

    const { data: invoiceList } = useQuery<unknown, AxiosError, InvoiceListType[]>({
        queryKey: ['invoiceList', businessId],
        queryFn: () => getInvoiceList(user?.profileId as string, businessId as string),
    })

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName='Invoices' />
            <Card className='mt-5 w-full' style={{ overflowY: 'auto' }}>
                <CardContent className='p-4 pt-[36px]  overflow-x-scroll md:overflow-x-auto'>
                    <InvoiceTable invoiceList={invoiceList} />
                </CardContent>
            </Card>
        </div>
    )
}
export default CPInvoice
