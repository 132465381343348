import { getInvoiceList } from '@/api/profile'
import { FullProfileType, SubscriptionsInfoType } from '@/api/profile/schema'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { isViewedAsAtom, viewedAsUserAtom } from '@/store/auth'
import { ROLE } from '@/constants'
import { userAtom } from '@/store/auth'
import { cn, numberFormatter, unixToLocalDate } from '@/utils/helper'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai'

const PaymentHistory = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    const isCustomer = user?.role.roleName === ROLE.CUSTOMER

    const { data: subscriptionsData, isLoading } = useQuery<
        unknown,
        AxiosError,
        SubscriptionsInfoType[]
    >({
        queryKey: ['subscriptionsInfo'],
        queryFn: () => getInvoiceList(user?.profileId as string),
    })

    return (
        <section>
            {!isCustomer && (
                <div className='bg-white pt-5 pb-7 px-5'>
                    <Table>
                        <TableHeader>
                            <TableRow className='whitespace-nowrap'>
                                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                    Date
                                </TableHead>
                                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                    Product
                                </TableHead>
                                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                    Reference
                                </TableHead>
                                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                    Amount
                                </TableHead>
                                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                    Status
                                </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {isLoading && <> Loading . . .</>}
                            {!isLoading && (
                                <>
                                    {subscriptionsData?.map((data) => (
                                        <TableRow
                                            key={data.subscriptionId}
                                            className='whitespace-nowrap'
                                        >
                                            <TableCell className='flex-row items-start text-start  text-[#191A0A] text-[16px] '>
                                                {unixToLocalDate(data.created)}
                                            </TableCell>
                                            <TableCell className='flex-row items-start text-start  text-[#191A0A] text-[16px] '>
                                                {data.planName}
                                            </TableCell>
                                            <TableCell className='flex-row items-start text-start  text-[#191A0A] text-[16px] '>
                                                {data.referenceNumber}
                                            </TableCell>
                                            <TableCell className='flex-row items-start text-start  text-[#191A0A] text-[16px] '>
                                                ${numberFormatter.format(Number(data.price / 100))}
                                            </TableCell>
                                            <TableCell
                                                className={cn(
                                                    data.status === 'paid'
                                                        ? 'text-zentive-green-dark'
                                                        : '',
                                                    'flex-row items-start text-start text-[16px]',
                                                )}
                                            >
                                                {data.status === 'paid' ? 'Successful' : 'Unpaid'}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </div>
            )}
        </section>
    )
}

export default PaymentHistory
