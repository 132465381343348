import { createBrowserRouter } from 'react-router-dom'
import PrivateRoutes from './PrivateRoutes'
import PublicRoutes from './PublicRoutes'
import { Navigate } from 'react-router-dom'
import {Error404} from '@/pages/common/Error404'
import { Error403 } from '@/pages/common/Error403'
import { LinkExpired } from '@/pages/common/LinkExpired'
// TO DO: add route authentication and error page handler.

export const Routes = createBrowserRouter([
    {
        path: '404',
        element: <Error404 />,
    },
    {
        path: '403',
        element: <Error403 />,
    },
    {
        path: 'error',
        element: <LinkExpired />,
    },
    {
        path: '*',
        element: <Navigate to='/404' replace />,
    },
    PublicRoutes,
    PrivateRoutes,
])

export default Routes
