import { useNavigate, useParams } from 'react-router-dom'
import BackButtonTopBar from '../../../Common/BackButtonTopBar'
import { Button } from '@/components/Button'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { NotePayloadType, noteSchema } from '@/api/notes/schema'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Label } from '@/components/Label'
import { Textarea } from '@/components/TextArea'
import dayjs from 'dayjs'
import { useMutation } from '@tanstack/react-query'
import { createJobNote } from '@/api/job'
import { AxiosError } from 'axios'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useToast } from '@/hooks/useToast'
import { US_FORMAT, US_FORMAT_WITH_TIME_MERIDIEM_DAY } from '@/constants'

const CrewJobAddNote = () => {
    const { t, i18n } = useTranslation(['view-job'])
    const navigate = useNavigate()
    const { toast } = useToast()
    const { jobId } = useParams()
    const now = dayjs()

    const createJobNotesMethods = useForm<NotePayloadType>({
        mode: 'onChange',
        resolver: zodResolver(noteSchema),
    })

    const { mutate: addJobNotesMu } = useMutation<unknown, AxiosError, NotePayloadType>({
        mutationFn: (newNote) => createJobNote(newNote),
        onSuccess: () => {
            navigate(-1)
            toast({
                description: t('Note has been added successfully'),
                variant: 'crew_interface',
                duration: 2000,
            })
        },
    })

    const {
        setValue,
        handleSubmit,
        control,
        formState: { isSubmitting },
        watch,
    } = createJobNotesMethods

    const onSubmitCreateJobNote = (data: NotePayloadType) => {
        addJobNotesMu(data)
    }

    useEffect(() => {
        setValue('jobId', jobId)
    }, [jobId])

    return (
        <div className='flex flex-col justify-between items-center'>
            <BackButtonTopBar title={t('Notes')} />
            <div className='flex flex-col w-10/12 max-w-[1024px] border border-zentive-green-dark p-3 rounded-lg bg-white mx-4'>
                <Form {...createJobNotesMethods}>
                    <form onSubmit={handleSubmit(onSubmitCreateJobNote)}>
                        <div className='w-full mx-auto py-3'>
                            <div>
                                <div className='p-1'>
                                    <Label className='flex justify-end text-[12px] mr-2 text-zentive-gray-medium'>
                                        {dayjs(now).format(
                                            i18n.language === 'en'
                                                ? US_FORMAT + ' h:mmA'
                                                : US_FORMAT_WITH_TIME_MERIDIEM_DAY,
                                        )}
                                    </Label>
                                    <FormField
                                        control={control}
                                        name='note'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Textarea
                                                        className='border-white shadow-none text-[#191A0A] h-[350px] min-h-[350px] max-h-[400px] text-[16px]'
                                                        // onChange={(event) => {
                                                        //     setNewNoteContent(event.target.value.trim())
                                                        // }}
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <Button
                            variant='ghost'
                            type='submit'
                            className='absolute top-1 right-5 z-50 text-zentive-green-dark font-semibold'
                            disabled={isSubmitting || !watch('note') || watch().note?.trim() === '' || watch().note !== watch().note?.trim()}
                        >
                            {t('Save')}
                        </Button>
                    </form>
                </Form>
            </div>
        </div>
    )
}

export default CrewJobAddNote
