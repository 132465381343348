import goTrueClient from '@/api/goTrueClient'
import sessionExpired from '@/assets/public/session_expired.png'
import { Button } from '@/components/Button'
import { useStripeConnect } from '@/hooks/useStripeConnect'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router'

const SessionExpired = () => {
    const navigate = useNavigate()
    const { data: authSession } = useQuery({
        enabled: true,
        queryKey: ['session'],
        queryFn: () => goTrueClient.getSession(),
    })

    const id = authSession?.data.session?.user.id

    const { isError, isPending, handleConnect } = useStripeConnect(id)

    return (
        <div>
            <div className='flex justify-center'>
                <img
                    src={sessionExpired}
                    alt='Session Expired Png'
                    className='h-[301.35px] w-[163.39px] '
                />
            </div>

            <p className='text-zentive-green-dark font-semibold text-center text-[24px] mt-[56.65px]'>
                Session Expired
            </p>
            <p className='text-[16px] text-center mt-[16px]'>
                Your session timed out. To continue working, sign in again.
            </p>
            <div className='flex justify-between rounded-b-[15px] mt-[56.65px] gap-3'>
                <Button
                    disabled={isPending}
                    onClick={() => {
                        navigate('/')
                    }}
                    className='w-[250px] text-[16px] font-semibold'
                    variant={'outline'}
                >
                    Go to the Login Page
                </Button>
                <Button
                    disabled={isError || isPending}
                    onClick={handleConnect}
                    className='w-[250px] text-[16px]'
                >
                    Set up Stripe Account
                </Button>
            </div>
        </div>
    )
}

export default SessionExpired
