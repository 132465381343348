import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import BreadCrumbs from '@/components/BreadCrumbs'
import 'react-datepicker/dist/react-datepicker.css'
import RoutingMapHeader from './RoutingMapHeader'
import { useAtomValue, useSetAtom } from 'jotai'
import emptyPlaceholder from '@/assets/private/route-map-empty.png'
import emptyResult from '@/assets/private/no-result-found.png'
import RoutingMapBody from './RoutingMapBody'
import {
    hasJobResultAtom,
    isPolylineLoadingAtom,
    routeCustomerListAtom,
    routeJobListAtom,
    routePolylineAtom,
} from '@/store/route'
import { RouteParamType, routeSchema } from '@/api/routing/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
// import { useEffect } from 'react'
import { isViewedAsAtom, viewedAsUserAtom, userAtom } from '@/store/auth'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const RoutingMap = () => {
    const routeJobList = useAtomValue(routeJobListAtom)
    const hasJobResult = useAtomValue(hasJobResultAtom)
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const setRouteJobList = useSetAtom(routeJobListAtom)
    const setPolylineList = useSetAtom(routePolylineAtom)
    const setIsPolylineLoading = useSetAtom(isPolylineLoadingAtom)
    const setHasJobResult = useSetAtom(hasJobResultAtom)
    const setRouteCustomerList = useSetAtom(routeCustomerListAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    useEffect(() => {
        setPolylineList(null)
        setRouteJobList([])
        setIsPolylineLoading(false)
        setHasJobResult(true)
        setRouteCustomerList([])
    }, [])

    const methods = useForm<RouteParamType>({
        mode: 'onSubmit',
        resolver: zodResolver(routeSchema),
        defaultValues: { isSavedPermanently: false, businessId: user?.businessId },
    })
    const navigate = useNavigate()

    const handleNavigation = () => {
        navigate(-1)
    }
    return (
        <FormProvider {...methods}>
            <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
                <BreadCrumbs titleName='Routing' />
                <div className='mt-10'>
                    <p
                        className=' text-zentive-blue-dark text-sm font-sans-pro mb-0 pb-0 hover:underline cursor-pointer'
                        onClick={handleNavigation}
                    >
                        Back to Route Search
                    </p>
                </div>
                <RoutingMapHeader />
                {!routeJobList?.length && hasJobResult ? (
                    <div className='bg-white w-auto rounded-sm mt-10 py-10'>
                        <div className='w-full'> </div>
                        <div className='flex justify-center'>
                            <img
                                src={emptyPlaceholder}
                                alt='empty-route-placeholder'
                                className='my-12 h-60'
                            />
                        </div>
                        <div className='flex flex-col'>
                            <p className='pb-2 text-center text-2xl text-zentive-green-dark font-semibold'>
                                Routing Map is Empty
                            </p>
                            <p className='pb-8 text-center text-md'>
                                You do not have any destination to display.
                            </p>
                        </div>
                    </div>
                ) : !routeJobList?.length && !hasJobResult ? (
                    <div className='bg-white w-auto rounded-sm mt-10 py-10'>
                        <div className='w-full'> </div>
                        <div className='flex justify-center'>
                            <img src={emptyResult} alt='no-result-found' className='my-12 h-60' />
                        </div>
                        <div className='flex flex-col'>
                            <p className='pb-2 text-center text-2xl text-zentive-green-dark font-semibold'>
                                No Results Found
                            </p>
                            <p className='pb-8 text-center text-md'>
                                Please search using different keywords or details.
                            </p>
                        </div>
                    </div>
                ) : (
                    <RoutingMapBody />
                )}
            </div>
        </FormProvider>
    )
}

export default RoutingMap
