import { signOut } from '@/api/auth'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'

export const CustomerTopbar = () => {
    const { navigateTo } = useAppNavigate()

    return (
        <div>
            <div className='sticky text-zentive-gray-semi-medium top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8'>
                <div className='flex flex-1 gap-x-4 lg:gap-x-6 justify-between'>
                    <div className='flex items-center gap-x-1 lg:gap-x-2 bg-white relative'>
                        <button onClick={() => navigateTo('/owner-subscription/end-trial')}>
                            <img
                                src={zentiveLogo}
                                alt='Zentive Logo'
                                className='h-[44px] w-[125px] cursor-pointer'
                            />
                        </button>
                    </div>
                    <div className='flex items-center gap-x-2 lg:gap-x-4'>
                        <div className='flex font-[400] flex-col'>
                            <button onClick={signOut}>
                                <div className='p-3 underline text-zentive-green-dark font-bold'>
                                    <p className='flex-1 text-left'> Logout </p>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
