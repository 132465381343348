import BreadCrumbs from '@/components/BreadCrumbs'
import { Button } from '@/components/Button'
import SearchBar from '@/components/InputSearchBox'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { FaPlus } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import CrewTable from './CrewTable'
import { getAllCrews } from '@/api/crew'
import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
    crewPaginationAtom,
    selectedCrewTabAtom,
    selectedCrewTabStatusAtom,
} from '@/store/employee'
import { FullProfileType } from '@/api/profile/schema'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { itemsPerPageAtom } from '@/store/manageSubs'
import { Pagination } from '@/components/Pagination'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import { CrewListResponseType } from '@/api/crew/schema'
import DeleteCrewModal from './ManageCrews/CrewModals/DeleteModal'
import { cn } from '@/utils/helper'
import { bulkUpdateCrewStatusAtom, crewSortByAtom, crewSortOrderAtom } from '@/store/crew'
import debounce from 'debounce'

const Crew = () => {
    const navigate = useNavigate()
    const selected = useAtomValue(selectedCrewTabStatusAtom)
    const setSelected = useSetAtom(selectedCrewTabAtom)
    const [keyWord, setKeyWord] = useState<string>('')
    const [pagination, setPagination] = useAtom(crewPaginationAtom)
    const [selectedItemsPerPage] = useAtom(itemsPerPageAtom)
    const queryClient = useQueryClient()

    //handle view as owner/admin
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const crewSortBy = useAtomValue(crewSortByAtom)
    const crewSortOrder = useAtomValue(crewSortOrderAtom)

    const setCrewsToUpdate = useSetAtom(bulkUpdateCrewStatusAtom)

    const handleSearch = (query: string) => {
        setKeyWord(query)
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 1,
        }))
    }

    const debouncedSearch = debounce((query: string) => {
        handleSearch(query)
    }, 500)

    const crewQuery = useQuery<CrewListResponseType, Error>(
        {
            queryKey: ['crewList', pagination, selected, keyWord, crewSortBy, crewSortOrder],
            queryFn: () => getAllCrews(keyWord.trim() ?? '', selected, user.businessId, pagination, crewSortBy, crewSortOrder),
            staleTime: 0,
        },
        queryClient,
    )

    useEffect(() => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 1,
            pageSize: selectedItemsPerPage,
        }))
    }, [selectedItemsPerPage, setPagination, keyWord])

    useEffect(() => {
        setSelected('CI')
    }, [])

    return (
        <div className={cn('w-full h-full', BREADCRUMBS_PADDING_STYLE)}>
            <BreadCrumbs titleName='Crew' />
            <div className='flex justify-between py-6'>
                <div>
                    <SearchBar
                        placeHolder='Search for...'
                        onSearchChange={(e) => debouncedSearch(e.target.value)}
                    />
                </div>
                <div className='flex gap-x-4'>
                    <DeleteCrewModal
                        isCrewTable={true}
                        onSuccessRestore={() => {
                            setCrewsToUpdate({
                                crewIds: [],
                            })
                        }}
                    />
                    <Button
                        type={'button'}
                        className='bg-zentive-green-dark'
                        onClick={() => {
                            navigate('/schedule/crew/add-crew')
                        }}
                    >
                        <FaPlus className='w-4 mr-2' />
                        Add Crew
                    </Button>
                </div>
            </div>
            {crewQuery?.data?.content?.length && <CrewTable crews={crewQuery} />}
            {!!crewQuery?.data?.content?.length && (
                <div className='mb-5 mt-3'>
                    {pagination.pageSize !== undefined && (
                        <Pagination
                            itemsPerPage={pagination.pageSize}
                            setPagination={setPagination}
                            totalRecords={crewQuery?.data?.meta.totalRecords ?? 1}
                            pagination={pagination}
                        />
                    )}
                </div>
            )}
            {!crewQuery?.data?.content?.length && (
                <div className='bg-white rounded-sm'>
                    <div className='w-[1260px]'> </div>
                    <div className='flex justify-center'>
                        <img
                            src={emptyPlaceholder}
                            alt='empty-table-placeholder'
                            className='my-12 h-60'
                        />
                    </div>
                    <p className='pb-4 text-center text-2xl text-gray-900'> No results found </p>
                </div>
            )}
        </div>
    )
}

export default Crew
