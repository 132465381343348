import { previousPathAtom } from '@/store/path'
import { useAtom } from 'jotai'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const usePathname = () => {
    const [previousPath, setPreviousPath] = useAtom(previousPathAtom)
    const location = useLocation()
    const previousPathnameRef = useRef<string>(previousPath)

    useEffect(() => {
        setPreviousPath(previousPathnameRef.current)
        previousPathnameRef.current = location.pathname
    }, [location])
}

export default usePathname
