import BreadCrumbs from '@/components/BreadCrumbs'
import { RevenueReportFilter } from './RevenueFilter'
import RevenueTable from './RevenueTable'

export const Revenue = () => {
    return (
        <div className='flex flex-col w-full h-full px-[70px] pt-[50px] gap-5'>
            <BreadCrumbs titleName='Revenue' />
            <RevenueReportFilter />
            <RevenueTable />
        </div>
    )
}
