import { resendEmailConfirmation } from '@/api/auth'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/Card'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import envelopeLogo from '@/assets/public/envelope.svg'
import { decryptString } from '@/utils/crypto'
import { useEffect, useState } from 'react'
import { useToast } from '@/hooks/useToast'
import { useResendTimer } from '@/hooks/useResendTimer'

const SignUpVerification = () => {
    const { displayText, isTimerActive, triggerCountdown } = useResendTimer()

    const [params] = useSearchParams()
    const navigate = useNavigate()
    const [email, setEmail] = useState<string>('')
    const emailParams = params.get('email')

    const { toast } = useToast()

    useEffect(() => {
        try {
            if (emailParams === null) {
                navigate('/sign-in')
                return
            }
            setEmail(decryptString(emailParams))
        } catch {
            navigate('/sign-in')
        }
    }, [params])

    const { mutate: resendMu, isPending: resendLoading } = useMutation({
        mutationFn: (email: string) => resendEmailConfirmation('signup', email),
        onSuccess: () => {
            toast({
                description: 'Verification Email Sent', 
                variant: 'default',
            })

            triggerCountdown()
        },
        onError: (err: AxiosError) => console.log(err),
    })
    const handleResendClick = (email: string) => {
        resendMu(email)
    }
    return (
        <Card>
            <CardHeader>
                <div className='flex justify-center w-full my-10'>
                    <img
                        src={envelopeLogo}
                        alt={'Envelope Logo'}
                        className='bg-blue-600 h-16 w-16'
                    />
                </div>
                <CardTitle className='text-zentive-green-dark text-center'>
                    Verify Your Email Address
                </CardTitle>
            </CardHeader>
            <CardContent className='text-center mb-10'>
                <div className='text-gray-400 w-108 text-left'>
                    <p>
                        We've sent an email verification to {email}. Click the "Verify your email
                        address" button to get started. If the email is not in your inbox, please
                        check your spam folder.
                    </p>
                </div>
                <div className='my-4'>
                    <NavLink to='/sign-in' className=' text-zentive-green-dark '>
                        {' '}
                        Back to Login
                    </NavLink>
                </div>
                <div>
                    <p className='text-gray-400'>
                        {isTimerActive ? (
                            displayText
                        ) : (
                            <>
                                Didn't get the email?{' '}
                                <button
                                    disabled={resendLoading}
                                    onClick={() => handleResendClick(email)}
                                    className=' text-zentive-green-dark'
                                >
                                    {' '}
                                    Click here to resend it
                                </button>
                            </>
                        )}
                    </p>
                </div>
            </CardContent>
        </Card>
    )
}

export default SignUpVerification
