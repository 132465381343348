import { FullProfileType } from '@/api/profile/schema'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useAtomValue } from 'jotai/react'

const useViewedAsUser = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)

    // When admin is currently logged in and selected a business owner to view
    // use the atom of the business owner stored in viewedAsUserAtom
    const currentUser = useAtomValue(
        isViewedAsOwner ? viewedAsUserAtom : userAtom,
    ) as FullProfileType

    return currentUser
}

export default useViewedAsUser
