import { CardTitle } from '@/components/Card'
import { TableRow, TableCell, Table, TableBody } from '@/components/Table'
import ExpenseTableHead from './ExpenseTable/ExpenseHeader'
import { ExpenseType } from '@/api/quoting/schema'

const Expense = ({ expense }: { expense: ExpenseType[] }) => {
    return (
        <div className='border border-[#EBEBEB] p-6 my-16 rounded-md'>
            <div className='flex flex-row justify-between w-full mb-6'>
                <CardTitle className='text-[18px] font-bold'>Expenses</CardTitle>
            </div>
            <Table className='table-auto whitespace-normal mx-auto'>
                <ExpenseTableHead />
                <TableBody className='bg-white'>
                    {expense?.length ? (
                        expense
                            ?.filter((exp) => !exp.isNotIncludedInInvoice)
                            ?.map((data) => (
                                <TableRow className='whitespace-nowrap'>
                                    <TableCell className=' text-[#191A0A] text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                        {data.accountName}
                                    </TableCell>
                                    <TableCell className=' text-[#191A0A] text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                        {data.description}
                                    </TableCell>
                                    <TableCell className=' text-[#191A0A] text-base max-w-[200px] text-ellipsis overflow-hidden'>
                                        {data.date}
                                    </TableCell>
                                    <TableCell className=' text-[#191A0A] text-base max-w-[200px] text-ellipsis overflow-hidden'>
                                        $
                                        {data.total.toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        })}
                                    </TableCell>
                                </TableRow>
                            ))
                    ) : (
                        <TableRow className='whitespace-nowrap'>
                            <TableCell className=' text-[#191A0A] text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                --
                            </TableCell>
                            <TableCell className=' text-[#191A0A] text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                --
                            </TableCell>
                            <TableCell className=' text-[#191A0A] text-base max-w-[200px] text-ellipsis overflow-hidden'>
                                --
                            </TableCell>
                            <TableCell className=' text-[#191A0A] text-base max-w-[200px] text-ellipsis overflow-hidden'>
                                --
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    )
}

export default Expense
