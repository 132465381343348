import { useState, useEffect, useCallback } from 'react'
import { GoogleMap, Polyline } from '@react-google-maps/api'
import Marker from './Marker'
import { CustomerProfileType } from '@/api/profile/schema'
import { mapBoundsAtom } from '@/store/location'
import { useAtom, useAtomValue } from 'jotai'
import {
    isLandmarkShownAtom,
    isPolylineLoadingAtom,
    routeCustomerListAtom,
    routePolylineAtom,
} from '@/store/route'
import polyline from '@mapbox/polyline'

const containerStyle = {
    borderRadius: '8px',
    width: '100%',
    height: '100%',
}

const Map = () => {
    const [map, setMap] = useState<google.maps.Map | null>(null)
    const [activeMarker, setMarker] = useState<string | null>(null)
    const [isMounted, setIsMounted] = useState<boolean>(false)
    const [previousBounds, setPreviousBounds] = useAtom(mapBoundsAtom)
    const customerList = useAtomValue(routeCustomerListAtom)
    const polylineResult = useAtomValue(routePolylineAtom)
    const isPolylineLoading = useAtomValue(isPolylineLoadingAtom)
    const isLandmarkShown = useAtomValue(isLandmarkShownAtom)
    const [visibility, setVisibility] = useState<string>('off')
    const [polylines, setPolylines] = useState<
        {
            lat: number
            lng: number
        }[]
    >([])

    useEffect(() => {
        if (isLandmarkShown) {
            setVisibility('on')
        } else {
            setVisibility('off')
        }
    }, [isLandmarkShown])

    const mapOptions = {
        keyboardShortcuts: false,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: true,
        zoomControl: true,
        styles: [
            {
                featureType: 'poi',
                stylers: [
                    {
                        visibility: visibility,
                    },
                ],
            },
        ],
    }

    // const coordinates =
    const [coordinates, setCoordinates] = useState<CustomerProfileType[]>([])

    const handleLoad = useCallback((mapInstance: google.maps.Map) => {
        setMap(mapInstance)
    }, [])

    useEffect(() => {
        setIsMounted(true)
    }, [])

    useEffect(() => {
        if (polylineResult?.encodedPolyline) {
            setCoordinates(polylineResult.customers)
            const decodedPolyline = polyline
                .decode(polylineResult?.encodedPolyline)
                ?.map(([lat, lng]) => ({ lat, lng }))
            setPolylines(decodedPolyline)
        } else {
            setCoordinates(customerList!)
        }
    }, [polylineResult, customerList])

    useEffect(() => {
        if (coordinates!?.length > 0 && map) {
            const bounds = new google.maps.LatLngBounds()
            coordinates!.forEach((coord: CustomerProfileType) => {
                bounds.extend(
                    new google.maps.LatLng(
                        coord.address.gmapLat as number,
                        coord.address.gmapLng as number,
                    ),
                )
            })

            setPreviousBounds(bounds)
        }
    }, [coordinates, map])

    useEffect(() => {
        if (!isMounted || !map || !coordinates) return
        const bounds = new google.maps.LatLngBounds()

        coordinates.forEach((coord: CustomerProfileType) => {
            bounds.extend(
                new google.maps.LatLng(
                    coord.address.gmapLat as number,
                    coord.address.gmapLng as number,
                ),
            )
        })

        if (!coordinates?.length && previousBounds) {
            map.fitBounds(previousBounds)
        } else {
            map.fitBounds(bounds)
            setPreviousBounds(bounds)
        }

        if (coordinates?.length === 1) {
            map.setZoom(10)
        }
    }, [coordinates, isMounted, map])

    return (
        <div className='relative w-full h-full'>
            {isPolylineLoading && (
                <div className='absolute inset-0 flex items-center justify-center z-50 backdrop-filter backdrop-brightness-50'>
                    <span className='text-white text-xl'>Loading...</span>
                </div>
            )}
            <GoogleMap mapContainerStyle={containerStyle} onLoad={handleLoad} options={mapOptions}>
                {isMounted &&
                    typeof google !== 'undefined' &&
                    coordinates!?.map((coord, index) => {
                        return (
                            <Marker
                                activeMarker={activeMarker}
                                coordinate={coord}
                                key={coord.profileId}
                                position={{
                                    lat: coord.address.gmapLat as number,
                                    lng: coord.address.gmapLng as number,
                                }}
                                setMarker={setMarker}
                                text={`${index + 1}`}
                            />
                        )
                    })}
                {isMounted && typeof google !== 'undefined' && (
                    <Polyline
                        path={polylines}
                        options={{
                            strokeColor: '#FF0000',
                            strokeOpacity: 1.0,
                            strokeWeight: 2,
                            icons: [
                                {
                                    icon: {
                                        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                        scale: 2,
                                        strokeColor: '#FF0000',
                                        fillOpacity: 1,
                                        fillColor: '#FF0000',
                                    },
                                    offset: '0%',
                                    repeat: '100px',
                                },
                            ],
                        }}
                    />
                )}
            </GoogleMap>
        </div>
    )
}

export default Map
