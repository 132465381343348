import { Tabs, TabsList, TabsTrigger } from '@radix-ui/react-tabs'
import { useEffect, useState } from 'react'
import Settings from './Settings'
import WorkOrServiceHIstory from './WorkOrServiceHIstory'
import UpdateCustomer from './UpdateCustomer'
import BreadCrumbs from '@/components/BreadCrumbs'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import { useParams } from 'react-router-dom'
import { getCustomerByProfileIdAndBusinessId } from '@/api/profile'
import { getJobsByCustomerProfileID } from '@/api/job'
import { useQuery } from '@tanstack/react-query'
import { formatFullName } from '@/utils/helper'
import { useAtomValue, useSetAtom } from 'jotai'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { CustomerProfileType, FullProfileType } from '@/api/profile/schema'
import { updateCustomerProfileAtom, profileStatusAtom, lastJobDateAtom } from '@/store/customer'
import { BusinessStatusType as ProfileStatusType } from '@/api/business/schema'

const ManageCustomers = () => {
    const [selected, setSelected] = useState<string>('PI')
    const { profileId } = useParams()
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    const setProfileStatus = useSetAtom(profileStatusAtom)
    const setCustomerUpdateProfileData = useSetAtom(updateCustomerProfileAtom)
    const setLastJobDate = useSetAtom(lastJobDateAtom)

    const { data: customerData, isPending: isLoading } = useQuery({
        queryKey: ['customer', profileId, user.business.businessId],
        queryFn: () =>
            getCustomerByProfileIdAndBusinessId(profileId as string, user?.business.businessId),
    })

    // Get the most recent job
    const pagination = {
        page: 1,
        pageSize: 1,
    }

    const { data: workHistory } = useQuery({
        enabled: !!profileId,
        queryKey: ['customerJobs', profileId, user.businessId, pagination],
        queryFn: () =>
            getJobsByCustomerProfileID(profileId as string, user.businessId as string, pagination),
    })

    const lastJobDate = workHistory?.content?.at(0)?.appointment?.startDateTime ?? ''

    const fullName = formatFullName(customerData?.firstName, customerData?.lastName)

    const handleSuccess = (data: CustomerProfileType) => {
        setProfileStatus({ ...data, businessId: user?.businessId } as ProfileStatusType) // This is for the settings tab
        setCustomerUpdateProfileData({ ...data }) // This is for customer profile data for forms
        setLastJobDate(lastJobDate as string)
    }

    useEffect(() => {
        if (customerData) {
            handleSuccess(customerData)
        }
    }, [customerData])

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            {!isLoading && (
                <>
                    <BreadCrumbs
                        titleName={fullName}
                        label={fullName}
                        pathId={customerData?.profileId}
                    />
                    <Tabs
                        defaultValue='PI'
                        onValueChange={(val) => setSelected(val)}
                        className=' mt-8 '
                    >
                        <TabsList className='grid w-full grid-cols-3 mt-7 space-x-4'>
                            <TabsTrigger
                                value='PI'
                                className={
                                    ' h-14 text-lg text-gray-400 data-[state=active]:text-indigo-900 data-[state=active]: border-zentive-blue-dark data-[state=active]:font-bold data-[state=active]:border-b-4 data-[state=active]:bg-zentive-gray-bg sm:truncate ...'
                                }
                            >
                                Personal Information
                            </TabsTrigger>
                            <TabsTrigger
                                value='WSH'
                                className={
                                    ' h-14 text-lg text-gray-400 data-[state=active]:text-indigo-900 data-[state=active]: border-zentive-blue-dark data-[state=active]:font-bold data-[state=active]:border-b-4 data-[state=active]:bg-zentive-gray-bg sm:truncate ...'
                                }
                            >
                                Work or Service History
                            </TabsTrigger>
                            <TabsTrigger
                                value='SET'
                                className={
                                    ' h-14 text-lg text-gray-400 data-[state=active]:text-indigo-900 data-[state=active]: border-zentive-blue-dark data-[state=active]:font-bold data-[state=active]:border-b-4 data-[state=active]:bg-zentive-gray-bg sm:truncate ...'
                                }
                            >
                                Settings
                            </TabsTrigger>
                        </TabsList>
                    </Tabs>
                    <div className='w-full'>
                        {
                            {
                                PI: <UpdateCustomer />,
                                WSH: <WorkOrServiceHIstory />,
                                SET: <Settings />,
                            }[selected]
                        }
                    </div>
                </>
            )}
        </div>
    )
}
export default ManageCustomers
