import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'

export const ServicesEmptyState = () => {
    return (
        <div className='bg-transparent bg-card'>
            <div className='flex justify-center'>
                <img
                    src={emptyPlaceholder}
                    alt='empty-table-placeholder'
                    className='my-12 h-[12rem]'
                />
            </div>
            <p className='pb-4 text-center text-2xl text-white'>Services Will Be Available Soon</p>
        </div>
    )
}
