import { ExpenseType } from '@/api/quoting/schema'
import { Card, CardContent, CardHeader } from '@/components/Card'
import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/Table'
import { FC } from 'react'

type ExpenseWithQuoteProps = {
    expense: ExpenseType[] | undefined
}

const ExpenseWithQuote: FC<ExpenseWithQuoteProps> = ({ expense }) => {
    const isArrayWithContent = expense && Array.isArray(expense) && expense?.length > 0

    if (!isArrayWithContent) {
        return null
    }

    const total = expense
        .reduce((accumulator, item) => accumulator + (item?.total ? item.total : 0), 0)
        .toFixed(2)

    return (
        <Card className='rounded-none'>
            <CardHeader className='bg-zentive-bg-100 h-[55px] flex flex-row items-center justify-between w-full text-[17.07px] font-semibold'>
                Expense
            </CardHeader>
            <CardContent>
                <Table>
                    <TableHeader>
                        <TableRow className='hover:bg-transparent'>
                            <TableHead variant='default'>Name</TableHead>
                            <TableHead variant='default'>Description</TableHead>
                            <TableHead variant='default'>Date</TableHead>
                            <TableHead variant='default'>Total</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {expense?.map((item) => (
                            <TableRow className='hover:bg-transparent' key={item.accountName}>
                                <TableCell className='px-0 text-left'>{item.accountName}</TableCell>
                                <TableCell className='px-0 text-right'>
                                    {item.description}
                                </TableCell>
                                <TableCell className='px-0 text-right'>{item.date}</TableCell>
                                <TableCell className='px-0 text-right'>
                                    ${(item?.total ? item.total : 0).toFixed(2)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter className='bg-transparent'>
                        <TableRow className='hover:bg-transparent'>
                            <TableCell colSpan={4} className='px-0 text-right font-bold'>
                                ${total}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </CardContent>
        </Card>
    )
}

export default ExpenseWithQuote
