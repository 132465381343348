import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { Button } from '@/components/Button'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { OptimizeGroupsSchemaType, OwnerForemanProfileType } from '@/api/routing/schema'
import { FaArrowRotateLeft } from 'react-icons/fa6'
import { useAtomValue } from 'jotai'
import { routeOptimizeAtom } from '@/store/route'

interface OptimizeAlertProps {
    count: number
    getOptimizePolylineMu: (optimizeGroups: OptimizeGroupsSchemaType) => void
    isDisabled: boolean
    startingAddress: OwnerForemanProfileType
}

const OptimizeAlert: React.FC<OptimizeAlertProps> = ({
    count,
    getOptimizePolylineMu,
    isDisabled,
    startingAddress,
}) => {
    const routeOptimize = useAtomValue(routeOptimizeAtom)
    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <Button
                    type='button'
                    disabled={isDisabled}
                    className=' border w-[50%] font-extabold bg-zentive-green-dark text-zentive-green-light hover:text-zentive-green-light hover:border-zentive-green-medium hover:bg-zentive-green-medium disabled:bg-zentive-gray-light disabled:text-zentive-gray-dark'
                >
                    <FaArrowRotateLeft className='mr-1' />
                    Optimize Stops
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white h-[270px] w-[577px] rounded-t-[15px] p-0'>
                <div className='flex gap-5 px-[26.5px] pt-[40px]  mb-4'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                        <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-[24px] text-[#191A0A] mt-[10px]'>
                            Optimize Stops
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-[14px] text-zentive-gray-medium mt-[18px]'>
                            By optimizing the stops all changes that you've created on the order
                            will be disregarded.
                        </AlertDialogDescription>
                        <AlertDialogDescription className='text-left text-[14px] text-zentive-gray-medium mt-[18px]'>
                            Remaining Optimization for today:{' '}
                            <span className=' text-zentive-green-dark '> {count} of 2 </span>
                        </AlertDialogDescription>

                        <AlertDialogDescription className='text-left text-[14px] text-zentive-gray-medium mt-[18px]'>
                            Are you sure you want to optimize stops?
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-[0px] bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[20px] pt-[6px]'>
                    <div className='w-full flex justify-end items-end gap-[16px]'>
                        <AlertDialogCancel className='w-[155px] h-[43px] text-[16px] font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            onClick={() => {
                                if (startingAddress?.profileId && routeOptimize) {
                                    getOptimizePolylineMu({
                                        startingId: startingAddress?.profileId,
                                        routeOptimizeGroups: routeOptimize,
                                    })
                                }
                            }}
                            className='w-[155px] h-[43px] text-[16px] font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            Optimize
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default OptimizeAlert
