import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import BreadCrumbs from '@/components/BreadCrumbs'
import 'react-datepicker/dist/react-datepicker.css'
import OptimizationHeader from './OptimizationHeader'
import { useAtomValue, useSetAtom } from 'jotai'
import emptyPlaceholder from '@/assets/private/no-route-optimization.png'
import { routeOptimizeAtom, routePolylineAtom } from '@/store/route'
import { OwnerForemanProfileType, RouteOptimizeType, RoutePolylineType } from '@/api/routing/schema'
import { useEffect, useState } from 'react'
import { isViewedAsAtom, viewedAsUserAtom, userAtom } from '@/store/auth'
import { getCustomersRoute } from '@/api/routing'
import { useQuery } from '@tanstack/react-query'
import OptimizationBody from './OptimizationBody'

const RouteOptimization = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const setRouteOptimize = useSetAtom(routeOptimizeAtom)
    const [customers, setCustomers] = useState<RouteOptimizeType[]>([])
    const setPolylineList = useSetAtom(routePolylineAtom)

    const { data } = useQuery({
        queryKey: ['customers'],
        queryFn: () => getCustomersRoute(user?.businessId as string),
        enabled: !!user?.businessId,
    })

    const onSuccess = (data: RouteOptimizeType[]) => {
        setRouteOptimize(data)
        setCustomers(data)
        if (data[0].encodedPolyline != '') {
            const profiless = data
                .filter((profile) => profile.isIncluded)
                ?.map((profile) => profile.profile)
                .filter((profile): profile is OwnerForemanProfileType => !!profile)

            const polylines: RoutePolylineType = {
                customers: profiless,
                encodedPolyline: data[0].encodedPolyline!,
            }
            // setCustomerRoutes(profiles)
            setPolylineList(polylines)
        }
    }

    useEffect(() => {
        if (data) {
            onSuccess(data)
        }
    }, [data])

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName='Route Optimization' />
            <OptimizationHeader />
            {customers?.length == 0 ? (
                <div className='bg-white w-auto rounded-sm mt-10 py-10'>
                    <div className='w-full'> </div>
                    <div className='flex justify-center'>
                        <img
                            src={emptyPlaceholder}
                            alt='empty-route-placeholder'
                            className='my-12 h-60'
                        />
                    </div>
                    <div className='flex flex-col'>
                        <p className='pb-2 text-center text-2xl text-zentive-green-dark font-semibold'>
                            No Data Available for Route Optimization
                        </p>
                        <p className='pb-8 text-center text-md'>
                            You do not have any properties to optimize.
                        </p>
                    </div>
                </div>
            ) : (
                <OptimizationBody />
            )}
        </div>
    )
}

export default RouteOptimization
