import { User } from '@supabase/gotrue-js'
import { z } from 'zod'
import { addressSchema } from '../address/schema'
import { businessDetails, businessSchema } from '../business/schema'
import { baseSchema } from '../common/schema'
import { email, employeeProfileDetailsSchema, profileDetails } from '../profile/schema'
import { lowercaseRegex, numericRegex, specialRegex, uppercaseRegex } from '@/utils/regex'

export const dummyUpdateAdminSchema = profileDetails.merge(addressSchema)

// Pang UPDATE to ng profile
export const userDataSchema = z
    .object({
        address: addressSchema,
        business: businessSchema.partial(),
        // role:
    })
    .merge(profileDetails)
    .merge(email)
    .merge(baseSchema.partial())

export const EmployeeDataSchema = z
    .object({
        address: addressSchema,
    })
    .merge(employeeProfileDetailsSchema)
    .merge(email)
    .merge(baseSchema.partial())

export const signInSchema = email.merge(
    z.object({
        password: z
            .string({ required_error: 'Password is required' })
            .min(1, { message: 'Password is required' })
            .min(8, { message: 'Password must be at least 8 characters long' }),
    }),
)

export const mcMergeFields = z.object({
    FNAME: z.string(),
    LNAME: z.string(),
    ADDRESS: z.string(),
    PHONE: z.string(),
})

export const subscribeToMailChimpSchema = z.object({
    email_address: z.string(),
    status: z.string(),
    merge_fields: mcMergeFields,
})

// Either this or the resetPasswordSchema remains
export const signUpPasswordSchema = z
    .object({
        newPassword: z
            .string()
            .min(8, { message: 'New password is required' }).trim().regex(uppercaseRegex, {
                message: 'Must Include at least one uppercase letter',
            })
            .regex(specialRegex, {
                message: 'Must include at least one special character',
            })
            .regex(numericRegex, { message: 'Must include at least one number' })
            .regex(lowercaseRegex, {
                message: 'Must include at least one lowercase letter',
            }),
        confirmPassword: z.string().min(8, { message: 'Confirm password is required' }).trim()
    })
    .superRefine((data, ctx) => {
        if (data.newPassword.trim() !== data.confirmPassword.trim()) {
            ctx.addIssue({
                path: ['confirmPassword'],
                code: 'custom',
                message: 'Passwords do not match',
            })
        }
    })

export const signUpFormSchema = profileDetails
    .omit({
        profileImage: true,
    })
    .merge(
        addressSchema.omit({
            addressId: true,
        }),
    )
    .merge(
        businessDetails.pick({
            businessName: true,
            businessWebsite: true,
            employeeCount: true,
            subscriptionTypeId: true,
            stripeCoupon: true,
        }),
    )

// email from api/profile/schema.ts is separated because it is used separately from the profileSchema
// same with resetPasswordEmailSchema, email is used independently
export const signUpSchema = email.merge(
    z.object({
        data: signUpFormSchema,
        password: signUpPasswordSchema,
    }),
)

export const resetPasswordSchema = signUpPasswordSchema

export const resetPasswordEmailSchema = email

export const emailSchema = email

export const updatePasswordSchema = z
    .object({
        currentPassword: z
            .string()
            .min(1, { message: 'Current password is required' })
            .min(8, { message: 'Current password must be at least 8 characters long' }),
        newPassword: z
            .string()
            .min(8, { message: 'New password must be at least 8 characters long' })
            .regex(uppercaseRegex, {
                message: 'New password must include at least one uppercase letter',
            })
            .regex(specialRegex, {
                message: 'New password must include at least one special character',
            })
            .regex(numericRegex, { message: 'New password must include at least one number' })
            .regex(lowercaseRegex, {
                message: 'New password must include at least one lowercase letter',
            }),
        confirmPassword: z.string().min(1, { message: 'Confirm password is required' }),
    })
    .superRefine((data, ctx) => {
        if (
            data.newPassword &&
            data.confirmPassword &&
            data.newPassword.trim() !== data.confirmPassword.trim()
        ) {
            ctx.addIssue({
                code: 'custom',
                path: ['confirmPassword'],
                message: 'Passwords do not match',
            })
        }
    })

// Merge email + picked phoneNumber
export const checkEmailAndPhoneValiditySchema = email
    .merge(
        profileDetails.pick({
            phoneNumber: true,
        }),
    )
    .extend({
        businessId: z.string().optional(),
    })

export const checkEmailAndPhoneValidityResponseSchema = z.object({
    isEmailExist: z.boolean(),
    isPhoneNumberExist: z.boolean(),
})

export const checkEmailValiditySchema = email.merge(
    z.object({
        isEmailExist: z.boolean().optional(),
    }),
)

export const NotificationContentSchema = z.object({
    category: z.string(),
    title: z.string(),
    message: z.string(),
})

export const NotificationResponseSchema = z.object({
    id: z.string(),
    content: NotificationContentSchema,
    to: z.string(),
    from: z.string(),
    read: z.boolean(),
    createdAt: z.string(), // to replace once refactoring is done
    createdBy: z.string(), // to replace once refactoring is done
    updatedAt: z.string(), // to replace once refactoring is done
    updatedBy: z.string(), // to replace once refactoring is done
})

export const NotificationListSchema = z.object({
    notifications: z.array(NotificationResponseSchema),
    unreadCount: z.number().optional(),
})

export const NotificationSchema = z.object({
    content: z.array(NotificationResponseSchema),
})

export const crewForgotPasswordSchema = z.object({
    email: z.string(),
    lang: z.string().optional(),
})

export type NotificationType = z.infer<typeof NotificationListSchema>
export type NotificationResponseType = z.infer<typeof NotificationResponseSchema>

export type SignUpValidityRequest = z.infer<typeof checkEmailAndPhoneValiditySchema>
export type SignupValidityType = z.infer<typeof checkEmailAndPhoneValidityResponseSchema>
export type EmailValidityType = z.infer<typeof checkEmailValiditySchema>
export type ResetPasswordEmailSchemaType = z.infer<typeof resetPasswordEmailSchema>
export type EmailSchemaType = z.infer<typeof emailSchema>
export type ResetPasswordType = z.infer<typeof resetPasswordSchema>
export type SignInType = z.infer<typeof signInSchema>
export type SignUpType = z.infer<typeof signUpSchema>

export type SignUpResponseType = User

export type UpdatePasswordType = z.infer<typeof updatePasswordSchema>

export type UserDataType = z.infer<typeof userDataSchema>
export type EmployeeDataType = z.infer<typeof EmployeeDataSchema>
export type CrewForgotPasswordType = z.infer<typeof crewForgotPasswordSchema>
export type MCSubscriptionType = z.infer<typeof subscribeToMailChimpSchema>
