import { ResetPasswordType } from '@/api/auth/schema'
import { FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { InputPassword } from '@/components/InputPassword'
import { useFormContext } from 'react-hook-form'

const UseForm = () => {
    const { control } = useFormContext<ResetPasswordType>()
    return (
        <div className='flex grid-cols-2 gap-4 justify-center items-center'>
            <div className='col-span-1'>
                <FormField
                    name='newPassword'
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <InputPassword
                                    className='font-sans-pro pl-3 w-107 h-11 mt-[20px] border border-gray-500'
                                    placeholder='New Password*'
                                    hasConfirmPassword
                                    isReverse={true}
                                    {...field}
                                />
                            </FormControl>
                        </FormItem>
                    )}
                />
                <FormField
                    name='confirmPassword'
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <InputPassword
                                    className='font-sans-pro pl-3 w-107 h-11 mt-[20px] border border-gray-500'
                                    placeholder='Confirm New Password*'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </div>
    )
}

export default UseForm
