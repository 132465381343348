import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'

const CPDashboard = () => {
    return (
        <div className='h-[500px]'>
            <div className='mt-[45px] ml-[75px] text-2xl text-zentive-gray-medium font-semibold'>
                Dashboard
            </div>
            <div className='h-full flex flex-col justify-center items-center'>
                <img src={emptyPlaceholder} alt='email-logo' className=' w-full mb-6 h-100' />
                <div className='text-[#504026] text-[24px] font-semibold'> Coming Soon... </div>
            </div>
        </div>
    )
}

export default CPDashboard
