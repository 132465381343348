import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import CreateCustomer from './AddCustomer'
import { cn } from '@/utils/helper'

const CreateCustomerView = () => {
    return (
        <div className={cn('w-full h-full', BREADCRUMBS_PADDING_STYLE)}>
            <CreateCustomer />
        </div>
    )
}
export default CreateCustomerView
