import { AddBulkEmployeeType } from '@/api/employee/schema'
import { FullProfileType, InviteEmployeeViaCSVType } from '@/api/profile/schema'
import { useToast } from '@/hooks/useToast'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai'
import { useRef, useState } from 'react'
import ImportCustomerCSVModal from './ImportCustomerCSVModal'
import { addBulkCustomer } from '@/api/profile'

const ImportEmployeeCSV = () => {
    const inputFileRef = useRef<HTMLInputElement | null>(null)
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const { toast } = useToast()
    const [employeeCSVModal, setEmployeeCSVModal] = useState<boolean>(false)

    const queryClient = useQueryClient()

    const { mutate: addBulkEmployeeMu } = useMutation<
        InviteEmployeeViaCSVType,
        AxiosError,
        AddBulkEmployeeType
    >({
        mutationFn: addBulkCustomer,
        onSuccess: (data) => {
            if (data?.failedImports?.length > 0) {
                setEmployeeCSVModal(true)
            } else {
                queryClient.invalidateQueries({ queryKey: ['Customers'] })
                toast({
                    description: 'Customer profiles has been added successfully',
                    variant: 'default',
                })
            }
            if (inputFileRef.current) {
                inputFileRef.current.value = ''
            }
        },
        onError: (err: AxiosError) => {
            console.log('Create Employee Error: ', err.message)
            toast({
                description: `Something went wrong, please follow the input guide from the CSV template.`,
                variant: 'destructive',
            })
            if (inputFileRef.current) {
                inputFileRef.current.value = ''
            }
        },
    })

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
        if (files && files?.length > 0) {
            const file = files[0]
            addBulkEmployeeMu({ businessId: user.businessId, employeeCSV: file })
        }
    }

    const handleClick = () => {
        inputFileRef.current?.click()
    }

    return (
        <>
            <ImportCustomerCSVModal open={employeeCSVModal} setOpen={setEmployeeCSVModal} />
            <input
                type='file'
                accept='.csv,.xlsx,.xls'
                ref={inputFileRef}
                className='hidden'
                onChange={handleFileInputChange}
            />
            <button
                className='w-full h-8 hover:bg-[#00000029] text-left px-3'
                onClick={handleClick}
            >
                Import CSV
            </button>
        </>
    )
}

export default ImportEmployeeCSV
