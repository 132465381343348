import { convertImageToBase64 } from '@/utils/helper'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import { useAtomValue } from 'jotai'
import { jobCompletedByEmployeeExportAtom } from '@/store/reports'
import { JobsCompletedByEmployeeExportType } from '@/api/reports/schema'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { GenerateJobsCompletedByEmployeePDF } from './GenerateJobsCompletedByEmployeePDF'

const ExportJobsCompletedByEmployeePDF = () => {
    const exportAtom = useAtomValue(jobCompletedByEmployeeExportAtom)

    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    if (exportAtom === null || !exportAtom?.records?.length) return null

    const handleExportToPDF = async () => {
        convertImageToBase64(zentiveLogo)
            .then((zentiveLogoUrl) => {
                const tableData: JobsCompletedByEmployeeExportType = {
                    records:
                        exportAtom?.records?.map((r) => ({
                            jobCrewMemberId: r.jobCrewMemberId ?? '',
                            jobNumber: r.jobNumber ?? '',
                            jobId: r.jobId ?? '',
                            employeeName: r.employeeName ?? '',
                            services: r.services ?? [],
                            invoiceAmount: r.invoiceAmount ?? 0,
                            laborCost: r.laborCost ?? 0,
                            date: r.date ?? '',
                            address: r.address ?? '',
                            invoiceStatus: r.invoiceStatus ?? '',
                            timeSpent: r.timeSpent ?? '',
                        })) ?? [],
                    totalInvoiceAmount: exportAtom?.totalInvoiceAmount ?? 0,
                    totalLaborCost: exportAtom?.totalLaborCost ?? 0,
                    totalTimeSpent: exportAtom?.totalTimeSpent ?? 0,
                } as JobsCompletedByEmployeeExportType

                GenerateJobsCompletedByEmployeePDF(
                    zentiveLogoUrl,
                    tableData,
                    user?.business?.businessName ?? 'Zentive',
                )
            })
            .catch((err) => console.error('Error converting image to Base64:', err.message))
    }

    return (
        <button
            className='w-[135px] h-[34px] text-gray-800 hover:bg-[#00000029] block px-4 py-2 text-base rounded-t-md'
            onClick={handleExportToPDF}
        >
            Export as PDF
        </button>
    )
}
export default ExportJobsCompletedByEmployeePDF
