 import { QuoteType } from '@/api/quoting/schema' 
 

const HeaderClientDetails = ({quote}:{quote: QuoteType}) => {  

    return (
        <div className='mx-5 mt-6'>
            <div className='text-[#0C3D3C] font-extrabold text-[20px] mt-4'>
                {quote?.name} 
            </div>
            <div className='flex flex-row'>
                <div className='flex flex-col mr-16'>
                    <div className='flex flex-row pt-6  mb-4'>
                        <span className='font-extrabold text-[16px]'>Property Address</span> 
                    </div>
                    <p className='text-zentive-gray-medium'>{quote?.address?.streetAddress}</p>
                    <p className='text-zentive-gray-medium'>{quote?.address?.city}, {quote?.address?.state} {quote?.address?.zipCode}</p>
                </div>
                <div className='flex flex-col mb-4'>
                    <span className='font-extrabold pt-6 mb-4 text-[16px]'>Contact Details</span>
                    <p className='text-zentive-gray-medium'>{quote?.profile?.phoneNumber}</p>
                    <p className='text-zentive-gray-medium'>{quote?.profile?.email}</p>
                </div>
            </div>
        </div>
    )
}

export default HeaderClientDetails
