import { InvoiceListType } from '@/api/customer/schema'

import { TableHeader, TableRow, TableHead, TableBody, TableCell, Table } from '@/components/Table'
import { cn } from '@/utils/helper'
import { FaArrowDownLong } from 'react-icons/fa6'
import InvoiceDialog from './InvoiceDialog'
import InvoiceStatusBar from '@/components/InvoiceStatusBar'

interface InvoiceTypeListProps {
    invoiceList: InvoiceListType[] | undefined
}

const tableHeader = ['Date', 'Invoice Number', 'Bill Amount', 'Balance', 'Status', 'Receipt']

const InvoiceTable = ({ invoiceList }: InvoiceTypeListProps) => {
    return (
        <Table>
            <TableHeader>
                <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                    {tableHeader?.map((header) => (
                        <TableHead
                            key={`${header}-head`}
                            className={cn(
                                'flex-row items-center text-center font-semibold text-zentive-gray-medium text-base',
                                header === 'Date'
                                    ? 'items-start text-start'
                                    : 'items-center text-center',
                            )}
                        >
                            <div className='inline-flex gap-[10px]'>
                                {header}
                                {header === 'Date' || (header === 'Status' && <FaArrowDownLong />)}
                            </div>
                        </TableHead>
                    ))}
                </TableRow>
            </TableHeader>
            <TableBody>
                {invoiceList?.map((body) => (
                    <TableRow key={body.invoiceInfo.created} className='whitespace-nowrap'>
                        <TableCell className='flex-row items-start text-start  text-[#191A0A] text-base '>
                            {body.invoiceInfo.created}
                        </TableCell>
                        <TableCell className='flex-row  items-center text-center  text-[#191A0A] text-base '>
                            {body.invoiceInfo.number}
                        </TableCell>
                        <TableCell className='flex-row items-center text-center  text-[#191A0A] text-base '>
                            ${(body.invoiceInfo.amount / 100)?.toLocaleString()}
                        </TableCell>
                        <TableCell className='flex-row items-center text-center  text-[#191A0A] text-base '>
                            $
                            {(
                                (body.invoiceInfo.status === 'paid' ? 0 : body.invoiceInfo.amount) /
                                100
                            )?.toLocaleString()}
                        </TableCell>
                        <TableCell className='flex flex-row items-center justify-center'>
                            <InvoiceStatusBar status={body.invoiceInfo.status} />
                        </TableCell>
                        <TableCell className='items-center justify-center'>
                            <InvoiceDialog invoiceList={body} />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
export default InvoiceTable
