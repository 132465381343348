import { z } from 'zod'
import { baseSchema } from '../common/schema'
import { profileSchema } from '../profile/schema'

export const noteSchema = baseSchema.extend({
    noteId: z.string().optional(),
    note: z.string({ required_error: 'Note is required' })
        .refine((value) => value.trim()?.length > 0, {
            message: 'Note cannot be empty or only whitespace',
        })
        .refine((value) => !/^\s/.test(value), {
            message: 'Note cannot start with white space',
        })
        .refine((value) => !/\s$/.test(value), {
            message: 'Note cannot end with white space',
        }),
    profileId: z.string().optional(),
    jobId: z.string().optional()
})

export const profileNoteSchema = profileSchema
    .pick({
        profileId: true,
        businessId: true,
    })
    .extend({
        notes: z.array(noteSchema),
    })

export const noteResponseSchema = z.object({
    data: z.array(profileNoteSchema),
    message: z.string(),
})

export type NotePayloadType = z.infer<typeof noteSchema>
export type NoteResponseType = z.infer<typeof noteResponseSchema>
export type ProfileNoteResponseType = z.infer<typeof profileNoteSchema>
