import { Card, CardContent, CardFooter, CardHeader } from '@/components/Card'
import BreadCrumbs from '@/components/BreadCrumbs'
import { BREADCRUMBS_PADDING_STYLE, COMPLETE_FORMAT_WITHOUT_TIME } from '@/constants'
import { useAtom, useAtomValue } from 'jotai'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { FullProfileType } from '@/api/profile/schema'
import { jobPaginationAtom } from '@/store/job'
import { useQuery } from '@tanstack/react-query'
import { getJobsByCustomerProfileID } from '@/api/job'
import { useEffect } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { cn, getQuoteServiceNames } from '@/utils/helper'
import { FaArrowDownLong } from 'react-icons/fa6'
import dayjs from 'dayjs'
import { Pagination } from '@/components/Pagination'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'

const tableHeader = ['Date', 'Job Charge', 'Services', 'Address']

const CPHistory = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const [pagination, setPagination] = useAtom(jobPaginationAtom)

    const { data: workHistory } = useQuery({
        queryKey: ['customerJobs', user.profileId, user.businessId, pagination],
        queryFn: () =>
            getJobsByCustomerProfileID(
                user.profileId as string,
                user.businessId as string,
                pagination,
            ),
    })

    useEffect(() => {
        setPagination({
            page: 1,
            pageSize: 5,
        })
    }, [setPagination])

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName='History' />
            <Card className='w-full'>
                <CardHeader></CardHeader>
                <CardContent className='overflow-x-scroll md:overflow-x-auto'>
                    <Table>
                        <TableHeader>
                            <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                                {tableHeader?.map((header) => (
                                    <TableHead
                                        key={`${header}-head`}
                                        className={cn(
                                            'flex-row items-center text-center font-semibold text-zentive-gray-medium text-base',
                                            header === 'Date'
                                                ? 'items-start text-start'
                                                : 'items-center text-center',
                                        )}
                                    >
                                        <div className='inline-flex gap-[10px]'>
                                            {header}
                                            {header === 'Date' && <FaArrowDownLong />}
                                        </div>
                                    </TableHead>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {workHistory?.content?.map((job, index) => (
                                <TableRow key={index} className='whitespace-nowrap'>
                                    <TableCell className='flex-row items-start text-start  text-[#191A0A] text-base '>
                                        {dayjs(job?.appointment?.startDateTime).format(
                                            COMPLETE_FORMAT_WITHOUT_TIME,
                                        )}
                                    </TableCell>

                                    <TableCell className='flex-row items-center text-center  text-[#191A0A] text-base '>
                                        {`$${job?.quote?.totalAmount?.toFixed(2)}`}
                                    </TableCell>
                                    <TableCell className='flex-row items-center text-center  text-[#191A0A] text-base '>
                                        {getQuoteServiceNames(job?.quote.productServiceGroup)}
                                    </TableCell>
                                    <TableCell className='flex-row items-center text-center  text-[#191A0A] text-base '>
                                        {job?.quote?.address?.streetAddress +
                                            ', ' +
                                            job?.quote?.address?.city +
                                            ', ' +
                                            job?.quote?.address?.state}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {!!workHistory?.content?.length && (
                        <div className='mb-5 mt-3'>
                            {pagination.pageSize !== undefined && (
                                <Pagination
                                    itemsPerPage={pagination.pageSize}
                                    setPagination={setPagination}
                                    totalRecords={workHistory?.meta.totalRecords ?? 1}
                                    pagination={pagination}
                                />
                            )}
                        </div>
                    )}
                    {!workHistory?.content?.length && (
                        <div className='mx-4 my-10'>
                            <div className='flex justify-center rounded-md bg-white'>
                                <img
                                    src={emptyPlaceholder}
                                    alt='empty-table-placeholder'
                                    className='my-8 h-60'
                                />
                            </div>
                            <p className='pb-4 text-center text-2xl text-black mt-4'>
                                {' '}
                                {`Oops, there are no jobs yet.`}{' '}
                            </p>
                        </div>
                    )}
                </CardContent>
                <CardFooter className='items-end justify-end'></CardFooter>
            </Card>
        </div>
    )
}
export default CPHistory
