import { Card, CardContent } from '@/components/Card'
import Calendar from './FullCalendar'
import { StatusFilter } from './StatusFilter'
import { useState } from 'react'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'

const BigCalendar = () => {
    const [isUnassignedToggled, setIsUnassigedToggled] = useState<boolean>(false)

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <h1 className='text-zentive-gray-medium font-semibold mb-2 text-2xl'>Calendar</h1>
            <Card className='bg-transparent border border-none'>
                <CardContent className=''>
                    <section className='flex flex-row gap-5'>
                        <StatusFilter setIsUnassigedToggled={setIsUnassigedToggled} />
                        <Calendar isUnassigedToggled={isUnassignedToggled} />
                    </section>
                </CardContent>
            </Card>
        </div>
    )
}

export default BigCalendar
