import { convertImageToBase64 } from '@/utils/helper'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo.png'
import dayjs from 'dayjs'
import { PDFJobLogExport } from '..'
import { JobListType, JobLogResponseType } from '@/api/job/schema'
import { jobStatus, JobStatusType } from '../../../ManageJobLogs/JobLogInfo'
import { getJobLogEndTime, getJobLogStartTime } from '@/utils/time'

interface IExportDataToPDF {
    date: string
    job: string
    customer: string
    crew: string
    start: string
    finish: string
    status: string
    invoiceStatus: string
}
interface IExportJobLogPDFProps {
    jobs?: JobListType
}

const ExportJobLogPDF = ({ jobs }: IExportJobLogPDFProps) => {
    if (jobs === null || !jobs?.content?.length) return null

    const handleExportToPDF = async () => {
        convertImageToBase64(zentiveLogo)
            .then((zentiveLogoUrl) => {
                const tableData: IExportDataToPDF[] = jobs.content?.map((job: JobLogResponseType) => ({
                    date: dayjs(job?.appointment?.startDateTime).format('MMMM DD, YYYY'),
                    job: job.quote.productServiceGroup?.[0]?.name,
                    customer:
                        job.quote &&
                        job.quote?.profile?.firstName + ' ' + job.quote?.profile?.lastName,
                    crew: job.crew?.crewNumber ? 'Crew 00' + job.crew?.crewNumber : 'Unassigned',
                    start: getJobLogStartTime(job),
                    finish: getJobLogEndTime(job),
                    status: jobStatus?.[job?.status as JobStatusType]?.text,
                    invoiceStatus: 'Upon Job Completion',
                })) as IExportDataToPDF[]

                PDFJobLogExport(zentiveLogoUrl, tableData)
            })
            .catch((err) => console.error('Error converting image to Base64:', err.message))
    }

    return (
        <button
            className='w-[135px] h-[34px] text-gray-800 hover:bg-[#00000029] block px-4 py-2 text-base rounded-t-md'
            onClick={handleExportToPDF}
        >
            Export as PDF
        </button>
    )
}
export default ExportJobLogPDF
