import { useAtomValue } from 'jotai'
import JobList from './JobList'
import JobStatuses from './JobStatuses'
import JobFilterDrawer from './JobDateFilter'
import StatusFilter from './StatusFilter'
import { getJobListByCrewMemberId } from '@/api/job'
import { userAtom } from '@/store/auth'
import { useQuery } from '@tanstack/react-query'
import { selectedDateFilterAtom } from '@/store/crew'
import Spinner from '@/components/Spinner'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import { getEmployeeById } from '@/api/profile'
import { FullProfileType } from '@/api/profile/schema'

const CrewJobs = () => {
    const selectedDateFilter = useAtomValue(selectedDateFilterAtom)
    const user = useAtomValue(userAtom) as FullProfileType

    const { businessId = '', profileId = '', crewMemberId = '' } = user
    const { startDate = '', endDate = '' } = selectedDateFilter

    const { data: employee, isLoading: isEmployeeLoading } = useQuery({
        enabled: !!profileId,
        queryKey: ['employee', profileId],
        queryFn: () => getEmployeeById(profileId),
    })

    const { data, isLoading: isJobListLoading } = useQuery({
        refetchInterval: 3000,
        enabled: !!(businessId && crewMemberId && profileId),
        queryKey: ['crewPortalJobList', businessId, crewMemberId, endDate, profileId, startDate],
        queryFn: () =>
            getJobListByCrewMemberId({
                businessId: businessId,
                crewMemberId: crewMemberId,
                endDate: endDate,
                keyword: '',
                profileId: profileId,
                startDate: startDate,
                status: '',
            }),
    })

    const jobList = data === undefined ? [] : data?.content

    if (isEmployeeLoading || isJobListLoading)
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-white'>
                <Spinner variant='normal' size='10' />
            </div>
        )

    if (!employee?.crewMemberId)
        return (
            <>
                <div className='mx-4 my-16'>
                    <div className='bg-white rounded-md flex justify-center'>
                        <img
                            src={emptyPlaceholder}
                            alt='empty-table-placeholder'
                            className='my-24 h-44'
                        />
                    </div>
                    <p className='pb-4 text-center text-2xl text-white mt-6'>
                        You're not a part of any crew yet
                    </p>
                </div>
                <JobFilterDrawer />
            </>
        )

    return (
        <div>
            <JobStatuses jobList={jobList} />
            <StatusFilter jobList={jobList} />
            <JobList jobList={jobList} />
            <JobFilterDrawer />
        </div>
    )
}

export default CrewJobs
