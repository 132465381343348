import { HiOutlineExclamationTriangle } from 'react-icons/hi2'

export const ConflictingCrewSchedule = () => {
    return (
        <div className='flex flex-col gap-5 items-center justify-center text-center py-10'>
            <section className='flex flex-row gap-5'>
                <span className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                    <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                </span>
                <p className='mt-5 font-bold text-lg text-zentive-red-dark'>
                    Crew Assignment Conflict
                </p>
            </section>
            <section className='max-w-[32rem] flex flex-col gap-5 text-bas tracking-wide'>
                <p>
                    Crew cannot be assigned due to a scheduling conflict. They are already booked
                    for another job on the conflicting date
                </p>
                <p>Please select a different crew.</p>
            </section>
        </div>
    )
}
