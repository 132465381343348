import { useMemo } from 'react'
import { MarkerF, InfoWindowF } from '@react-google-maps/api'
import zentiveMarker from '@/assets/private/zentive-marker.png'
import { GeocodeResult } from '@/hooks/useGeocode'
import { MarkerProps } from '@react-google-maps/api'
import { CustomerProfileType } from '@/api/profile/schema'

type CustomerMarkerProps = Pick<MarkerProps, 'clusterer'> & {
    activeMarker: string | null
    coordinate: CustomerProfileType
    position: GeocodeResult
    text: string
    setMarker: (val: null | string) => void
}

const Marker = ({
    activeMarker,
    clusterer,
    coordinate,
    position,
    text,
    setMarker,
}: CustomerMarkerProps) => {
    const iconProps = useMemo(
        () => ({
            anchor: new window.google.maps.Point(17, 46),
            scaledSize: new window.google.maps.Size(31, 45),
            labelOrigin: new google.maps.Point(15.5, 15.5),
            url: zentiveMarker,
            zIndex: 50,
        }),
        [],
    )

    const markerLabel = useMemo(
        () => ({
            text: text,
            color: '#3b671a',
            fontSize: '14px',
        }),
        [text],
    )

    const {
        profileId,
        firstName,
        lastName,
        address: { city, state, streetAddress, zipCode },
    } = coordinate

    return (
        <MarkerF
            key={profileId}
            position={position}
            icon={iconProps}
            label={markerLabel}
            clusterer={clusterer}
            onClick={() => setMarker(profileId)}
        >
            {activeMarker === profileId ? (
                <InfoWindowF options={{ minWidth: 280 }} onCloseClick={() => setMarker(null)}>
                    <div className='p-2 space-y-2'>
                        <div className='text-zentive-green-dark text-[14px] font-semibold flex flex-row items-center gap-2'>
                            <div className='rounded-full bg-zentive-green-dark w-fit h-fit text-white p-2'>
                                {text}
                            </div>
                            {firstName} {lastName}
                        </div>
                        <p className='text-[14px]'>
                            {streetAddress}, {city}, {zipCode}, {state}
                        </p>
                    </div>
                </InfoWindowF>
            ) : null}
        </MarkerF>
    )
}

export default Marker
