import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { Button } from '@/components/Button'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { routeOptimizeAtom } from '@/store/route'
import { useAtomValue } from 'jotai'
import { RouteOptimizeType } from '@/api/routing/schema'

interface ApplyAlertProps {
    count: number
    getPolylineMu: (profiles: RouteOptimizeType[]) => void
    isDisabled: boolean
}

const ApplyAlert: React.FC<ApplyAlertProps> = ({ count, getPolylineMu, isDisabled }) => {
    const routeOptimize = useAtomValue(routeOptimizeAtom)

    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <Button
                    className='py-1 h-full border border-zentive-green-dark tracking-wide bg-white text-sm text-zentive-green-dark font-semibold hover:text-zentive-green-light hover:border-zentive-green-medium hover:bg-zentive-green-medium'
                    variant='secondary'
                    disabled={isDisabled}
                    type={'button'}
                >
                    Apply Changes
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white h-[270px]  w-[577px] rounded-t-[15px] p-0'>
                <div className='flex gap-5 px-[26.5px] pt-[40px] mb-4'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                        <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-[24px] text-[#191A0A] mt-[10px]'>
                            Apply Changes
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-[14px] text-zentive-gray-medium mt-[18px]'>
                            By applying the changes your map view will be updated to your new chosen
                            route.
                        </AlertDialogDescription>
                        <AlertDialogDescription className='text-left text-[14px] text-zentive-gray-medium mt-[18px]'>
                            Remaining arrangements for today:{' '}
                            <span className=' text-zentive-green-dark '> {count} of 10 </span>
                        </AlertDialogDescription>

                        <AlertDialogDescription className='text-left text-[14px] text-zentive-gray-medium mt-[18px]'>
                            Are you sure you want to apply changes?
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-[0px] bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[20px] pt-[6px]'>
                    <div className='w-full flex justify-end items-end gap-[16px]'>
                        <AlertDialogCancel className='w-[155px] h-[43px] text-[16px] font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            onClick={() => {
                                if (routeOptimize) {
                                    getPolylineMu(routeOptimize)
                                }
                            }}
                            className='w-[155px] h-[43px] text-[16px] font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            Apply Changes
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default ApplyAlert
