import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@/components/Button'
import { useEffect, useState } from 'react'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import BreadCrumbs from '@/components/BreadCrumbs'
import { FaPencilAlt } from 'react-icons/fa'
import WrappedRoutingMapView from './ViewMap'
import { currentLocationAtom } from '@/store/route'
import { useAtomValue } from 'jotai'
import moment from 'moment-timezone'
import { formatDistanceToNow } from 'date-fns'
import useRoutePolyline from '@/hooks/useRoutePolyline'

const TimeAgo: React.FC<{ dateString: string | undefined }> = ({ dateString }) => {
    const [timeAgo, setTimeAgo] = useState('')

    useEffect(() => {
        if (dateString) {
            const parsedDate = moment(dateString).toDate()
            const timeDiff = formatDistanceToNow(parsedDate, { addSuffix: true })

            setTimeAgo(timeDiff)
        }
    }, [dateString])

    return (
        <div className='text-zentive-gray-medium'>Last Update: {dateString ? timeAgo : '--'}</div>
    )
}

const ViewRoute = () => {
    const currentLocation = useAtomValue(currentLocationAtom)

    const navigate = useNavigate()
    const { routeId } = useParams()
    const { data, isLoading, isSuccess } = useRoutePolyline(routeId as string)

    if (isLoading) {
        return 'Loading...'
    }

    if (isSuccess && data) {
        return (
            <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
                <BreadCrumbs titleName={`Route ${data.routeNumber}`} />

                <div className='mt-10 '>
                    <div className='w-full flex justify-end '>
                        <Button
                            className='mr-4  mb-2 text-[16px] bg-white border'
                            onClick={() => navigate(`/schedule/routing/edit-route/${routeId}`)}
                            type='button'
                            variant='secondary'
                        >
                            <FaPencilAlt className='h-4 w-4 cursor-pointer mr-2 ' />
                            Edit Route
                        </Button>
                    </div>

                    <TimeAgo dateString={currentLocation?.lastLocationUpdate} />

                    <WrappedRoutingMapView />
                </div>
            </div>
        )
    }

    return null
}

export default ViewRoute
