import { CustomerProfileType } from '@/api/profile/schema'
import InvoiceStatusBar from '@/components/InvoiceStatusBar'

const HeaderClientDetails = ({
    profile,
    status,
    invoiceNumber,
    sharedNotes,
}: {
    profile: CustomerProfileType
    status?: string
    invoiceNumber?: number
    sharedNotes?: string
}) => {
    return (
        <div className='flex flex-row justify-between'>
            <div className='flex flex-col mx-5 mt-2'>
                <p className='text-2xl font-semibold leading-none tracking-tight'>Invoice for</p>
                <div className='text-[#0C3D3C] font-extrabold text-[20px]'>
                    {profile?.firstName} {profile?.lastName}
                </div>
                <div className='flex flex-row gap-x-1'>
                    <div className='flex flex-col whitespace-no-wrap'>
                        <div className='pt-6 mb-4'>
                            <span className='font-extrabold text-[16px]'>Property Address</span>
                        </div>
                        <div>
                            <p className='text-zentive-gray-medium'>
                                {profile?.address?.streetAddress}
                            </p>
                            <p className='text-zentive-gray-medium'>
                                {profile?.address?.city}, {profile?.address?.state}{' '}
                            </p>
                            <p className='text-zentive-gray-medium'>{profile?.address?.zipCode}</p>
                        </div>
                    </div>
                    <div className='flex flex-col mb-4'>
                        <span className='font-extrabold pt-6 mb-4 text-[16px]'>
                            Contact Details
                        </span>
                        <p className='text-zentive-gray-medium'>{profile?.phoneNumber}</p>
                        <p className='text-zentive-gray-medium'>{profile?.email}</p>
                    </div>
                    <div className='flex flex-row'>
                        <div className='font-extrabold text-[16px] mr-4 pt-6'> Status: </div>{' '}
                        <InvoiceStatusBar status={status as string} />
                    </div>
                </div>
            </div>
            <div className='flex flex-shrink-0'>
                <div>
                    <div className='flex flex-row'>
                        <p className='text-xl font-semibold'>Invoice Number: &nbsp;</p>
                        <p className='border-2 rounded-md pr-20 py-0'> 00{invoiceNumber}</p>
                    </div>
                    <div>
                        <p className='text-xl font-semibold'>Shared Notes:</p>
                        <p className='border-2 h-24 break-all rounded-md'>
                            {sharedNotes ?? '--'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderClientDetails
