import autoTable from 'jspdf-autotable'
import jsPDF from 'jspdf'
import dayjs from 'dayjs'
import { InvoiceReportExportType } from '@/api/reports/schema'
import { RECORD_PREFIX, US_FORMAT } from '@/constants'
import { displayCurrency, formatRecordNumber } from '@/utils/helper'

export const GenerateInvoiceReportPDF = (
    zentiveLogoUrl: string,
    tableData: InvoiceReportExportType,
    businessName: string,
) => {
    const doc = new jsPDF({ orientation: 'landscape' })

    let startY = 10

    const imageHeight = 17
    const imageWidth = 50
    const pageWidth = doc.internal.pageSize.getWidth()
    const centerX = (pageWidth - imageWidth) / 2

    doc.addImage(zentiveLogoUrl, 'JPEG', centerX, startY, imageWidth, imageHeight)

    startY += imageHeight + 10

    doc.setFontSize(16)
    doc.text(businessName + ' Invoice Report', pageWidth / 2, startY, {
        align: 'center',
    })

    startY += 10

    // Define flex-like layout for the overview section
    doc.setFontSize(10)
    doc.setTextColor('#707070')

    // Overview header
    doc.setFont('Helvetica', 'bold')
    doc.text('Overview', 15, startY)

    doc.setTextColor('#707070')
    startY += 6 // Move down to start content

    // Unpaid, Past Due, and Total Records labels
    const labelGap = 60 // Gap between each section (approximate)
    const initialX = 15 // Starting X position

    let currentX = initialX
    doc.setFont('Helvetica', 'normal')

    doc.text(`Unpaid/Awaiting Payment (${tableData.unpaid})`, currentX, startY)
    currentX += labelGap

    doc.text(`Past Due (${tableData.pastDue})`, currentX, startY)
    currentX += labelGap

    doc.text(`Total Records (${tableData.totalRecords})`, currentX, startY)

    startY += 6 // Move down for the values

    // Reset X for values
    currentX = initialX

    // Unpaid/Awaiting Payment value
    doc.setFont('Helvetica', 'bold')
    doc.text(displayCurrency(tableData.totalUnpaid), currentX, startY)
    currentX += labelGap

    // Past Due value
    doc.text(displayCurrency(tableData.totalPastDue), currentX, startY)
    currentX += labelGap

    // Total Records value
    doc.text(displayCurrency(tableData.totalAmount), currentX, startY)

    startY += 5 // Space before the table

    const head = [
        [
            'Invoice Number',
            'Customer Name',
            'Email Address',
            'Invoice Amount',
            'Date',
            'Status',
            'Contact Number',
            'Billing Address',
            'Processed By',
        ],
    ]

    const body = tableData?.records?.map((data) => [
        RECORD_PREFIX.invoice + formatRecordNumber(data.invoiceNumber as number) ?? 'N/A', // Ensure that the value is either string or number
        data.customerName ?? '',
        data.email ?? '',
        displayCurrency(data.invoiceAmount),
        dayjs(data.date).format(US_FORMAT),
        data.status ?? '',
        data.phoneNumber ?? '',
        data.address ?? '',
        data.processedBy ?? '',
    ])

    autoTable(doc, {
        startY: startY,
        head: head,
        body: body,
        theme: 'striped',
        styles: { fontSize: 8, cellPadding: 2, overflow: 'linebreak' },
        headStyles: { fillColor: [59, 103, 26], textColor: [255, 255, 255], fontSize: 8 },
        didDrawPage: (data) => {
            const pageCount = doc.getNumberOfPages()
            const footerStr = `Page ${data.pageNumber} of ${pageCount}`
            doc.setFontSize(10)
            doc.text(footerStr, data.settings.margin.left, doc.internal.pageSize.getHeight() - 10)
        },
    })

    doc.save('invoice-report.pdf')
}
