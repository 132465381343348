import { CSVLink } from 'react-csv'
import dayjs from 'dayjs'
import { InvoiceReportExportType, InvoiceReportRecordType } from '@/api/reports/schema'
import { displayCurrency, formatPhoneNumber, formatRecordNumber } from '@/utils/helper'
import { RECORD_PREFIX, US_FORMAT } from '@/constants'
import { useAtomValue } from 'jotai'
import { invoiceReportExportAtom } from '@/store/reports'
import { E164Number } from 'libphonenumber-js/core'

const ExportInvoiceReportCSV = () => {
    const exportAtom = useAtomValue(invoiceReportExportAtom)

    if (exportAtom === null || !exportAtom?.records?.length) return null

    const tableData: InvoiceReportExportType | null =
        ((exportAtom &&
            exportAtom?.records &&
            exportAtom?.records.length > 0 && {
                records: exportAtom?.records.map((r) => ({
                    invoiceId: r?.invoiceId,
                    invoiceNumber: r?.invoiceNumber,
                    customerName: r?.customerName,
                    email: r?.email ?? '',
                    invoiceAmount: r?.invoiceAmount ?? '',
                    date: r?.date,
                    phoneNumber: r?.phoneNumber ?? '',
                    address: r?.address ?? '',
                    status: r?.status ?? '',
                    processedBy: r?.processedBy ?? '',
                })),
                unpaid: exportAtom?.unpaid ?? 0,
                totalUnpaid: exportAtom?.totalUnpaid ?? 0,
                totalPastDue: exportAtom?.totalPastDue ?? 0,
                pastDue: exportAtom?.pastDue ?? 0,
                totalRecords: exportAtom?.totalRecords ?? 0,
                totalAmount: exportAtom?.totalAmount ?? 0,
            }) as InvoiceReportExportType) || null

    if (!tableData) return

    const csvHeaders = [
        'Invoice Number',
        'Customer Name',
        'Email Address',
        'Invoice Amount',
        'Date',
        'Status',
        'Contact Number',
        'Billindg Address',
        'Processed By',
    ]

    const csvData =
        tableData?.records?.map((r: InvoiceReportRecordType) => ({
            'Invoice Number': RECORD_PREFIX.invoice + formatRecordNumber(r?.invoiceNumber),
            'Customer Name': r.customerName,
            'Email Address': r.email,
            'Invoice Amount': displayCurrency(r.invoiceAmount),
            Date: dayjs(r.date).format(US_FORMAT),
            Status: r.status as string,
            'Contact Number': formatPhoneNumber(r.phoneNumber as E164Number),
            'Billindg Address': r.address,
            'Processed By': r.processedBy,
        })) || []

    csvData.push({
        'Invoice Number': '',
        'Customer Name': '',
        'Email Address': '',
        'Invoice Amount': '',
        Date: '',
        Status: '',
        'Contact Number': '',
        'Billindg Address': '',
        'Processed By': '',
    })

    csvData.push({
        'Invoice Number': 'Overview',
        'Customer Name': '',
        'Email Address': 'Unpaid/Awaiting Payment: ' + displayCurrency(exportAtom?.totalUnpaid),
        'Invoice Amount': '',
        Date: 'Past Due: ' + displayCurrency(exportAtom?.totalPastDue),
        Status: '',
        'Contact Number': 'Total: ' + displayCurrency(exportAtom?.totalAmount),
        'Billindg Address': '',
        'Processed By': '',
    })

    return (
        <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={'invoice-report.csv'}
            className={
                'w-[135px] h-[34px] text-[#191A0A] hover:bg-[#00000029] block px-5 py-1 text-base rounded-b-md'
            }
        >
            Export as CSV
        </CSVLink>
    )
}

export default ExportInvoiceReportCSV
