import { FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { Textarea } from '@/components/TextArea'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { ProductServiceType } from '@/api/service/schema'

export const UpdateInputForm = () => {
    const { control, setValue, getValues } = useFormContext<ProductServiceType>()
    const [, setServiceType] = useState('service')
    const [unitPriceError, setUnitPriceError] = useState('')
    const [markupError, setMarkupError] = useState('')

    const handleUnitPriceChange = (unitPrice: number | string) => {
        const parsedUnitPrice = parseFloat(unitPrice as string)
        if (isNaN(parsedUnitPrice)) {
            setUnitPriceError('A number is required. Please fill in this field.')
            setValue('unitPrice', 0)
            setValue('cost', 0)
            return
        }
        setUnitPriceError('')
        const markup = getValues(`markup`)
        setValue(`unitPrice`, parsedUnitPrice)
        if (!isNaN(markup ?? 0)) {
            const totalCost = Number(
                (parsedUnitPrice + (parsedUnitPrice * markup!) / 100).toFixed(2),
            )
            setValue(`cost`, totalCost)
        }
    }

    const handleMarkupChange = (markup: number | string) => {
        const parsedMarkup = parseFloat(markup as string)
        if (isNaN(parsedMarkup)) {
            setMarkupError('A number is required. Please fill in this field.')
            setValue('markup', 0)
            setValue('cost', 0)
            return
        }
        setMarkupError('')
        const unitPrice = getValues(`unitPrice`)
        setValue(`markup`, parsedMarkup)
        if (!isNaN(unitPrice)) {
            const totalCost = Number((unitPrice! + (unitPrice! * parsedMarkup) / 100).toFixed(2))
            setValue(`cost`, totalCost)
        }
    }

    return (
        <div className='flex flex-col px-[5.93rem] py-[2.62rem]'>
            <h1 className='font-bold text-2xl text-zentive-black'>Update Service / Product</h1>
            <div className='mt-10'>
                <FormField
                    control={control}
                    name='type'
                    render={({}) => (
                        <FormItem>
                            <FormControl>
                                <select
                                    name='row'
                                    onChange={(val) => setServiceType(val.target.value)}
                                    className='w-full items-center font-sans text-base pl-3 h-11 border border-black disabled:bg-gray-100 text-gray-900 rounded'
                                >
                                    <option
                                        role='text'
                                        value='service'
                                        selected={getValues().type === 'service'}
                                    >
                                        Service
                                    </option>
                                    <option
                                        role='text'
                                        value='product'
                                        selected={getValues().type === 'product'}
                                    >
                                        Product
                                    </option>
                                </select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <div className='mt-10'>
                <FormField
                    control={control}
                    name='name'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    className='w-full items-center font-sans text-base h-11 border border-black disabled:bg-gray-100 text-gray-900'
                                    placeholder='Name'
                                    type='text'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>

            <div className='mt-10'>
                <FormField
                    control={control}
                    name='description'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Textarea
                                    className='py-4 w-full min-h-[7.8rem] items-center font-sans text-base border border-black disabled:bg-gray-100 text-gray-900'
                                    placeholder='Description'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>

            <div className='flex flex-row mt-10 w-full gap-4 pb-6'>
                <FormField
                    control={control}
                    name='unitPrice'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    className='font-sans pl-1 text-base h-11 border border-black text-gray-900 disabled:bg-gray-100 rounded-sm'
                                    placeholder='Unit Price $'
                                    type='number'
                                    {...field}
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === '-' ||
                                            e.key === '+' ||
                                            e.key === 'e' ||
                                            e.key === 'E'
                                        ) {
                                            e.preventDefault()
                                        }
                                    }}
                                    onChange={(e) => handleUnitPriceChange(e.target.value)}
                                />
                            </FormControl>
                            {unitPriceError && (
                                <span className='text-red-700'>{unitPriceError}</span>
                            )}
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={control}
                    name='markup'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    className='font-sans pl-1 text-base h-11 border border-black text-gray-900 disabled:bg-gray-100 rounded-sm'
                                    placeholder='Markup %'
                                    type='number'
                                    {...field}
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === '-' ||
                                            e.key === '+' ||
                                            e.key === 'e' ||
                                            e.key === 'E'
                                        ) {
                                            e.preventDefault()
                                        }
                                    }}
                                    onChange={(e) => handleMarkupChange(e.target.value)}
                                />
                            </FormControl>
                            {markupError && <span className='text-red-700'>{markupError}</span>}
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={control}
                    name='cost'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    className='font-sans pl-1 text-base h-11 border border-black text-gray-900 disabled:bg-gray-100 rounded-sm'
                                    placeholder='Cost $'
                                    type='number'
                                    disabled
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </div>
    )
}
