import { BillingInfoType } from '@/api/profile/schema'
import { Label } from '@radix-ui/react-label'
import { useNavigate } from 'react-router-dom'

interface UpdateCardDetailsProps {
    billingInfoData: BillingInfoType | undefined
}
const UpdateCardDetails = ({ billingInfoData }: UpdateCardDetailsProps) => {
    const navigate = useNavigate()
    return (
        <section>
            <Label>Payment Method</Label>
            <div className='w-full mb-[37px] pb-[16px] ring-1 rounded-[4px] ring-[#EBEBEB]'>
                <div className='flex justify-between mt-[6.5px] pt-[16.5px]'>
                    <div className='flex-col justify-start pl-[16px]'>
                        <p className='text-base font-semibold'>{billingInfoData?.lastFourNo}</p>
                    </div>
                    <div className='flex-col fjustify-start pr-[16px]'>
                        <button
                            className='m-auto'
                            onClick={() => {
                                navigate('/customer-portal/payments/customer-change-card')
                            }}
                        >
                            <p className='text-zentive-green-dark  m-auto'>
                                Update Payment Details
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default UpdateCardDetails
