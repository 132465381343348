import { useState, useEffect, useRef } from 'react'
import { Menu } from '@headlessui/react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { Button } from '@/components/Button'

interface IEditDropDown {
    isEditing: boolean
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
    type: string
    deleteFn: () => void
}

const EditDropDown = ({ isEditing, setIsEditing, type, deleteFn }: IEditDropDown) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const menuRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const onOpenMenu = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div ref={menuRef} className='relative inline-block text-left'>
            <Menu>
                <Menu.Button onClick={onOpenMenu}>
                    <BsThreeDotsVertical />
                </Menu.Button>

                {isOpen && !isEditing && (
                    <div className='w-[125px] max-w-[125px] absolute right-3 z-10 rounded-md bg-white shadow-lg'>
                        <div className='flex flex-col justify-start items-start w-full rounded-md'>
                            <Button
                                type='button'
                                variant={'ghost'}
                                className='w-18 pr-14 hover:bg-transparent'
                                onClick={deleteFn}
                            >
                                {' '}
                                Delete{' '}
                            </Button>
                            <Button
                                type='button'
                                variant={'ghost'}
                                className='w-18 pr-6 hover:bg-transparent'
                                onClick={() => {
                                    setIsEditing(true)
                                    setIsOpen(false)
                                }}
                            >
                                {' '}
                                {type === 'photo' ? 'Edit Images' : 'Edit Description'}
                            </Button>
                        </div>
                    </div>
                )}
            </Menu>
        </div>
    )
}

export default EditDropDown
