import jobsForTodaySvg from '@/assets/private/jobs-for-today-icon.svg'
import inProgressSvg from '@/assets/private/in-progress-icon.svg'
import needInvoicingSvg from '@/assets/private/need-invoicing-icon.svg'
import { Button } from '@/components/Button'
import { useNavigate } from 'react-router-dom'
import { PlusIcon } from '@radix-ui/react-icons'
import { useAtomValue } from 'jotai'
import { isViewedAsAtom, timezoneAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useQuery } from '@tanstack/react-query'
import { DashboardJobRecordsType } from '@/api/reports/schema'
import { getOwnerJobsDashboardRecords } from '@/api/reports'
import { formatToUTCWithOffset } from '@/utils/time'
import { ISO_8601_WITH_UTC_OFFSET } from '@/constants'
import dayjs from 'dayjs'
import Spinner from '@/components/Spinner'

export const JobsChart = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const tz = useAtomValue(timezoneAtom)

    const navigate = useNavigate()

    const { data: jobChartRecords, isLoading: jobChartLoading } = useQuery<DashboardJobRecordsType>(
        {
            queryKey: ['dashboardJobChart', user],
            queryFn: () =>
                getOwnerJobsDashboardRecords(
                    user?.businessId as string,
                    formatToUTCWithOffset(
                        dayjs().format(ISO_8601_WITH_UTC_OFFSET),
                        tz?.timeZoneId ?? '',
                        ISO_8601_WITH_UTC_OFFSET,
                    ),
                ),
        },
    )

    return (
        <div className='flex flex-row gap-10 rounded-lg'>
            <div className='bg-white p-5 w-[70rem] h-[10rem]'>
                <div>
                    <h1 className='font-semibold text-zentive-gray-medium'>JOBS</h1>
                </div>
                {jobChartLoading ? (
                    <Spinner variant={'normal'} className='h-[70px] w-[70px]' />
                ) : (
                    <div className='grid grid-cols-3 px-10 '>
                        <div className='flex flex-row gap-3 px-10 border border-1 border-zentive-gray-light border-y-0 border-l-0'>
                            <div>
                                <img src={jobsForTodaySvg} className='mt-1' />
                            </div>
                            <div className='flex flex-col gap-1'>
                                <p className='text-4xl font-bold text-zentive-green-dark'>
                                    {jobChartRecords?.jobsToday ?? 0}
                                </p>
                                <p className='text-md text-zentive-gray-medium'>Jobs Today</p>
                            </div>
                        </div>
                        <div className='flex flex-row gap-3 px-10 border border-1 border-zentive-gray-light border-y-0 border-l-0'>
                            <div>
                                <img src={inProgressSvg} className='mt-2' />
                            </div>
                            <div className='flex flex-col gap-1'>
                                <p className='text-4xl font-bold text-zentive-green-200'>
                                    {jobChartRecords?.inProgress ?? 0}
                                </p>
                                <p className='text-md text-zentive-gray-medium'>In Progress</p>
                            </div>
                        </div>
                        <div className='flex flex-row gap-3 px-10'>
                            <div>
                                <img src={needInvoicingSvg} className='mt-1' />
                            </div>
                            <div className='flex flex-col gap-1'>
                                <p className='text-4xl font-bold text-zentive-red-dark'>
                                    {jobChartRecords?.needInvoicing ?? 0}
                                </p>
                                <p className='text-md text-zentive-gray-medium'>Needs Invoicing</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div>
                <Button onClick={() => navigate('/schedule/jobs/new-job')} className='h-full px-10'>
                    <PlusIcon />
                    Create Job
                </Button>
            </div>
        </div>
    )
}
