import { Pie } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { useAtomValue } from 'jotai'
import { useQuery } from '@tanstack/react-query'
import { DashboardQuoteSentRecordsType } from '@/api/reports/schema'
import { getDashboardQuoteSentChartRecords } from '@/api/reports'
import Spinner from '@/components/Spinner'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { ISO_8601, ISO_8601_WITH_UTC_OFFSET } from '@/constants'
import { SelectSingleEventHandler } from 'react-day-picker'
import { useToast } from '@/hooks/useToast'
import { OneJobDatePicker } from '@/components/OneOffJobDatePicker'
import { NavLink } from 'react-router-dom'

// Register the required components from Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const QuoteChart = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const chartRef = useRef<ChartJS<'pie'> | null>(null)

    const [start, setStart] = useState<string>('')
    const [end, setEnd] = useState<string>('')

    const { toast } = useToast()

    const { data: quoteChartReport, isLoading: quoteChartLoading } =
        useQuery<DashboardQuoteSentRecordsType>({
            queryKey: ['quoteChartReport', user?.businessId, start, end],
            queryFn: () =>
                getDashboardQuoteSentChartRecords(user?.businessId as string, start, end),
        })

    const setStartDateTime = (date: Date | undefined) => {
        // Extract the Month, Day, and Year from the date in ISO 8601 format
        const newValueDate = dayjs(date).format(ISO_8601)
        const formattedStart = dayjs(`${newValueDate}`).format(ISO_8601_WITH_UTC_OFFSET)

        const present = new Date()
        const formattedPresent = dayjs(present).format(ISO_8601_WITH_UTC_OFFSET)

        if (end != '' && dayjs(formattedStart).isAfter(present)) {
            toast({
                description: 'Start date cannot be a future date',
                variant: 'destructive',
                duration: 2000,
            })

            setTimeout(() => {
                setStart(formattedPresent)
            }, 1000)

            return
        }

        setStart(formattedStart)

        return formattedStart
    }

    const setStartDate: SelectSingleEventHandler = (date: Date | undefined) =>
        setStartDateTime(date)

    const setEndDateTime = (date: Date | undefined) => {
        // Extract the Month, Day, and Year from the date in ISO 8601 format
        const newValueDate = dayjs(date).format(ISO_8601)
        const formattedEnd = dayjs(`${newValueDate}`).format(ISO_8601_WITH_UTC_OFFSET)
        setEnd(formattedEnd)

        return formattedEnd
    }

    const setEndDate: SelectSingleEventHandler = (date: Date | undefined) => setEndDateTime(date)

    // Prepare data for the chart
    const chartData = {
        labels: ['Accepted', 'Rejected', 'Pending'],
        datasets: [
            {
                data: [
                    quoteChartReport?.accepted || 0,
                    quoteChartReport?.rejected || 0,
                    quoteChartReport?.pending || 0,
                ],
                backgroundColor: ['#34C73B', '#FBF28A', '#FC7061'],
                borderColor: '#fff',
                borderWidth: 1,
            },
        ],
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right' as const,
            },
        },
    }

    useEffect(() => {
        if (dayjs(start).isAfter(dayjs(end))) {
            toast({
                description: 'Start date cannot be greater than or equal to the end date',
                variant: 'destructive',
                duration: 2000,
            })

            setTimeout(() => {
                setStart(dayjs(`${new Date()}`).format(ISO_8601_WITH_UTC_OFFSET))
                setEnd(dayjs(`${new Date()}`).format(ISO_8601_WITH_UTC_OFFSET))
            }, 1000)
        }
    }, [start, end])

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.destroy()
        }
    }, [])

    return (
        <div className='flex flex-col items-center h-full w-full bg-white p-7 min-h-[35rem] max-h-[35rem]'>
            <div className='w-full p-5'>
                <div className='flex flex-row gap-2'>
                    <div className='flex flex-col w-full'>
                        <OneJobDatePicker
                            setJobDate={setStartDate}
                            jobDate={start}
                            label='Start Date'
                            showOutsideDates={true}
                        />
                    </div>
                    <div className='flex flex-col w-full'>
                        <OneJobDatePicker
                            setJobDate={setEndDate}
                            jobDate={end}
                            label='End Date'
                            showOutsideDates={true}
                        />
                    </div>
                </div>

                {quoteChartLoading ? (
                    <Spinner variant={'normal'} className='h-[70px] w-[70px]' />
                ) : (
                    <>
                        <div className='mt-8 flex flex-row gap-5 text-center items-center justify-center w-full'>
                            <div>
                                <p className='text-lg text-zentive-gray-medium'>Total Quote Sent</p>
                                <p className='text-4xl font-bold text-zentive-green-dark'>
                                    {quoteChartReport?.totalQuoteSent ?? 0}
                                </p>
                            </div>

                            <div>
                                <p className='text-lg text-zentive-gray-medium'>Accepted</p>
                                <p className='text-4xl font-bold text-zentive-green-dark'>
                                    {quoteChartReport?.accepted ?? 0}
                                </p>
                            </div>

                            <div>
                                <p className='text-lg text-zentive-gray-medium'>Rejected</p>
                                <p className='text-4xl font-bold text-zentive-green-dark'>
                                    {quoteChartReport?.rejected ?? 0}
                                </p>
                            </div>

                            <div>
                                <p className='text-lg text-zentive-gray-medium'>Pending</p>
                                <p className='text-4xl font-bold text-zentive-green-dark'>
                                    {quoteChartReport?.pending ?? 0}
                                </p>
                            </div>
                        </div>

                        <div className='flex flex-row items-start justify-start w-full mt-[3rem]'>
                            <Pie
                                data={chartData}
                                options={options}
                                className='max-h-[15rem] min-h-[15rem]'
                                id='quote'
                                ref={chartRef}
                            />
                        </div>
                    </>
                )}
            </div>
            <div className='flex flex-row items-start justify-start w-full pl-5 mt-5'>
                <NavLink className='text-zentive-green-dark' to={'/reports/quote-reports'}>
                    Check Reports
                </NavLink>
            </div>
        </div>
    )
}

export default QuoteChart
