import { updateProfile } from '@/api/profile'
import { Form } from '@/components/Forms'
import { useToast } from '@/hooks/useToast'
import { customerImgAtom, lastJobDateAtom, updateCustomerProfileAtom } from '@/store/customer'
import { getDefaultFormValues, formatLastJobDate } from '@/utils/user'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'
import _ from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useBlocker, useNavigate } from 'react-router-dom'
import BaseCustomer from '../BaseCustomer'
import DiscardModal from '../BaseCustomer/CustomerModals/DiscardModal'
import { validateAddress } from '@/hooks/useGeocode'
import { SELECT_VALID_STREET_ADDRESS } from '@/constants'
import { CustomerProfileType, customerProfileSchema } from '@/api/profile/schema'

const UpdateCustomer: FC = () => {
    const navigate = useNavigate()
    const { toast } = useToast()
    const customerProfileData = useAtomValue(updateCustomerProfileAtom)
    const lastJobDateUnformatted = useAtomValue(lastJobDateAtom)
    const [selectedImageAtom, setSelectedImageAtom] = useAtom(customerImgAtom)

    const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false)

    const updateCustomerMethods = useForm<CustomerProfileType>({
        mode: 'onChange',
        resolver: zodResolver(customerProfileSchema),
    })

    const {
        handleSubmit,
        setValue,
        getValues,
        reset,
        trigger,
        formState: { isValid },
    } = updateCustomerMethods

    const { mutate: updateCustomerMu, isPending } = useMutation({
        mutationFn: updateProfile,
        onSuccess: () => {
            toast({
                description: 'Customer profile has been successfully updated',
                variant: 'default',
            }),
                navigate('/customers/customer-list'),
                setSelectedImageAtom([])
        },
        onError: (error) => {
            console.log('Update customer error:', error.message)
            toast({
                description: 'There was an error updating this profile',
                variant: 'destructive',
            })
        },
    })

    useEffect(() => {
        if (selectedImageAtom) {
            setValue('profileImage', selectedImageAtom[0]?.file)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedImageAtom])

    const {
        firstName = '',
        lastName = '',
        phoneNumber = '',
        address: { streetAddress = '', streetTwoAddress = '' } = {},
    } = getDefaultFormValues(customerProfileData as CustomerProfileType) || {} // This is for inital form value to compare with latest form value

    const initialFormValues = useMemo(() => {
        return {
            firstName,
            lastName,
            phoneNumber,
            streetAddress,
            streetTwoAddress,
        }
    }, [firstName, lastName, phoneNumber, streetAddress, streetTwoAddress])

    const formVal = getValues()

    const updatedFormValues = useMemo(() => {
        return {
            firstName: formVal.firstName,
            lastName: formVal.lastName,
            phoneNumber: formVal.phoneNumber,
            streetAddress: formVal.address?.streetAddress,
            streetTwoAddress: formVal.address?.streetTwoAddress || '',
        }
    }, [
        formVal.firstName,
        formVal.lastName,
        formVal.phoneNumber,
        formVal.address?.streetAddress,
        formVal.address?.streetTwoAddress,
    ]) // Form values that is updated based on user's input

    const isFormDirty = useCallback(() => {
        if (!_.isEqual(initialFormValues, updatedFormValues) || selectedImageAtom?.length > 0) {
            return true
        } else {
            return false
        }
    }, [initialFormValues, updatedFormValues, selectedImageAtom])

    const blocker = useBlocker(({ currentLocation, nextLocation }) => {
        // Check if navigation is to a different location
        const isDifferentLocation = currentLocation.pathname !== nextLocation.pathname

        const isBlockNeeded = isFormDirty() && !isPending

        return isBlockNeeded && isDifferentLocation
    })

    useEffect(() => {
        // Listen for changes in the blocker state
        if (blocker.state === 'blocked') {
            setIsDiscardModalOpen(true)
        } else {
            setIsDiscardModalOpen(false)
        }
    }, [blocker.state, setIsDiscardModalOpen])

    const onSubmit = async (data: CustomerProfileType) => {
        try {
            const addressToValidate = `${data?.address?.streetAddress || ''} ${
                data?.address?.state || ''
            }`
            await validateAddress(addressToValidate)
            const newImageData = selectedImageAtom[0]?.file
            updateCustomerMu({ ...data, profileImage: newImageData, business: {}, businessId: '' })
        } catch (err) {
            console.error(err)
            updateCustomerMethods.setError('address.streetAddress', {
                type: 'manual',
                message: SELECT_VALID_STREET_ADDRESS,
            })
        }
    }

    const handleDiscard = () => {
        setValue('firstName', customerProfileData?.firstName)
        setValue('lastName', customerProfileData?.lastName)
        setValue('phoneNumber', customerProfileData?.phoneNumber ?? '')
        setValue('address.streetAddress', customerProfileData?.address?.streetAddress)
        setValue('address.streetTwoAddress', customerProfileData?.address?.streetTwoAddress ?? '')
        setValue('address.city', customerProfileData?.address?.city)
        setValue('address.state', customerProfileData?.address?.state)
        setValue('address.zipCode', customerProfileData?.address?.zipCode)
        setValue('profileImage', customerProfileData?.profileImage)
        setValue('createdAt', customerProfileData?.createdAt)

        setIsDiscardModalOpen(false)
        setSelectedImageAtom([])
        navigate(-1)

        reset({
            ...getDefaultFormValues(customerProfileData as CustomerProfileType),
            ...formatLastJobDate(lastJobDateUnformatted),
        })
    }

    const handleCancel = () => {
        setIsDiscardModalOpen(false)
        blocker.state = 'unblocked' // Reset the blocker state
    }

    useEffect(() => {
        if (customerProfileData !== null) {
            reset({
                ...getDefaultFormValues(customerProfileData as CustomerProfileType),
                ...formatLastJobDate(lastJobDateUnformatted),
            })
            trigger()
        }
    }, [customerProfileData])

    return (
        <Form {...updateCustomerMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <BaseCustomer
                    mode='update'
                    isValid={isValid}
                    isSubmitting={isPending}
                    isFormDirty={isFormDirty()}
                />
                <DiscardModal
                    isOpen={isDiscardModalOpen}
                    onClose={handleCancel}
                    handleDiscard={handleDiscard}
                />
            </form>
        </Form>
    )
}

export default UpdateCustomer
