import { startTransition, useEffect, useCallback } from 'react'
import { Outlet } from 'react-router-dom'
import { Sidebar } from './PrivateSideBar/index'
import { Topbar } from './PrivateTopBar'
import MobileMenu from './PrivateSideBar/MenuChild/MobileMenu'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import {
    rememberMeAtom,
    userAtom,
    isViewedAsAtom,
    viewedAsUserAtom,
    timezoneAtom,
    businessIdAtom,
} from '@/store/auth'
import goTrueClient from '@/api/goTrueClient'
import { Toaster } from '@/components/Toaster'
import { EMPTY_UUID, LAPTOP_MAX_WIDTH, ROLE } from '@/constants'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getIsPasswordGenerated, signOut } from '@/api/auth'
import { RESET } from 'jotai/utils'
import CrewBottomNav from './CrewBottomNavBar'
import CrewTopBar from './CrewTopBar'
import './index.css'
import { crewjobSearchOpenAtom, splashScreenAtom } from '@/store/crew'
import { FullProfileType } from '@/api/profile/schema'
import zentiveLogoSplashScreen from '@/assets/common/zentivelogo.png'
import { cn } from '@/utils/helper'
import { useMediaQuery } from 'react-responsive'
import { CustomerTopbar } from './CustomerTopBar'
import { hasProperty } from '@/utils/obj'

const crewPortalCreatePassword = '/crew/create-password'
const dashboardRoutes = ['/crew/jobs', '/crew/route', '/crew/settings', '/crew/profile']
const customerSelectBusiness = `/customer-portal/select-business`

const PrivateLayout = () => {
    const [user, setUser] = useAtom(userAtom)
    const isSearchToggled = useAtomValue(crewjobSearchOpenAtom)
    const [isSplashShown, setIsSplashShown] = useAtom(splashScreenAtom)

    const [owner, setOwner] = useAtom(viewedAsUserAtom)
    const setTimezone = useSetAtom(timezoneAtom)
    const setBusinessId = useSetAtom(businessIdAtom)

    const { pathname } = useLocation()
    const isDashboardRoute = dashboardRoutes.includes(pathname)

    const rememberMe = useAtomValue(rememberMeAtom)
    const [isViewedAs, setIsViewAsSubscriber] = useAtom(isViewedAsAtom)

    const { data: isPasswordGeneratedRes } = useQuery({
        queryKey: ['isPasswordGenerated'],
        queryFn: () => getIsPasswordGenerated(user?.profileId as string),
        retry: 0,
    })

    const RenderSplashScreen = useCallback(() => {
        return (
            <div className='splash-screen z-50'>
                <div className='splash-screen-content'>
                    <img src={zentiveLogoSplashScreen} alt='Zentive Logo' className='' />
                </div>
            </div>
        )
    }, [])

    useEffect(() => {
        if (!isSplashShown) {
            const timer = setTimeout(() => {
                startTransition(() => {
                    setIsSplashShown(true)
                })
            }, 5000)

            return () => clearTimeout(timer)
        }
    }, [isSplashShown, setIsSplashShown])

    useEffect(() => {
        const isViewedAsAndOwner = isViewedAs && hasProperty(owner, 'businessId')
        const notViewedAsAndOwner =
            !isViewedAs &&
            (user?.role?.roleName === ROLE.OWNER || user?.role?.roleName === ROLE.CO_OWNER) &&
            hasProperty(user, 'businessId')

        const businessId = isViewedAsAndOwner
            ? owner.businessId
            : notViewedAsAndOwner
              ? user.businessId
              : ''

        if (businessId) {
            startTransition(() => {
                setBusinessId(businessId)
            })
        }
    }, [isViewedAs, owner, user, setBusinessId])

    useEffect(() => {
        const { data: authListener } = goTrueClient.onAuthStateChange((event, session) => {
            startTransition(() => {
                switch (event) {
                    case 'SIGNED_IN':
                        if (user?.business.subscriptionTypeId === EMPTY_UUID) {
                            console.log('DO SOMETHING WITH NO SUBSCRIPTION')
                        }
                        break
                    case 'TOKEN_REFRESHED':
                        if (!rememberMe) {
                            signOut()
                        }
                        if (!session) {
                            setIsViewAsSubscriber(false)
                            setUser(null)
                        }
                        break
                    case 'SIGNED_OUT':
                        setIsViewAsSubscriber(RESET)
                        setOwner(RESET)
                        setTimezone(RESET)
                        setUser(null)
                        setBusinessId(RESET)
                        break
                    default:
                        break
                }
            })
        })

        return () => {
            if (authListener?.subscription?.unsubscribe) {
                authListener.subscription.unsubscribe()
            }
        }
    }, [
        rememberMe,
        setIsViewAsSubscriber,
        setOwner,
        setTimezone,
        setUser,
        user?.business?.subscriptionTypeId,
    ])

    const xl_vw_already = useMediaQuery({ maxWidth: LAPTOP_MAX_WIDTH })

    const isNotPasswordGenerated = !(user as FullProfileType).isPasswordGenerated

    const shouldShowSidebar =
        !isPasswordGeneratedRes &&
        pathname !== '/customer-portal/create-password' &&
        pathname !== customerSelectBusiness

    const isCrewRole = user && (user as FullProfileType).role.roleName === ROLE.CREW

    return (
        <>
            <Toaster />
            {!isCrewRole && isNotPasswordGenerated ? (
                <div>
                    {!isPasswordGeneratedRes && <MobileMenu />}
                    {shouldShowSidebar && <Sidebar />}
                    <div
                        className={cn(
                            'min-h-screen bg-zentive-gray-bg',
                            !xl_vw_already && pathname !== customerSelectBusiness && 'lg:pl-72',
                        )}
                    >
                        {pathname === customerSelectBusiness ? <CustomerTopbar /> : <Topbar />}
                        <main className='py-10'>
                            <div className='px-4 sm:px-6 lg:px-8'>
                                <Outlet />
                            </div>
                        </main>
                    </div>
                </div>
            ) : (
                <>
                    <div className='flex justify-center h-full w-full overflow-x-hidden'>
                        <div className='w-full max-w-[1024px] md:text-lg border border-y-0 border-x-gray-200 bg-white'>
                            <div className='flex flex-grow'>
                                <main className='w-full'>
                                    {pathname !== crewPortalCreatePassword &&
                                        !isSearchToggled &&
                                        isSplashShown &&
                                        isDashboardRoute && <CrewTopBar />}
                                    <div
                                        className={cn(
                                            'relative crew-interface-bg overflow-y-auto',
                                            pathname.startsWith('/crew/route') && 'h-[80%]',
                                            isDashboardRoute && 'mt-14',
                                        )}
                                    >
                                        <Outlet />
                                    </div>
                                    {pathname !== crewPortalCreatePassword &&
                                        isSplashShown &&
                                        !isSearchToggled &&
                                        isDashboardRoute && <CrewBottomNav />}
                                </main>
                            </div>
                        </div>
                    </div>
                    {!isSplashShown && RenderSplashScreen()}
                </>
            )}
        </>
    )
}

export default PrivateLayout
