import { axiosInstance } from '../axiosInstance'
import { CreateOrUpdateDealStage, DealStage } from './schema'

export const getDealStagesByBusinessId = async (businessId: string): Promise<DealStage[]> => {
    const res = await axiosInstance.get(`/leads/deal-stages/${businessId}`)

    return res.data.content
}

export const createOrUpdateDealStage = async (
    data: CreateOrUpdateDealStage,
): Promise<DealStage> => {
    const res = await axiosInstance.post('/leads/create-update-deal-stage', data)

    return res.data.content
}
