import { IOffers } from '@/assets/static/signupGallery'
import { Table, TableBody, TableCell, TableRow } from '@/components/Table'

const FeatureTable = ({ tableData }: { tableData: Array<IOffers> }) => {
    return (
        <Table className='w-full'>
            <TableBody>
                {tableData?.map((data) => (
                    <TableRow
                        key={data.feature}
                        className='divide-x divide-gray-200 hover:bg-none odd:bg-white even:bg-zentive-green-light'
                    >
                        <TableCell className='w-[50%] text-[16px] pl-[26px] '>
                            {data.feature}
                        </TableCell>
                        <TableCell className='w-[160px] text-center '>
                            <p className='relative inline-block text-[16px] text-zentive-green-dark'>
                                {data.feature === 'Price' ? (
                                    <b>{data.starter}</b>
                                ) : (
                                    <>
                                        {data.starter}{' '}
                                        {data.feature === 'Set-Up Fee' && (
                                            <span className='absolute h-0.5 bg-zentive-red-dark w-full transform -rotate-45 top-1/2 left-0'></span>
                                        )}
                                    </>
                                )}
                            </p>
                        </TableCell>
                        <TableCell className='w-[160px] text-center'>
                            <p className='relative inline-block text-[16px] text-zentive-green-dark'>
                                {data.feature === 'Price' ? (
                                    <b>{data.standard}</b>
                                ) : (
                                    <>
                                        {data.standard}
                                        {data.feature === 'Set-Up Fee' && (
                                            <span className='absolute h-0.5 bg-zentive-red-dark w-full transform -rotate-45 top-1/2 left-0'></span>
                                        )}
                                    </>
                                )}
                            </p>
                        </TableCell>
                        <TableCell className='w-[160px] text-center'>
                            <p className='relative inline-block text-[16px] text-zentive-green-dark'>
                                {data.feature === 'Price' ? (
                                    <b>{data.pro}</b>
                                ) : (
                                    <>
                                        {data.pro}
                                        {data.feature === 'Set-Up Fee' && (
                                            <span className='absolute h-0.5 bg-zentive-red-dark w-full transform -rotate-45 top-1/2 left-0'></span>
                                        )}
                                    </>
                                )}
                            </p>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
export default FeatureTable
