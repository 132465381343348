import { Dialog, DialogTrigger } from '@/components/Dialog'
import SelectCustomer from './SelectCustomer'
import { Button } from '@/components/Button'
import { useAtomValue } from 'jotai'
import { customerQuoteAtom } from '@/store/owner'
import SelectedCustomer from './SelectedCustomer'

const CustomerDetailsWithoutQuote = () => {
    const customer = useAtomValue(customerQuoteAtom)

    return (
        <div className='w-1/2'>
            <Dialog>
                <DialogTrigger asChild={!!!customer} className='w-full'>
                    {customer ? (
                        <SelectedCustomer customer={customer} />
                    ) : (
                        <Button
                            className='h-[96px] w-[396px] text-[20.74px]'
                            type='button'
                            variant='outlineTwo'
                        >
                            Add a Client
                        </Button>
                    )}
                </DialogTrigger>
                <SelectCustomer />
            </Dialog>
        </div>
    )
}

export default CustomerDetailsWithoutQuote
