import { ISubscription } from '@/assets/static/signupGallery'
import FeatureTable from './FeatureTable'
import OffersTable from './OffersTable'
import { useLocation } from 'react-router-dom'
import { FC } from 'react'
import { SubscriptionMatrix, SubscriptionType } from '@/api/subscription/schema'
import SubscriptionButtons from '../SubscriptionButtons'
import { useQuery } from '@tanstack/react-query'
import { getSubscriptionMatrix } from '@/api/subscription'

type GalleryTableProps = {
    subscriptionTypes?: SubscriptionType[]
    tableData: ISubscription
    interval: string
}

const GalleryTable: FC<GalleryTableProps> = ({
    subscriptionTypes = undefined,
    tableData,
    interval,
}) => {
    const { pathname } = useLocation()

    const { data: matrix, isPending: isLoading } = useQuery<SubscriptionMatrix>({
        queryKey: ['subscriptionMatrix', interval],
        queryFn: () => getSubscriptionMatrix(interval),
    })

    if (isLoading || !matrix) {
        return <></>
    }

    console.log(matrix)

    return (
        <>
            <FeatureTable tableData={tableData.features} />
            <OffersTable tableData={matrix?.customerManagement} tableHead='Customer Management' />
            <OffersTable tableData={matrix?.jobManagement} tableHead='Job Management' />
            <OffersTable tableData={matrix?.financeManagement} tableHead='Finance Management' />
            <OffersTable tableData={matrix?.reportGeneration} tableHead='Report Generation' />
            <OffersTable tableData={matrix?.users} tableHead='Users' />
            <OffersTable tableData={matrix?.additionalItems} tableHead='Additional Items' />
            {pathname === '/sign-up' && subscriptionTypes ? (
                <SubscriptionButtons subscriptionTypes={subscriptionTypes} />
            ) : null}
        </>
    )
}

export default GalleryTable
