import { EmployeeToCrewType } from '@/api/employee/schema'
import { FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { crewListAtom, crewMemberProfileIdsAtom, selectedCrewForeManAtom } from '@/store/employee'
import { cn } from '@/utils/helper'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FiX } from 'react-icons/fi'

export type IAddForeManDropdownProps = React.InputHTMLAttributes<HTMLInputElement> & {
    className?: string
}

const AddForemanDropDown = React.forwardRef<HTMLInputElement, IAddForeManDropdownProps>(() => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useAtom(selectedCrewForeManAtom)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const crewListData = useAtomValue(crewListAtom)
    const setCrewMemberProfileIds = useSetAtom(crewMemberProfileIdsAtom)
    const { control, watch, setValue } = useFormContext()
    const menuRef = React.useRef<HTMLDivElement>(null)
    const foreManId = watch().foreManId
    const foreMan = crewListData?.content?.find((item) => item.profileId === foreManId)
    const { firstName = '', lastName = '' } = foreMan ?? {}

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleSelectItem = (crew: EmployeeToCrewType) => {
        const currentForeman = watch('foreManId')
        setSelectedItem(crew)
        setSearchTerm('')
        setValue('foreManId', crew?.profileId ?? '')
        setIsOpen(false)
        setCrewMemberProfileIds((prevProfileIds: string[]) => {
            const updatedProfileIds = [...prevProfileIds]
            if (currentForeman) {
                updatedProfileIds.push(currentForeman)
            }
            return updatedProfileIds.filter((profileId: string) => profileId !== crew?.profileId)
        })
    }

    const handleRemoveSelectedItem = (profileIdToRemove: string) => {
        setValue('foreManId', undefined)
        setCrewMemberProfileIds((prevProfileIds: string[]) => {
            const updatedProfileIds = [...prevProfileIds]
            if (profileIdToRemove) {
                updatedProfileIds.push(profileIdToRemove)
            }
            return updatedProfileIds
        })
        setSelectedItem(null)
        setSearchTerm('')
    }

    return (
        <div ref={menuRef} className='relative inline-block text-left mt-6 py-4'>
            <div className='bg-white border border-gray-400 rounded-sm'>
                <div className={cn(selectedItem ? 'p-2' : 'p-2')}>
                    <h3
                        className={cn(
                            selectedItem
                                ? 'bg-white block text-zentive-green-dark top-0'
                                : 'hidden',
                            'absolute',
                        )}
                    >
                        Assign Foreman*
                    </h3>
                    <FormField
                        control={control}
                        name='foreManId'
                        render={() => (
                            <FormItem>
                                <FormControl>
                                    <input
                                        type='text'
                                        value={
                                            foreManId
                                                ? firstName + ' ' + lastName
                                                : selectedItem
                                                  ? firstName + ' ' + lastName
                                                  : searchTerm
                                        }
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value)
                                        }}
                                        onFocus={() => setIsOpen(true)}
                                        className={cn(
                                            'w-[250px] max-w-full h-auto px-4 py-3 text-base text-zentive-gray-900 rounded-sm active:border-none focus:border-none focus:outline-none active:outline-none',
                                            selectedItem && foreManId ? 'bg-gray-200' : '',
                                            selectedItem && foreManId
                                                ? 'focus:outline-none active:outline-none'
                                                : '',
                                        )}
                                        placeholder='Assign Foreman*'
                                        style={{
                                            boxShadow: 'none',
                                        }}
                                        disabled={selectedItem && foreManId}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    {selectedItem && foreManId ? (
                        <button
                            onClick={() => handleRemoveSelectedItem(foreManId)}
                            className='absolute inset-y-0 right-0 flex items-center px-4'
                        >
                            <FiX className='h-5 w-5 text-gray-500 cursor-pointer' />
                        </button>
                    ) : null}
                </div>
            </div>

            {isOpen && (
                <div className='mt-2 w-[350x] h-auto absolute right-0 z-10 origin-top-right rounded-md bg-white shadow-lg focus:outline-none'>
                    <div className='bg-white rounded-md'>
                        {crewListData &&
                            crewListData?.content
                                .filter(
                                    (crew: EmployeeToCrewType) =>
                                        crew?.firstName
                                            ?.toLowerCase()
                                            .includes(searchTerm.toLowerCase()),
                                )
                                ?.map((crew: EmployeeToCrewType) => (
                                    <button
                                        key={crew.employeeNumber}
                                        className={cn(
                                            'w-full max-w-[400px] h-8 hover:bg-[#00000029] rounded-md text-left text-base text-zentive-green-dark',
                                            selectedItem ? '' : 'px-4',
                                        )}
                                        onClick={() => handleSelectItem(crew)}
                                    >
                                        <span
                                            className={cn(
                                                'whitespace-nowrap',
                                                watch().foreManId ? '' : 'px-7',
                                            )}
                                        >
                                            {crew.firstName + ' ' + crew.lastName}
                                        </span>
                                    </button>
                                ))}
                    </div>
                </div>
            )}
        </div>
    )
})

export default AddForemanDropDown
