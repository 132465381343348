import { Card, CardTitle } from '@/components/Card'
import { useForm } from 'react-hook-form'
import ForgotPasswordForm from './ForgotPasswordForm'
import { Form } from '@/components/Forms'
import { ResetPasswordEmailSchemaType, crewForgotPasswordSchema } from '@/api/auth/schema'
import { zodResolver } from '@hookform/resolvers/zod'

const ForgotPassword = () => {
    const methods = useForm<ResetPasswordEmailSchemaType>({
        resolver: zodResolver(crewForgotPasswordSchema),
    })

    return (
        <div className='forgot-password-bg flex items-center justify-center h-[36.25rem] w-[56.5rem] bg-zentive-green-light'>
            <div className='w-379 h-337 bg-white bg-no-repeat bg-clip-padding box-shadow border rounded-lg opacity-100'>
                <Form {...methods}>
                    <Card className='items-center justify-center h-full w-full bg-white border-none'>
                        <CardTitle className='text-center text-zentive-green-dark px-7 pt-7'>
                            Forgot Password
                        </CardTitle>
                        <p className='mt-4 w-[24rem] text-base text-gray-500 font-sans-pro px-7'>
                            Enter the email address associated with your account, and we'll send you
                            a link to reset your password.
                        </p>
                        <ForgotPasswordForm />
                    </Card>
                </Form>
            </div>
        </div>
    )
}

export default ForgotPassword
