import 'react-datepicker/dist/react-datepicker.css'
import Map from './Map'
import { useAtom, useAtomValue } from 'jotai'
import { FC, useEffect, useMemo, useState } from 'react'
import GoogleMapsApiWrapper from '@/components/GoogleMapsApiWrapper'
import { isLandmarkShownAtom, routeAtom } from '@/store/route'
import { Switch } from '@/components/Switch'

type MapViewProps = {
    isLoaded: boolean
    loadError?: Error
}

const RouteMap: FC<MapViewProps> = ({ isLoaded, loadError }) => {
    const [mappingComplete, setMappingComplete] = useState<boolean>(false)
    const route = useAtomValue(routeAtom)

    useEffect(() => {
        if (route) {
            setMappingComplete(true)
        }
    }, [route])

    const mapContent = useMemo(() => {
        if (!mappingComplete || !isLoaded || loadError) {
            return null
        }

        return (
            <div className='h-full w-full flex flex-col items-center'>
                <div className='w-full h-full min-h-[540px] rounded-[8px] border border-zentive-gray-medium'>
                    <Map />
                </div>
            </div>
        )
    }, [mappingComplete, isLoaded, loadError])

    return (
        <div className='w-full h-full flex flex-col items-center'>
            <div className='w-full  h-full min-h-[540px] rounded-[8px] border border-zentive-gray-medium'>
                {mapContent}
            </div>
        </div>
    )
}

const WrappedCrewRoutingMap = () => {
    const [isLandmarkShown, setIsLandmarkShown] = useAtom(isLandmarkShownAtom)

    return (
        <div className=' flex flex-col w-full  h-full'>
            <div className='w-full flex flex-row justify-end py-3   rounded-md bg-white pr-4'>
                <span className='mr-4 h-full'>Show landmarks in the map</span>
                <Switch
                    className='data-[state=checked]:bg-zentive-green-dark '
                    onCheckedChange={(event) => setIsLandmarkShown(event)}
                    checked={isLandmarkShown}
                />
            </div>
            <GoogleMapsApiWrapper
                render={(isLoaded: boolean, loadError?: Error) => (
                    <RouteMap isLoaded={isLoaded} loadError={loadError} />
                )}
            />
        </div>
    )
}

export default WrappedCrewRoutingMap
