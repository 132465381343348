import { FC, useEffect } from 'react'
import { USER_STATUS } from '@/constants'
import { useParams } from 'react-router-dom'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectedCrewTabStatusAtom } from '@/store/employee'
import DeleteCrewModal from '../CrewModals/DeleteModal'
import { bulkUpdateCrewStatusAtom } from '@/store/crew'


const CrewSettings: FC = () => {
    const { crewId } = useParams()
    const selected= useAtomValue(selectedCrewTabStatusAtom)
    const setCrewsToUpdate = useSetAtom(bulkUpdateCrewStatusAtom)

    useEffect(() => {
        setCrewsToUpdate({
            crewIds: [crewId ?? ''],
            status: selected === 'ACT' ? USER_STATUS.DELETED : USER_STATUS.ACTIVATED,
        })
    }, [selected])

    return (
        <div className='bg-white p-8'>
            <div className='bg-gray-200 p-6'>
                <p className='text-lg font-semibold'>Delete Crew</p>
                <p className='text-gray-500'>
                    Deleting employee's account will remove all associated data and access.
                </p>
                <DeleteCrewModal
                    onSuccessRestore={() => {
                        setCrewsToUpdate({
                            crewIds: [],
                        })
                    }}
                    isCrewTable={false}
                />
            </div>
        </div>
    )
}

export default CrewSettings
