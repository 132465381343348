import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

// We define a hook for useNavigate with types to provide intellisense
// useNavigate has no intellisense by default
type NavigationPath =
    | '/'
    | '/dashboard'
    | '/forgot-password'
    | '/free-trial'
    | '/profile'
    | '/sign-in'
    | '/sign-up'
    | 'verified'
    | '/privacy-policy'
    | '/service-agreement'
    | '/faqs'
    | '/owner-subscription'
    | '/owner-subscription/change-card'
    | '/owner-subscription/end-trial'
    | '/customer-portal/create-password'
    | '/crew/create-password'
    | '/crew/jobs'
    | '/customers'
    | '/customers/create-customer'
    | '/customers/customer-list'
    | '/customers/customer-list/map-view'
    | '/payment'
    | '/manage-subs'
    | '/settings'
    | '/settings/profile'
    | '/settings/owner-subscription'
    | '/schedule/jobs'
    | '/schedule/jobs/new-job'
    | '/schedule/employee'
    | '/manage-subs/subscribers'
    | '/owner-subscription/change-plan'
    | '/403'
    | '/customer-portal/select-business'
    | '/reset-password'

type RouteParams = { [key: string]: string | number | boolean }

const useAppNavigate = () => {
    const navigate = useNavigate()

    const navigateTo = useCallback(
        (path: NavigationPath, params?: RouteParams, searchParams?: URLSearchParams) => {
            let fullPath: string = path

            if (params) {
                Object.keys(params).forEach((key) => {
                    fullPath = fullPath.replace(`:${key}`, String(params[key]))
                })
            }

            if (searchParams) {
                fullPath += `?${searchParams.toString()}`
            }

            navigate(fullPath)
        },
        [navigate],
    )

    return { navigateTo }
}

export { useAppNavigate }
