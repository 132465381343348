import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import { useForm } from 'react-hook-form'
import { Form } from '@/components/Forms'
import { CrewForgotPasswordType, crewForgotPasswordSchema } from '@/api/auth/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import CrewForgotPasswordForm from './ForgotPasswordForm'
import '../index.css'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

const CrewForgotPassword = () => {
    const { t, i18n } = useTranslation(['forgot-password'])
    const methods = useForm<CrewForgotPasswordType>({
        resolver: zodResolver(crewForgotPasswordSchema),
    })

    const { setValue } = methods

    useEffect(() => {
        setValue('lang', i18n.language)
    }, [i18n.language])

    return (
        <div className='crew-bg flex flex-col justify-center items-center z-0'>
            <Form {...methods}>
                <div className='bg-white flex flex-col justify-center items-center space-y-2 rounded-md'>
                    <img
                        src={zentiveLogo}
                        alt='Zentive Logo'
                        className='h-[60px] w-[180px] cursor-pointer my-4'
                    />
                    <h1 className='w-64 md:w-100 text-center text-lg text-zentive-green-dark font-semibold'>
                        {t('Forgot Password')}
                    </h1>
                    <p className='md:w-100 w-72 text-center text-gray-500'>
                        {t(
                            `Please enter the email address associated with your account, and we'll send you a link to reset your password.`,
                        )}
                    </p>

                    <CrewForgotPasswordForm />
                </div>
            </Form>
        </div>
    )
}

export default CrewForgotPassword
