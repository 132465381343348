import { useAtomValue } from 'jotai'
import { toExportLeadAtom } from '@/store/lead'
import { Lead } from '@/api/lead/schema'
import dayjs from 'dayjs'

interface IExportLeadToCSV {
    dealSize: string
    customerName: string
    serviceType: string
    dealStage: string
    createdAt: string
}

export const ExportLeadCSV = () => {
    const leadData = useAtomValue(toExportLeadAtom)

    const handleExporttoCSV = async () => {
        const tableData: IExportLeadToCSV[] = (leadData || []).map((lead: Lead) => ({
            dealSize: '$' + lead.dealSize ? lead.dealSize.toString() : '0',
            customerName: `${lead.firstName} ${lead.lastName}`,
            serviceType: lead.productServiceNames?.join(', ') ?? 'N/A',
            dealStage: lead.label ?? 'N/A',
            createdAt: dayjs(lead.createdAt).format('MMM DD, YYYY hh:mm:ss A'),
        }))

        if (tableData.length === 0) return

        const csvHeaders = ['Deal Size', 'Customer', 'Service Type', 'Deals Stage', 'Date']

        const csvRows = tableData.map((lead) => {
            const formattedDealSize = `$${parseFloat(lead.dealSize).toFixed(2)}`

            return [
                formattedDealSize,
                lead.customerName,
                `"${lead.serviceType}"`,
                lead.dealStage,
                lead.createdAt,
            ].join(',')
        })

        const csvString = [csvHeaders.join(','), ...csvRows].join('\n')

        const downloadCSV = () => {
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
            const csvLink = document.createElement('a')
            csvLink.href = URL.createObjectURL(blob)
            csvLink.setAttribute('download', 'Lead_Data.csv')
            document.body.appendChild(csvLink)
            csvLink.click()
            document.body.removeChild(csvLink)
        }

        downloadCSV()
    }

    return { handleExporttoCSV }
}
