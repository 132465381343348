import zentiveLogo from '@/assets/common/zentive-logo.png'
import { paymentStepAtom } from '@/store/payment'
import { useAtomValue, useSetAtom } from 'jotai'

import { useAppNavigate } from '@/hooks/useAppNavigate'
import { RESET } from 'jotai/utils'
import { PAYMENT_BTN_STYLE, USER_STATUS } from '@/constants'
import { Button } from '@/components/Button'
import { signOut } from '@/api/auth'
import PaymentSteps from '../Payment/PaymentProcess/PaymentSteps'
import { cn } from '@/utils/helper'
import { Card, CardContent } from '@/components/Card'
import SetUpStripe from '../Payment/PaymentProcess/SetUpStripe'
import { updateProfileStatus } from '@/api/business'
import { useMutation } from '@tanstack/react-query'
import goTrueClient from '@/api/goTrueClient'
import { getEmployeeById as getOwnerById } from '@/api/profile'

const StepOne = () => {
    const setCurrentStep = useSetAtom(paymentStepAtom)
    const { navigateTo } = useAppNavigate()
    const { mutate } = useMutation({
        mutationFn: async () => {
            // kukunin yung user.id na nakastore sa gotrue session
            const session = await goTrueClient.getSession()

            // pag nakuha na, ipang qquery sa BE para maretrieve ang business_id
            // nireretrieve ang business_id kasi di pa siya stored sa session on sign-up, katapos pa lang
            const user = await getOwnerById(session.data.session?.user.id as string)

            // i-update yung user status from UNP (unpaid) to ACT (active)
            // para makapasok na sa App after matapos sa Step 1
            updateProfileStatus({
                profileId: user.profileId as string,
                businessId: user.businessId as string,
                status: USER_STATUS.ACTIVATED,
            })
        },
        onSuccess: () => setCurrentStep(2),
    })

    return (
        <div>
            <div className='h-fit w-full flex flex-col items-center gap-7 pt-7'>
                <img
                    src={zentiveLogo}
                    alt='Zentive Logo'
                    className='h-[123px] w-[123px] cursor-pointer'
                ></img>
                <div>
                    <p className='text-center text-[24px] text-zentive-green-dark font-bold'>
                        Thank You For Choosing Our Free Trial!
                    </p>
                </div>
                <div>
                    <p className='text-center'>
                        We're thrilled to have you on board. Get ready to explore all the amazing
                        features and benefits Zentive has to offer. Let's make the most out of this
                        trial together!
                    </p>
                </div>
                <div>
                    <p className='text-center'>Please click "Next" to continue.</p>
                </div>
            </div>
            <div className='absolute bottom-0 left-0 h-[75px] w-full px-7 bg-zentive-gray-light'>
                <div className='flex items-center justify-between gap-[16px] w-full h-full'>
                    <Button
                        className='w-full h-[43px] rounded-[4px] text-base font-semibold'
                        // disabled={paymentMu.isPending}
                        onClick={() => {
                            navigateTo('/')
                            setCurrentStep(RESET)
                            signOut()
                        }}
                        type='button'
                        variant='outline'
                    >
                        Back
                    </Button>

                    <Button onClick={() => mutate()} className={PAYMENT_BTN_STYLE} type='button'>
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}

const FreeTrial = () => {
    const currentStep = useAtomValue(paymentStepAtom)

    return (
        <Card className={cn('w-[556px] relative', currentStep === 1 ? 'h-[669px]' : 'h-[421px]')}>
            <CardContent className='p-7 w-full h-full space-y-[36px]'>
                <PaymentSteps />

                {currentStep === 1 ? <StepOne /> : <SetUpStripe />}
            </CardContent>
        </Card>
    )
}

export default FreeTrial
