import { Card, CardContent, CardFooter } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import { crewListAtom } from '@/store/employee'
import { cn } from '@/utils/helper'
import dayjs from 'dayjs'
import { useAtom, useAtomValue } from 'jotai'
import { FaArrowDownLong } from 'react-icons/fa6'
import { formatPhoneNumber } from 'react-phone-number-input'
import { EmployeeToCrewType } from '@/api/employee/schema'
import { useState } from 'react'
import { crewMembersToRemoveAtom } from '@/store/crew'
import { E164Number } from 'libphonenumber-js/core'
import NoteModal from '@/components/NotesModal'

const tableHeader = [
    'Employee Number',
    'Name',
    'Commission',
    'Pay Rate Per Hour',
    'Chemical Applicator License',
    'Email Address',
    'Address',
    'Contact Number',
    'Birthday',
    'Date Hired',
    'Notes',
]

const tableHeaderWithArrow = [
    'Employee Number',
    'Name',
    'Commission',
    'Pay Rate Per Hour',
    'Email Address',
    'Address',
    'Birthday',
    'Date Hired',
]

const AddCrewTable = () => {
    const crewListData = useAtomValue(crewListAtom)
    const [isCheckAll, setIsCheckAll] = useState<boolean>(false)
    const [crewMembersToRemove, setCrewMembersToRemove] = useAtom(crewMembersToRemoveAtom)

    const handleCheckClick = (employee: EmployeeToCrewType, isCheck: boolean) => {
        if (isCheck) {
            const updatedProfileIds = [...crewMembersToRemove.profileIds, employee.profileId]
            setCrewMembersToRemove({
                ...crewMembersToRemove,
                profileIds: updatedProfileIds,
            })
            setIsCheckAll(
                crewMembersToRemove.profileIds?.length + 1 === crewListData?.content?.length,
            )
        } else {
            const updatedProfileIds = crewMembersToRemove.profileIds.filter(
                (id) => id !== employee.profileId,
            )

            setCrewMembersToRemove({
                ...crewMembersToRemove,
                profileIds: updatedProfileIds,
            })

            setIsCheckAll(
                crewMembersToRemove.profileIds?.length - 1 === crewListData?.content?.length,
            )
        }
    }

    const handleCheckAll = (checked: boolean) => {
        setIsCheckAll(!isCheckAll)
        if (crewListAtom && checked) {
            const updatedProfileIds = [...crewMembersToRemove.profileIds]
            crewListData &&
                crewListData.content.forEach((crewMember) => {
                    updatedProfileIds.push(crewMember?.profileId)
                })
            setCrewMembersToRemove({
                ...crewMembersToRemove,
                profileIds: updatedProfileIds,
            })
        } else {
            setCrewMembersToRemove({
                profileIds: [],
            })
        }
    }

    return (
        <Card className='overflow-x-scroll green-scrollbar'>
            <CardContent>
                <Table className='whitespace-normal w-full'>
                    <TableHeader className='mb-[10px]'>
                        <TableRow>
                            {tableHeader?.map((header) => (
                                <TableHead
                                    key={`${header}-head`}
                                    className={cn(
                                        'font-semibold text-zentive-gray-medium text-base whitespace-nowrap',
                                    )}
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    <div className='inline-flex whitespace-nowrap'>
                                        {header === 'Employee Number' && (
                                            <Checkbox
                                                id={'message-checked-status'}
                                                className='mr-[5px] mt-[4px]'
                                                onCheckedChange={handleCheckAll}
                                                checked={
                                                    crewMembersToRemove.profileIds?.length
                                                        ? crewMembersToRemove.profileIds?.length >=
                                                          (crewListData?.content?.length ?? 0)
                                                        : false
                                                }
                                            />
                                        )}
                                        {header}
                                        {tableHeaderWithArrow.includes(header) && (
                                            <FaArrowDownLong className='w-3 h-3 mt-[6px] ml-1' />
                                        )}
                                    </div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody className='mt-10'>
                        {crewListData &&
                            crewListData?.content &&
                            crewListData?.content?.map((employee) => (
                                <TableRow
                                    key={employee.employeeNumber}
                                    className='whitespace-nowrap'
                                >
                                    <TableCell>
                                        <div className='flex items-center'>
                                            <div className='mt-[2px]'>
                                                <Checkbox
                                                    className='mr-[5px] mt-[2px]'
                                                    checked={crewMembersToRemove?.profileIds?.some(
                                                        (p) => p === employee.profileId,
                                                    )}
                                                    onCheckedChange={(val) => {
                                                        setIsCheckAll(
                                                            crewMembersToRemove.profileIds?.length +
                                                                1 ===
                                                                crewListData.content?.length,
                                                        )
                                                        handleCheckClick(employee, val as boolean)
                                                    }}
                                                />
                                            </div>
                                            <div>{'000' + employee.employeeNumber}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap'>
                                        <p>{employee.firstName + ' ' + employee.lastName}</p>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap text-right'>
                                        <p className=''>{employee.commission}%</p>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap text-right'>
                                        <p className=''>${employee.compensation.toFixed(2)}</p>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap'>
                                        <p className=''>{employee.caLicense}</p>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap'>
                                        <p className=''>{employee.email}</p>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap'>
                                        <p className=''>{employee?.address?.streetAddress}</p>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap'>
                                        <p className=''>
                                            {formatPhoneNumber(employee.phoneNumber as E164Number)}
                                        </p>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap'>
                                        <p className=''>
                                            {dayjs(employee.dateOfBirth).format('MMMM DD, YYYY')}
                                        </p>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap'>
                                        <p className=''>
                                            {dayjs(employee.createdAt).format('MMMM DD, YYYY')}
                                        </p>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap'>
                                        <NoteModal profileId={employee.profileId} />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </CardContent>
            <CardFooter className='items-end justify-end'></CardFooter>
        </Card>
    )
}
export default AddCrewTable
