import { CrewMemberDetailsType } from '@/api/crew/schema'
import { useFormContext } from 'react-hook-form'
import { MouseEvent } from 'react'

interface AssignForemanProps {
    onClick: (profileId: string) => Promise<void>
}

const AssignForeman: React.FC<AssignForemanProps> = ({ onClick }) => {
    const { watch } = useFormContext()
    const foremanDetails: CrewMemberDetailsType | null = watch('foreman') || null
    const memberIds: string[] = watch('crewMemberIds') || []

    const showForemanButton = !!(foremanDetails?.firstName && foremanDetails?.lastName)

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if (foremanDetails?.profileId) {
            onClick(foremanDetails.profileId)
        }
    }

    return (
        <div className='border border-zentive-gray border-t-1 p-5 w-full flex justify-between items-center'>
            <div className='flex flex-row gap-5'>
                <h1 className='font-bold text-zentive-gray-medium'>Foreman</h1>
                {showForemanButton && (
                    <button
                        className='font-bold text-zentive-blue-dark underline'
                        onClick={handleClick}
                        type='button'
                    >
                        {foremanDetails.firstName} {foremanDetails.lastName}
                    </button>
                )}
            </div>

            {memberIds?.length > 1 && (
                <button
                    className='font-bold text-zentive-blue-dark'
                    onClick={handleClick}
                    type='button'
                >
                    Assign a Foreman
                </button>
            )}
        </div>
    )
}

export default AssignForeman
