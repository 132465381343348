import { updateBusinessSubscriptionStatus as updateBusinessSubsStat } from '@/api/owner'
import { BusinessStatusType } from '@/api/business/schema'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { RadioGroupItem } from '@/components/RadioGroup'
import { USER_STATUS } from '@/constants'
import { currentSubsInfoAtom, manageSubsTabAtom, redirectedSubsTabAtom } from '@/store/manageSubs'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAtomValue, useSetAtom } from 'jotai'
import { FC, useMemo, useState } from 'react'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@/hooks/useToast'
import { AxiosError } from 'axios'
import OwnerJobRestrictionModal from '@/components/OwnerJobRestrictionModal'
import { validateUpdate } from '@/api/auth'
import ExistingPhoneNumberModal from '@/components/ExistingPhoneNumberModal'

interface RadioButtonStatusProps {
    status: 'ACT' | 'TER' | 'BP'
    id: string
}
const RadioButtonStatus: FC<RadioButtonStatusProps> = (props) => {
    const navigate = useNavigate()
    const setSelected = useSetAtom(redirectedSubsTabAtom)
    const setSelectedSubStatus = useSetAtom(manageSubsTabAtom)
    const params = new URLSearchParams(window.location.search)
    const [selectedStatus, setSelectedStatus] = useState<string>('')
    const profileID = params.get('profileid')
    const businessID = params.get('corporateid')
    const currentSubsInfo = useAtomValue(currentSubsInfoAtom)
    const [ownerJobModal, setOwnerJobModal] = useState<boolean>(false)
    const [existingPhoneNumberModal, setExistingPhoneNumberModal] = useState<boolean>(false)

    const { toast } = useToast()

    const queryClient = useQueryClient()

    const { mutate: updateProfileMu, isPending: updateLoading } = useMutation<
        unknown,
        AxiosError,
        BusinessStatusType
    >({
        mutationFn: (data: BusinessStatusType) => updateBusinessSubsStat(data),
        onSuccess: () => {
            let toastStatus = ''
            queryClient.invalidateQueries({ queryKey: ['businessStatus'] })
            setSelected(selectedStatus)
            setSelectedSubStatus(selectedStatus)
            if (selectedStatus === USER_STATUS.TERMINATED) {
                toastStatus = 'Terminated'
            } else if (selectedStatus === USER_STATUS.BILLING_PAUSED) {
                toastStatus = 'Billing Paused'
            } else if (selectedStatus === USER_STATUS.ACTIVATED) {
                toastStatus = 'Active'
            }

            toast({
                description: `User status has been updated to ${toastStatus}`,
                variant: 'default',
            })

            navigate('/manage-subs/subscribers')
        },
        onError: () => {
            setOwnerJobModal(true)
        },
    })

    const { mutate: validateUpdateMu } = useMutation<unknown, AxiosError, string>({
        mutationFn: (data) => validateUpdate(data),
        onSuccess: (isValid) => {
            if (isValid) {
                setSelectedStatus(props.status)
                updateProfileMu({
                    profileId: profileID,
                    businessId: businessID,
                    status: props.status,
                } as BusinessStatusType)
            } else {
                setExistingPhoneNumberModal(true)
            }
        },
        onError: () => {
            setOwnerJobModal(true)
        },
    })

    const handleClick = () => {
        if (props.status == USER_STATUS.ACTIVATED) {
            validateUpdateMu(profileID ?? '')
        } else {
            setSelectedStatus(props.status)
            updateProfileMu({
                profileId: profileID,
                businessId: businessID,
                status: props.status,
            } as BusinessStatusType)
        }
    }

    const DialogValues = useMemo(() => {
        switch (props.status) {
            case USER_STATUS.ACTIVATED:
                return {
                    title: 'Activate Account',
                    subTitle:
                        'By activating an account user will have access to the services, content, or features associated with the subscription plan.',
                    confirmationMessage: 'Are you sure you want to activate account?',
                    buttonLabel: 'Activate',
                }
            case USER_STATUS.BILLING_PAUSED:
                return {
                    title: 'Pause Billing',
                    subTitle:
                        'This will temporarily stopped the charging for subscription service.',
                    confirmationMessage:
                        'Are you sure you want to pause the billing of this account?',
                    buttonLabel: 'Pause Billing',
                }
            case USER_STATUS.TERMINATED:
                return {
                    title: 'Terminate Account',
                    subTitle: `By terminating an account, user will no longer have access to the services, content, or features associated with the subscription plan.`,
                    confirmationMessage: 'Are you sure you want to terminate this account?',
                    buttonLabel: 'Terminate',
                }
            default:
                return {
                    title: 'Terminate Account',
                    subTitle: `By terminating an account, user will no longer have access to the
                    services, content, or features associated with the subscription plan.`,
                    confirmationMessage: 'Are you sure you want to terminate this account?',
                    buttonLabel: 'Terminate',
                }
        }
    }, [props.status])
    return (
        <AlertDialog>
            <ExistingPhoneNumberModal
                setOpen={setExistingPhoneNumberModal}
                open={existingPhoneNumberModal}
            />
            <OwnerJobRestrictionModal open={ownerJobModal} setOpen={setOwnerJobModal} />
            <AlertDialogTrigger asChild>
                <RadioGroupItem
                    value={props.id}
                    id={props.id}
                    className='text-zentive-green-dark'
                    disabled={currentSubsInfo?.business.status === props.status}
                />
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-2xl p-0'>
                <div className='flex gap-5 px-6 pt-10'>
                    <div
                        className={`flex justify-center items-center bg-zentive-red-light h-16 rounded-full
                        ${props.status === USER_STATUS.ACTIVATED ? 'w-28' : ''}
                        ${props.status === USER_STATUS.BILLING_PAUSED ? 'w-16' : ''}
                        ${props.status === USER_STATUS.TERMINATED ? 'w-32' : ''}`}
                    >
                        <HiOutlineExclamationTriangle className='h-[32px] w-[32px] text-red-500' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-2xl text-gray-900 mt-2.5'>
                            {DialogValues.title}
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            {DialogValues.subTitle}
                        </AlertDialogDescription>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            {DialogValues.confirmationMessage}
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-0 bg-gray-100 rounded-b-2xl pr-[26px] pb-3.5 pt-1.5'>
                    <div className='w-full flex justify-end items-end gap-[16px]'>
                        <AlertDialogCancel className='w-97 h-10 text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            disabled={updateLoading}
                            onClick={handleClick}
                            className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-dark/90'
                        >
                            {DialogValues?.buttonLabel}
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default RadioButtonStatus
