import ResetJobLogModal from './ResetJobLogModal'

const JobLogSettings = () => {
    return (
        <div className='bg-white p-8'>
            <div className='bg-gray-200 p-6'>
                <p className='text-lg font-semibold'>Reset progress</p>
                <p className='text-gray-500'>Resetting the progress will reopen the job</p>
                <ResetJobLogModal />
            </div>
        </div>
    )
}
export default JobLogSettings
