import { CrewForgotPasswordType } from '@/api/auth/schema'
import { FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { useFormContext } from 'react-hook-form'
import ActionButton from '../ActionButton'
import { CardContent, CardFooter } from '@/components/Card'

const ForgotPasswordForm = () => {
    const { control } = useFormContext<CrewForgotPasswordType>()

    return (
        <div className='w-full h-full flex flex-col items-center'>
            <div className='col-span-1'>
                <CardContent className='px-7'>
                    <FormField
                        control={control}
                        name='email'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className='items-center font-sans pl-3 w-107 mt-8 border border-black'
                                        placeholder='Email Address'
                                        type='text'
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </CardContent>
                <CardFooter className='w-full mt-4 bg-zentive-gray-bg pr-[26px] pb-[15px] pt-[6px]'>
                    <ActionButton buttonContainerClassName='space-x-6 pl-1' />
                </CardFooter>
            </div>
        </div>
    )
}

export default ForgotPasswordForm
