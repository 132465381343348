import { updateContactStatus } from '@/api/business'
import { BusinessStatusType } from '@/api/business/schema'
import { TableCell } from '@/components/Table'
import { useToast } from '@/hooks/useToast'
import { cn } from '@/utils/helper'
import { useMutation } from '@tanstack/react-query'
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa'

interface ContactedButtonProps {
    updateContactedValue: BusinessStatusType
}
const ContactedButton = ({ updateContactedValue }: ContactedButtonProps) => {
    const { toast } = useToast()

    const searchMutation = useMutation({
        mutationKey: ['updateContacted'],
        mutationFn: (updateContactedValue: BusinessStatusType) =>
            updateContactStatus(updateContactedValue),
        onSuccess: () => {
            toast({
                description: 'Business contacted status has been successfully updated',
                variant: 'default',
            })
        },
    })

    return (
        <TableCell
            onClick={() => {
                updateContactedValue.isContacted = !updateContactedValue.isContacted
                searchMutation.mutate(updateContactedValue)
            }}
            key={'false'}
            className={cn(
                updateContactedValue.isContacted ? 'text-zentive-green-dark' : 'text-black',
                'text-center max-w-[150px] text-ellipsis overflow-hidden',
            )}
        >
            <button key='contacted-icon'>
                {updateContactedValue.isContacted ? <FaCheckCircle /> : <FaRegCircle />}
            </button>
        </TableCell>
    )
}

export default ContactedButton
