import { GoogleMap, MarkerClustererF } from '@react-google-maps/api'
import { useCallback, useEffect, useState } from 'react'
import { getFirstChar } from '@/utils/helper'
import Marker from './Marker'
import { CustomerProfileType } from '@/api/profile/schema'
import { mapBoundsAtom } from '@/store/location'
import { useAtom } from 'jotai'

type MapProps = {
    coordinates: CustomerProfileType[]
}

const containerStyle = {
    borderRadius: '8px',
    flex: 1,
}

const mapOptions = {
    keyboardShortcuts: false,
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: true,
    styles: [
        {
            featureType: 'poi',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
    ],
}

const Map = ({ coordinates }: MapProps) => {
    const [map, setMap] = useState<google.maps.Map | null>(null)
    const [activeMarker, setMarker] = useState<string | null>(null)
    const [isMounted, setIsMounted] = useState<boolean>(false)
    const [previousBounds, setPreviousBounds] = useAtom(mapBoundsAtom)

    const handleLoad = useCallback((mapInstance: google.maps.Map) => {
        setMap(mapInstance)
    }, [])

    useEffect(() => {
        setTimeout(() => setIsMounted(true), 0)
    }, [])

    useEffect(() => {
        if (coordinates?.length > 0 && map) {
            const bounds = new google.maps.LatLngBounds()

            coordinates.forEach((coord: CustomerProfileType) => {
                bounds.extend(
                    new google.maps.LatLng(
                        coord.address.gmapLat as number,
                        coord.address.gmapLng as number,
                    ),
                )
            })

            setPreviousBounds(bounds)
        }
    }, [coordinates, map])

    useEffect(() => {
        if (!isMounted || !map || !coordinates) return
        const bounds = new google.maps.LatLngBounds()

        coordinates.forEach((coord: CustomerProfileType) => {
            bounds.extend(
                new google.maps.LatLng(
                    coord.address.gmapLat as number,
                    coord.address.gmapLng as number,
                ),
            )
        })

        if (!coordinates?.length && previousBounds) {
            map.fitBounds(previousBounds)
        } else {
            map.fitBounds(bounds)
            setPreviousBounds(bounds)
        }

        if (coordinates?.length === 1) {
            map.setZoom(10)
        }
    }, [coordinates, isMounted, map])

    return (
        <GoogleMap mapContainerStyle={containerStyle} onLoad={handleLoad} options={mapOptions}>
            {isMounted && (
                <MarkerClustererF minimumClusterSize={2}>
                    {(clusterer) => {
                        return (
                            <>
                                {coordinates?.map((coord) => {
                                    return (
                                        <Marker
                                            activeMarker={activeMarker}
                                            clusterer={clusterer}
                                            coordinate={coord}
                                            key={coord.profileId}
                                            position={{
                                                lat: coord.address.gmapLat as number,
                                                lng: coord.address.gmapLng as number,
                                            }}
                                            setMarker={setMarker}
                                            text={`${getFirstChar(coord.firstName)}${getFirstChar(
                                                coord.lastName,
                                            )}`}
                                        />
                                    )
                                })}
                            </>
                        )
                    }}
                </MarkerClustererF>
            )}
        </GoogleMap>
    )
}

export default Map
