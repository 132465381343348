import { Button } from '@/components/Button'

export const NotificationsHeader = ({ onClick }: { onClick: () => void }) => {
    return (
        <div className='flex flex-row justify-between  p-5'>
            <h1 className='font-bold mt-2 text-base'>Notifications</h1>
            <div className=''>
                <Button className='mt-1 text-base' variant='link' onClick={onClick}>
                    Mark all as read
                </Button>
            </div>
        </div>
    )
}
