import { FC, useState } from 'react'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger } from '@/components/Select'
import { ModalVariants } from '..'
import { useExportLeadPDF } from './ExportPDFButton'
import { ExportLeadCSV } from './ExportFile/ExportCSV'
import { cn } from '@/utils/helper'

type ExportSelectionProps = {
    onValueChange: (val: ModalVariants) => void
    value: string
    disabled: boolean
}

const ExportSelection: FC<ExportSelectionProps> = ({ value, disabled }) => {
    const { handleExportToPDF } = useExportLeadPDF()
    const { handleExporttoCSV } = ExportLeadCSV()
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const handleExport = (exportType: string) => {
        if (exportType === 'export_pdf') {
            handleExportToPDF()
        } else if (exportType === 'export_csv') {
            handleExporttoCSV()
        }
        setIsOpen(false)
    }

    return (
        <>
            <Select
                onValueChange={handleExport}
                value={value}
                disabled={disabled}
                onOpenChange={setIsOpen}
            >
                <SelectTrigger
                    className={cn(
                        'h-[44px] w-[108px]',
                        isOpen
                            ? 'bg-zentive-green-dark text-white px-[20px]'
                            : 'bg-white border border-zentive-green-dark font-semibold text-zentive-green-dark px-[20px]',
                    )}
                >
                    Export
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        <SelectItem value='export_pdf' disabled={disabled}>
                            Export as PDF
                        </SelectItem>
                        <SelectItem value='export_csv' disabled={disabled}>
                            Export as CSV
                        </SelectItem>
                    </SelectGroup>
                </SelectContent>
            </Select>
        </>
    )
}

export default ExportSelection
