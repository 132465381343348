import { Link, useLocation } from 'react-router-dom'

const PublicFooter = () => {

    const location = useLocation()
    const currentPage = location.pathname

    return (
        <footer className='h-[120px] md:h-[46px] px-[36px] py-[25px] md:py-[16px] flex flex-col md:flex-row items-center justify-between bg-[#BECBCC] shadow-md'>
            <div className='flex flex-row gap-6 text-zentive-black text-sm'>
                <Link
                className={`underline hover:text-black hover:underline py-2 ${currentPage === '/service-agreement' && 'font-semibold'}`}
                    to='/service-agreement'
                >
                     Service Agreement
                </Link>
                <Link
                    className={`underline hover:text-black   hover:underline py-2 ${currentPage === '/privacy-policy' && 'font-semibold'}`}
                    to='/privacy-policy'
                >
                     Privacy Policy
                </Link>
                <Link
                    className={`underline hover:text-black hover:underline py-2 ${currentPage === '/faqs' && 'font-semibold'}`}
                    to='/faqs'
                >
                     FAQs
                </Link>
            </div>
            <div> 
                <span className='text-zentive-black text-sm'> Copyright © 2024 All Rights Reserved by Zentive </span>
            </div>
        </footer>
    )
}

export default PublicFooter
