import { Button } from '@/components/Button'
import { ISO_8601_WITH_UTC_OFFSET_IOS } from '@/constants'
import { TimeZoneResult } from '@/hooks/useGeocode'
import { timezoneAtom } from '@/store/auth'
import { crewInterfaceFilterAtom, selectedDateAtom, selectedDateFilterAtom } from '@/store/crew'
import { formatToUTCWithOffsetIos } from '@/utils/time'
import dayjs from 'dayjs'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { IoCheckmark } from 'react-icons/io5'

type DateFilter = {
    id: number
    name: string
    startDate: string
    endDate: string
}

export const formatDateTime = (dateTime: string, timezone: string, format: string) => {
    try {
        return formatToUTCWithOffsetIos(dateTime, timezone, format)
    } catch (error) {
        console.error('Error formatting date:', error)
        return 'Invalid Date'
    }
}

const JobDateSelector = () => {
    const { t } = useTranslation(['jobs'])
    const [selectedDate, setSelectedDate] = useAtom(selectedDateAtom)
    const setIsDateFilterOpen = useSetAtom(crewInterfaceFilterAtom)
    const setSelectedDateFilter = useSetAtom(selectedDateFilterAtom)
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult

    //job date filters
    const dateFilters: DateFilter[] = [
        {
            id: 1,
            name: t('Today'),
            startDate: formatDateTime(
                dayjs().startOf('day').toString(),
                timezone?.timeZoneId as string,
                ISO_8601_WITH_UTC_OFFSET_IOS,
            ),
            endDate: formatDateTime(
                dayjs().endOf('day').toString(),
                timezone?.timeZoneId as string,
                ISO_8601_WITH_UTC_OFFSET_IOS,
            ),
        },
        {
            id: 2,
            name: t('Tomorrow'),
            startDate: formatDateTime(
                dayjs().add(1, 'day').startOf('day').toString(),
                timezone?.timeZoneId as string,
                ISO_8601_WITH_UTC_OFFSET_IOS,
            ),
            endDate: formatDateTime(
                dayjs().add(1, 'day').endOf('day').toString(),
                timezone?.timeZoneId as string,
                ISO_8601_WITH_UTC_OFFSET_IOS,
            ),
        },
        {
            id: 3,
            name: t('Next 7 days'),
            startDate: formatDateTime(
                dayjs().add(1, 'day').startOf('day').toString(),
                timezone?.timeZoneId as string,
                ISO_8601_WITH_UTC_OFFSET_IOS,
            ),
            endDate: formatDateTime(
                dayjs().add(7, 'day').endOf('day').toString(),
                timezone?.timeZoneId as string,
                ISO_8601_WITH_UTC_OFFSET_IOS,
            ),
        },
        {
            id: 4,
            name: t('Next Month'),
            startDate: formatDateTime(
                dayjs().add(1, 'month').startOf('month').toString(),
                timezone?.timeZoneId as string,
                ISO_8601_WITH_UTC_OFFSET_IOS,
            ),
            endDate: formatDateTime(
                dayjs().add(1, 'month').endOf('month').toString(),
                timezone?.timeZoneId as string,
                ISO_8601_WITH_UTC_OFFSET_IOS,
            ),
        },
    ]

    const handleApplyClick = () => {
        setSelectedDateFilter(selectedDate)
        setIsDateFilterOpen(false)
    }

    return (
        <div>
            {dateFilters?.map((date, index) => (
                <div
                    key={index}
                    role='button'
                    onClick={() => {
                        setSelectedDate({ startDate: date.startDate, endDate: date.endDate })
                    }}
                    className='flex flex-row cursor-pointer justify-between px-4 py-6 w-full border border-b h-10 items-center'
                >
                    <p>{date.name}</p>
                    {selectedDate.startDate === date.startDate &&
                        selectedDate.endDate === date.endDate && (
                            <IoCheckmark className='text-zentive-green-dark h-5 w-5' />
                        )}
                </div>
            ))}
            <div className='flex justify-center items-center my-6'>
                <Button
                    variant='default'
                    className='w-[300px] md:w-[500px]'
                    onClick={handleApplyClick}
                >
                    {t('Apply')}
                </Button>
            </div>
        </div>
    )
}

export default JobDateSelector
