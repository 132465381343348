import BreadCrumbs from '@/components/BreadCrumbs'
import { JobsCompletedByEmployeeFilter } from './JobsCompletedByEmployeeFilter'
import JobsCompletedByEmployeeTable from './JobsCompletedByEmployeeTable'

export const JobsCompletedByEmployee = () => {
    return (
        <div className='flex flex-col w-full h-full px-[70px] pt-[50px] gap-5'>
            <BreadCrumbs titleName='Jobs Completed By Employees' />
            <JobsCompletedByEmployeeFilter />
            <JobsCompletedByEmployeeTable />
        </div>
    )
}
