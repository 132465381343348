import { InvoiceType } from '@/api/invoicing/schema'
import { Button } from '@/components/Button'
import {
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogOverlay,
    DialogPortal,
} from '@/components/Dialog'
import { Input } from '@/components/Input'
import { DialogClose } from '@radix-ui/react-dialog'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'

const AddDiscountDialog = ({
    setDiscount,
    subTotal,
    handleDiscountTypeChange,
}: {
    setDiscount: (discount: {
        percentage: number
        amount: number
        isPercent: boolean
        totalAfterDiscount: number
    }) => void
    subTotal: number
    handleDiscountTypeChange: (isPercent: boolean) => void
}) => {
    const { setValue } = useFormContext<InvoiceType>()

    // Local state for discount management
    const [discount, setDiscountInside] = useState({
        percentage: 0,
        amount: 0,
        isPercent: false,
        totalAfterDiscount: 0,
    })

    // Reset the discount state
    const resetDiscountState = () => {
        setDiscountInside({
            percentage: 0,
            amount: 0,
            isPercent: false,
            totalAfterDiscount: 0,
        })
    }

    // Handle change in discount value
    const handleOnDiscountChange = (discountRate: number) => {
        if (isNaN(discountRate)) {
            discountRate = 0
        }

        if (discount.isPercent) {
            const discountAmount = Number((subTotal * (discountRate / 100)).toFixed(2))
            const totalAfterDiscount = subTotal - discountAmount
            setDiscountInside((prevState) => ({
                ...prevState,
                percentage: discountRate,
                amount: discountAmount,
                totalAfterDiscount: totalAfterDiscount,
            }))
        } else {
            setDiscountInside((prevState) => ({
                ...prevState,
                amount: discountRate,
                totalAfterDiscount: subTotal - discountRate,
            }))
        }
        setValue('isDiscountPercent', discount.isPercent)
        setValue('discount', discountRate)
    }

    // Handle change in discount type
    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        resetDiscountState()
        const selectedOption = event.target.value
        const isPercent = selectedOption === '%'
        setDiscountInside((prevState) => ({
            ...prevState,
            isPercent: isPercent,
        }))
        handleDiscountTypeChange(isPercent)
    }

    // Handle cancel action
    const handleDiscountCancel = () => {
        setValue('discount', 0)
    }

    // Handle save action
    const handleSaveDiscount = () => {
        setDiscount(discount)
        resetDiscountState()
    }

    return (
        <DialogPortal>
            <DialogOverlay>
                <DialogContent className='max-w-[400px] w-[400px] max-h-[280px] h-[280px] rounded-md'>
                    <DialogHeader className='m-6'>
                        <DialogTitle className='mb-2'>
                            <div className='text-[24px] font-semibold text-left mb-6'>
                                Add Discount
                            </div>
                        </DialogTitle>
                        <DialogDescription className='no-scrollbar'>
                            <div className='flex justify-center'>
                                <Input
                                    className='w-full flex focus:border-r-0 justify-center items-center font-sans text-base rounded-tr-none rounded-br-none h-11 border border-black border-r-0 disabled:bg-gray-100 text-gray-900'
                                    placeholder='Discount'
                                    name='rate'
                                    type='number'
                                    value={
                                        discount.isPercent ? discount.percentage : discount.amount
                                    }
                                    onChange={(val) =>
                                        handleOnDiscountChange(parseFloat(val.target.value))
                                    }
                                    onKeyDown={(e) => {
                                        if (['-', '+', 'e', 'E'].includes(e.key)) {
                                            e.preventDefault()
                                        }
                                    }}
                                />
                                <select
                                    name='row'
                                    className='w-[20%] focus:outline-none font-sans text-base pl-3 border border-black disabled:bg-gray-100 text-gray-900 rounded-r-sm'
                                    onChange={handleSelectChange}
                                >
                                    <option>$</option>
                                    <option>%</option>
                                </select>
                            </div>
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className='absolute self-end py-4 px-6 w-full rounded-b-md justify-center items-center bg-zentive-gray-light'>
                        <div className='flex flex-row w-full justify-center gap-x-4 items-center'>
                            <DialogClose asChild>
                                <Button
                                    type='button'
                                    variant={'outline'}
                                    className='w-[50%]'
                                    onClick={handleDiscountCancel}
                                >
                                    Cancel
                                </Button>
                            </DialogClose>
                            <DialogClose asChild>
                                <Button
                                    type='submit'
                                    className='w-[50%]'
                                    disabled={discount.amount === 0 && discount.percentage === 0}
                                    onClick={handleSaveDiscount}
                                >
                                    Save
                                </Button>
                            </DialogClose>
                        </div>
                    </DialogFooter>
                </DialogContent>
            </DialogOverlay>
        </DialogPortal>
    )
}

export default AddDiscountDialog
