import { Table } from '@/components/Table'
import InvoicingTableHead from './TableHead'
import InvoicingTableBody from './TableBody'
import { BulkDeleteInvoiceType, InvoiceListType } from '@/api/invoicing/schema'
import { FC } from 'react'

type InvoicingTableContentProps = {
    invoice: InvoiceListType
    setInvoiceToUpdate: React.Dispatch<
        React.SetStateAction<{
            invoiceIds: string[]
        }>
    >
    setIsCheckAll: React.Dispatch<React.SetStateAction<boolean>>
    isCheckAll: boolean
    invoiceToUpdate: BulkDeleteInvoiceType
}

const InvoicingTableContents: FC<InvoicingTableContentProps> = ({
    invoice,
    setIsCheckAll,
    isCheckAll,
    setInvoiceToUpdate,
    invoiceToUpdate,
}) => (
    <div className='bg-white overflow-x-auto green-scrollbar'>
        <Table className='table-auto whitespace-normal mx-auto'>
            <InvoicingTableHead
                invoice={invoice}
                setInvoiceToUpdate={setInvoiceToUpdate}
                setIsCheckAll={setIsCheckAll}
                isCheckAll={isCheckAll}
            />
            <InvoicingTableBody
                invoice={invoice}
                setInvoiceToUpdate={setInvoiceToUpdate}
                invoiceToUpdate={invoiceToUpdate}
                setIsCheckAll={setIsCheckAll}
            />
        </Table>
    </div>
)

export default InvoicingTableContents
