import { Button } from '@/components/Button'
import { languageAtom } from '@/store/crew'
import { useSetAtom } from 'jotai'
import ReactCountryFlag from 'react-country-flag'
import { CiGlobe } from 'react-icons/ci'
import { FaCheck } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

const CrewSettings = () => {
    const { t, i18n } = useTranslation(['common'])
    const setLanguage = useSetAtom(languageAtom)

    const handleLanguageSelect = (lang: string) => {
        setLanguage(lang)
        i18n.changeLanguage(lang)
    }

    return (
        <div className='w-10/12 md:w-8/12 flex flex-col px-6 mx-auto '>
            <div className='flex flex-col px-6 bg-white rounded-md mt-16 border border-gray-300 shadow-md'>
                <div className='flex flex-row mx-2 gap-x-1 border-b py-2'>
                    <CiGlobe className='h-4 w-4 mt-2 text-blue-800' />
                    <p className='text-gray-500 mt-1'>{t('Language')}</p>
                </div>
                <div className='flex flex-col'>
                    <Button
                        className='flex flex-row justify-start border-b rounded-none px-2'
                        variant='ghost'
                        onClick={() => handleLanguageSelect('en')}
                    >
                        <ReactCountryFlag
                            countryCode='US'
                            svg
                            cdnUrl='https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/'
                            cdnSuffix='svg'
                            style={{
                                width: '2em',
                                height: '2em',
                            }}
                            title='US'
                        />
                        <p className='ml-2'>English</p>
                        {i18n.language === 'en' && (
                            <FaCheck className='text-zentive-green-dark ml-auto' />
                        )}
                    </Button>
                    <Button
                        className='flex flex-row justify-start rounded-none px-2'
                        variant='ghost'
                        onClick={() => handleLanguageSelect('es')}
                    >
                        <ReactCountryFlag
                            countryCode='ES'
                            svg
                            style={{
                                width: '2em',
                                height: '2em',
                            }}
                            cdnUrl='https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/'
                            cdnSuffix='svg'
                            title='ES'
                        />
                        <p className='ml-2'>Español</p>
                        {i18n.language === 'es' && (
                            <FaCheck className='text-zentive-green-dark ml-auto' />
                        )}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default CrewSettings
