import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { cn, numberFormatter } from '@/utils/helper'
import { ProductServiceGroupType, QuoteType } from '@/api/quoting/schema'

const TABLE_HEADER = ['Name', 'Quantity', 'Cost', 'Price', 'Total']

interface IJobLineItems {
    quote: QuoteType
}

const JobLineItems = ({ quote }: IJobLineItems) => {
    const total = quote?.productServiceGroup?.reduce(
        (sum, productService) => sum + (productService.cost || 0),
        0,
    )

    return (
        <div className='flex flex-col gap-x-2 bg-white px-6'>
            <div className='border border-zentive-gray-light border-1'>
                <div className='flex flex-row justify-between w-full px-6'>
                    <h1 className='text-2xl font-bold mt-5 mb-2'>Product/Service</h1>
                </div>
                <div className='flex flex-row justify-between w-full px-2'>
                    <Table className='table-auto whitespace-normal mx-auto'>
                        <TableHeader style={{ marginBottom: '10px' }}>
                            <TableRow>
                                {TABLE_HEADER?.map((header) => (
                                    <TableHead
                                        key={`${header}-head`}
                                        className={cn(
                                            'flex-row items-center text-center font-semibold text-base text-zentive-gray-dark',
                                            'items-start text-start w-[25%]',
                                            {
                                                'text-center': header === 'Quantity',
                                            },
                                        )}
                                    >
                                        <div className='inline-flex gap-[14px] whitespace-nowrap'>
                                            {header}
                                        </div>
                                    </TableHead>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {quote &&
                                quote?.productServiceGroup &&
                                quote?.productServiceGroup?.map(
                                    (services: ProductServiceGroupType) => (
                                        <TableRow
                                            key={services.productServiceId}
                                            className='whitespace-nowrap'
                                        >
                                            <TableCell className='whitespace-nowrap'>
                                                <p className='text-base capitalize'>
                                                    {services.name}
                                                </p>
                                            </TableCell>
                                            <TableCell>
                                                <p className='text-base text-center'>
                                                    {services.quantity ?? 1}
                                                </p>
                                            </TableCell>
                                            <TableCell>
                                                <p className='text-base'>
                                                    {services.cost
                                                        ? '$' +
                                                          numberFormatter.format(services.unitPrice)
                                                        : '$' + '0.00'}
                                                </p>
                                            </TableCell>
                                            <TableCell>
                                                <p className='text-base'>
                                                    {services.unitPrice
                                                        ? '$' +
                                                          numberFormatter.format(services.unitPrice)
                                                        : '$' + '0.00'}
                                                </p>
                                            </TableCell>
                                            <TableCell>
                                                <p className='text-base text-left'>
                                                    {services.cost
                                                        ? '$' +
                                                          numberFormatter.format(services.cost)
                                                        : '$' + '0.00'}
                                                </p>
                                            </TableCell>
                                        </TableRow>
                                    ),
                                )}
                            <TableRow className='whitespace-nowrap hover:bg-transparent'>
                                <TableCell className='whitespace-nowrap'>
                                    <p className='text-base'> </p>
                                </TableCell>
                                <TableCell>
                                    <p className='text-base'> </p>
                                </TableCell>
                                <TableCell>
                                    <p className='text-base font-bold'>
                                        {total && '$' + numberFormatter.format(total)}
                                    </p>
                                </TableCell>
                                <TableCell>
                                    <p className='text-base'> </p>
                                </TableCell>
                                <TableCell>
                                    <p className='text-left text-base font-bold'>
                                        {total && '$' + numberFormatter.format(total)}
                                    </p>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
        </div>
    )
}

export default JobLineItems
