import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/hooks/useToast'
import { AxiosError } from 'axios'
import { FaPencilAlt } from 'react-icons/fa'
import { ProductServiceType } from '@/api/service/schema'
import { productServiceSchema } from '@/api/quoting/schema'
import { updateService } from '@/api/service'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAtom } from 'jotai'
import { businessServiceTypeAtom } from '@/store/customer'
import { Form } from '@/components/Forms'
import { UpdateInputForm } from './UpdateFormService'
import _ from 'lodash'

type UpdateServiceProps = {
    service: ProductServiceType
}

const UpdateServiceModal = (props: UpdateServiceProps) => {
    const { toast } = useToast()
    const [businessServiceType, setBussinessServiceType] = useAtom(businessServiceTypeAtom)
    const [updatedService, setUpdatedService] = useState<ProductServiceType>(props.service)
    const [open, setOpen] = useState<boolean>(false)
    const queryClient = useQueryClient()

    const methods = useForm<ProductServiceType>({
        defaultValues: props.service,
        mode: 'onChange',
        resolver: zodResolver(productServiceSchema),
    })

    const initialFormValues = useMemo(() => {
        return { ...props.service }
    }, [props.service])

    const formValues = methods.watch()

    const isFormDirty = useCallback(() => {
        return !_.isEqual(initialFormValues, formValues)
    }, [initialFormValues, formValues])

    const { mutate: updateServiceMu, isPending: updateLoading } = useMutation<
        unknown,
        AxiosError,
        ProductServiceType
    >({
        mutationFn: (data) => updateService(data),
        onSuccess: () => {
            toast({
                description: 'Successfully Updated',
                variant: 'default',
            })
            const updatedServiceTypes = businessServiceType?.filter(
                (service) => service.productServiceId !== props.service.productServiceId,
            )
            const updatedList = [...(updatedServiceTypes ?? []), updatedService]
            setBussinessServiceType(updatedList)
            queryClient.invalidateQueries({ queryKey: ['ProductServiceType'] })
            setOpen(false)
        },
        onError: () => {
            toast({
                description: 'Product/Service already exists',
                variant: 'destructive',
            })
        },
    })

    useEffect(() => {
        methods.reset(props.service)
    }, [open])

    const onSubmit = (data: ProductServiceType) => {
        updateServiceMu(data)
        setUpdatedService(data)
    }

    return (
        <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogTrigger asChild>
                <button key='pencil-icon'>
                    <FaPencilAlt className='h-4 w-4 cursor-pointer text-indigo-900' />
                </button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white h-fit min-w-[35rem] rounded-[15px] p-0 '>
                <Form {...methods}>
                    <form>
                        <UpdateInputForm />
                    </form>
                </Form>
                <AlertDialogFooter className='bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[15px] pt-[6px] -mt-10'>
                    <div className='w-full flex justify-end items-end gap-[16px]'>
                        <AlertDialogCancel className='w-97 h-[43px] text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            disabled={updateLoading || !isFormDirty()}
                            onClick={methods.handleSubmit(onSubmit)}
                            className='w-97 h-[43px] text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            Update
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default UpdateServiceModal
