import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import CreateEmployee from './AddEmployee'
import { cn } from '@/utils/helper'

const CreateEmployeeView = () => {
    return (
        <div className={cn('max-w-[1700px] h-full', BREADCRUMBS_PADDING_STYLE)}>
            <CreateEmployee />
        </div>
    )
}
export default CreateEmployeeView
