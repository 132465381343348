import { convertImageToBase64 } from '@/utils/helper'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import { useAtomValue } from 'jotai'
import { netGrossExportAtom, netGrosstabsATom } from '@/store/reports'
import { NetGrossExportType } from '@/api/reports/schema'
import { GenerateGrossReportPDF } from './GenerateGrossReportPDF'
import { NET_GROSS_TABS } from '@/constants'
import { GenerateNetReportPDF } from './GenerateNetReportPDF'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'

const ExportGrossAndNetIncomePDF = () => {
    const exportAtom = useAtomValue(netGrossExportAtom)
    const selectedTab = useAtomValue(netGrosstabsATom)

    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    if (exportAtom === null || !exportAtom?.records?.length) return null

    const handleExportToPDF = async () => {
        convertImageToBase64(zentiveLogo)
            .then((zentiveLogoUrl) => {
                const tableData: NetGrossExportType = {
                    records:
                        exportAtom?.records?.map((r) => ({
                            jobNumber: r.jobNumber ?? 0,
                            services: r.services ?? [],
                            customerName: r.customerName ?? '',
                            date: r.date ?? '',
                            grossIncome: r.grossIncome ?? 0,
                            netIncome: r.netIncome ?? 0,
                            externalExpenses: r.externalExpenses ?? 0,
                            internalExpenses: r.internalExpenses ?? 0,
                            paidVia: r.paidVia ?? '',
                            invoiceNumber: r.invoiceNumber ?? 0,
                            quoteNumber: r.quoteNumber ?? 0,
                            quoteId: r.quoteId ?? '',
                        })) ?? [],
                    totalGrossIncome: exportAtom?.totalGrossIncome ?? 0,
                    totalExternalExpense: exportAtom?.totalExternalExpense ?? 0,
                    totalInternalExpense: exportAtom?.totalInternalExpense ?? 0,
                    totalNetIncome: exportAtom?.totalNetIncome ?? 0,
                } as NetGrossExportType

                selectedTab === NET_GROSS_TABS.gross
                    ? GenerateGrossReportPDF(
                          zentiveLogoUrl,
                          tableData,
                          user?.business?.businessName ?? 'Zentive',
                      )
                    : GenerateNetReportPDF(
                          zentiveLogoUrl,
                          tableData,
                          user?.business?.businessName ?? 'Zentive',
                      )
            })
            .catch((err) => console.error('Error converting image to Base64:', err.message))
    }

    return (
        <button
            className='w-[135px] h-[34px] text-gray-800 hover:bg-[#00000029] block px-4 py-2 text-base rounded-t-md'
            onClick={handleExportToPDF}
        >
            Export as PDF
        </button>
    )
}
export default ExportGrossAndNetIncomePDF
