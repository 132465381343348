import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { Dispatch, SetStateAction } from 'react'
import { Button } from '@/components/Button'
import { Modal } from '@/components/Modal'
import { UpdateJobStatusType } from '@/api/job/schema'
import { JOB_STATUS } from '@/constants'
import { toast } from '@/hooks/useToast'

interface ICancelJobModal {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    jobId: string
    updateJobStatusMu: (data: UpdateJobStatusType) => void
}

const CancelJobModal: React.FC<ICancelJobModal> = ({ open, setOpen, jobId, updateJobStatusMu }) => {
    const handleSave = () => {
        updateJobStatusMu({
            jobId: jobId ?? '',
            status: JOB_STATUS.CANCELED,
        })
        setOpen(false)
        toast({
            description: 'Successfully Canceled Job',
            variant: 'default',
        })
    }
    return (
        <Modal
            isOpen={open}
            isHideCloseButton
            onClose={() => {
                setOpen(false)
            }}
            title=''
            titleClassName=''
            containerClassName='max-w-[600px]'
        >
            <div className=''>
                <div className='flex gap-5 px-10'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-16 w-16 rounded-full'>
                        <HiOutlineExclamationTriangle className='h-6 w-6 mx-5  yx-5 text-red-500' />
                    </div>
                    <div className=' overflow-auto'>
                        <h1 className='text-left font-semibold text-2xl text-gray-900 mt-2.5'>
                            Cancel Job
                        </h1>
                        <p className='text-left text-sm text-gray-500 mt-5'>
                            Cancelling this job will remove all schedule and related data.
                        </p>
                        <p className='text-left text-sm text-gray-500 mt-5'>
                            Are you sure you want to cancel this job?
                        </p>
                    </div>
                </div>
                <div className='mt-6 flex justify-end gap-x-4 bg-gray-300 py-6 px-6'>
                    <Button
                        variant='ghost'
                        className='w-97 h-11 text-base font-semibold bg-white text-zentive-green-dark ring-zentive-green-dark border border-zentive-green-dark'
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                    >
                        Yes, please
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default CancelJobModal
