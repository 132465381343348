import { UpgradeBadge } from '@/components/UpgradeBadge'
import { crewjobSearchOpenAtom } from '@/store/crew'
import { cn } from '@/utils/helper'
import { useSetAtom } from 'jotai'
import { BsArrowLeft } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'

interface IBackButtonTopBarProps {
    title: string
    options?: boolean
    redirectTo?: string
    showUpgradeBadge?: boolean
    isLoading?: boolean
}

const BackButtonTopBar = ({
    title,
    options,
    redirectTo,
    showUpgradeBadge = false,
    isLoading = true,
}: IBackButtonTopBarProps) => {
    const navigate = useNavigate()
    const setIsSearchToggle = useSetAtom(crewjobSearchOpenAtom)

    const handleBackClick = () => {
        setIsSearchToggle(false)
        navigate((redirectTo as string) ?? -1)
    }

    return (
        <div className={cn('py-4 z-20 bg-white w-full px-3', !options && 'mb-5')}>
            <div className='flex flex-row justify-between items-center w-full px-4'>
                <div className='flex flex-row items-center gap-x-4'>
                    <BsArrowLeft className='h-5 w-5 cursor-pointer' onClick={handleBackClick} />
                    <span className='font-semibold text-sm'>{title}</span>
                    {showUpgradeBadge && !isLoading && <UpgradeBadge />}
                </div>
            </div>
        </div>
    )
}

export default BackButtonTopBar
