import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { Label } from '@/components/Label'
import { useFormContext } from 'react-hook-form'

const CustomerOtherFields = () => {
    const { control } = useFormContext()

    return (
        <div>
            <div className='mt-[14px] text-left'>
                <div className='w-[932px] h-[45px]'>
                    <Label className='font-semibold text-[16px]  text-zentive-green-dark'>
                        Other Details
                    </Label>
                </div>
            </div>
            <div className='grid grid-cols-3 sm:grid-cols-1 gap-4'>
                <FormField
                    control={control}
                    name='createdAt'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input disabled type='text' placeholder='Date Joined' {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={control}
                    name='lastJobDate'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input
                                    disabled
                                    type='text'
                                    placeholder='Last Job Date'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </div>
    )
}

export default CustomerOtherFields
