import { Button } from '@/components/Button'
import { Card, CardFooter } from '@/components/Card'
import AddQuotingHeader from './CardHeader'
import { Form } from '@/components/Forms'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { QuoteType, quoteSchema } from '@/api/quoting/schema'
import AddQuotingContent from './CardContent'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { saveQuote } from '@/api/quoting'
import { toast } from '@/hooks/useToast'
import { useNavigate } from 'react-router-dom'
import BreadCrumbs from '@/components/BreadCrumbs'
import { useSetAtom, useAtomValue } from 'jotai'
import { expenseAtom, quoteContentAtom, customerQuoteAtom, quoteTaxAtom } from '@/store/owner'
import { startTransition, useEffect } from 'react'
import {
    BREADCRUMBS_PADDING_STYLE,
    DEFAULT_QUOTE_TAX_VALUE,
    DEFAULT_QUOTE_VALUE,
} from '@/constants'
import { paginationAtom } from '@/store/manageSubs'

const AddQuoting = () => {
    const navigate = useNavigate()

    const setQuoteContent = useSetAtom(quoteContentAtom)
    const setExpenses = useSetAtom(expenseAtom)
    const setClient = useSetAtom(customerQuoteAtom)
    const selectedClient = useAtomValue(customerQuoteAtom)
    const setTaxes = useSetAtom(quoteTaxAtom)
    const queryClient = useQueryClient()
    const setPagination = useSetAtom(paginationAtom)

    const methods = useForm<QuoteType>({
        mode: 'onSubmit',
        resolver: zodResolver(quoteSchema),
        defaultValues: {
            productServiceGroup: [
                {
                    productServiceId: '',
                    name: '',
                    cost: 0.0,
                    markup: 0.0,
                    unitPrice: 0.0,
                    description: '',
                    quantity: 0,
                },
            ],
        },
    })

    const { mutate: saveServiceMu, isPending } = useMutation<ResponseType, AxiosError, QuoteType>({
        mutationKey: ['SaveService'],
        mutationFn: (data) => saveQuote(data),
        onSuccess: () => {
            toast({
                description: 'Quote sent!',
                variant: 'default',
            })
            queryClient.invalidateQueries({ queryKey: ['quoteList'] })
            setPagination({
                page: 1,
                pageSize: 5,
            })
            navigate('/financial-management/quoting')
            setTaxes(DEFAULT_QUOTE_TAX_VALUE)
        },
    })

    const onSubmit = (data: QuoteType) => {
        data.productServiceGroup?.forEach((item) => {
            item.cost = item?.cost ? item?.cost * 100 : 0
        })

        const isServiceExist = watchedFields.productServiceGroup?.some(
            (service) => service?.type === 'service',
        )

        if (isServiceExist) {
            startTransition(() => {
                saveServiceMu(data)
            })
        } else {
            toast({
                description: 'Must have at least one service',
                variant: 'default',
            })
        }
    }

    useEffect(() => {
        startTransition(() => {
            setExpenses([])
            setQuoteContent(DEFAULT_QUOTE_VALUE)
            setClient(null)
            setTaxes(DEFAULT_QUOTE_TAX_VALUE)
        })
    }, [setExpenses, setQuoteContent, setClient])

    const { handleSubmit, watch } = methods
    const watchedFields = watch()

    return (
        <div className={BREADCRUMBS_PADDING_STYLE}>
            <BreadCrumbs titleName='Add Quote' />
            <div className='mt-4 w-full'>
                <Form {...methods}>
                    <Card className='border-0 border-t-8 border-zentive-green-dark'>
                        <AddQuotingHeader />
                        <AddQuotingContent />
                        <CardFooter className='lg:flex lg:justify-end rounded-b-md bg-gray-200'>
                            <div className='flex space-x-4 mt-6'>
                                <Button
                                    type='button'
                                    variant={'outline'}
                                    className='w-full'
                                    onClick={() => navigate('/financial-management/quoting')}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type='submit'
                                    onClick={handleSubmit(onSubmit)}
                                    className='w-full'
                                    disabled={
                                        isPending ||
                                        watchedFields.productServiceGroup?.some(
                                            (service) => service?.productServiceId === '',
                                        ) ||
                                        !selectedClient
                                    }
                                >
                                    Save and Send Quote
                                </Button>
                            </div>
                        </CardFooter>
                    </Card>
                </Form>
            </div>
        </div>
    )
}

export default AddQuoting
