import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { signIn, signOut } from '@/api/auth'
import { SignInType, signInSchema } from '@/api/auth/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { AuthTokenResponse, AuthError } from '@supabase/gotrue-js'
import { useUpdateProfile } from './useUpdateProfile'
import { useAppNavigate } from './useAppNavigate'
import { useSetAtom } from 'jotai'
import { sessionAtom } from '@/store/auth'
import { ROLE } from '@/constants'
import { isCrew, isOwnerOrCustomer } from '@/utils/user'
import { useLocation } from 'react-router-dom'
import { useToast } from './useToast'
import { useTranslation } from 'react-i18next'

export const useSignInForm = () => {
    const { t } = useTranslation(['sign-in'])
    const { pathname } = useLocation()
    const { updateUserState } = useUpdateProfile()
    const { toast } = useToast()
    const { navigateTo } = useAppNavigate()

    const setSession = useSetAtom(sessionAtom)

    const signInForm = useForm<SignInType>({
        mode: 'onSubmit',
        resolver: zodResolver(signInSchema),
    })

    const handleSuccess = async (res: AuthTokenResponse) => {
        if (res.data.session && res.data.user) {
            try {
                const role = res.data.session?.user.user_metadata.role
                if (
                    (isOwnerOrCustomer(role) && pathname !== '/sign-in') ||
                    (isCrew(role) && pathname !== '/crew/sign-in')
                ) {
                    const { setValue, setError } = signInForm
                    toast({
                        description: `Please use the ${role.toLowerCase()} interface.`,
                        variant: !isCrew(role) ? 'crew_interface_destructive' : 'default',
                    })
                    setValue('email', '', { shouldValidate: true })
                    setValue('password', '')
                    setError('password', { message: t('Invalid password') })
                    signOut()
                    return
                }

                setSession(res.data.session)
                await updateUserState()

                if (
                    res.data.session?.user.user_metadata.isPasswordGenerated &&
                    res.data.session?.user.user_metadata.role === ROLE.CUSTOMER
                ) {
                    return navigateTo('/customer-portal/create-password')
                }

                if (res.data.session?.user.user_metadata.role === ROLE.CREW) {
                    return navigateTo(
                        res.data.session?.user.user_metadata.isPasswordGenerated
                            ? '/crew/create-password'
                            : '/crew/jobs',
                    )
                }
            } catch (err) {
                console.error(err)
            }
        }

        if (res.error) {
            signInForm.setError('password', {
                type: 'manual',
                message: res.error.message,
            })
        }
    }

    const handleError = (err: AuthError) => console.error(err.message)

    const signInMutator = useMutation<AuthTokenResponse, AuthError, SignInType>({
        mutationFn: signIn,
        onSuccess: handleSuccess,
        onError: handleError,
    })

    return { signInForm, signInMutator }
}
