import { Button } from '@/components/Button'
import { useNavigate } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { USER_STATUS } from '@/constants'
import { employeeImgAtom } from '@/store/employee'
import { useAtom } from 'jotai'

interface EmployeeButtonProp {
    isUpdated: boolean
    mode: string
}

const EmployeeButtons: React.FC<EmployeeButtonProp> = ({ isUpdated, mode }) => {
    const [selectedImage, setSelectedImage] = useAtom(employeeImgAtom)
    const {
        getValues,
        formState: { isValid, isSubmitting },
    } = useFormContext()
    const isInactive = getValues('status') === USER_STATUS.INACTIVE

    if (isInactive) {
        return null
    }

    const navigate = useNavigate()

    return (
        <div className='flex space-x-4 mt-[24px]'>
            <Button
                onClick={() => {
                    setSelectedImage([])
                    navigate('/schedule/employee')
                }}
                type='button'
                variant={'outline'}
                className='w-full'
            >
                Cancel
            </Button>
            <Button
                type='submit'
                className='w-full'
                disabled={
                    (mode === 'create' && !isValid) ||
                    !isUpdated ||
                    isSubmitting && (mode === 'update' && !selectedImage)
                }
            >
                Save
            </Button>
        </div>
    )
}
export default EmployeeButtons
