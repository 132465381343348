import { BillingInfoType } from '@/api/profile/schema'
import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { Label } from '@/components/Label'
import { userAtom } from '@/store/auth'
import { useAtomValue } from 'jotai'
import { useFormContext } from 'react-hook-form'

const BillingAdd = () => {
    const { control } = useFormContext<BillingInfoType>()

    const user = useAtomValue(userAtom)

    return (
        <div className='mt-8'>
            <div className='mt-[14px] text-left'>
                <div className='w-[932px] h-11'>
                    <Label className='font-semibold text-base  text-zentive-green-dark'>
                        Billing Address
                    </Label>
                </div>
            </div>
            <div className='grid grid-cols-3 sm:grid-cols-1 gap-4'>
                <div className='col-span-3 sm:col-span-1'>
                    <FormField
                        control={control}
                        name='address.streetOne'
                        defaultValue={user?.address.streetAddress}
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className='font-sans pl-3 w-full text-base h-11 border border-black text-[#191A0A] disabled:bg-[#EBEBEB] rounded-sm'
                                        type='text'
                                        placeholder='Street 1*'
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className='col-span-3 sm:col-span-1'>
                    <FormField
                        control={control}
                        name='address.streetTwo'
                        defaultValue={user?.address.streetTwoAddress}
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className='font-sans pl-3 w-full text-base h-11 border border-black text-[#191A0A] disabled:bg-[#EBEBEB] rounded-sm'
                                        type='text'
                                        placeholder='Street 2 (Optional)'
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className='col-span-1'>
                    <FormField
                        control={control}
                        name='address.city'
                        defaultValue={user?.address.city}
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className='font-sans pl-3 w-full text-base h-11 border border-black text-[#191A0A] disabled:bg-[#EBEBEB] rounded-sm'
                                        type='text'
                                        placeholder='City*'
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className='col-span-1'>
                    <FormField
                        control={control}
                        name='address.state'
                        defaultValue={user?.address.state}
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className='font-sans pl-3 w-full text-base h-11 border border-black text-[#191A0A] disabled:bg-[#EBEBEB] rounded-sm'
                                        type='text'
                                        placeholder='State*'
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className='col-span-1'>
                    <FormField
                        control={control}
                        name='address.zipCode'
                        defaultValue={user?.address.zipCode}
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className='font-sans pl-3 w-full text-base h-11 border border-black text-[#191A0A] disabled:bg-[#EBEBEB] rounded-sm'
                                        type='text'
                                        placeholder='Zip Code*'
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </div>
        </div>
    )
}
export default BillingAdd
