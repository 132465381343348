import { getAllCrews } from '@/api/crew'
import { CrewListResponseType, CrewType } from '@/api/crew/schema'
import { FullProfileType } from '@/api/profile/schema'
import { Checkbox } from '@/components/Checkbox'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { isAllCrewSelectedFilterAtom, selectedCrewFilterIdsAtom } from '@/store/jobLog'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'

const CrewFilter = () => {
    const queryClient = useQueryClient()
    const [selectedCrewIdFilter, setSelectedCrewIdFilter] = useAtom(selectedCrewFilterIdsAtom)
    const [isCheckAll, setIsCheckAll] = useAtom(isAllCrewSelectedFilterAtom)

    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    const crewQuery = useQuery<CrewListResponseType, Error>(
        {
            queryKey: ['crewList', user],
            queryFn: () => getAllCrews('', '', user.businessId, { page: 1, pageSize: 100 }),
            staleTime: 0,
        },
        queryClient,
    )

    const handleCheckAllClick = () => {
        if (isCheckAll) {
            setIsCheckAll(false)
            setSelectedCrewIdFilter([])
        } else {
            if (crewQuery.data) {
                const crewIds = crewQuery.data && crewQuery.data.content?.map((crew) => crew.crewId)
                setSelectedCrewIdFilter(crewIds)
                setIsCheckAll(true)
            }
        }
    }

    const handleCheckClick = (crew: CrewType, isCheck: boolean) => {
        if (isCheck) {
            const updatedSelectedCrewIds = selectedCrewIdFilter.includes(crew.crewId)
                ? selectedCrewIdFilter.filter((id) => id !== crew.crewId)
                : [...selectedCrewIdFilter, crew.crewId]

            setSelectedCrewIdFilter(updatedSelectedCrewIds)
            setIsCheckAll(selectedCrewIdFilter?.length + 1 === crewQuery.data?.content?.length)
        } else {
            const updatedSelectedCrewIds = selectedCrewIdFilter.includes(crew.crewId)
                ? selectedCrewIdFilter.filter((id) => id !== crew.crewId)
                : [...selectedCrewIdFilter, crew.crewId]

            setSelectedCrewIdFilter(updatedSelectedCrewIds)
            setIsCheckAll(selectedCrewIdFilter?.length - 1 === crewQuery.data?.content?.length)
        }
    }

    return (
        <div className='h-[190px] max-h-[190px] overflow-y-scroll green-scrollbar'>
            <div className='mt-3'>
                <Checkbox
                    className='mt-0.5 accent-zentive-green-dark'
                    onCheckedChange={handleCheckAllClick}
                    checked={isCheckAll}
                />
                <span className='pl-2 text-black'>All Crew</span>
            </div>
            {crewQuery.data &&
                crewQuery.data?.content?.map((crew: CrewType) => (
                    <div key={crew.crewNumber} className='mt-3'>
                        <Checkbox
                            checked={selectedCrewIdFilter.includes(crew.crewId) || isCheckAll}
                            className='mt-0.5 accent-zentive-green-dark'
                            onCheckedChange={(val) => {
                                handleCheckClick(crew, val as boolean)
                            }}
                        />
                        <span className='pl-2 text-black'>{`Crew 00${crew.crewNumber}`}</span>
                    </div>
                ))}
        </div>
    )
}

export default CrewFilter
