import { CreateJobType } from '@/api/job/schema'
import { CustomerProfileType } from '@/api/profile/schema'
import {
    BulkDeleteRouteType,
    CurrentPositionType,
    OwnerForemanProfileType,
    RouteOptimizeType,
    RoutePolylineType,
    RouteType,
} from '@/api/routing/schema'
import { atom } from 'jotai'

export const routeJobListAtom = atom<CreateJobType[] | null>([]) 
export const routeCustomerListAtom = atom<CustomerProfileType[] | null>([])
export const routePolylineAtom = atom<RoutePolylineType | null>(null)
// routePolylineAtom.onMount = (setAtom) => {
//     return () => setAtom(null)
// }

export const isPolylineLoadingAtom = atom<boolean>(false)
export const hasJobResultAtom = atom<boolean>(true)
export const isLandmarkShownAtom = atom<boolean>(false)
// export const customerRoutesAtom = atom<OwnerForemanProfileType[] | null>([])
export const startingAddressAtom = atom<OwnerForemanProfileType | null>(null)
export const routeOptimizeAtom = atom<RouteOptimizeType[] | null>([])
export const routeAtom = atom<RouteType | null>(null)
export const routeIDAtom = atom<string | null>(null)
export const currentLocationAtom = atom<CurrentPositionType | null>(null)
export const bulkDeleteRoutesAtom = atom<BulkDeleteRouteType | null>(null)
export const routeCrewPolylineAtom = atom<RoutePolylineType | null>(null)
