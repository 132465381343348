import { useToast } from '@/hooks/useToast'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import _ from 'lodash'
import { Button } from '@/components/Button'
import { TableCell } from '@/components/Table'
import { Input } from '@/components/Input'
import { ReadJobType, UpdateCrewDtrType, updateDtrSchema } from '@/api/job/schema'
import { formatTo24HourClock, formatToDateTimeWithUTC, formatToUTCWithOffset } from '@/utils/time'
import { ABBREVIATED_DATE_12_HOUR, ISO_8601 } from '@/constants'
import dayjs from 'dayjs'
import { CreateCrewMemberDTRParams, createCrewMemberDtr, updateCrewDtr } from '@/api/job'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UpdateDtrType } from '..'
import { FaCircle } from 'react-icons/fa'

interface UpdateExpenseAccountProps {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    updates: UpdateDtrType
    job: ReadJobType | undefined
    status: string
}

const UpdateDtr = ({ setIsOpen, updates, job, status }: UpdateExpenseAccountProps) => {
    const { toast } = useToast()
    const queryClient = useQueryClient()
    const updateCrewRenderedHoursMethods = useForm<UpdateCrewDtrType>({
        mode: 'onChange',
        resolver: zodResolver(updateDtrSchema),
    })
    const { control, setValue, handleSubmit, watch } = updateCrewRenderedHoursMethods

    const { mutate: createCrewMemberTimeRecordMu } = useMutation({
        mutationFn: (data: CreateCrewMemberDTRParams) => createCrewMemberDtr(data),
        onSuccess: () => {
            if (updates.startTimeId === '' && updates.endTimeId === '') {
                createCrewMemberTimeRecordMu({
                    time: watch().endTime as string,
                    jobCrewMemberId: updates.jobCrewMemberId as string,
                    type: 'end',
                    jobId: job?.jobId as string,
                })
            }
            setIsOpen(false)
            toast({
                description: 'Successfully Updated',
                variant: 'default',
            })
            queryClient.invalidateQueries({ queryKey: ['jobById'] })
        },
        onError: () => {
            setIsOpen(false)
        },
    })

    const { mutate: updateCrewDtrMu } = useMutation({
        mutationFn: (data: UpdateCrewDtrType) => updateCrewDtr(data),
        onSuccess: () => {
            toast({
                description: 'Successfully Updated',
                variant: 'default',
            })
            queryClient.invalidateQueries({ queryKey: ['jobById'] })
            setIsOpen(false)
        },
        onError: () => {
            toast({
                description: 'Something went wrong.. Please try a different time.',
                variant: 'destructive',
            })
            setIsOpen(false)
        },
    })

    const onSubmit = (data: UpdateCrewDtrType) => {
        if (data.startDtrId !== '' && data.endDtrId !== '') {
            updateCrewDtrMu(data)
        } else if (data.startDtrId === '' && data.endDtrId === '') {
            createCrewMemberTimeRecordMu({
                time: watch().startTime as string,
                jobCrewMemberId: updates.jobCrewMemberId as string,
                type: 'start',
                jobId: job?.jobId as string,
            })
        } else if (data.startDtrId !== '' && data.endDtrId === '') {
            if (data.startTime !== watch().startTime) {
                updateCrewDtrMu(data)
            }
            createCrewMemberTimeRecordMu({
                time: watch().endTime as string,
                jobCrewMemberId: updates.jobCrewMemberId as string,
                type: 'end',
                jobId: job?.jobId as string,
            })
        }
    }

    const onChange = (field: 'startTime' | 'endTime') => (value: string) => {
        setValue(`${field}`, value, { shouldValidate: true })
    }

    useEffect(() => {
        const startDateTime = formatTo24HourClock(
            formatToUTCWithOffset(
                updates.startTime as string,
                job?.address?.timezone as string,
                ABBREVIATED_DATE_12_HOUR,
            ),
        )
        formatToDateTimeWithUTC(dayjs(updates.startTime).format(ISO_8601), startDateTime, () =>
            onChange('startTime')(updates.startTime),
        )
        setValue('startDtrId', updates.startTimeId)

        const endDateTime = formatTo24HourClock(
            formatToUTCWithOffset(
                updates.endTime as string,
                job?.address?.timezone as string,
                ABBREVIATED_DATE_12_HOUR,
            ),
        )

        formatToDateTimeWithUTC(dayjs(updates.endTime).format(ISO_8601), endDateTime, () =>
            onChange('endTime')(updates.endTime),
        )

        setValue('endDtrId', updates.endTimeId)
    }, [updates, job])

    return (
        <TableCell className='py-0 px-0' colSpan={4}>
            <Form {...updateCrewRenderedHoursMethods}>
                <form className='flex flex-row py-0 text-zentive-black text-base'>
                    <div className='px-0 py-0'>
                        <FormField
                            control={control}
                            name='startTime'
                            render={({ field }) => (
                                <FormItem className=''>
                                    <FormControl className=''>
                                        <Input
                                            className='font-sans ml-4 pt-1 w-[120px] text-base h-9 border border-gray-400 disabled:bg-[#EBEBEB] rounded-sm'
                                            type='time'
                                            {...field}
                                            onChange={
                                                updates.startTimeId !== ''
                                                    ? (evt) => {
                                                          formatToDateTimeWithUTC(
                                                              dayjs(updates.startTime).format(
                                                                  'YYYY-MM-DD',
                                                              ),
                                                              evt.target.value,
                                                              field.onChange,
                                                          )
                                                      }
                                                    : (evt) => {
                                                          formatToDateTimeWithUTC(
                                                              dayjs(
                                                                  job?.appointment?.startDateTime,
                                                              ).format('YYYY-MM-DD'),
                                                              evt.target.value,
                                                              field.onChange,
                                                          )
                                                      }
                                            }
                                            value={field.value && formatTo24HourClock(field.value)}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className='py-0'>
                        <FormField
                            control={control}
                            name='endTime'
                            render={({ field }) => (
                                <FormItem className=''>
                                    <FormControl className=''>
                                        <Input
                                            className='ml-4 2xl:ml-32 pt-1 font-sans pl-3 w-[120px] text-base h-9 border border-gray-400 disabled:bg-[#EBEBEB] rounded-sm'
                                            type='time'
                                            {...field}
                                            onChange={
                                                updates.endTimeId !== ''
                                                    ? (evt) => {
                                                          formatToDateTimeWithUTC(
                                                              dayjs(updates.endTime).format(
                                                                  'YYYY-MM-DD',
                                                              ),
                                                              evt.target.value,
                                                              field.onChange,
                                                          )
                                                      }
                                                    : (evt) => {
                                                          formatToDateTimeWithUTC(
                                                              dayjs(
                                                                  job?.appointment?.endDateTime,
                                                              ).format('YYYY-MM-DD'),
                                                              evt.target.value,
                                                              field.onChange,
                                                          )
                                                      }
                                            }
                                            value={field.value && formatTo24HourClock(field.value)}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className='py-0 px-0'>
                        <p className='text-gray-500 ml-24 2xl:ml-36 mt-1'>
                            <span className={`ml-2 inline-block`}>
                                <FaCircle className='inline-block mb-1 w-2 h-2' /> {status}
                            </span>
                        </p>
                    </div>
                    <div className='py-0 px-0'>
                        <div className='flex flex-row gap-x-2'>
                            <Button
                                variant='secondary'
                                onClick={() => setIsOpen(false)}
                                className='w-28 h-9 text-base ml-20 2xl:ml-32 font-semibold text-zentive-green-dark ring-zentive-green-dark border border-zentive-green-dark'
                            >
                                Cancel
                            </Button>
                            <Button
                                //disabled={updateLoading || isFormDirty()}
                                onClick={handleSubmit(onSubmit)}
                                className='w-28 h-9 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </form>
            </Form>
        </TableCell>
    )
}

export default UpdateDtr
