import React from "react"
import PublicHeader from "@/layouts/PublicLayout/PublicHeader";
import logo404 from "@/assets/public/logo404.png"
import manwithtree from "@/assets/public/manwithtree404.png"

export const Error404 = () => {
    return(
        <React.Fragment>
           <div className="relative">
                <div className="bg-white absolute inset-x-0 top-0">
                    <PublicHeader />
                </div>
                <div className="bg-zentive-gray-bg min-h-screen flex justify-center">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-10 md:gap-x-10 items-center md:mt-40">
                        <div className="md:mb-64 md:mt-20 md:mr-0 sm:mt-40">
                            <img
                                src={logo404}
                                alt='404 Logo'
                                className='w-full h-auto max-w-md' 
                            />
                        </div>
                        <div className="md:mb-40 md:mt-20 md:ml-0">
                            <img
                                src={manwithtree}
                                alt='main with tree'
                                className='w-full h-auto'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

