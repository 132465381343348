import { cn } from '@/utils/helper'
import { Menu } from '@headlessui/react'
import { useEffect, useRef, useState } from 'react'
import { FaEllipsisH } from 'react-icons/fa'
import { LiaDoorClosedSolid } from 'react-icons/lia'
import { MdOutlineContentCopy } from 'react-icons/md'
import { ImPencil } from 'react-icons/im'
import { FaDollarSign } from 'react-icons/fa'
import { IoCloseCircleSharp } from 'react-icons/io5'
import DownloadPDFButton from './DownloadPDFButton'
import { ReadJobType, UpdateJobStatusType } from '@/api/job/schema'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAtomValue, useSetAtom } from 'jotai'
import { expenseAtomToNewJobAtom, productServiceAtomToNewJobAtom } from '@/store/job'
import { updateJobStatus } from '@/api/job'
import { useNavigate } from 'react-router-dom'
import CancelJobModal from './CancelJobModal'
import { formatToUTCWithOffset } from '@/utils/time'
import { timezoneAtom } from '@/store/auth'
import { TimeZoneResult } from '@/hooks/useGeocode'
import {
    ABBREVIATED_DATE_12_HOUR,
    DEFAULT_QUOTE_TAX_VALUE,
    DEFAULT_QUOTE_VALUE,
    JOB_STATUS,
} from '@/constants'
import dayjs from 'dayjs'
import { invoiceContentAtom, invoiceTaxAtom } from '@/store/owner'
import useStripeConnectStatus from '@/hooks/useStripeConnectStatus'

interface IMoreActionsDropDown {
    job: ReadJobType
    margin?: boolean
}

const MoreActionsDropDown = ({ job, margin }: IMoreActionsDropDown) => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const menuRef = useRef<HTMLDivElement>(null)
    const setInvoiceContent = useSetAtom(invoiceContentAtom)
    const setProductServiceToNewJob = useSetAtom(productServiceAtomToNewJobAtom)
    const setExpensesToNewJob = useSetAtom(expenseAtomToNewJobAtom)
    const [isCancelModalJobOpen, setIsCancelModalJobOpen] = useState<boolean>(false)
    const setTaxes = useSetAtom(invoiceTaxAtom)
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult

    const { mutate: updateJobStatusMu } = useMutation({
        mutationFn: (data: UpdateJobStatusType) => updateJobStatus(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['jobById'] })
        },
        onError: (err) => console.log(err),
    })

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const onOpenMenu = () => {
        setIsOpen(!isOpen)
    }

    const handleCreateSimilarJobClick = () => {
        setIsOpen(!isOpen)
        job.quote?.productServiceGroup && setProductServiceToNewJob(job.quote.productServiceGroup)
        if (job.quote?.expense && Array.isArray(job.quote.expense)) {
            const validatedExpenses = job.quote.expense?.map((exp) => ({
                date: exp.date ?? '',
                total: exp.total ?? 0,
                accountName: exp.accountName,
                description: exp.description,
                isNotIncludedInInvoice: exp.isNotIncludedInInvoice,
            }))
            setExpensesToNewJob(validatedExpenses)
        }
        navigate('/schedule/jobs/new-job')
    }

    const startDateTime = formatToUTCWithOffset(
        job.appointment?.startDateTime as string,
        timezone?.timeZoneId,
        ABBREVIATED_DATE_12_HOUR,
    )

    const currentDateTime = formatToUTCWithOffset(
        dayjs().toString(),
        timezone?.timeZoneId,
        ABBREVIATED_DATE_12_HOUR,
    )

    const closeJob =
        job?.crew?.description &&
        (dayjs(startDateTime).isBefore(currentDateTime) ||
            dayjs(startDateTime).isSame(currentDateTime))

    const handleGenerateInvoice = () => {
        if (job) {
            let group = job.quote.productServiceGroup

            setInvoiceContent((prevVal) => ({
                ...prevVal,
                laborCost: job?.quote?.laborCost ?? 0.0,
                discount: job?.quote?.discount ?? 0.0,
                totalUnitPrice: group
                    ? group?.map((datum) => datum.cost).reduce((a, b) => a + b)
                    : 0.0,
            }))
            navigate(`/financial-management/invoicing/add-invoice/${job.jobId}`)
            setTaxes(DEFAULT_QUOTE_TAX_VALUE)
            setInvoiceContent(DEFAULT_QUOTE_VALUE)
        }
    }

    const { data: isConnectedToStripe } = useStripeConnectStatus()

    return (
        <div ref={menuRef} className={`relative inline-block text-left ml-${margin ? '5' : 'auto'}`}>
            <Menu>
                <Menu.Button
                    className={cn(
                        'bg-zentive-green-dark text-white ml-2 hover:bg-zentive-green-dark/90',
                        'flex justify-center text-center items-center px-4 w-auto h-[48px] gap-x-1.5 rounded-md text=[#3B671A] font-semibold border border-zentive-green-dark text-[16px]',
                    )}
                    onClick={onOpenMenu}
                >
                    <FaEllipsisH className='w-4 h-4 mr-2' />
                    More Actions
                </Menu.Button>

                {isOpen ? (
                    <div className='mt-2 h-auto absolute right-0 z-10 origin-top-right rounded-md bg-white shadow-lg focus:outline-none'>
                        <div className='bg-white rounded-md space-y-2 w-[170x] max-w-[170px]'>
                            {job.status != JOB_STATUS.COMPLETED && (
                                <>
                                    <button
                                        className='w-full h-8 hover:bg-[#00000029] text-left px-3 whitespace-nowrap'
                                        onClick={() => {
                                            setIsOpen(!isOpen)
                                            navigate(`/schedule/jobs/edit/${job.jobId}`)
                                        }}
                                    >
                                        <ImPencil className='inline-block w-4 h-4' />
                                        <span className='ml-1 text-base'> Edit Job </span>
                                    </button>
                                    {closeJob && (
                                        <button
                                            className={cn(
                                                'w-full h-8 hover:bg-[#00000029] text-left px-2 whitespace-nowrap',
                                                job.status === JOB_STATUS.COMPLETED &&
                                                    'bg-zentive-gray-light',
                                            )}
                                            onClick={() => {
                                                setIsOpen(!isOpen)
                                                updateJobStatusMu({
                                                    jobId: job.jobId ?? '',
                                                    status: JOB_STATUS.COMPLETED,
                                                })
                                            }}
                                            disabled={job.status === JOB_STATUS.COMPLETED}
                                        >
                                            <LiaDoorClosedSolid className='inline-block w-6 h-6 text-zentive-greend-dark text-green-700' />
                                            <span className='text-base'> Close Job </span>
                                        </button>
                                    )}
                                </>
                            )}
                            <button
                                className={
                                    'w-full h-8 hover:bg-[#00000029] text-left px-3 whitespace-nowrap'
                                }
                                onClick={handleCreateSimilarJobClick}
                            >
                                <MdOutlineContentCopy className='inline-block w-5 h-5' />
                                <span className='text-base'> Create Similar Job </span>
                            </button>
                            {isConnectedToStripe && job.status === JOB_STATUS.COMPLETED && (
                                <button
                                    className={
                                        'w-full h-8 hover:bg-[#00000029] text-left px-3 whitespace-nowrap'
                                    }
                                    onClick={() => {
                                        setIsOpen(!isOpen)
                                        handleGenerateInvoice()
                                    }}
                                >
                                    <FaDollarSign className='inline-block w-5 h-5 text-zentive-greend-dark' />
                                    <span className='text-base'> Generate Invoice </span>
                                </button>
                            )}
                            <DownloadPDFButton job={job} />
                            {job?.status !== JOB_STATUS.COMPLETED &&
                                job?.status !== JOB_STATUS.CANCELED && (
                                    <button
                                        className={
                                            'w-full h-8 hover:bg-[#00000029] text-left px-3 whitespace-nowrap'
                                        }
                                        onClick={() => setIsCancelModalJobOpen(true)}
                                    >
                                        <IoCloseCircleSharp
                                            className='inline-block w-5 h-5'
                                            style={{ color: 'red' }}
                                        />
                                        <span className='text-base'> Cancel Job </span>
                                    </button>
                                )}
                        </div>
                    </div>
                ) : null}
            </Menu>
            <CancelJobModal
                open={isCancelModalJobOpen}
                setOpen={setIsCancelModalJobOpen}
                jobId={job.jobId ?? ''}
                updateJobStatusMu={updateJobStatusMu}
            />
        </div>
    )
}

export default MoreActionsDropDown
