import { updateProfileStatus } from '@/api/business'
import { BusinessStatusType as ProfileStatusType } from '@/api/business/schema'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
} from '@/components/AlertDialog'
import { USER_STATUS } from '@/constants'
import { useToast } from '@/hooks/useToast'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { UseFormReset } from 'react-hook-form'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { useNavigate } from 'react-router-dom'
import ExistingPhoneNumberModal from '../../../../../../../components/ExistingPhoneNumberModal'

interface ActivateModalProps {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    formValues: ProfileStatusType
    valueResetter: UseFormReset<ProfileStatusType>
}

const ActivateModal = ({ open, setOpen, formValues, valueResetter }: ActivateModalProps) => {
    const navigate = useNavigate()
    const { toast } = useToast()

    const [phoneNumberModal, setPhoneNumberModal] = useState<boolean>(false)

    const { mutate: activateProfileMu } = useMutation({
        mutationFn: (data: ProfileStatusType) => updateProfileStatus(data),
        onSuccess: () => {
            toast({
                description: 'Successfully Activated',
                variant: 'default',
            })
            navigate('/customers/customer-list')
        },
        onError: () => {
            setPhoneNumberModal(true)
        },
    })

    const onCancel = () => {
        setOpen(false)
        valueResetter()
    }

    const onSubmit = () => {
        // Expicitly set the status to ACT
        formValues.status = USER_STATUS.ACTIVATED
        activateProfileMu(formValues)
    }

    return (
        <AlertDialog open={open} onOpenChange={setOpen}>
            <ExistingPhoneNumberModal open={phoneNumberModal} setOpen={setPhoneNumberModal} />
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-[26.5px] pt-[40px]'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                        <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-[24px] text-[#191A0A] mt-[10px]'>
                            Activate Account
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-[14px] text-zentive-gray-medium mt-[18px]'>
                            By activating an account, user will have access to services, content or
                            features associated with the subscription plan. Are you sure you want to activate account?
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-[0px] bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[15px] pt-[6px]'>
                    <div className='w-full flex justify-end items-end gap-[16px]'>
                        <AlertDialogCancel
                            onClick={onCancel}
                            className='w-[155px] h-[43px] text-[16px] font-semibold text-zentive-green-dark ring-zentive-green-dark'
                        >
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            onClick={onSubmit}
                            className='w-[155px] h-[43px] text-[16px] font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            Activate
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
export default ActivateModal
