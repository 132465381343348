import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import PublicHeader from './PublicHeader'
import PublicFooter from './PublickFooter'
import { Toaster } from '@/components/Toaster'
import './index.css'

const PublicLayout = () => {
    const { pathname } = useLocation()

    return (
        <div className='flex flex-col h-screen'>
            {pathname === '/sign-in' ||
            pathname === '/verified' ||
            pathname === '/payment' ||
            pathname === '/free-trial' ||
            pathname === '/invite-result' ||
            !pathname.startsWith('/crew') ? (
                <>
                    <Toaster />
                    <PublicHeader />
                    <main className='flex-1 public-bg flex items-center justify-center'>
                        <Outlet />
                    </main>
                    <PublicFooter />
                </>
            ) : (
                <>
                    <Toaster />
                    <main className='flex-1 flex items-center justify-center'>
                        <div className='flex justify-center h-full w-full overflow-x-hidden'>
                            <div className='w-full max-w-[1024px] public-bg border border-y-0 border-x-gray-200 bg-white'>
                                <Outlet />
                            </div>
                        </div>
                    </main>
                </>
            )}
        </div>
    )
}

export default PublicLayout
