import { Card, CardContent, CardHeader } from '@/components/Card'
import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { Textarea } from '@/components/TextArea'
import { useFieldArray, useFormContext } from 'react-hook-form'
import repeatGrid from '@/assets/private/repeat_grid.svg'
import { useAtom, useAtomValue } from 'jotai'
import { useQuery } from '@tanstack/react-query'
import { getRequestServices } from '@/api/service'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { RequestServiceType } from '@/api/service/schema'
import { AxiosError } from 'axios'
import { CreateJobWithoutQuoteType } from '@/api/job/schema'
import { Trash2Icon } from 'lucide-react'
import { productServiceAtomToNewJobAtom } from '@/store/job'
import { useEffect, useState } from 'react'
import AddProductService from '../../AddProductService'
import { SingleProductService } from '@/api/quoting/schema'

const ContentService = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const [user] = useAtom(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const productServiceToNewJob = useAtomValue(productServiceAtomToNewJobAtom)
    const [isProductsModalOpen, setIsProductsModalOpen] = useState<boolean>(false)
    const { control, setValue, register, getValues, watch } =
        useFormContext<CreateJobWithoutQuoteType>()

    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: 'quote.productServiceGroup',
    })

    const { data: services } = useQuery<unknown, AxiosError, RequestServiceType>({
        queryKey: ['RequestServiceType'],
        queryFn: () => getRequestServices(user?.businessId as string),
    })

    const productSvc = watch('quote.productServiceGroup')

    useEffect(() => {
        if (productServiceToNewJob && productServiceToNewJob?.length >= 1) {
            replace(
                productServiceToNewJob?.map((service) => ({
                    productServiceId: service.productServiceId,
                    name: service.name,
                    unitPrice: service.unitPrice,
                    markup: service.markup,
                    cost: Number(
                        (
                            (service?.unitPrice ?? 0) +
                            ((service?.unitPrice ?? 0) * (service?.markup ?? 0)) / 100
                        ).toFixed(2),
                    ),
                    description: service.description,
                    quantity: 1,
                    type: service.type,
                })),
            )

            productServiceToNewJob.forEach((service, index) => {
                setValue(
                    `quote.productServiceGroup.${index}.productServiceId`,
                    service.productServiceId,
                    {
                        shouldValidate: true,
                    },
                )
                setValue(`quote.productServiceGroup.${index}.unitPrice`, service.unitPrice, {
                    shouldValidate: true,
                })
                setValue(`quote.productServiceGroup.${index}.markup`, service.markup, {
                    shouldValidate: true,
                })
                setValue(
                    `quote.productServiceGroup.${index}.cost`,
                    Number(
                        (
                            (service?.unitPrice ?? 0) +
                            ((service?.unitPrice ?? 0) * (service?.markup ?? 0)) / 100
                        ).toFixed(2),
                    ),
                    {
                        shouldValidate: true,
                    },
                )
                setValue(`quote.productServiceGroup.${index}.description`, service.description, {
                    shouldValidate: true,
                })
                setValue(`quote.productServiceGroup.${index}.name`, service.name, {
                    shouldValidate: true,
                })
                setValue(`quote.productServiceGroup.${index}.type`, service.type, {
                    shouldValidate: true,
                })
            })
        }
    }, [productServiceToNewJob, services, isProductsModalOpen])

    const handleOnChange = (service: string, index: number) => {
        if (service === '+ Add Product Or Services') {
            setIsProductsModalOpen(true)
            setValue(`quote.productServiceGroup.${index}.productServiceId`, '', {
                shouldValidate: true,
            })
            setValue(`quote.productServiceGroup.${index}.unitPrice`, 0, {
                shouldValidate: true,
            })
            setValue(`quote.productServiceGroup.${index}.markup`, 0, {
                shouldValidate: true,
            })
            setValue(`quote.productServiceGroup.${index}.cost`, 0, {
                shouldValidate: true,
            })
            setValue(`quote.productServiceGroup.${index}.description`, '', {
                shouldValidate: true,
            })

            setValue(`quote.productServiceGroup.${index}.name`, '', {
                shouldValidate: true,
            })
            setValue(`quote.productServiceGroup.${index}.type`, '', {
                shouldValidate: true,
            })
        } else {
            const serv = services?.services?.filter(
                (svc) => svc.productServiceId === service,
            )[0] as SingleProductService
            const markup = serv.markup || 0
            setValue(
                `quote.productServiceGroup.${index}.productServiceId`,
                serv.productServiceId as string,
                {
                    shouldValidate: true,
                },
            )
            setValue(`quote.productServiceGroup.${index}.unitPrice`, serv.unitPrice, {
                shouldValidate: true,
            })
            setValue(`quote.productServiceGroup.${index}.quantity`, 1, {
                shouldValidate: true,
            })
            setValue(`quote.productServiceGroup.${index}.markup`, markup, {
                shouldValidate: true,
            })
            setValue(
                `quote.productServiceGroup.${index}.cost`,
                Number(
                    (serv.unitPrice + (serv.unitPrice * (serv.markup as number)) / 100).toFixed(2),
                ),
                {
                    shouldValidate: true,
                },
            )

            setValue(`quote.productServiceGroup.${index}.description`, serv.description, {
                shouldValidate: true,
            })

            setValue(`quote.productServiceGroup.${index}.name`, serv.name as string, {
                shouldValidate: true,
            })
            setValue(`quote.productServiceGroup.${index}.type`, serv.type, {
                shouldValidate: true,
            })

            handleOnMarkupChange(index, markup)
        }
    }

    const handleOnPriceChange = (index: number, price: number) => {
        const markup = getValues(`quote.productServiceGroup.${index}.markup`)
        const quantity = getValues(`quote.productServiceGroup.${index}.quantity`) ?? 1
        const totalPrice = quantity! * price
        setValue(`quote.productServiceGroup.${index}.unitPrice`, price, {
            shouldValidate: true,
        })

        setValue(
            `quote.productServiceGroup.${index}.cost`,
            Number(((totalPrice * markup! / 100) + totalPrice).toFixed(2)),
            {
                shouldValidate: true,
            },
        )
    }

    const handleOnQuantityChange = (index: number, quantity: number, price: number) => {
        const markup = (getValues(`quote.productServiceGroup.${index}.markup`) as number)
        const totalPrice = quantity! * price
 
        setValue(`quote.productServiceGroup.${index}.quantity`, quantity, {
            shouldValidate: true,
        })

        setValue(
            `quote.productServiceGroup.${index}.cost`,
            Number(((totalPrice * markup! / 100) + totalPrice).toFixed(2)),
            {
                shouldValidate: true,
            },
        )
    }

    const handleOnMarkupChange = (index: number, markup: number) => {
        const price = getValues(`quote.productServiceGroup.${index}.unitPrice`)
        const quantity = getValues(`quote.productServiceGroup.${index}.quantity`)
        const totalPrice =  (quantity ? quantity : 1) * price
        const validMarkup = markup !== undefined && !isNaN(markup) ? markup : 0
   
        setValue(`quote.productServiceGroup.${index}.markup`, markup, {
            shouldValidate: true,
        })

        setValue(
            `quote.productServiceGroup.${index}.cost`,
            Number(((totalPrice * validMarkup / 100) + totalPrice).toFixed(2)),
            {
                shouldValidate: true,
            },
        )
    }

    const handleOnRemove = (index: number) => remove(index)

    return (
        <Card className='rounded-none'>
            <CardHeader className='bg-zentive-bg-100 h-[55px] flex flex-row items-center justify-between w-full'>
                <p className='font-semibold text-[17.07px] h-fit w-full text-zentive-green-dark'>
                    Product / Service
                </p>

                <button
                    type='button'
                    className='w-[21%] text-zentive-green-dark font-semibold text-right'
                    onClick={() =>
                        append({
                            productServiceId: '',
                            name: '',
                            cost: 0,
                            markup: 0,
                            unitPrice: 0,
                            description: '',
                            quantity: 1,
                            type: '',
                        })
                    }
                >
                    {'+ New Product / Service'}
                </button>
            </CardHeader>
            <CardContent className='overflow-x-scroll green-scrollbar'>
                {fields?.map((_, index) => (
                    <div className='w-full relative' key={index}>
                        <div className='flex flex-row w-[97%]'>
                            <div className='flex justify-left pt-5 mr-4'>
                                <img src={repeatGrid} alt={'Repeat Grid'} className='h-10 w-10' />
                            </div>

                            <div className='flex flex-col w-full my-4 mr-14'>
                                <div className='flex flex-row w-full mb-6 justify-between'>
                                    <div className='relative z-10'>
                                        <div className='flex absolute text-gray-900 ml-3 -mt-3 pl-1 pr-1 text-sm bg-white'>
                                            Name
                                        </div>
                                        <select
                                            name='row'
                                            onChange={(val) =>
                                                handleOnChange(val.target.value, index)
                                            }
                                            className='flex 2xl:w-96 xl:w-60 lg:w-48 font-sans text-base pl-3 pr-8 h-11 border border-black disabled:bg-gray-100 text-gray-900 rounded-sm mr-4 bg-white appearance-none'
                                            value={productSvc[index].productServiceId ?? ''}
                                        >
                                            <option
                                                className='text-lg hidden text-gray-900'
                                                value=''
                                            >
                                                Service/Product Name
                                            </option>
                                            <option
                                                className='text-lg font-bold py-8 my-8 text-gray-950 border border-b-4'
                                                disabled
                                            >
                                                Services
                                            </option>
                                            {services?.services?.map((data) => (
                                                <option
                                                    value={data.productServiceId}
                                                    key={data.productServiceId}
                                                    className='text-lg py-8 my-8 text-gray-900'
                                                    hidden={productSvc.some(
                                                        (product) =>
                                                            product.productServiceId ===
                                                            data.productServiceId,
                                                    )}
                                                >
                                                    {data.name}
                                                </option>
                                            ))}
                                            <option className='text-zentive-green-dark bg-zentive-gray-light font-semibold py-8 my-8'>
                                                + Add Product Or Services
                                            </option>
                                        </select>
                                    </div>

                                    <FormField
                                        control={control}
                                        disabled={
                                            !getValues(
                                                `quote.productServiceGroup.${index}.productServiceId`,
                                            ) ||
                                            getValues(`quote.productServiceGroup.${index}.type`) ===
                                                'service'
                                        }
                                        defaultValue={1}
                                        {...register(`quote.productServiceGroup.${index}.quantity`)}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input
                                                        className='w-24 mr-4 flex justify-center items-center font-sans text-base h-11 border border-black  text-gray-900'
                                                        placeholder='Quantity'
                                                        type='number'
                                                        {...field}
                                                        onChange={(val) =>
                                                            handleOnQuantityChange(
                                                                index,
                                                                parseInt(val.target.value),
                                                                watch().quote?.productServiceGroup[
                                                                    index
                                                                ]?.unitPrice,
                                                            )
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        control={control}
                                        disabled={
                                            !getValues(
                                                `quote.productServiceGroup.${index}.productServiceId`,
                                            )
                                        }
                                        {...register(
                                            `quote.productServiceGroup.${index}.unitPrice`,
                                        )}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input
                                                        className='2xl:w-64 xl:w-40 lg:w-32 mr-4 flex justify-center items-center font-sans text-base h-11 border border-black disabled:bg-zentive-gray-light text-gray-900 rounded-sm text-right focus:text-left'
                                                        placeholder='Price ($)'
                                                        type='number'
                                                        onKeyDown={(e) => {
                                                            if (
                                                                e.key === '-' ||
                                                                e.key === '+' ||
                                                                e.key === 'e' ||
                                                                e.key === 'E'
                                                            ) {
                                                                e.preventDefault()
                                                            }
                                                        }}
                                                        {...field}
                                                        onChange={(val) =>
                                                            handleOnPriceChange(
                                                                index,
                                                                parseFloat(val.target.value),
                                                            )
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        control={control}
                                        disabled={
                                            !getValues(
                                                `quote.productServiceGroup.${index}.productServiceId`,
                                            )
                                        }
                                        defaultValue={0}
                                        {...register(`quote.productServiceGroup.${index}.markup`)}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input
                                                        className='2xl:w-64 xl:w-48 lg:w-40 mr-4 flex justify-center items-center font-sans text-base  h-11 border border-black disabled:bg-[#ebebeb] disabled:text-zentive-gray-medium text-gray-900'
                                                        placeholder='Markup % (Optional)'
                                                        type='text'
                                                        onKeyDown={(e) => {
                                                            if (
                                                                e.key === '-' ||
                                                                e.key === '+' ||
                                                                e.key === 'e' ||
                                                                e.key === 'E'
                                                            ) {
                                                                e.preventDefault()
                                                            }
                                                        }}
                                                        {...field}
                                                        onChange={(val) => {
                                                            const newValue =
                                                                val.target.value.replace('%', '')
                                                            handleOnMarkupChange(
                                                                index,
                                                                newValue === ''
                                                                    ? 0
                                                                    : parseFloat(newValue),
                                                            )
                                                        }}
                                                        value={
                                                            field.value != 0
                                                                ? typeof field.value === 'number' &&
                                                                  !isNaN(field.value)
                                                                    ? `${field.value}%`
                                                                    : ''
                                                                : ''
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        control={control}
                                        {...register(`quote.productServiceGroup.${index}.cost`)}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input
                                                        className='2xl:w-52 xl:w-44 lg:w-40 flex justify-center items-center font-sans text-base h-11 border border-black text-gray-900'
                                                        placeholder='Total'
                                                        type='text'
                                                        disabled={true}
                                                        {...field}
                                                        onChange={(val) => {
                                                            const newValue =
                                                                val.target.value.replace('$', '')
                                                            handleOnPriceChange(
                                                                index,
                                                                newValue === ''
                                                                    ? 0
                                                                    : parseFloat(newValue),
                                                            )
                                                        }}
                                                        value={
                                                            field.value != 0
                                                                ? typeof field.value === 'number' &&
                                                                  !isNaN(field.value)
                                                                    ? `$${field.value.toLocaleString(
                                                                          undefined,
                                                                          {
                                                                              maximumFractionDigits: 2,
                                                                              minimumFractionDigits: 2,
                                                                          },
                                                                      )}`
                                                                    : ''
                                                                : ''
                                                        }
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                                <div>
                                    <FormField
                                        control={control}
                                        {...register(
                                            `quote.productServiceGroup.${index}.description`,
                                        )}
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Textarea
                                                        className='border p-4 h-24 w-full max-w-full border-gray-400 text-[#191A0A] text-[16px] focus-visible:ring-0 focus-visible:ring-offset-0'
                                                        placeholder='Description'
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        {index == 0 ? (
                            <></>
                        ) : (
                            <button
                                className='absolute right-0 top-6'
                                type='button'
                                onClick={() => handleOnRemove(index)}
                            >
                                <Trash2Icon className='text-zentive-red-dark' />
                            </button>
                        )}
                    </div>
                ))}
                <AddProductService
                    isOpen={isProductsModalOpen}
                    setIsOpen={setIsProductsModalOpen}
                />
            </CardContent>
        </Card>
    )
}

export default ContentService
