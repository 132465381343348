import { CardTitle } from '@/components/Card'
import { TableRow, TableCell, Table, TableBody } from '@/components/Table' 
import ExpenseTableHead from './ExpenseTable/ExpenseHeader' 
import { QuoteType } from '@/api/quoting/schema'

const Expense = ({ quote }: { quote: QuoteType }) => { 
    
    return (
        <div className='border border-[#EBEBEB] p-6 my-16 rounded-md'>
            <div className='flex flex-row justify-between w-full mb-6'>
                <CardTitle className='text-[18px] font-bold'>Expenses</CardTitle> 
            </div> 
                <Table className='table-auto whitespace-normal mx-auto'>
                    <ExpenseTableHead />
                    <TableBody className='bg-white'>
                        { quote?.expense?.map((data) => (
                            <TableRow className='whitespace-nowrap'>
                                <TableCell className=' text-[#191A0A] text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                    {data.accountName}
                                </TableCell>
                                <TableCell className=' text-[#191A0A] text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                    {data.description}
                                </TableCell>
                                <TableCell className=' text-[#191A0A] text-base max-w-[200px] text-ellipsis overflow-hidden'>
                                    {data.date}
                                </TableCell>
                                <TableCell className=' text-[#191A0A] text-base max-w-[200px] text-ellipsis overflow-hidden'>
                                    ${data.total}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table> 
        </div>
    )
}

export default Expense
