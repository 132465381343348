import { ReadJobType } from '@/api/job/schema'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'

interface IJobLogGallery {
    job: ReadJobType | undefined
}

const JobLogGallery = ({ job }: IJobLogGallery) => {
    if (!job?.jobImages)
        return (
            <div className='bg-white rounded-sm'>
                <div className='w-[1260px]'> </div>
                <div className='flex justify-center'>
                    <img
                        src={emptyPlaceholder}
                        alt='empty-table-placeholder'
                        className='my-12 h-60'
                    />
                </div>
                <p className='pb-4 text-center text-2xl text-gray-900'> Currently Empty </p>
            </div>
        )
    return (
        <div className='bg-white p-10 space-y-10'>
            <div className='grid grid-cols-8 gap-1 p-6'>
                {job.jobImages?.map((image, index) => (
                    <img
                        key={index}
                        src={image.jobImageUrl}
                        alt={`job image`}
                        className='w-40 h-40 border border-zentive-green-dark object-cover rounded-md cursor-pointer'
                    />
                ))}
            </div>
        </div>
    )
}
export default JobLogGallery
