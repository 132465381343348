import { z } from 'zod'
import { profileSchema } from '../profile/schema'
import { quoteSchema } from '../quoting/schema'
import { baseSchema } from '../common/schema'

import { PaginationSchema } from '@/components/Pagination/schema'

export const invoiceProfileSchema = z.object({
    profile: profileSchema,
    quoteId: z.string().optional(),
    quote: quoteSchema.optional(),
})

export const invoiceCustomerListSchema = z.object({
    content: z.array(invoiceProfileSchema),
    meta: PaginationSchema,
})

export const invoiceSchema = z
    .object({
        invoiceId: z.string().optional(),
        invoiceNumber: z.string().optional(),
        invoiceName: z.string().optional(),
        quote: quoteSchema.optional(),
    })
    .merge(quoteSchema.partial())
    .merge(baseSchema.partial())
    .extend({
        grossIncome: z.number().optional(),
        netIncome: z.number().optional(),
        businessInvoiceNumber: z.number(),
        sharedNotes: z.string().optional(),
    })

export const refundSchema = z
    .object({
        refundId: z.string().optional(),
        refundNumber: z.string().optional(),
        invoiceId: z.string().optional(),
        invoice: invoiceSchema.optional(),
        profileId: z.string().optional(),
        profile: profileSchema.optional(),
        businessId: z.string(),
        invoiceNumber: z.string().optional(),
        amount: z.number(),
        status: z.string().optional(),
    })
    .merge(baseSchema.partial())

export const invoiceListSchema = z.object({
    content: z.array(invoiceSchema),
    meta: PaginationSchema,
})

export const bulkDeleteInvoiceSchema = z.object({
    invoiceIds: z.array(z.string()),
})

export type InvoiceProfileType = z.infer<typeof invoiceProfileSchema>
export type InvoiceType = z.infer<typeof invoiceSchema>
export type InvoiceCustomerListType = z.infer<typeof invoiceCustomerListSchema>

export type InvoiceListType = z.infer<typeof invoiceListSchema>

export type BulkDeleteInvoiceType = z.infer<typeof bulkDeleteInvoiceSchema>

export type RefundType = z.infer<typeof refundSchema>
