import { Checkbox } from '@/components/Checkbox'
import { TableHead, TableHeader, TableRow } from '@/components/Table'
import { manageSubsTabAtom, toExportSubsAtom } from '@/store/manageSubs'
import { cn } from '@/utils/helper'
import { useAtom, useAtomValue } from 'jotai'
import { FC } from 'react'
import { SubscriberProps } from '../..'
// import SortingButton from './button_sort'
// import { FaArrowDownLong } from 'react-icons/fa6'

const T_HEADER_CLASS = 'font-semibold text-zentive-gray-medium text-base'

const SubsribersTableHead: FC<SubscriberProps> = ({ subscriberList }) => {
    const subStatus = useAtomValue(manageSubsTabAtom)

    const [toexportBusinessData, setToExportBusinessData] = useAtom(toExportSubsAtom)
    // const setSortingSubs = useSetAtom(sortingSubscriptionAtom)
    // const sortSubsKey = useAtomValue(sortingSubscriptionAtom)
    // const isAsc = useAtomValue(sortingToggle)
    // const setToggle = useSetAtom(sortingToggle)

    const handleSelectAllSubs = (isChecked: boolean) =>
        setToExportBusinessData(isChecked ? subscriberList : [])

    // const handleSorting = (keyword: string) => {
    //     const sortOrder = isAsc ? 'asc' : 'desc'
    //     const newToggle = !isAsc

    //     setToggle(newToggle)
    //     setSortingSubs(`${keyword}-${sortOrder}`)
    // }

    return (
        <TableHeader className='bg-white'>
            <TableRow className='whitespace-nowrap'>
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex items-center text-start gap-[10px]'>
                        <Checkbox
                            key={subStatus}
                            id='business-name'
                            className='mr-[5px] mt-[2px]'
                            onCheckedChange={(val) => handleSelectAllSubs(val as boolean)}
                            checked={
                                toexportBusinessData?.length
                                    ? toexportBusinessData?.length > 0 &&
                                      toexportBusinessData?.length == subscriberList?.length
                                    : false
                            }
                        />
                        Business Name
                        {/* <SortingButton
                            isActive={true}
                            currentKey={sortSubsKey}
                            keyword='businesses.business_name'
                            isUpDownKey={isAsc}
                            handleUpDownKey={() => handleSorting('businesses.business_name')} 
                        /> */}
                    </div>
                </TableHead>
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center text-start gap-[10px]'>
                        Subscriber
                        {/* <SortingButton
                            currentKey={sortSubsKey}
                            keyword='first_name'
                            isUpDownKey={isAsc}
                            isActive={true}
                            handleUpDownKey={() => handleSorting('first_name')}
                        /> */}
                    </div>
                </TableHead>
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center  text-end gap-[10px]'>
                        Subscription
                        {/* <SortingButton
                            currentKey={sortSubsKey}
                            keyword='subscription_types.price'
                            isUpDownKey={isAsc}
                            isActive={true}
                            handleUpDownKey={() => handleSorting('subscription_types.price')}
                        /> */}
                    </div>
                </TableHead>
                {subStatus === 'TER' && (
                    <TableHead
                        key='contacted'
                        className={cn(T_HEADER_CLASS, 'w-12 px-2 text-left')}
                    >
                        Contacted
                    </TableHead>
                )}
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center text-start gap-[10px]'>
                        Email Address
                        {/* <SortingButton
                            currentKey={sortSubsKey}
                            keyword='email'
                            isUpDownKey={isAsc}
                            isActive={true}
                            handleUpDownKey={() => handleSorting('email')}
                        /> */}
                    </div>
                </TableHead>

                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center text-start gap-[10px]'>
                        Address
                        {/* <SortingButton
                            currentKey={sortSubsKey}
                            keyword='addresses.street_address'
                            isUpDownKey={isAsc}
                            isActive={true}
                            handleUpDownKey={() => handleSorting('addresses.street_address')}
                        /> */}
                    </div>
                </TableHead>
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center text-start gap-[10px]'>
                        Contact Number
                        {/* <SortingButton
                            currentKey={sortSubsKey}
                            keyword='phone_number'
                            isUpDownKey={isAsc}
                            isActive={true}
                            handleUpDownKey={() => handleSorting('phone_number')}
                        /> */}
                    </div>
                </TableHead>
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center text-start gap-[10px]'>
                        Registered On
                        {/* <SortingButton
                            currentKey={sortSubsKey}
                            keyword='created_at'
                            isUpDownKey={isAsc}
                            isActive={true}
                            handleUpDownKey={() => handleSorting('created_at')}
                        /> */}
                    </div>
                </TableHead>
                <TableHead className={cn(T_HEADER_CLASS, '')}>Note</TableHead>
            </TableRow>
        </TableHeader>
    )
}

export default SubsribersTableHead
