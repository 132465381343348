import { ProfileSelfResponseType } from '@/api/business/schema'
import { getBillingInfo } from '@/api/profile'
import { BillingInfoType } from '@/api/profile/schema'
import { Label } from '@radix-ui/react-label'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

interface UpdateBilingInfoProps {
    businessProfileData: ProfileSelfResponseType | undefined
}
const UpdateBilingInfo = ({ businessProfileData }: UpdateBilingInfoProps) => {
    const navigate = useNavigate()

    const { data: billingInfoData } = useQuery<unknown, AxiosError, BillingInfoType>({
        queryKey: ['billingInfo'],
        queryFn: () => getBillingInfo(businessProfileData?.profileId as string),
        enabled: !!businessProfileData?.profileId,
    })

    return (
        <section>
            <Label className='text-zentive-gray-medium'>Payment Information</Label>
            <div className='w-full mb-[37px] pb-[16px] ring-1 rounded-[4px] ring-[#EBEBEB]'>
                <div className='flex justify-between mt-[6.5px] pt-[16.5px]'>
                    <div className='flex-col justify-start pl-[16px]'>
                        <p className='text-base font-semibold'>{billingInfoData?.lastFourNo}</p>
                    </div>
                    <div className='flex-col fjustify-start pr-[16px]'>
                        <div>
                            <button
                                onClick={() => {
                                    navigate('/owner-subscription/change-card')
                                }}
                            >
                                <p className='text-zentive-green-dark mt-[16px]'>
                                    Update Payment Details
                                </p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default UpdateBilingInfo
