import { CiMail } from 'react-icons/ci'
import { IoMdPhonePortrait } from 'react-icons/io'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { MessageListType } from '@/api/customer/schema'
import { useState } from 'react'

interface Props {
    message: MessageListType
}
const Recipients = ({ message }: Props) => {
    const navigate = useNavigate()

    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(5)

    const lastItemIndex = currentPage * itemsPerPage
    const firstItemIndex = lastItemIndex - itemsPerPage
    const currentItems = message.recipients.slice(firstItemIndex, lastItemIndex)

    const totalPages = Math.ceil(message.recipients.length / itemsPerPage)

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1)
        }
    }

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1)
        }
    }

    const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setItemsPerPage(Number(event.target.value))
        setCurrentPage(1)
    }

    return (
        <>
            <table className='table-auto text-left w-full'>
                <thead className='text-indigo-900'>
                    <tr className='border-b'>
                        <th className='px-6 py-3'>Contact Details</th>
                        <th className='px-6 py-3'>Recipient Contact Info</th>
                        <th className='px-6 py-3'>Delivered</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((recipient) => (
                        <tr key={recipient.email} className='border-b'>
                            <td className='text-indigo-900 px-6 py-3'>{recipient.name}</td>
                            <td className='text-indigo-900 px-6 py-3'>
                                <p>{recipient.email}</p>
                                <p>{recipient.phoneNumber}</p>
                            </td>
                            <td className='px-6 py-3'>
                                {(message.type === 'email' || message.type === 'both') && (
                                    <div className='flex flex-row space-x-2'>
                                        <CiMail className='w-5 h-5 mt-[0.20rem] mr-[0.30rem]' />{' '}
                                        {recipient.dateDelivered}
                                    </div>
                                )}
                                {(message.type === 'sms' || message.type === 'both') && (
                                    <div className='flex flex-row space-x-2'>
                                        <IoMdPhonePortrait className='w-5 h-5 mt-[0.20rem] mr-[0.30rem]' />{' '}
                                        {recipient.dateDelivered}
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='justify-between flex flex-row'>
                <button
                    className='p-5 text-indigo-900 font-bold flex flex-row items-center'
                    onClick={() => navigate(-1)}
                >
                    <FaChevronLeft className='text-center pr-2' /> <p>Go Back</p>
                </button>
                <div className='p-5 flex flex-row space-x-2'>
                    <p className='space-x-1'>Rows of per page: </p>
                    <select
                        name='row'
                        id=''
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                    >
                        <option role='text' value={5}>
                            5
                        </option>
                        <option role='text' value={10}>
                            10
                        </option>
                        <option role='text' value={15}>
                            15
                        </option>
                        <option role='text' value={20}>
                            20
                        </option>
                    </select>
                    <p>
                        {currentPage} of {totalPages}
                    </p>
                    <div className='items-center text-center flex space-x-5'>
                        <button onClick={prevPage} disabled={currentPage === 1}>
                            <FaChevronLeft className='text-center' />
                        </button>
                        <button onClick={nextPage} disabled={currentPage === totalPages}>
                            <FaChevronRight className='text-center' />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Recipients
